import { DateRangeFilter } from "@interface/App"
import { MinorCashAsyncResponse, MinorCashHistoryAsyncResponse } from "@interface/MinorCash"
import { useGetMinorBoxHistoryQuery, useGetMinorBoxQuery } from "@query/MinorBox"

export const useGetMinorCash = ({ startDate, endDate }:Required<DateRangeFilter>) => {
    const { data, isLoading } = useGetMinorBoxQuery({ startDate, endDate })
    const minorCash = data?.payload??{} as MinorCashAsyncResponse['payload']
    return { minorCash, isLoading }
}

export const useGetMinorBoxHistory = ({ startDate, endDate }:Required<DateRangeFilter>) => {
    const { data, isLoading } = useGetMinorBoxHistoryQuery({ startDate, endDate })
    const history = data?.payload??[] as MinorCashHistoryAsyncResponse['payload']
    return { history, isLoading }
}