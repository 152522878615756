import * as React from 'react';
import { Tab as TabMUI, Tabs, TabsProps as TabsPropsMUI} from '@mui/material';
import Box from '@mui/material/Box';

type TabsProps = Pick<TabsPropsMUI, "orientation" | "variant" | "onChange" >

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface Props extends TabsProps{
    TabRender: any
    TabItem: string[]
    tabPosition?: number
}

const Tab = ({ TabRender, TabItem, orientation="vertical", variant="standard", tabPosition=0, onChange }:Props) => {
  const [value, setValue] = React.useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if( onChange !== undefined ) onChange(event, newValue)
  };

  React.useEffect(() => {
    setValue(tabPosition)
  },[tabPosition])

  return (
    <Box sx={orientation === "vertical" ? { flexGrow: 1, bgcolor: 'background.paper', display: 'flex' } : {}} >
      <Tabs variant={variant} orientation={orientation} value={value} onChange={handleChange} sx={{ borderRight: 1, borderColor: 'divider' }} >
        {TabItem.map((itemName, index) => {
            return(
                <TabMUI key={index} label={itemName} {...a11yProps(index)} />
            )
        })}
      </Tabs>
      {TabRender.map((Element:React.ReactElement, index:number) => {
        return(
            <TabPanel key={index} value={value} index={index}>
                {Element}
            </TabPanel>
        )
      })}
    </Box>
  );
}

export default Tab