import Box from "../../../../components/Box"
import DataGrid, { GridToolbarContainer } from "../../../../components/DataGrid"
import IconButton from "../../../../components/IconButton"
import Typography from "../../../../components/Typography"
import AddRounded from '@mui/icons-material/AddRounded';
import Tooltip from "../../../../components/Tooltip";
import Dialog, { DialogContent } from "../../../../components/Dialog";
import dayjs from "dayjs";
import DateTimePicker from "../../../../components/DateTimePicker";
import Grid from "../../../../components/Grid";

const NightlySurchargesList = () => {
    
    const HeaderCustom = () => {
        return(
            <GridToolbarContainer>
                <Box pt={1} px={2} display={'flex'} justifyContent={'space-between'} width={1} alignItems={'center'} >
                    <Typography fontWeight={'bold'} textTransform={'uppercase'} > Recargos nocturnos </Typography>
                    <Tooltip title="Añadir recargo" >
                        <IconButton>
                            <AddRounded />
                        </IconButton>
                    </Tooltip>
                </Box>
            </GridToolbarContainer>
        )
    }

    return(
        <>
            <DataGrid
                components={{
                    Toolbar: HeaderCustom
                }}
                height={250}
                hideFooter
                columns={[]}
                rows={[]}
            />
            <Dialog maxWidth='xs' open={false} onClose={() => null} fullWidth >
                <DialogContent>
                    <Grid container >
                        <Grid xs={12} >
                            <DateTimePicker label="Inicio turno" onChange={() => null} value={dayjs().toDate()} />
                        </Grid>
                        <Grid xs={12} >
                            <DateTimePicker label="Finaliza turno" onChange={() => null} value={dayjs().toDate()} />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default NightlySurchargesList