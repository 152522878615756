import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useAllowEmployeeLoginMutation, useGetEmployeeQuery } from "../../../api/querys/Employee"
import Box from '../../../components/Box';
import Switch from "@component/Switch"

const AllowLogin = () => {
    const [ allowEmployeeLogin, { isLoading } ] = useAllowEmployeeLoginMutation()
    const [ allowLogin, setAllowLogin ] = React.useState<boolean>(false)
    const { employeeId } = useParams()
    const { data } = useGetEmployeeQuery(employeeId!, { refetchOnMountOrArgChange: true })
    const { enqueueSnackbar } = useSnackbar()
    const employee = data?.payload
    const { t } = useTranslation()
    const handleChange = async () => {
        const resp = await allowEmployeeLogin({ employeeId: employeeId!, allowLogin: !allowLogin }).unwrap()
        if( resp.status === 1 ){
            const message = !allowLogin ? t('loginAllowed') : t('loginBlocked')
            enqueueSnackbar(message, {variant: 'success'})
        }else{
            enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'), { variant: 'error' })
        }
    }
    React.useEffect(() => {
        if( employee !== undefined ){
            setAllowLogin(employee.allowLogin)
        }
    },[employee])
    return(
        <Box display={'flex'}  >
            <Switch
                disabled={isLoading}
                checked={allowLogin}
                label={t('allowLogin')}
                onChange={handleChange}
            />
        </Box>
    )
}

export default AllowLogin