import Card from "@component/Card"
import InformativeList from "@component/InformativeList"
import { useCashiersReport } from "@feature/MovementsCashier/hooks/useMovementsCashier"
import dayjs from "dayjs"
import CurrencyExchangeRounded from '@mui/icons-material/CurrencyExchangeRounded';
import IconButton from "@component/IconButton";
import Tooltip from "@component/Tooltip";
import TransferToPettyCash from "@feature/MovementsCashier/components/TransferToPettyCash";
import { useAppSignalSelector } from "@hook/state";
import { useGetMinorBoxHistory, useGetMinorCash } from "../hooks/useMinorCash";
import { Boxes } from "@interface/App";

const CashRegisters = () => {
    const { MovementsCashierState, MinorCashState } = useAppSignalSelector()
    const { startDate, endDate } = MinorCashState.value.filterDateRange
    const { minorCash } = useGetMinorCash({ endDate, startDate })
    const { summaries } = useCashiersReport({ endDate, startDate })
    const { history } = useGetMinorBoxHistory({ endDate, startDate })

    const handleSelectedSummary = (summaryCashier:(typeof summaries[0])) => MovementsCashierState.value = {...MovementsCashierState.value, summaryCashier: summaryCashier}
    const boxList:Boxes[] = ['bank', 'largeBox', 'masterBox', 'minorBox']
    let translatesCashiers = history.reduce((acc, params) => (params.from === "cashierBox") && (params.transfer === "minorBox") ? ((params?.amount??0)+acc) : (acc+0),0)
    let translatesBoxes = history.reduce((acc, params) => (params.from !== "cashierBox") && (params.transfer === "minorBox") ? ((params?.amount??0)+acc) : (acc+0),0)
    let departuresCashiers = history.reduce((acc, params) => (params.from === "cashierBox") && (params.transfer !== "minorBox") ? ((params?.amount??0)+acc) : (acc+0),0)
    let departuresMinorBox = history.reduce((acc, params) => ( (params.from === "minorBox") && (params.transfer === "cashExpense") ) ? ((params?.amount??0)+acc) : (acc+0),0)
    let outboundTransfers = history.reduce((acc, params) => ( (params.from === "minorBox") && ( boxList.some((n) => n === params.transfer) ) ) ? ((params?.amount??0)+acc) : (acc+0),0)

    return(
        <>
            <Card sx={{ mb: 1 }} >
                <InformativeList
                    data={[
                        { primaryText:`Traslados entrantes de cajeros`, secondaryText: ``, value: (translatesCashiers) },
                        { primaryText:`Traslados entrantes de otras cajas`, secondaryText: ``, value: (translatesBoxes) },
                    ]}
                />
                <InformativeList
                    isAnExpenses
                    data={[
                        { primaryText:`Gastos cajeros`, secondaryText: ``, value: (departuresCashiers) },
                        { primaryText:`Gastos caja menor`, secondaryText: ``, value: (departuresMinorBox) },
                        { primaryText:`Traslados salientes`, secondaryText: ``, value: (outboundTransfers) },
                    ]}
                />
                <InformativeList
                    data={[
                        { primaryText:`Efectivo restante`, secondaryText: ``, value: (translatesCashiers-departuresMinorBox-outboundTransfers) },
                    ]}
                />
                <InformativeList
                    data={[
                        { primaryText:`Caja disponible`, secondaryText: `Actualizada el: ${dayjs(minorCash?.minorBox?.updatedAt??"").format("DD-MM-YYYY HH:mm A")}`, value: (minorCash?.minorBox?.amount??0) },
                    ]}
                />
            </Card>
            <Card>
                { summaries.map((summary, index) => {
                    return(
                        <InformativeList
                            key={index}
                            data={[{
                                primaryText:`${summary?.cashier?.name??""} ${summary?.cashier?.lastName??""}`,
                                secondaryText: "Efectivo en caja",
                                value: summary?.inCash??0,
                                slot:
                                    <>
                                        {(summary?.inCash??0) >= 1 &&
                                            <Tooltip title="Pasar dinero a caja menor" >
                                                <IconButton size="small" sx={{ ml: 1 }} onClick={() => handleSelectedSummary(summary)} >
                                                    <CurrencyExchangeRounded sx={{ fontSize: "1rem" }} />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </>
                            }]}
                        />
                    )
                }) }
                <InformativeList
                    data={[
                        { primaryText:`Efectivo total`, secondaryText: "", value: summaries.reduce((acc, params) => (params?.inCash??0)+acc, 0) },
                    ]}
                />
                <TransferToPettyCash />
            </Card>
        </>
    )
}

export default CashRegisters