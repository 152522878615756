import { createSlice } from '@reduxjs/toolkit'

interface stateProps {
}

const initialState:stateProps = {
    paySheetSelected: []
}

export const DebtsToPaySlice = createSlice({
    name: 'debtsToPay',
    initialState,
    reducers: {
    }
})

export const {
} = DebtsToPaySlice.actions
export default DebtsToPaySlice.reducer