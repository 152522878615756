import DataGrid, { GridColDef } from "@component/DataGrid"
import { useAppSignalSelector } from "@hook/state"
import { currencyFormat } from "@hook/useNumberFormat"
import { Boxes } from "@interface/App"
import clsx from "clsx"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useBox, useGetBoxMovements } from "../hooks/useBox"
import MovementsOfTheBoxToolbar from "./MovementsOfTheBoxTollbar"
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import IconButton from "@component/IconButton"
import Tooltip from "@component/Tooltip"
import Swal from "sweetalert2"

interface Props {
    box: Boxes
}

const MovementsOfTheBox = ({ box }:Props) => {
    const { bankId } = useParams()
    const { BusinessBoxState } = useAppSignalSelector()
    const { filterDateRange } = BusinessBoxState.value
    const { startDate, endDate } = filterDateRange
    const { movements, isLoading } = useGetBoxMovements({ box, endDate, startDate, bank: bankId })
    const { t } = useTranslation()
    const { removeCashMovement } = useBox()

    const handleDeleteMovement = async (params:any) => {
        const swalAlert = await Swal.fire({
            icon: 'question',
            text: `¿Desea eliminar este movimiento por $ ${currencyFormat(params?.amount??0)}?, el dinero regresara a ${t(params?.from??"")}`,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Eliminar'
        })

        if( swalAlert.isConfirmed ){
            removeCashMovement({ itemId: params?._id??"", variant: params.variant })
        }
    }

    let columns:GridColDef[] = [
        { maxWidth: 160, minWidth: 160, field: "cashier", headerName: 'Registrado por', valueGetter: ({ row }) => `${row?.cashier?.name??""} ${row?.cashier?.lastName??""}`},
        { maxWidth: 130, minWidth: 130, field: "from", headerName: 'Desde', renderCell: ({ row }) => `${t(row?.from??"")}`},
        { maxWidth: 130, minWidth: 130, field: "transfer", headerName: 'Para', renderCell: ({ row }) => `${t(row?.transfer??"")}`},
        { maxWidth: 120, minWidth: 120, field: "amount", headerName: 'Monto', renderCell: ({ row }) => `$ ${ currencyFormat(Number(row?.amount??0)) }`,
        cellClassName: ({row}) => 
            clsx('super-app', {
                negative: (row.from === "bank" && row.transfer === "bank") ? (row?.outputBank === bankId) : (row.transfer !== box ) ,
                positive: (row.from === "bank" && row.transfer === "bank") ? (row?.outputBank !== bankId) : (row.transfer === box || row.transfer === "invoiceRecovered") ,
            }),
        },
        { flex: 1, field: "note", headerName: 'Nota', renderCell: ({ row }) => `${t(row?.note??"")}`},
        { maxWidth: 160, minWidth: 160, field: "createdAt", headerName: 'Registro', renderCell: ({ row }) => `${ dayjs(row?.createdAt).format("DD-MM-YYYY HH:mm A") }`},
        { maxWidth: 50, minWidth: 50, field: "buttons", headerName: ' ', renderCell: ({ row }) => {
            return(
                <>
                    <Tooltip title="Borrar movimiento" >
                        <IconButton size="small" color="error" onClick={() => handleDeleteMovement(row)} >
                            <DeleteRounded sx={{ fontSize: '1rem' }} />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }},
    ]

    return(
        <>
            <DataGrid
                sx={{
                    '& .super-app.negative': {
                        color: (theme) => theme.palette.error.main
                    },
                    '& .super-app.positive': {
                        color: (theme) => theme.palette.success.main
                    },
                }}
                slots={{
                    toolbar: MovementsOfTheBoxToolbar
                }}
                loading={isLoading}
                columns={columns}
                rows={movements}
            />
        </>
    )
}

export default MovementsOfTheBox