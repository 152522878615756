import { GridColDef } from "@mui/x-data-grid"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useProductPurchaseHistoryQuery } from "../../../api/querys/Product"
import DataGrid, { GridToolbarContainer } from "@component/DataGrid"
import DatePicker from "@component/DatePicker"
import Fade from "@component/Fade"
import Grid from "@component/Grid"
import Typography from "@component/Typography"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { calcProfitPercentage, currencyFormat } from "../../../hooks/useNumberFormat"
import { updateProductPurchaseHistoryEndDate, updateProductPurchaseHistoryStartDate } from "../services/ProductSlice"
import List, { ListItem, ListItemText } from "@component/List"
import Box from "@component/Box"

const ProductPurchaseHistory = () => {
    const dispatch = useAppDispatch()
    const { ProductState } = useAppSelector(({ ProductState }) => ({ ProductState }))
    const { startDateProductPurchaseHistory, endDateProductPurchaseHistory } = ProductState
    const { t } = useTranslation()
    const { productId } = useParams()
    const { data } = useProductPurchaseHistoryQuery({ startDate: startDateProductPurchaseHistory, endDate: endDateProductPurchaseHistory, productId: productId! })
    const history = data?.payload?.results??[]
    const product = data?.payload?.name??""

    const columns:Array<GridColDef> = [
        {field: 'customer', flex: 1, headerName: t('customer')! , valueGetter: ({row}) => (row?.customer?.name??"VARIOS"), renderCell: ({ row }) => <>{row?.customer?.name??"VARIOS"}</> },
        {field: 'consecutive', maxWidth: 80, minWidth: 80, align: 'center', headerName: '#FAC'},
        {field: 'productQuantity', maxWidth: 50, minWidth: 50, align: 'center', headerName: t('quantity')!, renderCell: ({ row }) => (<> {row.productQuantity}x </>)},
        {field: 'productCostPrice', maxWidth: 80, minWidth: 80, align: 'center', headerName: t('cost')!, renderCell: ({ row }) => (<>$ {currencyFormat(row.productCostPrice)} </>) },
        {field: 'productSalePrice', maxWidth: 80, minWidth: 80, align: 'center', headerName: t('salePrice')!, renderCell: ({ row }) => (<>$ {currencyFormat(row.productSalePrice)} </>) },
        {field: 'productTotalSale', maxWidth: 80, minWidth: 80, align: 'center', headerName: t('totalSale')!, renderCell: ({ row }) => (<>$ {currencyFormat(row.productTotalSale)} </>) },
        {field: 'productMargin', maxWidth: 80, minWidth: 80, align: 'center', headerName: t('margin')!, renderCell: ({ row }) => (<> {row.productMargin} %</>) },
        {field: 'createdAt', maxWidth: 80, minWidth: 80, align: 'center', headerName: t('billingDate')!, renderCell: ({ row }) => (<> { dayjs(row.createdAt).format('DD-MM-YYYY') } </>) },
    ]

    const CustomHeader = () => {
        const totalQuantity = history.reduce((acc, params:any) => (acc+(params?.productQuantity??0)),0)
        const totalSale = history.reduce((acc, params) => (acc+Number((params?.productTotalSale??0))),0)
        const totalProfit = history.reduce((acc, params:any) => (acc+Number((params?.productProfit??0))),0)
        const totalMargin = calcProfitPercentage(totalProfit, totalSale)
        return(
            <GridToolbarContainer>
                <Box display={'flex'} flexDirection={'column'} width={"100%"} >
                    <Typography pt={2} px={1} mb={1} fontWeight={'700'} color={'GrayText'} >
                        {product}
                    </Typography>
                    <List sx={{ padding: 0, display: "flex", width: "100%" }} >
                        <ListItem sx={{ padding: 0 }} >
                            <ListItemText primary={`${currencyFormat(totalQuantity)}`} secondary="Cantidades vendidas" />
                        </ListItem>
                        <ListItem sx={{ padding: 0 }} >
                            <ListItemText primary={`$ ${currencyFormat(totalSale)}`} secondary="Total venta" />
                        </ListItem>
                        <ListItem sx={{ padding: 0 }} >
                            <ListItemText primary={`$ ${currencyFormat(totalProfit)}`} secondary="Total ganancia" />
                        </ListItem>
                        <ListItem sx={{ padding: 0 }} >
                            <ListItemText primary={`${totalMargin} %`} secondary="Total margen" />
                        </ListItem>
                    </List>
                </Box>
            </GridToolbarContainer>
        )
    }

return(
        <Fade in={true} >
            <Grid container spacing={1} >
                <Grid xs={12} >
                    <DatePicker value={dayjs(startDateProductPurchaseHistory).toDate()} onChange={({ value }) => dispatch(updateProductPurchaseHistoryStartDate( dayjs(value).format('MM-DD-YYYY') )) } />
                    <DatePicker value={dayjs(endDateProductPurchaseHistory).toDate()} onChange={({ value }) => dispatch(updateProductPurchaseHistoryEndDate( dayjs(value).format('MM-DD-YYYY') )) } />
                </Grid>
                <Grid xs={12} >
                    <DataGrid
                        slots={{
                            toolbar: CustomHeader
                        }}
                        rows={history}
                        columns={columns}
                    />
                </Grid>
            </Grid>
        </Fade>
    )
}

export default ProductPurchaseHistory