import Avatar from "./Avatar"
import Divider from "./Divider"
import { ListItem, ListItemIcon, ListItemText } from "./List"

interface Props {
    showAvatar?: boolean
    primaryText?: string
    secondaryText?: string
    avatar?: JSX.Element | Array<JSX.Element>
}

const ListItemData = ({ showAvatar = false, avatar, primaryText, secondaryText }: Props) => {
    return (
        <>
            <ListItem>
                {showAvatar &&
                    <ListItemIcon>
                        {(avatar !== undefined && avatar !== null) ?
                            <>{avatar}</> :
                            <Avatar>
                                {secondaryText![0]}
                            </Avatar>
                        }
                    </ListItemIcon>
                }
                <ListItemText
                    primary={primaryText ?? ""}
                    secondary={secondaryText ?? ""}
                />
            </ListItem>
            <Divider />
        </>
    )
}

export default ListItemData