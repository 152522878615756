import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { genUUID } from '@service/parseData';

interface stateProps {
  isOpenFixedExpenseForm: boolean
  isOpenFilterWindow: boolean
  isOpenFixedExpensePayment: boolean
  groupBy: "tags" | null
  alertPreventionDeleteSelect: boolean
  tableSelection: Array<string[]>
  filterGroupBy: Array<{
    _id: string
    name: string
    value: string
  }>
}

const initialState: stateProps = {
  tableSelection: [],
  isOpenFixedExpensePayment: false,
  alertPreventionDeleteSelect: false,
  isOpenFixedExpenseForm: false,
  isOpenFilterWindow: false,
  groupBy: null,
  filterGroupBy: [
    {_id: genUUID(), name: "Etiquetas", value: "tags"}
  ]
};

export const FixedCostsSlice = createSlice({
  name: 'fixedCosts',
  initialState,
  reducers: {
    showFixedExpenseForm: (state, action: PayloadAction<boolean>) => {
      state.isOpenFixedExpenseForm = action.payload
    },
    showFilterWindow: (state, action:PayloadAction<boolean>) => {
      state.isOpenFilterWindow = action.payload
    },
    showAlertPreventionDeleteSelect: (state, action:PayloadAction<boolean>) => {
      state.alertPreventionDeleteSelect = action.payload
    },
    showFixedExpensePayment: (state, action:PayloadAction<boolean>) => {
      state.isOpenFixedExpensePayment = action.payload
    },
    updateFilterGroupBy: (state, action:PayloadAction<stateProps['groupBy']>) => {
      state.groupBy = action.payload
    },
    updateTableSelection: (state, action:PayloadAction<stateProps['tableSelection']>) => {
      state.tableSelection = action.payload
    },
  },
});

export const {
  showFixedExpenseForm,
  showFilterWindow,
  updateFilterGroupBy,
  updateTableSelection,
  showAlertPreventionDeleteSelect,
  showFixedExpensePayment,
} = FixedCostsSlice.actions;
export default FixedCostsSlice.reducer;
