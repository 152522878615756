import React from 'react'
import AccountCircleRounded from "@mui/icons-material/AccountCircleRounded"
import List, { ListItem, ListItemIcon, ListItemText } from "@component/List"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { readCookie } from '../../../services/parseData'
import { selectCustomerForInvoice } from '../services/PointOfSaleSlice'
import DeleteForeverRounded from '@mui/icons-material/DeleteForeverRounded'
import IconButton from '../../../components/IconButton'
import CurrencyExchangeRounded from '@mui/icons-material/CurrencyExchangeRounded';
import CustomerAdvance from '@feature/Customer/components/CustomerAdvance'
import { useURLParams } from '@hook/useURLParams'

const PointOfSaleCustomer = () => {
    const { handleParameterGroups } = useURLParams()
    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }))
    const { customer } = PointOfSaleState
    const dispatch = useAppDispatch() 
    React.useEffect(() => {
        const cookieCustomerString = readCookie("customer")
        if( Boolean(cookieCustomerString) ){
            const cookieCustomer = JSON.parse(cookieCustomerString!)
            dispatch(selectCustomerForInvoice(cookieCustomer))
        }
    },[])
    return(
        <>
            { customer?._id !== undefined &&
                <List sx={{ padding: 0 }} >
                    <ListItem sx={{ padding: 0 }} >
                        <ListItemIcon>
                            <AccountCircleRounded sx={{ fontSize: "2.5rem" }} />
                        </ListItemIcon>
                        <ListItemText
                            primary={customer?.name??""}
                            secondary={customer?.address??""}
                        />
                        <ListItemIcon>
                            <IconButton color='error' onClick={() => dispatch(selectCustomerForInvoice(null))} >
                                <DeleteForeverRounded />
                            </IconButton>
                            <IconButton color='primary' onClick={() => handleParameterGroups(["open_modal", "_id"], ["customer_advance", (customer?._id??"")]) } >
                                <CurrencyExchangeRounded />
                            </IconButton>
                        </ListItemIcon>
                    </ListItem>
                </List>
            }
            <CustomerAdvance />
        </>
    )
}

export default PointOfSaleCustomer