import { useSnackbar } from "notistack"
import React from "react"
import { useTranslation } from "react-i18next"
import { useLoginUserMutation } from "../../../api/querys/Authentication"
import Box from "@component/Box"
import Button from "@component/Button"
import Fade from "@component/Fade"
import Form from "@component/Form"
import Link from "@component/Link"
import TextField from "@component/TextField"
import Typography from "@component/Typography"
import { useMessageErrors } from "../../../hooks/messageErrors"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import AppTitle from "../../Authentication/components/AppTitle"
import { clearLoginForm, loadAccountOnQuickLogin, loadToken, toggleFormAuthentication, updateErrorsInLoginFormFields, updateUserLoginForm } from "../../Authentication/services/AuthenticationSlice"
import { validateLoginForm } from "../services/methods"
import QuickLogin from "./QuickLogin"

const LoginForm = () => {
    const [loginUser, { isLoading }] = useLoginUserMutation()
    const { enqueueSnackbar } = useSnackbar();
    const { messageErrors } = useMessageErrors()
    const dispatch = useAppDispatch()
    const { AuthenticationState } = useAppSelector(({ AuthenticationState }) => ({ AuthenticationState }))
    const { loginForm, loginFormFieldError } = AuthenticationState
    const { t } = useTranslation()
    const handleChange = ({target}:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { name, value } = target
        const data = {...loginForm,[name]: value}
        dispatch(updateUserLoginForm(data))
    }

    const handleSubmit = async () => {
        const errors = validateLoginForm(loginForm)
        dispatch(updateErrorsInLoginFormFields(errors))
        if( Object.keys(errors).length >= 1 ){
            enqueueSnackbar(t('verifyForm'), { variant: "warning" });
            return
        }
        const { status, payload } = await loginUser(loginForm).unwrap()
        if( status === 0 ){
            messageErrors({ errorCode: payload?.code??0, key:payload?.key??"", respStatus: status, keyValue:payload?.keyValue??{} })
            return
        }
        if( !Boolean(payload.token) ){
            enqueueSnackbar(t('tokenError'), { variant: "error" });
            return
        }
        dispatch(loadAccountOnQuickLogin({name: `${payload.user.name} ${payload.user.lastName}`, email: payload.user.email, password: loginForm.password}))
        dispatch(clearLoginForm())
        dispatch(loadToken(payload.token))
    }

    return(
        <>
            <AppTitle />
            <Fade in={true} >
                <Form onSubmit={handleSubmit} >
                    <Box mt={3} mb={1} >
                        <Typography variant="h6" fontWeight={"bold"} textAlign={"center"} sx={{ textTransform: "uppercase" }} > {t('log_in')} </Typography>
                    </Box>
                    <TextField error={Boolean(loginFormFieldError.email)} value={loginForm.email} onChange={handleChange} name="email" inputProps={{ style: { fontWeight: "500" } }} autoFocus type="email" label={t('email')} />
                    <TextField error={Boolean(loginFormFieldError.password)} value={loginForm.password} onChange={handleChange} name="password" type="password" label={t('password')} />
                    <Button type="submit" loading={isLoading} >
                        {t('login')}
                    </Button>
                    <Box mt={2} >
                        <Link to={"/"} onClick={() => dispatch(toggleFormAuthentication("registration")) } > {t('registerNow')} </Link>
                    </Box>
                    <QuickLogin />
                </Form>
            </Fade>
        </>
    )
}

export default LoginForm