import AddRounded from '@mui/icons-material/AddRounded';

import { useTranslation } from 'react-i18next';

import Box from '../../../components/Box';
import Tooltip from '../../../components/Tooltip';
import IconButton from '../../../components/IconButton';
import CustomerBusinessTypeInput from '../../Customer/components/CustomerBusinessTypeInput';
import {
  displayBusinessesTypeForm,
  loadBusinessesTypeToEdit,
} from '../services/BusinessesTypeSlice';
import { useAppDispatch } from '../../../hooks/state';
import { useGetCustomerBusinessTypeQuery } from '../../../api/querys/Customer';

const BusinessesTypeSearchBar: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data: BusinessesTypeProductData  } = useGetCustomerBusinessTypeQuery()

  const BusinessesTypeArray =
    BusinessesTypeProductData?.payload?.customerBusinessTypes.results ?? []

  const handleOnChange = (BusinessesTypeId: string) => {
    const findSameBusinessesType = BusinessesTypeArray.find(
      (props: { _id: string }) => props?._id === BusinessesTypeId
    )!;
    dispatch(loadBusinessesTypeToEdit(findSameBusinessesType));
  };

  const handleOpenForm = () => {
    dispatch(displayBusinessesTypeForm(true));
  };

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
    >
      <CustomerBusinessTypeInput
        customerBusinessTypeId=''
        onChange={(_,result: any) => handleOnChange(result?._id ?? '')}
      />
      <Box ml={1}>
        <Tooltip title={t('createNewBusinessType')}>
          <IconButton
            squareButton
            onClick={handleOpenForm}
          >
            <AddRounded />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default BusinessesTypeSearchBar;
