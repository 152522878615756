import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { DateRangeFilter } from '@interface/App'

interface stateProps {
    filterMovements: Required<DateRangeFilter>
}

const initialState:stateProps = {
    filterMovements: {
        startDate: dayjs().format("MM-DD-YYYY"),
        endDate: dayjs().format("MM-DD-YYYY"),
    }
}

export const CashierMovementsSlice = createSlice({
    name: 'cashierMovements',
    initialState,
    reducers: {
        updateCashierMovementDates: (state, action:PayloadAction<Required<DateRangeFilter>>) => {
            state.filterMovements.startDate = dayjs(action.payload.startDate).format("MM-DD-YYYY")
            state.filterMovements.endDate = dayjs(action.payload.endDate).format("MM-DD-YYYY")
        }
    }
})

export const {
    updateCashierMovementDates
} = CashierMovementsSlice.actions
export default CashierMovementsSlice.reducer