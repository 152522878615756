import React from 'react';
import { Collapse, ListItemButton, ListItemIcon, ListItemText } from '../../../components/List';
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom';
import ExpandLessRounded from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';
import { useAppDispatch } from '../../../hooks/state';
import { showDrawer } from '../../../services/AppSlice';
import useMediaQuery from '@mui/material/useMediaQuery';


interface ListItemButtonCustomProps {
    active?: boolean
}

const ListItemButtonCustom = styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== 'active'
})<ListItemButtonCustomProps>(({ active }) => ({
    borderRadius: "100px 0px 0px 100px",
    "&:hover": {
        backgroundColor: "#ffffff6e",
    },
    ...(active ? {
        backgroundColor: "#ffffff6e",
    } : {})
}))

interface Props {
    title: string
    icon: React.ReactNode,
    path?: string
    collapse?: boolean
    children?: React.ReactNode
    open?: boolean
    onClose?: () => void
}

const ListItemDrawerButton = ({ title, icon, path, collapse = false, children, onClose, open }: Props) => {
    const matches = useMediaQuery('(max-width:599px)');
    // const [open, setOpen] = React.useState<boolean>(false)
    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    const handleClick = () => {
        if (collapse) {
            if( onClose !== undefined ){
                onClose()
            }
        } else {
            navigate(path!)
            matches && dispatch(showDrawer(false))
        }
    }

    const disabledButton = React.useMemo(() => {
        let childrenCollapse = children as any[]
        childrenCollapse = Array.isArray(childrenCollapse) ? childrenCollapse : [...(Boolean(childrenCollapse) ? [childrenCollapse] : [])]
        const haveValues = childrenCollapse?.filter(n => Boolean(n?.props?.children) ) || []
        return (collapse && haveValues.length <= 0) ? true : false
    }, [collapse, children])
    return (
        <>
            {!disabledButton &&
                <>
                    <ListItemButtonCustom onClick={handleClick} active={open} >
                        <ListItemIcon sx={{ color: "#FFFFFF", minWidth: "40px" }} >
                            {icon}
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{ fontWeight: "500", fontSize: "13px" }}
                            primary={title}
                        />
                        {collapse ?
                            <>
                                {!open ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                            </> :
                            <></>
                        }
                    </ListItemButtonCustom>
                    {collapse &&
                        <Collapse in={open} sx={{ marginLeft: 1 }} >
                            {children}
                        </Collapse>
                    }
                </>
            }
        </>
    )
}

export default ListItemDrawerButton