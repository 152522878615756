import { useGetRoleByBusinessQuery } from '../../../api/querys/Permission';
import Chip from '../../../components/Chip';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import { RoleProps } from '../../../interfaces/Role';
import { selectRole } from '../services/permissionSlice';

const RolesList = () => {
  const dispatch = useAppDispatch();
  const { PermissionState } = useAppSelector(({ PermissionState }) => ({
    PermissionState,
  }));
  const { rolSelected } = PermissionState;
  const { data: dataRoles } = useGetRoleByBusinessQuery();
  const roles = dataRoles?.payload ?? [];

  const handleChangeRole = (rol: RoleProps) => {
    dispatch(selectRole(rol));
  };
  return (
    <>
      {roles.map((rol, index) => {
        return (
          <Chip
            sx={{ mx: '2px' }}
            onClick={() => handleChangeRole(rol)}
            variant={rol._id === rolSelected._id ? 'filled' : 'outlined'}
            size='small'
            color={rol._id === rolSelected._id ? 'primary' : 'default'}
            key={index}
            label={rol.name}
          />
        );
      })}
    </>
  );
};

export default RolesList;
