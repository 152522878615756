import { useGetSessionDataQuery } from "../../../api/querys/Users"
import Divider from "@component/Divider"
import List, { ListItem, ListItemText } from "@component/List"

const BusinessDataDrawer = () => {
    const { data } = useGetSessionDataQuery()
    const business = data?.payload.business
    return(
        <List >
            <Divider />
            <ListItem>
                <ListItemText
                    primaryTypographyProps={{ fontWeight: "bold" }}
                    secondaryTypographyProps={{ fontWeight: "500", color: "#FFFFFF", textOverflow: "ellipsis",  }}
                    primary={business?.name}
                    secondary={business?.email}
                />
            </ListItem>
            <Divider />
        </List>
    )
}

export default BusinessDataDrawer