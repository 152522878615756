import Typography from "@component/Typography"
import { currencyFormat } from "@hook/useNumberFormat"
import { useTranslation } from "react-i18next"
import ListDataGrid from "./ListDataGrid"
import { useGetSummaryDetailsToSuppliers } from "@feature/Home/hooks/usePanel"

const SupplierAdvanceDetails = ({date=""}) => {
    const { isLoading, resp } = useGetSummaryDetailsToSuppliers({ startDate: date, endDate: date })
    const { t } = useTranslation()
    const columns = [
        { field: "concept", width: 220, headerName: "", renderCell: ({row}) => {
            return(
                <Typography variant="body2" sx={{
                    textTransform: 'uppercase',
                    fontWeight: '500',
                    fontSize: '.795rem',
                }} >
                    {`${t((row?.concept??""))}`}
                </Typography>
            )
        }},
        { field: "total", flex: 1, headerName: "", renderCell: ({row}) => {
            return(
                <Typography variant="body2" sx={{
                    fontWeight: 'bold',
                    color: (theme) => (row?.importance??0) === 1 ? theme.palette.success.main : theme.palette.error.main
                }} >
                    {`$ ${currencyFormat((row?.total??0), true)}`}
                </Typography>
            )
        }},
    ]
    return(
        <>
            <ListDataGrid
                loading={isLoading}
                title="Resumen de anticipos a proveedores"
                columns={columns}
                rows={resp}
            />
        </>
    )
}

export default SupplierAdvanceDetails