import React from "react"
import { useTranslation } from "react-i18next"
import Button from "@component/Button"
import Dialog, { DialogActions, DialogContent, DialogContentText } from "@component/Dialog"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { clearBillingArea, showAlertClearBillingArea } from "../services/PointOfSaleSlice"
import { deleteCookie, readCookie } from "@service/parseData"

const AlertClearAreaDeBilling = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }))
    const { alertClearBillingArea } = PointOfSaleState
    const handleClearBillingArea = () => {
        dispatch(showAlertClearBillingArea())
        dispatch(clearBillingArea())
        deleteCookie('productList')
        deleteCookie('customer')
        deleteCookie('invoiceIdentifiers')
    }
    const handleClearBillAreaKeyPress = (event:React.KeyboardEvent) => {
        const { keyCode, which } = event
        const code = keyCode??which
        if( code === 13 ){
            event.preventDefault()
            event.stopPropagation()
            handleClearBillingArea()
        }
    }
    return(
        <Dialog onKeyDown={handleClearBillAreaKeyPress} open={alertClearBillingArea} onClose={() => dispatch(showAlertClearBillingArea())} fullWidth maxWidth="xs" >
            <DialogContent sx={{ pb: 0, textAlign:"center" }} >
                    <DialogContentText > {t('doYouWantToCleanTheBillingArea')} </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="text" color="error" onClick={handleClearBillingArea} type="submit" >{t('cleanUp')}</Button>
                <Button variant="text" onClick={() => dispatch(showAlertClearBillingArea())} >{t('cancel')} </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AlertClearAreaDeBilling