import Title from '@component/Title';
import Fade from '../../components/Fade';
import Grid from '../../components/Grid';
import LockComponent from '../../components/LockComponent';
import Tab from '../../components/Tab';
import { useAppSelector } from '../../hooks/state';
import { useBusinessPermissions } from '../../hooks/usePermissions';
import DetailsSale from './components/DetailsSale';
import ListOfTableReports from './components/ListOfTableReports';
import ReportFilters from './components/ReportFilters';
import TableReports from './components/TableReports';
import TableReportsArchived from './components/TableReportsArchived';
import TableReportsPrices from './components/TableReportsPrices';

const ReportOfTheDay = () => {
  const { ReportOfTheDay } = useBusinessPermissions()
  const { ReportOfTheDayState } = useAppSelector(({ ReportOfTheDayState }) => ({ ReportOfTheDayState }));
  const { groupById, } = ReportOfTheDayState;
  return (
    <Fade in={true}>
      {ReportOfTheDay.lock_module ?
        <Grid container spacing={2} >
          <Grid xs={12}>
            <Title text='Informe' />
          </Grid>
          <Grid xs={12}>
            <ReportFilters />
          </Grid>
          {!groupById && (
            <Grid xs={12} sm={12} md={4} lg={4} sx={{
              display: { lg: "none", md: "none", sm: "block", xs: "block" }
            }} >
              <DetailsSale />
            </Grid>
          )}
          <Grid xs={groupById ? 12 : 12} sm={groupById ? 12 : 12} md={groupById ? 12 : 8} lg={groupById ? 12 : 8} >
            {!groupById &&
              <>
                <Tab
                  orientation='horizontal'
                  TabItem={[
                    ...( ReportOfTheDay.see_sales ? ["Ventas"] : []),
                    ...( ReportOfTheDay.see_prices ? ["Cotizaciones"] : []),
                    "Archivados",
                  ]}
                  TabRender={[
                    ...( ReportOfTheDay.see_sales ? [<TableReports />] : []),
                    ...( ReportOfTheDay.see_prices ? [<TableReportsPrices />] : []),
                    <TableReportsArchived />,
                  ]}
                />
              </>
            }
            {groupById && <ListOfTableReports />}
          </Grid>
          {!groupById && (
            <Grid xs={12} sm={12} md={4} lg={4} sx={{
              display: { lg: "block", md: "block", sm: "none", xs: "none" }
            }} >
              <DetailsSale />
            </Grid>
          )}
        </Grid>
        :
        <LockComponent />
      }
    </Fade>
  );
};

export default ReportOfTheDay;
