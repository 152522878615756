import Box from "@component/Box"
import IconButton from "@component/IconButton"
import ProductCategoriesInput from "../../Products/components/ProductCategoriesInput"
import AddRounded from '@mui/icons-material/AddRounded';
import Tooltip from "@component/Tooltip";
import { useTranslation } from "react-i18next";
import { displayProductCategoryForm } from "../services/ProductCategorySlice";
import { useAppDispatch } from "../../../hooks/state";

const CategoryProductSearchBar = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    return(
        <Box display={'flex'} alignItems={'center'} >
            <ProductCategoriesInput productCategoryId="" />
            <Box ml={1} >
                <Tooltip title={t('createBewProductCategory')} >
                    <IconButton squareButton onClick={()=> dispatch(displayProductCategoryForm(true)) } >
                        <AddRounded />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    )
}

export default CategoryProductSearchBar