import { useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import Box from "../../../../../components/Box"
import NumberFormat from "../../../../../components/NumberFormat"
import Typography from "../../../../../components/Typography"
import { useAppSelector } from "../../../../../hooks/state"
import { currencyFormat } from "../../../../../hooks/useNumberFormat"
import { ListProductsInvoice } from "@interface/PointOfSale"
import { ConfigProduct } from "../interface/WindowProductPOS"

interface Props {
    setConfigProduct : React.Dispatch<React.SetStateAction<ConfigProduct>>
    typePrice: ListProductsInvoice['typePrice']
    pricePosition: number
    manualPrice: number
}

const WindowProductPOSPrices = ({ manualPrice, pricePosition, setConfigProduct, typePrice }:Props) => {
    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }))
    const { productSelected,  } = PointOfSaleState.windowProduct
    const product = {...productSelected}
    const prices = product.prices!
    const theme = useTheme()
    const { t } = useTranslation()

    const handleFocusManualPrice:React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = () => {
        setConfigProduct(n => ({...n, typePrice: "manual"}))
        setConfigProduct( n => ({...n, pricePosition: -1}) )
    }

    const handleClickPrice = (index:number) => {
        setConfigProduct(n => ({...n, pricePosition: index, typePrice: "sellPrice", manualPrice: 0}))
    }

    return(
        <>
            <Box display={'flex'} justifyContent={'space-between'} gap={2} >
                {prices.map((price, index) => {
                    return(
                        <Box onClick={() =>  handleClickPrice(index)} key={index} sx={{ cursor: 'pointer' }} bgcolor={pricePosition === index ? theme.palette.primary.main : '#e3e3e3'} color={pricePosition === index ? theme.palette.primary.contrastText : 'CaptionText'} lineHeight={"1.2"} fontWeight={'bold'} borderColor={'GrayText'} borderRadius={1} width={1} textAlign='center' >
                            <Typography> Precio {index+1} </Typography>
                            {currencyFormat(price?.sellPrice??0)}
                        </Box>
                    )
                })}
            </Box>
            <Box mt={1} >
                <NumberFormat
                    onFocus={handleFocusManualPrice}
                    id="manualPrice"
                    onChange={({ target }) => setConfigProduct(n => ({...n, manualPrice: Number(target?.value??0) }))}
                    prefix="$ "
                    label={t('manualPrice')!}
                    value={manualPrice}
                />
            </Box>
        </>
    )
}

export default WindowProductPOSPrices