import Typography from "@component/Typography"
import { currencyFormat } from "@hook/useNumberFormat"
import { useTranslation } from "react-i18next"
import ListDataGrid from "./ListDataGrid"
import { useGetSummaryOfBoxes } from "@feature/Home/hooks/usePanel"

const MinorBoxDetails = ({date=""}) => {
    const { isLoading, resp, movements } = useGetSummaryOfBoxes({ startDate: date, endDate: date })
    let minorBoxMovements = movements.filter((n) => n.box === "minorBox")
    let minorBox = resp.find(n => n.box === "minorBox") || null
    minorBox = Boolean(minorBox) ? [{...minorBox, _id: `${(minorBox?._id??'')}a`, amount: (minorBox?.initialValue??0)}, {...minorBox}] : []
    minorBox.splice(minorBox.length -1, 0, ...minorBoxMovements)
    const { t } = useTranslation()
    const columns = [
        { field: "_id", width: 220, headerName: "", renderCell: ({row}) => {
            return(
                <Typography variant="body2" sx={{
                    textTransform: 'uppercase',
                    fontWeight: '500',
                    fontSize: '.795rem',
                }} >
                    { Boolean(row?.concept) ? t(`${row?.concept??""}`) : t(`${row?.box??""}`) }
                </Typography>
            )
        }},
        { field: "total", flex: 1, headerName: "", renderCell: ({row}) => {
            let redVariantColor = ['advanceProvider', 'advanceSupplier', 'paymentPurchase', 'paymentExpenseFixed', 'outboundTransfer', 'cashExpense', 'purchase_credit_payment', 'cash_purchase_payment', 'advance_purchase_payment']
            redVariantColor = redVariantColor.some((n) => n === (row?.concept??""))
            return(
                <Typography className={Boolean(row?.concept) ? (row?.concept??"") : ""} variant="body2" sx={{
                    fontWeight: 'bold',
                    ...(Boolean(row?.concept) ? { color: (theme) => redVariantColor ? theme.palette.error.main : theme.palette.success.main } : { color: (theme) => theme.palette.success.main })
                }} >
                    { Boolean(row?.concept) ?
                        `$ ${currencyFormat((row?.total??0), true)}`
                        :
                        `$ ${currencyFormat((row?.amount??0), true)}`
                    }
                </Typography>
            )
        }},
    ]
    return(
        <>
            <ListDataGrid
                loading={isLoading}
                title="Resumen de caja menor"
                columns={columns}
                rows={minorBox}
            />

        </>
    )
}

export default MinorBoxDetails