
import Dialog, { DialogContent } from '../../../components/Dialog';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import { showCreditForm, showPaymentMethodForm } from '../service/InvoiceSlice';
import CreditForm from './CreditForm';

interface Props {
    invoiceId: string | undefined;
    observation: string
  }
  
const CreditMethod = ({ invoiceId, observation }: Props) => {
  const dispatch = useAppDispatch();

  const { InvoiceState } = useAppSelector(({ InvoiceState }) => ({
    InvoiceState,
  }));
  const { openCreditForm } = InvoiceState;


  const handleClose = () => {
    dispatch(showCreditForm(false));
    dispatch(showPaymentMethodForm(true));
  };

  return (
    <Dialog
      open={openCreditForm}
      onClose={handleClose}
      maxWidth={'xs'}
      fullWidth
    >
      <DialogContent>
        <CreditForm invoiceId={invoiceId} observation={observation} />
      </DialogContent>
    </Dialog>
  );
};
export default CreditMethod;
