import React from 'react'
import Autocomplete from "@component/Autocomplete"
import Button from "@component/Button"
import Dialog, { DialogActions, DialogContent } from "@component/Dialog"
import { FileDownloadRounded } from "@mui/icons-material"
import { genUUID } from "@service/parseData"
import { useURLParams } from '@hook/useURLParams'
import { useSheet } from '@hook/useSheet'
import { useGetAccurateProductInformation } from '../hooks/useProducts'

const SelectProductListFields = () => {
    const { getQuery, delQueries } = useURLParams()
    const downloadCustomerList = getQuery("download")
    const [filter, setFilter] = React.useState<string>("")
    const { fieldsToResponse, listForTheBook, isLoading  } = useGetAccurateProductInformation(filter)
    const open = (downloadCustomerList === "products-list")
    const fieldsDefault = [
        {_id: genUUID(), name: "Consecutivo", value: "consecutive"},
        {_id: genUUID(), name: "Nombre", value: "name"},
        {_id: genUUID(), name: "Categoría", value: "category"},
        {_id: genUUID(), name: "Costo", value: "cost"},
        {_id: genUUID(), name: "Precios", value: "prices"},
        {_id: genUUID(), name: "Embalaje", value: "packaging"},
        {_id: genUUID(), name: "SKU", value: "skuCode"},
        {_id: genUUID(), name: "Stock", value: "stock"},
        {_id: genUUID(), name: "Tipo de producto", value: "compount"},
        {_id: genUUID(), name: "Producto padre", value: "productParent"},
        {_id: genUUID(), name: "Proveedor", value: "provider"},
        {_id: genUUID(), name: "Impuesto", value: "tax"},
    ]
    const { useCreateSheet:createSheet } = useSheet()
    const handleFields = (params:Array<typeof fieldsDefault[0]>) => {
        let values:any = {}
        for( let n of params ){
            values[n.value] = 1
        }
        values = JSON.stringify(values)
        setFilter(values)
    }

    const handleDownloadFile = () => createSheet({ columns: fieldsToResponse, rows: listForTheBook, fileName: "productos.xlsx" })
    return(
        <>
            <Dialog fullWidth open={(open)} onClose={() => delQueries(['download'])} maxWidth="xs" >
                <DialogContent>
                    <Autocomplete
                        onChange={(_, params:any) => handleFields(params)}
                        ChipProps={{
                            size: "small",
                            color: "primary",
                            variant: "outlined"
                        }}
                        multiple
                        label="Seleccione los campos"
                        options={fieldsDefault}
                    />
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<FileDownloadRounded />} variant="text" loading={isLoading} onClick={handleDownloadFile} >
                        Descargar documento
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SelectProductListFields