import React from 'react'
import EmployeeForm from "./EmployeeForm"
import { useGetEmployeeQuery } from '../../../api/querys/Employee'
import { useParams } from "react-router-dom"

const EditEmployee = () => {
    const { employeeId } = useParams()
    const { data } = useGetEmployeeQuery(employeeId!)
    const employee = data?.payload
    
    return(
        <EmployeeForm employee={employee} />
    )
}

export default EditEmployee