import { Boxes } from "@interface/App"
import { BoxCashBalanceAsyncResponse, BoxMovementsAsyncResponse, FilterBoxMovementsProps, RemoveCashMovementAsyncResponse, RemoveCashMovementParams } from "@interface/Box"
import { api, mutationConfig } from "../splitApi"

const LargeBox = api.injectEndpoints({
    endpoints: (build) => ({
        getBox: build.query<BoxCashBalanceAsyncResponse, { box: Boxes, bank?: string }>({
            query: ({ box, bank }) => ({ url: `/get/cash/balance`, params: { box, bank } }),
            providesTags: ['MultiBox']
        }),
        getBoxMovements: build.query<BoxMovementsAsyncResponse, FilterBoxMovementsProps>({
            query: ({startDate, endDate, box, bank }) => ({ url: `/get/box/movements`, params: {startDate, endDate, box, bank} }),
            providesTags: ['MultiBox']
        }),
        removeCashMovement: build.mutation<RemoveCashMovementAsyncResponse, RemoveCashMovementParams>({
            query(body) {
                return {...mutationConfig, url: '/remove/cash/movement', body}
            },
            invalidatesTags: ['Transfer', 'Expense', 'MultiBox', 'Cashier', 'MinorBox', 'Banks']
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetBoxQuery,
    useGetBoxMovementsQuery,
    useRemoveCashMovementMutation
} = LargeBox