import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useChangeAccessPasswordMutation } from '../../../api/querys/Employee'
import TextFieldPassword from "@component/TextFieldPassword"

type P = React.ComponentProps<typeof TextFieldPassword>

interface Props extends P {
    allowLogin: boolean,
}

const ChangeAccessPassword = ({ allowLogin }:Props) => {
    const [password, setPassword] = React.useState<string>("")
    const { employeeId } = useParams()
    const { t } = useTranslation()
    const [ changeAccessPassword, { isLoading } ] = useChangeAccessPasswordMutation()
    const { enqueueSnackbar } = useSnackbar()

    const handleChange = ({ target }:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = target
        setPassword(value)
    }

    const handleBlur = async () => {
        if( password.trim() !== "" ){
            const resp = await changeAccessPassword({ employeeId: employeeId!, password }).unwrap()
            if( resp.status === 1 ){
                enqueueSnackbar(t('theAccessPasswordUpdatedSuccessfully'), { variant: 'success' })
            }else{
                enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'), { variant: 'error' })
            }
        }
    }

    React.useEffect(() => {
        if( !isLoading ){
            setPassword("")
        }
    },[isLoading])

    return(
        <TextFieldPassword
            onChange={handleChange}
            value={password}
            disabled={!allowLogin}
            onBlur={handleBlur}
        />
    )
}

export default ChangeAccessPassword