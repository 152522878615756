import {
  closeProductPackagingEditionForm,
  displayProductPackagingForm,
} from '../services/ProductPackagingSlice';

import { useAppDispatch, useAppSelector } from '../../../hooks/state';

import ProductPackagingForm from './ProductPackagingForm';

import Dialog, { DialogContent } from '../../../components/Dialog';

const CreatePackagingProduct = () => {
  const dispatch = useAppDispatch();
  const { ProductPackagingState } = useAppSelector(
    ({ ProductPackagingState }) => ({ ProductPackagingState })
  );
  const { isOpenForm, packagingToEdit } = ProductPackagingState;

  const handleClose = () => {
    if (packagingToEdit._id === '') {
      dispatch(displayProductPackagingForm(false));
    } else {
      dispatch(closeProductPackagingEditionForm());
    }
  };


  return (
    <Dialog
      open={isOpenForm}
      onClose={handleClose}
      fullWidth
    >
      <DialogContent>
        <ProductPackagingForm />
      </DialogContent>
    </Dialog>
  );
};

export default CreatePackagingProduct;
