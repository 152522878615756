import React from "react"

interface Props {
    onSubmit?: () => void
    children?: JSX.Element | JSX.Element[] | string | number
}

const Form = ({ children, onSubmit }: Props) => {
    const formRef = React.useRef<HTMLFormElement | null>(null)
    const handlerSubmit = (evt:React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault()
        evt.stopPropagation()
        if( onSubmit !== undefined ) onSubmit()
    }

    const handleKeyUp = (evt:React.KeyboardEvent<HTMLFormElement>) => {
        const { keyCode, which } = evt
        const elem = document.querySelector("form button[type='submit']")
        if( elem === null ){
            if( keyCode === 13 || which === 13 ){
                if( onSubmit !== undefined ) onSubmit()
            }
        }
    }
    return(
        <form ref={formRef} onSubmit={handlerSubmit} onKeyUp={handleKeyUp} >
            {children}
        </form>
    )
}

export default Form