import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ZoneData } from "@interface/Zones";
import { useAppSelector } from "../../../hooks/state";
import { useCustomerZones } from "../hooks/useCustomerZones";

import Button from "@component/Button";
import Form from "@component/Form";
import Grid from "@component/Grid";
import TextField from "@component/TextField";

export const ZoneForm = () => {
  const { t } = useTranslation();

  const [form, setForm] = useState<Pick<ZoneData, "name">>({ name: "" });

  const { isOpenForm, zoneToEdit } = useAppSelector(
    ({ ZoneState }) => ZoneState
  );

  const { handleCreateZone, isLoadingRegister, isLoadingUpdate } =
    useCustomerZones();

  const handleSubmit = () => handleCreateZone(form);

  useEffect(() => {
    if (isOpenForm && zoneToEdit._id !== "") {
      setForm((n) => ({ ...n, name: zoneToEdit.name, _id: zoneToEdit._id }));
    }
  }, [isOpenForm, zoneToEdit]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid xs={12}>
            <TextField
              required
              label={t("nameZone")}
              autoFocus
              value={form.name}
              onChange={({ target }) =>
                setForm((n) => ({ ...n, name: target.value }))
              }
            />
          </Grid>
          <Grid xs={12}>
            <Button
              type="submit"
              loading={isLoadingRegister || isLoadingUpdate}
            >
              {t(zoneToEdit._id === "" ? "createZone" : "save")}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};
