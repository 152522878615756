import { useTranslation } from 'react-i18next';

import { useSnackbar } from 'notistack';

import {
  useRegisterPackagingProductMutation,
  useUpdatePackagingProductMutation,
  useDeletePackagingProductMutation,
} from '../../../api/querys/Product';

import type { ProductPackaging } from '../../../interfaces/Product';

export const usePackagingProducts = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [registerPackagingProduct] = useRegisterPackagingProductMutation();
  const [updatePackagingProduct] = useUpdatePackagingProductMutation();
  const [deletePackagingProduct] = useDeletePackagingProductMutation();

  const registerPackagingProductMutation = async (
    payload: ProductPackaging
  ) => {
    if (payload.name.trim() === '') {
      enqueueSnackbar(t('categoryNameIsARequiredField'), {
        variant: 'warning',
      });
      return;
    }
    if (Boolean(payload._id) && payload.name.trim() === '') {
      enqueueSnackbar(
        t(
          'itSeemsThatAnImportantPieceOfInformationIsMissingToUpdateTheProduct'
        )!,
        { variant: 'error' }
      );
      return;
    }
    const resp = Boolean(payload._id)
      ? await updatePackagingProduct(payload).unwrap()
      : await registerPackagingProduct(payload).unwrap();
    if (resp?.status === 1) {
      const message = Boolean(payload._id)
        ? t('theProductPackagingWasSuccessfullyUpdated')
        : t('theProductPackagingWasRegisteredSuccessfully')!;
      enqueueSnackbar(message, { variant: 'success' });
    } else {
      enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest')!, {
        variant: 'error',
      });
    }
  };

  const deletePackagingProductMutation = async (packaging: string[]) => {
    console.log(packaging);
    if (packaging.length <= 0) {
      enqueueSnackbar(t('selectAtLeastOnePackagingToRemove')!, {
        variant: 'warning',
      });
      return;
    }
    const resp = await deletePackagingProduct(packaging).unwrap();
    if (resp.status === 1) {
      enqueueSnackbar(t('packagingWereSuccessfullyRemoved')!, {
        variant: 'success',
      });
      return;
    } else {
      enqueueSnackbar(
        t(
          'itSeemsThatAnImportantPieceOfInformationIsMissingToUpdateTheProduct'
        )!,
        { variant: 'error' }
      );
      return;
    }
  };
  return { registerPackagingProductMutation, deletePackagingProductMutation };
};
