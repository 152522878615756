import React from 'react'
import { AppSignalState, SignalAppState } from "@service/signalStore";
import { createContext } from "react";

export const AppSignals = createContext<SignalAppState | null>(null);

interface Props {
    children: React.ReactNode
}

const SignalProvider = ({children}:Props) => {
    return(
        <AppSignals.Provider value={AppSignalState()}>
            {children}
        </AppSignals.Provider>
    )
}

export default SignalProvider