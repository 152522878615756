import { useURLParams } from "@hook/useURLParams"
import { FilterListRounded } from "@mui/icons-material"
import dayjs from "dayjs"
import React from "react"
import Box from "./Box"
import BoxTraslade from "./BoxTraslade"
import FilterByDateRange from "./FilterByDateRange"
import IconButton from "./IconButton"
import Popover from "./Popover"
import Stack from "./Stack"
import Tooltip from "./Tooltip"
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import Checkbox from "./Checkbox"

interface Props {
    filterByBoxes?: boolean
}

const Filters = ({ filterByBoxes }:Props) => {
    const [dateRange, setDateRange] = React.useState({startDate: dayjs().toDate(), endDate: dayjs().toDate()})
    const { handleActionParameter, delQueries, getParameterGroup, handleParameterGroups, addQuery, getQuery } = useURLParams()
    const { filterBy=null, startDate=null, endDate="", profit="" } = getParameterGroup(['filterBy', 'startDate', 'endDate', 'profit'])
    const arrangeBy = getQuery("arrangeBy")
    const [ anchorEl, setAnchorEl ] = React.useState<HTMLElement | null>(null)
    const handleClickFilters = () => {
        const elem = document.getElementById("b_filters") as HTMLElement
        if( elem !== null ){
            setAnchorEl(elem)
        }
    }
    const addDateRangeFilter = () => {
        handleParameterGroups(['startDate', 'endDate'], [dayjs(dateRange.startDate).format("MM-DD-YYYY"), dayjs(dateRange.endDate).format("MM-DD-YYYY")])
    }
    React.useEffect(() => {
        if( Boolean(endDate) && Boolean(startDate) ){
            setDateRange((n) => ({...n, endDate: dayjs(endDate).toDate(), startDate: dayjs(startDate).toDate()}))
        }
    },[startDate, endDate])
    return(
        <>
            <Stack alignItems={'center'} direction={'row'} sx={{ gap: 1 }} >
                <FilterByDateRange
                    initDate={dateRange.startDate}
                    endDate={dateRange.endDate}
                    onChange={({ endDate, initDate }) => setDateRange(n => ({...n, endDate, startDate: initDate})) }
                />
                <Box>
                    <IconButton squareButton onClick={addDateRangeFilter} >
                        <SendRoundedIcon />
                    </IconButton>
                </Box>
                <Box>
                    <Tooltip title="Filtros" >
                        <IconButton id="b_filters" squareButton onClick={handleClickFilters} >
                            <FilterListRounded />
                        </IconButton>
                    </Tooltip>
                    <Popover PaperProps={{ sx: { minWidth: 300, width: 450 } }} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }} >
                        <Stack sx={{ padding: 2 }} >
                            <Checkbox label="Ver ganancia por mes" onChange={({ target }) => target.checked ? handleActionParameter("profit", "month") : delQueries(['profit'])} checked={profit === "month"} />
                            {filterByBoxes &&
                                <BoxTraslade label="Caja de salida" value={filterBy} onChange={(_, params:any) => Boolean(params?._id) ? handleActionParameter("filterBy", (params?.value??"")) : delQueries(['filterBy']) } />
                            }
                            <Checkbox label="Organizar por concepto" onChange={({ target }) => target.checked ? addQuery({arrangeBy: "concept"}) : delQueries(['arrangeBy'])} checked={arrangeBy === "concept"} />
                        </Stack>
                    </Popover>
                </Box>
            </Stack>
        </>
    )
}

export default Filters