import { styled } from "@mui/material/styles"
import Box from "./Box"
import Card from "./Card"
import CircularProgress from "./CircularProgess"
import Typography from "./Typography"
import useMediaQuery from '@mui/material/useMediaQuery';

interface Props {
    title: string
    moreText: string | number | undefined
    informativeText?: string | number | undefined
    icon: JSX.Element
    onClick?: () => void,
    loading?: boolean,
    isShow?: boolean
}

const MainCard = styled(Card)(({ theme }) => ({
    display: "inline-flex",
    alignItems: 'center',
    justifyContent: 'space-between',
    background: `linear-gradient(360deg, ${theme.palette.primary.dark} 20%, ${theme.palette.primary.main} 100%)`,
    border: 'none',
    borderRadius: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    marginLeft: 3,
    marginRight: 3,
    maxWidth: 330,
    width: "100%",
    cursor: "pointer",
    position: 'relative',
    '& div':{
        '& p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            ...(useMediaQuery(theme.breakpoints.down("sm")) && {
                width: "150px",
            }),
            textOverflow: "ellipsis"
        },
        '& p:nth-of-type(1)':{
            fontSize: "1rem",
            fontWeight: "500",
        },
        '& p:nth-of-type(2)':{
            fontSize: "1.3rem",
            fontWeight: "bold",
        },
        '& p:nth-of-type(3)':{
            fontSize: "0.9rem",
            fontWeight: "500",
        },
    },
    ...(useMediaQuery(theme.breakpoints.down("sm")) && {
        background: 'transparent',
        color: '#000',
        padding: 0,
    })
}))

const IconItem = styled(Box)(({ theme }) => ({
    '& svg': {
        fontSize: theme.spacing(10),
        color: theme.palette.mode === "light" ? "rgb(255 255 255 / 70%)" : "rgb(0 0 0 / 70%)",
        '@media (max-width: 595px)': {
            color: theme.palette.primary.main
        }
    }
}))

const CardInformative = ({ isShow = true, title, moreText, icon, onClick, informativeText, loading = false, ...rest }: Props) => {
    return (
        <MainCard sx={{ display: { xs: `${isShow ? "inline-flex" : "none"}`, lg: 'inline-flex', sm: 'inline-flex', md: 'inline-flex' } }} onClick={onClick} >
            {loading &&
                <Box sx={{ position: 'absolute' }} top={"35%"} left={'45%'} >
                    <CircularProgress />
                </Box>
            }
            <Box {...rest} display={'flex'} p={1} flexDirection={'column'} >
                <Typography sx={{ userSelect: 'none' }} > {title} </Typography>
                <Typography sx={{ userSelect: 'none' }} >{moreText}</Typography>
                <Typography sx={{ userSelect: 'none' }} >{informativeText}</Typography>
            </Box>
            <IconItem>
                {icon}
            </IconItem>
        </MainCard>
    )
}

export default CardInformative