import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Form from '../../../components/Form';
import Grid from '../../../components/Grid';
import TextField from '../../../components/TextField';
import { useAppSelector } from '../../../hooks/state';
import { CustomerNeighborhoodsProps } from '../../../interfaces/Customer';

import { useNeighborhood } from '../hooks/useNeighborhood';

const initialValueOfNeighborhoodsForm = {
  name: '',
};

const NeighborhoodsForm: React.FC = () => {
  const { t } = useTranslation();

  const { NeighborhoodState } = useAppSelector(({ NeighborhoodState }) => ({
    NeighborhoodState,
  }));
  const { isOpenForm, NeighborhoodsToEdit } = NeighborhoodState;

  const {
    registerNeighborhoodMutation,
    registerNeighborhoodIsLoading,
    updateNeighborhoodIsLoading,
  } = useNeighborhood();

  const [form, setForm] = useState<CustomerNeighborhoodsProps>(
    initialValueOfNeighborhoodsForm
  );

  const handleChange = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = evt.target;
    setForm((preventFormState) => ({
      ...preventFormState,
      [name]: value,
    }));
  };

  const handleSubmit = () => registerNeighborhoodMutation(form);

  useEffect(() => {
    if (isOpenForm && NeighborhoodsToEdit._id !== '') {
      setForm((preventFormState) => ({
        ...preventFormState,
        name: NeighborhoodsToEdit.name,
        _id: NeighborhoodsToEdit?._id,
      }));
    }
  }, [isOpenForm, NeighborhoodsToEdit]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={1}
      >
        <Grid xs={12}>
          <TextField
            margin='none'
            label={t('nameOfTheNeighborhood')}
            name='name'
            value={form.name}
            autoFocus
            onChange={handleChange}
          />
        </Grid>
        <Grid xs={12}>
          <Button
            type='submit'
            loading={
              registerNeighborhoodIsLoading || updateNeighborhoodIsLoading
            }
          >
            {NeighborhoodsToEdit._id !== ''
              ? t('updateNeighborhood')
              : t('createNeighborhood')}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default NeighborhoodsForm;
