import React from 'react'
import Grid from "@component/Grid"
import Title from "@component/Title"
import InventoryDetails from "./PanelV2/InventoryDetails"
import SuppliersDetails from "./PanelV2/SuppliersDetails"
import SummaryDashboardFinancialStatus from "./SummaryDashboardFinancialStatus"
import FixedExpenseDetails from "./PanelV2/FixedExpenseDetails"
import DateFilter from "./PanelV2/DateFilter"
import dayjs from "dayjs"
import SupplierAdvanceDetails from './PanelV2/SupplierAdvanceDetails'
import CustomerAdvanceDetails from './PanelV2/CustomerAdvanceDetails'
import LargeBoxDetails from './PanelV2/LargeBoxDetails'
import MinorBoxDetails from './PanelV2/MinorBoxDetails'
import BankBoxDetails from './PanelV2/BankBoxDetails'
import { useGetSummaryOfBoxes } from '../hooks/usePanel'
import MasterBoxDetails from './PanelV2/MasterBoxDetails'
import BusinessAssets from './BusinessAssets'
import BusinessLiabilities from './BusinessLiabilities'
import BusinessEquity from './BusinessEquity'
import DetailedAssets from './DetailedAssets'
import DetailedLiabilities from './DetailedLiabilities'

const HomeAdmin = () => {
    const [ date, setDate ] = React.useState(dayjs().format("MM-DD-YYYY"))
    const { isLoading, resp, movements } = useGetSummaryOfBoxes({ startDate: date, endDate: date })
    let bank = resp.filter((n:any) => n.box === "bank")
    const handlePreviousDate = () => {
        const newDate = dayjs(date).subtract(1, "day").format("MM-DD-YYYY")
        setDate(newDate)
    }
    const handleAfterDate = () => {
        const newDate = dayjs(date).add(1, "day").format("MM-DD-YYYY")
        setDate(newDate)
    }
    return(
        <Grid container spacing={2} >
            <Grid xs={12} >
                <Title text="Panel" />
            </Grid>
            <Grid xs={12} >
                {/* @ts-ignore */}
                <DateFilter date={date} onAfter={handleAfterDate} onPrevious={handlePreviousDate} />
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={4} >
                <BusinessAssets startDate={date} />
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={4} >
                <BusinessLiabilities startDate={date} />
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={4} >
                <BusinessEquity startDate={date} />
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={4} >
                <InventoryDetails date={date} />
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={4} >
                <SuppliersDetails date={date} />
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={4} >
                <LargeBoxDetails date={date} />
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={4} >
                <MinorBoxDetails date={date} />
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={4} >
                <MasterBoxDetails date={date} />
            </Grid>
            { bank.map((item:any, index:any) => (
                <Grid key={index} xs={12} sm={12} md={6} lg={4} >
                    <BankBoxDetails isLoading={isLoading} bank={item} movements={movements} />
                </Grid>
            )) }
            <Grid xs={12} sm={12} md={6} lg={4} >
                <SupplierAdvanceDetails date={date} />
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={4} >
                <CustomerAdvanceDetails date={date} />
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={4} >
                <FixedExpenseDetails date={date} />
            </Grid>
            <Grid xs={12} >
                <Grid container >
                    <Grid xs={12} sm={6} md={6} lg={6} >
                        <DetailedAssets startDate={date} />
                    </Grid>
                    <Grid xs={12} sm={6} md={6} lg={6} >
                        <DetailedLiabilities startDate={date} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} >
                <SummaryDashboardFinancialStatus date={date} />
            </Grid>
        </Grid>
    )
}

export default HomeAdmin