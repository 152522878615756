import { useTranslation } from 'react-i18next';

import { useSnackbar } from 'notistack';

import {
  useDeleteCustomersCityMutation,
  useRegisterCustomersCityMutation,
  useUpdateCustomersCityMutation,
} from '../../../api/querys/Customer';
import { CustomerCityProps } from '../../../interfaces/Customer';

export const useCity = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [registerCity, { isLoading: registerCityIsLoading }] =
    useRegisterCustomersCityMutation();
  const [updateCity, { isLoading: updateCityIsLoading }] =
    useUpdateCustomersCityMutation();
  const [deleteCity, { isLoading: deleteCityIsLoading }] =
    useDeleteCustomersCityMutation();

  const registerCityMutation = async (
    payload: CustomerCityProps
  ) => {
    if (payload.name.trim() === '') {
      enqueueSnackbar(t('CityNameIsARequiredField'), {
        variant: 'warning',
      });
      return;
    }
    if (Boolean(payload._id) && payload.name.trim() === '') {
      enqueueSnackbar(
        t(
          'itSeemsThatAnImportantPieceOfInformationIsMissingToUpdateTheProduct'
        )!,
        { variant: 'error' }
      );
      return;
    }
    const resp = Boolean(payload._id)
      ? await updateCity(payload).unwrap()
      : await registerCity(payload).unwrap();
    if (resp?.status === 1) {
      const message = Boolean(payload._id)
        ? t('theCityWasSuccessfullyUpdated')
        : t('theCityWasRegisteredSuccessfully')!;
      enqueueSnackbar(message, { variant: 'success' });
    } else {
      enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest')!, {
        variant: 'error',
      });
    }
  };

  const deleteCityMutation = async (packaging: string[]) => {
    console.log(packaging);
    if (packaging.length <= 0) {
      enqueueSnackbar(t('selectAtLeastCityToRemove')!, {
        variant: 'warning',
      });
      return;
    }
    const resp = await deleteCity(packaging).unwrap();
    if (resp.status === 1) {
      enqueueSnackbar(t('CitiesWereSuccessfullyRemoved')!, {
        variant: 'success',
      });
      return;
    } else {
      enqueueSnackbar(
        t(
          'itSeemsThatAnImportantPieceOfInformationIsMissingToUpdateTheProduct'
        )!,
        { variant: 'error' }
      );
      return;
    }
  };
  return {
    registerCityMutation,
    deleteCityMutation,
    registerCityIsLoading,
    updateCityIsLoading,
    deleteCityIsLoading,
  };
};
