import { GridColDef } from '@mui/x-data-grid';
import { useGetCustomerPurchaseTrackingQuery } from '../../../api/querys/PointOfSale';
import CircularProgress from '../../../components/CircularProgess';
import DataGrid from '../../../components/DataGrid';
import Fade from '../../../components/Fade';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import {
    calcProfitPercentage,
    currencyFormat,
} from '../../../hooks/useNumberFormat';
import { useCustomerPurchaseTracking } from '../hooks/useCustomerPurchaseTracking';
import BillsOfTheDay from './BillsOfTheDay';
import { useSnackbar } from 'notistack';
import {
    showBillsOfTheDay,
    showProductPurchaseTracking,
} from '../services/CustomerPurchaseTrackingSplice';
import ProductPurchaseTrackingList from './ProductPurchaseTrackingList';
import Tooltip from '../../../components/Tooltip';
import { handleCellKeyDownProps } from '../../../interfaces/CustomerPurchaseTraking';
import { styled } from '@mui/material/styles';
import Box from '../../../components/Box';
import { useSheet } from '@hook/useSheet';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import IconButton from '@component/IconButton';
import dayjs from 'dayjs';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '.css-s1v7zr-MuiDataGrid-virtualScrollerRenderZone > div:nth-of-type(odd)': {
        backgroundColor: theme.palette.mode === 'light' && '#00000012',
    },
}));

const StyledBox = styled(Box)(() => ({
    cursor: 'pointer',
}));

const HeaderDataGridList = (dates: string[]) => {
    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Cliente',
            minWidth: 250,
            renderCell: ({ row }) => (
                <Tooltip title={row.name}>
                    <StyledBox>{row.name}</StyledBox>
                </Tooltip>
            ),
        },
        {
            field: 'totalSale',
            headerName: 'Total',
            minWidth: 120,
            align: 'center',
            headerAlign: 'center',
            renderCell: ({ row }) => (
                <Tooltip title={row.name}>
                    <StyledBox>$ {currencyFormat(row.totalSale)}</StyledBox>
                </Tooltip>
            ),
        },
        {
            field: 'totalProfit',
            headerName: '$',
            minWidth: 120,
            align: 'center',
            headerAlign: 'center',
            renderCell: ({ row }) => (
                <Tooltip title={row.name}>
                    <StyledBox> $ {currencyFormat(row.totalProfit)}</StyledBox>
                </Tooltip>
            ),
        },
        {
            field: 'totalProfitPercentage',
            headerName: '%',
            minWidth: 80,
            align: 'center',
            headerAlign: 'center',
            renderCell: ({ row }) => (
                <Tooltip title={row.name}>
                    <StyledBox>
                        {calcProfitPercentage(row.totalProfit, row.totalSale)} %
                    </StyledBox>
                </Tooltip>
            ),
        },
    ];
    for (let dateIndex in dates) {
        
        columns.push({
            field: `${dates[dateIndex]}`,
            headerName: `${dates[dateIndex]}`,
            minWidth: 250,
            renderCell: ({ row }) => {
                return(
                    <Tooltip title={row.name}>
                        <StyledBox>$ {currencyFormat(row[dates[dateIndex]].total)} | $ {currencyFormat(row[dates[dateIndex]].profitCurrency)} | {row[dates[dateIndex]].profitPercentage} %</StyledBox>
                    </Tooltip>
                )
            },
        });
    }

    return columns;
};
const CustomerPurchaseTrackingList = () => {
    const { CustomerPurchaseTrackingState } = useAppSelector(
        ({ CustomerPurchaseTrackingState }) => ({ CustomerPurchaseTrackingState })
    );
    const { startDate, endDate, branch, businessType, zone, customerId } =
        CustomerPurchaseTrackingState;

    const { data, isLoading, isSuccess } = useGetCustomerPurchaseTrackingQuery({
        startDate,
        endDate,
        branch,
        businessType,
        zone,
        customerId,
    });
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const showComponent = !isLoading && isSuccess;
    const customerPurchaseTracking =
        data?.payload?.customerPurchaseTracking ?? [];
    const customerPurchaseTrackingCustomers = data?.payload?.customers ?? [];
    const { organizeData } = useCustomerPurchaseTracking(
        customerPurchaseTracking
    );
    const { purchaseTrackingList, dates } = organizeData(
        customerPurchaseTrackingCustomers
    );
    const columns: GridColDef[] = HeaderDataGridList(dates);

    const handleSelectColum = (data: any) => {
        const { field, row } = data;
        if (field !== undefined) {
            if (
                field !== 'name' &&
                field !== 'totalSale' &&
                field !== 'totalProfit' &&
                field !== 'totalProfitPercentage'
            ) {
                const invoices = row[`invoices_${field}`];
                if (invoices.length <= 0) {
                    enqueueSnackbar('No hay facturas para mostrar', {
                        variant: 'warning',
                    });
                } else {
                    dispatch(
                        showBillsOfTheDay({
                            showBillsOfTheDay: true,
                            billsOfTheDay: invoices,
                        })
                    );
                }
            } else {
                const keys = Object.keys(row).filter((n) => Array.isArray(row[n]));
                const invoicesCollection = keys.map((n) => row[n]);
                const concatCollection = invoicesCollection.reduce(
                    (accumulator = [], currentValue) => accumulator.concat(currentValue)
                );
                const invoiceIdsCollection = concatCollection.map(
                    (n: any) => n.invoiceId
                );
                dispatch(
                    showProductPurchaseTracking({
                        show: true,
                        invoiceCollection: invoiceIdsCollection,
                    })
                );
            }
        }
    };

    const handleCellKeyDown = ({ params, evt }: handleCellKeyDownProps) => {
        evt.keyCode === 13 && handleSelectColum(params);
    };

    const { useCreateSheet:createSheet } = useSheet()
    
    const createNewSheet = () => {
        let purchaseTrackingListClone = [...purchaseTrackingList]
        let datesCopy = [...dates]

        purchaseTrackingListClone = purchaseTrackingListClone.map((n) => {
            return datesCopy.map((x) => ({
                name: (n?.name??""),
                totalSale: `$ ${currencyFormat((n?.totalSale??0))}`,
                totalProfit: `$ ${currencyFormat((n?.totalProfit??0))}`,
                totalPercentage: `$ ${ calcProfitPercentage((n?.totalProfit??0), (n?.totalSale??0)) }`,
                [x]: `$ ${currencyFormat(n[x].total)} | $ ${currencyFormat(n[x].profitCurrency)} | ${n[x].profitPercentage}$`,
            }))
        })
        purchaseTrackingListClone = purchaseTrackingListClone.reduce((acc, val) => [...acc, ...val], [])
        createSheet({ rows: purchaseTrackingListClone, columns: ["Cliente", "Venta", "Ganancia", "Porcentaje", ...dates], fileName: `Seguimiento_${dayjs().format("DDMMYYYYHHmmss")}.xlsx` })
    }

    if (showComponent) {
        return (
            <>
                <IconButton onClick={createNewSheet} color='primary' squareButton >
                    <FileDownloadRoundedIcon />
                </IconButton>
                <StyledDataGrid
                    columns={columns}
                    rows={purchaseTrackingList}
                    onCellClick={handleSelectColum}
                    onCellKeyDown={(params, evt): void =>
                        handleCellKeyDown({ params, evt })
                    }
                />
                <BillsOfTheDay />
                <ProductPurchaseTrackingList />
            </>
        );
    } else {
        return (
            <Fade in={true}>
                <CircularProgress />
            </Fade>
        );
    }
};

export default CustomerPurchaseTrackingList;
