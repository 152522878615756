import { LoadingButton, LoadingButtonProps } from "@mui/lab"
import Box from "./Box"

type Props = Pick<LoadingButtonProps, "endIcon" | "startIcon" | "loading" | "variant" | "children" | "onClick" | "fullWidth" | "size" | "type" | "color" | "disableElevation" | "disabled">

const Button = ({ children, loading=false, variant="contained", fullWidth=true, size="large", ...rest }:Props) => {
    return(
        <Box my={1} >
            <LoadingButton loading={loading} variant={variant} fullWidth={fullWidth} size={size} {...rest} >
                {children}
            </LoadingButton>
        </Box>
    )
}

export default Button