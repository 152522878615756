import React from 'react';
import { useTranslation } from "react-i18next"
import Box from '../../../components/Box';
import Button from "@component/Button"
import Form from "@component/Form"
import Grid from "@component/Grid"
import NumberFormat from '../../../components/NumberFormat';
import TextField from "@component/TextField"
import UploadImage from "@component/UploadImage"
import type { ProductFormProps, ProductProps } from '../../../interfaces/Product';
import { useProducts } from '../hooks/useProducts';
import PackagingProductInput from "./PackagingProductInput"
import ProductCategoriesInput from "./ProductCategoriesInput"
import SimpleProductInput from './SimpleProductInput';
import TypeProductInput from "./TypeProductInput"
import DataSaverOnRounded from '@mui/icons-material/DataSaverOnRounded';
import IconButton from '../../../components/IconButton';
import Tooltip from '../../../components/Tooltip';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import Slide from '../../../components/Slide';
import { toggleBetweenForms } from '../services/ProductSlice';
import ArrowBackIosRounded from '@mui/icons-material/ArrowBackIosRounded';
import ProductCategoryForm from '../../ProductCategories/components/ProductCategoryForm';
import ProductPackagingForm from '../../ProductPackaging/components/ProductPackagingForm';
import { calcProfitPercentage } from '../../../hooks/useNumberFormat';
import ClearRounded from '@mui/icons-material/ClearRounded';
import { useSnackbar } from 'notistack';
import TaxInput from '../../Business/components/TaxInput';
import InputSearchProviders from '../../Provider/components/InputSearchProviders';
import { useGetPackagingProductQuery } from '@query/Product';

const productForm = {
    image: null,
    name: "",
    category: "",
    compount: false,
    packaging: "",
    stock: "",
    skuCode: "",
    prices: [{ sellPrice: "", percentageProfit: "", profit: "" }],
    cost: "",
    productParent: "",
    tax: "",
    provider: "",
    tags: []
}

interface PricesProps {
    cost: number
    sale: number
    profit: number
    margin: number
    onChange?: (value:ProductFormProps['prices'][0]) => void
    onRemove?: () => void
    index?: number
    options?: boolean
    size?: "small" | "medium"
}

export const Prices = ({ cost, sale, profit, margin, onChange, onRemove, index=0, options=true, size="medium" }:PricesProps) => {
    const { t } = useTranslation()
    const changePrice = React.useCallback((name:string, value:number) => {
        const activeElement = document.activeElement
        if( activeElement !== null ){
            const activeElementId = activeElement.id
            if( activeElementId === "priceSalePrice" ){
                const profit = (value-cost)
                const margin = calcProfitPercentage(profit, value, true)
                if(onChange !== undefined){
                    onChange({ profit, sellPrice: value, percentageProfit: margin })
                }
                return
            }
            if( activeElementId === "priceProfitPrice" ){
                const sale = (cost+value)
                const margin = calcProfitPercentage(value, sale, true)
                if(onChange !== undefined){
                    onChange({ profit: value, sellPrice: sale, percentageProfit: margin })
                }
                return
            }
            if( activeElementId === "priceMarginPrice" ){
                const residualMargin = (100-value)/100
                const sale = cost/residualMargin
                const profit = sale-cost
                if(onChange !== undefined){
                    onChange({ profit, sellPrice: sale, percentageProfit: value })
                }
                return
            }
        }
    },[cost, onChange])
    return(
        <>
            <Grid xs={12} md={4} sm={4} >
                <NumberFormat size={size} id="priceSalePrice" name='salePrice' label={t('salePrice')!} value={sale} prefix='$ ' onChange={({ target }) => changePrice(target.name, Number(target?.value??0))} />
            </Grid>
            <Grid xs={12} md={4} sm={4} >
                <NumberFormat size={size} id="priceProfitPrice" name='profit' label={t('profit')!} value={profit} prefix='$ ' onChange={({ target }) => changePrice(target.name, Number(target?.value??0))} />
            </Grid>
            <Grid xs={12} md={4} sm={4} >
                <Box display={'flex'} alignItems={'center'} >
                    <NumberFormat size={size} id="priceMarginPrice" name='margin' label={t('margin')!} value={margin} suffix=' %' onChange={({ target }) => changePrice(target.name, Number(target?.value??0))} />
                    {((index > 0) && options) &&
                        <Box>
                            <Tooltip title={t('removePrice')} >
                                <IconButton color='error' onClick={() => onRemove && onRemove()} >
                                    <ClearRounded />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }
                </Box>
            </Grid>
        </>
    )
}

interface Props {
    editProduct?: ProductFormProps & {image: FileList[0] | null} | null
    onCreateFastProduct?: (product:ProductProps) => void
    defaultFields?: boolean
}

const ProductForm = ({ editProduct, defaultFields, onCreateFastProduct }:Props) => {

    const { t } = useTranslation()
    const { ProductState } = useAppSelector(({ ProductState }) => ({ ProductState }))
    const { createTypeForm } = ProductState
    const dispatch = useAppDispatch()
    const [form, setForm] = React.useState<ProductFormProps & {image: FileList[0] | null}>({...productForm})
    const { registerProduct, isLoadingRegisterProduct, isLoadingUpdateProduct } = useProducts({ onCreateFastProduct, skipAvailableProductCode: editProduct?.skuCode ? true : false })
    const { enqueueSnackbar } = useSnackbar()
    const { data:packagingProduct } = useGetPackagingProductQuery()

    const addNewPrice = () => {
        const prices = [...form.prices]
        if( prices.length < 3 ){
            prices.push({...productForm.prices[0]})
            setForm(n => ({...n, prices}))
        }else{
            enqueueSnackbar(t('youHaveReachedThePriceLimit')!,{variant: "warning"})
        }
    }
    const removePrice = (index:number) => {
        const priceList = [...form.prices]
        priceList.splice(index, 1)
        setForm(n => ({...n, prices: priceList}))
    }
    const handleChangePrice = (price:ProductFormProps['prices'][0], index:number) => {
        const priceList = [...form.prices]
        priceList[index] = price
        setForm(n => ({...n, prices: priceList}))
    }
    const handleSubmit = () => registerProduct(form)

    React.useEffect(() => {
        if( editProduct !== undefined && editProduct !== null ){
            setForm(editProduct)
        }
    },[editProduct])

    React.useEffect(() => {
        return () => {
            setForm({...productForm})
        }
    },[])

    const handleChangeSingleProduct = (_id:any, params:any) => {
        const packages = (packagingProduct?.payload?.productsPackaging?.results??[])
        const packagingSelected = packages.find((n:any) => n._id === form.packaging )
        setForm(n => ({...n, productParent: params._id, cost: (params?.cost??0)*(packagingSelected?.quantity??0) }))
    }

    if( createTypeForm === "product" ){
        return(
            <Slide in={true} mountOnEnter unmountOnExit direction='right' >
                <Form onSubmit={handleSubmit} >
                    <Grid container spacing={1} >
                        <Grid xs={12} md={12} sm={12} sx={{ display: 'flex', justifyContent:'center' }} >
                            <UploadImage valueFile={form.image} onChange={(value) => setForm(n => ({...n, image: value}))} />
                        </Grid>
                        <Grid xs={12} md={defaultFields ? 12 : 6} sm={defaultFields ? 12 : 6} >
                            <TextField name="name" onChange={({ target }) => setForm(n => ({...n, name: target.value}))} value={form.name} margin="none" label={t('nameOfProduct')} required autoFocus />
                        </Grid>
                        { !defaultFields &&
                            <>
                                <Grid xs={12} md={6} sm={6} >
                                    <Box display={'flex'} alignItems={'center'} >
                                        <ProductCategoriesInput productCategoryId={form.category} onChange={(value) => setForm(n => ({...n, category: value}))} />
                                        <Box>
                                            <Tooltip title={t('createNewCategory')} >
                                                <IconButton onClick={() => dispatch(toggleBetweenForms("category"))} >
                                                    <DataSaverOnRounded />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid xs={12} md={6} sm={6} >
                                    <TypeProductInput value={form.compount} onChange={() => setForm(n => ({...n, compount: !form.compount}))} />
                                </Grid>
                                <Grid xs={12} md={6} sm={6} >
                                    <Box display={'flex'} alignItems={'center'} >
                                        <PackagingProductInput label={form.compount ? "Cantidad que descuenta" : "Embalaje de compra"} productPackagingId={form.packaging} onChange={(value) => setForm(n => ({...n, packaging: value }))} />
                                        <Box>
                                            <Tooltip title={t('createNewPackaging')} >
                                                <IconButton onClick={() => dispatch(toggleBetweenForms("packaging"))} >
                                                    <DataSaverOnRounded />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Grid>
                                { form.compount &&
                                    <Grid xs={12} >
                                        <SimpleProductInput simpleProductId={form.productParent} onChange={handleChangeSingleProduct} />
                                    </Grid>
                                }
                                <Grid xs={12} md={6} sm={6} >
                                    <TaxInput value={form.tax} onChange={(_, params:any) => setForm(n => ({...n, tax: params?._id??""}))} />
                                </Grid>
                                <Grid xs={12} md={6} sm={6} >
                                    <InputSearchProviders value={form.provider} onChange={(_, params:any) => setForm(n => ({...n, provider: params?._id??""}))} />
                                </Grid>
                            </>
                        }
                        <Grid xs={12} md={6} sm={6} >
                            <NumberFormat disabled value={form.stock} label={t('stock')!} onChange={({ target }) => setForm(n => ({...n, stock: Number(target?.value??0) })) } />
                        </Grid>
                        <Grid xs={12} md={6} sm={6} >
                            <TextField name="skuCode" onChange={({ target }) => setForm(n => ({...n, skuCode: target.value}))} value={form.skuCode} margin="none" label={t('productCode')} />
                        </Grid>
                        { !defaultFields &&
                            <Grid xs={12} md={12} sm={12} >
                                {/* <TagsInput value={form.tags} onChange={(_, params:any) => setForm(n => ({...n, tags: params.map((i:any) => i?._id??"") }))} /> */}
                            </Grid>
                        }
                        <Grid xs={12} >
                            <Box display={'flex'} alignItems={'center'} >
                                <NumberFormat disabled={Boolean(form?.compount)} label={t('cost')!} value={form.cost} prefix='$ ' required onChange={({ target }) => setForm(n => ({...n, cost: Number(target?.value??0)}))} />
                                <Box>
                                    <Tooltip title={t('addAnotherPrice')} >
                                        <IconButton onClick={addNewPrice} >
                                            <DataSaverOnRounded />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Grid>
                        {form.prices.map((price, index) => {
                            return(
                                <Prices index={index} cost={Number(form.cost)} key={index} sale={Number(price.sellPrice)} margin={Number(price.percentageProfit)} profit={Number(price.profit)} onChange={(values) => handleChangePrice(values, index)} onRemove={() => removePrice(index)} />
                            )
                        })}
                        <Grid xs={12} >
                            <Button type='submit' loading={isLoadingRegisterProduct || isLoadingUpdateProduct} >
                                { Boolean(form?._id) ?
                                    "Guardar cambios"
                                    :
                                    t('createProduct')
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            </Slide>
        )
    }
    else if( createTypeForm === "category" ){
        return(
            <>
                <IconButton onClick={() => dispatch(toggleBetweenForms("product"))} >
                    <ArrowBackIosRounded />
                </IconButton>
                <Slide in={true} mountOnEnter unmountOnExit direction='left' >
                    <ProductCategoryForm />
                </Slide>
            </>
        )
    }
    else{
        return(
            <>
                <IconButton onClick={() => dispatch(toggleBetweenForms("product"))} >
                    <ArrowBackIosRounded />
                </IconButton>
                <Slide in={true} mountOnEnter unmountOnExit direction='left' >
                    <ProductPackagingForm />
                </Slide>
            </>
        )
    }
}

export default ProductForm