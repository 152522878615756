import Card from "@component/Card"
import Divider from "@component/Divider"
import List, { ListItem, ListItemText } from "@component/List"
import Typography from "@component/Typography"
import { currencyFormat } from "@hook/useNumberFormat"
import React from "react"
import { useGetBusinessFinancialStatement } from "@feature/Business/hooks/useBusiness"
import { useTranslation } from "react-i18next"

const DetailedLiabilities = ({ startDate="" }) => {
    const { t } = useTranslation()
    const { financialStatement } = useGetBusinessFinancialStatement({ startDate })
    const fixedCosts = financialStatement?.fixedCosts?.map((n) => ({ primaryText: `${t(n.expense)}`, secondaryText: "", value: (n?.total??0) }))||[]
    let totalFixedCosts = fixedCosts.reduce((acc, params) => (acc+params?.value??0),0)
    return(
        <>
            <Card sx={{ mb: 1 }} variant="elevation" elevation={4} >
                <List>
                    <Typography mt={1} fontSize={'1rem'} fontWeight={'bold'} textTransform={'uppercase'}  >Pasivos</Typography>
                    <ListItem sx={{ padding: 0 }} >
                        <ListItemText primaryTypographyProps={{ textTransform: "uppercase", fontSize: '0.875rem', fontWeight: "500" }} secondaryTypographyProps={{ fontSize: '0.875rem', fontWeight: "500", color: (theme) => theme.palette.error.main }} primary={"Pago proveedores"} secondary={`$ ${currencyFormat(financialStatement?.cxp??0, true)}`} />
                    </ListItem>
                    <Divider />
                    <ListItem sx={{ padding: 0 }} >
                        <ListItemText primaryTypographyProps={{ textTransform: "uppercase", fontSize: '0.875rem', fontWeight: "500" }} secondaryTypographyProps={{ fontSize: '0.875rem', fontWeight: "500", color: (theme) => theme.palette.error.main }} primary={"Anticipo clientes"} secondary={`$ ${currencyFormat(financialStatement?.advancePaymentCustomers??0, true)}`} />
                    </ListItem>
                    <Divider />
                    <ListItem sx={{ padding: 0 }} >
                        <ListItemText primaryTypographyProps={{ textTransform: "uppercase", fontSize: '0.875rem', fontWeight: "500" }} secondaryTypographyProps={{ fontSize: '0.875rem', fontWeight: "500", color: (theme) => theme.palette.error.main }} primary={"Gastos fijos"} secondary={`$ ${currencyFormat(totalFixedCosts??0, true)}`} />
                    </ListItem>
                    <Divider />
                </List>
            </Card>
        </>
    )
}

export default DetailedLiabilities