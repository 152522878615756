import { useTranslation } from 'react-i18next';
import EditRounded from '@mui/icons-material/EditRounded';
import { GridColDef } from '@mui/x-data-grid';

import { useGetPackagingProductQuery } from '../../../api/querys/Product';
import { loadProductPackagingToEdit, selectPackagingToDelete, showAlertDeletePackaging } from '../services/ProductPackagingSlice';

import { useAppDispatch, useAppSelector } from '../../../hooks/state';

import DataGrid from '../../../components/DataGrid';
import IconButton from '../../../components/IconButton';
import DataGridCustomHeaderToDelete from '../../../components/DataGridCustomHeaderToDelete';
import AlertDeletePackagingProduct from './AlertDeletePackagingProduct';
import { ProductPackaging } from '../../../interfaces/Product';

const ProductPackagingList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { ProductPackagingState } = useAppSelector(
    ({ ProductPackagingState }) => ({ ProductPackagingState })
  );

  const { selectionToDelete } = ProductPackagingState;

  const { data: packagingProductData, isLoading } =
    useGetPackagingProductQuery();

  const productPackaging =
    packagingProductData?.payload?.productsPackaging?.results ?? [];

  const handleDeletePackaging = () => {
    dispatch(showAlertDeletePackaging(true));
  };

  const handleEditPackaging = (row: ProductPackaging) => {
    dispatch(loadProductPackagingToEdit(row));
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('nameOfPackaging')!,
      flex: 1,
    },
    {
      field: 'quantity',
      headerName: t('quantityOfPackaging')!,
      flex: 1,
    },
    {
      field: ' ',
      align: 'center',
      maxWidth: 80,
      minWidth: 80,
      renderCell: ({ row }) => {
        return (
          <IconButton onClick={() => handleEditPackaging(row)}>
            <EditRounded />
          </IconButton>
        );
      },
    },
  ];

  const HeaderDataGrid = () => (
    <DataGridCustomHeaderToDelete
      listItems={selectionToDelete}
      texts={[t('selectedProductPackaging'), t('selectedOneProductPackaging')]}
      title=''
      onClick={handleDeletePackaging}
    />
  );

  const handleSelection = (items: any[]) => {
    dispatch(selectPackagingToDelete(items));
  };

  return (
    <>
      <DataGrid
        onRowSelectionModelChange={handleSelection}
        checkboxSelection
        disableRowSelectionOnClick
        rowHeight={30}
        rows={productPackaging}
        columns={columns}
        loading={isLoading}
        slots={{
          toolbar: HeaderDataGrid,
        }}
      />
      <AlertDeletePackagingProduct />
    </>
  );
};

export default ProductPackagingList;
