import { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '../../../components/Dialog';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import { useCity } from '../hooks/useCity';
import { showAlertDeleteCity } from '../services/CitySlice';

const AlertDeleteCity = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { CityState } = useAppSelector(({ CityState }) => ({
    CityState,
  }));
  const { isOpenAlertDeleteCity, selectionToDelete } =
    CityState;

  const { deleteCityMutation, deleteCityIsLoading } =
    useCity();

  const handleSubmit = () => {
    deleteCityMutation(selectionToDelete);
    handleClose();
  };

  const handleClose = () => {
    dispatch(showAlertDeleteCity(false));
  };

  const handleKeyDown = (evt: KeyboardEvent<HTMLDivElement>) => {
    const { keyCode, which } = evt;
    if (keyCode === 13 || which === 13) {
      handleSubmit();
    }
  };

  return (
    <Dialog
      open={isOpenAlertDeleteCity}
      onClose={handleClose}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>{t('deleteCity')}</DialogTitle>

      <DialogContent>
        <DialogContentText>{`¿${t(
          'areYouSureYouWantToPerformThisAction'
        )}?`}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant='text'
          color='error'
          type='submit'
          onClick={handleSubmit}
          loading={deleteCityIsLoading}
        >
          {t('remove')}
        </Button>
        <Button
          variant='text'
          onClick={handleClose}
        >
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDeleteCity;
