import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./styles/index.css";
import "./styles/icons/style.css";
import "./services/translate";
import "dayjs/locale/es";
import "dayjs/locale/en";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./services/store";
import Routes from "./services/routes";
import Customers from "./features/Customer";
import CustomerData from "./features/Customer/CustomerData";
import Home from "./features/Home";
import Employee from "./features/Employee";
import EmployeeData from "./features/Employee/EmployeeData";
import Inventory from "./features/Inventory";
import Products from "./features/Products";
import CustomerPurchaseTracking from "./features/CustomerPurchaseTracking";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ImportProducts from "./features/Products/ImportProducts";
import ProductCategories from "./features/ProductCategories";
import Permission from "./features/Permission";
import ReportOfTheDay from "./features/ReportOfTheDay";
import CreditReport from "./features/CreditReport";
import ClosingOfTheDay from "./features/ClosingOfTheDay";
import DeliveryReport from "./features/DeliveryReport";
import Invoice from "./features/Invoice";
import ProductData from "./features/Products/ProductData";
import BusinessProvider from "./features/Provider";
import ProviderData from "./features/Provider/ProviderData";
import Business from "./features/Business";
import PurchasingSuppliers from "./features/PurchasingSuppliers";
import MultiBox from "./features/MultiBox";
import ProductPackaging from "./features/ProductPackaging";
import DebtsToPay from "./features/DebtsToPay";
import CustomerZones from "./features/CustomerZones";
import CustomerNeighborhoods from "./features/CustomerNeighborhoods";
import CustomerBranch from "./features/CustomerBranch";
import CustomerCity from "./features/CustomerCity";
import CustomerBusinessesType from "./features/CustomerBusinessesType";
import Banks from "./features/Banks";
import BankData from "./features/Banks/components/BankData";
import BatchData from "./features/Products/components/BatchData";
import PurchaseReports from "@feature/PurchaseReport";
import PurchaseData from "@purchase_report/PurchaseData";
// import FixedCosts from "@feature/FixedCosts";
import Settings from "@feature/Settings";
import Cashiers from "@feature/Cashier";
import MovementsCashier from "@feature/MovementsCashier";
import Expenses from "@feature/Expenses";
import MinorCash from "@feature/MinorCash";
import MassProducts from "@feature/MassProducts";
import FixedCosts from "@feature/_FixedCosts";
import DiscountShopping from "@feature/DiscountShopping";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            { path: Routes.indexHome(), element: <Home /> },
            { path: Routes.indexCustomers(), element: <Customers /> },
            { path: Routes.customerData(), element: <CustomerData /> },
            { path: Routes.indexEmployee(), element: <Employee /> },
            { path: Routes.employeeData(), element: <EmployeeData /> },
            { path: Routes.indexInventory(), element: <Inventory /> },
            { path: Routes.inventoryByCategoryProducts(), element: <Inventory /> },
            { path: Routes.indexProducts(), element: <Products /> },
            { path: Routes.indexCustomerPurchaseTracking(), element: <CustomerPurchaseTracking />, },
            { path: Routes.indexImportProducts(), element: <ImportProducts /> },
            { path: Routes.indexProductCategory(), element: <ProductCategories /> },
            { path: Routes.indexProductPackaging(), element: <ProductPackaging /> },
            { path: Routes.indexPermissions(), element: <Permission /> },
            { path: Routes.indexReportOfTheDay(), element: <ReportOfTheDay /> },
            { path: Routes.indexCreditReport(), element: <CreditReport /> },
            { path: Routes.indexDeliveryReport(), element: <DeliveryReport /> },
            { path: Routes.indexClosingOfTheDay(), element: <ClosingOfTheDay /> },
            { path: Routes.indexInvoice(), element: <Invoice /> },
            { path: Routes.indexProduct(), element: <ProductData /> },
            { path: Routes.indexProviders(), element: <BusinessProvider /> },
            { path: Routes.indexProvider(), element: <ProviderData /> },
            { path: Routes.indexBank(), element: < BankData /> },
            { path: Routes.indexBusinessData(), element: <Business /> },
            { path: Routes.indexPurchasingSuppliers(), element: <PurchasingSuppliers />, },
            { path: Routes.indexMinorBox(), element: <MinorCash /> },
            { path: Routes.indexLargeBox(), element: <MultiBox box="largeBox" /> },
            { path: Routes.indexBankBox(), element: <MultiBox box="bank" /> },
            { path: Routes.indexCustomerZone(), element: <CustomerZones /> },
            { path: Routes.indexNeighborhoods(), element: <CustomerNeighborhoods /> },
            { path: Routes.indexCustomerBusinessesType(), element: <CustomerBusinessesType /> },
            { path: Routes.indexCustomerBranch(), element: <CustomerBranch /> },
            { path: Routes.indexCustomerCity(), element: <CustomerCity /> },
            { path: Routes.indexDebtsToPay(), element: <DebtsToPay /> },
            { path: Routes.indexBanks(), element: <Banks /> },
            { path: Routes.indexBatch(), element: <BatchData /> },
            { path: Routes.indexPurchaseReports(), element: <PurchaseReports /> },
            { path: Routes.indexPurchase(), element: <PurchaseData /> },
            { path: Routes.indexMasterBox(), element: <MultiBox box="masterBox" /> },
            { path: Routes.indexSetting(), element: <Settings /> },
            { path: Routes.indexFixedCosts(), element: <FixedCosts /> },
            { path: Routes.indexCashiers(), element: <Cashiers /> },
            { path: Routes.indexCashierMovements(), element: <MovementsCashier /> },
            { path: Routes.indexExpenses(), element: <Expenses /> },
            { path: Routes.indexMassProducts(), element: <MassProducts /> },
            { path: Routes.indexShoppingDiscounts(), element: <DiscountShopping /> },
        ],
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es" >
                <RouterProvider router={router} />
            </LocalizationProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
