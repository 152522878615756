import { AppSignals } from './../components/SignalProvider';
import { AppContext, ContextProviderStateProps } from './../components/ContextProvider';
import React, { useContext } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../services/store';
import { SignalAppState } from '@service/signalStore';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppSignalSelector = () => useContext<SignalAppState>(AppSignals as any)
export const useAppContextSelector = () => useContext<ContextProviderStateProps & {setGlobalState: React.Dispatch<React.SetStateAction<ContextProviderStateProps>>} >(AppContext as any)