import React from 'react'
import InputSearchProviders from '@feature/Provider/components/InputSearchProviders'
import Typography from '@component/Typography'
import { useAppDispatch, useAppSelector } from '@hook/state'
import { updatePerform, updateSelectedProvider } from '../services/PurchasesSuppliersSlice'
import { IconContainer } from '@feature/Invoice/components/SelectPaymentMethod'
import Grid from '@component/Grid'
import CurrencyExchangeRounded from '@mui/icons-material/CurrencyExchangeRounded';
import ShoppingCartRounded from '@mui/icons-material/ShoppingCartRounded';
import Box from '@component/Box'
import Form from '@component/Form'
import AddAdvanceToSupplier from './AddAdvanceToSupplier'

const ProviderSelection = () => {
    const dispatch = useAppDispatch()
    const { PurchasesSuppliersState } = useAppSelector(({ PurchasesSuppliersState }) => ({ PurchasesSuppliersState }))
    const { providerSelected } = PurchasesSuppliersState

    React.useEffect(() => {
        setTimeout(() => {
            const elem = document.getElementById("providerSelected") as HTMLInputElement
            if( elem !== null ){
                elem.focus()
            }
        }, 100)
    },[])

    const handleProviderSelected = async (params:any) => {
            dispatch(updateSelectedProvider({
                advance: params?.advancesAvailable??null,
                name: params?.name??"",
                tax: params?.tax??null,
                _id: params?._id
            }))
    }
    const handleSubmitForm = () => {
        const elem = document.activeElement
        const selection = elem?.id
        if( selection !== null && selection !== undefined ){
            if( selection === "purchase" ){
                dispatch(updatePerform("purchase"))
            }
            if( selection === "advance" ){
                dispatch(updatePerform("advance"))
            }
        }
    }
    return(
        <Form onSubmit={handleSubmitForm} >
            <Grid container spacing={1} >
                <Grid xs={12} >
                    <Typography fontWeight={'bold'} fontSize={'1rem'} textTransform={'uppercase'} >
                        Seleccione un proveedor para continuar
                    </Typography>
                </Grid>
                <Grid xs={12} >
                    <InputSearchProviders id='providerSelected' onChange={(_, params:any) => handleProviderSelected(params??null)} />
                </Grid>
                { providerSelected !== null &&
                    <Grid xs={12} sx={{ display:'flex', justifyContent: 'center'}} >
                        <Box maxWidth={150} minWidth={150}>
                            <IconContainer tabIndex={0} id="purchase" onClick={handleSubmitForm} >
                                <ShoppingCartRounded sx={{ fontSize: "5rem" }} color="primary" />
                                <Typography textTransform={'uppercase'} fontWeight={'bold'} fontSize={'1rem'} >
                                    Compra
                                </Typography>
                            </IconContainer>
                        </Box>
                        <Box maxWidth={150} minWidth={150}>
                            <IconContainer tabIndex={0} id="advance" onClick={handleSubmitForm} >
                                <CurrencyExchangeRounded sx={{ fontSize: "5rem" }} color="primary" />
                                <Typography textTransform={'uppercase'} fontWeight={'bold'} fontSize={'1rem'} >
                                    Anticipo
                                </Typography>
                            </IconContainer>
                        </Box>
                    </Grid>
                }
            </Grid>
            <AddAdvanceToSupplier />
        </Form>
    )
}

export default ProviderSelection