import { EditPurchaseFieldValueAsyncResponse, EditPurchaseFieldValueParamsProps, GetPurchaseDataProps, GetPurchasesOfTheDayToSupplier, InvoicesByConsecutiveNumberAsyncResponse, PurchasingReportPropsResponse, RecordPaymentToPurchaseResponse, RecordPurchasingDataAsyncResponse } from "@interface/Purchasing"
import { RecordPaymentPurchaseProps } from "@interface/ReportPurchase"
import { PurchasingSuppliersTotalResumeResponse } from "../../interfaces/PurchasingSuppliers"
import { api, mutationConfig } from "../splitApi"

const PurchasingSuppliers = api.injectEndpoints({
    endpoints: (build) => ({
        getPurchase: build.query<GetPurchaseDataProps, {purchaseId: string}>({
            query: ({ purchaseId }) => ({ url: '/get/purchase/from/supplier', params: {purchaseId} }),
            providesTags: ['PurchasingSuppliers']
        }),
        getInformationOfTheTotalPurchase: build.query<PurchasingSuppliersTotalResumeResponse, {purchaseId: string}>({
            query: ({ purchaseId }) => ({ url: '/get/information/of/the/total/purchase', params: {purchaseId} }),
            providesTags: ['PurchasingSuppliers']
        }),
        getPurchasesFromSupplierBusiness: build.query<PurchasingReportPropsResponse, { startDate: string, endDate: string, filterBy?: "expirationDate" }>({
            query: ({ startDate, endDate, filterBy }) => ({
                url: '/get/purchases/from/supplier/business',
                params: { startDate, endDate, filterBy }
            }),
            providesTags: ['PurchasingSuppliers']
        }),
        recordPurchaseFromSupplier: build.mutation<RecordPurchasingDataAsyncResponse, FormData>({
            query(body) {
                return {...mutationConfig, url: '/record/purchase/from/supplier', body}
            },
            invalidatesTags: ['Provider', 'PurchasingSuppliers', 'MinorBox', 'MultiBox', 'Expense', 'Product']
        }),
        sendPaymentToPurchase: build.mutation<RecordPaymentToPurchaseResponse, RecordPaymentPurchaseProps[]>({
            query(body) {
                return { ...mutationConfig, url: '/send/payment/to/purchase', body }
            },
            invalidatesTags: ['Provider', 'PurchasingSuppliers', 'MinorBox', 'MultiBox', 'Expense']
        }),
        getPurchasesOfTheDayToSupplier: build.query<GetPurchasesOfTheDayToSupplier, {providerId: string}>({
            query: ({ providerId }) => ({ url: '/get/purchases/of/the/day/to/supplier', params: {providerId} }),
        }),
        updatePurchaseInformation: build.mutation<RecordPurchasingDataAsyncResponse, FormData>({
            query(body) {
                return {...mutationConfig, url: '/update/purchase/information', body}
            },
            invalidatesTags: ['Provider', 'PurchasingSuppliers', 'MinorBox', 'MultiBox', 'Expense', 'Product']
        }),
        deletePurchase: build.mutation<any, { purchaseId: string }>({
            query(body) {
                return {...mutationConfig, url: '/delete/purchase', body}
            },
            invalidatesTags: ['Provider', 'PurchasingSuppliers', 'MinorBox', 'MultiBox', 'Expense', 'Product']
        }),
        changeMethodOfPaymentOfThePurchaseInvoice: build.mutation<any, { purchasesId: string, paymentMethod: string }>({
            query(body) {
                return {...mutationConfig, url: '/change/method/of/payment/of/the/purchase/invoice', body}
            },
        }),
        editPurchaseFieldValue: build.mutation<EditPurchaseFieldValueAsyncResponse, EditPurchaseFieldValueParamsProps>({
            query(body) {
                return {...mutationConfig, url: '/edit/purchase/field/value', body}
            },
            invalidatesTags: ['Provider', 'PurchasingSuppliers']
        }),
        getInvoicesByConsecutiveNumber: build.query<InvoicesByConsecutiveNumberAsyncResponse, {consecutive: string | null}>({
            query: ({ consecutive }) => ({ url: '/get/invoices/by/consecutive/number', params: {consecutive} }),
            providesTags: ['PurchasingSuppliers']
        }),
        generateShoppingDiscount: build.mutation<any, { purchasesId: string[] }>({
            query(body) {
                return {...mutationConfig, url: '/generate/shopping/discount', body}
            },
            invalidatesTags: ['PurchasingSuppliers']
        }),
        removePurchaseDiscount: build.mutation<any, { discountId: string }>({
            query(body) {
                return {...mutationConfig, url: '/remove/purchase/discount', body}
            },
            invalidatesTags: ['PurchasingSuppliers']
        }),
        getDiscountsOnPurchases: build.query<any, void>({
            query: () => ({ url: '/get/discounts/on/purchases'}),
            providesTags: ['PurchasingSuppliers']
        }),
    }),
    overrideExisting: false,
})

export const {
    useRecordPurchaseFromSupplierMutation,
    useGetPurchasesFromSupplierBusinessQuery,
    useGetInformationOfTheTotalPurchaseQuery,
    useGetPurchaseQuery,
    useSendPaymentToPurchaseMutation,
    useGetPurchasesOfTheDayToSupplierQuery,
    useUpdatePurchaseInformationMutation,
    useDeletePurchaseMutation,
    useChangeMethodOfPaymentOfThePurchaseInvoiceMutation,
    useEditPurchaseFieldValueMutation,
    useGetInvoicesByConsecutiveNumberQuery,
    useGenerateShoppingDiscountMutation,
    useRemovePurchaseDiscountMutation,
    useGetDiscountsOnPurchasesQuery,
} = PurchasingSuppliers