import type { LoginForm, UserFormRegister } from "@interface/User"

export const validateRegisterForm = (registerForm:UserFormRegister) => {
    const data = {...registerForm}
    let errors:{[key:string]: boolean} = {}

    if( data.name.trim() === "" ) errors['name'] = true
    if( data.lastName.trim() === "" ) errors['lastName'] = true
    if( data.email.trim() === "" ) errors['email'] = true
    if( data.password.trim() === "" ) errors['password'] = true
    if( data.repeatPassword.trim() === "" ) errors['repeatPassword'] = true
    
    return errors
}

export const validateLoginForm = (loginForm:LoginForm) => {
    const data = {...loginForm}
    let errors:{[key:string]: boolean} = {}

    if( data.email.trim() === "" ) errors['email'] = true
    if( data.password.trim() === "" ) errors['password'] = true

    return errors
}

export const validateDoublePassword = (registerForm:UserFormRegister) => registerForm.password === registerForm.repeatPassword