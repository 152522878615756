import Box from "@component/Box"
import IconButton from "@component/IconButton"
import Tooltip from "@component/Tooltip"
import { useAppContextSelector } from "@hook/state"
import { LocalAtmRounded } from "@mui/icons-material"
import { Badge } from "@mui/material"
import React from "react"
import PaymentPurchases from "./components/PaymentPurchases"

const ButtonMakePurchasePayment = () => {
    const [ anchorEl, setAnchorEl ] = React.useState<HTMLButtonElement | null>(null)
    const { PurchaseReportContext } = useAppContextSelector()
    const { selectedShoppingList } = PurchaseReportContext
    const lengthElem = selectedShoppingList.reduce((acc, params) => (params?.length??0)+acc,0)
    const handleOpen = () => {
        const elem = document.getElementById("payment-purchase") as HTMLButtonElement
        if( Boolean(elem) ){
            setAnchorEl(elem)
        }
    }

    return(
        <>
            <Box>
                <Tooltip title="Realizar pagos" >
                    <IconButton squareButton disabled={lengthElem === 0} color="primary" id={`payment-purchase`} onClick={handleOpen} >
                        <Badge badgeContent={lengthElem} color="error" >
                            <LocalAtmRounded />
                        </Badge>
                    </IconButton>
                </Tooltip>
            </Box>
            <PaymentPurchases anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
        </>
    )
}

export default ButtonMakePurchasePayment