import { FormControlLabel as FormControlLabelMUI, FormControlLabelProps } from '@mui/material'

interface Props extends Pick<FormControlLabelProps, "control" | "label" | "onChange" | "sx" > {}

const FormControlLabel = ({ ...rest }:Props) => {
    return(
        <FormControlLabelMUI {...rest} />
    )
}

export default FormControlLabel