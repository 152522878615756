import React from "react"
import Box from "@component/Box"
import Grid from "@component/Grid"
import Table, { TableBody, TableCell, TableHead, TableRow } from "@component/Table"
import { useAppSelector } from "@hook/state"
import { currencyFormat } from "@hook/useNumberFormat"
import { usePurchasingTools } from "../hooks/usePurchasing"
import Swal from 'sweetalert2'
import Typography from "@component/Typography"

const FooterSelectedProducts = () => {
    const [alertNumbers, setAlertNumber] = React.useState<number>(0)
    const { PurchasesSuppliersState } = useAppSelector(({ PurchasesSuppliersState }) => ({ PurchasesSuppliersState }))
    const { productsSelectedList, providerSelected } = PurchasesSuppliersState
    const { calcTotalPurchasing } = usePurchasingTools()
    const { subTotal, total, totalIva, iva, reteFuente, totalFreight } = calcTotalPurchasing(productsSelectedList, providerSelected)

    React.useEffect(() => {
        if ((alertNumbers === 0) && (reteFuente >= 1)) {
            Swal.fire({
                icon: 'info',
                text: `Se aplicara retención en esta y otras facturas existentes del dia de hoy pertenecientes a ${providerSelected?.name??""}.`,
            })
            setAlertNumber((n) => n + 1)
        }
    }, [reteFuente, alertNumbers])
    return (
        <>
            <Grid container >
                <Grid xs={12} >
                    <Typography variant="body2" textTransform={'uppercase'} > Total fletes: $ {currencyFormat(totalFreight)} </Typography>
                </Grid>
                <Grid xs={8} >
                    <Table sx={{ maxWidth: 600 }} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Impuesto</TableCell>
                                <TableCell align="left">Base</TableCell>
                                <TableCell align="left">Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {iva?.map((row) => (
                                <TableRow key={row._id}>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{`$ ${currencyFormat(row.base)}`}</TableCell>
                                    <TableCell align="left">{`$ ${currencyFormat(row.total)}`}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid xs={4} >
                    <Box display={'flex'} flexDirection={'column'} >
                        <Table size="small" >
                            <TableBody>
                                <TableRow>
                                    <TableCell rowSpan={4} />
                                    <TableCell colSpan={2}>Subtotal</TableCell>
                                    <TableCell align="right">{`$ ${currencyFormat(subTotal)}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2}>IVA</TableCell>
                                    <TableCell align="right">{`$ ${currencyFormat(totalIva)}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2}>Retención</TableCell>
                                    <TableCell align="right">{`$ ${currencyFormat(reteFuente)}`}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: "bold" }} colSpan={2}>Total</TableCell>
                                    <TableCell sx={{ fontWeight: "bold" }} align="right">{`$ ${currencyFormat(total)}`}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default FooterSelectedProducts