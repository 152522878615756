import Box from "@component/Box"
import Card from "@component/Card"
import Typography from "@component/Typography"
import { styled } from "@mui/material/styles"
import { DataGrid } from "@mui/x-data-grid"

const DataGridStyled = styled(DataGrid)(() => ({
    border: 'none',
    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-row:hover .MuiDataGrid-cell:focus': {
        outline: 'none',
        border: 'none',
    },
    '& .MuiDataGrid-columnHeaders' :{
        display: 'none'
    },
    '& .MuiDataGrid-footerContainer' :{
        display: 'none'
    },
}))

const ListDataGrid = ({ loading=undefined, sx={}, title="", columns=[], rows=[], getRowId=(params) => (params?._id??"") }) => {
    return(
        <>
            <Card elevation={0} sx={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0, ...sx}} >
                <Typography sx={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '.875rem' }} mb={1} > {title} </Typography>
                <Box height={180} sx={{ overflowY: 'auto' }} >
                    <DataGridStyled
                        loading={loading}
                        getRowId={getRowId}
                        autoHeight
                        columns={columns}
                        rows={rows}
                        rowHeight={35}
                    />
                </Box>
            </Card>
        </>
    )
}

export default ListDataGrid