import React from "react"
import * as XLSX from 'xlsx'

interface CreateSheetProps {
    rows?: any[]
    columns?: string[]
    name?: string
    fileName?: string
    max_width?: (w:any, r:any) => number
    arrayCollection?: boolean
}

export const useSheet = () => {
    const useCreateSheet = React.useCallback(({ rows=[], columns=[], name="", fileName="default.xlsx", max_width, arrayCollection = false }:CreateSheetProps) => {
        const worksheet = arrayCollection ? XLSX.utils.aoa_to_sheet(rows) : XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();

        if( max_width !== undefined && arrayCollection === false){
            const max_width_cell = rows.reduce(max_width, 10);
            worksheet["!cols"] = [ { wch: max_width_cell } ];
        }

        if( arrayCollection === false ) {
            XLSX.utils.sheet_add_aoa(worksheet, [[...columns]], { origin: "A1" })
        }

        XLSX.utils.book_append_sheet(workbook, worksheet, name);
        XLSX.writeFile(workbook, fileName, { compression: true });
    },[])

    const useReadBook = React.useCallback((event:React.ChangeEvent<HTMLInputElement>, callback?: (result:any) => void) => {
        const { files } = event.target
        let fileReader = new FileReader();
        let data:any = [];
        fileReader.onload = (event) => {
            try{
                const { result } = event.target!;
                const workbook = XLSX.read(result, { type: 'binary' });
                for (const sheet in workbook.Sheets) {
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        break;
                    }
                }
                if( callback !== undefined ) callback(data)
            }catch(err){
                console.log(err);
            }
        }
        fileReader.readAsBinaryString(files![0]);
        return data
    },[])
    return { useCreateSheet, useReadBook }
}