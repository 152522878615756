import { TextField as TextFieldMUI, TextFieldProps, OutlinedInputProps } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'


const CustomTextField = styled((props: TextFieldProps) => (
    <TextFieldMUI
        InputProps={{} as Partial<OutlinedInputProps>}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiFilledInput-root': {
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-focused': {
            backgroundColor: 'transparent',
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
    },
}));
type Props = Pick<TextFieldProps, "helperText" | "placeholder" | "onBlur" | "onFocus" | "onChange" | "value" | "variant" | "label" | "fullWidth" | "margin" | "type" | "autoFocus" | "inputProps" | "InputProps" | "name" | "error" | "multiline" | "rows" | "required" | "disabled" | "size" | "id" | "onKeyDown" >

const TextField = ({variant="filled", fullWidth=true, margin="dense", type="text", ...rest}: Props) => {
    return (
        <CustomTextField variant={variant} fullWidth={fullWidth} margin={margin} type={type} {...rest} />
    )
}

export default TextField