import React from 'react'
import { useURLParams } from "@hook/useURLParams"
import { usePurchaseData, usePurchaseReport } from "../hooks/usePurchaseReport"
import Dialog, { DialogActions, DialogContent } from "@component/Dialog"
import DatePicker from "@component/DatePicker"
import dayjs from "dayjs"
import Button from "@component/Button"
import Form from '@component/Form'

const EditPurchaseData = () => {
    const { editPurchaseFieldValue } = usePurchaseReport()
    const [newValue, setNewValue] = React.useState<any>("")
    const { getParameterGroup, delQueries } = useURLParams()
    const { ref=null, modal=false, field="" } = getParameterGroup(["_id", "open_modal", "field"])
    const { report } = usePurchaseData({ purchaseId: ref! })
    const result = React.useMemo(() => report, [report])

    React.useEffect(() => {
        if( Boolean(result?._id) ){
            setNewValue((result?.dueDate??""))
        }
    },[result])

    const handleSubmit = () => editPurchaseFieldValue({ field, newValue, purchaseId: ref })

    return(
        <>
            <Dialog maxWidth="xs" open={(modal === "edit_purchase_data")} fullWidth onClose={() => delQueries(["field", "modal", "ref"])} >
                <Form onSubmit={handleSubmit} >
                    <DialogContent>
                        { field === "dueDate" &&
                            <DatePicker
                                minDate={null}
                                maxDate={null}
                                label="Fecha de vencimiento"
                                fullWidth
                                value={ dayjs(newValue).toDate() }
                                onChange={({ value }) => setNewValue(dayjs(value).toDate()) }
                            />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" type='submit' >
                            Guardar
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    )
}

export default EditPurchaseData