import React from 'react'
import Button from "@component/Button"
import Dialog, { DialogActions, DialogContent } from "@component/Dialog"
import Form from "@component/Form"
import Grid from "@component/Grid"
import BoxPayments from "@feature/Business/components/BoxPayments"
import { useURLParams } from "@hook/useURLParams"
import { LocalAtmRounded } from "@mui/icons-material"
import { useAccountsPayable, useGetDebtsToPay } from "../hooks/useAccountsPayable"
import Typography from "@component/Typography"
import { currencyFormat } from "@hook/useNumberFormat"
import { PayAllAccountPayableProps } from '@interface/DebtsToPay'
import Swal from 'sweetalert2'

const PayTheEntireBill = () => {
    const [ payment, setPayment ] = React.useState<PayAllAccountPayableProps>({ payment: [], tag: "" })
    const { getQuery, delQueries } = useURLParams()
    const { debtsToPay } = useGetDebtsToPay({ only: "balance", show: "results" })
    const paymentAll = getQuery("modal")
    const tag = getQuery("tag")
    const results = debtsToPay.find((n) => n._id === tag)?.results??[]
    const totals = results.reduce((acc, params) => (params?.amount??0)+acc,0)
    const { payTheEntireBill, isLoadingPayTheEntireBillMutation } = useAccountsPayable()

    const handleSubmit = () => {
        const totalPayment = payment.payment.reduce((acc, params) => (params?.payment??0)+acc,0)
        if( totalPayment > totals){
            Swal.fire({
                icon: 'warning',
                text: `Estas intentando pagar una cuenta por $ ${currencyFormat(totals)} con un monto de $ ${currencyFormat(totalPayment)}, verifica los montos he intenta nuevamente.`
            })
            return
        }
        if( payment.payment.length <= 0 ){
            Swal.fire({ icon: 'warning', text: `Seleccione una caja y un monto he intente nuevamente` })
            return
        }
        if( !Boolean(payment.tag) && payment.tag.trim() === "" ){
            Swal.fire({ icon: 'error', text: `Upps parece que ha ocurrido algún error` })
            return
        }

        payTheEntireBill(payment)
    }

    React.useEffect(() => {
        setPayment(n => ({...n, tag: tag??""}))
    },[tag])

    return(
        <Dialog fullWidth open={(paymentAll === "paymentAll")} onClose={() => delQueries(['modal', 'tag'])} >
            <DialogContent>
                <Form onSubmit={handleSubmit} >
                    <Grid container >
                        <Grid xs={12} >
                            <Typography textTransform={'uppercase'} fontWeight={'bold'} mb={1} > Total a pagar: $ {currencyFormat(totals)} </Typography>
                        </Grid>
                        <Grid xs={12} >
                            <BoxPayments maxPayments={1} maxPayment={totals} onChange={(params) => setPayment(n => ({...n, payment: params}))} />
                        </Grid>
                    </Grid>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button loading={isLoadingPayTheEntireBillMutation} onClick={handleSubmit} variant="text" startIcon={<LocalAtmRounded />} >
                    Pagar cuentas
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PayTheEntireBill