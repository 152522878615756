import { Boxes } from "@interface/App"
import { BoxCashBalanceAsyncResponse, BoxMovementsAsyncResponse, FilterBoxMovementsProps, RemoveCashMovementParams } from "@interface/Box"
import { useGetBoxMovementsQuery, useGetBoxQuery, useRemoveCashMovementMutation } from "@query/LargeBox"
import { useTranslation } from "react-i18next"
import Swal from "sweetalert2"


export const useBox = () => {
    const { t } = useTranslation()
    const [ removeCashMovementMutation, { isLoading:isLoadingRemoveCashMovementMutation } ] = useRemoveCashMovementMutation()

    const removeCashMovement = async (payload:RemoveCashMovementParams) => {
        try {
            const resp = await removeCashMovementMutation(payload).unwrap()
            if( resp.status === 1 ){
                Swal.fire({
                    icon: 'success',
                    text: 'El movimiento se elimino satisfactoriamente, se ha creado un nuevo registro de movimiento.'
                })
            }else{
                Swal.fire({
                    icon: 'error',
                    text: t('anErrorOccurredWhileTryingToProcessTheRequest')!
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        removeCashMovement,
        isLoadingRemoveCashMovementMutation
    }
}


type useGetBoxMovementsProps = FilterBoxMovementsProps

export const useGetBoxMovements = (params:useGetBoxMovementsProps) => {
    const { data, isLoading } = useGetBoxMovementsQuery(params)
    let movements = data?.payload??[] as BoxMovementsAsyncResponse['payload']
    return { movements, isLoading }
}

export const useGetBox = (params: { box: Boxes, bank?:string }) => {
    const { data, isLoading } = useGetBoxQuery(params)
    let box = data?.payload??{} as BoxCashBalanceAsyncResponse['payload']

    return { box, isLoading }
}