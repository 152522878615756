import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetEmployeeQuery } from '../../../api/querys/Employee';
import Box from "@component/Box"
import Divider from "@component/Divider"
import Fade from "@component/Fade"
import Grid from "@component/Grid"
import Switch from "@component/Switch"
import TextField from "@component/TextField"
import Typography from "@component/Typography"
import AllowLogin from './AllowLogin';
import ChangeAccessPassword from "./ChangeAccessPassword"
import ChangeRole from './ChangeRole';


const EmployeeSettings = () => {
    const { employeeId } = useParams()
    const { data } = useGetEmployeeQuery(employeeId!, { refetchOnMountOrArgChange: true })
    const employee = data?.payload
    const { t } = useTranslation()

    return(
        <Fade in={true} >
            <Grid container spacing={2} >
                <Grid xs={12} >
                    <Box mb={1} >
                        <Typography textTransform={'uppercase'} fontWeight={'500'} color={'GrayText'} > {t('security')} </Typography>
                        <Divider />
                    </Box>
                    <Box my={1} >
                        <AllowLogin />
                    </Box>
                    <Box my={1} >
                        <ChangeAccessPassword allowLogin={employee?.allowLogin!} />
                    </Box>                    
                    <Box my={1} >
                        <ChangeRole value={employee?.rol?._id??""} />
                    </Box>
                </Grid>
                <Grid xs={12} >
                    <Box mb={1} >
                        <Typography textTransform={'uppercase'} fontWeight={'500'} color={'GrayText'} > {t('employeeSession')} </Typography>
                        <Divider />
                    </Box>
                    <Box my={1} >
                        <Switch label={t('blockEmployee')} />
                    </Box>
                    <Box my={1} >
                        <TextField label={t('numberOfConcurrentSessions')} />
                    </Box>
                </Grid>
            </Grid>
        </Fade>
    )
}

export default EmployeeSettings