import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '../../../components/Dialog';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import { showAlertDeletePackaging } from '../services/ProductPackagingSlice';
import { usePackagingProducts } from '../hooks/usePackagingProducts';

type Event = {
  keyCode: number;
  which: number;
};

const AlertDeletePackagingProduct = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { ProductPackagingState } = useAppSelector(
    ({ ProductPackagingState }) => ({ ProductPackagingState })
  );
  const { isOpenAlertDeletePackagingProduct, selectionToDelete } =
    ProductPackagingState;
  const { deletePackagingProductMutation } = usePackagingProducts();

  const handleSubmit = () => {
    deletePackagingProductMutation(selectionToDelete);
    handleClose();
  };

  const handleClose = () => {
    dispatch(showAlertDeletePackaging(false));
  };

  const handleKeyUDown = (evt: Event) => {
    const { keyCode, which } = evt;
    if (keyCode === 13 || which === 13) {
      handleSubmit();
    }
  };

  return (
    <Dialog
      open={isOpenAlertDeletePackagingProduct}
      onClose={handleClose}
      onKeyDown={handleKeyUDown}
    >
      <DialogTitle>{t('deletePackagingProduct')}</DialogTitle>

      <DialogContent>
        <DialogContentText>{`¿${t(
          'areYouSureYouWantToPerformThisAction'
        )}?`}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant='text'
          color='error'
          type='submit'
          onClick={handleSubmit}
        >
          {t('remove')}
        </Button>
        <Button
          variant='text'
          onClick={handleClose}
        >
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDeletePackagingProduct;
