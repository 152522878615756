import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { useGetBankQuery } from "../../../api/querys/Bank"
import { IBankProps } from "@interface/Bank"
import BankForm from "./BankForm"

const EditBank = () => {
    const { bankId } = useParams()
    const { data } = useGetBankQuery({ bankId: bankId! })
    const editBank: IBankProps = useMemo(() => {
        const bank = data?.payload
        const form: IBankProps = {
            _id: bank?._id ?? "",
            name: bank?.name ?? "",
            account: bank?.account ?? "current",
            accountNumber: bank?.accountNumber ?? "",
            accountType: bank?.accountType ?? "legal",
        }
        return form
    }, [data?.payload])
    return <BankForm editBank={editBank} />
}

export default EditBank