import React from 'react';
import { useTranslation } from "react-i18next"
import { useGetBusinessProductsQuery } from "../../../api/querys/Product"
import Autocomplete from "@component/Autocomplete"

interface Props extends Omit<React.ComponentProps<typeof Autocomplete>, "loading" | "options" | "label" > {}

const SearchProductsInput = ({ ...rest }:Props) => {
    const { t } = useTranslation()
    const { data, isLoading } = useGetBusinessProductsQuery()
    const products = data?.payload?.products?.results??[]
    React.useEffect(() => {
        setTimeout(() => {
            let elem = document.getElementById("searchProductsInput") as HTMLInputElement
            if( elem !== null ){
                elem.focus()
            }
        }, 150)
    },[])
    return(
        <Autocomplete id='searchProductsInput' {...rest} label={t('searchProduct')!} options={products} loading={isLoading} />
    )
}

export default SearchProductsInput