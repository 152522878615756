import { useTranslation } from "react-i18next"
import Button from "@component/Button"
import Dialog, { DialogActions, DialogContent } from "@component/Dialog"
import Typography from "@component/Typography"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { useProvider } from "../hooks/useProvider"
import { showAlertDeleteProvider } from "../services/ProviderSlice"

const AlertDeleteProvider = () => {
    const { t } = useTranslation()
    const { ProviderState } = useAppSelector(({ ProviderState }) => ({ ProviderState }))
    const { isOpenAlertDeleteProvider, selectedProviders } = ProviderState
    const dispatch = useAppDispatch()
    const { deleteProviders } = useProvider()

    return(
        <Dialog open={isOpenAlertDeleteProvider} onClose={() => dispatch(showAlertDeleteProvider(false)) } >
            <DialogContent sx={{ pb: 0 }} >
                <Typography> ¿{t('areYouSureYouWantToPerformThisAction')}? </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="text" color="error" onClick={() => deleteProviders(selectedProviders)} > {t('remove')} </Button>
                <Button variant="text" onClick={() => dispatch(showAlertDeleteProvider(false))} > {t('cancel')} </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AlertDeleteProvider