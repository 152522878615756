import Fade from "@component/Fade"
import Grid from "@component/Grid"
import PurchaseInvoice from "../PurchaseInvoice"
import PurchaseInvoiceOptions from "./components/PurchaseInvoiceOptions"
import { useParams } from "react-router-dom"
import PurchaseDetails from "./components/PurchaseDetails"
import EditPurchaseData from "./components/EditPurchaseData"

const PurchaseData = () => {
    const { purchaseId } = useParams()
    return(
        <Fade in={true} >
            <Grid container spacing={1} >
                <Grid xs={8} sx={{ display:'flex', alignItems: 'center', flexDirection: 'column' }} >
                    <PurchaseInvoiceOptions purchaseId={purchaseId!} />
                    <PurchaseInvoice />
                </Grid>
                <Grid xs={4} >
                    <PurchaseDetails />
                </Grid>
                <EditPurchaseData />
            </Grid>
        </Fade>
    )
}

export default PurchaseData