import { useSnackbar } from "notistack";
import React from "react"
import { useTranslation } from "react-i18next";

interface Props {
    errorCode: number,
    respStatus:number,
    key:string,
    keyValue?: object
}

type implicit = { [key:string] : string }

const DBErrors:implicit = {
    11000: 'duplicateKey'
}

export const useMessageErrors = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation()

    const messageErrors = React.useCallback(({errorCode, respStatus, key, keyValue}:Props) => {
        if( respStatus === 0 && Boolean(key)){
            enqueueSnackbar(t(key),{ variant: 'error' })
            return
        }
        if( Boolean(DBErrors[errorCode]) ){
            enqueueSnackbar(t(DBErrors[errorCode],{...keyValue}),{ variant: 'error' })
            return
        }
    },[enqueueSnackbar, t])

    return { messageErrors }
}