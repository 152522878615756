import React from 'react';
import { useTranslation } from "react-i18next"
import { useGetProvidersByBusinessQuery } from "../../../api/querys/Provider"
import Autocomplete from "@component/Autocomplete"

type Props = Omit<React.ComponentProps<typeof Autocomplete>, "options" | "label" | "loading" | "value" > & { value?: string }

const InputMultipleProviders = ({ ...rest }:Props) => {
    const { t } = useTranslation()
    const { data, isLoading } = useGetProvidersByBusinessQuery()
    const providers = data?.payload??[]

    return(
        <>
            <Autocomplete
                ChipProps={{
                    size: "small",
                    variant: "outlined",
                    color: "primary"
                }}
                limitTags={1}
                multiple
                loading={isLoading}
                {...rest}
                options={providers}
                label={t('providers')!}
            />
        </>
    )
}

export default InputMultipleProviders