import dayjs from "dayjs"
import { useSnackbar } from "notistack"
import React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useGetEmployeeQuery } from "../../../../api/querys/Employee"
import Box from "../../../../components/Box"
import Checkbox from "../../../../components/Checkbox"
import Divider from "../../../../components/Divider"
import Grid from "../../../../components/Grid"
import Switch from "../../../../components/Switch"
import TimePicker from "../../../../components/TimePicker"
import Typography from "../../../../components/Typography"
import { useAppDispatch, useAppSelector } from "../../../../hooks/state"
import { EmployeeProps } from "@interface/Employee"
import { toggleWorkDay, updateFirstWorkingDay, updateSecondWorkingDay } from "../../services/PaySheetSlice"

interface Props {
    setDataInitialState: React.Dispatch<React.SetStateAction<Partial<Pick<EmployeeProps, "baseSalary" | "cutForPayments" | "startOfTheDay" | "endOfDay">>>>
}

const WorkDay = ({ setDataInitialState }:Props) => {
    const dispatch = useAppDispatch()
    const { PaySheetState } = useAppSelector(({ PaySheetState }) => ({ PaySheetState }))
    const { workDays } = PaySheetState
    const { t } = useTranslation()
    const { employeeId } = useParams()
    const { data } = useGetEmployeeQuery(employeeId!)
    const employee = data?.payload


    return(
        <Grid container >
            <Grid xs={12} >
                {workDays.map((day, index) => {
                    return(
                        <Box key={index} >
                            <Grid container spacing={2} >
                                <Grid xs={3} >
                                    <Switch label={t(day.day)} checked={day.active} onChange={() => dispatch(toggleWorkDay({ day: day.day, isActive: !day.active }))} />
                                </Grid>
                                <Grid xs={9} >
                                    <Box display={'flex'} flexDirection={'column'} >
                                        <Typography fontWeight={'500'} lineHeight={0} fontSize=".9rem" mt={2} color={'GrayText'} >Primera jornada</Typography>
                                        <Box display={'flex'} gap={1} >
                                            <TimePicker disabled={!day.active} label="Inicia" onChange={(date:any) => dispatch(updateFirstWorkingDay({ day: day.day, time: "startTime", value: dayjs(date).toDate() }))} value={day.firstWorkingDay.startOfActivity} />
                                            <TimePicker disabled={!day.active} label="Finaliza" onChange={(date:any) => dispatch(updateFirstWorkingDay({ day: day.day, time: "endTime", value: dayjs(date).toDate() }))} value={day.firstWorkingDay.finishActivity} />
                                        </Box>
                                        <Typography fontWeight={'500'} lineHeight={0} fontSize=".9rem" mt={2} color={'GrayText'} >Segunda jornada</Typography>
                                        <Box display={'flex'} gap={1} >
                                            <TimePicker disabled={!day.active} label="Inicia" onChange={(date:any) => dispatch(updateSecondWorkingDay({ day: day.day, time: "startTime", value: dayjs(date).toDate() }))} value={day.secondWorkingDay.startOfActivity} />
                                            <TimePicker disabled={!day.active} label="Finaliza" onChange={(date:any) => dispatch(updateSecondWorkingDay({ day: day.day, time: "startTime", value: dayjs(date).toDate() }))} value={day.secondWorkingDay.finishActivity} />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    )
                })}
            </Grid>
            {/* <Grid xs={12} >
                <Typography sx={{ textDecoration: 'underline' }} lineHeight={0} padding={0} fontWeight={'500'} fontSize={".8rem"} color={'GrayText'} >Primera jornada del dia</Typography>
            </Grid>
            <Grid xs={6} sx={{ paddingTop: 0 }} >
                <TimePicker label="Inicia jornada" onChange={handleStartOfDay} value={initDate} />
            </Grid>
            <Grid xs={6} sx={{ paddingTop: 0 }} >
                <TimePicker label="Finaliza jornada" onChange={handleEndOfDay} value={endDate} />
            </Grid>
            <Grid xs={12} >
                <Typography sx={{ textDecoration: 'underline' }} lineHeight={0} padding={0} fontWeight={'500'} fontSize={".8rem"} color={'GrayText'} >Segunda jornada del dia</Typography>
            </Grid>
            <Grid xs={6} sx={{ paddingTop: 0 }} >
                <TimePicker label="Inicia jornada" onChange={handleStartOfDay} value={initDate} />
            </Grid>
            <Grid xs={6} sx={{ paddingTop: 0 }} >
                <TimePicker label="Finaliza jornada" onChange={handleEndOfDay} value={endDate} />
            </Grid>
            <Grid xs={12} >
                <Typography sx={{ textDecoration: 'underline' }} lineHeight={0} padding={0} fontWeight={'500'} fontSize={".8rem"} color={'GrayText'} >Dias laborales</Typography>
            </Grid>
            <Grid xs={12} >
                <Checkbox
                    label={t('day_1')}
                    onChange={() => null}
                />
                <Checkbox
                    label={t('day_2')}
                    onChange={() => null}
                />
                <Checkbox
                    label={t('day_3')}
                    onChange={() => null}
                />
                <Checkbox
                    label={t('day_4')}
                    onChange={() => null}
                />
                <Checkbox
                    label={t('day_5')}
                    onChange={() => null}
                />
                <Checkbox
                    label={t('day_6')}
                    onChange={() => null}
                />
                <Checkbox
                    label={t('day_0')}
                    onChange={() => null}
                />
            </Grid> */}
        </Grid>
    )
}

export default WorkDay