import { showDrawer } from '@service/AppSlice';
import { useAppDispatch, useAppSelector } from '@hook/state';
import { ProductsForSupplierPurchasesResponse } from "@interface/Product"
import { GetPurchasesOfTheDayToSupplier, InvoicesByConsecutiveNumberAsyncResponse, ProviderSelected, PurchasingProductsSelected, RecordPurchasingData, RecordPurchasingDataResponse } from "@interface/Purchasing"
import { useGetProductsForSupplierPurchasesQuery } from "@query/Product"
import { useChangeMethodOfPaymentOfThePurchaseInvoiceMutation, useDeletePurchaseMutation, useGetInvoicesByConsecutiveNumberQuery, useGetPurchasesOfTheDayToSupplierQuery, useRecordPurchaseFromSupplierMutation, useUpdatePurchaseInformationMutation } from '@query/PurchasingSuppliers'
import { transformIntoFormData } from '@service/parseData'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { restartDefaultValues } from '../services/PurchasesSuppliersSlice';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export const usePurchasing = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const [ recordPurchaseFromSupplierMutation, {isLoading:isLoadingUseRecordPurchaseFromSupplierMutation} ] = useRecordPurchaseFromSupplierMutation()
    const [ updatePurchaseInformationMutation, {isLoading:isLoadingUpdatePurchaseInformationMutation} ] = useUpdatePurchaseInformationMutation()
    const [ deletePurchaseMutation, {isLoading:isLoadingDeletePurchaseMutation} ] = useDeletePurchaseMutation()
    const [ changeMethodOfPaymentOfThePurchaseInvoiceMutation, { isLoading:isLoadingChangeMethodOfPaymentOfThePurchaseInvoiceMutation } ] = useChangeMethodOfPaymentOfThePurchaseInvoiceMutation()

    
    const recordPurchaseFromSupplier = async (params:RecordPurchasingData) => {
        const { purchaseId } = params
        const payload = transformIntoFormData(params, ['payments', 'productList', 'provider'])
        const resp = Boolean(purchaseId) ? await updatePurchaseInformationMutation(payload).unwrap() : await recordPurchaseFromSupplierMutation(payload).unwrap()
        if( resp.status === 1 ){
            if( Boolean(purchaseId) ){
                enqueueSnackbar("La compra se actualizo satisfactoriamente", { variant: 'success' })
                navigate(-1)
            }else{
                enqueueSnackbar("La compra se registro satisfactoriamente", { variant: 'success' })
            }
            dispatch(restartDefaultValues())
            dispatch(showDrawer(true))
        }else{
            enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest')!, { variant: 'error' })
        }
    }

    const deletePurchase = async ({purchaseId}:{ purchaseId: string }) => {
        const resp = await deletePurchaseMutation({ purchaseId }).unwrap()
        if( resp.status === 1 ){
            Swal.fire({
                icon: 'success',
                text: 'La compra se elimino exitosamente',
                showConfirmButton: false,
                timer: 2000
            })
        }
    }

    const changeMethodOfPaymentOfThePurchaseInvoice = async (params:{ purchasesId: string, paymentMethod: string }) => {
        try {
            const resp = await changeMethodOfPaymentOfThePurchaseInvoiceMutation(params).unwrap()

            if( resp.status === 1 ){
                enqueueSnackbar("El método de pago se actualizo con éxito", { variant: 'success' })
            }else{
                enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest')!, { variant: 'error' })
            }

        } catch (error) {
            console.log(error)
        }
    }
    return {
        recordPurchaseFromSupplier,
        isLoadingUseRecordPurchaseFromSupplierMutation,
        isLoadingUpdatePurchaseInformationMutation,
        deletePurchase,
        isLoadingDeletePurchaseMutation,
        changeMethodOfPaymentOfThePurchaseInvoice,
        isLoadingChangeMethodOfPaymentOfThePurchaseInvoiceMutation
    }
}
export const useGetPurchasesOfTheDayToSupplier = ({providerId}:{providerId: string}) => {
    const { data, isLoading } = useGetPurchasesOfTheDayToSupplierQuery({ providerId }, { refetchOnMountOrArgChange: true })
    const purchases = data?.payload??[] as GetPurchasesOfTheDayToSupplier['payload']

    return { purchases, isLoading }
}
export const usePurchasingTools = () => {
    const { PurchasesSuppliersState } = useAppSelector(({ PurchasesSuppliersState }) => ({ PurchasesSuppliersState }))
    const { valueTotalOfPreviousPurchases } = PurchasesSuppliersState

    const calcProductIVA = ({ iva, cost }: { iva: number, cost: number }) => (iva/100)*cost
    const convertInProductSelected = (params:RecordPurchasingDataResponse['products']) => {
        const values = params?.map((n) => ({
            _id: n._id,
            compounds: [],
            freight: n.freight,
            name: n.name,
            packaging: n.packaging,
            prices: n.prices,
            purchaseCost: n.purchaseCost,
            quantity: n.quantity,
            tax: n.tax,
        })) as PurchasingProductsSelected[]
        return values
    }
    const calcTotalPurchasing = (items:Array<PurchasingProductsSelected>, provider?: ProviderSelected | null ) => {

        let reteFuente = 0
        const totalFreight = items.reduce((acc, val) => ((val?.freight??0)*(val?.quantity??0))+acc,0)

        let total = items?.reduce((accumulator, params) => {
            const total = (params.quantity*params.purchaseCost)
            const tax = (params?.tax?.tax??0)
            const iva = calcProductIVA({ iva: tax, cost: total })
            return accumulator+(total+iva)
        }, 0)

        let subTotal = items?.reduce((accumulator, params) => {
            const total = (params.quantity*params.purchaseCost)
            return accumulator+total
        }, 0)

        const taxes = items?.filter(n => n?.tax?._id !== null && n?.tax?._id !== undefined) || []
        let totalTaxes:{ _id: string, name: string, total: number, base:number }[] = []
        for( let product of taxes ){
            const taxIndex = totalTaxes.findIndex((n:any) => n._id === product.tax._id)
            const subTotal = (product.quantity*product.purchaseCost)
            if( taxIndex >= 0 ){
                totalTaxes[taxIndex].total += calcProductIVA({ cost: subTotal, iva:(product?.tax?.tax??0) })
                totalTaxes[taxIndex].base += subTotal
            }else{
                if( Boolean(product?.tax?._id) ){
                    totalTaxes.push({
                        _id: product?.tax?._id??"",
                        name: `${product.tax.name} ${product.tax.tax}%`,
                        total: calcProductIVA({ cost: subTotal, iva:(product?.tax?.tax??0) }),
                        base: subTotal
                    })
                }
            }
        }
        let totalIva = totalTaxes?.reduce((accumulator, params) => (params?.total??0)+accumulator, 0)

        if( ((provider !== undefined) && (provider !== null)) ){
            const minimumAmount = (provider.tax?.minimumAmount??0)
            const reteTax = (provider.tax?.tax??0)
            if( (subTotal+valueTotalOfPreviousPurchases) >= minimumAmount ){
                reteFuente = (reteTax/100)*subTotal
                totalTaxes.push({
                    _id: provider.tax?._id??"",
                    base: subTotal,
                    name: `${(provider.tax?.name??"").toUpperCase()} ${reteTax}%`,
                    total: reteFuente
                })
            }
        }

        total -= reteFuente

        return {total, subTotal, iva: totalTaxes, totalIva, reteFuente, totalFreight }
    }
    const calcPrices = (item:PurchasingProductsSelected | null) => {
        if( item === null ){
            return null
        }else{
            const product:PurchasingProductsSelected = structuredClone(item)
            const tax = calcProductIVA({ cost:(product.purchaseCost), iva:(product?.tax?.tax??0) })
            const taxUnit = tax/(item?.packaging?.quantity??0)
            product.costUnit = ((item?.purchaseCost??0)/(item?.packaging?.quantity??0))+taxUnit
            product.unitFreight = ((item?.freight??0)/(item?.packaging?.quantity??0))
            product.costWithFreight = (product.costUnit+product.unitFreight)
            if( product.compounds !== null && product.compounds !== undefined ){
                for( let index in product.compounds ){
                    const compound = product.compounds[index]
                    compound.costUnit = (product?.costUnit??0)*(compound?.packaging?.quantity??0)
                    compound.costWithFreight = (compound.costUnit+product.unitFreight)
                }
            }
            return product
        }
    }

    return {
        calcProductIVA,
        calcTotalPurchasing,
        calcPrices,
        convertInProductSelected
    }
}
export const useProductsForSupplierPurchases = () => {
    const { data, isLoading } = useGetProductsForSupplierPurchasesQuery({})
    const products = data?.payload??[] as ProductsForSupplierPurchasesResponse['payload']
    return { products, isLoading }
}

export const useGetInvoicesByConsecutiveNumber = ({ consecutive }:{consecutive: string | null}) => {
    const { data, isLoading } = useGetInvoicesByConsecutiveNumberQuery({ consecutive }, { skip: !Boolean(consecutive) })
    const resp = (data?.payload??[]) as InvoicesByConsecutiveNumberAsyncResponse['payload']

    return {
        resp,
        isLoading
    }
}