import Typography from "@component/Typography"
import { currencyFormat } from "@hook/useNumberFormat"
import { useTranslation } from "react-i18next"
import ListDataGrid from "./ListDataGrid"
import { useGetInventorySummary } from "@feature/Home/hooks/usePanel"

const InventoryDetails = ({ date="" }) => {
    const { t } = useTranslation()
    const { resp, isLoading } = useGetInventorySummary({ startDate: date, endDate: date })
    const columns = [
        { field: "concept", width: 220, headerName: "", renderCell: ({row}) => {
            return(
                <Typography variant="body2" sx={{ textTransform: 'uppercase', fontWeight: '500', fontSize: '.795rem' }} >
                    {`${t((row?.concept??""))}`}
                </Typography>
            )
        }},
        { field: "total", flex: 1, headerName: "", renderCell: ({row}) => {
            let redVariantColor = ['cost_sale']
            redVariantColor = redVariantColor.some((n) => n === (row?.concept??""))
            return(
                <Typography variant="body2" sx={{
                        fontWeight: 'bold',
                        ...(Boolean(row?.concept) ? { color: (theme) => redVariantColor ? theme.palette.error.main : theme.palette.success.main } : {})
                    }} >
                    {`$ ${currencyFormat((row?.total??0), true)}`}
                </Typography>
            )
        }},
    ]
    return(
        <>
            <ListDataGrid
                loading={isLoading}
                title="RESUMEN DE INVENTARIO"
                columns={columns}
                rows={resp}
            />
        </>
    )
}

export default InventoryDetails