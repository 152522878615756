export const modals_map = {
    customer_advance: "customer_advance",
    fixed_expense: "fixed_expense",
    edit_purchase_data: "edit_purchase_data",
}

export const URLParameters_map = {
    open_modal: "modal",
    _id: "ref",
    advance: "advance",
    pay: "pay",
    group: "group",
    paymentType: "paymentType",
    startDate: "startDate",
    endDate: "endDate",
    name: "name",
    filterBy: "filterBy",
    field: "field",
    categories: "categories",
    providers: "providers",
    profit: "profit",
}