import DataCard from "@component/DataCard"
import { useGetBusinessFinancialStatement } from "@feature/Business/hooks/useBusiness"
import { currencyFormat } from "@hook/useNumberFormat"
import TrendingUpRounded from '@mui/icons-material/TrendingUpRounded';

const BusinessAssets = ({startDate=""}) => {
    const { asset } = useGetBusinessFinancialStatement({ startDate })

    return(
        <>
            <DataCard
                headerText="Activos"
                text={`$ ${currencyFormat(asset, true)}`}
                icon={<TrendingUpRounded />}
                typographyProps={{
                    sx:{
                        color: (theme) => theme.palette.success.main
                    }
                }}
            />
        </>
    )
}

export default BusinessAssets