import { useTranslation } from 'react-i18next';

import { useSnackbar } from 'notistack';

import {
  useDeleteCustomersNeighborhoodMutation,
  useRegisterCustomersNeighborhoodMutation,
  useUpdateCustomersNeighborhoodMutation,
} from '../../../api/querys/Customer';
import { CustomerNeighborhoodsProps } from '../../../interfaces/Customer';

export const useNeighborhood = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [registerNeighborhood, { isLoading: registerNeighborhoodIsLoading }] =
    useRegisterCustomersNeighborhoodMutation();
  const [updateNeighborhood, { isLoading: updateNeighborhoodIsLoading }] =
    useUpdateCustomersNeighborhoodMutation();
  const [deleteNeighborhood, { isLoading: deleteNeighborhoodIsLoading }] =
    useDeleteCustomersNeighborhoodMutation();

  const registerNeighborhoodMutation = async (
    payload: CustomerNeighborhoodsProps
  ) => {
    if (payload.name.trim() === '') {
      enqueueSnackbar(t('NeighborhoodNameIsARequiredField'), {
        variant: 'warning',
      });
      return;
    }
    if (Boolean(payload._id) && payload.name.trim() === '') {
      enqueueSnackbar(
        t(
          'itSeemsThatAnImportantPieceOfInformationIsMissingToUpdateTheProduct'
        )!,
        { variant: 'error' }
      );
      return;
    }
    const resp = Boolean(payload._id)
      ? await updateNeighborhood(payload).unwrap()
      : await registerNeighborhood(payload).unwrap();
    if (resp?.status === 1) {
      const message = Boolean(payload._id)
        ? t('theNeighborhoodWasSuccessfullyUpdated')
        : t('theNeighborhoodWasRegisteredSuccessfully')!;
      enqueueSnackbar(message, { variant: 'success' });
    } else {
      enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest')!, {
        variant: 'error',
      });
    }
  };

  const deleteNeighborhoodMutation = async (packaging: string[]) => {
    console.log(packaging);
    if (packaging.length <= 0) {
      enqueueSnackbar(t('selectAtLeastNeighborhoodToRemove')!, {
        variant: 'warning',
      });
      return;
    }
    const resp = await deleteNeighborhood(packaging).unwrap();
    if (resp.status === 1) {
      enqueueSnackbar(t('neighborhoodWereSuccessfullyRemoved')!, {
        variant: 'success',
      });
      return;
    } else {
      enqueueSnackbar(
        t(
          'itSeemsThatAnImportantPieceOfInformationIsMissingToUpdateTheProduct'
        )!,
        { variant: 'error' }
      );
      return;
    }
  };
  return {
    registerNeighborhoodMutation,
    deleteNeighborhoodMutation,
    registerNeighborhoodIsLoading,
    updateNeighborhoodIsLoading,
    deleteNeighborhoodIsLoading,
  };
};
