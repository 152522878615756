import React from 'react'

export const useNumberFormat = () => {
    const useCurrency = React.useCallback((number:number) => {
        let convertPositive = Math.abs(number)
        let toFixedNumber = Number.parseFloat(String(convertPositive)).toFixed(2)
        return new Intl.NumberFormat("es-CO").format(parseFloat(toFixedNumber))
    }, [])
    return { useCurrency }
}

export const currencyFormat = (number:number, useNegative?:boolean) => {
    let convertPositive = !useNegative ? Math.abs(number) : number
    let toFixedNumber = Number.parseFloat(String(convertPositive)).toFixed(2)
    let numberReturn = parseFloat(toFixedNumber)
    if( Number.isNaN(numberReturn) ) numberReturn = 0
    return new Intl.NumberFormat("es-CO").format(numberReturn)
}

export const calcProfitPercentage = (profit:number, sale:number, forceNumber?:boolean) => {
    let resp = 0
    const percentage = (profit/sale)*100
    const toFixedNumber = Number.parseFloat(String(percentage)).toFixed(2)
    if( forceNumber === undefined || forceNumber === false ){
        resp = currencyFormat(percentage) as any
    }else{
        resp = parseFloat(toFixedNumber)
    }

    if( Number.isNaN(resp) ){
        resp = 0
    }
    return resp
}