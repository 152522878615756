import Dialog, { DialogActions, DialogContent } from "@component/Dialog"
import BoxPayments from "@feature/Business/components/BoxPayments"
import { useURLParams } from "@hook/useURLParams"
import { useAccountsPayable, useGetDebtsToPay } from "../hooks/useAccountsPayable"
import Form from "@component/Form"
import Grid from "@component/Grid"
import Button from "@component/Button"
import LocalAtmRounded from '@mui/icons-material/LocalAtmRounded';
import { PayAccountPayableProps } from "@interface/DebtsToPay"
import React from "react"
import Swal from "sweetalert2"
import { currencyFormat } from "@hook/useNumberFormat"

const PayAccountPayable = () => {
    const [payment, setPayment] = React.useState<PayAccountPayableProps>({ payments: [], accountPayableId: "" })
    const { getQuery, delQuery } = useURLParams()
    const { recordPaymentToAccountPayable, isLoadingRecordPaymentToAccountPayableMutation } = useAccountsPayable()
    const tag = getQuery("tag")
    const paymentId = getQuery("paymentId")
    const open = Boolean(paymentId)
    const { debtsToPay } = useGetDebtsToPay({ only: "balance", show: "results" })
    const results = debtsToPay.find(n => n._id === tag)
    const pay = results?.results.find(n => n._id === paymentId)
    const handleSubmit = () => {
        let totalPayment = payment.payments.reduce((acc, params) => ((params?.payment??0)+acc),0)
        if( payment.payments.length <= 0 ){
            Swal.fire({
                icon: "warning",
                text: "Seleccione un monto y una caja",
                showConfirmButton: false,
                timer: 2000
            })
            return
        }
        if( totalPayment > (pay?.amount??0)){
            Swal.fire({
                icon: "warning",
                text: `Verifique los valores, intenta realizar un pago por $ ${currencyFormat(totalPayment)} a una cuenta por pagar de $ ${currencyFormat((pay?.amount??0))}`,
            })
            return
        }
        recordPaymentToAccountPayable(payment)
    }

    React.useEffect(() => {
        setPayment(n => ({...n, accountPayableId: paymentId as string}))
    },[paymentId])
    return(
        <Dialog fullWidth open={open} onClose={() => delQuery("paymentId")} >
            <DialogContent>
                <Form onSubmit={handleSubmit} >
                    <Grid container >
                        <Grid xs={12} >
                            <BoxPayments maxPayments={1} maxPayment={(pay?.amount??0)} onChange={(values) => setPayment(n => ({...n, payments: values}))} />
                        </Grid>
                    </Grid>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button loading={isLoadingRecordPaymentToAccountPayableMutation} onClick={handleSubmit} variant="text" startIcon={<LocalAtmRounded />} > Pagar </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PayAccountPayable