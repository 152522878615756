import { useNavigate } from "react-router-dom"
import { useGetEmployessByBusinessQuery } from "../../../api/querys/Employee"
import Autocomplete from "@component/Autocomplete"
import Box from "@component/Box"
import Routes from "../../../services/routes"
import ButtonAddEmployee from "./ButtonAddEmployee"

const SearchEmployee = () => {
    const { data, isLoading } = useGetEmployessByBusinessQuery()
    const employees = data?.payload?.employees?.results??[]
    const navigate = useNavigate()
    const handleSelection = (element:any, param:any) => navigate(Routes.employeeData(param._id))
    return(
        <Box display={'flex'} alignItems={'center'} >
            <Autocomplete
                onChange={handleSelection}
                loading={isLoading}
                options={employees}
                label="Buscar empleado"
            />
            <Box mx={1} >
                <ButtonAddEmployee />
            </Box>
        </Box>
    )
}

export default SearchEmployee