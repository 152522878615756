import { ProvidersResponse, ProviderPropsForm, ProviderRegisterResponse, AdvanceToProviderSend, SupplierAdvancesHistoryAsyncResponse } from './../../interfaces/Provider.d';
import { api, mutationConfig } from "../splitApi"
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { ProviderID, ProviderPropsAsyncResponse, UpdateSupplierAdvanceAsyncResponse, UpdateSupplierAdvanceProps } from '@interface/_Provider';

const Providers = api.injectEndpoints({
    endpoints: (build) => ({
        getProvidersByBusiness: build.query<ProvidersResponse, void>({
            query: () => '/get/providers/by/business',
            providesTags: ['Provider']
        }),
        registerProvider: build.mutation<ProviderRegisterResponse, ProviderPropsForm>({
            query(body) {
                return {...mutationConfig, url: '/register/provider', body}
            },
            invalidatesTags: ['Provider']
        }),
        updateProvider: build.mutation<ProviderRegisterResponse, ProviderPropsForm>({
            query(body) {
                return {...mutationConfig, url: '/update/provider', body}
            },
            invalidatesTags: ['Provider']
        }),
        getProvider: build.query<ProviderPropsAsyncResponse, ProviderID>({
            query: ({ providerId }) => ({ url: '/get/provider', params: {providerId} }),
            providesTags: ['Provider']
        }),
        deleteProvider: build.mutation<any, GridRowSelectionModel>({
            query(body) {
                return {...mutationConfig, url: '/delete/providers', body}
            },
            invalidatesTags: ['Provider']
        }),
        registerAdvanceToProvider: build.mutation<any, AdvanceToProviderSend>({
            query(body) {
                return {...mutationConfig, url: '/register/advance/to/supplier', body}
            },
            invalidatesTags: ['Provider', 'MinorBox', 'LargeBox', 'MasterBox', 'BankBox', 'BoxBalances']
        }),
        obtainSupplierAdvances: build.query<SupplierAdvancesHistoryAsyncResponse, void>({
            query: () => ({ url: '/obtain/supplier/advances' }),
        }),
        updateSupplierAdvance: build.mutation<UpdateSupplierAdvanceAsyncResponse, UpdateSupplierAdvanceProps>({
            query(body) {
                return {...mutationConfig, url: '/update/supplier/advance', body}
            },
            invalidatesTags: ['Provider', 'MinorBox', 'LargeBox', 'MasterBox', 'BankBox', 'BoxBalances']
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetProvidersByBusinessQuery,
    useRegisterProviderMutation,
    useGetProviderQuery,
    useUpdateProviderMutation,
    useDeleteProviderMutation,
    useRegisterAdvanceToProviderMutation,
    useObtainSupplierAdvancesQuery,
    useUpdateSupplierAdvanceMutation
} = Providers