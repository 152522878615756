import Box from "@component/Box"
import IconButton from "@component/IconButton"
import InputSearchProviders from "./InputSearchProviders"
import AddRounded from '@mui/icons-material/AddRounded';
import Tooltip from "@component/Tooltip";
import { useTranslation } from "react-i18next";
import { openFormCreateProvider } from "../services/ProviderSlice";
import { useAppDispatch } from "../../../hooks/state";
import Routes from "../../../services/routes";
import { useNavigate } from "react-router-dom";
import Grid from "@component/Grid";
import Checkbox from "@component/Checkbox";
import { useURLParams } from "@hook/useURLParams";

const ProviderNavbar = () => {
    const { getQuery, addQuery, delQueries } = useURLParams()
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const navigate = useNavigate()
    return(
        <Grid container >
            <Grid xs={12} >
                <Box display={'flex'} alignItems={'center'} >
                    <InputSearchProviders onChange={(_, n:any) => navigate(Routes.indexProvider(n._id)) } />
                    <Box ml={1} >
                        <Tooltip title={t('createNewProvider')!} >
                            <IconButton squareButton onClick={() => dispatch(openFormCreateProvider(true))} >
                                <AddRounded />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </Grid>
            <Grid xs={12} >
                <Checkbox
                    checked={getQuery("with") === "advances"}
                    label="Proveedores con anticipo"
                    onChange={() => getQuery("with") === "advances" ? delQueries(['with']) : addQuery({ with: 'advances' }) }
                />
                <Checkbox
                    checked={getQuery("retention") === "withholding_tax"}
                    label="Proveedores con retención"
                    onChange={() => getQuery("retention") === "withholding_tax" ? delQueries(['retention']) : addQuery({ retention: 'withholding_tax' }) }
                />
            </Grid>
        </Grid>
    )
}

export default ProviderNavbar