import React from 'react';
import { DesktopDatePicker, DesktopDatePickerProps as DesktopDatePickerPropsMUI } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import TextField from "./TextField"

type DesktopDatePickerProps = Pick<DesktopDatePickerPropsMUI<any, any>, "className" | "components" | "ToolbarComponent" | "disablePast" | "disableFuture" | "inputFormat" | "views" | "openTo" | "inputRef" | "autoFocus" | "label" | "value" | "maxDate" | "minDate" | "InputProps" >
type TextFieldProps = Pick<React.ComponentProps<typeof TextField>, "size" | "fullWidth">

interface Props extends DesktopDatePickerProps{
    name?: string
    onChange?: ({value, keyboardInputValue, name}:{value: Date | null, keyboardInputValue?: string | undefined, name: string | undefined}) => void
    size?: TextFieldProps['size']
    fullWidth?: TextFieldProps['fullWidth']
}

const DatePicker = React.forwardRef<HTMLInputElement, Props>(({ value=dayjs().toDate(), onChange, name, size="medium", maxDate=dayjs().toDate(), minDate=false, fullWidth=false, inputFormat="DD/MM/YYYY", ...rest }, ref) => {
    const handleChange = (value: Date | null, keyboardInputValue?: string | undefined) => {
        if( onChange !== undefined ){
            onChange({value, keyboardInputValue, name})
        }
    }

    return(
        <DesktopDatePicker
            {...rest}
            inputRef={ref}
            className='data-picker-container'
            onChange={handleChange}
            value={value}
            inputFormat={inputFormat}
            maxDate={(maxDate as Date | undefined)}
            minDate={(minDate as Date | undefined)}
            renderInput={(params) => <TextField margin='none' {...params} fullWidth={fullWidth} size={size} />}
        />
    )
})

export default DatePicker