import { DeleteRounded } from "@mui/icons-material"
import IconButton from "./IconButton"
import Tooltip from "./Tooltip"
import Swal from "sweetalert2"
import { useTheme } from "@mui/material/styles"

interface Props {
    onDelete?: () => void
    quantityItems?: number
}

const DeleteItemsButton = ({ onDelete, quantityItems }:Props) => {
    const theme = useTheme()
    const handleClick = async () => {
        const swalAlert = await Swal.fire({
            icon: 'question',
            text: '¿Estas seguro que deseas eliminar los elementos seleccionado?',
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Eliminar",
            confirmButtonColor: theme.palette.error.main,
            cancelButtonColor: theme.palette.primary.main,
        })

        if(swalAlert.isConfirmed){
            if( onDelete !== undefined ){
                onDelete()
            }
        }

    }
    
    return(
        <>
            { ((quantityItems !== undefined) && (quantityItems >= 1)) &&
                <Tooltip title="Eliminar selección" >
                    <IconButton squareButton color="error" onClick={handleClick} >
                        <DeleteRounded />
                    </IconButton>
                </Tooltip>
            }
        </>
    )
}

export default DeleteItemsButton