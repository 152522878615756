import { ReceiptLongRounded } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { KeyboardEvent, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '../../../components/Box';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, } from '../../../components/List';
import Typography from '../../../components/Typography';

import { currencyFormat } from '../../../hooks/useNumberFormat';

type InvoiceCardProps = {
  _id?: string;
  total: number;
  consecutive: number;
  customer: string;
  seller: string;
  isInformative?: boolean;
  onClick?: (props: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void;
  onKeyDown?: (evt: KeyboardEvent<HTMLDivElement>) => void;
  autoFocus?: boolean;
};

const InvoiceCard = ({ _id, total, consecutive, customer, seller, isInformative = false, onClick = () => {}, onKeyDown = () => {}, autoFocus = false, }: InvoiceCardProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const informativeStyle = {
    width: '100%',
    padding: 0
  };

  const defaultStyle = {
    paddingTop: 0,
    paddingBotton: 0,
  };

  const Item = () => (
    <ListItemText
      primaryTypographyProps={{
        style: {
          fontSize: '0.9rem',
        },
      }}
      primary={
        <>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
              color: theme.palette.primary.main,
            }}
          >
            {`#R-${consecutive}`}
          </Typography>
          <Typography
            sx={{
              fontSize: '0.8rem',
            }}
          >{`${seller} - $ ${currencyFormat(total)}`}</Typography>
        </>
      }
      secondaryTypographyProps={{
        style: {
          fontSize: '0.8rem',
          fontWeight: 'bold',
          color: theme.palette.grey[500],
        },
      }}
      secondary={`${t('customer')}: ${t(customer)}`}
    />
  );

  return (
    <>
      {!isInformative && (
        <ListItemButton divider sx={defaultStyle} onClick={(evt) => onClick(evt)} onKeyDown={(evt) => onKeyDown(evt)} autoFocus={autoFocus} >
          <Item />
          <ListItemIcon sx={{ marginLeft: '10px', minWidth: 0 }}>
            <ReceiptLongRounded sx={{ fontSize: '2.5rem' }} />
          </ListItemIcon>
        </ListItemButton>
      )}

      {isInformative && (
        <ListItem sx={informativeStyle} >
          <Box sx={{ width: '100%', py: "4px", px: 1 }} border='1px solid rgba(0, 0, 0, 0.12)' borderRadius='8px' >
            <Item />
          </Box>
        </ListItem>
      )}
    </>
  );
};

export default InvoiceCard;
