import React from 'react'
import FormBusiness from "./FormBusiness"
import { useGetBusinessQuery } from "../../../api/querys/Business"
import { BusinessFormProps } from '../../../interfaces/Business'
import Fade from '../../../components/Fade'

const EditBusiness = () => {
    const { data } = useGetBusinessQuery()
    const business = data?.payload
    const businessEdit:BusinessFormProps = React.useMemo(() => {
        const form:BusinessFormProps = {
            name: "",
            nit: "",
            address: "",
            email: "",
            phone: ""
        }
        form._id = business?._id??""
        form.name = business?.name??""
        form.address = business?.address??""
        form.email = business?.email??""
        form.nit = business?.nit??""
        form.phone = business?.phone??""
        return form
    },[business])
    return(
        <Fade in={true} >
            <FormBusiness businessEdit={businessEdit} />
        </Fade>
    )
}

export default EditBusiness