import { useSnackbar } from "notistack"
import { useDeleteCustomersMutation } from "../../../api/querys/Customer"
import Button from "@component/Button"
import Dialog, { DialogActions, DialogContent, DialogContentText, DialogTitle } from "@component/Dialog"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { showSecurityAlertClearClient } from "../services/CustomerSlice"

const AlertDeleteCustomer = () => {
    const dispatch = useAppDispatch()
    const { CustomerState } = useAppSelector(({ CustomerState }) => ({ CustomerState }))
    const { securityAlertClearClient, customersToDelete } = CustomerState
    const [deleteCustomers, { isLoading }] = useDeleteCustomersMutation()
    const { enqueueSnackbar } = useSnackbar()
    const handleDelCustomers = async () => {
        const resp = await deleteCustomers(customersToDelete).unwrap()
        if( resp.status === 1 ){
            const message = customersToDelete.length >= 2 ? "Clientes eliminados satisfactoriamente" : "Cliente eliminado satisfactoriamente"
            enqueueSnackbar(message,{ variant: "success" })
            dispatch(showSecurityAlertClearClient(false))
        }
    }
    return(
        <Dialog open={securityAlertClearClient} onClose={() => dispatch(showSecurityAlertClearClient(false))} >
            <DialogTitle> Eliminar clientes </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    ¿Esta seguro que desea ejecutar esta acción?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="text" color="error" loading={isLoading} onClick={handleDelCustomers} >Eliminar</Button>
                <Button variant="text" onClick={() => dispatch(showSecurityAlertClearClient(false))} loading={isLoading} >Cancelar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AlertDeleteCustomer