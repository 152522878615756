import Box from "@component/Box"
import FilterByDateRange from "@component/FilterByDateRange"
import Stack from "@component/Stack"
import { useURLParams } from "@hook/useURLParams"
import dayjs from "dayjs"
import React from "react"


const HistoryOfPaidAccountsToolbar = () => {
    const { addQuery, getQuery } = useURLParams()
    const startDate = getQuery('initialDate') || dayjs().format("MM-DD-YYYY")
    const endDate = getQuery('finalDate') || dayjs().format("MM-DD-YYYY")

    return(
        <Stack direction={'row'} justifyContent={'space-between'} pt={1} px={2} >
            <Box>
                <FilterByDateRange
                    initDate={dayjs(startDate).toDate()}
                    endDate={dayjs(endDate).toDate()}
                    size="small"
                    onChange={({ initDate, endDate:finDate }) => addQuery({ initialDate: dayjs(initDate).format("MM-DD-YYYY"), finalDate: dayjs(finDate).format("MM-DD-YYYY") })}
                />
            </Box>
            <Box>

            </Box>
        </Stack>
    )
}

export default HistoryOfPaidAccountsToolbar