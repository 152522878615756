import React from "react";
import { Drawer as MuiDrawer, DrawerProps, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { ListItemButton, ListItemIcon, ListItemText } from "@component/List";
import Toolbar from "@component/Toolbar";
import Typography from "@component/Typography";
import BusinessDataDrawer from "./BusinessDataDrawer";
import PointOfSaleRounded from "@mui/icons-material/PointOfSaleRounded";
import { useAppDispatch, useAppSelector } from "../../../hooks/state";
import { displayResponsiveDesign, showDrawer } from '../../../services/AppSlice';
import Box from '../../../components/Box';
import IconButton from '../../../components/IconButton';
import ArrowBackIosRounded from '@mui/icons-material/ArrowBackIosRounded';
import { openPointOfSaleWindow } from "../../PointOfSale/services/PointOfSaleSlice";
import DrawerButton from "./DrawerButton";
import { drawerList } from "../services/drawerList";

interface Props extends DrawerProps {
    open?: boolean;
    drawerWidth?: number;
}

const CustomDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth', })<Props>(({ theme, open, drawerWidth }) => ({
    '& .MuiDrawer-paper': {
        background:
            theme.palette.mode === 'light'
                ? 'linear-gradient(360deg, rgb(0 58 82) 20%, rgb(0 89 126) 100%)'
                : theme.palette.background.paper,
        color: '#FFFFFF',
        ...(theme.palette.mode === 'light' && { border: 'none' }),
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(0),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(0),
            },
        }),
    },
}));

const Drawer = ({ drawerWidth = 250 }: Props) => {
    const { t } = useTranslation();
    const { AppState } = useAppSelector(({ AppState }) => ({ AppState }));
    const { openDrawer: open } = AppState;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (matches) {
            dispatch(displayResponsiveDesign(true));
            dispatch(showDrawer(false));
        } else {
            dispatch(displayResponsiveDesign(false));
            dispatch(showDrawer(true));
        }
    }, [matches, dispatch]);

    return (
        <CustomDrawer open={open} drawerWidth={drawerWidth} variant="permanent" >
            <Toolbar sx={{ display: 'flex', flexDirection: 'column', px: [1], overflow: 'hidden' }} >
                <Box display={'flex'} width={1} >
                    <Typography width={1} fontFamily={'Alatsi'} fontSize={'1.7rem'} fontWeight='bold' sx={{ userSelect: 'none' }} >
                        {t('appTitle')}
                    </Typography>
                    <IconButton onClick={() => dispatch(showDrawer(false))} color='inherit' >
                        <ArrowBackIosRounded />
                    </IconButton>
                </Box>
                <BusinessDataDrawer />
                <DrawerButton drawerList={drawerList} />
            </Toolbar>
            <ListItemButton onClick={() => dispatch(openPointOfSaleWindow(true))} sx={{ position: 'absolute', bottom: "0px", width: "100%", height: "50px", backgroundColor: (theme) => 'rgb(0 89 126)' }} >
                <ListItemIcon sx={{ minWidth: "40px" }} >
                    <PointOfSaleRounded sx={{ color: "#FFFFFF", fontSize: "2rem" }} />
                </ListItemIcon>
                <ListItemText primary="PUNTO DE VENTA" primaryTypographyProps={{ fontWeight: "bold", fontSize: '1rem' }} />
            </ListItemButton>
        </CustomDrawer >
    );
};

export default Drawer;
