import { useTranslation } from 'react-i18next';
import EditRounded from '@mui/icons-material/EditRounded';
import { GridColDef } from '@mui/x-data-grid';

import DataGrid from '../../../components/DataGrid';
import IconButton from '../../../components/IconButton';
import DataGridCustomHeaderToDelete from '../../../components/DataGridCustomHeaderToDelete';
import AlertDeleteNeighborhoods from './AlertDeleteNeighborhoods';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import { loadNeighborhoodsToEdit, selectNeighborhoodsToDelete, showAlertDeleteNeighborhoods } from '../services/NeighborhoodsSlice';
import { CustomerNeighborhoodsProps } from '../../../interfaces/Customer';
import { useGetCustomersNeighborhoodQuery } from '../../../api/querys/Customer';

const NeighborhoodsList: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { NeighborhoodState } = useAppSelector(({ NeighborhoodState }) => ({
    NeighborhoodState,
  }));
  const { selectionToDelete } = NeighborhoodState;

  const { data: neighborhoodProductData, isLoading } =
    useGetCustomersNeighborhoodQuery();
  const neighborhoodArray =
    neighborhoodProductData?.payload??[];

  const handleDeleteNeighborhoods = () => {
    dispatch(showAlertDeleteNeighborhoods(true));
  };

  const handleEditNeighborhoods = (row: CustomerNeighborhoodsProps) => {
    dispatch(loadNeighborhoodsToEdit(row));
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('nameOfTheNeighborhood')!,
      flex: 1,
    },
    {
      field: ' ',
      align: 'center',
      maxWidth: 80,
      minWidth: 80,
      renderCell: ({ row }) => {
        return (
          <IconButton onClick={() => handleEditNeighborhoods(row)}>
            <EditRounded />
          </IconButton>
        );
      },
    },
  ];

  const HeaderDataGrid = () => (
    <DataGridCustomHeaderToDelete
      listItems={selectionToDelete}
      texts={[t('selectedNeighborhoods'), t('selectedNeighborhood')]}
      title=''
      onClick={handleDeleteNeighborhoods}
    />
  );

  const handleSelection = (items: any[]) => {
    dispatch(selectNeighborhoodsToDelete(items));
  };

  return (
    <>
      <DataGrid
        onRowSelectionModelChange={handleSelection}
        checkboxSelection
        disableRowSelectionOnClick
        rowHeight={30}
        rows={neighborhoodArray}
        columns={columns}
        loading={isLoading}
        components={{
          Toolbar: HeaderDataGrid,
        }}
      />
      <AlertDeleteNeighborhoods />
    </>
  );
};

export default NeighborhoodsList;
