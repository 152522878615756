import { GridColDef } from "@mui/x-data-grid"
import dayjs from "dayjs"
import { t } from "i18next"
import { useGetPreviousDaysCreditsQuery } from "../../../api/querys/PointOfSale"
import Box from "@component/Box"
import DataGrid, { GridToolbarContainer } from "@component/DataGrid"
import Typography from "@component/Typography"
import { useAppSelector } from "../../../hooks/state"
import { currencyFormat } from "../../../hooks/useNumberFormat"

const CreditsPreviousTable = () => {
    const { ClosingOfTheDayState } = useAppSelector(({ ClosingOfTheDayState }) => ({ClosingOfTheDayState}))
    const { dateClosingOfDay } = ClosingOfTheDayState
    const { data } = useGetPreviousDaysCreditsQuery({ closingDate: dateClosingOfDay })
    const creditsPrevious = data?.payload?.creditInformationPreviousDays?.results
    const creditsPreviousList = creditsPrevious??[]
    
    const columns:Array<GridColDef> = [
        { maxWidth: 80, minWidth: 80, field: "consecutive", headerName: t('#FAC')!},
        {field: "customer", headerName: t('customer')!, flex: 1, renderCell: ({row}) => <> { row?.customer?.name??"" } </>, valueGetter: ({ row }) => row?.customer?.name??"" },
        {field: "total", headerName: t('total')!, renderCell: ({row}) => <>$ { currencyFormat(row.total) }</>},
        {field: "paymentsMade", headerName: t('totalPayment')!, renderCell: ({row}) => <>$ { currencyFormat(row.paymentsMade) }</>},
        {field: "balance", headerName: t('balance')!, renderCell: ({row}) => <>$ { currencyFormat(row.balance) }</>},
        {field: "paymentsCash", headerName: t('cashPayments')!, renderCell: ({row}) => <>$ { currencyFormat(row.paymentsCash) }</>},
        {field: "paymentsConsignment", headerName: t('paymentsConsig')!, renderCell: ({row}) => <>$ { currencyFormat(row.paymentsConsignment) }</>},
        {field: "creditExpirationDate", headerName: t('expirationDate')!, renderCell: ({row}) => <> { dayjs(row.creditExpirationDate??"").format("DD-MM-YYYY") }</>},
    ]
    const HeaderDataGrid = () => {
        return(
            <GridToolbarContainer>
                <Box p={2} >
                    <Typography textTransform={'uppercase'} fontWeight="bold" >{t('creditsPreviousDays')}</Typography>
                    <Typography fontSize={'.9rem'} textTransform={'uppercase'} fontWeight="bold" >{t('total')}: $ {currencyFormat(creditsPreviousList.reduce((acc, params) => (acc+params?.total??0),0))}</Typography>
                </Box>
            </GridToolbarContainer>
        )
    }
    return(
        <Box mb={2}>
            <DataGrid
                slots={{
                    toolbar: HeaderDataGrid
                }}
                rows={creditsPreviousList}
                columns={columns}
            />
        </Box>
    )
}

export default CreditsPreviousTable