import { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '../../../components/Dialog';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import { useNeighborhood } from '../hooks/useNeighborhood';
import { showAlertDeleteNeighborhoods } from '../services/NeighborhoodsSlice';

const AlertDeleteNeighborhoods = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { NeighborhoodState } = useAppSelector(({ NeighborhoodState }) => ({
    NeighborhoodState,
  }));
  const { isOpenAlertDeleteNeighborhoods, selectionToDelete } =
    NeighborhoodState;

  const { deleteNeighborhoodMutation, deleteNeighborhoodIsLoading } =
    useNeighborhood();

  const handleSubmit = () => {
    deleteNeighborhoodMutation(selectionToDelete);
    handleClose();
  };

  const handleClose = () => {
    dispatch(showAlertDeleteNeighborhoods(false));
  };

  const handleKeyDown = (evt: KeyboardEvent<HTMLDivElement>) => {
    const { keyCode, which } = evt;
    if (keyCode === 13 || which === 13) {
      handleSubmit();
    }
  };

  return (
    <Dialog
      open={isOpenAlertDeleteNeighborhoods}
      onClose={handleClose}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>{t('deleteNeighborhood')}</DialogTitle>

      <DialogContent>
        <DialogContentText>{`¿${t(
          'areYouSureYouWantToPerformThisAction'
        )}?`}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant='text'
          color='error'
          type='submit'
          onClick={handleSubmit}
          loading={deleteNeighborhoodIsLoading}
        >
          {t('remove')}
        </Button>
        <Button
          variant='text'
          onClick={handleClose}
        >
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDeleteNeighborhoods;
