import { useEffect } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import EditRounded from "@mui/icons-material/EditRounded";

import { useGetCustomerZoneQuery } from "../../../api/querys/Customer";
import { useAppDispatch, useAppSelector } from "../../../hooks/state";
import { loadZoneToEdit, loadZonesToDelete, showSecurityAlertClearZone } from "../services/ZoneSlice";

import DataGrid from "@component/DataGrid";
import DataGridCustomHeaderToDelete from "@component/DataGridCustomHeaderToDelete";
import IconButton from "@component/IconButton";

export const Zonelist = () => {
  const { t } = useTranslation();

  const { data } = useGetCustomerZoneQuery();

  const { selectionToDelete } = useAppSelector(({ ZoneState }) => ZoneState);

  const dispatch = useAppDispatch();

  const list = data?.payload.customerZones.results || [];

  const columns: GridColDef[] = [
    { field: "name", headerName: t("name")!, flex: 1 },
    {
      field: " ",
      headerName: " ",
      align: "center",
      maxWidth: 80,
      minWidth: 80,
      renderCell: ({ row }) => {
        return (
          <IconButton onClick={() => dispatch(loadZoneToEdit(row))}>
            <EditRounded />
          </IconButton>
        );
      },
    },
  ];

  const handleCustomerSelection = (data: any) =>
    dispatch(loadZonesToDelete(data));

  const HeaderDataGrid = () => (
    <DataGridCustomHeaderToDelete
      listItems={selectionToDelete}
      texts={[t("selectedZones")!, t("selectedZone")!]}
      title={t("registeredZones")!}
      onClick={() => dispatch(showSecurityAlertClearZone(true))}
    />
  );

  useEffect(() => {
    return () => {
      dispatch(loadZonesToDelete([]));
    };
  }, [dispatch]);

  return (
    <>
      <DataGrid
        disableRowSelectionOnClick
        components={{
          Toolbar: HeaderDataGrid,
        }}
        rows={list}
        columns={columns}
        onRowSelectionModelChange={handleCustomerSelection}
        checkboxSelection
      />
    </>
  );
};
