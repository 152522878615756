import { Menu as MenuMUI, MenuItem as MenuItemMUI, MenuProps, MenuItemProps as  MenuItemPropsMUI } from '@mui/material'

type Props = Pick<MenuProps, "children" | "open" | "anchorEl" | "id" | "onClose" | "onClick" | "PaperProps" | "transformOrigin" | "anchorOrigin">

const Menu = ({ children, ...rest }:Props) => {
    return(
        <MenuMUI {...rest}>
            {children}
        </MenuMUI>
    )
}

export default Menu

type MenuItemProps = Pick<MenuItemPropsMUI, "children" | "onClick" >

export const MenuItem = ({children, ...rest}:MenuItemProps) => {
    return(
        <MenuItemMUI {...rest} >
            {children}
        </MenuItemMUI>
    )
}