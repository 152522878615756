import Dialog, { DialogContent } from "@component/Dialog"
import Tab from "@component/Tab"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { ProductProps } from "@interface/Product"
import ProductForm from "../../Products/components/ProductForm"
import { showFormCreateQuickProduct, showPOSProductWindow } from "../services/PointOfSaleSlice"

const CreateFastProduct = () => {
    const dispatch = useAppDispatch()
    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }))
    const { isOpenFormCreateQuickProduct } = PointOfSaleState
    const handleCreateFastProduct = (product:ProductProps) => {
        if( Boolean(product._id) ){
            dispatch(showPOSProductWindow({ isOpen: true, productSelected: { _id: product._id, cost: product.cost, name: product.name, prices: product.prices, skuCode: product.skuCode, packaging: product.packaging, stock: product.stock, compount: product.compount } }))
            dispatch(showFormCreateQuickProduct(false))
        }
    }
    return(
        <Dialog fullWidth open={isOpenFormCreateQuickProduct} onClose={() => dispatch(showFormCreateQuickProduct(false))} >
            <DialogContent>
                <Tab
                    orientation="horizontal"
                    TabItem={["Simple", "Avanzado"]}
                    TabRender={[<ProductForm defaultFields onCreateFastProduct={handleCreateFastProduct} />, <ProductForm onCreateFastProduct={handleCreateFastProduct} />]}
                />
            </DialogContent>
        </Dialog>
    )
}

export default CreateFastProduct