import Button from "@component/Button"
import Form from "@component/Form"
import Grid from "@component/Grid"
import NumberFormat from "@component/NumberFormat"
import TextField from "@component/TextField"
import TagInput from "@feature/Tags/components/TagsInput"
import { FixedCostsForm } from "@interface/FixedCosts"
import { TagsProps } from "@interface/Tags"
import React from "react"
import { useParams } from "react-router-dom"
import { useFixedCosts, useFixedExpenseFormData } from "../hooks/useFixedCosts"
import Checkbox from "@component/Checkbox"

const FixedExpenseForm = () => {
    const { fixedCostId } = useParams()
    const formData = useFixedExpenseFormData({ fixedCostId })
    const [fixedCostForm, setFixedCostForm] = React.useState<FixedCostsForm>({...formData})

    const { registerNewFixedExpense } = useFixedCosts()
    const handleSubmit = () => registerNewFixedExpense(fixedCostForm)

    React.useEffect(() => {
        setFixedCostForm({...formData})
    },[formData])
    return(
        <Form onSubmit={handleSubmit} >
            <Grid container spacing={1} >
                <Grid xs={6} >
                    <TextField value={fixedCostForm.name} margin="none" label="Gasto" onChange={({ target }) => setFixedCostForm(n => ({...n, name: target.value}))} />
                </Grid>
                <Grid xs={6} >
                    <NumberFormat value={fixedCostForm.amount} onChange={({target}) => setFixedCostForm(n => ({...n, amount: (Number(target?.value??0)) }))} prefix="$ " label="Monto" />
                </Grid>
                <Grid xs={8} >
                    <NumberFormat
                        disabled={!fixedCostForm.recurrent}
                        label="Vence cada"
                        min={1}
                        maxVal={31}
                        value={fixedCostForm.expirationDate}
                        suffix={ fixedCostForm.expirationDate <= 1 ? " día" : " días"}
                        onChange={({ target }) => setFixedCostForm(n => ({...n, expirationDate: Number(target?.value??1) }))}
                    />
                </Grid>
                <Grid xs={4} sx={{ display: 'flex', alignItems: 'center' }} >
                    <Checkbox label="Recurrente" checked={fixedCostForm.recurrent} onChange={() => setFixedCostForm(n => ({...n, recurrent: !fixedCostForm.recurrent}))} />
                </Grid>
                <Grid xs={12} >
                    <TagInput limitTags={1} value={fixedCostForm.tags as TagsProps[]} onTagChange={(tags) => setFixedCostForm(n => ({...n, tags: tags as FixedCostsForm['tags']}))} module="fixedCost" />
                </Grid>
                <Grid xs={12} >
                    <Button type="submit" disableElevation >
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </Form>
    )
}

export default FixedExpenseForm