import DataCard from "@component/DataCard"
import { useGetBusinessFinancialStatement } from "@feature/Business/hooks/useBusiness";
import { currencyFormat } from "@hook/useNumberFormat";
import MonetizationOnRounded from '@mui/icons-material/MonetizationOnRounded';

const BusinessEquity = ({ startDate="" }) => {
    const { patrimony } = useGetBusinessFinancialStatement({ startDate })
    return(
        <>
            <DataCard
                headerText="Balance"
                icon={<MonetizationOnRounded />}
                text={currencyFormat(patrimony, true)}
                typographyProps={{
                    sx: {
                        color: (theme) => patrimony >= 1 ? theme.palette.success.main : theme.palette.error.main
                    }
                }}
            />
        </>
    )
}

export default BusinessEquity