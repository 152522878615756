import { styled } from '@mui/material/styles';

import TransferList from '../../../components/TransferList';

import { useTranslation } from 'react-i18next';
import {
  useGetPermissionsQuery,
  useGetRoleByBusinessQuery,
} from '../../../api/querys/Permission';
import Box from '../../../components/Box';

import { useAppSelector } from '../../../hooks/state';
import { usePermissions } from '../hooks/usePermissions';

import { PermissionProps } from '../../../interfaces/Permission';

const StyledBox = styled(Box)(({ theme }) => ({
  '.css-6s1xft-MuiGrid-root': {
    [theme.breakpoints.down('md')]: {
      display: 'grid',
    },
  },
}));

const TransferListPermissions = () => {
  const { t } = useTranslation();

  const { updateRol, isLoadingUpdateRole } = usePermissions();

  const { PermissionState } = useAppSelector(({ PermissionState }) => ({
    PermissionState,
  }));
  const { rolSelected } = PermissionState;

  const { data: dataPermissions } = useGetPermissionsQuery();
  const { data: dataRole } = useGetRoleByBusinessQuery();

  const permissions = dataPermissions?.payload ?? [];
  const prevRole = dataRole?.payload ?? [];

  const handleSubmit = (newRole: Pick<PermissionProps, 'name' | '_id'>[]) => {
    updateRol({ _id: rolSelected._id, name: rolSelected.name, permissions: newRole, });
  };

  const findPrevRole = prevRole?.find(
    (props: any) => props._id === rolSelected._id
  );

  const roleId = findPrevRole?.permissions?.map((props: any) => {
    return { _id: props?._id };
  });

  const filterPermissionsArray = permissions?.filter(
    (props: any, index: any) => props?._id !== roleId?.[index]?._id
  );

  const filterRoleById = permissions?.filter((props, index) => roleId?.some(x => x._id === props._id) );

  const showPrevPermissionsName = filterPermissionsArray.map(
    (props) => props.name
  );
  const showPrevRoleName = filterRoleById.map((props) => props.name);

  return (
    <StyledBox>
      <TransferList
        leftData={permissions}
        leftDataFilter={showPrevPermissionsName}
        rightDataFilter={showPrevRoleName}
        labelLeft={t('permissionsAvailable')}
        labelRight={t('selectedPermissions')}
        subHeaderLabel={t('selected')}
        handleSubmit={handleSubmit}
        isLoading={isLoadingUpdateRole}
        prevDataState={rolSelected.name}
      />
    </StyledBox>
  );
};

export default TransferListPermissions;
