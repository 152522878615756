import React from 'react'
import { useTranslation } from "react-i18next"
import Button from "@component/Button"
import Form from "@component/Form"
import Grid from "@component/Grid"
import TextField from "@component/TextField"
import { useAppSelector } from '../../../hooks/state'
import type { ProductCategoriesProps } from '../../../interfaces/Product'
import { useCategoryProducts } from '../services/useCategoryProducts'

const categoryForm = {
    name: ""
}

const ProductCategoryForm = () => {
    const { t } = useTranslation()
    const { ProductCategoryState } = useAppSelector(({ ProductCategoryState }) => ({ ProductCategoryState }))
    const { typeForm, isOpenForm, categoryToEdit } = ProductCategoryState
    const [form, setForm] = React.useState<ProductCategoriesProps>({...categoryForm})
    const { useRegisterCategoryProduct } = useCategoryProducts({ typeForm })
    const HandleSubmit = () =>  useRegisterCategoryProduct(form)

    React.useEffect(() => {
        if( isOpenForm && typeForm === "update" ){
            setForm(n => ({...n, name: categoryToEdit.name, _id: categoryToEdit._id }))
        }
    },[typeForm, isOpenForm, categoryToEdit])

    return(
        <Form onSubmit={HandleSubmit} >
            <Grid container spacing={1} >
                <Grid xs={12} >
                    <TextField required label={t('categoryName')} autoFocus value={form.name} onChange={({ target }) => setForm(n => ({...n, name: target.value}))} />
                </Grid>
                <Grid xs={12} >
                    <Button type="submit" > {t('createCategory')} </Button>
                </Grid>
            </Grid>
        </Form>
    )
}

export default ProductCategoryForm