import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface InitialStateProps {
  isOpenForm: boolean;
  branchToEdit: any;
  selectionToDelete: string[];
  isOpenAlertDeleteBranch: boolean;
}

const initialState: InitialStateProps = {
  isOpenForm: false,
  branchToEdit: { name: '', _id: '' },
  selectionToDelete: [],
  isOpenAlertDeleteBranch: false,
};
export const CustomerBranchSlice = createSlice({
  name: 'CustomerBranch',
  initialState,
  reducers: {
    displayBranchForm: (state, action: PayloadAction<boolean>) => {
      state.isOpenForm = action.payload;
    },
    closeBranchEditionForm: (state) => {
      state.branchToEdit = { name: '', _id: '' };
      state.isOpenForm = false;
    },
    loadBranchToEdit: (
      state,
      action: PayloadAction<InitialStateProps['branchToEdit']>
    ) => {
      state.branchToEdit = action.payload;
      state.isOpenForm = true;
    },
    selectBranchToDelete: (state, action: PayloadAction<string[]>) => {
      state.selectionToDelete = action.payload;
    },
    showAlertDeleteBranch: (state, action: PayloadAction<boolean>) => {
      state.isOpenAlertDeleteBranch = action.payload;
    },
  },
});

export const {
  displayBranchForm,
  closeBranchEditionForm,
  loadBranchToEdit,
  selectBranchToDelete,
  showAlertDeleteBranch,
} = CustomerBranchSlice.actions;

export default CustomerBranchSlice.reducer;
