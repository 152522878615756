import { useTranslation } from "react-i18next";

import AddRounded from "@mui/icons-material/AddRounded";

import { displayZoneForm, loadZoneToEdit } from "./../services/ZoneSlice";
import { useAppDispatch } from "../../../hooks/state";

import Box from "@component/Box";
import Tooltip from "@component/Tooltip";
import IconButton from "@component/IconButton";
import CustomerZoneInput from "../../Customer/components/CustomerZoneInput";

export const ZonesSearchBar = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const handleAutocompleteChange = (evet: any, zone: any) =>
    dispatch(loadZoneToEdit(zone));

  return (
    <>
      <Box display={"flex"} alignItems={"center"}>
        <CustomerZoneInput
          customerZoneId=""
          onChange={handleAutocompleteChange}
        />

        <Box ml={1}>
          <Tooltip title={t("createNewCustomerZone")}>
            <IconButton
              squareButton
              onClick={() => dispatch(displayZoneForm(true))}
            >
              <AddRounded />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
};
