import { useTranslation } from 'react-i18next';

import { useSnackbar } from 'notistack';

import {
  useDeleteCustomerBranchMutation,
  useRegisterCustomerBranchMutation,
  useUpdateCustomerBranchMutation,
} from '../../../api/querys/Customer';
import { CustomerBranchProps } from '../../../interfaces/Customer';

export const useCustomerBranch = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [
    registerCustomerBranch,
    { isLoading: registerCustomerBranchIsLoading },
  ] = useRegisterCustomerBranchMutation();
  const [updateCustomerBranch, { isLoading: updateCustomerBranchIsLoading }] =
    useUpdateCustomerBranchMutation();
  const [deleteCustomerBranch, { isLoading: deleteCustomerBranchIsLoading }] =
    useDeleteCustomerBranchMutation();

  const registerCustomerBranchMutation = async (payload: CustomerBranchProps) => {
    if (payload.name.trim() === '') {
      enqueueSnackbar(t('branchNameIsARequiredField'), {
        variant: 'warning',
      });
      return;
    }
    if (Boolean(payload._id) && payload.name.trim() === '') {
      enqueueSnackbar(
        t(
          'itSeemsThatAnImportantPieceOfInformationIsMissingToUpdateTheProduct'
        )!,
        { variant: 'error' }
      );
      return;
    }
    const resp = Boolean(payload._id)
      ? await updateCustomerBranch(payload).unwrap()
      : await registerCustomerBranch(payload).unwrap();
    if (resp?.status === 1) {
      const message = Boolean(payload._id)
        ? t('theBranchWasSuccessfullyUpdated')
        : t('theBranchWasRegisteredSuccessfully')!;
      enqueueSnackbar(message, { variant: 'success' });
    } else {
      enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest')!, {
        variant: 'error',
      });
    }
  };

  const deleteCustomerBranchMutation = async (payload: string[]) => {
    if (payload.length <= 0) {
      enqueueSnackbar(t('branchSuccessfullyRemoved')!, {
        variant: 'warning',
      });
      return;
    }
    const resp = await deleteCustomerBranch(payload).unwrap();
    if (resp.status === 1) {
      enqueueSnackbar(t('branchesWasDeleted')!, {
        variant: 'success',
      });
      return;
    } else {
      enqueueSnackbar(
        t(
          'itSeemsThatAnImportantPieceOfInformationIsMissingToUpdateTheProduct'
        )!,
        { variant: 'error' }
      );
      return;
    }
  };
  return {
    registerCustomerBranchMutation,
    deleteCustomerBranchMutation,
    registerCustomerBranchIsLoading,
    updateCustomerBranchIsLoading,
    deleteCustomerBranchIsLoading,
  };
};
