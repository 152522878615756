import { api, mutationConfig } from "../splitApi";

import { ZoneData } from "../../interfaces/Zones";
import { AsyncResponse } from "../../interfaces/Reponse";

const Zone = api.injectEndpoints({
  endpoints: (build) => ({
    registerZone: build.mutation<
      AsyncResponse<ZoneData>,
      Pick<ZoneData, "name">
    >({
      query(body) {
        return { ...mutationConfig, url: "/register/customer/zone", body };
      },
      invalidatesTags: ["Zones"],
    }),
    updateZone: build.mutation<
      AsyncResponse<ZoneData>,
      Pick<ZoneData, "name" | "_id">
    >({
      query(body) {
        return { ...mutationConfig, url: "/update/customer/zone", body };
      },
      invalidatesTags: ["Zones"],
    }),
    deleteZones: build.mutation<any, string[]>({
      query(body) {
        return { ...mutationConfig, url: "/delete/customer/zones", body };
      },
      invalidatesTags: ["Zones"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useRegisterZoneMutation,
  useUpdateZoneMutation,
  useDeleteZonesMutation,
} = Zone;
