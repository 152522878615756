import { useFormik, FormikValues } from 'formik';
import * as yup from 'yup';

interface Props {
    initialValues: FormikValues
    yupRules: yup.AnyObject
    onSubmit?: (params:FormikValues) => void
}

export const useFormikProductPreSelectionWindow = ({ initialValues={}, yupRules={}, onSubmit }:Props) => {
    const validationSchema = yup.object(yupRules);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if( onSubmit !== undefined ){
                onSubmit(values)
                formik.resetForm()
            }
        },
    });

    return { formik }
}