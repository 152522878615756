import React from 'react'
import List, { ListItem, ListItemText } from "@component/List"
import { useParams } from "react-router-dom"
import { usePurchaseData } from "../hooks/usePurchaseReport"
import { currencyFormat } from '@hook/useNumberFormat'
import PersonRounded from '@mui/icons-material/PersonRounded';
import { ListItemIcon } from '@mui/material'
import NumbersRounded from '@mui/icons-material/NumbersRounded';
import CreditCardRounded from '@mui/icons-material/CreditCardRounded';
import AttachMoneyRounded from '@mui/icons-material/AttachMoneyRounded';
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import CalendarMonthRounded from '@mui/icons-material/CalendarMonthRounded';
import EditRounded from '@mui/icons-material/EditRounded';
import IconButton from '@component/IconButton'
import Tooltip from '@component/Tooltip'
import { useURLParams } from '@hook/useURLParams'
import DiscountRoundedIcon from '@mui/icons-material/DiscountRounded';

const PurchaseDetails = () => {
    const { handleParameterGroups } = useURLParams()
    const { purchaseId } = useParams()
    const { report } = usePurchaseData({ purchaseId: purchaseId! })
    const { t } = useTranslation()
    return(
        <>
            <List sx={{
                padding: 0,
                '& li': { 
                    padding: 0,
                }
            }} >
                <ListItem>
                    <ListItemText primary={report?.whoReceives??""} secondary="Recibido por" />
                    <ListItemIcon>
                        <PersonRounded />
                    </ListItemIcon>
                </ListItem>
                <ListItem>
                    <ListItemText primary={`F#${report?.invoiceNumber??""}`} secondary="Numero de factura" />
                    <ListItemIcon>
                        <NumbersRounded />
                    </ListItemIcon>
                </ListItem>
                <ListItem>
                    <ListItemText primary={`C#${report?.consecutive??""}`} secondary="Consecutivo" />
                    <ListItemIcon>
                        <NumbersRounded />
                    </ListItemIcon>
                </ListItem>
                <ListItem>
                    <ListItemText primary={t(report?.typePayment??"")} secondary="Tipo de pago" />
                    <ListItemIcon>
                        <CreditCardRounded />
                    </ListItemIcon>
                </ListItem>
                <ListItem>
                    <ListItemIcon sx={{ display: 'flex', alignItems: 'center', minWidth: "40px" }} >
                        <Tooltip title="Editar fecha de vencimiento" >
                            <IconButton color='primary' size='small' onClick={() => handleParameterGroups(["field", "open_modal", "_id"], ["dueDate", "edit_purchase_data", (report?._id??"")])} >
                                <EditRounded />
                            </IconButton>
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={dayjs((report?.dueDate??"")).format("DD-MM-YYYY")} secondary="Fecha de vencimiento" />
                    <ListItemIcon sx={{ display: 'flex', alignItems: 'center' }} >
                        <CalendarMonthRounded />
                    </ListItemIcon>
                </ListItem>
                <ListItem>
                    <ListItemText primary={`$ ${currencyFormat((report?.discount??0))}`} secondary="Descuento" />
                    <ListItemIcon>
                        <DiscountRoundedIcon />
                    </ListItemIcon>
                </ListItem>
                {report?.payments?.map((pay, index) => {
                    return(
                        <React.Fragment key={index} >
                            <ListItem  >
                                <ListItemText primary={`$ ${currencyFormat(pay?.payment??0)}`} secondary={`Abono - ${t(pay?.box??"")}`} />
                                <ListItemIcon>
                                    <AttachMoneyRounded />
                                </ListItemIcon>
                            </ListItem>
                        </React.Fragment>
                    )
                })}
            </List>
        </>
    )
}

export default PurchaseDetails