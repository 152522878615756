import DataGrid, { GridColDef, GridEventListener } from "@component/DataGrid"
import { currencyFormat } from "@hook/useNumberFormat"
import dayjs from "dayjs"
import { useParams } from "react-router"
import { useGetProvider } from "../hooks/useProvider"
import { useTranslation } from "react-i18next"
import Grid from "@component/Grid"
import List, { ListItem, ListItemText } from "@component/List"
import { useURLParams } from "@hook/useURLParams"
import UpdateAdvanceValue from "./UpdateAdvanceValue"

const ListOfAdvancesToSupplier = () => {
    const { handleParameterGroups, delQueries } = useURLParams()
    const { providerId } = useParams()
    const { advances, totalAdvance, provider } = useGetProvider({ providerId: providerId! })
    const { t } = useTranslation()
    const columns:GridColDef[] = [
        { flex: 1, field: "box", headerName: "Caja", renderCell: ({ row }) => `${t(row?.box??"")}` },
        { maxWidth: 140, minWidth: 140, field: "amount", headerName: "Valor", renderCell: ({ row }) => `$ ${ currencyFormat(row.amount) }` },
        { maxWidth: 160, minWidth: 160, field: "createdAt", headerName: "Fecha de registro", renderCell: ({ row }) => `${ dayjs(row.createdAt).format("DD-MM-YYYY HH:mm A") }`},
    ]
    const handleCellClick:GridEventListener<"cellClick"> = ({ field, id }) => {
        if( field === "amount" ){
            handleParameterGroups(['field', '_id'], [field, id as string])
        }else{
            delQueries(['field', 'ref'])
        }
    }
    return(
        <>
            <Grid container >
                <Grid xs={12} >
                    <List sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }} >
                        <ListItem>
                            <ListItemText primaryTypographyProps={{ fontWeight: 'bold' }} primary={`$ ${currencyFormat(totalAdvance)}`} secondary="Total en anticipos" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primaryTypographyProps={{ fontWeight: 'bold' }} primary={`$ ${currencyFormat(provider.paymentAvailable)}`} secondary="Anticipo disponible" />
                        </ListItem>
                    </List>
                </Grid>
                <Grid xs={12} >
                    <DataGrid
                        onCellClick={handleCellClick}
                        columns={columns}
                        rows={advances}
                    />
                </Grid>
            </Grid>
            <UpdateAdvanceValue />
        </>
    )
}

export default ListOfAdvancesToSupplier