import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useGetProductQuery } from "../../../api/querys/Product"
import Box from "@component/Box"
import Fade from "@component/Fade"
import List from "@component/List"
import ListItemData from "@component/ListItemData"
import { currencyFormat } from "../../../hooks/useNumberFormat"
import { ProductAvatar } from "./ProductsList"

const InformationProduct = () => {
    const { productId } = useParams()
    const { data } = useGetProductQuery(productId)
    const product = data?.payload
    const { t } = useTranslation()
    return(
        <>
            <Fade in={true} >
                <List>
                    <ListItemData showAvatar avatar={<ProductAvatar imagePath={product?.image??""} />} primaryText={t('name')!} secondaryText={`${product?.name??""}`} />
                    <ListItemData primaryText={t('cost')!} secondaryText={`$ ${ currencyFormat(product?.cost??0) }`} />
                    <ListItemData primaryText={t('category')!} secondaryText={`${product?.category?.name??""}`} />
                    <ListItemData primaryText={t('packaging')!} secondaryText={`${(product?.packaging?.name??"")} - ${product?.packaging?.quantity??0}`} />
                    <ListItemData primaryText={t('SKUCode')!} secondaryText={`${product?.skuCode??""}`} />
                    {(!product?.compount??false) &&
                        <ListItemData primaryText={t('stock')!} secondaryText={`${product?.stock??""}`} />
                    }
                    {(product?.compount??false) &&
                        <ListItemData primaryText={t('compound')!} secondaryText={`${product?.productParent?.name??""}`} />
                    }
                    {(product?.compount??false) &&
                        <ListItemData primaryText={t('stock')!} secondaryText={`${ Number((product?.productParent?.stock??0)/(product?.packaging?.quantity??0)).toFixed(2) }`} />
                    }
                    { (product !== undefined && product !== null) && product!.prices.map((price, index) => {
                        return(
                            <Box key={index} component={'span'} >
                                <ListItemData primaryText={`${t('salePrice')} ${index+1}: $ ${currencyFormat(price?.sellPrice??0)}`} secondaryText={`$ ${currencyFormat(price?.profit??0) } - ${currencyFormat(price?.percentageProfit??0)} %`} />
                            </Box>
                        )
                    }) }
                </List>
            </Fade>
        </>
    )
}

export default InformationProduct