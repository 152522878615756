import GroupsRounded from "@mui/icons-material/GroupsRounded"
import QueryStatsRounded from "@mui/icons-material/QueryStatsRounded"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useGetSessionDataQuery } from "../../../api/querys/Users"
import AppBar from "@component/AppBar"
import Avatar from "@component/Avatar"
import Box from "@component/Box"
import IconButton from "@component/IconButton"
import List, { ListItem, ListItemAvatar, ListItemText } from "@component/List"
import Toolbar from "@component/Toolbar"
import Tooltip from "@component/Tooltip"
import Typography from "@component/Typography"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { useBusinessPermissions } from "../../../hooks/usePermissions"
import Routes from "../../../services/routes"
import { openPointOfSaleWindow, showFormCreateQuickClient, showFormCreateQuickProduct } from "../services/PointOfSaleSlice"
import DataSaverOnRounded from '@mui/icons-material/DataSaverOnRounded';
import { useCashierReport } from "@feature/MovementsCashier/hooks/useMovementsCashier"
import dayjs from "dayjs"
import { currencyFormat } from "@hook/useNumberFormat"

const AppBarPOS = () => {

    const { PointOfSale } = useBusinessPermissions()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }))
    const { invoiceIdentifiers } = PointOfSaleState
    const { data } = useGetSessionDataQuery()
    const _id = data?.payload?._id??""
    const name = data?.payload?.name??""
    const lastName = data?.payload?.lastName??""
    const rol = data?.payload?.rol?.name??""
    const { t } = useTranslation()
    const { summary } = useCashierReport({ startDate: dayjs().format("MM-DD-YYYY"), endDate: dayjs().format("MM-DD-YYYY"), cashierId: _id })
    const goToReports = () => {
        dispatch(openPointOfSaleWindow(false))
        navigate(Routes.indexReportOfTheDay())
    }

    const handleOpenCreateFastCustomer  = () => {
        dispatch(showFormCreateQuickClient(true))
    }

    return(
        <AppBar elevation={0} sx={ Boolean(invoiceIdentifiers._id) ? { backgroundColor: (theme) => theme.palette.warning.dark } : {}} >
            <Toolbar>
                <Box display={'flex'} flexGrow={1} alignItems="center" >
                    <List sx={{ padding: 0, }} >
                        <ListItem sx={{ padding: 0 }} >
                            <ListItemAvatar>
                                <Avatar />
                            </ListItemAvatar>
                            <ListItemText primaryTypographyProps={{ fontWeight: "bold" }} primary={`${name} ${lastName}`} secondaryTypographyProps={{ fontWeight: 500, color: "#FFFFFF" }} secondary={`${t(rol!)} - en caja $ ${currencyFormat(summary.inCash)}`} />
                        </ListItem>
                    </List>
                    {Boolean(invoiceIdentifiers._id) &&
                        <Box ml={2} >
                            <Typography fontWeight={'bold'} textTransform={'uppercase'} >Consecutivo: #R{invoiceIdentifiers.consecutive} </Typography>
                        </Box>
                    }
                </Box>
                <Box display={'flex'} >
                    <>
                        {PointOfSale.create_customer_from_point_of_sale &&
                            <Tooltip title={t('createCustomer')!} >
                                <IconButton id="createCustomerPOS" color="inherit" onClick={handleOpenCreateFastCustomer} >
                                    <GroupsRounded sx={{ fontSize: '2.5rem' }} />
                                </IconButton>
                            </Tooltip>
                        }
                        {PointOfSale.create_product_from_point_of_sale &&
                            <Tooltip title={t('createProduct')!} >
                                <IconButton id="createProductPOS" color="inherit" onClick={() => dispatch(showFormCreateQuickProduct(true))} >
                                    <DataSaverOnRounded sx={{ fontSize: '2.5rem' }} />
                                </IconButton>
                            </Tooltip>
                        }
                        {PointOfSale.go_to_reports_from_point_of_sale &&
                            <Tooltip title={t('Ver informes')!} >
                                <IconButton id="goToReports" color="inherit" onClick={goToReports} >
                                    <QueryStatsRounded sx={{ fontSize: '2.5rem' }} />
                                </IconButton>
                            </Tooltip>
                        }
                    </>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default AppBarPOS