import { CardProps } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from "@component/Box"
import Card from "@component/Card"
import Divider from "@component/Divider"
import LockComponent from '../../../components/LockComponent';
import Typography from "@component/Typography"
import { useAppSelector } from '../../../hooks/state';
import { currencyFormat } from '../../../hooks/useNumberFormat';
import { useBusinessPermissions } from '../../../hooks/usePermissions';
import { InvoiceProps } from '../../../interfaces/PointOfSale'
import TemplateCreditNote from './TemplateCreditNote';
import Stack from '@component/Stack';

interface Props extends InvoiceProps{
    sx?: CardProps['sx']
}

const TemplateInvoice = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { AppState } = useAppSelector(({ AppState }) => ({ AppState }))
    const { invoiceAlphabeticalOrder } = AppState
    const { Invoice } = useBusinessPermissions()
    let { business, consecutive, createdAt, seller, customer, products, total, sx, invoiceType, creditNote } = props

    const { t } = useTranslation()
    products = products??[] as InvoiceProps['products']
    const productsAlphabeticalOrder = [...products].sort((a,b) => a.name.localeCompare(b.name))
    const details = invoiceAlphabeticalOrder === "default" ? products : productsAlphabeticalOrder
    return(
        <Stack width={1} spacing={1} >
            <Card className='templateInvoice' ref={ref} sx={{ width: "100%", height: "max-content", padding: 1.5, ...sx }} >
                {Invoice.view_information ?
                    <>
                        {invoiceType === "price" ?
                            <Box>
                                <Typography lineHeight={1.2} fontWeight={'bold'} fontSize={"1.5rem"} textAlign={'center'} textTransform={'uppercase'} >Orden de pedido</Typography>
                                <Typography lineHeight={1.2} fontWeight={'bold'} fontSize={"1.2rem"} textAlign={'center'} > UNIKO </Typography>
                            </Box>
                            :
                            <Box>
                                <Typography lineHeight={1.2} fontWeight={'bold'} fontSize={"1.2rem"} textAlign={'center'} >{ business?.name??"" }</Typography>
                                <Typography lineHeight={1.2} fontWeight={'500'} fontSize={"1rem"} textAlign={'center'} > Tel. { business?.phone??"" } </Typography>
                                <Typography lineHeight={1.2} fontWeight={'500'} fontSize={"1rem"} textAlign={'center'} > {business?.address??""} </Typography>
                                <Typography lineHeight={1.2} fontWeight={'500'} fontSize={"1rem"} textAlign={'center'} > {business?.nit ? business?.nit : ""} </Typography>
                            </Box>
                        }
                        <Box mt={1} display="flex" justifyContent={'space-between'} >
                            <Typography lineHeight={1.2} fontSize={".7rem"} fontWeight={'500'} > {invoiceType === "price" ? "#C" : "#R"} {consecutive} </Typography>
                            <Typography lineHeight={1.2} fontSize={".7rem"} >{t('date')}:  {dayjs(createdAt).format("DD-MM-YYYY HH:mm:ss")} </Typography>
                        </Box>
                        <Box mb={1} >
                            <Typography lineHeight={1.2} fontSize={".8rem"} > {t('seller')} : {seller?.name??""} </Typography>
                        </Box>
                        <Divider />
                        <Box my={1} >
                            <Typography lineHeight={1.2} fontSize={".7rem"} >{t('customer')}: {customer?.name??""} </Typography>
                            <Typography lineHeight={1.2} fontSize={".7rem"} >Tel: {customer?.phoneNumber??""} </Typography>
                            <Typography lineHeight={1.2} fontSize={".7rem"} >{t('address')}: {customer?.address??""} </Typography>
                            <Typography lineHeight={1.2} fontSize={".7rem"} >{t('city')}: {customer?.city??""} </Typography>
                        </Box>
                        <Divider />
                        {(details !== undefined) &&
                            details?.map((product, index) => {
                                return(
                                    <Box key={index} >
                                        <Box my={"2px"} display={'flex'} flexDirection={'column'} >
                                            <Typography lineHeight={1.2} fontSize={".8rem"} fontWeight='bold' > {product?.name??""} </Typography>
                                            <Box display={'flex'} justifyContent={'space-between'} >
                                                <Box display={'flex'} >
                                                    <Typography mr={1} lineHeight={1.2} fontSize={".8rem"} > x{product?.quantity??0} </Typography>
                                                    <Typography lineHeight={1.2} fontSize={".8rem"} > $ { currencyFormat(product?.salePrice??0) } </Typography>
                                                </Box>
                                                <Typography lineHeight={1.2} fontSize={".8rem"} > $ {currencyFormat(product.salePrice*product.quantity)} </Typography>
                                            </Box>
                                        </Box>
                                        <Divider
                                            sx={{
                                                borderColor: "#00000078"
                                            }}
                                        />
                                    </Box>
                                )
                            })
                        }
                        <Box my={1} >
                            <Typography textAlign={'right'} lineHeight={1.2} fontSize={"1rem"} textTransform={'uppercase'} fontWeight={'bold'} >{t('total')}: $ { currencyFormat(total??0) } </Typography>
                        </Box>
                    </>
                    :
                    <>
                        <LockComponent />
                    </>
                }
            </Card>
            <TemplateCreditNote creditNote={creditNote} />
        </Stack>
    )
})

export default TemplateInvoice