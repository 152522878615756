import React from 'react';
import InputAdornment from "./InputAdornment"
import TextField from "./TextField"
import RemoveRedEyeRounded from '@mui/icons-material/RemoveRedEyeRounded';
import VisibilityOffRounded from '@mui/icons-material/VisibilityOffRounded';

type P = React.ComponentProps<typeof TextField>

interface Props extends P {}

const TextFieldPassword = ({...rest}:Props) => {
    const [viewPassword, setViewPassword] = React.useState<boolean>(false)
    return(
        <TextField
            {...rest}
            type={viewPassword ? 'text' : 'password'}
            label="Contraseña"
            InputProps={{
                endAdornment:(
                    <InputAdornment position="end" sx={{ cursor: 'pointer' }} onClick={() => setViewPassword(!viewPassword)} >
                        {viewPassword ? <VisibilityOffRounded /> : <RemoveRedEyeRounded />}
                    </InputAdornment>
                )
            }}
        />
    )
}

export default TextFieldPassword