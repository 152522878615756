import { useTranslation } from "react-i18next"
import Typography from "../../../../../components/Typography"
import { useAppSelector } from "../../../../../hooks/state"
import { currencyFormat } from "../../../../../hooks/useNumberFormat"
import { ListProductsInvoice } from "@interface/PointOfSale"

interface Props {
    manualPrice: number
    quantity: number
    pricePosition: number
    typePrice: ListProductsInvoice['typePrice']
}

const WindowProductPOSTotalize = ({ manualPrice, pricePosition, quantity, typePrice }:Props) => {
    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }))
    const { productSelected,  } = PointOfSaleState.windowProduct
    const product = {...productSelected}
    const { t } = useTranslation()
    return(
        <>
            <Typography fontWeight={'bold'} fontSize={'1.2rem'} >
                {t('total')}: {typePrice === "sellPrice" ? 
                    ( currencyFormat((product?.prices?.[pricePosition]?.sellPrice??0)*quantity) )
                    :
                    ( currencyFormat(manualPrice*quantity) )
                }
            </Typography>
        </>
    )
}

export default WindowProductPOSTotalize