import { AllCashiersPropsAsyncResponse, CashierExpenseAsyncResponse, CashierFormProps, CashierPropsAsyncResponse, CashiersSummaryAsyncResponse, CashierSummaryAsyncResponse, CashierSummaryDetailsAsyncResponse, FilterMovementsCashierProps, MovementsCashierPropsAsyncResponse, MovementsConsignmentCashierPropsAsyncResponse, TransferToPettyCashAsyncResponse, TransferToPettyCashFormProps } from "@interface/Cashier"
import { api, mutationConfig } from "../splitApi"

const Cashier = api.injectEndpoints({
    endpoints: (build) => ({
        getCashier: build.query<CashierPropsAsyncResponse, { cashierId: string }>({
            query: ({cashierId}) => ({
                url: '/get/cashier',
                params: {cashierId}
            }),
            providesTags: ['Cashier']
        }),
        getBusinessCashiers: build.query<AllCashiersPropsAsyncResponse, void>({
            query: () => ({
                url: '/get/business/cashiers',
            }),
            providesTags: ['Cashier']
        }),
        getCashierSummary: build.query<CashierSummaryAsyncResponse, FilterMovementsCashierProps>({
            query: ({ cashierId, endDate, startDate }) => ({
                url: '/get/cashier/summary',
                params: { cashierId, endDate, startDate }
            }),
            providesTags: ['Cashier']
        }),
        getCashierSummaryDetails: build.query<CashierSummaryDetailsAsyncResponse, FilterMovementsCashierProps>({
            query: ({ cashierId, endDate, startDate }) => ({
                url: '/get/cashier/summary/details',
                params: { cashierId, endDate, startDate }
            }),
            providesTags: ['Cashier']
        }),
        getCashiersSummary: build.query<CashiersSummaryAsyncResponse, Omit<FilterMovementsCashierProps, "cashierId">>({
            query: ({ endDate, startDate }) => ({
                url: '/get/cashiers/summary',
                params: { endDate, startDate }
            }),
            providesTags: ['Cashier']
        }),
        registerNewCashier: build.mutation<CashierPropsAsyncResponse, CashierFormProps>({
            query(body) {
                return {...mutationConfig, url: '/register/new/cashier', body}
            },
            invalidatesTags: ['Cashier', 'MinorBox'],
        }),
        transferToPettyCash: build.mutation<TransferToPettyCashAsyncResponse, TransferToPettyCashFormProps>({
            query(body) {
                return {...mutationConfig, url: '/transfer/to/petty/cash', body}
            },
            invalidatesTags: ['Cashier', 'MinorBox'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetCashierQuery,
    useGetBusinessCashiersQuery,
    useRegisterNewCashierMutation,
    useGetCashierSummaryQuery,
    useGetCashierSummaryDetailsQuery,
    useGetCashiersSummaryQuery,
    useTransferToPettyCashMutation
} = Cashier