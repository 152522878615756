import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import { removeProductFromList, showCashierWindow } from '../services/PointOfSaleSlice';

interface Props {
    children: React.ReactNode
}

const onClickCellDataGridActive = () => {
    const activeElement = document.activeElement
    const searchBarFocus = activeElement?.id??""
    if( activeElement !== null && searchBarFocus !== 'searchBarPOS' ){
        const props = activeElement.getAttribute('data-colindex')
        if( Number(props) >= 0 ){
            const selectorDataGrid = activeElement.getAttribute('role')
            if( selectorDataGrid === 'cell' ){
                if( activeElement !== null && activeElement !== undefined ){
                    activeElement.id = "dataGridSelected"
                    const dataGridColSelected = document.getElementById('dataGridSelected')
                    if( dataGridColSelected !== null ){
                        dataGridColSelected.click()
                        activeElement.id = ""
                    }
                }
            }
        }
    }
}

const ProviderKeyboardShortcutsPOS = ({ children }:Props) => {
    const dispatch = useAppDispatch()
    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }))
    const { windowProduct, productList, isOpenCashierWindow } = PointOfSaleState
    const { openWindow } = windowProduct
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handlerKeyboardShortcutsPOS = (keyboardEvent:KeyboardEvent) => {
        const { keyCode, which } = keyboardEvent
        const code = keyCode??which
        const lockKey:Array<number> = [113, 114, 115, 116, 117, 123, 33, 34, 36, 46]
        if( lockKey.indexOf(code) >= 0 ){
            keyboardEvent.preventDefault()
            keyboardEvent.stopPropagation()
        }

        switch (code) {
            case 13: //enter key
                if( openWindow === false ){
                    onClickCellDataGridActive()
                }
            break;
            case 8: //back delete key
                const activeElement = document.activeElement
                const firstChild = activeElement && (activeElement.firstChild as HTMLElement)
                const firstChildId = firstChild?.id??""
                if( firstChildId !== null ){
                    const sliceId = firstChildId.split('-')
                    const productId = (sliceId.length === 2 && sliceId[0] === "item") ? sliceId[1] : null
                    if( productId !== null ){
                        dispatch(removeProductFromList(productId))
                    }
                }
            break;
            case 113: //f2 key
                if( openWindow === false ){
                    const elementSearchBarPOS = (document.getElementById('searchBarPOS') as HTMLInputElement)
                    if( elementSearchBarPOS !== null ){
                        elementSearchBarPOS.focus()
                    }
                }
            break;
            case 114: //f3 key
                if( openWindow === false ){
                    const querySelectorAllDataGrid = document.querySelectorAll('div[data-colindex="0"]')
                    const colDataGrid = querySelectorAllDataGrid[querySelectorAllDataGrid.length-productList.length]
                    if( colDataGrid !== null && colDataGrid !== undefined ){
                        colDataGrid.id = "dataGridSelected"
                        const dataGridColSelected = document.getElementById('dataGridSelected')
                        if( dataGridColSelected !== null ){
                            dataGridColSelected.focus()
                            colDataGrid.id = ""
                        }
                    }
                }
            break;
            case 115: //F4 key
                const recordSaleButton = document.getElementById('recordSale')
                if( recordSaleButton !== null ){
                    recordSaleButton.click()
                }
            break;
            case 116: //F5 key
                const recordPriceButton = document.getElementById('recordPrice')
                if( recordPriceButton !== null ){
                    recordPriceButton.click()
                }
            break;
            case 117: // F6 key
                const recordSalWithPrintElementButton = document.getElementById('recordSalWithPrintElement')
                if( recordSalWithPrintElementButton !== null ){
                    recordSalWithPrintElementButton.click()
                }
            break;
            case 123: // f12 key
                const goToReportsElementButton = document.getElementById('goToReports')
                if( goToReportsElementButton !== null ){
                    goToReportsElementButton.click()
                }
            break;
            
            case 36: //home key
                const printLastInvoiceButton = document.getElementById('printLastInvoice')
                if( printLastInvoiceButton !== null ){
                    printLastInvoiceButton.click()
                }
            break;
            case 46: // supr key 
                const cleanBillButton = document.getElementById('cleanBill')
                if( cleanBillButton !== null ){
                    cleanBillButton.click()
                }
            break;
            case 33: // re pag key 
                if( isOpenCashierWindow ){
                    dispatch(showCashierWindow(false))
                }else{
                    dispatch(showCashierWindow(true))
                }
            break;
            case 34: // Av pag key 
            if( isOpenCashierWindow ){
                dispatch(showCashierWindow(false))
            }else{
                dispatch(showCashierWindow(true))
            }
        break;
            default:
            break;
        }
    }

    React.useEffect(() => {
            window.addEventListener('keydown', handlerKeyboardShortcutsPOS, true)
        return () => {
            window.removeEventListener('keydown', handlerKeyboardShortcutsPOS, true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[openWindow, productList, isOpenCashierWindow])
    
    return(
        <>
            {children}
        </>
    )
}

export default ProviderKeyboardShortcutsPOS