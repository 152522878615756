import { Chip as ChipMUI, ChipProps } from "@mui/material"

interface Props extends Pick<ChipProps, "label" | "onClick" | "onDelete" | "deleteIcon" | "color" | "variant" | "size" | "sx" > {}

const Chip = ({...rest}:Props) => {
    return(
        <ChipMUI {...rest} />
    )
}

export default Chip