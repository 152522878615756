import React from 'react'
import type { CustomerPurchaseTrackingProps, CustomerPurchaseTrackingPropsCustomers, ProductPurchaseTrackingProps, ProductPurchaseTrackingPropsCustomers } from '../../../interfaces/PointOfSale'
import { calcProfitPercentage, currencyFormat } from '../../../hooks/useNumberFormat'

export const useCustomerPurchaseTracking = (data:Array<CustomerPurchaseTrackingProps>) => {
    const organizeData = React.useCallback((customers:Array<CustomerPurchaseTrackingPropsCustomers>) => {
        let complete:any[] = structuredClone(customers)
        const dates = data.map(n => n._id)
        for( let dateIndex in dates ){
            const results = data[dateIndex].results
            for( let customerIndex in customers ){
                const customer = customers[customerIndex]
                const existInvoices = results.filter(n => n.customer._id === customer.customerId)
                complete[customerIndex][`invoices_${data[dateIndex]._id}`] = existInvoices
                complete[customerIndex][data[dateIndex]._id] = {
                    total: existInvoices.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0 ),
                    profitCurrency: existInvoices.reduce((accumulator, currentValue) => accumulator + currentValue.totalProfitCurrency, 0 ),
                    profitPercentage: calcProfitPercentage(
                        (existInvoices.reduce((accumulator, currentValue) => accumulator + currentValue.totalProfitCurrency, 0 )),
                        (existInvoices.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0 )),
                        true
                    )
                }
                complete[customerIndex]['totalSale'] = existInvoices.reduce((accumulator, currentValue) => accumulator + currentValue.total, complete[customerIndex]['totalSale']??0 )
                complete[customerIndex]['totalProfit'] = existInvoices.reduce((accumulator, currentValue) => accumulator + currentValue.totalProfitCurrency, complete[customerIndex]['totalProfit']??0 )
            
                if( !Boolean(complete[customerIndex]._id) ){
                    complete[customerIndex]._id = complete[customerIndex].customerId
                    delete complete[customerIndex].customerId
                }
            }
        }
        return { purchaseTrackingList: complete, dates }
    },[data])
    return { organizeData }
}

export const useProductPurchaseTracking = (data:Array<ProductPurchaseTrackingProps>) => {
    const organizeData = React.useCallback((products:Array<ProductPurchaseTrackingPropsCustomers>) => {
        const dates = data.map(n => n._id)
        const productList = structuredClone(products)
        
        for( let datesIndex in dates ){
            const results = data[datesIndex].results
            for( let productsIndex in products ){
                const product = products[productsIndex]
                const existInvoices = results.filter(n => n.product.productId === product._id)
                productList[productsIndex]['consecutive'] = existInvoices[0]?.consecutive??0
                productList[productsIndex][`${dates[datesIndex]}`] = `${existInvoices[0]?.product?.quantity??0} - $ ${currencyFormat(existInvoices[0]?.product?.salePrice??0)}`
            }
        }

        return { productTrackingList: productList, dates }
    },[data])
    return { organizeData }
}