import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import Button from "@component/Button"
import Dialog, { DialogActions, DialogContent } from "@component/Dialog"
import Typography from "@component/Typography"
import { useAppSelector } from "../../../hooks/state"
import { useProducts } from "../hooks/useProducts"
import { showProductDeletionAlert } from "../services/ProductSlice"

const AlertDeleteProducts = () => {
    const { ProductState } = useAppSelector(({ ProductState }) => ({ ProductState }))
    const { isTheProductDeletionAlertOpen, productSelection } = ProductState
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { deleteProducts, isLoadingDeleteProduct } = useProducts({})
    return(
        <Dialog fullWidth open={isTheProductDeletionAlertOpen} onClose={() => dispatch(showProductDeletionAlert(false))} maxWidth="xs" >
            <DialogContent sx={{ paddingBottom: "0px" }} >
                <Typography textAlign={'center'} >
                    ¿{t('areYouSureYouWantToPerformThisAction')}?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button loading={isLoadingDeleteProduct} variant="text" color="error" onClick={() => deleteProducts(productSelection)} > {t('remove')} </Button>
                <Button loading={isLoadingDeleteProduct} variant="text" onClick={() => dispatch(showProductDeletionAlert(false))} > {t('cancel')} </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AlertDeleteProducts