import React from "react"
import { useParams } from "react-router-dom"
import { useGetProviderQuery } from "../../../api/querys/Provider"
import type { ProviderPropsForm } from "@interface/Provider"
import ProviderForm from "./ProviderForm"

const EditProvider = () => {
    const { providerId } = useParams()
    const { data } = useGetProviderQuery({ providerId: providerId! })
    const provider = data?.payload
    const editProvider:Omit<ProviderPropsForm, "tax"> & {tax: string} = React.useMemo(() => {
        let form:Omit<ProviderPropsForm, "tax"> & {tax: string} = { name: "",address: "",city: "",code: "",ivaResponsible: false,nit: "",phone: "",representative: "",_id: "", retefuente: false, tax: "" }
        if( Boolean(provider?._id) ){
            form._id = provider?._id??""
            form.address = provider?.address??""
            form.city = provider?.city??""
            form.code = provider?.code??""
            form.ivaResponsible = provider?.ivaResponsible??false
            form.name = provider?.name??""
            form.nit = provider?.nit??""
            form.phone = provider?.phone??""
            form.representative = provider?.representative??""
            form.tax = provider?.tax?._id??""
            form.typeOfBankAccount = provider?.typeOfBankAccount??""
            form.bankAgreementNumber = provider?.bankAgreementNumber??""
            form.bank = provider?.bank??""
            form.bankAccountNumber = provider?.bankAccountNumber??undefined
            form.retefuente = provider?.retefuente??false
        }
        return form
    },[provider])
    return(
        <>
            <ProviderForm editProvider={editProvider} />
        </>
    )
}

export default EditProvider