import Title from '@component/Title';
import Grid from '../../components/Grid';
import LockComponent from '../../components/LockComponent';
import { useBusinessPermissions } from '../../hooks/usePermissions';
import CreateNeighborhoods from './components/CreateNeighborhoods';
import NeighborhoodsList from './components/NeighborhoodsList';
import NeighborhoodsSearchBar from './components/NeighborhoodsSearchBar';

const CustomerNeighborhoods = () => {
    const { Customers } = useBusinessPermissions()
    return (
        <>
            {Customers.filters ?
                <Grid container spacing={1} >
                    <Grid xs={12}>
                        <Title text='Barrios clientes' />
                    </Grid>
                    <Grid xs={12}>
                        <NeighborhoodsSearchBar />
                    </Grid>
                    <Grid xs={12}><NeighborhoodsList /></Grid>
                    <CreateNeighborhoods />
                </Grid>
                :
                <LockComponent />
            }
        </>
    );
};

export default CustomerNeighborhoods;
