import { useTranslation } from "react-i18next"
import { useGetBusinessQuery } from "../../../api/querys/Business"
import Fade from "@component/Fade"
import List from "@component/List"
import ListItemData from "@component/ListItemData"

const BusinessData = () => {
    const { t } = useTranslation()
    const { data } = useGetBusinessQuery()
    const business = data?.payload

    return(
        <Fade in={true} >
            <List>
                <ListItemData showAvatar primaryText={t('name')!} secondaryText={business?.name??""} />
                <ListItemData primaryText={t('Id')!} secondaryText={business?.nit??""} />
                <ListItemData primaryText={t('email')!} secondaryText={business?.email??""} />
                <ListItemData primaryText={t('phoneNumber')!} secondaryText={business?.phone??""} />
                <ListItemData primaryText={t('address')!} secondaryText={business?.address??""} />
            </List>
        </Fade>
    )
}

export default BusinessData