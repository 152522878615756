import Dialog, { DialogContent } from '../../../components/Dialog';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import {
  closeBusinessesTypeEditionForm,
  displayBusinessesTypeForm,
} from '../services/BusinessesTypeSlice';
import BusinessesTypeForm from './BusinessesTypeForm';

const CreateBusinessesType = () => {
  const dispatch = useAppDispatch();
  const { BusinessesTypeState } = useAppSelector(({ BusinessesTypeState }) => ({
    BusinessesTypeState,
  }));
  const { isOpenForm, BusinessesTypeToEdit } = BusinessesTypeState;

  const handleClose = () => {
    if (BusinessesTypeToEdit._id === '') {
      dispatch(displayBusinessesTypeForm(false));
    } else {
      dispatch(closeBusinessesTypeEditionForm());
    }
  };

  return (
    <Dialog
      open={isOpenForm}
      onClose={handleClose}
      fullWidth
    >
      <DialogContent>
        <BusinessesTypeForm />
      </DialogContent>
    </Dialog>
  );
};

export default CreateBusinessesType;
