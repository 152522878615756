import BoxTraslade from "@component/BoxTraslade"
import Button from "@component/Button"
import Dialog, { DialogContent } from "@component/Dialog"
import Form from "@component/Form"
import Grid from "@component/Grid"
import NumberFormat from "@component/NumberFormat"
import { useURLParams } from "@hook/useURLParams"
import { CustomerAdvanceFormProps } from "@interface/Customer"
import React from "react"
import { useCustomer } from "../hooks/useCustomer"

const CustomerAdvance = () => {

    const [customerAdvanceForm, setCustomerAdvanceForm] = React.useState<CustomerAdvanceFormProps>({ refId: "", amount: 0, box: "minorBox", bank: null })
    const { isOpenModalByURLParameter, delQueries } = useURLParams()
    const isOpen = isOpenModalByURLParameter("customer_advance")
    const { recordCustomerAdvance } = useCustomer()

    const handleSubmit = () => recordCustomerAdvance(customerAdvanceForm)

    React.useEffect(() => {
        if( !isOpen ){
            setCustomerAdvanceForm(n => ({...n, amount: 0, bank: null, box: "minorBox", refId: ""}))
        }
    },[isOpen])

    return(
        <>
            <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={() => delQueries(["modal", "ref"])} >
                <DialogContent>
                    <Form onSubmit={handleSubmit} >
                        <Grid container spacing={1} >
                            <Grid xs={12} >
                                <BoxTraslade
                                    value={customerAdvanceForm.box}
                                    useBanks
                                    label="Seleccione una caja de destino"
                                    onChange={(_, params:any) => setCustomerAdvanceForm(n => ({...n, box: params?.value??"minorBox"}))}
                                    onBankChange={(params:any) => setCustomerAdvanceForm(n => ({...n, bank: params?._id??null}))}
                                />
                            </Grid>
                            <Grid xs={12} >
                                <NumberFormat
                                    value={customerAdvanceForm.amount}
                                    prefix="$ "
                                    label="Monto"
                                    onChange={({ target }) => setCustomerAdvanceForm((n) => ({...n, amount: Number(target?.value??0)}))}
                                />
                            </Grid>
                            <Grid xs={12} >
                                <Button type="submit" > Registrar anticipo </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CustomerAdvance