import React from 'react'
import { useTranslation } from "react-i18next"
import Button from "@component/Button"
import Checkbox from "@component/Checkbox"
import Form from '../../../components/Form'
import Grid from "@component/Grid"
import NumberFormat from "@component/NumberFormat"
import TextField from "@component/TextField"
import type { ProviderPropsForm } from '../../../interfaces/Provider'
import RetentionInput from '../../Business/components/RetentionInput'
import { useProvider } from '../hooks/useProvider'
import Autocomplete from '@component/Autocomplete'

const form:Omit<ProviderPropsForm, "tax"> & {tax: string} = {
    name: "",
    nit: "",
    code: "",
    address: "",
    phone: "",
    city: "",
    representative: "",
    ivaResponsible: false,
    retefuente: false,
    tax: "",
    typeOfBankAccount: "",
    bankAccountNumber: undefined,
    bankAgreementNumber: "",
    bank: "",
}

interface Props {
    editProvider?: Omit<ProviderPropsForm, "tax"> & {tax: string}
}

const ProviderForm = ({ editProvider }:Props) => {
    const [providerForm, setProviderForm] = React.useState<Omit<ProviderPropsForm, "tax"> & {tax: string}>({...form})
    const { t } = useTranslation()
    const { registerProvider, isLoadingRegisterProvider, isLoadingUpdateProviderMutation } = useProvider()
    const handleSubmit = () => registerProvider(providerForm)
    const optionsAccounts = [
        {_id: 1, name: "Cuenta corriente", value: "currentAccount"},
        {_id: 2, name: "Cuenta de ahorro", value: "savingsAccount"},
    ]

    React.useEffect(() => {
        if( editProvider !== undefined ){
            setProviderForm({...editProvider})
        }
    },[editProvider])
    return(
        <Form onSubmit={handleSubmit} >
            <Grid container spacing={1} >
                <Grid xs={6} >
                    <TextField required value={providerForm.name} onChange={({ target }) => setProviderForm(n => ({...n, name: target.value}))} margin="none" label={t('provider')!} />
                </Grid>
                <Grid xs={6} >
                    <TextField value={providerForm.nit} onChange={({ target }) => setProviderForm(n => ({...n, nit: target.value}))} margin="none" label={t('nit')!} />
                </Grid>
                <Grid xs={6} >
                    <TextField value={providerForm.representative} onChange={({ target }) => setProviderForm(n => ({...n, representative: target.value}))} margin="none" label={t('representative')!} />
                </Grid>
                <Grid xs={6} >
                    <TextField value={providerForm.code} onChange={({ target }) => setProviderForm(n => ({...n, code: target.value}))} margin="none" label={t('code')!} />
                </Grid>
                <Grid xs={6} >
                    <NumberFormat thousandSeparator='' value={providerForm.phone} onChange={({ target }) => setProviderForm(n => ({...n, phone: target.value}))} label={t('phoneNumber')!} />
                </Grid>
                <Grid xs={6} >
                    <TextField value={providerForm.city} onChange={({ target }) => setProviderForm(n => ({...n, city: target.value}))} margin="none" label={t('city')!} />
                </Grid>
                <Grid xs={6} >
                    <Autocomplete
                        onChange={(_, params:any) => setProviderForm(n => ({...n, typeOfBankAccount: params?.value??"" })) }
                        label={t('Tipo de cuenta')!}
                        options={optionsAccounts}
                        value={optionsAccounts.find((n) => n.value === providerForm.typeOfBankAccount) || null}
                    />
                </Grid>
                <Grid xs={6} >
                    <TextField
                        margin='none'
                        value={providerForm.bank}
                        label={t('Banco')!}
                        onChange={({ target }) => setProviderForm((n) => ({...n, bank: (target?.value??"")}))}
                    />
                </Grid>
                <Grid xs={6} >
                    <TextField
                        margin='none'
                        value={providerForm.bankAccountNumber}
                        label={t('Numero de cuenta')!}
                        onChange={({ target }) => setProviderForm((n) => ({...n, bankAccountNumber: (target?.value??"")}))}
                    />
                </Grid>
                <Grid xs={6} >
                    <TextField
                        value={providerForm.bankAgreementNumber}
                        margin="none"
                        label={t('Numero de convenio')!}
                        onChange={({ target }) => setProviderForm((n) => ({...n, bankAgreementNumber: (target?.value??"") }))}
                    />
                </Grid>
                <Grid xs={12} >
                    <TextField value={providerForm.address} onChange={({ target }) => setProviderForm(n => ({...n, address: target.value}))} margin="none" label={t('address')!} multiline rows={3} />
                </Grid>
                <Grid xs={12} >
                    <Checkbox checked={providerForm.ivaResponsible}  label={t('responsibleForValueAddedTax')!} onChange={() => setProviderForm(n => ({...n, ivaResponsible: !providerForm.ivaResponsible }))} />
                </Grid>
                <Grid xs={12} >
                    <Checkbox checked={providerForm.retefuente}  label={t('Aplica retención en la fuente')!} onChange={ () => setProviderForm(n => ({...n, retefuente: !providerForm.retefuente })) } />
                </Grid>
                {providerForm.retefuente &&
                    <Grid xs={12} >
                        <RetentionInput value={providerForm.tax} onChange={(_, result:any) => setProviderForm(n => ({...n, tax: result._id }))} />
                    </Grid>
                }
                <Grid xs={12} >
                    <Button type="submit" loading={isLoadingRegisterProvider || isLoadingUpdateProviderMutation} > { Boolean(providerForm._id) ? t('save') : t('createSupplier') } </Button>
                </Grid>
            </Grid>
        </Form>
    )
}

export default ProviderForm