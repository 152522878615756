import React from 'react'
import Switch from "@component/Switch"
import { useAppDispatch, useAppSelector } from '../../../hooks/state'
import { setInvoiceAlphabeticalOrder } from "../../../services/localStorage"
import { changeInvoiceAlphabeticalOrder } from '../../../services/AppSlice'

const SwitchAlphabeticalOrder = () => {
    const dispatch = useAppDispatch()
    const { AppState } = useAppSelector(({ AppState }) => ({ AppState }))
    const { invoiceAlphabeticalOrder } = AppState

    const value = React.useMemo(() => {
        setInvoiceAlphabeticalOrder(invoiceAlphabeticalOrder)
        return invoiceAlphabeticalOrder === "alphabetical"
    },[invoiceAlphabeticalOrder])

    return(
        <>
            <Switch
                onChange={({ target }) => dispatch(changeInvoiceAlphabeticalOrder(target.checked === true ? "alphabetical" : "default"))}
                label='Organizar productos alfabéticamente'
                checked={value}
            />
        </>
    )
}

export default SwitchAlphabeticalOrder