import Box from "@component/Box"
import IconButton from "@component/IconButton";
import Stack from "@component/Stack"
import Tooltip from "@component/Tooltip";
import { showFixedExpenseForm } from "@feature/FixedCosts/services/FixedCostsSlice";
import { useAppDispatch } from "@hook/state";
import AddRounded from '@mui/icons-material/AddRounded';

const AccountsPayableListToolbar = () => {

    const dispatch = useAppDispatch()

    return(
        <Stack pt={1} px={2} direction={'row'} justifyContent={'space-between'} >
            <Box>

            </Box>
            <Box>
                <Tooltip title="Añadir nuevo gasto fijo" >
                    <IconButton squareButton onClick={() => dispatch(showFixedExpenseForm(true))} >
                        <AddRounded />
                    </IconButton>
                </Tooltip>
            </Box>
        </Stack>
    )
}

export default AccountsPayableListToolbar