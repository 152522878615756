import { KeyRounded } from "@mui/icons-material"
import Box from "./Box"
import Grid from "./Grid"
import Typography from "./Typography"

const LockComponent = () => {
    return(
        <Grid container >
            <Grid xs={12} sx={{ display: 'flex',justifyContent: 'center', alignItems: 'center', height: '90vh' }} >
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
                    <KeyRounded sx={{ fontSize: "15rem", color: (theme) => theme.palette.grey[400] }} />
                    <Typography sx={{ color: (theme) => theme.palette.grey[400] }} fontSize={'1.5rem'} fontWeight="bold" position={'relative'} bottom="20px" lineHeight={1} >Upps... Parece que no tienes acceso a este modulo.</Typography>
                </Box>
            </Grid>
        </Grid>
    )
}

export default LockComponent