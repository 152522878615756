import Grid from "@component/Grid"
import { useDiscountShopping, useGetDiscountsOnPurchases } from "./hooks/useDiscountShopping"
import DataGrid, { GridColDef } from "@component/DataGrid"
import { currencyFormat } from "@hook/useNumberFormat"
import dayjs from "dayjs"
import Box from "@component/Box"
import Typography from "@component/Typography"
import Title from "@component/Title"
import IconButton from "@component/IconButton"
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import Swal from "sweetalert2"

const DiscountShopping = () => {
    const { resp, totalDiscounts } = useGetDiscountsOnPurchases()
    const { removePurchaseDiscount } = useDiscountShopping()
    const alertDeleteDiscount = async (discountId:string) => {
        const swalAlert = await Swal.fire({
            icon: 'question',
            text: '¿Desea eliminar el descuento a la compra?',
            confirmButtonText: 'Eliminar',
            showDenyButton: true,
            denyButtonText: 'Cancelar'
        })
        if( swalAlert.isConfirmed  ){
            removePurchaseDiscount({ discountId })
        }
    }

    const columns:GridColDef[] = [
        {maxWidth: 140, minWidth: 140, field: "user", headerName: "Registrado por", renderCell: ({row}) => `${row?.user?.name??""}`},
        {maxWidth: 80, minWidth: 80, field: "consecutive", headerName: "#C", renderCell: ({row}) => `${(row?.purchase?.consecutive??"")}`},
        {flex: 1, field: "provider", headerName: "Proveedor", renderCell: ({row}) => `${(row?.purchase?.provider?.name??"")}`},
        {maxWidth: 90, minWidth: 90, field: "invoiceNumber", headerName: "#F", renderCell: ({row}) => `${(row?.purchase?.invoiceNumber??"")}`},
        {maxWidth: 140, minWidth: 140, field: "discountValue", headerName: "Valor del descuento", renderCell: ({row}) => `$ ${currencyFormat((row?.discountValue??0))}`},
        {maxWidth: 160, minWidth: 160, field: "createdAt", headerName: "Registrado el", renderCell: ({row}) => `${dayjs(row?.createdAt??"").format("DD-MM-YYYY HH:mm A")}`},
        {maxWidth: 60, minWidth: 60, field: " ", headerName: " ", align: 'center', renderCell: ({row}) => {
            return(
                <>
                    <IconButton color="error" size="small" onClick={() => alertDeleteDiscount((row?._id??""))} >
                        <DeleteRounded sx={{ fontSize: "1rem" }} />
                    </IconButton>
                </>
            )
        }},
    ]
    return(
        <>
            <Grid container spacing={1} >
                <Grid xs={12} >
                    <Title text="Descuentos a compras" />
                </Grid>
                <Grid xs={12} >
                    <DataGrid
                        slots={{
                            toolbar: () => {
                                return(
                                    <>
                                        <Box pt={1} px={2} >
                                            <Typography fontWeight={'bold'} textTransform={'uppercase'} fontSize={'.875rem'} > Total descuentos: $ {currencyFormat(totalDiscounts)} </Typography>
                                        </Box>
                                    </>
                                )
                            }
                        }}
                        columns={columns}
                        rows={resp}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default DiscountShopping