import { GridColDef } from "@mui/x-data-grid"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useGetDeliveryReportQuery } from "../../../api/querys/PointOfSale"
import DataGrid from "@component/DataGrid"
import { useAppSelector } from "../../../hooks/state"
import { currencyFormat } from "../../../hooks/useNumberFormat"
import Routes from "../../../services/routes"
import Box from "@component/Box"
import Typography from "@component/Typography"

interface Props {
    tabPosition: number
}

const DeliveryList = ({ tabPosition }:Props) => {
    const { DeliveriesState } = useAppSelector(({ DeliveriesState }) => ({ DeliveriesState }))
    const { initDate, endDate } = DeliveriesState.dateRange
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { data, isLoading } = useGetDeliveryReportQuery({ endDate, startDate: initDate })
    const useData = tabPosition === 0 ? "deliveries" : (tabPosition === 1 ? "paidDeliveries" : "pendingDeliveries")
    const credits = data?.payload[useData]?.results ?? []
    const columns: GridColDef[] = [
        { field: "customer", headerName: t('customer')!, flex: 1, minWidth: 200 },
        { field: "total", headerName: t('credit')!, renderCell: ({ row }) => <>$ {currencyFormat(row.total)}</> },
        { field: "balance", headerName: t('balance')!, renderCell: ({ row }) => <>$ {currencyFormat(row.balance)}</> },
        { field: "consecutive", headerName: "#FAC", align: 'center' },
        { field: "deliveryAmount", headerName: t('Valor del envío')!, renderCell: ({ row }) => <>$ {currencyFormat(row.deliveryAmount)}</> },
        { field: "createdAt", headerName: t('billingDate')!, minWidth: 150, align: 'center', renderCell: ({ row }) => <>{dayjs(row.createdAt).format("DD-MM-YYYY")}</> },
    ]
    const handleCellClick = (row: any) => navigate(Routes.indexInvoice(row.id))

    return (
        <Box>
            <DataGrid
                slots={{
                    toolbar: () => (<>
                        <Typography pt={1} px={2} textTransform={'uppercase'} fontWeight={'bold'} >Domicilios</Typography>
                    </>)
                }}
                loading={isLoading}
                columns={columns}
                rows={credits}
                onCellClick={handleCellClick}
            />
        </Box>
    )
}

export default DeliveryList