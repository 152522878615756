import React from 'react';
import Avatar from "./Avatar"
import AddPhotoAlternateRounded from '@mui/icons-material/AddPhotoAlternateRounded';
import { styled } from '@mui/material/styles'
import Box from './Box';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import IconButton from './IconButton';
import Tooltip from './Tooltip';

const CustomAvatar = styled(Avatar)(() => ({
    width: 80,
    height: 80,
    cursor: "pointer"
}))

interface Props {
    onChange?: (file:FileList[0] | null) => void
    valueFile?: FileList[0] | null
}

const UploadImage = ({ onChange, valueFile }:Props) => {
    const [file, setFile] = React.useState<string>("")
    const elementRef = React.useRef<HTMLInputElement | null>(null)

    const handlePopupUploadImage = () => {
        const element = elementRef.current
        if( element !== null || element !== undefined ) {
            element?.click()
        }
    }

    const handleSelectImage = () => {
        const element = elementRef.current
        if( element !== null || element !== undefined ) {
            const files = element?.files
            if( files !== undefined && files !== null ) {
                const file = files[0]
                setFile(URL.createObjectURL(file))
                if( onChange !== undefined ){
                    onChange(file)
                }
            }
        }
    }

    React.useEffect(() => {
        if( !Boolean(file) ){
            const element = elementRef.current
            if( element !== null && element !== undefined ) {
                element.value = ""
            }
        }
    },[file])

    const handleDeleteImage = () => {
        setFile("")
        if( onChange !== undefined ){
            onChange(null)
        }
    }

    React.useEffect(() => {
        if( valueFile !== undefined && valueFile !== null ){
            if( file.trim() === "" ){
                setFile(URL.createObjectURL(valueFile))
            }
        }
    },[valueFile, file])

    return(
        <>
            <Box display={'flex'} alignItems={'center'} >
                <CustomAvatar src={file} onClick={handlePopupUploadImage} >
                    <AddPhotoAlternateRounded sx={{ fontSize: 40 }} />
                </CustomAvatar>
                { Boolean(file) &&
                    <Box>
                        <Tooltip title="Eliminar selección" >
                            <IconButton onClick={handleDeleteImage} >
                                <DeleteRounded />
                            </IconButton>
                        </Tooltip>
                    </Box>
                }
            </Box>
            <input ref={elementRef} type={'file'} style={{ display: 'none' }} accept="image/*" onChange={handleSelectImage} />
        </>
    )
}

export default UploadImage