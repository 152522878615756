import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Form from '../../../components/Form';
import Grid from '../../../components/Grid';
import TextField from '../../../components/TextField';
import { useAppSelector } from '../../../hooks/state';
import { CustomerBusinessesTypeProps } from '../../../interfaces/Customer';

import { useBusinessesType } from '../hooks/useBusinessesType';

const initialValueOfBusinessesTypeForm = {
  name: '',
};

const BusinessesTypeForm: React.FC = () => {
  const { t } = useTranslation();

  const { BusinessesTypeState } = useAppSelector(({ BusinessesTypeState }) => ({
    BusinessesTypeState,
  }));
  const { isOpenForm, BusinessesTypeToEdit } = BusinessesTypeState;

  const {
    registerBusinessesTypeMutation,
    registerBusinessesTypeIsLoading,
    updateBusinessesTypeIsLoading,
  } = useBusinessesType();

  const [form, setForm] = useState<CustomerBusinessesTypeProps>(
    initialValueOfBusinessesTypeForm
  );

  const handleChange = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = evt.target;
    setForm((preventFormState) => ({
      ...preventFormState,
      [name]: value,
    }));
  };

  const handleSubmit = () => registerBusinessesTypeMutation(form)
  

  useEffect(() => {
    if (isOpenForm && BusinessesTypeToEdit._id !== '') {
      setForm((preventFormState) => ({
        ...preventFormState,
        name: BusinessesTypeToEdit.name,
        _id: BusinessesTypeToEdit?._id,
      }));
    }
  }, [isOpenForm, BusinessesTypeToEdit]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={1}
      >
        <Grid xs={12}>
          <TextField
            margin='none'
            label={t('nameOfTheBusinessType')}
            name='name'
            value={form.name}
            autoFocus
            onChange={handleChange}
          />
        </Grid>
        <Grid xs={12}>
          <Button
            type='submit'
            loading={
              registerBusinessesTypeIsLoading || updateBusinessesTypeIsLoading
            }
          >
            {BusinessesTypeToEdit._id !== ''
              ? t('updateBusinessesType')
              : t('createBusinessesType')}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default BusinessesTypeForm;
