import React from "react"
import Card from "@component/Card"
import List, { ListItem, ListItemText } from "@component/List"
import Stack from "@component/Stack"
import { useAppDispatch, useAppSelector } from "@hook/state"
import { useGetPurchasesOfTheDayToSupplier } from "../hooks/usePurchasing"
import { currencyFormat } from "@hook/useNumberFormat"
import Typography from "@component/Typography"
import { updateTotalValueOfPreviousPurchases } from "../services/PurchasesSuppliersSlice"

const SupplierPreviousPurchasesList = () => {
    const dispatch = useAppDispatch()
    const { PurchasesSuppliersState } = useAppSelector(({ PurchasesSuppliersState }) => ({ PurchasesSuppliersState }))
    const { providerSelected } = PurchasesSuppliersState
    const { purchases } = useGetPurchasesOfTheDayToSupplier({ providerId: providerSelected?._id??"" })
    const total = purchases.reduce((acc, params) => (params.subTotal+acc),0)
    React.useEffect(() => {
        dispatch(updateTotalValueOfPreviousPurchases(total)) 
    },[total])
    return(
        <>
            <Card>
                <Stack>
                    <Typography fontWeight={'bold'} textTransform={'uppercase'} > Compras recientes </Typography>
                    <List sx={{ padding: 0 }} >
                        {purchases.map((n, index) => {
                            return(
                                <ListItem sx={{ padding: 0 }} key={index} >
                                    <ListItemText
                                        primary={`Consecutivo: #C${n?.consecutive??""}`}
                                        secondary={`Sub total: $ ${currencyFormat(n?.subTotal??0)}`}
                                    />
                                </ListItem>
                            )
                        })}
                        <ListItem sx={{ padding: 0 }} >
                            <ListItemText
                                primary={`TOTAL: $ ${ currencyFormat(total) }`}
                            />
                        </ListItem>
                    </List>
                </Stack>
            </Card>
        </>
    )
}

export default SupplierPreviousPurchasesList