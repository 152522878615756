import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetTaxesQuery } from "../../../api/querys/Business"
import Autocomplete from "@component/Autocomplete"
import Grid from '../../../components/Grid';
import { currencyFormat } from '../../../hooks/useNumberFormat';
import { TaxesBusinessProps } from '../../../interfaces/Business';

type AutocompleteProps = Omit<React.ComponentProps<typeof Autocomplete>, "options" | "label" | "getOptionLabel" | "renderOption">

interface Props extends AutocompleteProps {
    value: string
}


const RetentionInput = ({ value="", ...rest }:Props) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const { data, isLoading } = useGetTaxesQuery()
    const retention = data?.payload.retefuente??[]
    const selected = retention.find(n => n._id === value) || null

    return(
        <Autocomplete
            value={selected}
            {...rest}
            loading={isLoading}
            label={t('retention')!}
            options={retention}
            getOptionLabel={(params:any) => `${t(params.name)} - ${params.tax}% - BM: $ ${currencyFormat(params.minimumAmount) }` }
            renderOption={(props, params:any, index) =>{
                const data = params as TaxesBusinessProps
                return(
                    <li {...props} key={`${index}-${data._id}`} style={{ borderBottom: `1px solid ${theme.palette.grey[500]}` }} >
                        <Grid container >
                            <Grid xs={12} >
                                {t('tax')}: {t(data.name)}
                            </Grid>
                            <Grid xs={12} >
                                {t('value')}: {data.tax}%
                            </Grid>
                            <Grid xs={12} >
                                {t('minimumBasis')}: $ { currencyFormat(data.minimumAmount) }
                            </Grid>
                        </Grid>
                    </li>
                )
            } }
        />
    )
}

export default RetentionInput