import React from "react"
import { useTranslation } from "react-i18next"
import Autocomplete from "@component/Autocomplete"

interface Props {
    onChange?: (wallet:"cash" | "consignment") => void
}

const WalletsInput = ({ onChange }:Props) => {
    const [walletSelected, setWalletSelected] = React.useState<number>(1)
    const { t } = useTranslation()
    const wallet = [
        { _id: 1, name: t('cash'), value: "cash" },
        { _id: 2, name: t('consignment'), value: "consignment" },
    ]

    const handleChange = (wallet:"cash" | "consignment", _id:number) => {
        setWalletSelected(_id)
        if( onChange !== undefined ){
            onChange(wallet)
        }
    }
    const value = wallet.find(n => n._id === walletSelected)
    return(
        <>
            <Autocomplete
                value={value}
                onChange={(_,n:any) => handleChange(n?.value??"", n?._id??1)}
                label={t('wallet')!}
                options={wallet}
            />
        </>
    )
}

export default WalletsInput