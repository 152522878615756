import Box from '@component/Box';
import { AccountBalanceRounded, AssessmentRounded, BusinessCenterRounded, CardMembershipRounded, CategoryRounded, CloudUploadRounded, CurrencyExchangeRounded, DeliveryDiningRounded, DriveFileRenameOutlineRounded, FilterListRounded, GiteRounded, Groups2Rounded, GroupsRounded, HolidayVillageRounded, LocalAtmRounded, LocalOfferRounded, LocalShippingRounded, LocationCityRounded, PaymentsRounded, PeopleRounded, PersonSearchRounded, PlaceRounded, PointOfSaleRounded, PriceCheckRounded, QueryStatsRounded, RoofingRounded, SavingsRounded, ShoppingCartRounded, StoreRounded, TextSnippetRounded, TrendingUpRounded, VpnKeyRounded, WalletRounded, WarehouseRounded, WindowRounded } from '@mui/icons-material';
import Routes from '@service/routes'



export const drawerList = [
    { collapse: false, icon: <RoofingRounded />, path: "/", title: "Panel", children: [] },
    {
        collapse: true, icon: <ShoppingCartRounded />, path: "", title: "Compras - BETA",
        children: [
            { groupNamePermission: "PurchaseFromSuppliers", namePermission: "lock_module", path: Routes.indexPurchasingSuppliers(), title: "Realizar compra", children:[], collapse: false, icon: null },
            { groupNamePermission: "PurchaseFromSuppliers", namePermission: "lock_module", path: Routes.indexPurchaseReports(), title: "Reportes de compra", children:[], collapse: false, icon: null, },
            { groupNamePermission: "PurchaseFromSuppliers", namePermission: "lock_module", path: Routes.indexShoppingDiscounts(), title: "Descuentos", children:[], collapse: false, icon: null, },
        ]
    },
    {
        collapse: true, icon: <SavingsRounded />, path: "", title: "Movimientos",
        children: [
            { groupNamePermission: "MinorBox", namePermission: "lock_module", path: Routes.indexMinorBox(), title: "pettyCash", children:[], collapse: false, icon: <WalletRounded /> },
            { groupNamePermission: "BoxLarge", namePermission: "lock_module", path: Routes.indexLargeBox(), title: "boxLarge", children:[], collapse: false, icon: <WalletRounded />, },
            { groupNamePermission: "Banks", namePermission: "lock_module", path: Routes.indexBanks(), title: "Bancos", children:[], collapse: false, icon: <AccountBalanceRounded />, },
            { groupNamePermission: "MasterBox", namePermission: "lock_module", path: Routes.indexMasterBox(), title: "Caja mayor 2", children:[], collapse: false, icon: <CurrencyExchangeRounded />, },
        ]
    },
    {
        collapse: true, icon: <StoreRounded />, path: "", title: "commerce",
        children: [
            { groupNamePermission: "ClosingOfTheDay", namePermission: "lock_module", path: Routes.indexClosingOfTheDay(), title: "closingOfTheDay", children:[], collapse: false, icon: <TrendingUpRounded /> },
            { groupNamePermission: "Permissions", namePermission: "lock_module", path: Routes.indexPermissions(), title: "permissions", children:[], collapse: false, icon: <VpnKeyRounded /> },
            { groupNamePermission: "MyBusiness", namePermission: "lock_module", path: Routes.indexBusinessData(), title: "myBusiness", children:[], collapse: false, icon: <TextSnippetRounded />, },
            { groupNamePermission: "AccountsPayable", namePermission: "lock_module", path: Routes.indexDebtsToPay(), title: "Cuentas por pagar", children:[], collapse: false, icon: <LocalAtmRounded />, },
            { groupNamePermission: "FixedExpenses", namePermission: "lock_module", path: Routes.indexFixedCosts(""), title: "fixedCosts", children:[], collapse: false, icon: <CardMembershipRounded />, },
        ]
    },
    {
        collapse: true, icon: <Groups2Rounded />, path: "", title: "third",
        children: [
            { groupNamePermission: "Customers", namePermission: "lock_module", path: Routes.indexCustomers(), title: "customers", children:[], collapse: false, icon: <GroupsRounded /> },
            { groupNamePermission: "Team", namePermission: "lock_module", path: Routes.indexEmployee(), title: "team", children:[], collapse: false, icon: <PeopleRounded />, },
            { groupNamePermission: "Provider", namePermission: "lock_module", path: Routes.indexProviders(), title: "providers", children:[], collapse: false, icon: <LocalShippingRounded />, },
        ]
    },
    {
        collapse: true, icon: <WarehouseRounded />, path: "", title: "supplies",
        children: [
            { groupNamePermission: "Inventory", namePermission: "lock_module", path: Routes.indexInventory(), title: "inventory", children:[], collapse: false, icon: <WindowRounded /> },
            { groupNamePermission: "Product", namePermission: "lock_module", path: Routes.indexProducts(), title: "products", children:[], collapse: false, icon: <LocalOfferRounded />, },
            { groupNamePermission: "Product", namePermission: "bulk_upload_from_file", path: Routes.indexImportProducts(), title: "importProducts", children:[], collapse: false, icon: <CloudUploadRounded />, },
            { groupNamePermission: "Product", namePermission: "lock_module", path: Routes.indexMassProducts(), title: "Productos en masa", children:[], collapse: false, icon: <DriveFileRenameOutlineRounded />, },
            { groupNamePermission: "ProductCategories", namePermission: "lock_module", path: Routes.indexProductPackaging(), title: "productPackaging", children:[], collapse: false, icon: <CategoryRounded />, },
            { groupNamePermission: "ProductCategories", namePermission: "lock_module", path: Routes.indexProductCategory(), title: "productCategories", children:[], collapse: false, icon: <CategoryRounded />, },
        ]
    },
    {
        collapse: true, icon: <PointOfSaleRounded />, path: "", title: "pointOfSale",
        children: [
            { groupNamePermission: "Cashiers", namePermission: "lock_module", path: Routes.indexCashiers(), title: "Cajeros", children:[], collapse: false, icon: <PaymentsRounded /> },
            { groupNamePermission: "Expenses", namePermission: "lock_module", path: Routes.indexExpenses(), title: "Gastos", children:[], collapse: false, icon: <Box fontSize={'1.8rem'} className='icon-moneybag' /> },
        ]
    },
    {
        collapse: true, icon: <AssessmentRounded />, path: "", title: "reports",
        children: [
            { groupNamePermission: "ReportOfTheDay", namePermission: "lock_module", path: Routes.indexReportOfTheDay(), title: "reportOfTheDay", children:[], collapse: false, icon: <QueryStatsRounded /> },
            { groupNamePermission: "CustomerFollowUP", namePermission: "lock_module", path: Routes.indexCustomerPurchaseTracking(), title: "customerFollowUp", children:[], collapse: false, icon: <PersonSearchRounded /> },
            { groupNamePermission: "Credit", namePermission: "lock_module", path: Routes.indexCreditReport(), title: "credits", children:[], collapse: false, icon: <PriceCheckRounded /> },
            { groupNamePermission: "Delivery", namePermission: "lock_module", path: Routes.indexDeliveryReport(), title: "delivery", children:[], collapse: false, icon: <DeliveryDiningRounded /> },
        ]
    },
    {
        collapse: true, icon: <FilterListRounded />, path: "", title: "filterCustomers",
        children: [
            { groupNamePermission: "Customers", namePermission: "filters", path: Routes.indexCustomerZone(), title: "zones", children:[], collapse: false, icon: <PlaceRounded /> },
            { groupNamePermission: "Customers", namePermission: "filters", path: Routes.indexCustomerBranch(), title: "customerBranch", children:[], collapse: false, icon: <HolidayVillageRounded /> },
            { groupNamePermission: "Customers", namePermission: "filters", path: Routes.indexNeighborhoods(), title: "neighborhoods", children:[], collapse: false, icon: <GiteRounded /> },
            { groupNamePermission: "Customers", namePermission: "filters", path: Routes.indexCustomerBusinessesType(), title: "businessType", children:[], collapse: false, icon: <BusinessCenterRounded /> },
            { groupNamePermission: "Customers", namePermission: "filters", path: Routes.indexCustomerCity(), title: "cities", children:[], collapse: false, icon: <LocationCityRounded /> },
        ]
    },
]