import FilterByDateRange from "@component/FilterByDateRange"
import Stack from "@component/Stack"
import { useAppSignalSelector } from "@hook/state"
import dayjs from "dayjs"

const FilterBox = () => {
    const { BusinessBoxState } = useAppSignalSelector()
    const { filterDateRange } = BusinessBoxState.value
    const { startDate, endDate } = filterDateRange
    const handleChangeDate = (initDate:Date, finDate:Date) => {
        const a = dayjs(initDate).format("MM-DD-YYYY")
        const b = dayjs(finDate).format("MM-DD-YYYY")
        BusinessBoxState.value = {...BusinessBoxState.value, filterDateRange: { endDate: b, startDate: a }}
    }
    return(
        <Stack sx={{ mb: 1 }} >
            <FilterByDateRange
                initDate={dayjs(startDate).toDate()}
                endDate={dayjs(endDate).toDate()}
                onChange={({ initDate, endDate:finDate }) => handleChangeDate(initDate, finDate) }
            />
        </Stack>
    )
}

export default FilterBox