import Button from "@component/Button"
import DatePicker from "@component/DatePicker"
import Dialog, { DialogActions, DialogContent } from "@component/Dialog"
import { useAppDispatch, useAppSelector } from "@hook/state"
import dayjs from "dayjs"
import { usePurchasing } from "../hooks/usePurchasing"
import { displayFormCredit, showPaymentSelectionWindow, updateDueDate } from "../services/PurchasesSuppliersSlice"

const CreditForm = () => {
    const dispatch = useAppDispatch()
    const { PurchasesSuppliersState } = useAppSelector(({ PurchasesSuppliersState }) => ({ PurchasesSuppliersState }))
    const { openFormCredit, dueDate, productsSelectedList, providerSelected, billingDate, haulier, dateReceived, whoReceives, invoiceNumber, purchaseId } = PurchasesSuppliersState
    const { recordPurchaseFromSupplier, isLoadingUseRecordPurchaseFromSupplierMutation } = usePurchasing()
    const handleSubmit = () => recordPurchaseFromSupplier({ invoiceNumber, haulier, purchaseId, productList: productsSelectedList, provider: providerSelected!, typePayments: "credit", billingDate, dateReceived, whoReceives, dueDate})

    const handleClose = () => {
        dispatch(displayFormCredit(false))
        dispatch(showPaymentSelectionWindow(true))
    }

    return(
        <Dialog maxWidth='xs' fullWidth open={openFormCredit} onClose={handleClose} >
            <DialogContent>
                <DatePicker maxDate={null} minDate={dayjs().toDate()} label="Fecha de expiración" fullWidth value={dueDate} onChange={({ value }) => dispatch(updateDueDate(value as Date))} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} variant='text' loading={isLoadingUseRecordPurchaseFromSupplierMutation} >
                    Finalizar compra
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreditForm