import { ExpensesAsyncResponse } from './../../../interfaces/Expense.d';
import { useAppDispatch } from './../../../hooks/state';
import { ExpenseFormProps } from "@interface/Expense"
import { useArchiveExpenseMutation, useGetExpensesQuery, useRegisterNewExpenseMutation } from "@query/Expense"
import { useSnackbar } from "notistack"
import React from "react"
import { useTranslation } from "react-i18next"
import { showFormOfExpense } from "../services/ExpenseSlice"
import { DateRangeFilter } from '@interface/App';
import Swal from 'sweetalert2';
import { useURLParams } from '@hook/useURLParams';

export const useExpense = () => {
    const dispatch = useAppDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const [ registerNewExpenseMutation, { isLoading:isLoadingRegisterNewExpenseMutation } ] = useRegisterNewExpenseMutation()
    const [ archiveExpenseMutation, { isLoading:isLoadingArchiveExpenseMutation } ] = useArchiveExpenseMutation()

    const registerNewExpense = async (payload:ExpenseFormProps) => {
        try {
            const resp = await registerNewExpenseMutation(payload).unwrap()
            if( resp.status === 1 ){
                enqueueSnackbar("El gasto se registro con satisfactoriamente", { variant: "success" })
                dispatch(showFormOfExpense(false))
            }else{
                enqueueSnackbar(t("anErrorOccurredWhileTryingToProcessTheRequest"), { variant: "error" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const archiveExpense = async (params:{expenseId: string}) => {
        const resp = await archiveExpenseMutation(params).unwrap()
        if( resp.status === 1 ){
            Swal.fire({
                icon: 'success',
                text: 'El gasto se archivo satisfactoriamente',
                timer: 2000
            })
        }else{
            Swal.fire({
                icon: 'error',
                text: t("anErrorOccurredWhileTryingToProcessTheRequest")!,
            })
        }
    }

    return {
        registerNewExpense,
        archiveExpense,
        isLoadingArchiveExpenseMutation,
        isLoadingRegisterNewExpenseMutation,
    }
}

export const useGetExpenses = ({ startDate, endDate }:DateRangeFilter) => {
    const { data, isLoading } = useGetExpensesQuery({ startDate, endDate })
    let resp = data?.payload??[] as ExpensesAsyncResponse['payload']
    let list = [...resp].filter((n) => (n.concept !== "advanceSupplier") )
    const { getQuery } = useURLParams()
    const filterBy = getQuery("filterBy")
    const arrangeBy = getQuery("arrangeBy")

    if( Boolean(filterBy) ) list = list.filter((n) => n.box === filterBy)

    if( !Boolean(arrangeBy) ){
        list = [{
            concept: 'expense',
            results: list,
            total: list.reduce((acc, val) => (acc+val?.amount??0),0)
        }] as any
    }

    if( arrangeBy === "concept" ){
        let newList:any[] = []
        for(let x of list){
            const indexConcept = newList.findIndex((n) => (n.concept === x.concept))
            if( indexConcept >= 0 ){
                newList[indexConcept].results.push(x)
                newList[indexConcept].total += x.amount
            }else{
                newList.push({
                    concept: x.concept,
                    results: [x],
                    total: x.amount
                })
            }
        }
        list = newList
    }

    let expensesByMonth: any[] = []

    return {
        list,
        expenses: [] as ExpensesAsyncResponse['payload'],
        totalExpense: 0,
        purchases: [] as ExpensesAsyncResponse['payload'],
        totalPurchases: 0,
        isLoading,
        expensesByMonth
    }
}

export const useExpenseData = () => {
    const [expenseData] = React.useState<ExpenseFormProps>({ _id: "", amount: 0, box: "cashierBox", concept: "cashExpense", note: "", bank: null })

    const expenseFormData = React.useMemo(() => expenseData, [expenseData])
    
    return { expenseFormData }
}
