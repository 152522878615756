import React from 'react'
import { useTranslation } from "react-i18next"
import { typeOfPayment } from '..'
import Button from "@component/Button"
import Dialog, { DialogContent } from "@component/Dialog"
import Form from "@component/Form"
import TextField from "@component/TextField"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { useInvoice } from '../hooks/useInvoice'
import { showDeliveryForm, showPaymentMethodForm } from "../service/InvoiceSlice"
import NumberFormat from '@component/NumberFormat'
import { useURLParams } from '@hook/useURLParams'

interface Props {
    invoiceId: string | undefined
    observation: string
    typeOf?: keyof typeof typeOfPayment
}

const DeliveryNameForm = ({ invoiceId, observation, typeOf }:Props) => {
    const [deliveryData, setDeliveryData] = React.useState<{ deliveryName: string, deliveryAmount: number }>({ deliveryName: "", deliveryAmount: 0 })
    const dispatch = useAppDispatch()
    const { InvoiceState } = useAppSelector(({ InvoiceState }) => ({ InvoiceState }))
    const { openDeliveryForm } = InvoiceState
    const { t } = useTranslation()
    const { changePaymentMethod } = useInvoice()
    const { delQueries } = useURLParams()

    const handleClose = () => {
        dispatch(showDeliveryForm(false))
        dispatch(showPaymentMethodForm(true))
    }
    const handleSubmit = () => {
        changePaymentMethod( (typeOf !== undefined ? typeOf : typeOfPayment.delivery), { invoiceId: invoiceId ?? '', deliveryName: deliveryData.deliveryName, deliveryAmount: deliveryData.deliveryAmount, observation })
        dispatch(showDeliveryForm(false))
    }

    React.useEffect(() => {
        if( !openDeliveryForm ){
            delQueries(['typeOf'])
        }
    },[openDeliveryForm])

    return(
        <Dialog open={openDeliveryForm} onClose={handleClose} maxWidth={'xs'} fullWidth >
            <DialogContent>
                <Form onSubmit={handleSubmit} >
                    <TextField autoFocus label={t('addressName')!} value={deliveryData.deliveryName} onChange={({ target }) => setDeliveryData(n => ({...n, deliveryName: target.value})) } />
                    <NumberFormat required label='Valor del envío' value={deliveryData.deliveryAmount} onChange={({ target }) => setDeliveryData(n => ({...n, deliveryAmount: Number(target?.value??0)})) } />
                    <Button type="submit" > {t('save')} </Button>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default DeliveryNameForm