import AddRounded from '@mui/icons-material/AddRounded';

import { useTranslation } from 'react-i18next';

import {
  displayProductPackagingForm,
  loadProductPackagingToEdit,
} from '../services/ProductPackagingSlice';

import { useAppDispatch } from '../../../hooks/state';

import Box from '../../../components/Box';
import Tooltip from '../../../components/Tooltip';
import IconButton from '../../../components/IconButton';
import PackagingProductInput from '../../Products/components/PackagingProductInput';
import { useGetPackagingProductQuery } from '../../../api/querys/Product';

const PackagingProductSearchBar: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data: packagingProductData } = useGetPackagingProductQuery();

  const productPackaging =
    packagingProductData?.payload?.productsPackaging?.results ?? [];

  const handleOnChange = (evt: string) => {
    const findSamePackaging = productPackaging.find(
      (props) => props?._id === evt
    )!;
    dispatch(loadProductPackagingToEdit(findSamePackaging));
  };

  const handleOpenForm = () => {
    dispatch(displayProductPackagingForm(true));
  };

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
    >
      <PackagingProductInput
        productPackagingId=''
        onChange={handleOnChange}
      />
      <Box ml={1}>
        <Tooltip title={t('createNewPackaging')}>
          <IconButton
            squareButton
            onClick={handleOpenForm}
          >
            <AddRounded />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default PackagingProductSearchBar;
