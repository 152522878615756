import { useTranslation } from 'react-i18next'
import { useGetPackagingProductQuery } from '../../../api/querys/Product'
import Autocomplete from "@component/Autocomplete"
import React from 'react'
import Typography from '@component/Typography'
import Stack from '@component/Stack'

type AutocompleteProps = React.ComponentProps<typeof Autocomplete>

interface Props {
    id?: string
    productPackagingId: string
    label?: string 
    onChange?: (value:string, params:any) => void
    error?: AutocompleteProps['error']
    helperText?: AutocompleteProps['helperText']
}

const PackagingProductInput = ({ id, productPackagingId, onChange, ...rest }:Props) => {
    const { t } = useTranslation()
    const { label=t('packaging')! } = rest
    const { data, isSuccess, isLoading } = useGetPackagingProductQuery()
    const productPackaging = data?.payload?.productsPackaging?.results??[]
    const loadingData = isSuccess && !isLoading
    const value = productPackaging.find((n:typeof productPackaging[0]) => n?._id === productPackagingId) || null

    return(
        <Autocomplete
            {...rest}
            id={id}
            name='packaging'
            label={label}
            options={productPackaging}
            loading={!loadingData}
            value={value}
            onChange={(_, b:any) => {
                if( onChange !== undefined ){
                    onChange(b?._id??"", b)
                }
            }}
            renderOption={(props, option:any) => {
                return(
                    <li {...props} key={option._id} >
                        <Stack>
                            <Typography>
                                {option.name}
                            </Typography>
                            <Typography>
                                x{option.quantity}
                            </Typography>
                        </Stack>
                    </li>
                )
            }}
        />
    )
}

export default PackagingProductInput