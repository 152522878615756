import { DateRangeFilter } from './../../interfaces/App.d';
import { ExpenseFormProps, ExpensePropsAsyncResponse, ExpensesAsyncResponse } from "@interface/Expense"
import { api, mutationConfig } from "../splitApi"

const Expense = api.injectEndpoints({
    endpoints: (build) => ({
        getExpenses: build.query<ExpensesAsyncResponse, DateRangeFilter >({
            query: ({startDate, endDate}) => ({
                url: '/get/expenses',
                params: {startDate, endDate}
            }),
            providesTags: ['Expense']
        }),
        registerNewExpense: build.mutation<ExpensePropsAsyncResponse, ExpenseFormProps>({
            query(body) {
                return {...mutationConfig, url: '/register/new/expense', body}
            },
            invalidatesTags: ['Expense', 'Cashier', 'MinorBox', 'MultiBox']
        }),
        archiveExpense: build.mutation<any, { expenseId: string }>({
            query(body) {
                return {...mutationConfig, url: '/archive/expense', body}
            },
            invalidatesTags: ['Expense', 'Cashier', 'MinorBox', 'MultiBox']
        }),
    }),
    overrideExisting: false,
})

export const {
    useRegisterNewExpenseMutation,
    useGetExpensesQuery,
    useArchiveExpenseMutation
} = Expense