import Typography from "@component/Typography"
import { currencyFormat } from "@hook/useNumberFormat"
import { useTranslation } from "react-i18next"
import ListDataGrid from "./ListDataGrid"
import { useGetSupplierSummary } from "@feature/Home/hooks/usePanel"
import React from "react"

const SuppliersDetails = ({date=""}) => {
    const { isLoading, resp } = useGetSupplierSummary({startDate: date, endDate: date})
    const { t } = useTranslation()
    let columns = React.useMemo(() => {
        let result = [
            { field: "concept", width: 220, headerName: "", renderCell: ({row}) => {
                return(
                    <Typography variant="body2" sx={{
                        textTransform: 'uppercase',
                        fontWeight: '500',
                        fontSize: '.795rem',
                    }} >
                        {`${t((row?.concept??""))}`}
                    </Typography>
                )
            }},
            { field: "total", flex: 1, headerName: "", renderCell: ({row}) => {
                return(
                    <Typography variant="body2" sx={{
                        fontWeight: 'bold',
                        color: (theme) => (row?.importance??0) === 1 ? theme.palette.success.main : theme.palette.error.main
                    }} >
                        {`$ ${currencyFormat((row?.total??0), true)}`}
                    </Typography>
                )
            }},
        ]
        return result
    }, [t])
    
    return(
        <>
            <ListDataGrid
                loading={isLoading}
                title="Resumen proveedores (CxP)"
                columns={columns}
                rows={resp}
            />
        </>
    )
}

export default SuppliersDetails