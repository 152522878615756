import React from 'react'
import Button from "@component/Button"
import Checkbox from "@component/Checkbox"
import Dialog, { DialogActions, DialogContent } from "@component/Dialog"
import Grid from "@component/Grid"
import NumberFormat from "@component/NumberFormat"
import TextField from "@component/TextField"
import TagInput from "@feature/Tags/components/TagsInput"
import { useURLParams } from "@hook/useURLParams"
import { useFixedCost, useGetFixedExpense } from "@fixed_cost_hook/useFixedCost"
import { FixedCostsProps } from '@interface/FixedCosts'
import Form from '@component/Form'

const FixedExpenseForm = () => {
    const [data, setData] = React.useState<Pick<FixedCostsProps, "_id" | "amount" | "expirationDate" | "name" | "recurrent" | "tags" | "generateDaily">>()
    const { isOpenModalByURLParameter, getParameterGroup, delQueries } = useURLParams()
    const open = isOpenModalByURLParameter("fixed_expense")
    const { ref="" } = getParameterGroup(["_id"])
    let { fixedCost } = useGetFixedExpense({ fixedCostId: ref })
    const { saveFixedExpense } = useFixedCost()

    fixedCost = React.useMemo(() => (fixedCost), [fixedCost])

    React.useEffect(() => {
        if( Boolean(fixedCost?._id) ){
            console.log("🚀 ~ fixedCost:", fixedCost)
            setData(() => ({...fixedCost}))
        }
    }, [fixedCost])

    const handleChange = (attr:(keyof typeof fixedCost), value:any) => setData((prevState:any) => ({...prevState, [attr]: value}))
    
    const handleSubmit = () => saveFixedExpense(data as any)
    React.useEffect(() => {
        if( !open ){
            setData({} as typeof data)
        }
    },[open])
    return(
        <>
            <Dialog fullWidth maxWidth="xs" open={open} onClose={() => delQueries(["ref", "modal"])} >
                <Form onSubmit={handleSubmit} >
                    <DialogContent>
                        <Grid container spacing={1} >
                            <Grid xs={6} >
                                <TextField value={(data?.name??"")} margin="none" label="Nombre del gasto" onChange={({ target }) => handleChange("name", (target?.value??""))} />
                            </Grid>
                            <Grid xs={6} >
                                <NumberFormat value={(data?.amount??0)} label="Monto" prefix="$ " onChange={({ target }) => handleChange("amount", (Number(target?.value??0)))} />
                            </Grid>
                            <Grid xs={6} >
                                <NumberFormat disabled={(data?.generateDaily)} value={(data?.expirationDate??0)} label="Vence el" suffix=" de cada mes"  maxVal={31} onChange={({ target }) => handleChange("expirationDate", (Number(target?.value??0)))} />
                            </Grid>
                            <Grid xs={6} >
                                <TagInput value={(data?.tags??[]) as any} limitTags={1} module="fixedCost" onTagChange={(params:any) => handleChange("tags", params)} />
                            </Grid>
                            <Grid xs={6} >
                                <Checkbox checked={(data?.generateDaily??false)} label="Generar gasto diario" onChange={({ target }) => handleChange("generateDaily", (target?.checked??false))} />
                            </Grid>
                            <Grid xs={6} >
                                <Checkbox checked={(data?.recurrent??false)} label="Seguimiento automático" onChange={({ target }) => handleChange("recurrent", (target?.checked??false))} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" variant="text" >
                            Guardar
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    )
}

export default FixedExpenseForm