import { AppSignals } from "@component/SignalProvider"
import TextField from "@component/TextField"
import { useAppSignalSelector } from "@hook/state"
import React from "react"

const NoteNoteCredit = () => {
    const { PointOfSaleSignalState } = useAppSignalSelector()
    const { note } = PointOfSaleSignalState.value
    const handleChange = ({ target }:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        PointOfSaleSignalState.value = {...PointOfSaleSignalState.value, note: target.value}
    }
    return(
        <>
            <TextField
                value={note}
                label="Nota"
                multiline
                rows={3}
                onChange={handleChange}
            />
        </>
    )
}

export default NoteNoteCredit