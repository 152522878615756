import Box from "@component/Box"
import Checkbox from "@component/Checkbox"
import IconButton from "@component/IconButton"
import Stack from "@component/Stack"
import Tooltip from "@component/Tooltip"
import { useAppContextSelector } from "@hook/state"
import { useURLParams } from "@hook/useURLParams"
import { DoDisturbAltRounded } from "@mui/icons-material"
import { Badge } from "@mui/material"
import Swal from "sweetalert2"
import { useFixedCost } from "@fixed_cost_hook/useFixedCost"
import AddRounded from '@mui/icons-material/AddRounded';

const FixedExpenseFilters = () => {
    const { stopFixedExpense } = useFixedCost()
    const { setGlobalState, FixedCostContext } = useAppContextSelector()
    const { fixedCostSelection } = FixedCostContext
    let selection = fixedCostSelection.reduce((acc, params) => [...acc, ...params], [])
    const { getParameterGroup, delQuery, handleActionParameter, handleModalByURLParameter } = useURLParams()
    const { group } = getParameterGroup(["group"])
    const handleChangeGroupFixedCost = (checked:boolean) => {
        if( checked ){
            handleActionParameter("group", "tags")
        }else{
            delQuery("group")
        }
        setGlobalState((n) => ({...n,
            FixedCostContext: {
                ...n.FixedCostContext,
                fixedCostSelection: []
            }
        }))
    }

    const handleStopFixedExpenses = async () => {
        const Swalert = await Swal.fire({
            icon: "warning",
            text: "¿Desea detener los gastos fijos seleccionados?. Ya no se generaran automáticamente los gastos.",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Detener"
        })
        if( Swalert.isConfirmed ){
            stopFixedExpense()
        }else{
            setGlobalState((n) => ({...n,
                FixedCostContext: {
                    ...n.FixedCostContext,
                    fixedCostSelection: []
                }
            }))
        }
    }
    const handleOpenFormFixedCost = () => handleModalByURLParameter("fixed_expense")
    return(
        <>
            <Stack direction={"row"} justifyContent={'space-between'} sx={{ pt: 1 }} >
                <Box width={1} >
                    <Checkbox
                        checked={group === "tags"}
                        label="Agrupar por etiqueta"
                        onChange={({ target }) => handleChangeGroupFixedCost(target.checked) }
                    />
                </Box>
                <Box width={1} >
                    <Stack direction={'row'} justifyContent={'end'} spacing={1} >
                        <Box>
                            <Tooltip title="Detener gasto fijo" >
                                <IconButton onClick={handleStopFixedExpenses} squareButton disabled={selection.length === 0} >
                                    <Badge badgeContent={selection.length} color="error" >
                                        <DoDisturbAltRounded />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box>
                            <Tooltip title="Crear gasto fijo" >
                                <IconButton onClick={handleOpenFormFixedCost} squareButton >
                                    <AddRounded />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Stack>
                </Box>
            </Stack>
        </>
    )
}

export default FixedExpenseFilters