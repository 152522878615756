import DataGrid, { GridColDef } from "@component/DataGrid"
import { useGetConsignmentHistory } from "@feature/Banks/hooks/useBank"
import { useAppSignalSelector } from "@hook/state"
import { currencyFormat } from "@hook/useNumberFormat"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

const ConsignmentHistory = () => {
    const { t } = useTranslation()
    const { bankId } = useParams()
    const { BusinessBoxState } = useAppSignalSelector()
    const { filterDateRange } = BusinessBoxState.value
    const { startDate, endDate } = filterDateRange
    const { history } = useGetConsignmentHistory({ startDate, endDate, bankId: bankId! })
    const columns:GridColDef[] = [
        { maxWidth: 80, minWidth: 80, field: "consecutive", headerName: "#R", renderCell: ({ row }) => `${row?.consecutive??0}` },
        { flex: 1, field: "customer", headerName: "Cliente", renderCell: ({ row }) => t(row?.customer??"")},
        {maxWidth: 120, minWidth: 120, field: "total", headerName: "Total", renderCell: ({ row }) => `$ ${currencyFormat(row?.total??0)}` },
        { maxWidth: 120, minWidth: 120, field: "variant", headerName: "Transacción", renderCell: ({ row }) => `${row?.variant === "consignment" ? "Consignación" : "Abono"}` },
        { maxWidth: 160, minWidth: 160, field: "createdAt", headerName: "Fecha de facturación", renderCell: ({ row }) => dayjs(`${row?.createdAt}`).format("DD-MM-YYYY HH:mm A") },
    ]
    return(
        <>
            <DataGrid
                columns={columns}
                rows={history}
            />
        </>
    )
}

export default ConsignmentHistory