import Tab from "@component/Tab"
import MovementsOfTheBox from "./MovementsOfTheBox"
import { Boxes } from "@interface/App"
import ConsignmentHistory from "./ConsignmentHistory"

interface Props {
    box: Boxes
}

const MovementsTab = ({ box }:Props) => {

    return(
        <>
            <Tab
                orientation="horizontal"
                TabItem={[
                    "Movimientos",
                    ...(box === "bank") ? ["Historial de consignaciones"] : []
                ]}
                TabRender={[
                    <MovementsOfTheBox box={box} />,
                    ...(box === "bank") ? [<ConsignmentHistory />] : []
                ]}
            />
        </>
    )
}

export default MovementsTab