import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { ProductPackaging } from '../../../interfaces/Product';

interface InitialStateProps {
  isOpenForm: boolean;
  packagingToEdit: ProductPackaging;
  selectionToDelete: string[];
  isOpenAlertDeletePackagingProduct: boolean;
}

const initialState: InitialStateProps = {
  isOpenForm: false,
  packagingToEdit: { name: '', quantity: 0, _id: '' },
  selectionToDelete: [],
  isOpenAlertDeletePackagingProduct: false,
};
export const ProductPackagingSlice = createSlice({
  name: 'productPackaging',
  initialState,
  reducers: {
    displayProductPackagingForm: (state, action: PayloadAction<boolean>) => {
      state.isOpenForm = action.payload;
    },
    closeProductPackagingEditionForm: (state) => {
      state.packagingToEdit = { name: '', quantity: 0, _id: '' };
      state.isOpenForm = false;
    },
    loadProductPackagingToEdit: (
      state,
      action: PayloadAction<InitialStateProps['packagingToEdit']>
    ) => {
      state.packagingToEdit = action.payload;
      state.isOpenForm = true;
    },
    selectPackagingToDelete: (state, action: PayloadAction<string[]>) => {
      state.selectionToDelete = action.payload;
    },
    showAlertDeletePackaging: (state, action: PayloadAction<boolean>) => {
      state.isOpenAlertDeletePackagingProduct = action.payload;
    },
  },
});

export const {
  displayProductPackagingForm,
  closeProductPackagingEditionForm,
  loadProductPackagingToEdit,
  selectPackagingToDelete,
  showAlertDeletePackaging,
} = ProductPackagingSlice.actions;

export default ProductPackagingSlice.reducer;
