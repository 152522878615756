import React from 'react'
import Dialog, { DialogContent } from "@component/Dialog"
import Grid from "@component/Grid"
import NumberFormat from "@component/NumberFormat"
import ExpensesInput from "./Fixed ExpensesInput"
import { ExpensesToPaySelection } from '@interface/FixedCosts'
import { currencyFormat } from '@hook/useNumberFormat'
import Typography from '@component/Typography'
import Button from '@component/Button'
import { Boxes } from '@interface/App'
import { useFixedCosts } from '../hooks/useFixedCosts'
import Form from '@component/Form'
import { showFixedExpensePayment } from '../services/FixedCostsSlice'
import { useAppDispatch, useAppSelector } from '@hook/state'
import Swal from 'sweetalert2'

interface Props {
    forBox: Boxes
    bankId?: string
    maxAmount?: number
}

const PayFixedExpense = ({ forBox, bankId, maxAmount }:Props) => {
    const dispatch = useAppDispatch()
    const { FixedCostsState } = useAppSelector(({ FixedCostsState }) => ({ FixedCostsState }))
    const { isOpenFixedExpensePayment } = FixedCostsState
    const [expensesToPay, setExpensesToPay] = React.useState<ExpensesToPaySelection>([{ _id: "", amount: 0 }])
    const totalPayment = expensesToPay.reduce((accumulator:number, params) => params.amount+accumulator, 0)

    const handlePaymentSelected = (params:any, index:number) => {
        const expensesToPayCopy = [...expensesToPay]

        if( Boolean(params) ){
            expensesToPayCopy[index]._id = params._id
            expensesToPayCopy[index].amount = params.total
            const lastExpense = expensesToPayCopy[expensesToPayCopy.length-1]
            if( Boolean(lastExpense._id) ){
                expensesToPayCopy.push({ _id: "", amount: 0 })
            }
        }else{
            expensesToPayCopy.splice(index, 1)
        }

        setExpensesToPay(expensesToPayCopy)
    }
    const handleChangeAmount = (params:number, index:number) => {
        const expensesToPayCopy = [...expensesToPay]
        expensesToPayCopy[index].amount = params
        setExpensesToPay(expensesToPayCopy)
    }
    const { payFixedExpense, isLoadingPayFixedExpenseMutation } = useFixedCosts()

    const handleSubmit = () => {
        if( maxAmount !== undefined ){
            if( totalPayment > maxAmount ){
                Swal.fire({
                    icon: 'warning',
                    text: `No es posible pagar gastos fijos por $ ${currencyFormat(totalPayment)}, solo tiene disponible $ ${currencyFormat(maxAmount)} en la caja.`
                })
                return
            }
        }
        payFixedExpense({ box: forBox, bank: bankId, expenses: expensesToPay })
    }
    return(
        <Dialog fullWidth open={isOpenFixedExpensePayment} onClose={() => dispatch(showFixedExpensePayment(false))} >
            <DialogContent>
                <Form onSubmit={handleSubmit} >
                    <Grid container spacing={1} >
                        <Grid xs={12} >
                            <Typography fontWeight={'bold'} >Total: $ {currencyFormat(totalPayment)} </Typography>
                        </Grid>
                        {expensesToPay.map((expense, index) => {
                            return(
                                <React.Fragment key={index} >
                                    <Grid xs={6} >
                                        <ExpensesInput expenseId={expense._id} onChange={(params) => handlePaymentSelected(params, index) } />
                                    </Grid>
                                    <Grid xs={6} >
                                        <NumberFormat label="Monto" prefix="$ " value={expense.amount} onChange={({ target }) => handleChangeAmount((Number(target?.value??0)),index)} />
                                    </Grid>
                                </React.Fragment>
                            )
                        })}
                        <Grid xs={12} >
                            <Button type='submit' disableElevation loading={isLoadingPayFixedExpenseMutation} >
                                Pagar
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default PayFixedExpense