import Tooltip from "@component/Tooltip"
import DataGrid, { GridColDef } from "@component/DataGrid"
import { useAppSelector } from "@hook/state"
import { currencyFormat } from "@hook/useNumberFormat"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useGetCashierSummaryDetails } from "../hooks/useMovementsCashier"
import ListsToolbar from "./ListsToolbar"
import Typography from "@component/Typography"
import Stack from "@component/Stack"

const PaymentsReceivedByConsignments = () => {
    const { cashierId } = useParams()
    const { CashierMovementsState } = useAppSelector(({ CashierMovementsState }) => ({ CashierMovementsState }))
    const { filterMovements } = CashierMovementsState
    const { startDate, endDate } = filterMovements
    const { summary, isLoading } = useGetCashierSummaryDetails({ cashierId: cashierId!, startDate, endDate })
    const { paymentsConsignment=[] } = summary
    const { t } = useTranslation()

    const columns:GridColDef[] = [
        { maxWidth: 110, minWidth: 110, field: "consecutive", headerName: "#", valueGetter: ({ row }) => `#R${row.consecutive}` },
        { flex: 1, field: "customer", headerName: "Cliente", valueGetter: ({ row }) => `${t(row?.customer??"")}` },
        {field: "seller", headerName: "Vendedor"},
        {field: "total", headerName: "Total",
            renderCell: ({ row }) => {
                return(
                    <Tooltip placement="top" title={
                        <>
                            {row?.paymentCredits?.map((param:any, index:number) => {
                                return(
                                    <Stack direction={'row'} key={index} >
                                        <Stack sx={{ mr: 1 }} > { dayjs(param?.date).format("DD-MM-YYYY HH:mm A") }: </Stack>
                                        <Stack sx={{ mr: 1 }} > { param?.bank }: </Stack>
                                        <Stack> ${currencyFormat(param?.amount??0)} </Stack>
                                    </Stack>
                                )
                            })}
                        </>
                    } >
                        <Typography variant="body2" >
                            {`$ ${currencyFormat(row?.consignmentPayments??0)}`}
                        </Typography>
                    </Tooltip>
                )
            }
        },
        {field: "typePayment", headerName: "Tipo de pago", valueGetter: ({ row }) => `${t(row?.typePayment??"")}`},
        { maxWidth: 160, minWidth: 160, field: "createdAt", headerName: "Registro", valueGetter: ({ row }) => `${dayjs(row?.createdAt).format("DD-MM-YYYY HH:mm A")}`},
    ]

    return(
        <>
            <DataGrid
                slots={{
                    toolbar: () => <ListsToolbar listTitle="Pagos - Consignaciones recibidas" movements={paymentsConsignment} />
                }}
                height={'auto'}
                sx={{ maxHeight: 450 }}
                autoHeight
                loading={isLoading}
                columns={columns}
                rows={paymentsConsignment}
            />
        </>
    )
}

export default PaymentsReceivedByConsignments