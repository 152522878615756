import React from 'react'
import { genUUID } from "@service/parseData"
import Autocomplete from "./Autocomplete"
import InputSearchBank from '@feature/Banks/components/InputSearchBank'
import { Boxes } from '@interface/App'
import Box from './Box'

type AutocompleteProps = Omit<React.ComponentProps<typeof Autocomplete>, "options" | "renderInput" | "value" >

interface Props extends AutocompleteProps {
    omitValue?: Boxes
    value?: string
    useBanks?: boolean
    onBankChange?: (params:any) => void
}

const BoxTraslade = ({ omitValue, value, label="Trasladar", useBanks, onBankChange, ...rest }:Props) => {
    const [bankValue, setBankValue] = React.useState<any>(null)
    const options = [
        {name: "Caja menor", value: "minorBox", _id: genUUID()},
        {name: "Caja mayor", value: "largeBox", _id: genUUID()},
        {name: "Caja mayor 2", value: "masterBox", _id: genUUID()},
    ]

    const values = React.useMemo(() => {
        if( useBanks ) options.push({name: "Bancos", value: "bank", _id: genUUID()})
        return options.filter(n => n.value !== omitValue)
    },[useBanks])
    const valueSelected = values.find(n => n.value === value) || null

    const handleChangeBank = (params:any) => {
        setBankValue(params?._id??null)
        if( onBankChange !== undefined ){
            onBankChange(params)
        }
    }
    
    return(
        <>
            <Autocomplete
                {...rest}
                value={valueSelected}
                label={label}
                options={values}
            />
            {valueSelected?.value === "bank" &&
                <Box mt={1} >
                    <InputSearchBank value={bankValue} onChange={(_, params) => handleChangeBank(params)} />
                </Box>
            }
        </>
    )
}

export default BoxTraslade