import React from 'react'
import { ButtonGroup } from "@mui/material"
import Box from "@component/Box";
import Tooltip from "@component/Tooltip";
import { useTranslation } from "react-i18next";
import { usePointOfSale } from "../hooks/usePointOfSale";
import { LoadingButton } from "@mui/lab";
import { styled } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress';
import { showAlertClearBillingArea } from "../services/PointOfSaleSlice";
import { useAppDispatch } from "../../../hooks/state";
import ReactToPrint from "react-to-print";
import PointOfSaleRounded from '@mui/icons-material/PointOfSaleRounded';
import ReceiptLongRounded from '@mui/icons-material/ReceiptLongRounded';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import ReceiptRounded from '@mui/icons-material/ReceiptRounded';
import { useBusinessPermissions } from '../../../hooks/usePermissions';
import CurrencyExchangeRounded from '@mui/icons-material/CurrencyExchangeRounded';

const Button = styled(LoadingButton)(() => ({
    width: "100%"
}))

interface Props {
    invoiceElement: React.MutableRefObject<HTMLDivElement | null>
}

const PointOfSaleButtons = ({ invoiceElement }:Props) => {
    const { PointOfSale } = useBusinessPermissions()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const { recordSale, isLoadingRecordSale, isLoadingUpdateSale } = usePointOfSale()

    return(
        <Box width={1} sx={{ backgroundColor: "#FFFFFF" }} >
            <ButtonGroup sx={{ width: "100%" }} >
                {PointOfSale.record_sale &&
                    <Button id="recordSale" variant="outlined" onClick={() => recordSale()} loading={isLoadingRecordSale} >
                        {isLoadingRecordSale || isLoadingUpdateSale ? 
                            <CircularProgress /> :
                            <Tooltip title={t('recordSale')!} >
                                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
                                    <PointOfSaleRounded />
                                    (F4)
                                </Box>
                            </Tooltip>
                        }
                    </Button>
                }
                {PointOfSale.record_price &&
                    <Button id="recordPrice" variant="outlined" onClick={() => recordSale(false, "price")} loading={isLoadingRecordSale} >
                        {isLoadingRecordSale || isLoadingUpdateSale ? 
                            <CircularProgress /> :
                            <Tooltip title={t('Registrar cotización')!} >
                                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
                                    <CurrencyExchangeRounded />
                                    (F5)
                                </Box>
                            </Tooltip>
                        }
                    </Button>
                }
                {PointOfSale.record_and_print_sale &&
                    <Button id="recordSalWithPrintElement" variant="outlined" loading={isLoadingRecordSale||isLoadingUpdateSale} onClick={() => recordSale(true)} >
                        {isLoadingRecordSale || isLoadingUpdateSale ? 
                            <CircularProgress /> :
                            <Tooltip title={t('recordSaleAndPrintReceipt')!} >
                                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
                                    <ReceiptLongRounded />
                                    (F6)
                                </Box>
                            </Tooltip>
                        }
                    </Button>
                }
                <Button id="cleanBill" variant="outlined" loading={isLoadingRecordSale || isLoadingUpdateSale} onClick={() => dispatch(showAlertClearBillingArea())} >
                    {isLoadingRecordSale || isLoadingUpdateSale ? 
                        <CircularProgress /> :
                        <Tooltip title={t('clearBillingArea')!} >
                            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
                                <DeleteRounded />
                                (Supr)
                            </Box>
                        </Tooltip>
                    }
                </Button>
                {PointOfSale.print_last_receipt &&
                    <ReactToPrint
                        trigger={() => (
                            <Button id="printLastInvoice" variant="outlined" loading={isLoadingRecordSale || isLoadingUpdateSale} >
                            {isLoadingRecordSale || isLoadingUpdateSale ? 
                                <CircularProgress /> :
                                <Tooltip title={t('printLastReceipt')!} >
                                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
                                        <ReceiptRounded />
                                        (Inicio)
                                    </Box>
                                </Tooltip>
                            }
                        </Button>
                        )}
                        content={() => invoiceElement.current}

                    />
                }
            </ButtonGroup>
        </Box>
    )
}

export default PointOfSaleButtons