import Button from "@component/Button"
import Dialog, { DialogActions, DialogContent } from "@component/Dialog"
import NumberFormat from "@component/NumberFormat"
import TextField from "@component/TextField"
import { useURLParams } from "@hook/useURLParams"
import { useTranslation } from "react-i18next"
import { useGetProductsForMassEdition, useMassProduct } from "../hooks/useMassProducts"
import React from "react"
import ProductCategoriesInput from "@feature/Products/components/ProductCategoriesInput"
import PackagingProductInput from "@feature/Products/components/PackagingProductInput"
import SimpleProductInput from "@feature/Products/components/SimpleProductInput"
import InputSearchProviders from "@feature/Provider/components/InputSearchProviders"
import TaxInput from "@feature/Business/components/TaxInput"
import Form from "@component/Form"
import { UpdateProductFieldParams } from "@interface/Product"
import { Prices } from "@feature/Products/components/ProductForm"
import Grid from "@component/Grid"
import TypeProductInput from "@feature/Products/components/TypeProductInput"
import { useGetSessionDataQuery } from "@query/Users"

const EditOfSelectedField = () => {
    const { data } = useGetSessionDataQuery()
    const permissionToModifiedStock = ((data?.payload?.rol?.name??"") === "admin")
    const [newValue, setNewValue] = React.useState<any>(null)
    const { getQuery, delQueries } = useURLParams()
    const { t } = useTranslation()
    const field = getQuery("field")
    const productId = getQuery("product")
    const { getProduct } = useGetProductsForMassEdition()
    const product = getProduct(productId??"")
    const _product = React.useMemo(() => structuredClone(product), [product])
    const { updateProductField, isLoadingUpdateProductFieldMutation } = useMassProduct()
    const open = Boolean(productId) && Boolean(field)
    const textFields:string[] = ["name", "skuCode"]
    const numberFields:string[] = ["cost", "stock"]
    const selectFields:string[] = ["category", "packaging", "productParent", "provider", "tax", "prices", "compount"]

    const autocompleteElement = React.useMemo(() => {
        let elem = <></>
        if( Boolean(_product) ){
            if( field === "category"){
                elem = <ProductCategoriesInput productCategoryId={newValue} onChange={(value) => setNewValue(value??null)} />
            }
            if( field === "packaging"){
                elem = <PackagingProductInput productPackagingId={newValue} onChange={(value) => setNewValue(value??null)} />
            }
            if( field === "productParent"){
                elem = <SimpleProductInput simpleProductId={newValue} onChange={(value) => setNewValue(value??null)} />
            }
            if( field === "provider"){
                elem = <InputSearchProviders value={newValue} onChange={(_, params:any) => setNewValue(params?._id??null)} />
            }
            if( field === "tax"){
                elem = <TaxInput value={newValue} onChange={(_, params:any) => setNewValue(params?._id??null)}  />
            }
            if( field === "compount"){
                elem = <TypeProductInput value={newValue} onChange={() => setNewValue(!newValue)} />
            }
            if( field === "prices"){
                elem = <Grid container spacing={1} >
                    {newValue?.map((price:any, index:number) => {
                        return(
                            <Prices key={index} cost={_product.cost} margin={price.percentageProfit} profit={price.profit} sale={price.sellPrice} onChange={(values) => setNewValue((params:any) => {
                                let _params = [...params]
                                _params[index] = values
                                return _params
                            })} />
                        )
                    })}
                </Grid>
            }
        }

        return elem
    },[_product, field, newValue, setNewValue])

    React.useEffect(() => {
        if( _product ){
            if( field === "category" || field === "packaging" || field === "provider" || field === "tax" ){
                setNewValue(_product[field??""]?._id??"")
            }else{
                setNewValue(_product[field??""])
            }
        }
    }, [_product, field])

    React.useEffect(() => {
        if( !open ){
            setNewValue(null)
        }
    }, [open])


    const handleSubmit = () => updateProductField({ field: field as UpdateProductFieldParams['field'], productId: productId??"", value: newValue })

    return(
        <Dialog fullWidth open={open} onClose={() => delQueries(['field', 'product'])} maxWidth={'xs'} >
            <DialogContent>
                <Form onSubmit={handleSubmit} >

                </Form>
                {product !== null && product !== undefined &&
                    <>
                    { textFields.some((n) => n === field) &&
                        <TextField label={t(field??"")!} value={newValue??""} onChange={({ target }) => setNewValue(target?.value??"")} />
                    }
                    { numberFields.some((n) => n === field) &&
                        <NumberFormat disabled={_product.compount || (field === "stock" && !permissionToModifiedStock)} prefix={ field === "cost" ? "$ " : "" } label={t(field??"")!} value={newValue??0} onChange={({ target }) => setNewValue(target?.value??0)} />
                    }
                    { selectFields.some((n) => n === field) &&
                        autocompleteElement
                    }
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Button loading={isLoadingUpdateProductFieldMutation} variant="text" onClick={handleSubmit} >
                    Guardar cambios
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditOfSelectedField