import React from "react"
import { useParams } from "react-router-dom"
import { useGetEmployeeQuery } from "../../../../api/querys/Employee"
import Autocomplete from "../../../../components/Autocomplete"
import { EmployeeProps } from "@interface/Employee"

interface cutoffDateListProps {
    _id: number,
    name: string,
    value: 'biweekly' | 'monthly'
}

const cutoffDateList:Array<cutoffDateListProps> = [
    { _id: 1, name: "Quincenal", value: "biweekly" },{ _id: 2, name: "Mensual", value: "monthly" }
]

interface Props {
    setDataInitialState: React.Dispatch<React.SetStateAction<Partial<Pick<EmployeeProps, "baseSalary" | "cutForPayments" | "startOfTheDay" | "endOfDay">>>>
}

const CutoffDate = ({ setDataInitialState }:Props) => {
    const [valueSelected, setValueSelected] = React.useState<cutoffDateListProps>(cutoffDateList[0])
    const { employeeId } = useParams()
    const { data, isLoading } = useGetEmployeeQuery(employeeId!)
    const employee = data?.payload

    React.useEffect(() => {
        if( employee?.cutForPayments !== null && employee?.cutForPayments !== undefined ){
            const value = cutoffDateList.find(n => n.value === employee.cutForPayments)||null
            if( value?._id !== null && value?._id !== undefined ){
                setValueSelected(value)
            }
        }
    },[employee?.cutForPayments])

    React.useEffect(() => {
        setDataInitialState(n => ({...n, cutForPayments: valueSelected.value}))
    },[valueSelected, setDataInitialState])

    return(
        <Autocomplete
            onChange={(_, val:any) => setValueSelected(val)}
            loading={isLoading}
            value={valueSelected}
            label="Fecha de corte"
            options={cutoffDateList} />
    )
}

export default CutoffDate