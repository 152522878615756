import DataGrid, { GridColDef } from "@component/DataGrid"
import { useAppSelector } from "@hook/state"
import { currencyFormat } from "@hook/useNumberFormat"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useGetCashierSummaryDetails } from "../hooks/useMovementsCashier"
import ListsToolbar from "./ListsToolbar"

const ExpenseListCashier = () => {
    const { cashierId } = useParams()
    const { CashierMovementsState } = useAppSelector(({ CashierMovementsState }) => ({ CashierMovementsState }))
    const { filterMovements } = CashierMovementsState
    const { startDate, endDate } = filterMovements
    const { summary, isLoading } = useGetCashierSummaryDetails({ cashierId: cashierId!, startDate, endDate })
    const { expenses=[] } = summary
    const { t } = useTranslation()

    const columns:GridColDef[] = [
        { maxWidth: 150, minWidth: 150, field: "concept", headerName: "Concepto", valueGetter: ({ row }) => t(row?.concept??"") },
        { maxWidth: 150, minWidth: 150, field: "amount", headerName: "Monto", valueGetter: ({ row }) => `$ ${ currencyFormat((row?.amount??0)) }` },
        { flex: 1, field: "note", headerName: "Nota", valueGetter: ({ row }) => t(row?.note??"") },
        { maxWidth: 160, minWidth: 160, field: "createdAt", headerName: "Registro", valueGetter: ({ row }) => `${ dayjs(row?.createdAt).format("DD-MM-YYYY HH:mm A") }` },
    ]

    return(
        <>
            <DataGrid
                slots={{
                    toolbar: () => <ListsToolbar listTitle="Gastos" movements={expenses} />
                }}
                height={'auto'}
                sx={{ maxHeight: 450 }}
                autoHeight
                loading={isLoading}
                columns={columns}
                rows={expenses}
            />
        </>
    )
}

export default ExpenseListCashier