import Fade from "@component/Fade"
import Grid from "@component/Grid"
import ListWithCashiers from "./components/ListWithCashiers"
import { useBusinessPermissions } from "@hook/usePermissions"
import LockComponent from "@component/LockComponent"

const Cashiers = () => {
    const { Cashiers:CashiersPermission } = useBusinessPermissions()

    return(
        <Fade in={true} >
            {CashiersPermission.lock_module ?
                <Grid container >
                    <Grid xs={12} >
                        <ListWithCashiers />
                    </Grid>
                </Grid>
                :
                <LockComponent />
            }
        </Fade>
    )
}

export default Cashiers