import React from 'react'
import IconButton from "@component/IconButton"
import Popover from "@component/Popover"
import { FilterListRounded } from "@mui/icons-material"
import Stack from '@component/Stack'
import Checkbox from '@component/Checkbox'
import { FilterProducts } from '../hooks/useMassProducts'
import { useURLParams } from '@hook/useURLParams'
import MultipleProductCategoriesInput from '@feature/Products/components/MultipleProductCategoriesInput'
import MultipleProvidersInput from '@feature/Provider/components/MultipleProvidersInput'
import Tooltip from '@component/Tooltip'

interface Props {
    filters: FilterProducts
    setFilters: React.Dispatch<React.SetStateAction<FilterProducts>>
}

const MassiveProductFilters = ({ filters, setFilters }:Props) => {
    const { handleActionParameter, delQueries, getParameterGroup } = useURLParams()
    const [ anchorEl, setAnchorEl ] = React.useState<HTMLElement | null>(null)
    const { group="" } = getParameterGroup(['group'])

    const handleClickMassiveProductFilters = () => {
        const elem = document.getElementById("b_massiveProductFilters") as HTMLElement
        if( elem !== null ){
            setAnchorEl(elem)
        }
    }

    const handleFilters = (params:any[], field:("categories" | "providers")) => {
        const values = params.map((n) => (n?.name??"")).join("-")
        if( Boolean(values) ){
            handleActionParameter(field, values)
        }else{
            delQueries([field])
        }
    }

    return(
        <>
            <Tooltip title="Filtros" >
                <IconButton id="b_massiveProductFilters" squareButton onClick={handleClickMassiveProductFilters} >
                    <FilterListRounded />
                </IconButton>
            </Tooltip>
            <Popover PaperProps={{ sx: { minWidth: 300, width: 450 } }} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Stack sx={{ padding: 2 }} >
                    <Checkbox label="Organizar simples y compuestos" checked={filters.arrangeSimpleCompounds} onChange={() => setFilters((n) => ({...n, arrangeSimpleCompounds: !n.arrangeSimpleCompounds, onlyCompounds: false, onlySimple: false}))} />
                    <Checkbox label="Ver solo simples" checked={filters.onlySimple} onChange={() => setFilters((n) => ({...n, onlySimple: !n.onlySimple, arrangeSimpleCompounds: false}))} />
                    <Checkbox label="Ver solo compuestos" checked={filters.onlyCompounds} onChange={() => setFilters((n) => ({...n, onlyCompounds: !n.onlyCompounds, arrangeSimpleCompounds: false}))} />
                    <Checkbox label="Agrupar por categorías" checked={(group === "categories")} onChange={({ target }) => Boolean(target?.checked) ? handleActionParameter("group", "categories") : delQueries(['group']) } />
                    <Checkbox label="Agrupar por proveedores" checked={(group === "providers")} onChange={({ target }) => Boolean(target?.checked) ? handleActionParameter("group", "providers") : delQueries(['group']) } />
                    <MultipleProductCategoriesInput label='Seleccione categorías' onChange={(_, params:any) => handleFilters(params, "categories")} />
                    <MultipleProvidersInput sx={{ my: 1 }} label='Seleccione proveedores' onChange={(_, params:any) => handleFilters(params, "providers")} />
                </Stack>
            </Popover>
        </>
    )
}

export default MassiveProductFilters