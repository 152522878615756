import { useGetProviderQuery, useGetProvidersByBusinessQuery, useObtainSupplierAdvancesQuery, useUpdateSupplierAdvanceMutation } from '@query/Provider';
import { useDispatch } from 'react-redux';
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useDeleteProviderMutation, useRegisterAdvanceToProviderMutation, useRegisterProviderMutation, useUpdateProviderMutation } from "../../../api/querys/Provider"
import type { AdvanceToProviderSend, ProviderPropsForm, SupplierAdvancesHistoryAsyncResponse } from "@interface/Provider"
import { openFormCreateProvider, showAlertDeleteProvider } from "../services/ProviderSlice"
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { updatePerform, updateSelectedProvider } from '@feature/PurchasingSuppliers/services/PurchasesSuppliersSlice';
import { useURLParams } from '@hook/useURLParams';
import { ProviderID, ProviderPropsAsyncResponse, UpdateSupplierAdvanceProps } from '@interface/_Provider';
import { isErrorResponse, isSuccessResponse } from '@service/AnswersServices';

export const useProvider = () => {
    const dispatch = useDispatch()
    const { delQueries } = useURLParams()

    const [ registerProviderMutation, {isLoading:isLoadingRegisterProvider} ] = useRegisterProviderMutation()
    const [ updateProviderMutation, {isLoading:isLoadingUpdateProviderMutation} ] = useUpdateProviderMutation()
    const [ deleteProviderMutation, {isLoading:isLoadingDeleteProviderMutation} ] = useDeleteProviderMutation()
    const [ registerAdvanceToProviderMutation, {isLoading:isLoadingRegisterAdvanceToProviderMutation} ] = useRegisterAdvanceToProviderMutation()
    const [ updateSupplierAdvanceMutation, { isLoading:isLoadingUpdateSupplierAdvanceMutation } ] = useUpdateSupplierAdvanceMutation()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const registerProvider = async (provider:ProviderPropsForm) => {
        try {
            if( Boolean(provider._id) ){
                const resp = await updateProviderMutation(provider).unwrap()
                if( resp.status === 1 ){
                    enqueueSnackbar(t('vendorDataUpdatedSuccessfully')!,{ variant: "success" })
                }else{
                    enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'),{ variant: "error" })
                }
            }else{
                const resp = await registerProviderMutation(provider).unwrap()
                if( resp.status === 1 ){
                    enqueueSnackbar(t('theProviderWasRegisteredSuccessfully')!,{ variant: "success" })
                    dispatch(openFormCreateProvider(false))
                }else{
                    enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'),{ variant: "error" })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    const deleteProviders = async (providers:GridRowSelectionModel) => {
        try {
            const resp = await deleteProviderMutation(providers).unwrap()
            if( resp.status === 1 ){
                const message = providers.length >= 2 ? t('vendorsWereSuccessfullyRemoved') : t('theProviderWasRemovedSuccessfully')
                enqueueSnackbar(message,{ variant: "success" })
                dispatch(showAlertDeleteProvider(false))
            }else{
                enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'),{ variant: "error" })
            }
        } catch (error) {
            console.log(error)
        }
    }
    const registerAdvanceToSupplier = async (params:AdvanceToProviderSend) => {
        try {
            const resp = await registerAdvanceToProviderMutation(params).unwrap()
            if( resp.status === 1 ){
                enqueueSnackbar(t('El anticipo se registro con satisfactoriamente'),{ variant: "success" })
                dispatch(updatePerform(null))
                dispatch(updateSelectedProvider(null))
            }else{
                enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'),{ variant: "error" })
            }
        } catch (error) {
            console.log(error)
        }
    }
    const updateSupplierAdvance = async (payload:UpdateSupplierAdvanceProps) => {
        try {
            const resp = await updateSupplierAdvanceMutation(payload).unwrap()
            if( resp.status === 1 ){
                isSuccessResponse("Se actualizo el anticipo satisfactoriamente")
                delQueries(['ref', 'field'])
            }else{
                isErrorResponse()
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        registerProvider,
        deleteProviders,
        isLoadingRegisterProvider,
        isLoadingUpdateProviderMutation,
        isLoadingDeleteProviderMutation,
        registerAdvanceToSupplier,
        isLoadingRegisterAdvanceToProviderMutation,
        isLoadingUpdateSupplierAdvanceMutation,
        updateSupplierAdvance
    }
}

export const useGetProvider = ({ providerId }:ProviderID) => {

    const { data, isLoading, isSuccess } = useGetProviderQuery({ providerId })
    const provider = data?.payload??{} as ProviderPropsAsyncResponse['payload']
    const totalAdvance = provider?.advances?.reduce((acc, params) => (params?.amount??0)+acc,0)
    const advances = provider?.advances??[] as ProviderPropsAsyncResponse['payload']['advances']
    return { provider, totalAdvance, advances, isLoading, isSuccess }
}

export const useObtainSupplierAdvances = () => {
    const { data, isLoading } = useObtainSupplierAdvancesQuery()
    const history = data?.payload??[] as SupplierAdvancesHistoryAsyncResponse['payload']
    return { history, isLoading }
}

export const useGetProvidersByBusiness = () => {
    const { getQuery } = useURLParams()
    const providerWith = getQuery("with")
    const retention = getQuery("retention")
    const { data, isLoading } = useGetProvidersByBusinessQuery()
    const providers = data?.payload??[] as any[]
    let list = [...providers]
    if( retention === "withholding_tax" ){
        list = list.filter(n => Boolean(n?.tax?.tax))
    }
    if( providerWith === "advances" ){
        list = list.filter(n => n.advancesAvailable >= 1)
    }

    return {
        providers,
        list,
        isLoading
    }
}