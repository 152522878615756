import type { RoleProps } from './../../../interfaces/Role.d';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface stateProps {
  isOpenFormCreateRol: boolean;
  isOpenEditRol: boolean;
  rolSelected: RoleProps;
}

const initialState: stateProps = {
  isOpenFormCreateRol: false,
  isOpenEditRol: false,
  rolSelected: { name: '', permissions: [], _id: '' },
};

export const PermissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    openRoleCreateForm: (state, action: PayloadAction<boolean>) => {
      state.isOpenFormCreateRol = action.payload;
    },
    openRoleEditForm: (state, action: PayloadAction<boolean>) => {
      state.isOpenEditRol = action.payload;
    },
    selectRole: (state, action: PayloadAction<RoleProps>) => {
      state.rolSelected = action.payload;
    },
  },
});

export const { openRoleCreateForm, openRoleEditForm, selectRole } =
  PermissionSlice.actions;
export default PermissionSlice.reducer;
