import { io } from 'socket.io-client';
import { api, domain } from '../api/splitApi';
import { getToken } from './localStorage';
import { store } from '../services/store';
const dispatch = (evt) => store.dispatch(evt);

const identifiersKeys = {
    new_invoice_without_defined_means_of_payment: 'new_invoice_without_defined_means_of_payment',
    new_change_in_invoice_payment_status: 'new_change_in_invoice_payment_status',
    override_tag_for_unpaid_invoices: 'override_tag_for_unpaid_invoices',
    invalidate_tags_for_invoice_payment_method_update: 'invalidate_tags_for_invoice_payment_method_update',
    updated_role_permissions: 'updated_role_permissions',
    override_user_session_tag: 'override_user_session_tag',
    update_boxes: 'update_boxes',
};

const socketIO = io(`${domain}/`, {
    transports: ['websocket'],
    auth: {
        token: getToken(),
    },
});


export const openSocket = () => {
    socketIO.on('connect', (socket) => {
        // console.log('Socket new connection ', socket);
    });
    socketIO.on(identifiersKeys.invalidate_tags_for_invoice_payment_method_update, ({ invalidateTag }) => {
        if (invalidateTag) {
            dispatch(api.util.invalidateTags(['InvoiceWithOutPayment', 'Product']));
        }
    });
    socketIO.on(identifiersKeys.override_tag_for_unpaid_invoices, (invalidateTag) => {
        if (invalidateTag) {
            dispatch(api.util.invalidateTags(['Invoice', 'Product', 'CashWithdrawals', 'InvoiceWithOutPayment', 'Cashier', 'MinorBox', 'MultiBox']));
        }
    });
    socketIO.on(identifiersKeys.update_boxes, ({ invalidateTag }) => {
        if (invalidateTag) {
            dispatch(api.util.invalidateTags(['MinorBox', 'MultiBox', 'Cashier']))
        }
    });
    socketIO.on('connect_error', (error) => {
        console.log('Error connection socket', error);
    });
};

export const newInvoiceWithoutDefinedPayment = (invoiceId) => {
    socketIO.emit(identifiersKeys.new_invoice_without_defined_means_of_payment, invoiceId);
};
export const newChangeInvoicePaymentStatus = (invoiceIdUpdate) => {
    socketIO.emit(identifiersKeys.new_change_in_invoice_payment_status, invoiceIdUpdate);
};
export const castRoleUpdated = (isUpdate) => {
    socketIO.emit(identifiersKeys.updated_role_permissions, { isUpdate })
}