import DataGrid, { GridColDef } from "@component/DataGrid"
import { useObtainSupplierAdvances } from "../hooks/useProvider"
import { currencyFormat } from "@hook/useNumberFormat"
import dayjs from "dayjs"

const HistoryOfAdvances = () => {
    const { history, isLoading } = useObtainSupplierAdvances()
    const columns:GridColDef[] = [
        {flex: 1, field: "provider", headerName: "Proveedor"},
        {flex: 1, field: "amount", headerName: "Monto", valueGetter: ({ row }) => `$ ${currencyFormat((row?.amount??0))}`},
        {flex: 1, field: "createdAt", headerName: "Fecha de registro", valueGetter: ({ row }) => `${dayjs(row.createdAt).format("DD-MM-YYYY HH:mm A")}`},
    ]
    return(
        <>
            <DataGrid
                loading={isLoading}
                columns={columns}
                rows={history}
            />
        </>
    )
}

export default HistoryOfAdvances