import Alert from "./Alert "

const BetaPhaseAlert = () => {
    return(
        <Alert severity="warning" sx={{ mb: 1 }} >
            Este modulo se encuentra en fase <strong>BETA</strong>, En esta fase se busca obtener retroalimentación de los usuarios para detectar problemas y mejoras que puedan ser implementadas
        </Alert>
    )
}

export default BetaPhaseAlert