import { api, mutationConfig } from "../splitApi"
import { MinorBoxReportResponse, MinorBoxResponse, WithdrawalArchiveResponse, WithdrawalsForm, WithdrawalsResponse } from "../../interfaces/MinorBox";
import { DateRangeFilter } from "@interface/App";
import { MinorCashAsyncResponse, MinorCashHistoryAsyncResponse } from "@interface/MinorCash";

const User = api.injectEndpoints({
    endpoints: (build) => ({
        getMinorBox: build.query<MinorCashAsyncResponse, DateRangeFilter>({
            query: ({ startDate, endDate }) => ({ url: '/get/minor/box', params: {startDate, endDate} }),
            providesTags: ['MinorBox']
        }),
        getMinorBoxHistory: build.query<MinorCashHistoryAsyncResponse, DateRangeFilter>({
            query: ({ startDate, endDate }) => ({ url: '/get/petty/cash/history', params: {startDate, endDate} }),
            providesTags: ['MinorBox']
        }),


        getMinorBoxReport: build.query<MinorBoxReportResponse, DateRangeFilter>({
            query: ({ startDate, endDate }) => ({ url: '/get/petty/cash/report', params: {startDate, endDate} }),
            providesTags: ['MinorBox']
        }),
        getCashWithdrawals: build.query<WithdrawalsResponse, {isArchived: boolean}>({
            query: ({ isArchived }) => ({ url: `/get/cash/withdrawals`, params: {isArchived} }),
            providesTags: ['Transfer']
        }),
        archivedWithdrawal: build.mutation<WithdrawalArchiveResponse, {cashWithdrawalId: string}>({
            query(body) {
                return {...mutationConfig, url: '/archive/withdrawal', body}
            },
            invalidatesTags: ['Transfer'],
        }),
        registerWithdrawal: build.mutation<WithdrawalArchiveResponse, WithdrawalsForm>({
            query(body) {
                return {...mutationConfig, url: '/register/withdrawal', body}
            },
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetMinorBoxQuery,
    useGetMinorBoxHistoryQuery,
    useGetCashWithdrawalsQuery,
    useArchivedWithdrawalMutation,
    useRegisterWithdrawalMutation,
    useGetMinorBoxReportQuery
} = User