// components
import Button from "@component/Button"
import Form from '../../../components/Form'
import Grid from "@component/Grid"
import TextField from "@component/TextField"
// hooks
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useBank } from '../hooks/useBank'
import { useTranslation } from "react-i18next"

import { IBankProps } from '../../../interfaces/Bank'
import { FormControl, FormControlLabel, FormLabel, RadioGroup } from "@component/Radio"

const form: IBankProps = {
    name: "",
    accountNumber: 0,
    account: "current",
    accountType: "legal"
}

interface Props {
    editBank?: IBankProps
}

const BankForm = ({ editBank }: Props) => {
    const { t } = useTranslation()
    const { bankId } = useParams()
    const [bankForm, setBankForm] = useState(form)
    const { registerBanks, isLoadingRegisterBank, isLoadingUpdateBankMutation } = useBank()
    const handleSubmit = () => registerBanks(bankForm)

    useEffect(() => {
        editBank && setBankForm({ ...editBank })
    }, [editBank])


    return (
        <Form onSubmit={handleSubmit} >
            <Grid container spacing={1} >
                <Grid xs={6} >
                    <TextField required value={bankForm.name} onChange={({ target }) => setBankForm(n => ({ ...n, name: target.value as IBankProps["name"] }))} margin="none" label={t('bank')!} />
                </Grid>
                <Grid xs={6} >
                    <TextField value={bankForm.accountNumber} onChange={({ target }) => setBankForm(n => ({ ...n, accountNumber: target.value }))} margin="none" label={t('accountNumber')!} />
                </Grid>
                <Grid xs={6} >
                    <FormControl>
                        <FormLabel>{t('personType')!}</FormLabel>
                        <RadioGroup value={bankForm.accountType} onChange={({ target }) => setBankForm((n) => ({ ...n, accountType: target.value as IBankProps["accountType"] }))}>
                            <FormControlLabel value='legal' label={t('legal')!} />
                            <FormControlLabel value='natural' label={t('natural')!} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid xs={6} >
                    <FormControl>
                        <FormLabel>{t('accountType')}</FormLabel>
                        <RadioGroup value={bankForm.account} onChange={({ target }) => setBankForm((n) => ({ ...n, account: target.value as any }))}>
                            <FormControlLabel value='current' label={t('current')!} />
                            <FormControlLabel value='savings' label={t('savings')!} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid xs={12} >
                    <Button type="submit" loading={isLoadingRegisterBank || isLoadingUpdateBankMutation} > {bankId ? t('save') : t('createBank')} </Button>
                </Grid>
            </Grid>
        </Form>
    )
}

export default BankForm 