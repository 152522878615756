import Box from "@component/Box"
import Divider from "@component/Divider"
import Fade from "@component/Fade"
import Form from "@component/Form"
import Grid from "@component/Grid"
import IconButton from "@component/IconButton"
import NumberFormat from "@component/NumberFormat"
import Typography from "@component/Typography"
import NightlySurchargesList from "./PaySheetComponents/NightlySurchargesList"
import WorkDay from "./PaySheetComponents/WorkDay"
import SaveRounded from '@mui/icons-material/SaveRounded';
import Tooltip from "@component/Tooltip"
import CutoffDate from "./PaySheetComponents/CutoffDate"
import BaseSalary from "./PaySheetComponents/BaseSalary"
import React from "react"
import { EmployeeProps } from '../../../interfaces/Employee'
import { useParams } from "react-router-dom"
import { useGetEmployeeQuery } from "../../../api/querys/Employee"
import { useEmployee } from "../hooks/useEmployee"



const PaySheet = () => {
    const [basicPayrollInformation, setBasicPayrollInformation] = React.useState<Partial<Pick<EmployeeProps, "_id" | "baseSalary" | "cutForPayments" | "startOfTheDay" | "endOfDay">>>({})
    const { employeeId } = useParams()
    const { data } = useGetEmployeeQuery(employeeId!)
    const employee = data?.payload
    const { updateBasicPayrollData } = useEmployee({})
    React.useEffect(() => {
        if( employee !== undefined  && employee !== null ){
            setBasicPayrollInformation({
                baseSalary: employee.baseSalary,
                cutForPayments: employee.cutForPayments,
                endOfDay: employee.endOfDay,
                startOfTheDay: employee.startOfTheDay,
            })
        }else{
            setBasicPayrollInformation({
                baseSalary: 0,
                cutForPayments: 'biweekly',
                endOfDay: '17:00',
                startOfTheDay: '08:00',
            })
        }
    },[employee?._id, setBasicPayrollInformation])
    const handleSubmit = () => updateBasicPayrollData( {...basicPayrollInformation, _id: employeeId} as Required<typeof basicPayrollInformation> )

    return(
        <Fade in={true} >
            <Form onSubmit={handleSubmit} >
                <Grid container spacing={2} sx={{ position: 'relative' }} >
                    <Box right={'32vw'} position={'fixed'} >
                        <Tooltip title="Guardar cambios" >
                            <IconButton squareButton onClick={handleSubmit} >
                                <SaveRounded />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Grid xs={12} >
                        <Typography textTransform={'uppercase'} fontWeight={'bold'} color={'GrayText'} >Jornada laboral</Typography>
                        <Divider />
                    </Grid>
                    <Grid xs={12} >
                        <WorkDay setDataInitialState={setBasicPayrollInformation} />
                    </Grid>
                    <Grid xs={12} >
                        <Typography textTransform={'uppercase'} fontWeight={'bold'} color={'GrayText'} >Devengado</Typography>
                        <Divider />
                    </Grid>
                    <Grid xs={12} >
                        <CutoffDate setDataInitialState={setBasicPayrollInformation} />
                    </Grid>
                    <Grid xs={12} >
                        <BaseSalary setDataInitialState={setBasicPayrollInformation} />
                    </Grid>
                    <Grid xs={12} >
                        <NightlySurchargesList />
                    </Grid>
                    <Grid xs={12} >
                        <Typography textTransform={'uppercase'} fontWeight={'bold'} color={'GrayText'} >Deducciones</Typography>
                        <Divider />
                    </Grid>
                    <Grid xs={12} >
                        <NumberFormat prefix="$" label="Aporte a salud" />
                    </Grid>
                </Grid>
            </Form>
        </Fade>
    )
}

export default PaySheet