import CreditsPreviousTable from "./CreditsPreviousTable"
import CreditsTable from "./CreditsTable"
import DeliveriesTable from "./DeliveriesTable"
import SaleBranchTable from "./SaleBranchTable"
import UndefinedTable from "./UndefinedTable"

const DiscriminationCloseOfTheDay = () => {

    return(
        <>
            <CreditsPreviousTable />
            <SaleBranchTable />
            <CreditsTable />
            <DeliveriesTable />
            <UndefinedTable />
        </>
    )
}

export default DiscriminationCloseOfTheDay