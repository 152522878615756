import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { GridRowSelectionModel } from '@mui/x-data-grid'

interface stateProps {
    isOpenFormCreateProvider: boolean
    selectedProviders: GridRowSelectionModel
    isOpenAlertDeleteProvider: boolean
}

const initialState:stateProps = {
    isOpenFormCreateProvider: false,
    selectedProviders: [],
    isOpenAlertDeleteProvider: false
}

export const ProviderSlice = createSlice({
    name: 'provider',
    initialState,
    reducers: {
        openFormCreateProvider: (state, action:PayloadAction<boolean>) => {
            state.isOpenFormCreateProvider = action.payload
        },
        selectProviders: (state, action:PayloadAction<GridRowSelectionModel>) => {
            state.selectedProviders = action.payload
        },
        showAlertDeleteProvider: (state, action:PayloadAction<boolean>) => {
            state.isOpenAlertDeleteProvider = action.payload
        }
    }
})

export const {
    openFormCreateProvider,
    selectProviders,
    showAlertDeleteProvider
} = ProviderSlice.actions
export default ProviderSlice.reducer