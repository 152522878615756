import Fade from '@component/Fade'
import Grid from '@component/Grid'
import LockComponent from '@component/LockComponent'
import { useBusinessPermissions } from '@hook/usePermissions'
import CreditsList from '@credit_components/CreditsList'
import CreditInformation from '@credit_components/CreditInformation'
import FilterCredits from '@credit_components/FilterCredits'
import Title from '@component/Title'

const CreditReport = () => {
    const { Credit } = useBusinessPermissions()
    return(
        <Fade in={true} >
            {Credit.lock_module ?
                <Grid container spacing={1} >
                    <Grid xs={12} sm={12} md={12} lg={12} >
                        <Title text='Créditos' />
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} >
                        <FilterCredits />
                    </Grid>
                    <Grid xs={9} >
                        <CreditsList />
                    </Grid>
                    <Grid xs={3} >
                        <CreditInformation />
                    </Grid>
                </Grid>
                :
                <LockComponent />
            }
        </Fade>
    )
}

export default CreditReport