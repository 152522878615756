import { Avatar as AvatarMUI, AvatarProps } from '@mui/material'

type Props = Pick<AvatarProps, "children" | "sx" | "onClick" | "src" >

const Avatar = ({children, ...rest}:Props) => {
    return(
        <AvatarMUI {...rest} >
            {children}
        </AvatarMUI>
    )
}

export default Avatar