import React from 'react'
import { useTranslation } from "react-i18next"
import Box from "@component/Box"
import Button from "@component/Button"
import Form from "@component/Form"
import TextField from "@component/TextField"
import Typography from "@component/Typography"
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import IconButton from "@component/IconButton"
import Tooltip from "@component/Tooltip"
import Grid from "@component/Grid"
import AppTitle from "../../Authentication/components/AppTitle"
import { clearRegistrationForm, loadToken, toggleFormAuthentication, updateErrorsInRegistrationFormFields, updateUserRegistrationForm } from "../../Authentication/services/AuthenticationSlice"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import Fade from "@component/Fade"
import { useSnackbar } from 'notistack';
import { useRegisterUserMutation } from '../../../api/querys/Authentication'
import { validateDoublePassword, validateRegisterForm } from '../../Authentication/services/methods'
import { useMessageErrors } from '../../../hooks/messageErrors'


const RegistrationForm = () => {
    const [registerUser, { isLoading }] = useRegisterUserMutation()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const { messageErrors } = useMessageErrors()
    const { AuthenticationState } = useAppSelector(({ AuthenticationState }) => ({ AuthenticationState }))
    const { registerForm, registerFormFieldError } = AuthenticationState

    const handleChange = ({ target }:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { name, value } = target
        const data = {...registerForm, [name]: value}
        dispatch(updateUserRegistrationForm(data))
    }

    const handleSubmit = async () => {
        let errors = validateRegisterForm(registerForm)
        dispatch(updateErrorsInRegistrationFormFields(errors))
        if( Object.keys(errors).length >= 1 ){
            enqueueSnackbar(t('verifyForm'), { variant: "warning" });
            return
        }
        if( !validateDoublePassword(registerForm) ){
            enqueueSnackbar(t('passwordsDoNotMatch'), { variant: "warning" });
            return
        }
        const { status, payload } = await registerUser(registerForm).unwrap()
        if( status === 0 ){
            messageErrors({ errorCode: payload?.code??0, key:payload?.key??"", respStatus: status, keyValue:payload?.keyValue??{} })
            return
        }
        enqueueSnackbar(t('userRegisteredWithExist'), { variant: "success" });
        if( !Boolean(payload.token) ){
            enqueueSnackbar(t('tokenError'), { variant: "error" });
            return
        }
        dispatch(clearRegistrationForm())
        dispatch(loadToken(payload.token))
    }

    return(
        <>
            <AppTitle />
            <Fade in={true} >
                <Form onSubmit={handleSubmit} >
                    <Box mt={3} mb={1} >
                        <Typography variant="h6" fontWeight={"bold"} textAlign={"center"} sx={{ textTransform: "uppercase" }} > {t('signUpEasy')} </Typography>
                    </Box>
                    <Grid container spacing={1} >
                        <Grid xs={6} >
                            <TextField value={registerForm.name} margin="none" error={Boolean(registerFormFieldError.name)} name="name" onChange={handleChange} inputProps={{ style: { fontWeight: "500" } }} autoFocus type="text" label={t('name')} />
                        </Grid>
                        <Grid xs={6} >
                            <TextField value={registerForm.lastName} margin="none" name="lastName" onChange={handleChange} inputProps={{ style: { fontWeight: "500" } }} type="text" label={t('surname')} />
                        </Grid>
                        <Grid xs={12} >
                            <TextField value={registerForm.email} margin="none" error={Boolean(registerFormFieldError.email)} name="email" onChange={handleChange} inputProps={{ style: { fontWeight: "500" } }} type="email" label={t('email')} />
                        </Grid>
                        <Grid xs={12} >
                            <TextField value={registerForm.phone} margin="none" name="phone" onChange={handleChange} inputProps={{ style: { fontWeight: "500" } }} type="text" label={t('phoneNumber')} />
                        </Grid>
                        <Grid xs={6} >
                            <TextField value={registerForm.password} margin="none" error={Boolean(registerFormFieldError.password)} name="password" onChange={handleChange} type="password" label={t('password')} />
                        </Grid>
                        <Grid xs={6} >
                            <TextField value={registerForm.repeatPassword} margin="none" error={Boolean(registerFormFieldError.repeatPassword)} name="repeatPassword" onChange={handleChange} type="password" label={t('repeatPassword')} />
                        </Grid>
                    </Grid>
                    <Button loading={isLoading} type='submit' >
                        {t('checkIn')}
                    </Button>
                    <Box mt={2} >
                        <Tooltip title={t('toReturn')} placement="right-end" >
                            <IconButton onClick={() => dispatch(toggleFormAuthentication("login")) } >
                                <ChevronLeftRoundedIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Form>
            </Fade>
        </>
    )
}

export default RegistrationForm