import Button from "@component/Button"
import Dialog, { DialogActions, DialogContent, DialogTitle } from "@component/Dialog"
import Grid from "@component/Grid"
import { useProvider } from "@feature/Provider/hooks/useProvider"
import { useAppDispatch, useAppSelector } from "@hook/state"
import { AdvanceToProviderSend } from "@interface/Provider"
import React from "react"
import BoxPayments from '../../Business/components/BoxPayments'
import { updatePerform, updateSelectedProvider } from '../services/PurchasesSuppliersSlice'

const AddAdvanceToSupplier = () => {
    const [payments, setPayments] = React.useState<AdvanceToProviderSend | null>(null)
    const dispatch = useAppDispatch()
    const { PurchasesSuppliersState } = useAppSelector(({ PurchasesSuppliersState }) => ({ PurchasesSuppliersState }))
    const { perform, providerSelected } = PurchasesSuppliersState
    const open = (perform === "advance" && providerSelected !== null)
    const { registerAdvanceToSupplier } = useProvider()

    const handleClose = () => {
        dispatch(updatePerform(null))
        dispatch(updateSelectedProvider(null))
    }

    const handleSubmit = () => registerAdvanceToSupplier(payments!)
    return(
        <Dialog fullWidth open={open} onClose={handleClose} >
            <DialogTitle>
                {providerSelected?.name??""}
            </DialogTitle>
            <DialogContent>
                <Grid container >
                    <Grid xs={12} >
                        <BoxPayments onChange={(n) => setPayments({...n[0], providerId: providerSelected?._id??""})} maxPayments={1} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={handleSubmit} >
                    Guardar anticipo
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddAdvanceToSupplier