import Grid from "@component/Grid"
import Title from "@component/Title"
import PurchasesList from "@purchase_report_component/PurchasesList"
import PurchasingReportFilters from "@purchase_report_component/PurchasingReportFilters"

const PurchaseReport = () => {
    return(
        <>
            <Grid container >
                <Grid xs={12} >
                    <Title text="Reporte de compras" />
                </Grid>
                <Grid xs={12} >
                    <PurchasingReportFilters />
                </Grid>
                <Grid xs={12} >
                    <PurchasesList />
                </Grid>
            </Grid>
        </>
    )
}

export default PurchaseReport