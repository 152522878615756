import { InventoryDataResponse } from "@interface/Product"
import { useGetProductInventoryQuery } from "@query/Product"


type GetProductInventoryParamsProps = { categoryId?: string }

export const useGetProductInventory = ({ categoryId }:GetProductInventoryParamsProps) => {
    type ResultResponse = InventoryDataResponse['payload']['productsInventory']['results']
    const { data, isLoading } = useGetProductInventoryQuery(categoryId)
    const resp = {...data}
    const list = resp?.payload?.productsInventory?.results??[] as ResultResponse
    let listForCategory: { category: string, totalInventory: number, results: ResultResponse }[] = []

    for( let item of list ){
        const getIndex = listForCategory.findIndex((n) => (n?.category??"") === (item?.category??"default"))
        if( getIndex >= 0 ){
            listForCategory[getIndex].results.push(item)
            listForCategory[getIndex].totalInventory += item.totalStockCost
        }else{
            listForCategory.push({
                category: (item?.category??"default"),
                totalInventory: (item?.totalStockCost??0),
                results: [item]
            })
        }
    }

    return {
        resp,
        list,
        isLoading,
        listForCategory
    }
}