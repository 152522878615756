import DataGrid, { GridColDef } from "@component/DataGrid"
import { useGetHistoryOfPaidAccounts } from "../hooks/useAccountsPayable"
import { currencyFormat } from "@hook/useNumberFormat"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import HistoryOfPaidAccountsToolbar from "./HistoryOfPaidAccountsToolbar"

const HistoryOfPaidAccounts = () => {
    const { history } = useGetHistoryOfPaidAccounts()
    const { t } = useTranslation()
    const columns:GridColDef[] = [
        {minWidth: 160, flex: 1, field: 'cashier', headerName: "Registrado por", valueGetter: ({row}) => `${row?.cashier?.name??""} ${row?.cashier?.lastName??""}`},
        {minWidth: 120, flex: 1, field: 'tag', headerName: "Etiqueta"},
        {minWidth: 160, flex: 1, field: 'name', headerName: "Asunto"},
        {maxWidth: 110, minWidth: 110, field: 'amount', headerName: "Monto", renderCell: ({row}) => `$ ${currencyFormat(row?.amount??0)}`},
        {maxWidth: 95, minWidth: 95, field: 'expiration', headerName: "Vencimiento", renderCell: ({row}) => `${dayjs(row?.expiration).format("DD-MM-YYYY")}` },
        {maxWidth: 110, minWidth: 110, field: 'pay', headerName: "Pago", renderCell: ({row}) => `$ ${currencyFormat(row?.pay??0)}`},
        {maxWidth: 110, minWidth: 110, field: 'balance', headerName: "Saldo", renderCell: ({row}) => `$ ${currencyFormat(row?.balance??0)}`},
        {maxWidth: 120, minWidth: 120, field: 'box', headerName: "Caja de pago", renderCell: ({row}) => `${t(row?.box??"")}` },
        {maxWidth: 160, minWidth: 160, field: 'paymentDate', headerName: "Fecha de pago", renderCell: ({row}) => `${dayjs(row?.expiration).format("DD-MM-YYYY HH:mm A")}` },
    ]

    return(
        <>
            <DataGrid
                slots={{
                    toolbar: HistoryOfPaidAccountsToolbar
                }}
                columns={columns}
                rows={history}
            />
        </>
    )
}

export default HistoryOfPaidAccounts