import Dialog, { DialogContent } from "@component/Dialog"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { CustomerDataProps } from "@interface/Customer"
import CustomerForm from "../../Customer/components/CustomerForm"
import { selectCustomerForInvoice, showFormCreateQuickClient } from "../services/PointOfSaleSlice"
import type { InvoiceProps } from "@interface/PointOfSale"

const CreateFastCustomer = () => {
    const dispatch = useAppDispatch()
    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }))
    const { isOpenFormCreateQuickClient } = PointOfSaleState
    const handleCreateFastCustomer = (customer:CustomerDataProps) => {
        if( Boolean(customer._id) ){
            const invoiceCustomer:InvoiceProps['customer'] = {
                _id: customer._id,
                address: customer.address,
                city: customer._id,
                name: customer.name,
                phoneNumber: customer.phoneNumber
            }
            dispatch(selectCustomerForInvoice(invoiceCustomer))
            dispatch(showFormCreateQuickClient(false))
        }
    }
    return(
        <Dialog fullWidth open={isOpenFormCreateQuickClient} onClose={() => dispatch(showFormCreateQuickClient(false))} >
            <DialogContent>
                <CustomerForm onCreateFastCustomer={handleCreateFastCustomer} />
            </DialogContent>
        </Dialog>
    )
}

export default CreateFastCustomer