import {
  Drawer as DrawerMUI,
  DrawerProps as DrawerPropsMUI,
} from '@mui/material';

type Props = Pick<
  DrawerPropsMUI,
  'children' | 'anchor' | 'open' | 'onClose' | 'sx'
>;

const Drawer = ({ children, ...rest }: Props) => {
  return <DrawerMUI PaperProps={{style: {width: "260px"}}} {...rest}>{children}</DrawerMUI>;
};

export default Drawer;
