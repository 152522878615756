import { useTranslation } from "react-i18next"
import Box from "@component/Box"
import Typography from "@component/Typography"

const AppTitle = () => {
    const { t } = useTranslation()
    return(
        <Box component={"div"} >
            <Typography variant="h6" fontWeight={"bold"} fontSize="1.5rem" fontFamily={"Alatsi"} lineHeight={1} textTransform={"uppercase"} > {t('appTitle')} </Typography>
            <Typography variant="h6" fontWeight={"500"} > {t('fastCompleteAndSafe')} </Typography>
        </Box>
    )
}

export default AppTitle