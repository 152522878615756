import Box from "@component/Box"
import Button from "@component/Button"
import Divider from "@component/Divider"
import List, { ListItem, ListItemIcon, ListItemText } from "@component/List"
import Popover from "@component/Popover"
import Stack from "@component/Stack"
import Typography from "@component/Typography"
import BoxPayments from "@feature/Business/components/BoxPayments"
import { useAppContextSelector } from "@hook/state"
import { currencyFormat } from "@hook/useNumberFormat"
import { useURLParams } from "@hook/useURLParams"
import React from "react"
import { useGetPurchasesFromSupplierBusiness, usePurchaseReport } from "@purchase_report_hook/usePurchaseReport"
import AttachMoneyRounded from '@mui/icons-material/AttachMoneyRounded';
import Checkbox from "@component/Checkbox"
import { RecordPaymentPurchaseProps } from "@interface/ReportPurchase"
import NumberFormat from "@component/NumberFormat"

interface Props {
    anchorEl: HTMLButtonElement | null
    onClose?: () => void
    variant?: "payment" | "discount"
}

const PaymentPurchases = ({ anchorEl, onClose, variant="payment" }: Props) => {
    const { sendPaymentToPurchase, isLoadingSendPaymentToPurchaseMutation, generateShoppingDiscount, isLoadingGenerateShoppingDiscountMutation } = usePurchaseReport()
    const [paymentsPurchase, setPaymentsPurchase] = React.useState<RecordPaymentPurchaseProps[]>([])
    const { getParameterGroup } = useURLParams()
    const { group=null } = getParameterGroup(["group"])
    const { list } = useGetPurchasesFromSupplierBusiness()
    const { PurchaseReportContext } = useAppContextSelector()
    const { selectedShoppingList } = PurchaseReportContext
    
    let populateList = selectedShoppingList?.map((n) => list?.filter((x) => n?.some((c) => c === (x?._id??""))) )
    populateList = populateList?.map((n) => n?.sort((a, b) => (a?.provider?.name??"").localeCompare((b?.provider?.name??"")) ))

    let singleList = populateList.reduce((acc, params) => [...acc, ...params], [])

    const handleClose = () => {
        if (onClose !== undefined) {
            onClose()
        }
    }

    const handleChange = ({ paymentWithAdvance, payments, purchaseId, index }:RecordPaymentPurchaseProps & { index:number }) => {
        const _payments = [...paymentsPurchase]
        _payments[index] = {paymentWithAdvance, payments, purchaseId}
        setPaymentsPurchase(_payments)
    }
    const handleChangeAdvanceValue = ({ paymentWithAdvance, payments, purchaseId, index }:RecordPaymentPurchaseProps & { index:number }) => {
        const _payments = [...paymentsPurchase]
        _payments[index] = { paymentWithAdvance, payments, purchaseId }
        setPaymentsPurchase(_payments)
    }
    const handleSubmit = () => {
        if( variant === "payment" ){
            sendPaymentToPurchase(paymentsPurchase)
        }
        if( variant === "discount" ){
            const payload = selectedShoppingList.reduce((acc, val) => [...acc, ...val], [])
            generateShoppingDiscount(payload)
        }
    }
    React.useEffect(() => {
        if( !Boolean(anchorEl) ){
            setPaymentsPurchase([])
        }
    },[anchorEl])
    return (
        <>
            <Popover PaperProps={{ sx: { minWidth: 300, width: 450 } }} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Box px={2} py={1} >
                    <Stack>
                        {group === "providers" ?
                            <>
                                {populateList.map((list, index) => {
                                    let totalByProviders = list.reduce((acc, params) => (params?.balance ?? 0) + acc, 0)
                                    return (
                                        <Box key={index} >
                                            {list.map((item, indexItem) => {
                                                
                                                return(
                                                    <Box key={indexItem} >
                                                        <Box display={'flex'} justifyContent={'space-between'} >
                                                            {indexItem === 0 ?
                                                                <Typography fontWeight={'bold'} fontSize={'.875rem'} textTransform={'uppercase'} display={'flex'} flexDirection={'column'} >
                                                                    {list[0]?.provider?.name??""}
                                                                    { (variant === "payment") &&
                                                                        <Box lineHeight={1} component={'span'} fontSize={'.775rem'} fontWeight={'bold'} color={'GrayText'} textTransform={'uppercase'} > Anticipo disponible: $ {currencyFormat(item?.provider?.advance??0)} </Box>
                                                                    }
                                                                </Typography>
                                                                :
                                                                <Box />
                                                            }
                                                            <Typography color={'GrayText'} fontSize={'.875rem'} fontWeight={'bold'} textTransform={'uppercase'} >
                                                                {`C#${item?.consecutive ?? 0} - F#${item?.invoiceNumber ?? 0}`}
                                                            </Typography>
                                                        </Box>
                                                        <List sx={{ padding: 0 }} >
                                                            <ListItem sx={{ padding: 0 }} >
                                                                <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ fontSize: '.875rem', fontWeight: '700' }} primary={`$ ${currencyFormat(item?.balance ?? 0)}`} />
                                                                { (variant === "payment") &&
                                                                    <ListItemIcon>
                                                                        <Checkbox disabled={(item?.provider?.advance??0) === 0} onChange={({ target }) => handleChange({ payments: [], paymentWithAdvance: target.checked, purchaseId: item?._id??"", index: indexItem })} label="Pagar con anticipo" />
                                                                    </ListItemIcon>
                                                                }
                                                            </ListItem>
                                                        </List>
                                                        { (variant === "payment") &&
                                                            <>
                                                                {!Boolean(paymentsPurchase[indexItem]?.paymentWithAdvance) ?
                                                                    <BoxPayments onChange={(payments) => handleChange({ payments, paymentWithAdvance: false, purchaseId: item?._id??"", index: indexItem })} size="small" maxPayment={(item?.balance??0)} />
                                                                    :
                                                                    <>
                                                                        <NumberFormat size="small" label="Anticipo" maxVal={(item?.provider?.advance??0)} onChange={({ target }) => handleChangeAdvanceValue({ index, payments: [{ bank: null, box: "advance", payment: (Number(target?.value??0)) }], paymentWithAdvance: true, purchaseId: (item?._id??"") })} />
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </Box>
                                                )
                                            })}
                                            { (variant === "payment") ?
                                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                                                    <Typography fontSize={'.875rem'} fontWeight={'bold'} textTransform={'uppercase'} > {`Total : `} </Typography>
                                                    <Typography fontSize={'1rem'} fontWeight={'bold'} textTransform={'uppercase'} > {`$ ${currencyFormat(totalByProviders)}`} </Typography>
                                                </Box>
                                                :
                                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                                                    <Typography fontSize={'.875rem'} fontWeight={'bold'} textTransform={'uppercase'} > {`Total en descuentos: `} </Typography>
                                                    <Typography fontSize={'1rem'} fontWeight={'bold'} textTransform={'uppercase'} > {`$ ${currencyFormat(totalByProviders)}`} </Typography>
                                                </Box>
                                            }
                                            <Divider sx={{ my: 1 }} />
                                        </Box>
                                    )
                                })}
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                                { (variant === "payment") ?
                                    <>
                                        <Typography fontSize={'.875rem'} fontWeight={'bold'} textTransform={'uppercase'} > {`Total a pagar : `} </Typography>
                                        <Button startIcon={<AttachMoneyRounded />} disableElevation onClick={handleSubmit} disabled={paymentsPurchase.length === 0 || populateList.reduce((acc, params) => acc+(params.reduce((accumulator, values) => (values?.balance??0)+accumulator, 0 )),0 ) <= 0} loading={isLoadingSendPaymentToPurchaseMutation} >
                                            { `${currencyFormat( paymentsPurchase.reduce((acc, params) => (params.payments.reduce((_, items) => (items?.payment??0)+acc,0 )),0) )}` }
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <Typography fontSize={'.875rem'} fontWeight={'bold'} textTransform={'uppercase'} > {`Total en descuentos : `} </Typography>
                                        <Button startIcon={<AttachMoneyRounded />} disableElevation onClick={handleSubmit} disabled={populateList.reduce((acc, value) => [...acc, ...value], []).reduce((acc:any, value:any) => acc+(value?.balance??0),0) <= 0} loading={isLoadingGenerateShoppingDiscountMutation} >
                                            { `${currencyFormat(
                                                populateList.reduce((acc, value) => [...acc, ...value], []).reduce((acc:any, value:any) => acc+(value?.balance??0),0)
                                            )}` }
                                        </Button>
                                    </>
                                }
                                </Box>
                            </>
                            :
                            <>
                                {singleList.map((item, index) => {
                                    return (
                                        <Box key={index} >
                                            <Box display={'flex'} justifyContent={'space-between'} >
                                                <Typography fontSize={'.875rem'} fontWeight={'bold'} textTransform={'uppercase'} display={'flex'} flexDirection={'column'} >
                                                    {item?.provider?.name ?? ""}
                                                    { (variant === "payment") &&
                                                        <Box lineHeight={1} component={'span'} fontSize={'.775rem'} fontWeight={'bold'} color={'GrayText'} textTransform={'uppercase'} > Anticipo disponible: $ {currencyFormat(item?.provider?.advance??0)} </Box>
                                                    }
                                                </Typography>
                                                <Typography color={'GrayText'} fontSize={'.875rem'} fontWeight={'bold'} textTransform={'uppercase'} >
                                                    {`C#${item?.consecutive ?? 0} - F#${item?.invoiceNumber ?? 0}`}
                                                </Typography>
                                            </Box>
                                            <List sx={{ padding: 0 }} >
                                                <ListItem sx={{ padding: 0 }} >
                                                    <ListItemText sx={{ m: 0 }} primaryTypographyProps={{ fontSize: '.875rem', fontWeight: '700' }} primary={`$ ${currencyFormat(item?.balance ?? 0)}`} />
                                                    { (variant === "payment") &&
                                                        <ListItemIcon>
                                                            <Checkbox disabled={(item?.provider?.advance??0) === 0} label="Pagar con anticipo" onChange={({ target }) => handleChange({ payments: [], paymentWithAdvance: target.checked, purchaseId: item?._id??"", index })} />
                                                        </ListItemIcon>
                                                    }
                                                </ListItem>
                                            </List>
                                            { (variant === "payment") &&
                                                <>
                                                    { !Boolean(paymentsPurchase[index]?.paymentWithAdvance) ?
                                                        <BoxPayments size="small" maxPayment={(item?.balance??0)} onChange={(payments) => handleChange({ payments, paymentWithAdvance: false, purchaseId: item?._id??"", index })} />
                                                        :
                                                        <>
                                                            <NumberFormat onChange={({ target }) => handleChangeAdvanceValue({ index, payments: [{ bank: null, box: "advance", payment: (Number(target?.value??0)) }], paymentWithAdvance: true, purchaseId: (item?._id??"") })} size="small" label="Anticipo" maxVal={(item?.provider?.advance??0)} />
                                                        </>
                                                    }
                                                </>
                                            }
                                            <Divider sx={{ my: 1 }} />
                                        </Box>
                                    )
                                })}
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mt={2} >
                                { (variant === "payment") &&
                                    <>
                                        <Typography fontSize={'.875rem'} fontWeight={'bold'} textTransform={'uppercase'} > {`Total a pagar: `} </Typography>
                                        <Button startIcon={<AttachMoneyRounded />} disableElevation onClick={handleSubmit} disabled={((paymentsPurchase.length === 0) || (singleList.reduce((acc, params) => (params?.balance ?? 0) + acc, 0) <= 0))} loading={isLoadingSendPaymentToPurchaseMutation} >
                                            { `${currencyFormat( paymentsPurchase.reduce((acc, params) => (params.payments.reduce((_, items) => (items?.payment??0)+acc,0 )),0) )}` }
                                        </Button>
                                    </>
                                }
                                { (variant === "discount") &&
                                    <>
                                        <Typography fontSize={'.875rem'} fontWeight={'bold'} textTransform={'uppercase'} > {`Total en descuentos: `} </Typography>
                                        <Button startIcon={<AttachMoneyRounded />} disableElevation onClick={handleSubmit} disabled={ (singleList?.reduce((acc, val) => (acc+val?.balance??0),0)) <= 0 } loading={isLoadingGenerateShoppingDiscountMutation} >
                                            { `${currencyFormat( (singleList?.reduce((acc, val) => (acc+val?.balance??0),0)) )}` }
                                        </Button>
                                    </>
                                }
                                </Box>
                            </>
                        }
                    </Stack>
                </Box>
            </Popover>
        </>
    )
}

export default React.memo(PaymentPurchases)