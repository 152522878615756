import Button from "@component/Button"
import Dialog, { DialogActions, DialogContent } from "@component/Dialog"
import Grid from "@component/Grid"
import BoxPayments from "@feature/Business/components/BoxPayments"
import { useURLParams } from "@hook/useURLParams"
import React from "react"
import { useCustomer } from "../hooks/useCustomer"

interface Props {
    maxAmount: number
}

const ReturnAdvance = ({ maxAmount }:Props) => {
    const [amountReturnData, setAmountReturnData] = React.useState(null)
    const { getQuery, delQueries } = useURLParams()
    const open = (getQuery("modal") === "return_advance")
    const customerId = getQuery("ref")
    const { returnAdvanceToCustomer, isLoadingReturnAdvanceToCustomerMutation } = useCustomer()
    const handlePayment = (params:any[]) => {
        const payment = params[0]??null
        setAmountReturnData(payment)
    }
    const sendReturnAdvanceToCustomer = () => returnAdvanceToCustomer({ customerId, payment: amountReturnData })
    return(
        <>
            <Dialog maxWidth="xs" fullWidth open={open} onClose={() => delQueries(['modal', 'ref'])} >
                <DialogContent>
                    <Grid container >
                        <Grid xs={12} >
                            <BoxPayments maxPayments={1} maxPayment={maxAmount} onChange={(params) => handlePayment(params)} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" loading={isLoadingReturnAdvanceToCustomerMutation} onClick={sendReturnAdvanceToCustomer} >
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ReturnAdvance