import ApplicationMode from "@component/ApplicationMode"
import Box from "@component/Box"
import Card from "@component/Card"
import Container from "@component/Container"
import Grid from "@component/Grid"
import Image from "@component/Image"
import { useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useAppSelector } from "../../hooks/state"
import loginLg from '../../styles/images/login-lg.png'
import LoginForm from "./components/LoginForm"
import RegistrationForm from "./components/RegisterForm"

const Authentication = () => {
    const { AuthenticationState } = useAppSelector(({ AuthenticationState }) => ({ AuthenticationState }))
    const { authenticationForm } = AuthenticationState
    const theme = useTheme()
    const mediaQuery = useMediaQuery(theme.breakpoints.down("sm"))

    return(
        <Box width={"100%"} height={"100%"} >
            <Box display={'flex'} justifyContent={'end'} position="fixed" width={1} left={"-20px"} >
                <ApplicationMode />
            </Box>
            <Grid container sx={{ width: "100%", height: "100%" }} >
                { !mediaQuery &&
                    <Grid xs={6} sx={{ display: "flex", justifyContent: "center", alignItems:"center" }} >
                        <Box maxWidth={"60%"} >
                            <Image src={loginLg} alt="login left image" floatingAnimation />
                        </Box>
                    </Grid>
                }
                <Grid xs={12} sm={12} md={6} lg={6} sx={{ display: "flex", justifyContent: "center", alignItems:"center" }} >
                    <Container>
                        <Card sx={{ width: "100%", padding: 2 }} >
                            { authenticationForm === "login" && <LoginForm /> }
                            { authenticationForm === "registration" && <RegistrationForm /> }
                        </Card>
                    </Container>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Authentication