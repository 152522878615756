import Fade from "@component/Fade"
import Grid from "@component/Grid"
import CashTransfer from "@feature/Business/components/CashTransfer"
import PayFixedExpense from "@feature/FixedCosts/components/PayFixedExpense"
import { useAppSignalSelector } from "@hook/state"
import CashRegisters from "./components/CashRegisters"
import FiltersMinorCash from "./components/FiltersMinorCash"
import MinorCashMoveList from "./components/MinorCashMoveList"
import { useGetMinorCash } from "./hooks/useMinorCash"
import CreateExpense from "@feature/Expenses/components/CreateExpense"
import Title from "@component/Title"

const MinorCash = () => {
    const { MinorCashState } = useAppSignalSelector()
    const { startDate, endDate } = MinorCashState.value.filterDateRange
    const { minorCash } = useGetMinorCash({ endDate, startDate })
    return(
        <Fade in={true} >
            <Grid container spacing={1} >
                <Grid xs={12} >
                    <Title text="Caja menor" />
                </Grid>
                <Grid xs={8} >
                    <FiltersMinorCash />
                    <MinorCashMoveList />
                </Grid>
                <Grid xs={4} >
                    <CashRegisters />
                </Grid>
                <CreateExpense maxAmount={(minorCash?.minorBox?.amount??0)} box="minorBox" conceptExpense="cashExpense" />
                <PayFixedExpense maxAmount={(minorCash?.minorBox?.amount??0)} forBox="minorBox" />
                <CashTransfer from="minorBox" maxAmount={(minorCash?.minorBox?.amount??0)} omitValue="minorBox" />
            </Grid>
        </Fade>
    )
}

export default MinorCash