import { useParams } from "react-router-dom"
import { useUpdateEmployeeRoleMutation } from "../../../api/querys/Employee"
import RolInput from "../../Permission/components/RolInput"

interface Props {
    value: string
}

const ChangeRole = ({ value }:Props) => {
    const { employeeId } = useParams()
    const [ updateEmployeeRoleMutation, { isLoading } ] = useUpdateEmployeeRoleMutation()
    return(
        <>
            <RolInput loading={isLoading} value={value} onChange={(_, params:any) => updateEmployeeRoleMutation({ employeeId: employeeId!, roleId: params._id })} />
        </>
    )
}

export default ChangeRole