import { AppBar as AppBarMUI, AppBarProps } from '@mui/material';

type AppBarType = Pick<AppBarProps, "sx" | "children" | "elevation" | "color">

interface Props extends AppBarType {}

const AppBar = ({children, ...rest}:Props) => {
    return(
        <AppBarMUI {...rest} >
            {children}
        </AppBarMUI>
    )
}

export default AppBar