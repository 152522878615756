import Autocomplete from "@component/Autocomplete"
import { GetBusinessTagsResponse, TagsProps } from "@interface/Tags";
import { createFilterOptions } from "@mui/base";
import { useGetBusinessTagsQuery } from "@query/Tags";
import { genUUID } from "@service/parseData"
import React from "react"

type AutocompleteProps = Omit<React.ComponentProps<typeof Autocomplete>, "options" | "renderInput" | "value" >
const filter = createFilterOptions<any>();

interface Props extends AutocompleteProps {
    module: string
    onTagChange?: (params:TagsProps[]) => void
    value?: TagsProps[]
}

const TagInput = ({ module, value=[], onTagChange }:Props) => {
    const { data, isLoading } = useGetBusinessTagsQuery({ module })
    const [inputSelection, setInputSelection] = React.useState< Partial<TagsProps[]> >([])
    const tags = data?.payload??[] as GetBusinessTagsResponse['payload']
    const handleChange:AutocompleteProps['onChange'] = (_, params) => {
        let data = params as any[]
        const lastResult = data[data.length -1]
        if( Boolean(lastResult?.inputValue) || !Boolean(lastResult?._id) ){
            data[data.length -1] = {
                _id: genUUID(),
                name: lastResult?.inputValue??lastResult,
                module
            }
        }
        setInputSelection(data)
        if( onTagChange !== undefined ) onTagChange(data)
    }

    React.useEffect(() => {
        if( value !== undefined ){
            if( value.length >= 1 ){
                setInputSelection(value)
            }
        }
    }, [value])
    return(
        <>
            <Autocomplete
                loading={isLoading}
                filterOptions={(options:any, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some((option:any) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            _id: genUUID(),
                            inputValue,
                            name: `Añadir "${inputValue}"`,
                        });
                    }
                    return filtered;
                }}
                ChipProps={{
                    size: 'small',
                    color: "primary",
                    variant: 'outlined'
                }}
                freeSolo
                onChange={handleChange}
                value={inputSelection}
                multiple
                label="Etiquetas"
                options={tags}
            />
        </>
    )
}

export default TagInput