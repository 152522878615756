import Fade from "@component/Fade"
import Grid from "@component/Grid"
import ClosingOfTheDayDetails from "./components/ClosingOfTheDayDetails"
import DiscriminationCloseOfTheDay from "./components/DiscriminationCloseOfTheDay"
import FilterClosingOfTheDay from "./components/FilterClosingOfTheDay"
import Title from "@component/Title"

const ClosingOfTheDay = () => {

    return (
        <Fade in={true} >
            <Grid container spacing={2} >
                <Grid xs={12} sx={{
                        display: { lg:"block", md:"block", sm:"flex", xs:"flex" },
                        width:{ lg:"100%", md:"100%", sm:"100%", xs:"100%" },
                        justifyContent:{ lg:"space-between", md:"space-between", sm:"space-between", xs:"space-between" }
                }}>
                </Grid>
                <Grid xs={12} >
                    <Title text="Cierre del dia" />
                </Grid>
                <Grid xs={12} >
                    <FilterClosingOfTheDay />
                </Grid>
                <Grid xs={12} sm={12} md={12} sx={{ display: { lg: "none", md: "block", sm: "block", xs: "block" } }} >
                    <ClosingOfTheDayDetails />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={8} >
                    <DiscriminationCloseOfTheDay />
                </Grid>
                <Grid xs={4} sx={{ display: { lg: "block", md: "none", sm: "none", xs: "none" } }} >
                    <ClosingOfTheDayDetails />
                </Grid>
            </Grid>
        </Fade>
    )
}

export default ClosingOfTheDay