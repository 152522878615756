import React from 'react';
import { useSnackbar } from 'notistack';
import { useRecordSaleMutation, useUpdateSaleMutation } from '../../../api/querys/PointOfSale';
import { useAppDispatch, useAppSelector, useAppSignalSelector } from '../../../hooks/state';
import { RecordInvoice } from '../../../interfaces/PointOfSale';
import { useTranslation } from 'react-i18next';
import { clearBillingArea, loadLastInvoice, uploadInvoicePreview } from '../services/PointOfSaleSlice';
import { newInvoiceWithoutDefinedPayment } from '../../../services/socket';
import { getInvoiceValues, showPaymentMethodForm } from '../../Invoice/service/InvoiceSlice';
import { useBusinessPermissions } from '../../../hooks/usePermissions';
import { api } from '../../../api/splitApi';
import { NoteCreditFormProps } from '@interface/Invoice';
import { useRegisterCreditNoteMutation } from '@query/Invoice';
import { deleteCookie } from '@service/parseData';
import Swal from 'sweetalert2';

export const usePointOfSale = () => {
    const { PointOfSale } = useBusinessPermissions()
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();
    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }));
    const { customer, productList, total, totalProfitCurrency, totalProfitPercentage, invoiceIdentifiers, registerCreditNote } = PointOfSaleState;
    const [recordSaleMutation, { isError: isErrorRecordSale, isLoading: isLoadingRecordSale },] = useRecordSaleMutation();
    const [updateSaleMutation, { isError: isErrorUpdateSale, isLoading: isLoadingUpdateSale },] = useUpdateSaleMutation();
    const [ registerCreditNoteMutation, { isLoading:isLoadingRegisterCreditNoteMutation } ] = useRegisterCreditNoteMutation()

    const { PointOfSaleSignalState } = useAppSignalSelector()
    const printLastInvoice = () => {
        const element = document.getElementById('printLastInvoice');
        if (element !== null) {
            element.click();
        }

    };

    const registerNoteCredit = async (invoiceData:RecordInvoice, print?:boolean) => {
        try {
            const { note } = PointOfSaleSignalState.value
            if( note.trim() === "" && !Boolean(note) ){
                enqueueSnackbar('La nota es un campo obligatoria', { variant: 'warning' });
                return
            }
            const payload:NoteCreditFormProps = { invoiceId: invoiceIdentifiers._id, note, ...invoiceData, variant: "edited" }
            const resp = await registerCreditNoteMutation(payload).unwrap()
            if( resp.status === 1 ){
                enqueueSnackbar(t('La nota crédito se registro con satisfactoriamente')!, { variant: 'success', });
                dispatch(clearBillingArea());
                deleteCookie('productList')
                deleteCookie('customer')
                deleteCookie('invoiceIdentifiers')
            }else{
                enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest')!, { variant: 'error', });
            }
        } catch (error) {
            console.log("error")
        }
    }

    const recordSale = async (print?: boolean, invoiceType?: "sale" | "price") => {
        try {
            const customerId = customer !== null ? customer._id : null;
            const invoice: RecordInvoice = { customer: customerId!, products: productList, total, totalProfitCurrency, totalProfitPercentage: Number(totalProfitPercentage), invoiceType: invoiceType??"sale" };
            if( registerCreditNote ){
                registerNoteCredit(invoice, print)
            }else{
                const productListItems = [...productList];
                if (productListItems.length <= 0) {
                    enqueueSnackbar(t('selectAProductToRecordTheInvoice'), { variant: 'warning' });
                    return;
                }
                if( isLoadingRecordSale && isLoadingUpdateSale ){
                    Swal.fire({
                        icon: "warning",
                        text: t('unstableConnection')!
                    })
                    return
                }
                const resp = Boolean(invoiceIdentifiers._id) ? await updateSaleMutation({ ...invoice, invoiceId: invoiceIdentifiers._id, }).unwrap() : await recordSaleMutation(invoice).unwrap();
                if (resp.status === 1) {
                    dispatch(loadLastInvoice(resp.payload));
                    if (print === true) {
                        setTimeout(() => printLastInvoice(), 500);
                        dispatch(uploadInvoicePreview(resp.payload));
                    }
                    dispatch(clearBillingArea());
                    deleteCookie('productList')
                    deleteCookie('customer')
                    deleteCookie('invoiceIdentifiers')
                    if (resp.payload.invoiceType !== "price") {
                        enqueueSnackbar(t('theSaleWasRegisteredSuccessfully'), { variant: 'success', });
                        newInvoiceWithoutDefinedPayment(resp.payload._id)
                        if (PointOfSale.select_invoice_payment_method && !Boolean(invoiceIdentifiers._id)) {
                            const invoiceProps = { ...resp.payload, seller: resp?.payload?.seller?.name ?? "", customer: resp?.payload?.customer?.name ?? "" }
                            dispatch(getInvoiceValues(invoiceProps));
                            dispatch(showPaymentMethodForm(true));
                        }
                    } else {
                        enqueueSnackbar(t('La cotizacion se registro con exito'), { variant: 'success', });
                        dispatch(api.util.invalidateTags(['Invoice']));
                        dispatch(uploadInvoicePreview(resp.payload));
                    }
    
                } else {
                    enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest')!, { variant: 'error', });
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const timeoutError = React.useCallback(() => {
        if (isErrorRecordSale) {
            enqueueSnackbar(
                t('itSeemsThatYouHaveProblemsWithYourConnectionTheWaitingTimeHasExpired'), { variant: 'error' });
        }
    }, [isErrorRecordSale, enqueueSnackbar, t]);

    React.useEffect(() => {
        timeoutError();
    }, [timeoutError]);

    return {
        recordSale,
        isLoadingRecordSale,
        isErrorUpdateSale,
        isLoadingUpdateSale,
        isLoadingRegisterCreditNoteMutation
    };
};
