import Divider from "@component/Divider"
import Grid from "@component/Grid"
import Typography from "@component/Typography"
import SwitchInventoryByLot from "./SwitchInventoryByLot"

const BusinessSettings = () => {
    return(
        <>
            <Grid container spacing={3} >
                <Grid xs={8} >
                    <Typography fontWeight={'500'} textTransform={'uppercase'}>
                        Inventario por lotes
                    </Typography>
                    <Divider />
                    <Typography fontSize={'.9rem'} color={'GrayText'} >
                        El inventario se maneja por lotes de compras. (Cada compra es un nuevo lote) y se descuenta las unidades del lote mas antiguo con unidades disponibles.
                    </Typography>
                </Grid>
                <Grid xs={4} sx={{ display: 'flex', alignItems: 'center' }} >
                    <SwitchInventoryByLot />
                </Grid>
            </Grid>
        </>
    )
}

export default BusinessSettings