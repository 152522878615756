import AddRounded from '@mui/icons-material/AddRounded';

import { useTranslation } from 'react-i18next';

import Box from '../../../components/Box';
import Tooltip from '../../../components/Tooltip';
import IconButton from '../../../components/IconButton';
import CustomerCityInput from '../../Customer/components/CustomerCityInput';
import {
  displayCityForm,
  loadCityToEdit,
} from '../services/CitySlice';
import { useAppDispatch } from '../../../hooks/state';
import { useGetCustomersCitiesQuery } from '../../../api/querys/Customer';

const CitySearchBar: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data: CityData } = useGetCustomersCitiesQuery();

  const CityArray =
    CityData?.payload??[]
  
  const handleOnChange = (CityId: string) => {
    const findSameCity = CityArray?.find((props) => props?._id === CityId )!
    dispatch(loadCityToEdit(findSameCity));
  };

  const handleOpenForm = () => {
    dispatch(displayCityForm(true));
  };

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
    >
      <CustomerCityInput
        CityId=''
        onChange={(_,result: any) => handleOnChange(result?._id ?? '')}
      />
      <Box ml={1}>
        <Tooltip title={t('createNewCity')}>
          <IconButton
            squareButton
            onClick={handleOpenForm}
          >
            <AddRounded />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default CitySearchBar;
