import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { CustomerNeighborhoodsProps } from '../../../interfaces/Customer';

interface InitialStateProps {
  isOpenForm: boolean;
  NeighborhoodsToEdit: CustomerNeighborhoodsProps;
  selectionToDelete: string[];
  isOpenAlertDeleteNeighborhoods: boolean;
}

const initialState: InitialStateProps = {
  isOpenForm: false,
  NeighborhoodsToEdit: { name: '', _id: '' },
  selectionToDelete: [],
  isOpenAlertDeleteNeighborhoods: false,
};
export const NeighborhoodsSlice = createSlice({
  name: 'Neighborhoods',
  initialState,
  reducers: {
    displayNeighborhoodsForm: (state, action: PayloadAction<boolean>) => {
      state.isOpenForm = action.payload;
    },
    closeNeighborhoodsEditionForm: (state) => {
      state.NeighborhoodsToEdit = { name: '', _id: '' };
      state.isOpenForm = false;
    },
    loadNeighborhoodsToEdit: (
      state,
      action: PayloadAction<InitialStateProps['NeighborhoodsToEdit']>
    ) => {
      state.NeighborhoodsToEdit = action.payload;
      state.isOpenForm = true;
    },
    selectNeighborhoodsToDelete: (state, action: PayloadAction<string[]>) => {
      state.selectionToDelete = action.payload;
    },
    showAlertDeleteNeighborhoods: (state, action: PayloadAction<boolean>) => {
      state.isOpenAlertDeleteNeighborhoods = action.payload;
    },
  },
});

export const {
  displayNeighborhoodsForm,
  closeNeighborhoodsEditionForm,
  loadNeighborhoodsToEdit,
  selectNeighborhoodsToDelete,
  showAlertDeleteNeighborhoods,
} = NeighborhoodsSlice.actions;

export default NeighborhoodsSlice.reducer;
