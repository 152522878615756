import React from 'react'
import { currencyFormat } from "../hooks/useNumberFormat"
import Box from "./Box"
import Divider from "./Divider"
import List, { ListItem, ListItemIcon, ListItemText } from "./List"
import { ListItemTextProps } from "@mui/material"

interface Props {
    data: {
        primaryText: string | React.ReactNode
        secondaryText: string | React.ReactNode
        value?: number
        slot?: React.ReactNode
        onClick?: (params:any) => void
    }[]
    isAnExpenses?: boolean,
    primaryTypographyProps?: ListItemTextProps['primaryTypographyProps']
}

const InformativeList = ({ data, isAnExpenses = false, primaryTypographyProps }: Props) => {
    return (
        <List sx={{ padding: 0 }} >
            {data.map((item, index) => {
                return (
                    <Box key={index} >
                        <ListItem sx={{ padding: 0, ...(Boolean(item?.onClick) ? {cursor: 'pointer'} : {}) }} onClick={ () => (item.onClick !== undefined) ? item.onClick(item) : null } >
                            <ListItemText
                                primaryTypographyProps={primaryTypographyProps}
                                primary={item.primaryText}
                                secondary={item.secondaryText}
                            />
                            {item.value !== undefined &&
                                <ListItemIcon>
                                    <ListItemText primaryTypographyProps={{ ...primaryTypographyProps, color: ({ palette }) => isAnExpenses ? '#FF3232' : palette.success.main }} primary={`$ ${currencyFormat(item.value, true)}`} />
                                </ListItemIcon>
                            }
                            {item.slot !== undefined && 
                                item.slot
                            }
                        </ListItem>
                        <Divider />
                    </Box>
                )
            })}
        </List>
    )
}

export default InformativeList