import { useTranslation } from "react-i18next"
import Fade from "@component/Fade"
import Grid from "@component/Grid"
import LockComponent from "@component/LockComponent"
import Tab from "@component/Tab"
import { useBusinessPermissions } from "../../hooks/usePermissions"
import EditProvider from "./components/EditProvider"
import ProviderInformation from "./components/ProviderInformation"
import ListOfAdvancesToSupplier from "./components/ListOfAdvancesToSupplier"

const ProviderData = () => {
    const { Provider } = useBusinessPermissions()
    const { t } = useTranslation()
    return(
        <Fade in={true} >
            {Provider.lock_module ?
                <Grid container >
                    <Grid xs={8} >
                        <Tab
                            TabItem={[t('information'), t('editInformation'), 'Anticipos']}
                            TabRender={[<ProviderInformation />, <EditProvider />, <ListOfAdvancesToSupplier />]}
                        />
                    </Grid>
                </Grid>
                :
                <LockComponent />
            }
        </Fade>
    )
}

export default ProviderData