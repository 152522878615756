import { GridRowSelectionModel } from '@component/DataGrid';
import { api,mutationConfig } from "../splitApi"
import { BanksResponse, SingleBankResponse,  IBankProps, BankBoxResponse, BankBoxReportResponse, TranslateCashForm, BankBoxRegisterResponse, ConsignmentHistoryAsyncResponse } from '@interface/Bank';
import { DateRangeFilter } from '@interface/App';

const Bank = api.injectEndpoints({
    endpoints: (build) => ({
        getBankBox: build.query<BankBoxResponse, DateRangeFilter & { bank: string } >({
            query: ({ startDate, endDate, bank }) => ({
                url: `/get/bank/box`,
                params: {startDate, endDate, bank}
            }),
            providesTags: ['BankBox']
        }),
        getBankBoxReport: build.query<BankBoxReportResponse, DateRangeFilter & { bank: string } >({
            query: ({ startDate, endDate, bank }) => ({
                url: `/get/bank/cash/report`,
                params: {startDate, endDate, bank}
            }),
            providesTags: ['BankBox']
        }),
        recordBankBoxTransfer: build.mutation<BankBoxRegisterResponse, TranslateCashForm>({
            query(body) {
                return {...mutationConfig, url: '/record/bank/box/transfer', body}
            },
        }),
        getBankByBusiness: build.query<BanksResponse, void>({
            query: () => ({ url: '/get/banks/by/business'}),
            providesTags: ["Banks"],
        }),
        deleteBank: build.mutation<any, GridRowSelectionModel>({
            query(body) {
                return {...mutationConfig, url: '/delete/banks', body}
            },
            invalidatesTags: ["Banks"]
        }),
        registerBank: build.mutation<SingleBankResponse, IBankProps>({
            query(body) {
                return {...mutationConfig, url: '/register/new/bank', body}
            },
            invalidatesTags:["Banks"],
        }),
        updateBank: build.mutation<SingleBankResponse, IBankProps>({
            query(body) {
                return {...mutationConfig, url: '/update/bank', body}
            },
            invalidatesTags: ["Banks"],
        }),
        getBank: build.query<SingleBankResponse, {bankId: string}>({
            query: ({ bankId }) => ({ url: '/get/bank', params: {bankId} }),
            providesTags:  ["Banks"],
        }),
        getCashBank: build.query<any, void>({
            query: () => ({ url: '/get/cash/from/banks' }),
            providesTags:  ["Banks"],
        }),
        getConsignmentHistory: build.query<ConsignmentHistoryAsyncResponse, DateRangeFilter & {bankId: string}>({
            query: ({ startDate, endDate, bankId }) => ({ url: '/get/consignment/history', params: { startDate, endDate, bankId } }),
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetBankBoxReportQuery,
    useGetBankByBusinessQuery,
    useGetCashBankQuery,
    useGetBankQuery,
    useDeleteBankMutation,
    useRegisterBankMutation,
    useUpdateBankMutation,
    useGetBankBoxQuery,
    useRecordBankBoxTransferMutation,
    useGetConsignmentHistoryQuery
} = Bank