import { api, mutationConfig } from "../splitApi"
import type { LoginForm, UserLoginResponse, UserProps, UserRegisterResponse } from "../../interfaces/User"

const Auth = api.injectEndpoints({
    endpoints: (build) => ({
        example: build.query({
            query: () => 'test',
        }),
        loginUser: build.mutation<UserLoginResponse, Partial<LoginForm>>({
            query(body) {
                return {...mutationConfig, url: '/login', body}
            },
        }),
        registerUser: build.mutation<UserRegisterResponse, Partial<UserProps>>({
            query(body) {
                return {...mutationConfig, url: '/register/user', body}
            },
        }),
    }),
    overrideExisting: false,
})

export const { useExampleQuery, useRegisterUserMutation, useLoginUserMutation } = Auth