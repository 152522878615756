import React from "react"
import { useParams } from "react-router-dom"
import { useGetEmployeeQuery } from "../../../../api/querys/Employee"
import NumberFormat from "../../../../components/NumberFormat"
import { EmployeeProps } from "@interface/Employee"

interface Props {
    setDataInitialState: React.Dispatch<React.SetStateAction<Partial<Pick<EmployeeProps, "baseSalary" | "cutForPayments" | "startOfTheDay" | "endOfDay">>>>
}

const BaseSalary = ({ setDataInitialState }:Props) => {
    const [baseSalary, setBaseSalary] = React.useState<number>(0)
    const { employeeId } = useParams()
    const { data } = useGetEmployeeQuery(employeeId!)
    const employee = data?.payload

    React.useEffect(() => {
        if( employee !== null && employee !== undefined ) {
            setBaseSalary(employee?.baseSalary??0)
        }
    },[employee?._id])

    React.useEffect(() => {
        setDataInitialState(n => ({...n, baseSalary: baseSalary}))
    },[baseSalary])

    return(
        <>
            <NumberFormat value={baseSalary} prefix="$" label="Salario base" onChange={({ target }) => setBaseSalary(Number(target?.value??0))} />
        </>
    )
}

export default BaseSalary