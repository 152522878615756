import Box from "@component/Box"
import Container from "@component/Container"
import Toolbar from "@component/Toolbar"
import AppBar from "./Appbar"
import Drawer from "./Drawer"
import { ContainerProps } from '@mui/material'
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { openPointOfSaleWindow } from "../../PointOfSale/services/PointOfSaleSlice"

interface Props {
    children?: ContainerProps['children']
}

const Main = ({ children }:Props) => {
    const dispatch = useAppDispatch()
    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }))
    const { openWindow } = PointOfSaleState.windowProduct

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleKeyEvent = (event:KeyboardEvent) => {
        const { keyCode, which } = event
        const lockKey = [112]
        const code = which??keyCode
        if( lockKey.includes(code) ){
            event.preventDefault()
            event.stopPropagation()
        }
        if( code === 112 ){
            if( openWindow === false ){
                dispatch(openPointOfSaleWindow(true))
            }
        }
    }

    React.useEffect(() => {
        window.addEventListener('keydown', handleKeyEvent)
        return () => {
            window.removeEventListener('keydown', handleKeyEvent)
        }
    },[handleKeyEvent, openWindow])

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar />
            <Drawer />
            <Box component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.background.paper
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar />
                <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                    {children}
                </Container>
            </Box>
        </Box>
    )
}

export default Main