import {
    List as ListMUI, ListProps as ListPropsMUI,
    ListItem as ListItemMUI, ListItemProps as ListItemPropsMUI,
    ListItemAvatar as ListItemAvatarMUI, ListItemAvatarProps as ListItemAvatarPropsMUI,
    ListItemText as ListItemTextMUI, ListItemTextProps as ListItemTextPropsMUI,
    ListItemButton as ListItemButtonMUI, ListItemButtonProps as ListItemButtonPropsMUI,
    ListItemIcon as ListItemIconMUI, ListItemIconProps,
    Collapse as CollapseMUI, CollapseProps as CollapsePropsMUI,
} from '@mui/material'

type ListProps = Pick<ListPropsMUI, "children" | "sx" | "disablePadding" >

const List = ({ children, ...rest }:ListProps) => {
    return(
        <ListMUI {...rest} style={{ padding: 0 }} >
            {children}
        </ListMUI>
    )
}

export default List

type ListItemProps = Pick<ListItemPropsMUI, "children" | "sx" | "disablePadding" | "onClick">

export const ListItem = ({children, ...rest}:ListItemProps) => {
    return(
        <ListItemMUI {...rest} style={{ padding: 0 }} >
            {children}
        </ListItemMUI>
    )
}

type ListItemAvatarProps = Pick<ListItemAvatarPropsMUI, "children" | "sx">

export const ListItemAvatar = ({ children, ...rest }:ListItemAvatarProps) => {
    return(
        <ListItemAvatarMUI {...rest} >
            {children}
        </ListItemAvatarMUI>
    )
}

type ListItemTextProps = Pick<ListItemTextPropsMUI, "children" | "primary" | "secondary" | "primaryTypographyProps" | "secondaryTypographyProps" | "sx">

export const ListItemText = ({ children, ...rest }:ListItemTextProps) => {
    return(
        <ListItemTextMUI {...rest}>
            {children}
        </ListItemTextMUI>
    )
}

type ListItemButtonProps = Pick<ListItemButtonPropsMUI, "children" | "onClick" | "onKeyDown" | "sx" | "divider" | "autoFocus">

export const ListItemButton = ({ children, ...rest }:ListItemButtonProps) => {
    return(
        <ListItemButtonMUI {...rest} >
            {children}
        </ListItemButtonMUI>
    )
}

type Props = Pick<ListItemIconProps, "children" | "sx">

export const ListItemIcon = ({ children, ...rest }:Props) => {
    return(
        <ListItemIconMUI {...rest} >
            {children}
        </ListItemIconMUI>
    )
}

type CollapseProps = Pick<CollapsePropsMUI, "children" | "sx" | "in" | "timeout" | "unmountOnExit" >

export const Collapse = ({ children, ...rest }:CollapseProps) => {
    
    return(
        <CollapseMUI {...rest} >
            <List disablePadding>
                {children}
            </List>
        </CollapseMUI>
    )
}