import React from 'react';
import { useGetRoleByBusinessQuery } from "../../../api/querys/Permission"
import Autocomplete from "@component/Autocomplete"
import { RoleProps } from "@interface/Role"

type AutocompleteProps = Pick<React.ComponentProps<typeof Autocomplete>, "onChange" | "loading">
interface Props extends AutocompleteProps {
    value?: string
}

const RolInput = ({ value, ...rest }:Props) => {
    const { data } = useGetRoleByBusinessQuery()
    const roles = data?.payload??[] as Array<RoleProps>
    const rolSelected = roles.find((b) => b._id === value) || null
    return(
        <>
            <Autocomplete
                value={rolSelected}
                {...rest}
                label="Rol"
                options={roles}
            />
        </>
    )
}

export default RolInput