import React from 'react'
import Fade from "@component/Fade"
import Grid from "@component/Grid"
import CustomersList from "./components/CustomersList"
import Box from "@component/Box"
import SearchCustomer from "./components/SearchCustomer"
import DiscriminationCustomers from "./components/DiscriminationCustomers"
import CircularProgress from "@component/CircularProgess"
import { useGetBusinessCustomersQuery } from "../../api/querys/Customer"
import { useSnackbar } from 'notistack'
import CreateCustomer from './components/CreateCustomer'
import AlertDeleteCustomer from './components/AlertDeleteCustomer'
import { useBusinessPermissions } from '../../hooks/usePermissions'
import LockComponent from '../../components/LockComponent'
import Title from '@component/Title'

const Customers = () => {
    const { Customers } = useBusinessPermissions()
    const { isSuccess, isLoading, isError } = useGetBusinessCustomersQuery()
    const { enqueueSnackbar } = useSnackbar()
    const showComponent = !isLoading && isSuccess
    React.useEffect(() => {
        if( isError ) enqueueSnackbar('Ocurrió un error al intentar obtener la información', { variant: 'error' })
    },[isError, enqueueSnackbar])
    if( showComponent ){
        return(
            <Fade in={true} >
                {Customers.lock_module ?
                    <Box pt={1}  >
                        <Box mb={1} >
                            <Title text='Clientes' />
                        </Box>
                        <SearchCustomer />
                        <Grid container spacing={1} >
                            <Grid xs={9} >
                                {Customers.view_information &&
                                    <CustomersList />
                                }
                            </Grid>
                            <Grid xs={3} >
                                {Customers.view_information &&
                                    <DiscriminationCustomers />
                                }
                            </Grid>
                        </Grid>
                        {Customers.create_information &&
                            <CreateCustomer />
                        }
                        {Customers.delete_information &&
                            <AlertDeleteCustomer />
                        }
                    </Box>
                    :
                    <LockComponent />
                }
            </Fade>
        )
    }else{
        return(
            <Fade in={true} >
                <CircularProgress />
            </Fade>
        )
    }
}

export default Customers