import React from 'react';
import Fade from '../../components/Fade';
import Grid from '../../components/Grid';
import TemplateInvoice from './components/TemplateInvoice';
import { useGetInvoiceQuery } from '../../api/querys/PointOfSale';
import { useParams } from 'react-router-dom';
import DetailsInvoice from './components/DetailsInvoice';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import InvoiceOptions from './components/InvoiceOptions';
import SelectPaymentMethod from './components/SelectPaymentMethod';
import { useBusinessPermissions } from '../../hooks/usePermissions';
import TemplateLargeInvoice from './components/TemplateLargeInvoice';
import { useAppDispatch, useAppSelector } from '../../hooks/state';
import { activateCreditNoteRegistration, loadSalesList, openPointOfSaleWindow, selectCustomerForInvoice, uploadInvoiceIdentifiers, uploadInvoicePreview } from '../PointOfSale/services/PointOfSaleSlice';
import { InvoiceProps } from '../../interfaces/PointOfSale';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

export const typeOfPayment: any = {
    cash: 'cash',
    credit: 'credit',
    delivery: 'delivery',
    consignment: 'consignment',
    customerAdvance: 'customerAdvance',
};

const Invoice = () => {
    const dispatch = useAppDispatch()
    const { AppState } = useAppSelector(({ AppState }) => ({ AppState }))
    const { invoiceMode } = AppState
    const { Invoice: InvoicePermission } = useBusinessPermissions()
    const invoiceElement = React.useRef<HTMLDivElement | null>(null);
    const { t } = useTranslation();
    const { invoiceId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { data } = useGetInvoiceQuery({ invoiceId: invoiceId! });
    const statusData = data?.status;
    const invoice = data?.payload ?? {} as InvoiceProps

    React.useEffect(() => {
        if (statusData === 0) {
            enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'), {
                variant: 'error',
            });
        }
    }, [statusData, enqueueSnackbar, t]);

    const handleEditSelected = () => {
        dispatch(loadSalesList(invoice.products))
        dispatch(uploadInvoiceIdentifiers({ _id: invoice._id, consecutive: invoice.consecutive }))

        if (Boolean(invoice?.customer?.name)) {
            dispatch(selectCustomerForInvoice(invoice.customer))
        } else {
            dispatch(selectCustomerForInvoice(null))
        }
        dispatch(uploadInvoicePreview({}))
        dispatch(openPointOfSaleWindow(true))
    }
    
    const handleCreateCreditNote = () => {
        handleEditSelected()
        dispatch(activateCreditNoteRegistration(true))
    }

    const theme = useTheme()
    const smQuery = useMediaQuery(theme.breakpoints.down("sm"))

    return (
        <Fade in={true}>
            <Grid container spacing={2} >
                {smQuery &&
                    <Grid xs={12} sm={12} md={4} lg={4} >
                        {InvoicePermission.view_information &&
                            <DetailsInvoice {...invoice} />
                        }
                    </Grid>
                }
                <Grid xs={12} sm={12} md={8} lg={8} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', }} >
                    <InvoiceOptions
                        onCreditNote={handleCreateCreditNote}
                        onEdit={handleEditSelected}
                        invoice={invoice}
                        invoiceElement={invoiceElement}
                    />
                    {invoiceMode === "pos" ?
                        <TemplateInvoice
                            ref={invoiceElement}
                            {...invoice}
                        />
                        :
                        <TemplateLargeInvoice
                            ref={invoiceElement}
                            {...invoice}
                        />
                    }
                </Grid>

                {!smQuery &&
                    <Grid xs={12} sm={12} md={4} lg={4} >
                        {InvoicePermission.view_information &&
                            <DetailsInvoice {...invoice} />
                        }
                    </Grid>
                }

                {InvoicePermission.change_payment_method &&
                    <SelectPaymentMethod invoiceId={invoiceId} />
                }
            </Grid>
        </Fade>
    );
};

export default Invoice;
