import { Link as LinkRRD, LinkProps } from 'react-router-dom'
import { styled } from '@mui/material/styles'

const CustomLink = styled(LinkRRD)(({ theme }) => ({
    textDecoration: "none",
    color: theme.palette.primary.dark,
    "&:active":{
        color: theme.palette.primary.main,
        textDecoration: "underline"
    }
}))

const Link = ({to="/", children, ...rest}:LinkProps) => {
    return(
        <CustomLink to={to} {...rest} >
            {children}
        </CustomLink>
    )
}

export default Link