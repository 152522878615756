import React from 'react'
import { createContext } from "react";
import { PurchaseReportContext } from '@purchase_report_hook/PurchaseReportContext'
import { FixedCostContext } from '@fixed_cost_hook/FixedCostContext'

export const AppContext = createContext<null | any>(null);

interface Props {
    children: React.ReactNode
}

const state = {
    PurchaseReportContext,
    FixedCostContext
}

export type ContextProviderStateProps = typeof state

const ContextProvider = ({children}:Props) => {
    const [globalState, setGlobalState] = React.useState<ContextProviderStateProps>({...state})
    return(
        <AppContext.Provider value={{...globalState, setGlobalState}}>
            {children}
        </AppContext.Provider>
    )
}

export default ContextProvider