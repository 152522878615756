import React from 'react'
import DarkModeRounded from "@mui/icons-material/DarkModeRounded"
import { useAppDispatch, useAppSelector } from "../hooks/state"
import IconButton from "./IconButton"
import Tooltip from "./Tooltip"
import Brightness7Rounded from '@mui/icons-material/Brightness7Rounded';
import { toggleApplicationMode } from "../services/AppSlice";
const ApplicationMode = () => {
    const { AppState } = useAppSelector(({ AppState }) => ({AppState}))
    const dispatch = useAppDispatch()
    const { mode } = AppState
    const text = mode === "light" ? "Modo oscuro" : "Modo claro"
    const icon = mode === "light" ? <DarkModeRounded /> : <Brightness7Rounded />
    const newMode = mode === "light" ? "dark" : "light"
    React.useEffect(() => {
        const localStorageModeApp = localStorage.getItem("mode")
        if( Boolean(localStorageModeApp) ){
            if( localStorageModeApp === "light" ) dispatch(toggleApplicationMode("light"))
            if( localStorageModeApp === "dark" ) dispatch(toggleApplicationMode("dark"))
        }
    },[dispatch])
    return(
        <Tooltip title={text} placement="left" >
            <IconButton color="default" onClick={() => dispatch(toggleApplicationMode(newMode))} >
                {icon}
            </IconButton>
        </Tooltip>
    )
}

export default ApplicationMode