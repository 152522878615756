import { useTranslation } from "react-i18next"
import Box from "@component/Box"
import Typography from "@component/Typography"

const ExcelProductsIndex = () => {
    const { t } = useTranslation()
    return(
        <Box component={'div'} color={'red'} fontWeight={'bold'} >
            {t('note')}:
            <Typography color={'GrayText'} fontWeight={'500'} >* {t('theHeaderWithTheTitlesOfTheColumnsInTheTemplateMustNotBeModifiedAlteredToAvoidErrorsWhenLoadingTheProducts')}.  </Typography>
        </Box>
    )
}

export default ExcelProductsIndex