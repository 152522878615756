// @flow
import Box from "@component/Box"
import Card from "@component/Card"
import IconButton from "@component/IconButton"
import Stack from "@component/Stack"
import Typography from "@component/Typography"
import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

const DateFilter = ({ date="", onAfter=undefined, onPrevious=undefined }) => {
    const { t } = useTranslation()
    const month = dayjs(date).month()
    const day = dayjs(date).day()
    const dayDate = dayjs(date).format("DD")
    const year = dayjs(date).year()
    const dateText = `${t(`day_${day}`)} ${dayDate} de ${t(`month_${month}`)} del ${year}`
    const disabledAfterDay = dayjs(date).format("DD-MM-YYYY") === dayjs().format("DD-MM-YYYY")
    return(
        <>
        <Card elevation={0} sx={{ padding: '5px' }} >
            <Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'} >
                <Box>
                    <IconButton onClick={onPrevious} >
                        <ChevronLeftRounded />
                    </IconButton>
                </Box>
                <Box >
                    <Typography fontWeight={'500'} fontSize={'.750rem'} textAlign={'center'} lineHeight={'0.8'} >
                        Estas viendo el resumen del
                    </Typography>
                    <Typography fontWeight={'bold'} textTransform={'uppercase'} fontSize={'.890rem'} >
                        {dateText}
                    </Typography>
                </Box>
                <Box>
                    <IconButton onClick={onAfter} disabled={disabledAfterDay} >
                        <ChevronRightRounded />
                    </IconButton>
                </Box>
            </Stack>

        </Card>
        </>
    )
}

export default DateFilter