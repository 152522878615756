import AttachMoneyRounded from "@mui/icons-material/AttachMoneyRounded"
import InventoryRounded from "@mui/icons-material/InventoryRounded"
import TurnedInRounded from "@mui/icons-material/TurnedInRounded"
import { useParams } from "react-router-dom"
import { useGetCategoriesProductQuery, useGetProductInventoryQuery } from "../../../api/querys/Product"
import Box from "@component/Box"
import CardInformative from "@component/CardInformative"
import { useNumberFormat } from "../../../hooks/useNumberFormat"
import BeginningInventoryTemplate from "./BeginningInventoryTemplate"
import Checkbox from "@component/Checkbox"
import { useURLParams } from "@hook/useURLParams"

const DiscriminateInventory = () => {
    const { handleActionParameter, getParameterGroup, delQueries } = useURLParams()
    const { group="" } = getParameterGroup(['group'])
    const { categoryId } = useParams()
    const { data } = useGetProductInventoryQuery(categoryId)
    const { data:dataCategoriesProduct } = useGetCategoriesProductQuery()
    const { useCurrency } = useNumberFormat()
    const costOfAllStock = data?.payload?.productsInventory?.costOfAllStock??0
    const count = data?.payload?.productsInventory?.count??0
    const totalCategories = dataCategoriesProduct?.payload?.productCategories?.count??0
    return(
        <Box>
            <Box sx={{ overflow: "auto", whiteSpace: 'nowrap', paddingBottom: 1 }}>
                <CardInformative
                    title={"Productos inventariados"}
                    moreText={useCurrency(count)}
                    icon={<InventoryRounded />}
                />
                <CardInformative
                    title={"Costo del inventario actual"}
                    moreText={`$ ${useCurrency(costOfAllStock)}`}
                    icon={<AttachMoneyRounded />}
                />
                <CardInformative
                    title={"Categorías registradas"}
                    moreText={`${useCurrency(totalCategories)}`}
                    icon={<TurnedInRounded />}
                />
                <BeginningInventoryTemplate />
            </Box>
            <Checkbox
                checked={(group === "categories")}
                label="Agrupar por categorías"
                onChange={() => (group === "categories") ? delQueries(["group"]) : handleActionParameter("group", "categories")}
            />
        </Box>
    )
}

export default DiscriminateInventory