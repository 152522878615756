import { useAppContextSelector } from "@hook/state"
import { useURLParams } from "@hook/useURLParams"
import { EditPurchaseFieldValueParamsProps, GetPurchaseDataProps, PurchasingReportPropsResponse } from "@interface/Purchasing"
import { RecordPaymentPurchaseProps } from "@interface/ReportPurchase"
import { useEditPurchaseFieldValueMutation, useGenerateShoppingDiscountMutation, useGetPurchaseQuery, useGetPurchasesFromSupplierBusinessQuery, useSendPaymentToPurchaseMutation } from "@query/PurchasingSuppliers"
import { isErrorResponse, isSuccessResponse, blockService } from "@service/AnswersServices"

export const usePurchaseReport = () => {
    const { setGlobalState } = useAppContextSelector()
    const { delQueries } = useURLParams()
    const [ sendPaymentToPurchaseMutation, { isLoading:isLoadingSendPaymentToPurchaseMutation } ] = useSendPaymentToPurchaseMutation()
    const [ editPurchaseFieldValueMutation, { isLoading:isLoadingEditPurchaseFieldValueMutation } ] = useEditPurchaseFieldValueMutation()
    const [ generateShoppingDiscountMutation, { isLoading:isLoadingGenerateShoppingDiscountMutation } ] = useGenerateShoppingDiscountMutation()

    const sendPaymentToPurchase = async (payload:RecordPaymentPurchaseProps[]) => {
        try {
            if( isLoadingSendPaymentToPurchaseMutation ){
                blockService()
                return
            }
            const resp = await sendPaymentToPurchaseMutation(payload).unwrap()
            
            if( resp.status === 1 ){
                isSuccessResponse( payload.length === 1 ? "El pago se realizo satisfactoriamente" : "Los pagos se realizaron satisfactoriamente")
                setGlobalState((prevState) => {
                    return {
                        ...prevState,
                        PurchaseReportContext: {
                            ...prevState.PurchaseReportContext,
                            anchorElPaymentPurchases: [],
                            selectedShoppingList: []
                        }
                    }
                })
            }else{
                let errors = resp.payload as any
                if( Array.isArray(errors) ){
                    for( let error of errors ){
                        isErrorResponse(error)
                    }
                }

                if( Boolean(errors?.key) ){
                    isErrorResponse(errors?.key)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    
    const editPurchaseFieldValue = async (params:EditPurchaseFieldValueParamsProps) => {
        try {
            const resp = await editPurchaseFieldValueMutation(params).unwrap()
            if( resp.status === 1 ){
                isSuccessResponse("El campo de la compra se actualizo satisfactoriamente")
                delQueries(["field", "modal", "ref"])
            }else{
                isErrorResponse()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const generateShoppingDiscount = async (payload:string[]) => {
        try {
            const resp = await generateShoppingDiscountMutation({ purchasesId: payload }).unwrap()
            if( resp.status === 1 ){
                isSuccessResponse( payload.length === 1 ? "Se aplico el descuento satisfactoriamente." : "Se aplicaron los descuentos satisfactoriamente")
            } else {
                isErrorResponse()
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        sendPaymentToPurchase,
        isLoadingSendPaymentToPurchaseMutation,
        isLoadingEditPurchaseFieldValueMutation,
        editPurchaseFieldValue,
        isLoadingGenerateShoppingDiscountMutation,
        generateShoppingDiscount
    }
}

export const useGetPurchasesFromSupplierBusiness = () => {
    const { getParameterGroup } = useURLParams()
    let { pay="", paymentType="", endDate="", startDate="", name="", filterBy=undefined } = getParameterGroup(["paymentType", "pay", "startDate", "endDate", "name", "filterBy"])

    const { isLoading, data } = useGetPurchasesFromSupplierBusinessQuery({ startDate, endDate, filterBy })
    let resp = data?.payload??[] as PurchasingReportPropsResponse['payload']
    let list = [...resp]
    if( Boolean(name) ){
        name = name.split('-')
        list = list.filter((n) => name.some((x:string) => x === n?.provider?.name??""))
    }
    if( pay === "pending" ){
        list = list.filter((n) => n.balance >= 1)
    }
    if( Boolean(paymentType) ){
        list = list.filter((n) => n.typePayment === paymentType)
    }

    let totalBalance = list.reduce((acc, params) => (params?.balance??0)+acc,0)
    let totalTax = list.reduce((acc, params) => (params?.tax??0)+acc,0)
    let totalRete = list.reduce((acc, params) => (params?.retention??0)+acc,0)
    let totalPurchase = list.reduce((acc, params) => (params?.total??0)+acc,0)
    let totalSubTotal = list.reduce((acc, params) => (params?.subTotal??0)+acc,0)

    let groupByProviders:{ provider: string, name: string, results: (typeof list[0])[], totalBalance: number, totalTax: number, totalRete: number, totalPurchase: number, totalSubTotal: number }[] = []

    for( let item of list ){
        const findIndex = groupByProviders.findIndex((n) => n.provider === (item?.provider?._id??""))
        if( findIndex >= 0 ){
            groupByProviders[findIndex].results.push(item)
            groupByProviders[findIndex].totalBalance += item.balance
            groupByProviders[findIndex].totalPurchase += item.total
            groupByProviders[findIndex].totalRete += item.retention
            groupByProviders[findIndex].totalSubTotal += item.subTotal
            groupByProviders[findIndex].totalTax += item.tax
        }else{
            groupByProviders.push({
                provider: item?.provider?._id??"",
                name: item?.provider?.name??"",
                results: [item],
                totalBalance: item.balance,
                totalPurchase: item.total,
                totalRete: item.retention,
                totalSubTotal: item.subTotal,
                totalTax: item.tax
            })
        }
    }

    return {
        resp,
        isLoading,
        list,
        totalBalance,
        totalTax,
        totalRete,
        totalPurchase,
        totalSubTotal,
        groupByProviders
    }
}

interface usePurchaseDataParams {
    purchaseId: string
}

export const usePurchaseData = ({ purchaseId }:usePurchaseDataParams) => {
    const { data } = useGetPurchaseQuery({ purchaseId }, { skip: !Boolean(purchaseId) })
    const report = data?.payload??{} as GetPurchaseDataProps['payload']
    return { report }
}