import Dialog, { DialogContent, DialogTitle } from "@component/Dialog"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { displayFormToCreateEmployee } from "../services/EmployeeSlice"
import EmployeeForm from "./EmployeeForm"

const CreateEmployee = () => {
    const {EmployeeState} = useAppSelector(({ EmployeeState }) => ({EmployeeState}))
    const dispatch = useAppDispatch()
    const { openCreateForm } = EmployeeState
    return(
        <Dialog open={openCreateForm} onClose={() => dispatch(displayFormToCreateEmployee(false))} >
            <DialogTitle >CREAR EMPLEADO</DialogTitle>
            <DialogContent>
                <EmployeeForm />
            </DialogContent>
        </Dialog>
    )
}

export default CreateEmployee