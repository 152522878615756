import Dialog, { DialogContent } from '../../../components/Dialog';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import {
  closeCityEditionForm,
  displayCityForm,
} from '../services/CitySlice';
import CityForm from './CityForm';

const CreateCity = () => {
  const dispatch = useAppDispatch();
  const { CityState } = useAppSelector(({ CityState }) => ({
    CityState,
  }));
  const { isOpenForm, CityToEdit } = CityState;

  const handleClose = () => {
    if (CityToEdit._id === '') {
      dispatch(displayCityForm(false));
    } else {
      dispatch(closeCityEditionForm());
    }
  };

  return (
    <Dialog
      open={isOpenForm}
      onClose={handleClose}
      fullWidth
    >
      <DialogContent>
        <CityForm />
      </DialogContent>
    </Dialog>
  );
};

export default CreateCity;
