import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { useTranslation } from 'react-i18next';
import CardInformative from '../../../components/CardInformative';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import { openRoleCreateForm, openRoleEditForm } from '../services/permissionSlice';
import SecurityRounded from '@mui/icons-material/SecurityRounded';
import { useGetRoleByBusinessQuery } from '../../../api/querys/Permission';
import Box from '../../../components/Box';

const InformativeHeader = () => {
  const { data: dataRoles } = useGetRoleByBusinessQuery();
  const roles = dataRoles?.payload ?? [];
  const { PermissionState } = useAppSelector(({ PermissionState }) => ({
    PermissionState,
  }));
  const { rolSelected } = PermissionState;
  const rolePermissions = rolSelected?.permissions ?? [];
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleOpenCreateForm = () => {
    dispatch(openRoleCreateForm(true));
  };

  const handleOpenEditForm = () => {
    dispatch(openRoleEditForm(true))
  }

  return (
    <Box sx={{ overflow: 'auto', whiteSpace: 'nowrap', paddingBottom: 1 }}>
      <CardInformative
        title=''
        icon={<AddCircleOutlineRoundedIcon />}
        onClick={handleOpenCreateForm}
        moreText={t('registerNewRole')!}
        informativeText={t('registeredRoles', { rolesQuantity: roles.length })!}
      />
      <CardInformative
        title=''
        icon={<SecurityRounded />}
        onClick={handleOpenEditForm}
        moreText={t(`${rolSelected.name}`)!}
        informativeText={
          t('assignedPermissions', {
            permissionQuantity: rolePermissions.length,
          })!
        }
      />
    </Box>
  );
};

export default InformativeHeader;
