import React from 'react'
import DataGrid, { GridColDef } from "@component/DataGrid"
import { currencyFormat } from "@hook/useNumberFormat"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useExpense, useGetExpenses } from "../hooks/useExpense"
import CreateExpense from "./CreateExpense"
import IconButton from "@component/IconButton"
import Tooltip from "@component/Tooltip"
import MoveToInbox from '@mui/icons-material/MoveToInbox';
import Swal from "sweetalert2"
import { useTheme } from "@mui/material/styles"
import { useBusinessPermissions } from "@hook/usePermissions"
import List, { ListItem, ListItemText } from "@component/List"
import Title from "@component/Title"
import { useURLParams } from "@hook/useURLParams"

const ExpenseList = () => {
    const theme = useTheme()
    const { getParameterGroup } = useURLParams()
    const { startDate=dayjs().format("MM-DD-YYYY"), endDate=dayjs().format("MM-DD-YYYY") } = getParameterGroup(['startDate', 'endDate'])
    const { list:expenses } = useGetExpenses({ startDate, endDate })
    const { t } = useTranslation()
    const { archiveExpense } = useExpense()
    const { Expenses } = useBusinessPermissions()

    const handleArchiveExpense = async (expense:(typeof expenses[0])) => {
        if( Expenses.delete_expense ){
            const SwalAlert = await Swal.fire({
                icon: 'question',
                text: `¿Desea archivar el gasto por $ ${ currencyFormat(expense.amount) }?`,
                confirmButtonColor: theme.palette.warning.main,
                confirmButtonText: "Archivar",
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                cancelButtonColor: theme.palette.primary.main,
            })
    
            if( SwalAlert.isConfirmed ){
                archiveExpense({ expenseId: expense._id })
            }
        }else{
            Swal.fire({
                icon: 'warning',
                text: `No tienes permisos para ejecutar esta acción`,
            })
        }
    }

    let columns:GridColDef[] = [
        { maxWidth: 160, minWidth: 160, field: "cashier", headerName: "Registrado por", valueGetter: ({ row }) => `${row?.cashier.name??""} ${row?.cashier.lastName??""}` },
        { maxWidth: 120, minWidth: 120, field: "amount", headerName: "Monto", renderCell: ({ row }) => `$ ${currencyFormat(row?.amount??0)}` },
        { maxWidth: 120, minWidth: 120, field: "box", headerName: "Caja de salida", renderCell: ({ row }) => `${t(row?.box??"")}` },
        { maxWidth: 120, minWidth: 120, field: "concept", headerName: "Concepto", renderCell: ({ row }) => `${t(row?.concept??"")}` },
        { flex: 1, minWidth: 300, field: "note", headerName: "Nota", renderCell: ({ row }) => `${t(row?.note??"")}` },
        { maxWidth: 160, minWidth: 160,field: "createdAt", headerName: "Registro", renderCell: ({ row }) => `${ dayjs(row?.createdAt).format("DD-MM-YYYY HH:mm A") }` },
        { maxWidth: 50, minWidth: 50,field: " ", headerName: " ", align: "center",
            renderCell: ({ row }) => {
                return(
                    <>
                        <Tooltip title="Archivar gasto" >
                            <IconButton size="small" onClick={() => handleArchiveExpense(row)} >
                                <MoveToInbox />
                            </IconButton>
                        </Tooltip>
                    </>
                )
            }
        },
    ]
    return(
        <>
            { expenses.map((item:any, index) => {
                return(
                    <React.Fragment key={index} >
                        <Title text={t((item?.concept??""))} variant="subtitle" hideDivider />
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={`$ ${currencyFormat((item?.total??0))}`}
                                    secondary="Total"
                                />
                            </ListItem>
                        </List>
                        <DataGrid
                            columns={columns}
                            rows={(item?.results??[])}
                        />
                    </React.Fragment>
                )
            }) }
            <CreateExpense box="minorBox" conceptExpense="cashExpense" />
        </>
    )
}

export default ExpenseList