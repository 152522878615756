import { useGetDeliveryReportQuery } from "../../../api/querys/PointOfSale"
import Box from "@component/Box"
import CardInformative from "@component/CardInformative"
import { currencyFormat } from "../../../hooks/useNumberFormat"
import PriceCheckRounded from '@mui/icons-material/PriceCheckRounded';
import AttachMoneyRounded from '@mui/icons-material/AttachMoneyRounded';
import CurrencyExchangeRounded from '@mui/icons-material/CurrencyExchangeRounded';
import { useTranslation } from "react-i18next";
import DeliveryDiningRounded from '@mui/icons-material/DeliveryDiningRounded';
import { useAppSelector } from "../../../hooks/state";

interface Props {
    positionTab: number
}

const InformativeDelivery = ({ positionTab }:Props) => {
    const { DeliveriesState } = useAppSelector(({ DeliveriesState }) => ({ DeliveriesState }))
    const { initDate, endDate } = DeliveriesState.dateRange
    const { t } = useTranslation()
    const useData = positionTab === 0 ? "deliveries" : (positionTab === 1 ? "paidDeliveries" : "pendingDeliveries")
    const { data } = useGetDeliveryReportQuery({ endDate, startDate: initDate })
    const totalCredits = data?.payload[useData]?.totalCredits??0
    const totalBalance = data?.payload[useData]?.totalBalance??0
    const totalPayments = data?.payload[useData]?.totalPayments??0
    const totalRegisters = data?.payload[useData]?.totalRegisters??0
    const deliveryAmount = data?.payload[useData]?.deliveryAmount??0
    return(
        <Box sx={{ overflow: "auto", whiteSpace: 'nowrap', paddingBottom: 1 }} >
            <CardInformative icon={<DeliveryDiningRounded />} moreText={`$ ${currencyFormat(totalCredits)}`} title="" informativeText={t('householdsTotal')!} />
            <CardInformative icon={<PriceCheckRounded />} moreText={`$ ${currencyFormat(totalPayments)}`} title="" informativeText={t('balancePaid')!} />
            <CardInformative icon={<AttachMoneyRounded />} moreText={`$ ${currencyFormat(totalBalance)}`} title="" informativeText={t('outstandingBalance')!} />
            <CardInformative icon={<AttachMoneyRounded />} moreText={`$ ${currencyFormat(deliveryAmount)}`} title="" informativeText={t('Pago domicilio')!} />
            <CardInformative icon={<CurrencyExchangeRounded />} moreText={`${currencyFormat(totalRegisters)}`} title="" informativeText={t('pendingAddresses')!} />
        </Box>
    )
}

export default InformativeDelivery