import React from 'react'
import Box from "@component/Box"
import IconButton from "@component/IconButton"
import Tooltip from "@component/Tooltip"
import TemplateInvoice from "@feature/Invoice/components/TemplateInvoice"
import { useAppSelector } from "@hook/state"
import { PrintRounded } from "@mui/icons-material"
import { useGetInvoicesToPrintQuery } from "@query/PointOfSale"
import ReactToPrint from "react-to-print"

const PrintAllInvoices = () => {
    const { ReportOfTheDayState } = useAppSelector(({ ReportOfTheDayState }) => ({
        ReportOfTheDayState,
    }));
    const { initDate, endDate } = ReportOfTheDayState;
    const { data, isFetching } = useGetInvoicesToPrintQuery({ startDate: initDate, endDate })
    const list = (data?.payload ?? [])
    const currentRef = React.useRef()

    return (
        <>
            <Box>
                <ReactToPrint
                    trigger={() => {
                        return (
                            <Tooltip title={'Imprimir facturas'}>
                                <IconButton disabled={isFetching} squareButton onClick={() => null} >
                                    <PrintRounded />
                                </IconButton>
                            </Tooltip>
                        )
                    }}
                    content={() => currentRef.current}
                    pageStyle={`
                        @media print {
                            .templateInvoice {
                                page-break-before: always;
                            }
                        }
                    `}
                />
                <Box ref={currentRef} >
                    {list.map((item, index) => {
                        return (
                            <TemplateInvoice
                                key={index}
                                sx={{ displayPrint: 'block', display: 'none' }}
                                {...item}
                            />
                        )
                    })}
                </Box>
            </Box>
        </>
    )
}

export default PrintAllInvoices