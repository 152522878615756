import React from "react"
import { useTranslation } from "react-i18next"
import Fade from "@component/Fade"
import Grid from "@component/Grid"
import Tab from "@component/Tab"
import { useBusinessPermissions } from "../../hooks/usePermissions"
import BusinessData from "./components/BusinessData"
import EditBusiness from "./components/EditBusiness"
import LockComponent from "@component/LockComponent"
import BusinessSettings from "./components/BusinessSettings"

const Business = () => {
    const { t } = useTranslation()
    const { MyBusiness } = useBusinessPermissions()
    const tabs = React.useMemo(() => {
        const tabItem:Array<string> = []
        const tabRender:Array<React.ReactNode> = []
        if( MyBusiness.view_information ){
            tabItem.push(t('information'))
            tabRender.push(<BusinessData />)
        }
        if( MyBusiness.edit_information ){
            tabItem.push(t('editInformation'))
            tabRender.push(<EditBusiness />)
        }
        tabItem.push(t('Ajustes'))
        tabRender.push(<BusinessSettings />)
        return { tabItem, tabRender }
    },[])
    return(
        <Fade in={true} >
            {MyBusiness.lock_module ?
                <Grid container >
                    <Grid xs={8} >
                        <Tab
                            TabItem={tabs.tabItem}
                            TabRender={tabs.tabRender}
                        />
                    </Grid>
                </Grid>
                :
                <LockComponent />
            }
        </Fade>
    )
}

export default Business