import React from 'react';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Box from './Box';
import Tooltip from './Tooltip';
import IconButton from './IconButton';
import Avatar from './Avatar';
import Menu, { MenuItem } from './Menu';
import { ListItem, ListItemAvatar, ListItemIcon, ListItemText } from './List';
import Divider from './Divider';
import { useAppDispatch } from '../hooks/state';
import { signOff } from '../features/Authentication/services/AuthenticationSlice';
import { useGetSessionDataQuery } from '../api/querys/Users';
import { useTranslation } from 'react-i18next';
import packageJSON from '../../package.json'
import Typography from './Typography';
import { useNavigate } from 'react-router-dom';
import Routes from '@service/routes';

const AccountMenu = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const { data } = useGetSessionDataQuery()
    const { payload } = data!
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate()
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const username = `${payload?.name??""} ${payload?.lastName??""}`
    const userRol = `${payload?.rol?.name??""}`
    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title={t('accountSettings')!} placement='bottom-start' >
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        minWidth: 250,
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <ListItem>
                    <ListItemAvatar sx={{ minWidth: 40 }} >
                        <Avatar sx={{ width: 32, height: 32 }}>
                            {username[0]}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primaryTypographyProps={{ style: { fontWeight: "bold" } }} primary={username} secondaryTypographyProps={{ style: { fontWeight: "500" } }} secondary={t(userRol)} />
                </ListItem>
                <Divider />
                <MenuItem onClick={() => navigate(Routes.indexSetting())} >
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    {t('settings')}
                </MenuItem>
                <MenuItem onClick={() => dispatch(signOff())} >
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    {t('signOff')}
                </MenuItem>
                <ListItem>
                    <Box display={'flex'} flexDirection={'column'} >
                        <Typography lineHeight={1} fontSize={'.8rem'} >
                            Facill - App
                        </Typography>
                        <Typography lineHeight={1} fontSize={'.7rem'} >
                            v{packageJSON.version}
                        </Typography>
                    </Box>
                </ListItem>
            </Menu>
        </React.Fragment>
    );
}

export default AccountMenu