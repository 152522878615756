import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

interface initialStateProps {
    dateClosingOfDay: string
}

const initialState:initialStateProps = {
    dateClosingOfDay: dayjs().format('MM-DD-YYYY')
}

export const ClosingOfTheDaySlice = createSlice({
    name: 'closingOfTheDay',
    initialState,
    reducers: {
        changeClosingDateOfDay: (state, action:PayloadAction<string>) => {
            state.dateClosingOfDay = action.payload
        }
    }
})

export const {
    changeClosingDateOfDay
} = ClosingOfTheDaySlice.actions
export default ClosingOfTheDaySlice.reducer