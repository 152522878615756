import DataGrid, { GridColDef } from "@component/DataGrid"
import { useGetCreditReport } from "../hooks/useCredits"
import { Brightness1Rounded } from "@mui/icons-material"
import Box from "@component/Box"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { currencyFormat } from "@hook/useNumberFormat"
import { useAppSelector } from "@hook/state"
import { useURLParams } from "@hook/useURLParams"
import Typography from "@component/Typography"
import List, { ListItem, ListItemText } from "@component/List"
import Stack from "@component/Stack"
import { FixedSizeList } from 'react-window'
import { useNavigate } from "react-router-dom"
import Routes from "@service/routes"

const CreditsList = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { getQuery } = useURLParams()
    const groupByQuery = getQuery("groupBy")
    const { CreditsState } = useAppSelector(({ CreditsState }) => ({ CreditsState }))
    const { dateRange, customerId } = CreditsState
    const { endDate, initDate } = dateRange
    const { credits, isFetching, creditsForCustomers } = useGetCreditReport({ endDate, startDate: initDate, customerId })

    const columns: GridColDef[] = [
        {
            field: "customer", headerName: t('customer')!, flex: 1, renderCell: ({ row }) => {
                const expire = dayjs().diff(dayjs(row.creditExpirationDate), 'days')
                return (
                    <Box>
                        <Brightness1Rounded sx={{ color: (theme) => expire <= 0 ? theme.palette.grey[600] : theme.palette.error.main, fontSize: '.7rem', mr: 1 }} />
                        {row.customer}
                    </Box>
                )
            }
        },
        { maxWidth: 100, minWidth: 100, field: "total", headerName: t('credit')!, renderCell: ({ row }) => <>$ {currencyFormat(row.total)}</> },
        { maxWidth: 100, minWidth: 100, field: "balance", headerName: t('balance')!, renderCell: ({ row }) => <>$ {currencyFormat(row.balance)}</> },
        { maxWidth: 80, minWidth: 80, field: "consecutive", headerName: "#FAC", align: 'center' },
        { maxWidth: 160, minWidth: 160, field: "createdAt", headerName: t('billingDate')!, renderCell: ({ row }) => <>{dayjs(row.createdAt).format("DD-MM-YYYY HH:mm A")}</> },
        {
            maxWidth: 170, minWidth: 170, field: "creditExpirationDate", headerName: t('expirationDate')!, align: 'left', renderCell: ({ row }) => {
                let days = dayjs(row.creditExpirationDate).startOf("day").diff(dayjs().startOf("day"), 'days')
                let isFuture = days >= 0 ? "dentro de" : "hace"
                let textDay = days <= 1 ? (days <= -2) ? "días" : "día" : "días"
                days = days >= 100 ? '99+' : days as any

                return (
                    <>
                        {`${dayjs(row.creditExpirationDate).format("DD-MM-YY")} ${isFuture} ${days} ${textDay}`}
                    </>
                )
            }
        },
    ]
    const handleCellClick = (row: any) => navigate(Routes.indexInvoice(row.id))

    return (
        <>
            {groupByQuery === "customer" &&
                <>
                    <FixedSizeList height={1800} itemCount={creditsForCustomers.length} itemSize={450} width={"100%"} >
                        {({ index, style }) => {
                            const n = creditsForCustomers[index];
                            let results = n?.results ?? [];

                            return (
                                <DataGrid
                                    slots={{
                                        toolbar: () => {
                                            return (
                                                <Box pt={1} px={2}>
                                                    <Typography fontWeight={"bold"} fontSize={"0.875rem"}>
                                                        {n.customer}
                                                    </Typography>
                                                    <Stack direction={"row"} spacing={1}>
                                                        <List sx={{ padding: 0 }}>
                                                            <ListItem sx={{ padding: 0 }}>
                                                                <ListItemText
                                                                    primaryTypographyProps={{
                                                                        textTransform: "uppercase",
                                                                        fontSize: "0.875rem",
                                                                        fontWeight: "500",
                                                                    }}
                                                                    secondaryTypographyProps={{
                                                                        fontSize: "0.875rem",
                                                                        fontWeight: "500",
                                                                        color: (theme) => theme.palette.info.main,
                                                                    }}
                                                                    primary={"Total créditos facturados"}
                                                                    secondary={`$ ${currencyFormat(n.total)}`}
                                                                />
                                                            </ListItem>
                                                        </List>
                                                        <List sx={{ padding: 0 }}>
                                                            <ListItem>
                                                                <ListItemText
                                                                    primaryTypographyProps={{
                                                                        textTransform: "uppercase",
                                                                        fontSize: "0.875rem",
                                                                        fontWeight: "500",
                                                                    }}
                                                                    secondaryTypographyProps={{
                                                                        fontSize: "0.875rem",
                                                                        fontWeight: "500",
                                                                        color: (theme) => theme.palette.success.main,
                                                                    }}
                                                                    primary={"Total abonos realizados"}
                                                                    secondary={`$ ${currencyFormat(n.payment)}`}
                                                                />
                                                            </ListItem>
                                                        </List>
                                                        <List sx={{ padding: 0 }}>
                                                            <ListItem>
                                                                <ListItemText
                                                                    primaryTypographyProps={{
                                                                        textTransform: "uppercase",
                                                                        fontSize: "0.875rem",
                                                                        fontWeight: "500",
                                                                    }}
                                                                    secondaryTypographyProps={{
                                                                        fontSize: "0.875rem",
                                                                        fontWeight: "500",
                                                                        color: (theme) => theme.palette.error.main,
                                                                    }}
                                                                    primary={"Total créditos pendientes"}
                                                                    secondary={`$ ${currencyFormat(n.balance)}`}
                                                                />
                                                            </ListItem>
                                                        </List>
                                                    </Stack>
                                                </Box>
                                            );
                                        },
                                    }}
                                    sx={{ mb: 1 }}
                                    height={"auto"}
                                    autoHeight
                                    key={index}
                                    loading={isFetching}
                                    columns={columns}
                                    rows={results}
                                    style={style}
                                    onCellClick={handleCellClick}
                                />
                            );
                        }}
                    </FixedSizeList>
                </>
            }
            {groupByQuery === null &&
                <DataGrid
                    loading={isFetching}
                    columns={columns}
                    rows={credits}
                    onCellClick={handleCellClick}
                />
            }
        </>
    )
}

export default CreditsList