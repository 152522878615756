import { TablePaginationProps, Pagination as MuiPagination } from '@mui/material';
import { DataGrid as DataGridMUI, GridRowSelectionModel as GridRowSelectionModelMUI, GridEventListener as GridEventListenerMUI, useGridApiEventHandler as useGridApiEventHandlerMUI, useGridApiContext as useGridApiContextMUI, GridCellEditStopParams as GridCellEditCommitParamsMUI, GridColDef as GridColDefMUI, DataGridProps as DataGridPropsMUI, GridToolbarContainer as GridToolbarContainerMUI, GridEventLookup, GRID_CHECKBOX_SELECTION_COL_DEF, useGridSelector, gridPageCountSelector, GridPagination } from '@mui/x-data-grid';
import React from 'react';
import Box from './Box';
type DataGridProps = Pick<DataGridPropsMUI, "isRowSelectable" | "style" | "columnHeaderHeight" | "disableColumnFilter" | "getRowClassName" | "slotProps" | "rowSelectionModel" | "initialState" | "slots" | "processRowUpdate" | "isCellEditable" | "onRowEditStart" | "autoHeight" | "experimentalFeatures" | "editMode" | "hideFooter" | "onRowClick" | "onCellDoubleClick" | "onCellKeyDown" | "hideFooterPagination" | "components" | "onCellClick" | "checkboxSelection" | "onRowSelectionModelChange" | "disableRowSelectionOnClick" | "rowHeight" | "className" | "sx" | "onCellEditStart" | "onCellEditStop" | "onRowEditCommit" | "onRowEditStop" >
export type GridRowSelectionModel = GridRowSelectionModelMUI
export const GridSelectionColDef = GRID_CHECKBOX_SELECTION_COL_DEF
export type GridColDef = GridColDefMUI
export type GridCellEditCommitParams = GridCellEditCommitParamsMUI
export type GridEventListener<T extends keyof GridEventLookup> = GridEventListenerMUI<T>
export const useGridApiContext = useGridApiContextMUI
export const useGridApiEventHandler = useGridApiEventHandlerMUI

interface Props extends DataGridProps {
    columns: GridColDef[]
    rows: any
    height?: number | string
    loading?: boolean
    FooterComponent?: React.ReactNode
}

function Pagination({ page, onPageChange, className }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <MuiPagination
        siblingCount={0}
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
      />
    );
}

const DataGrid = ({ columns, rows, height=450, rowHeight=25, FooterComponent, slots, ...rest }:Props) => {

    function CustomPagination(props: any) {
        return(
            <>
                {FooterComponent}
                <GridPagination labelRowsPerPage="" rowSpan={0} labelDisplayedRows={() => null} ActionsComponent={Pagination} {...props} />
            </>
        )
    }
    return(
        <Box component={'div'} sx={{ height, width: '100%' }} >
            <DataGridMUI
                slots={{
                    pagination: CustomPagination,
                    ...slots
                }}
                pagination
                rowHeight={rowHeight}
                getRowId={params => params._id}
                columns={columns}
                rows={rows}
                {...rest}
            />
        </Box>
    )
}

export default React.memo(DataGrid)


export const GridToolbarContainer = ({children}:{children: JSX.Element}) => {
    return(
        <GridToolbarContainerMUI>
            {children}
        </GridToolbarContainerMUI>
    )
}