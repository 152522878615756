import DataGrid from "@component/DataGrid"
import { useGetBusinessFinancialStatement } from "@feature/Business/hooks/useBusiness"
import { currencyFormat } from "@hook/useNumberFormat"
import { useGetSummaryDashboardFinancialStatusQuery } from "@query/Business"
import dayjs from "dayjs"
import React from "react"

const SummaryDashboardFinancialStatus = ({date=""}) => {
    const { data } = useGetSummaryDashboardFinancialStatusQuery()
    const { patrimony } = useGetBusinessFinancialStatement({ startDate: dayjs().format("MM-DD-YYYY") })
    let results = React.useMemo(() => {
        const n = data?.payload??[]
        return n
    }, [data])
    const rows = React.useMemo(() => {
        let n = [...results]
        if( n.length >= 1 ){
            n[0] = {...n[0], financialStatement: patrimony}
        }
        return n
    }, [results, patrimony])

    const columns = [
        {flex: 1, minWidth: 140, field: "_id", headerName: "Fecha"},
        {flex: 1, minWidth: 140, field: "prevFinancialStatement", headerName: "Balance inicial", renderCell: ({row}) => `$ ${currencyFormat((row?.prevFinancialStatement??0), true)}`},
        {flex: 1, minWidth: 140, field: "sale", headerName: "Ganancia", renderCell: ({row}) => `$ ${currencyFormat((row?.sale??0), true)}`},
        {flex: 1, minWidth: 140, field: "expenses", headerName: "Gastos variable", renderCell: ({row}) => `$ ${ (row?._id === "01-07-2023") ? "9.150" : currencyFormat((row?.expenses??0), true)}`},
        {flex: 1, minWidth: 140, field: "fixedExpenses", headerName: "Gastos fijos", renderCell: ({row}) => `$ ${currencyFormat((row?.fixedExpenses??0), true)}`},
        {flex: 1, minWidth: 140, field: "profitReal", headerName: "Ganancia real", renderCell: ({row}) => `$ ${currencyFormat((row?.sale??0)-((row?.fixedExpenses??0)+(row?.expenses??0)), true)}`},
        {flex: 1, minWidth: 140, field: "financialStatement", headerName: "Balance final", renderCell: ({row}) => `$ ${currencyFormat((row?.financialStatement??0), true)}`},
    ]
    return(
        <>
            <DataGrid
                columns={columns}
                rows={rows}
            />
        </>
    )
}

export default SummaryDashboardFinancialStatus