
import List from '@component/List'
import ListItemDrawerButton from './ListItemDrawerButton'
import React from 'react'
import { useBusinessPermissions } from '@hook/usePermissions'
import { useTranslation } from 'react-i18next'

export interface DrawerListProps {
    path: string,
    icon: React.ReactNode | null,
    title: string,
    collapse: boolean,
    children: DrawerListProps[]
    groupNamePermission?: string
    namePermission?: string
}

interface Props {
    drawerList : Array<DrawerListProps>
}

const DrawerButton = ({ drawerList }:Props) => {
    const [openItem, setOpenItem] = React.useState<boolean[]>([false])
    const PermissionsCollection = useBusinessPermissions()
    const { t } = useTranslation()

    React.useEffect(() => {
        let values = []
        for(let n of drawerList){
            values.push(false)
        }
        if( openItem.length <  drawerList.length){
            setOpenItem(values)
        }
    },[drawerList, openItem])
    const handleOpenItems = (index:number) => {
        let _openItem = [...openItem]
        const initialValue = _openItem[index]
        _openItem = _openItem.map(n => false)
        _openItem[index] = !initialValue
        setOpenItem(_openItem)
    }
    return(
        <List sx={{ width: 'calc(100% + 15px)', paddingLeft: '15px', overflow: 'auto', position: 'relative', height: '75vh', }} >
            {drawerList.map((item, index) => {
                if( item.collapse ){
                    return(
                        <ListItemDrawerButton key={index} collapse={item.collapse} title={t(item.title)} icon={item.icon} open={openItem[index]} onClose={() => handleOpenItems(index)}   >
                            {item.children.map((chil, chilIndex) => {
                                let permissions = structuredClone(PermissionsCollection)
                                let applyPermission = (chil?.groupNamePermission && chil?.namePermission) ? permissions[chil?.groupNamePermission??""][chil?.namePermission??""] : true

                                return(
                                    <React.Fragment key={chilIndex}  >
                                        {applyPermission &&
                                            <ListItemDrawerButton path={chil.path} title={t(chil.title)} icon={chil.icon} />
                                        }
                                    </React.Fragment>
                                )

                            })}
                        </ListItemDrawerButton>
                    )
                }else{
                    return(
                        <ListItemDrawerButton key={index} path={item.path} title={item.title} icon={item.icon} />
                    )
                }
            })}
        </List>
    )
}

export default DrawerButton