import type { PermissionResponseProps } from '../../interfaces/Permission';
import type { RoleFormProps, RoleResponseProps } from '../../interfaces/Role';
import { api, mutationConfig } from '../splitApi';

const Permission = api.injectEndpoints({
  endpoints: (build) => ({
    getRoleByBusiness: build.query<RoleResponseProps, void>({
      query: () => '/get/business/roles',
      providesTags: ['Permission'],
    }),
    getPermissions: build.query<PermissionResponseProps, void>({
      query: () => '/get/permissions',
      providesTags: ['Permission'],
    }),
    registerRol: build.mutation<RoleResponseProps, RoleFormProps>({
      query(body) {
        return { ...mutationConfig, url: '/register/role', body };
      },
      invalidatesTags: ['Permission'],
    }),
    updateRole: build.mutation<RoleResponseProps, RoleFormProps>({
      query(body) {
        return { ...mutationConfig, url: '/update/role', body };
      },
      invalidatesTags: ['Permission']
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPermissionsQuery,
  useGetRoleByBusinessQuery,
  useRegisterRolMutation,
  useUpdateRoleMutation,
} = Permission;
