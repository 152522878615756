import React from 'react'
import Box from "@component/Box"
import Dialog, { DialogActions, DialogContent, DialogTitle } from "@component/Dialog"
import Dropzone from "@component/Dropzone"
import Typography from "@component/Typography"
import BoxPayments, { onChangeEventBoxResult } from "@feature/Business/components/BoxPayments"
import { useAppDispatch, useAppSelector } from "@hook/state"
import { currencyFormat } from "@hook/useNumberFormat"
import { usePurchasing, usePurchasingTools } from "../hooks/usePurchasing"
import { showCashForm, showPaymentSelectionWindow } from "../services/PurchasesSuppliersSlice"
import Button from '@component/Button'

const CashPaymentForm = () => {
    const [images, setImages] = React.useState<File[]>([])
    const [payments, setPayments] = React.useState<onChangeEventBoxResult[]>([])
    const dispatch = useAppDispatch()
    const { PurchasesSuppliersState } = useAppSelector(({ PurchasesSuppliersState }) => ({ PurchasesSuppliersState }))
    const { productsSelectedList, providerSelected, haulier, openFormCash, billingDate, dateReceived, whoReceives, invoiceNumber, purchaseId } = PurchasesSuppliersState
    const { calcTotalPurchasing } = usePurchasingTools()
    const { total } = calcTotalPurchasing(productsSelectedList, providerSelected)
    const { recordPurchaseFromSupplier, isLoadingUseRecordPurchaseFromSupplierMutation } = usePurchasing()
    const handleSubmit = () => recordPurchaseFromSupplier({ purchaseId, invoiceNumber, haulier, payments, productList: productsSelectedList, provider: providerSelected!, typePayments: "cash", images, billingDate, dateReceived, whoReceives})

    const handleClose = () => {
        dispatch(showCashForm(false))
        dispatch(showPaymentSelectionWindow(true))
    }

    return(
        <>
            <Dialog fullWidth open={openFormCash} onClose={handleClose} >
                <DialogTitle>
                    <Typography>Total a pagar: $ {currencyFormat(total)} </Typography>
                    <Typography>Total abonos: $ {currencyFormat(payments?.reduce((accumulator, params) => params.payment+accumulator,0 ))} </Typography>
                    <Typography>Saldo: $ {currencyFormat( (total-payments?.reduce((accumulator, params) => params.payment+accumulator,0 )) )} </Typography>
                </DialogTitle>
                <DialogContent>
                    <BoxPayments maxPayment={total} onChange={(params) => setPayments(params)} />
                    <Box mt={1} >
                        <Dropzone onChange={({ value }) => setImages(value)} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} variant='text' loading={isLoadingUseRecordPurchaseFromSupplierMutation} >
                        Finalizar compra
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CashPaymentForm