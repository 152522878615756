const Routes = {
  indexHome: () => `/`,
  indexCustomers: () => `/customers`,
  customerData: (customerId?: string) => customerId === undefined ? `/customer/:customerId` : `/customer/${customerId}`,
  indexEmployee: () => `/employees`,
  employeeData: (employeeId?: string) => employeeId === undefined ? `/employee/:employeeId` : `/employee/${employeeId}`,
  indexInvoice: (invoiceId?: string) => invoiceId === undefined ? `/invoice/:invoiceId` : `/invoice/${invoiceId}`,
  indexInventory: () => `/inventories`,
  inventoryByCategoryProducts: (categoryId?: string) => categoryId === undefined ? `/inventories/:categoryId` : `/inventories/${categoryId}`,
  indexProducts: () => `/products`,
  indexCustomerPurchaseTracking: () => `/customer/purchase/tracking`,
  indexImportProducts: () => `/import/products`,
  indexProductCategory: () => `/product/category`,
  indexProductPackaging: () => `/product/packaging`,
  indexPermissions: () => `/permissions`,
  indexReportOfTheDay: () => `/report/of/the/day`,
  indexCreditReport: () => `/credit/report`,
  indexDeliveryReport: () => `/delivery/report`,
  indexClosingOfTheDay: () => `/closing/of/the/day`,
  indexBatch: (productId?: string) => productId === undefined ? `/batch/:productId` : `/batch/${productId}`,
  indexProduct: (productId?: string) => productId === undefined ? `/product/:productId` : `/product/${productId}`,
  indexProviders: () => `/providers`,
  indexBanks:()=>`/banks`,
  indexBank: (bankId?: string) => bankId === undefined ? `/bank/:bankId` : `/bank/${bankId}`,
  indexProvider: (providerId?: string) => providerId === undefined ? `/provider/:providerId` : `/provider/${providerId}`,
  indexBusinessData: () => `/business`,
  indexPurchasingSuppliers: () => `/purchasing/suppliers`,
  indexMinorBox: () => `/minor/box`,
  indexLargeBox: () => `/large/box`,
  indexCustomerZone: () => "/customer/zones",
  indexNeighborhoods: () => "/customer/neighborhoods",
  indexCustomerBranch: () => "/customer/branch",
  indexMassProducts: () => "/mass/products",
  indexCustomerCity: () => "/customer/city",
  indexCustomerBusinessesType: () => "/customer/businesses/type",
  indexDebtsToPay: () => `/debts/to/pay`,
  indexPurchaseReports: () => `/purchase/reports`,
  indexMasterBox: () => `/master/box`,
  indexFixedCosts: (fixedCostId?: string) => fixedCostId === undefined ? `/fixed/costs/:fixedCostId?` : `/fixed/costs/${fixedCostId}`,
  indexSetting: () => `/setting`,
  indexPurchase: (purchaseId?: string) => purchaseId === undefined ? `/purchase/:purchaseId` : `/purchase/${purchaseId}`,
  indexBankBox: (bankId?: string) => bankId === undefined ? `/bank/box/:bankId` : `/bank/box/${bankId}`,
  indexCashiers: () => `/cashiers`,
  indexCashierMovements: (cashierId?:string) =>  (cashierId === undefined) ? `/cashier/:cashierId` : `/cashier/${cashierId}`,
  indexExpenses: () =>  `/expenses`,
  indexShoppingDiscounts: () =>  `/shopping/discounts`,
};

export default Routes;
