import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useGetProviderQuery } from "../../../api/querys/Provider"
import Fade from "@component/Fade"
import List from "@component/List"
import ListItemData from "@component/ListItemData"

const ProviderInformation = () => {
    const { t } = useTranslation()
    const { providerId } = useParams()
    const { data } = useGetProviderQuery({ providerId: providerId! })
    const provider = data?.payload
    return(
        <Fade in={true} >
            <List>
                <ListItemData showAvatar primaryText={t('provider')!} secondaryText={provider?.name??""} />
                <ListItemData primaryText={t('representative')!} secondaryText={provider?.representative??""} />
                <ListItemData primaryText={t('code')!} secondaryText={provider?.code??""} />
                <ListItemData primaryText={t('address')!} secondaryText={provider?.address??""} />
                <ListItemData primaryText={t('city')!} secondaryText={provider?.city??""} />
                <ListItemData primaryText={t('responsibleForValueAddedTax')!} secondaryText={provider?.ivaResponsible ? t('yeah')! : t('not')!} />
                <ListItemData primaryText={t('nit')!} secondaryText={provider?.nit??""} />
                <ListItemData primaryText={t('phoneNumber')!} secondaryText={provider?.phone??""} />
            </List>
        </Fade>
    )
}

export default ProviderInformation