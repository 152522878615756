import Tab from "@component/Tab"
import ListProviders from "./ListProviders"
import HistoryOfAdvances from "./HistoryOfAdvances"

const MovementsSuppliersTab = () => {
    return(
        <>
            <Tab
                orientation="horizontal"
                TabItem={["Proveedores", "Historial de anticipos"]}
                TabRender={[<ListProviders />, <HistoryOfAdvances />]}
            />
        </>
    )
}

export default MovementsSuppliersTab