import DataGrid, { GridColDef, GridEventListener } from "@component/DataGrid"
import { useAppDispatch, useAppSelector } from "@hook/state"
import { currencyFormat } from "@hook/useNumberFormat"
import { useProductsForSupplierPurchases, usePurchasingTools } from "../hooks/usePurchasing"
import { updateSelectedProduct, updateSelectedProductOfList } from "../services/PurchasesSuppliersSlice"
import FooterSelectedProducts from "./FooterSelectedProducts"
import IconButton from "@component/IconButton"
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import Tooltip from "@component/Tooltip"
import { updateListOfSelectedProducts } from "../services/PurchasesSuppliersSlice"

const TableSelectedProducts = () => {
    const dispatch = useAppDispatch()
    const { PurchasesSuppliersState } = useAppSelector(({ PurchasesSuppliersState }) => ({ PurchasesSuppliersState }))
    const { products } = useProductsForSupplierPurchases()
    const { productsSelectedList } = PurchasesSuppliersState
    const { calcProductIVA } = usePurchasingTools()
    const handleRemoveProduct = (itemId:string) => {
        const _productsSelectedList = [...productsSelectedList]
        const productIndex = _productsSelectedList.findIndex(n => n._id === itemId)
        if( productIndex >= 0 ){
            _productsSelectedList.splice(productIndex, 1)
            dispatch(updateListOfSelectedProducts(_productsSelectedList))
        }
    }
    const columns:GridColDef[] = [
        {
            flex: 1,
            field: "name",
            headerName: "Producto"
        },
        {
            maxWidth: 100,
            minWidth: 100,
            field: "quantity",
            headerName: "Cant."
        },
        {
            field: "purchaseCost",
            headerName: "Cst.",
            valueFormatter: ({ value }) => {
                if( value === null ){
                    return '$ 0'
                }else{
                    return `$ ${currencyFormat(Number(value))}`
                }
            }
        },
        {
            field: "freight",
            headerName: "Flete",
            valueFormatter: ({ value }) => {
                if( value === null ){
                    return '$ 0'
                }else{
                    return `$ ${currencyFormat(Number(value))}`
                }
            }
        },
        {
            field: "totalFreight",
            headerName: "Total flete",
            renderCell: ({ row }) => `$ ${currencyFormat(Number(((row?.freight??0)*(row?.quantity??0))))}`
        },
        {
            field: "tax",
            headerName: "Impuesto",
            valueFormatter: ({ value }) => {
                if( value?._id === null || value?._id === undefined){
                    return ''
                }else{
                    return `${value.name} ${value.tax}%`
                }
            }
        },
        {
            field: "iva",
            headerName: "IVA",
            renderCell: ({ row }) => {
                const iva = calcProductIVA({ iva: (row?.tax?.tax??0), cost: (row.quantity*row.purchaseCost) })
                return `$ ${currencyFormat(Number(iva))}`
            }
        },
        {
            field: "packaging",
            headerName: "Embalaje",
            valueFormatter: ({ value }) => {
                if( value?._id === null || value?._id === undefined){
                    return ''
                }else{
                    return `${value.name}`
                }
            }
        },
        {
            field: "subTotal",
            headerName: "Sub total",
            renderCell: ({ row }) => {
                return `$ ${currencyFormat(Number(row.purchaseCost*row.quantity))}`
            }
        },
        {
            field: "total",
            headerName: "Total",
            renderCell: ({ row }) => {
                const iva = calcProductIVA({ iva: (row?.tax?.tax??0), cost: (row.quantity*row.purchaseCost) })
                const subTotal = Number(row.purchaseCost*row.quantity)
                const total = (subTotal+iva)
                return `$ ${currencyFormat(total)}`
            }
        },
        {
            field: "options",
            headerName: " ",
            align: "center",
            maxWidth: 50,
            minWidth: 50,
            renderCell: ({ row }) => {
                return(
                    <Tooltip title="Eliminar producto" >
                        <IconButton color="error" onClick={() => handleRemoveProduct(row?._id??"")} >
                            <DeleteRounded />
                        </IconButton>
                    </Tooltip>
                )
            }
        },
    ]
    const handleRowClick:GridEventListener<"cellClick"> = ({ row, field, id }) => {
        if( field !== "options" ){
            dispatch(updateSelectedProductOfList(row))
            let p = products.find(n => n._id === id) || null
            dispatch(updateSelectedProduct(p))
        }
    }
    return(
        <>
            <DataGrid
                slots={{
                    footer: FooterSelectedProducts,
                }}
                onCellClick={handleRowClick}
                columns={columns}
                rows={productsSelectedList}
            />
        </>
    )
}

export default TableSelectedProducts