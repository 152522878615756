import React from 'react';
import { useGetSessionDataQuery } from "../api/querys/Users"
import { PermissionProps } from '../interfaces/Permission';
import { RoleProps } from '../interfaces/Role';
import { permissionsMap } from '../services/permissions.map';

export const usePermissions = () => {
    type userPermissionValues = keyof typeof permissionsMap;
    const { data } = useGetSessionDataQuery()
    const userPermissions = data?.payload?.rol?.permissions??[] as RoleProps['permissions']

    const hasPermission = React.useCallback((permission:userPermissionValues) => {
        let result = userPermissions.find(n => n.name === permission) || {} as PermissionProps
        return Boolean(result.name)
    },[userPermissions])

    const haveSufficientPermissions = React.useCallback( (permissions:Array<userPermissionValues>) => {
        let result = []
        for( let permission of permissions ){
            const p = userPermissions.find(n => n.name === permission) || {} as PermissionProps
            if( Boolean(p.name) ){
                result.push(p)
            }
        }
        return result.length >= 1 ? true : false
    },[userPermissions])

    return { hasPermission, haveSufficientPermissions }
}

export const useBusinessPermissions = () => {
    const { hasPermission, haveSufficientPermissions } = usePermissions()
    const MyBusiness = {
        'view_information': hasPermission('view_business_information'),
        'edit_information': hasPermission('edit_business_information'),
        'lock_module': haveSufficientPermissions(['view_business_information', 'edit_business_information']),
    }
    const Permissions = {
        'lock_module': haveSufficientPermissions(['manage_role_and_permissions']),
    }
    const BoxLarge = {
        'lock_module': haveSufficientPermissions(['manage_box_large']),
    }
    const Banks = {
        'lock_module': haveSufficientPermissions(['manage_banks']),
    }
    const AccountsPayable = {
        'lock_module': haveSufficientPermissions(['manage_accounts_payable']),
    }
    const Customers = {
        'lock_module': haveSufficientPermissions(['view_customer_information', 'register_customer_information', 'edit_customer_information', 'delete_customer_information']),
        'view_information': hasPermission('view_customer_information'),
        'create_information': hasPermission('register_customer_information'),
        'edit_information': hasPermission('edit_customer_information'),
        'delete_information': hasPermission('delete_customer_information'),
        'only_view_and_edit': haveSufficientPermissions(['view_customer_information', 'edit_customer_information']),
        'filters': hasPermission('filters_customer'),

    }
    const Team = {
        'lock_module': haveSufficientPermissions(['manage_team']),
    }
    const Provider = {
        'lock_module': haveSufficientPermissions(['manage_provider']),
    }
    const Inventory = {
        'lock_module': haveSufficientPermissions(['manage_inventory']),
    }
    const Product = {
        'lock_module': haveSufficientPermissions(['bulk_upload_from_file', 'view_product_information', 'register_product_information', 'edit_product_information', 'delete_product_information', 'view_purchase_history']),
        'view_information': hasPermission('view_product_information'),
        'create_information': hasPermission('register_product_information'),
        'edit_information': hasPermission('edit_product_information'),
        'delete_information': hasPermission('delete_product_information'),
        'view_purchase_history': hasPermission('view_purchase_history'),
        'only_view_and_edit': haveSufficientPermissions(['view_product_information', 'edit_product_information']),
        'bulk_upload_from_file': hasPermission('bulk_upload_from_file'),
    }
    const ProductCategories = {
        'lock_module': haveSufficientPermissions(['manage_product_category']),
    }
    const ProductPackaging = {
        'lock_module': haveSufficientPermissions(['manage_product_packaging']),
    }
    const MinorBox = {
        'lock_module': haveSufficientPermissions(['manage_minor_box']),
    }
    const MinorExpense = {
        'lock_module': haveSufficientPermissions(['manage_minor_expenses']),
    }
    const MinorPurchases = {
        'lock_module': haveSufficientPermissions(['manage_minor_purchases']),
    }
    const ClosingOfTheDay = {
        'lock_module': haveSufficientPermissions(['manage_closing_of_the_day']),
    }
    const ReportOfTheDay = {
        'lock_module': haveSufficientPermissions(['see_prices', 'see_sales', 'manage_report_of_the_day', 'filter_reports_by_days', 'filter_reports_by_invoice_values', 'download_template_of_the_report_of_the_day']),
        'filter_reports_by_days': hasPermission('filter_reports_by_days'),
        'filter_reports_by_invoice_values': hasPermission('filter_reports_by_invoice_values'),
        'download_template_of_the_report_of_the_day': hasPermission('download_template_of_the_report_of_the_day'),
        'see_prices': hasPermission('see_prices'),
        'see_sales': hasPermission('see_sales'),
    }
    const CustomerFollowUP = {
        'lock_module': haveSufficientPermissions(['manage_customer_follow_up']),
    }
    const Invoice = {
        'lock_module': haveSufficientPermissions(['edit_invoice', 'print_invoice', 'change_payment_method_invoice', 'add_subscriptions_invoice']),
        'view_information': hasPermission('view_invoice'),
        'edit_information': hasPermission('edit_invoice'),
        'print': hasPermission('print_invoice'),
        'change_payment_method': hasPermission('change_payment_method_invoice'),
        'add_subscriptions': hasPermission('add_subscriptions_invoice'),
        
    }
    const Credit = {
        'lock_module': haveSufficientPermissions(['manage_credit']),
    }
    const Delivery = {
        'lock_module': haveSufficientPermissions(['manage_delivery']),
    }
    const PurchaseFromSuppliers = {
        'lock_module': haveSufficientPermissions(['record_purchase_from_suppliers', 'register_advance_to_supplier', 'remove_purchase']),
        'record_purchase_from_suppliers': hasPermission('record_purchase_from_suppliers'),
        'register_advance_to_supplier': hasPermission('register_advance_to_supplier'),
        'remove_purchase': hasPermission('remove_purchase'),
    }
    const Cashiers = {
        'lock_module': haveSufficientPermissions(['manage_cashiers']),
        'manage_cashiers': hasPermission('manage_cashiers'),
    }
    const FixedExpenses = {
        'lock_module': haveSufficientPermissions(['manage_fixed_expenses']),
        'manage_fixed_expenses': hasPermission('manage_fixed_expenses'),
    }
    const Expenses = {
        'lock_module': haveSufficientPermissions(['manage_expenses', 'delete_expense']),
        'manage_expenses': hasPermission('manage_expenses'),
        'delete_expense': hasPermission('delete_expense'),
    }
    const MasterBox = {
        'lock_module': haveSufficientPermissions(['manage_master_box']),
        'manage_master_box': hasPermission('manage_master_box'),
    }
    const Boxes = {
        'lock_module': haveSufficientPermissions(['manage_master_box', 'manage_minor_box', 'manage_box_large', 'manage_banks']),
    }
    const PointOfSale = {
        'lock_module': haveSufficientPermissions(['record_sale', 'record_and_print_sale', 'print_last_receipt', 'create_customer_from_point_of_sale', 'go_to_reports_from_point_of_sale', 'create_product_from_point_of_sale', 'record_cashier_expense']),
        'record_cashier_expense': hasPermission('record_cashier_expense'),
        'record_sale': hasPermission('record_sale'),
        'record_price': hasPermission('record_price'),
        'record_and_print_sale': hasPermission('record_and_print_sale'),
        'print_last_receipt': hasPermission('print_last_receipt'),
        'create_customer_from_point_of_sale': hasPermission('create_customer_from_point_of_sale'),
        'create_product_from_point_of_sale': hasPermission('create_product_from_point_of_sale'),
        'go_to_reports_from_point_of_sale': hasPermission('go_to_reports_from_point_of_sale'),
        'select_invoice_payment_method': hasPermission('select_invoice_payment_method'),
    }

    return {
        MyBusiness,
        Permissions,
        BoxLarge,
        Banks,
        AccountsPayable,
        Customers,
        Team,
        Provider,
        Inventory,
        Product,
        ProductCategories,
        ProductPackaging,
        MinorBox,
        MinorExpense,
        MinorPurchases,
        ClosingOfTheDay,
        ReportOfTheDay,
        CustomerFollowUP,
        Invoice,
        Credit,
        Delivery,
        PointOfSale,
        PurchaseFromSuppliers,
        Cashiers,
        FixedExpenses,
        Expenses,
        MasterBox,
        Boxes,
    }
}