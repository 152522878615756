import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { ZoneData } from "@interface/Zones";

export interface initialStateProps {
  isOpenForm: boolean;
  listZones: ZoneData[];
  zoneToEdit: Pick<ZoneData, "name" | "_id">;
  selectionToDelete: string[];
  securityAlertClearZone: boolean;
}

const initialState: initialStateProps = {
  isOpenForm: false,
  listZones: [],
  zoneToEdit: { name: "", _id: "" },
  selectionToDelete: [],
  securityAlertClearZone: false,
};

export const ZoneSlice = createSlice({
  name: "zoneSlice",
  initialState,
  reducers: {
    updateZoneList: (state, action: PayloadAction<ZoneData[]>) => {
      state.listZones = action.payload;
    },
    displayZoneForm: (state, action: PayloadAction<boolean>) => {
      state.isOpenForm = action.payload;
      state.zoneToEdit = { _id: "", name: "" };
    },
    loadZoneToEdit: (
      state,
      action: PayloadAction<initialStateProps["zoneToEdit"]>
    ) => {
      state.isOpenForm = true;
      state.zoneToEdit = action.payload;
    },
    loadZonesToDelete: (state, action: PayloadAction<string[]>) => {
      state.selectionToDelete = action.payload;
    },
    showSecurityAlertClearZone: (state, action: PayloadAction<boolean>) => {
      state.securityAlertClearZone = action.payload;
    },
  },
});

export const {
  displayZoneForm,
  updateZoneList,
  loadZoneToEdit,
  loadZonesToDelete,
  showSecurityAlertClearZone,
} = ZoneSlice.actions;

export default ZoneSlice.reducer;
