import type { EmployeeDataForm, EmployeeDataPropsResponse, EmployeeDataReponse, EmployeeProps } from "../../interfaces/Employee";
import { api, mutationConfig } from "../splitApi"

const User = api.injectEndpoints({
    endpoints: (build) => ({
        getEmployessByBusiness: build.query<EmployeeDataReponse, void>({
            query: () => '/get/business/employee',
            providesTags: ['Employee'],
        }),
        getEmployee: build.query<EmployeeDataPropsResponse, string>({
            query: (employeeId) => ({ url: `/get/employee`, params: {employeeId} }),
            providesTags: ['Employee'],
        }),
        registerEmployee: build.mutation<EmployeeDataPropsResponse, Omit<EmployeeDataForm, "_id">>({
            query(body) {
                return {...mutationConfig, url: '/register/employee', body}
            },
            invalidatesTags: ['Employee'],
        }),
        updateEmployee: build.mutation<any, Partial<EmployeeDataForm>>({
            query(body) {
                return {...mutationConfig, url: '/update/employee', body}
            },
            invalidatesTags: ['Employee'],
        }),
        deleteEmployee: build.mutation<any, string[]>({
            query(body) {
                return {...mutationConfig, url: '/delete/employee', body}
            },
            invalidatesTags: ['Employee'],
        }),
        allowEmployeeLogin: build.mutation<any, { employeeId: string, allowLogin: boolean }>({
            query(body) {
                return {...mutationConfig, url: '/allow/employee/login', body}
            },
            invalidatesTags: ['Employee'],
        }),
        changeAccessPassword: build.mutation<any, { employeeId: string, password: string }>({
            query(body) {
                return {...mutationConfig, url: '/change/access/password', body}
            },
            invalidatesTags: ['Employee'],
        }),
        updateBasicPayrollData: build.mutation<any, Pick<EmployeeProps, "_id" | "startOfTheDay" | "endOfDay" | "baseSalary" | "cutForPayments" > >({
            query(body) {
                return {...mutationConfig, url: '/update/basic/payroll/data', body}
            },
            invalidatesTags: ['Employee', 'PaySheet'],
        }),
        updateEmployeeRole: build.mutation<any, { roleId: string, employeeId: string }>({
            query(body) {
                return {...mutationConfig, url: '/update/employee/role', body}
            },
            invalidatesTags: ['Employee'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetEmployessByBusinessQuery,
    useGetEmployeeQuery,
    useUpdateEmployeeMutation,
    useRegisterEmployeeMutation,
    useDeleteEmployeeMutation,
    useAllowEmployeeLoginMutation,
    useChangeAccessPasswordMutation,
    useUpdateBasicPayrollDataMutation,
    useUpdateEmployeeRoleMutation
} = User