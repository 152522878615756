import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { GridRowSelectionModel } from '@mui/x-data-grid'

interface stateProps {
    isOpenFormCreateBanks: boolean
    selectedBanks: GridRowSelectionModel
    isOpenAlertDeleteBanks: boolean
}

const initialState:stateProps = {
    isOpenFormCreateBanks: false,
    selectedBanks: [],
    isOpenAlertDeleteBanks: false
}

export const BankSlice = createSlice({
    name: 'bank',
    initialState,
    reducers: {
        openFormCreateBanks: (state, action:PayloadAction<boolean>) => {
            state.isOpenFormCreateBanks = action.payload
        },
        selectBanks: (state, action:PayloadAction<GridRowSelectionModel>) => {
            state.selectedBanks = action.payload
        },
        showAlertDeleteBanks: (state, action:PayloadAction<boolean>) => {
            state.isOpenAlertDeleteBanks = action.payload
        }
    }
})

export const {
    openFormCreateBanks,
    selectBanks,
    showAlertDeleteBanks
} = BankSlice.actions
export default BankSlice.reducer