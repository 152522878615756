import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetSalesReportQuery } from '../../../api/querys/PointOfSale';
import DataGrid from '../../../components/DataGrid';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import { currencyFormat } from '../../../hooks/useNumberFormat';
import Routes from '../../../services/routes';
import { resetFilters } from '../services/ReportOfTheDaySlice';

const TableReportsPrices = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { ReportOfTheDayState } = useAppSelector(({ ReportOfTheDayState }) => ({
    ReportOfTheDayState,
  }));
  const { initDate, endDate, userId, customerId, invoiceNumbers, businessTypeId, zoneId, branchId, neighborhoodId, cityId, groupById, documentNumber } = ReportOfTheDayState;
  const { data } = useGetSalesReportQuery({ initDate, endDate, userId, customerId, invoiceNumbers, businessTypeId, zoneId, branchId, neighborhoodId, cityId, groupById, documentNumber });
  const reportsPrices = data?.payload?.prices??[];

  const columns: Array<GridColDef> = [
    {
      field: 'customer',
      headerName: t('customer')!,
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => <> {t(row.customer)} </>,
    },
    { field: 'consecutive', headerName: '#COT', minWidth: 100 },
    {
      field: 'total',
      headerName: t('sale')!,
      minWidth: 100,
      renderCell: ({ row }) => <>$ {currencyFormat(row.total)} </>,
    },
    {
      field: 'totalProfitCurrency',
      headerName: '$',
      headerAlign: 'center',
      minWidth: 100,
      renderCell: ({ row }) => (
        <>$ {currencyFormat(row.totalProfitCurrency)} </>
      ),
    },
    {
      field: 'totalProfitPercentage',
      headerName: '%',
      headerAlign: 'center',
      maxWidth: 70,
      minWidth: 70,
      renderCell: ({ row }) => (
        <>{currencyFormat(row.totalProfitPercentage)}%</>
      ),
    },
    { field: 'seller', headerName: t('seller')! },
  ];
  
  const handleCellClick = ({ id }: GridCellParams) => {
    const rowId = id as string;
    dispatch(resetFilters())
    navigate(Routes.indexInvoice(rowId));
  };

  return (
    <>
      <DataGrid
        rows={reportsPrices}
        columns={columns}
        onCellClick={handleCellClick}
      />
    </>
  );
};

export default TableReportsPrices;
