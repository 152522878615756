import { Radio as RadioMUI, RadioGroup as RadioGroupMUI, FormControl as FormControlMUI, FormLabel as FormLabelMUI, FormControlLabel as FormControlLabelMUI } from '@mui/material';
import React from 'react';

type RadioGroupProps = Pick<React.ComponentProps<typeof RadioGroupMUI>, "row" | "name" | "children" | "value" | "onChange">
export const RadioGroup = ({children, row=true, ...rest}:RadioGroupProps) => {
    return(
        <RadioGroupMUI row={row} {...rest}>
            {children}
        </RadioGroupMUI>
    )
}
type FormControlProps = Pick<React.ComponentProps<typeof FormControlMUI>, "children">
export const FormControl = ({ children }:FormControlProps) => {
    return(
        <FormControlMUI>
            {children}
        </FormControlMUI>
    )
}

type FormLabelProps = Pick<React.ComponentProps<typeof FormLabelMUI>, "children">

export const FormLabel = ({children}:FormLabelProps) => {
    return(
        <FormLabelMUI>
            {children}
        </FormLabelMUI>
    )
}

type FormControlLabelProps = Pick<React.ComponentProps<typeof FormControlLabelMUI>, "value" | "label" |"disabled">

export const FormControlLabel = ({...rest}:FormControlLabelProps) => {
    return(
        <FormControlLabelMUI control={<RadioMUI />} {...rest} />
    )
}