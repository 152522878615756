import Fade from "@component/Fade"
import FixedExpensesList from "./components/FixedExpensesList"
import Grid from "@component/Grid"
import FixedExpenseFilters from "./components/FixedExpenseFilters"
import Title from "@component/Title"
import FixedExpenseForm from "./components/FixedExpenseForm"

const FixedCosts = () => {

    return(
        <>
            <Fade in={true} >
                <>
                    <Grid container >
                        <Grid xs={12} >
                            <Title text="Gastos fijos" />
                        </Grid>
                        <Grid xs={12} >
                            <FixedExpenseFilters />
                        </Grid>
                        <Grid xs={12} >
                            <FixedExpensesList />
                        </Grid>
                    </Grid>
                    <FixedExpenseForm />
                </>
            </Fade>
        </>
    )
}

export default FixedCosts