import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import { useGetSessionDataQuery } from "../../api/querys/Users"
import Box from "@component/Box"
import Fade from "@component/Fade"
import Typography from "@component/Typography"
import HomeAdmin from "./components/HomeAdmin"

const Home = () => {
    const theme = useTheme()
    const { data } = useGetSessionDataQuery()
    const user = data?.payload
    const { t } = useTranslation()

    return(
        <Fade in={true} >
            <Box>
                <Typography fontWeight={'bold'} >
                    {t('welcome')} <Box component={'span'} sx={{ color: theme.palette.primary.main }} > {user?.name??""} {user?.lastName??""} </Box>, {t('thatHowThingsAreGoingToday')}.
                </Typography>
                {((user?.typeUser??"employee") === "employee") ?
                    <></>
                    :
                    <HomeAdmin />
                }
            </Box>
        </Fade>
    )
}

export default Home