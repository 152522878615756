import DataGrid, { GridColDef, GridEventListener } from "@component/DataGrid"
// import ReactDOM from 'react-dom/client';
import AccountsPayableListToolbar from "./AccountsPayableListToolbar"
import { useGetDebtsToPay } from "../hooks/useAccountsPayable"
import { currencyFormat } from "@hook/useNumberFormat"
import { useURLParams } from "@hook/useURLParams"
// import IconButton from "@component/IconButton"
// import CurrencyExchangeRounded from '@mui/icons-material/CurrencyExchangeRounded';
// import Tooltip from "@component/Tooltip"
// import Swal from "sweetalert2"
// import Typography from "@component/Typography"
// import List, { ListItem, ListItemText } from "@component/List"
// import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const AccountsPayableList = () => {
    const { addQuery } = useURLParams()
    const { debtsToPay } = useGetDebtsToPay({ only: "balance", show: "results" })
    const { t } = useTranslation()

    // const handlePaymentAll = (tag:string) => {
    //     const elements = debtsToPay.find(n => n._id === tag)?.results??[]
    //     const total = elements.reduce((acc, params) => (params?.amount??0)+acc,0)

    //     const swalAlert = Swal.fire({
    //         icon: 'question',
    //         html: `<div id="swal-root" ></div>`,
    //         confirmButtonText: 'Ir pagar',
    //         showCancelButton: true,
    //         cancelButtonText: 'Cancelar'
    //     })
    //     ReactDOM.createRoot(document.getElementById("swal-root") as HTMLElement ).render(
    //     <>
    //         <Typography textTransform={'uppercase'} fontWeight={'bold'} >¿Deseas pagar todas las cuentas por ${currencyFormat(total)}?</Typography>
    //         <List sx={{ padding: 0 }} >
    //             {elements.map((item, index) => {
    //                 return(
    //                 <ListItem key={index} sx={{ padding: 0 }} >
    //                     <ListItemText sx={{ margin: 0 }} primary={`${item?.name??""} - Vencido el ${dayjs(item?.createdAt).format("DD-MM-YYYY")}`} secondary={`$ ${currencyFormat(item?.amount??0)}`} />
    //                 </ListItem>
    //                 )
    //             })}
    //         </List>
    //     </>)
    //     swalAlert.then(({ isConfirmed }) => {
    //         if( isConfirmed ){
    //             addQuery({ modal: 'paymentAll', tag })
    //         }
    //     })
    // }

    const columns:GridColDef[] = [
        {flex: 1, field: '_id', headerName: 'Etiqueta', valueGetter: ({ row }) => `${t(row?._id??"")}` },
        {flex: 1, field: 'results', headerName: 'Resultados', renderCell: ({row}) => `${row.results.length}`},
        {flex: 1, field: 'total', headerName: 'Total', renderCell: ({ row }) => `${currencyFormat(row?.total??0)}`},
        //Estudiar utilidad de esta accion
        // {maxWidth: 50, minWidth: 50, field: 'buttons', headerName: ' ', renderCell: ({ row }) => {
        //     return(
        //         <>
        //             <Tooltip title='Pagar todo' >
        //                 <IconButton size="small" onClick={() => handlePaymentAll(row?._id??"")} >
        //                     <CurrencyExchangeRounded sx={{ fontSize: "1rem" }} />
        //                 </IconButton>
        //             </Tooltip>
        //         </>
        //     )
        // }},
    ]
    const handleCellClick:GridEventListener<"cellClick"> = ({ id, field }) => {
        if( field !==  'buttons' ){
            addQuery({ modal: 'accountsPayablePreview', tag: id as string })
        }
    }
    return(
        <>
            <DataGrid
                onCellClick={handleCellClick}
                slots={{
                    toolbar: AccountsPayableListToolbar
                }}
                columns={columns}
                rows={debtsToPay}
            />
        </>
    )
}

export default AccountsPayableList