import Button from "@component/Button"
import Form from "@component/Form"
import Grid from "@component/Grid"
import TextField from "@component/TextField"
import EmployeesInput from "@feature/Employee/components/EmployeesInput"
import { useValidation } from "@hook/formik"
import { useCashier, useCashierData } from "../hooks/useCashier"
import * as yup from 'yup'
import BoxPayments from "@feature/Business/components/BoxPayments"

const FormCashier = () => {
    const { cashierFormData } = useCashierData()
    const { registerNewCashier, isLoadingRegisterNewCashierMutation } = useCashier()
    const handleSubmit = (params:any) => registerNewCashier(params)

    const { formik } = useValidation({
        initialValues: cashierFormData,
        yupRules: {
            boxName: yup.string()
            .required("El nombre de caja es un campo obligatorio"),
            cashier: yup.string()
            .required("El responsable de caja es un campo obligatorio")
        },
        onSubmit: handleSubmit
    })
    return(
        <Form onSubmit={() => formik.handleSubmit()} >
            <Grid container spacing={1} >
                <Grid xs={6} >
                    <TextField
                        margin="none"
                        label="Nombre caja"
                        placeholder="Ej: Caja 1"
                        onChange={({ target }) => formik.setFieldValue('boxName', target?.value??"" )}
                        value={formik.values.boxName}
                        error={formik.touched.boxName && Boolean(formik.errors.boxName)}
                        helperText={<>{formik.touched.boxName && formik.errors.boxName}</>}
                    />
                </Grid>
                <Grid xs={6} >
                    <EmployeesInput
                        label="Responsable de caja"
                        onChange={(_, params:any) => formik.setFieldValue('cashier', params?._id??"" )}
                        error={formik.touched.cashier && Boolean(formik.errors.cashier)}
                        helperText={<>{formik.touched.cashier && formik.errors.cashier}</>}
                    />
                </Grid>
                <Grid xs={12} >
                    <BoxPayments
                        maxPayments={1}
                        onChange={(params) => {
                            let values = params.length === 1 ? params[0] : null
                            formik.setFieldValue('box', values?.box??"")
                            formik.setFieldValue('amount', values?.payment??0)
                        }}
                    />
                </Grid>
                <Grid xs={12} >
                    <Button loading={isLoadingRegisterNewCashierMutation} disableElevation type="submit" >
                        Crear caja
                    </Button>
                </Grid>
            </Grid>
        </Form>
    )
}

export default FormCashier