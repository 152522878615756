import React from 'react'
import { useGetCustomerBranchQuery } from "../../../api/querys/Customer"
import Autocomplete from "@component/Autocomplete"
import type { CustomerBusinessTypesData } from "@interface/Customer"

interface Props {
    customerBranchId: string
    onChange?: (event:any, value: any | null, name: string | undefined) => void
    fullWidth?: boolean
}

const CustomerBranchInput = ({ customerBranchId, ...rest }:Props) => {
    const { data, isSuccess, isLoading } = useGetCustomerBranchQuery()
    const customerBranch = data?.payload?.customerBranch?.results??[]
    const loadingData = isSuccess && !isLoading
    const MemoizeValue = React.useMemo(() => {
        let result:Partial<CustomerBusinessTypesData> | null = null
        if(loadingData){
            if( customerBranchId !== undefined && Boolean(customerBranchId) ){
                const existCustomerBusinessType = customerBranch.find(n => n._id === customerBranchId)!
                if( Boolean(existCustomerBusinessType) ){
                    result = existCustomerBusinessType
                }
            }
        }
        return result
    },[customerBranchId, loadingData, customerBranch])
    return(
        <Autocomplete
            name='branch'
            label="Sucursal"
            options={customerBranch}
            loading={!loadingData}
            value={MemoizeValue||null}
            {...rest}
        />
    )
}

export default CustomerBranchInput