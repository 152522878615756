import Card from "@component/Card"
import Divider from "@component/Divider"
import InformativeList from "@component/InformativeList"
import Typography from "@component/Typography"
import { useAppSignalSelector } from "@hook/state"
import { Boxes } from "@interface/App"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useGetBox, useGetBoxMovements } from "../hooks/useBox"

interface Props {
    box: Boxes
}

const AbstractBox = ({ box }:Props) => {
    const { bankId } = useParams()
    const { BusinessBoxState } = useAppSignalSelector()
    const { filterDateRange } = BusinessBoxState.value
    const { startDate, endDate } = filterDateRange
    const { box:boxBalance } = useGetBox({ box, bank: bankId })
    const { movements } = useGetBoxMovements({ box, endDate, startDate, bank: bankId })
    const { t } = useTranslation()
    const BoxArr:Boxes[] = ['minorBox', 'masterBox', 'bank', 'largeBox']
    const incomingTranslates = movements.reduce((acc, params) => ((params.transfer === box)) ? (acc+(params?.amount??0)) : (acc+0),0)
    const outgoingTranslates = movements.reduce((acc, params) => ((params.from === box) && ( BoxArr.some((n) => n === params.transfer))) ? (acc+(params?.amount??0)) : (acc+0),0)
    const purchases = movements.reduce((acc, params) => ((params.from === box) && ( ['paymentPurchase', 'advanceSupplier'].some((n) => n === params.transfer) )) ? (acc+(params?.amount??0)) : (acc+0),0)
    const expenses = movements.reduce((acc, params) => ((params.from === box) && ( ['paymentExpenseFixed', 'cashExpense'].some((n) => n === params.transfer) )) ? (acc+(params?.amount??0)) : (acc+0),0)

    return(
        <>
            <Typography textTransform={'uppercase'} fontWeight={'bold'} >
                { t(box) } {Boolean(boxBalance?.bank) ? `- ${boxBalance.bank}` : "" }
            </Typography>
            <Divider sx={{ mb: 1 }} />
            <Card sx={{ mb: 1 }} >
                <InformativeList
                    data={[
                        { primaryText:`Traslados entrantes`, secondaryText: ``, value: (incomingTranslates) },
                    ]}
                />
                <InformativeList
                    isAnExpenses
                    data={[
                        { primaryText:`Traslados salientes`, secondaryText: ``, value: (outgoingTranslates) },
                        { primaryText:`Pagos a compras`, secondaryText: ``, value: (purchases) },
                        { primaryText:`Gastos`, secondaryText: ``, value: (expenses) },
                    ]}
                />
                <InformativeList
                    data={[
                        { primaryText:`Caja disponible`, secondaryText: `Actualizada el: ${dayjs(boxBalance?.updatedAt??"").format("DD-MM-YYYY HH:mm A")}`, value: (boxBalance?.amount??0) },
                    ]}
                />
            </Card>
        </>
    )
}

export default AbstractBox