/* eslint-disable no-useless-escape */
import { Buffer } from 'buffer';
import { v4 as uuid } from 'uuid';
import { domain } from '../api/splitApi';

export const encode = (item: object) => {
  let data = JSON.stringify(item);
  return Buffer.from(data).toString('base64');
};

export const decode = (encode: string) => {
  let data = Buffer.from(encode, 'base64').toString('ascii');
  return JSON.parse(data);
};

export const readCookie = (name: string) => {
  return (
    decodeURIComponent(
      document.cookie.replace(
        new RegExp(
          "(?:(?:^|.*;)\\s*" +
            name.replace(/[\-\.\+\*]/g, "\\$&") +
            "\\s*\\=\\s*([^;]*).*$)|^.*$"
        ),
        "$1"
      )
    ) || null
  );
};
export const writeCookie = ({key, value, expire}:{key:string, value:any, expire?:string | Date }) => {
  document.cookie = `${key}=${value};expires=${expire};`
}
export const deleteCookie = (name:string) => {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export const transformIntoFormData = (data:any, stringify?:Array<string>, isArray?:boolean) => {
  const formData = new FormData();

  if( !Boolean(isArray) ){
    for (let [key, value] of Object.entries(data)) {
      if( stringify !== undefined ){
        const includeKey = stringify.includes(key)
        if( includeKey ){
          value = JSON.stringify(value);
        }
      }
      if (key === 'prices') {
        value = JSON.stringify(value);
      }
      formData.append(key, value as any);
    }
    for (let [key, value] of Object.entries(data)) {
      if(key === "image" || key === "images"){
        if( Array.isArray(value) && value.length >= 1 ){
          formData.delete(key)
          value.map(n => formData.append(key, n as any))
        }
      }
    }
  }else{
    for( let index in data ){
      const params = data[index]
      for (let [key, value] of Object.entries(params)) {
        if( stringify !== undefined ){
          const includeKey = stringify.includes(key)
          if( includeKey ){
            value = JSON.stringify(value);
          }
        }
        if (key === 'prices') {
          value = JSON.stringify(value);
        }
        formData.append(key, value as any);
      }
      for (let [key, value] of Object.entries(params)) {
        if(key === "image" || key === "images"){
          if( Array.isArray(value) && value.length >= 1 ){
            formData.delete(key)
            value.map(n => formData.append(key, n as any))
          }
        }
      }
    }
  }

  return formData;
};

export const transformArrayToString = (array: Array<any>, field = '_id') => {
  const getIds = array?.map((props) => props[field]);
  const listOfIds = getIds?.toString();
  return listOfIds;
};

export const genUUID = () => {
  const hash = uuid()
  return hash
}

export const addAsteriskSymbol = ({accountNumber, numbersVisible=4}:{accountNumber:number|string, numbersVisible?:number}): string => {
  const numberToString=accountNumber.toString()
  
  const accountLength = numberToString.length
  const lastNumbersRest = accountLength - numbersVisible

  const lastNumber = numberToString.substring(lastNumbersRest, accountLength)
  const numbers = numberToString.substring(0, lastNumbersRest)
  const addSymbol = numbers.replace(/\d/g, "*")

  return addSymbol.concat(lastNumber)
}

export const imagePath = (route:string) => {
  const publicPathSplit = route.split(/(?:\\|\/)+/)
  const publicPathSplitWithoutPublic = publicPathSplit.slice(1, publicPathSplit.length)
  const path = publicPathSplitWithoutPublic.join("/")
  return `${domain}/${path}`
}
export const imageName = (route:string) => route.split(/(?:\\|\/)+/)[route.split(/(?:\\|\/)+/).length-1]