import { useTranslation } from "react-i18next"
import Box from "../../../../../components/Box"
import Typography from "../../../../../components/Typography"
import { useAppSelector } from "../../../../../hooks/state"
import { calcProfitPercentage, currencyFormat } from "../../../../../hooks/useNumberFormat"
import { ListProductsInvoice } from "@interface/PointOfSale"

interface Props {
    pricePosition: number
    typePrice: ListProductsInvoice['typePrice']
    manualPrice: number
}

const WindowProductPOSInformationSale = ({ manualPrice, pricePosition, typePrice }:Props) => {
    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }))
    const { productSelected,  } = PointOfSaleState.windowProduct
    const product = {...productSelected}
    const { t } = useTranslation()
    return(
        <Typography lineHeight={"1.2"} fontWeight={'bold'} >
            {typePrice === "sellPrice" &&
                <>
                    {t('price')} {pricePosition+1}: $ { currencyFormat(product?.prices?.[pricePosition]?.sellPrice??0) } - {product?.prices?.[pricePosition]?.percentageProfit??1}% <br />
                </>
            }
            {typePrice === "manual" &&
                <>
                    {t('manualPrice')}: $ {currencyFormat(manualPrice)} - { calcProfitPercentage((manualPrice-product.cost!), manualPrice) } % <br />
                </>
            }
            Stock: { currencyFormat(product?.stock??0, true) }
                {product.compount &&
                    <Box component={'span'} fontSize={'.9rem'} ml={1} >
                        {product?.packaging?.name??""}
                    </Box>
                }
        </Typography>
    )
}

export default WindowProductPOSInformationSale