import React from "react"
import { useTranslation } from "react-i18next"
import Button from "@component/Button"
import Form from "@component/Form"
import Grid from "@component/Grid"
import NumberFormat from "@component/NumberFormat"
import TextField from "@component/TextField"
import type { BusinessFormProps } from "@interface/Business"
import { useBusiness } from "../hooks/useBusiness"

const form:BusinessFormProps = {
    name: "",
    nit: "",
    address: "",
    email: "",
    phone: ""
}

interface Props {
    businessEdit?: BusinessFormProps
}

const FormBusiness = ({ businessEdit }:Props) => {
    const [ formBusiness, setFormBusiness ] = React.useState<BusinessFormProps>({...form})
    const { t } = useTranslation()
    const { registerBusiness, isLoadingRegisterBusiness, isLoadingUpdateBusiness } = useBusiness()
    React.useEffect(() => {
        if( businessEdit !== undefined ){
            setFormBusiness({...businessEdit})
        }
    },[businessEdit])
    const handleSubmit = () => registerBusiness(formBusiness)
    return(
        <Form onSubmit={handleSubmit} >
            <Grid container spacing={1} >
                <Grid xs={6} >
                    <TextField required value={formBusiness.name} onChange={({ target }) => setFormBusiness(n => ({...n, name: target.value }))} margin="none" label={t('name')!} />            
                </Grid>
                <Grid xs={6} >
                    <TextField value={formBusiness.email} onChange={({ target }) => setFormBusiness(n => ({...n, email: target.value }))} margin="none" label={t('email')!}  />
                </Grid>
                <Grid xs={6} >
                    <NumberFormat thousandSeparator="" value={formBusiness.phone} onChange={({ target }) => setFormBusiness(n => ({...n, phone: target.value }))} label={t('phoneNumber')!} />
                </Grid>
                <Grid xs={6} >
                    <TextField placeholder="Ej. NIT-XXXXXX" value={formBusiness.nit} onChange={({ target }) => setFormBusiness(n => ({...n, nit: target.value }))} margin="none" label={t('Id')} />
                </Grid>
                <Grid xs={12} >
                    <TextField value={formBusiness.address} onChange={({ target }) => setFormBusiness(n => ({...n, address: target.value }))} margin="none" label={t('address')} multiline rows={3} />
                </Grid>
                <Grid xs={12} >
                    <Button loading={isLoadingRegisterBusiness || isLoadingUpdateBusiness} type="submit" > {formBusiness._id ? t('save') : t('createBusiness') }  </Button>
                </Grid>
            </Grid>
        </Form>
    )
}

export default FormBusiness