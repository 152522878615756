import React from 'react'
import Box from "@component/Box"
import Card from "@component/Card"
import Typography from "@component/Typography"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { calcProfitPercentage, currencyFormat } from '../../../hooks/useNumberFormat'
import { updateInvoiceTotal } from '../services/PointOfSaleSlice'

const PointOfSaleTotalize = () => {
    const dispatch = useAppDispatch()
    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }))
    const { productList } = PointOfSaleState

    const sale = React.useMemo(() => {
        let totalize:{total: number, profit: number, margin: number | string} = {
            total: 0,
            profit: 0,
            margin: 0
        }
        for( let product of productList ){
            totalize.total += (product.salePrice*product.quantity)
            totalize.profit += ((product.salePrice*product.quantity)-(product.costPrice*product.quantity))
        }
        totalize.margin = calcProfitPercentage(totalize.profit, totalize.total, true)
        return totalize
    },[productList])

    const { total, profit, margin } = sale

    React.useEffect(() => {
        dispatch(updateInvoiceTotal({ total: sale.total, totalProfitCurrency: sale.profit, totalProfitPercentage: sale.margin }))
    },[sale, dispatch])

    return(
        <Card sx={{ marginBottom: 3 }} >
            <Box p={1} display={'flex'} flexDirection={'column'} >
                <Typography fontSize={'2.5rem'} fontWeight={'bold'} lineHeight={1} > $ {currencyFormat(total)} </Typography>
                <Box display={'flex'} >
                    <Typography mr={2} fontWeight={'500'} color={'GrayText'} > $ {currencyFormat(profit)} </Typography>
                    <Typography fontWeight={'500'} color={'GrayText'} > {Number.isNaN(margin) === true ? 0 : margin} %</Typography>
                </Box>
            </Box>
        </Card>
    )
}

export default PointOfSaleTotalize