import React from "react";
import { useTranslation } from "react-i18next"
import Box from "@component/Box";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import Table, { TableBody, TableCell, TableHead, TableRow } from "@component/Table";
import { currencyFormat } from "@hook/useNumberFormat";
import { RecordPurchasingDataResponse } from "@interface/Purchasing";
import { usePurchasingTools } from "@feature/PurchasingSuppliers/hooks/usePurchasing";
import Card from "@component/Card";

const TableCellCustomer = styled(TableCell)(({theme}) => ({
    lineHeight: '0.43',
    borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
    fontSize: '.875rem',
    fontWeight: 'bold'
}))

const TableCellProduct = styled(TableCell)(({theme}) => ({
    lineHeight: '0.43',
    borderBottom: "1px solid #6c6c6c",
    fontSize: '.875rem'
}))

interface Props extends RecordPurchasingDataResponse {

}

const TemplateReportInvoice = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { t } = useTranslation()
    const { createdAt, consecutive, provider, dueDate, dateReceived, billingDate, typePayment, products, user, subTotal, total, retention, tax, invoiceNumber } = props
    const singleProducts = products?.filter(n => n.typeProduct === "single")
    const { calcProductIVA } = usePurchasingTools()
    return(
        <Box ref={ref} sx={{width: 1, padding: 1.5 }} >
            <Card sx={{ p: 2 }} >
                <Box display={'flex'} justifyContent="space-between" alignItems={'center'} >
                    <Box fontSize={'0.8rem'} fontWeight="500" >
                        <Box fontWeight="bold" >
                            {t('dateAndTime')}
                        </Box>
                        {dayjs(createdAt??"").format("DD-MM-YYYY HH:mm A")}
                    </Box>
                    <Box textAlign={'center'} >
                        <Box fontSize={'1rem'} fontWeight="bold" >
                        </Box>
                        <Box fontSize={'0.8rem'} fontWeight="bold" >
                        </Box>
                        <Box fontSize={'0.8rem'} fontWeight="bold" >
                        </Box>
                    </Box>
                    <Box display='inline-flex' flexDirection={'column'} >
                        <Box fontWeight={'bold'} border={1} borderRadius={5} borderColor='grey.500' p={1} height="fit-content" fontSize={'.8rem'} >
                            #C{consecutive??0}
                        </Box>
                    </Box>
                </Box>
                <Box display={'flex'} >
                    <Box width={'auto'} >
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCellCustomer>{t('provider')}:</TableCellCustomer>
                                    <TableCellCustomer align="left"> {provider?.name??""} </TableCellCustomer>
                                </TableRow>
                                <TableRow>
                                    <TableCellCustomer>{t('Numero de factura')}:</TableCellCustomer>
                                    <TableCellCustomer align="left"> {invoiceNumber??""} </TableCellCustomer>
                                </TableRow>
                                <TableRow>
                                    <TableCellCustomer>{t('Fecha de facturación')}:</TableCellCustomer>
                                    <TableCellCustomer align="left"> { dayjs(billingDate??"").format("DD-MM-YYYY") } </TableCellCustomer>
                                </TableRow>
                                <TableRow>
                                    <TableCellCustomer>{t('Fecha de recibido')}:</TableCellCustomer>
                                    <TableCellCustomer align="left"> {dayjs(dateReceived??"").format("DD-MM-YYYY")} </TableCellCustomer>
                                </TableRow>
                                {typePayment === "credit" &&
                                    <TableRow>
                                        <TableCellCustomer>{t('Fecha de vencimiento')}:</TableCellCustomer>
                                        <TableCellCustomer align="left"> {dayjs(dueDate??"").format("DD-MM-YYYY")} </TableCellCustomer>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
                <Table size="small" >
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('quantity')}</TableCell>
                            <TableCell>{t('product')}</TableCell>
                            <TableCell>{t('unitValue')}</TableCell>
                            <TableCell>{t('IVA')}</TableCell>
                            <TableCell>{t('total')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { singleProducts?.map((product, index) => (
                            <TableRow key={index} >
                                <TableCellProduct  align="center">x{product?.quantity??''}</TableCellProduct>
                                <TableCellProduct >{product?.name??''}</TableCellProduct>
                                <TableCellProduct >$ {currencyFormat(product?.purchaseCost??0)}</TableCellProduct>
                                <TableCellProduct >$ {currencyFormat( calcProductIVA({ cost: (product?.purchaseCost??0)*(product?.quantity??0), iva: (product?.tax?.tax??0) }) )}</TableCellProduct>
                                <TableCellProduct  align="right" >$ {currencyFormat(((product?.quantity??0)*(product?.purchaseCost??0)))}</TableCellProduct>
                            </TableRow>
                        )) }
                    </TableBody>
                </Table>
                <Box display={'flex'} justifyContent={'end'} >
                    <Box width={'auto'} >
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCellProduct >{t('subtotal')}</TableCellProduct>
                                    <TableCellProduct align="right"> $ {currencyFormat(subTotal)} </TableCellProduct>
                                </TableRow>
                                <TableRow>
                                    <TableCellProduct sx={{ fontWeight: 'bold' }} >{t('IVA')}</TableCellProduct>
                                    <TableCellProduct sx={{ fontWeight: 'bold' }} align="right"> $ {currencyFormat(tax)} </TableCellProduct>
                                </TableRow>
                                <TableRow>
                                    <TableCellProduct sx={{ fontWeight: 'bold' }} >{t('total')}</TableCellProduct>
                                    <TableCellProduct sx={{ fontWeight: 'bold' }} align="right"> $ {currencyFormat(subTotal+tax)} </TableCellProduct>
                                </TableRow>
                                <TableRow>
                                    <TableCellProduct sx={{ fontWeight: 'bold' }} >{t('Retención')}</TableCellProduct>
                                    <TableCellProduct sx={{ fontWeight: 'bold' }} align="right"> $ {currencyFormat(retention)} </TableCellProduct>
                                </TableRow>
                                <TableRow>
                                    <TableCellProduct sx={{ fontWeight: 'bold' }} >{t('total')}</TableCellProduct>
                                    <TableCellProduct sx={{ fontWeight: 'bold' }} align="right"> $ {currencyFormat(total)} </TableCellProduct>
                                </TableRow>
                                <TableRow>
                                    <TableCell >{t('Registrado por')}</TableCell>
                                    <TableCell align="right"> {user?.name??""}  {user?.lastName??""} </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
            </Card>
        </Box>
    )
})

export default TemplateReportInvoice