import Button from "@component/Button"
import Dialog, { DialogContent } from "@component/Dialog"
import Form from "@component/Form"
import Grid from "@component/Grid"
import NumberFormat from "@component/NumberFormat"
import { useAppSignalSelector } from "@hook/state"
import { useCashierMovement } from "../hooks/useMovementsCashier"
import Swal from "sweetalert2"
import { currencyFormat } from "@hook/useNumberFormat"

const TransferToPettyCash = () => {
    const { MovementsCashierState } = useAppSignalSelector()
    const { summaryCashier, amountToWithdraw } = MovementsCashierState.value
    const handleClose = () => MovementsCashierState.value = {...MovementsCashierState.value, summaryCashier: null}
    const { isLoadingTransferToPettyCashMutation, transferToPettyCash } = useCashierMovement()
    const handleSubmit = () => {
        if( summaryCashier?.inCash !== undefined ){
            if( amountToWithdraw > summaryCashier.inCash ){
                Swal.fire({
                    icon: 'warning',
                    text: `No es posible trasladar $ ${currencyFormat(amountToWithdraw)}, solo tiene disponible $ ${currencyFormat(summaryCashier.inCash)} en la caja.`,
                })
                return
            }
        }
        transferToPettyCash()
    }
    const handleChange = (value:number) => MovementsCashierState.value = {...MovementsCashierState.value, amountToWithdraw: value}
    return(
        <Dialog fullWidth maxWidth="xs" open={Boolean(summaryCashier)} onClose={handleClose} >
            <DialogContent>
                <Form onSubmit={handleSubmit} >
                    <Grid container >
                        <Grid xs={12} >
                            <NumberFormat
                                maxVal={(summaryCashier?.inCash??0)}
                                label="Monto"
                                value={amountToWithdraw}
                                onChange={({ target }) => handleChange( (Number(target.value??0)) )}
                            />
                        </Grid>
                        <Grid xs={12} >
                            <Button type="submit" loading={isLoadingTransferToPettyCashMutation} disableElevation >
                                Transferir
                            </Button>
                        </Grid>
                    </Grid>

                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default TransferToPettyCash