import { useGetSessionDataQuery } from "../../../api/querys/Users"
import Switch from "@component/Switch"
import { UserSessionDataResponse } from "@interface/User"
import { useBusiness } from "../hooks/useBusiness"

const SwitchInventoryByLot = () => {
    const { isLoadingUpdateBatchInventoryBusinessSettings, updateBatchInventoryBusinessSettings } = useBusiness()
    const { data, isLoading } = useGetSessionDataQuery()
    const business = data?.payload?.business??{} as UserSessionDataResponse['payload']['business']

    return(
        <>
            <Switch
                onChange={() => updateBatchInventoryBusinessSettings()}
                disabled={isLoading || isLoadingUpdateBatchInventoryBusinessSettings}
                checked={business?.setting?.batchInventory??false}
                label=""
            />
        </>
    )
}

export default SwitchInventoryByLot