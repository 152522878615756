import { DateRangeFilter } from "@interface/App";
import type { DebtsToPayResponse, HistoryOfPaidAccountsAsyncResponse, PayAccountPayableAsyncResponse, PayAccountPayableProps, PayAllAccountPayableAsyncResponse, PayAllAccountPayableProps } from "../../interfaces/DebtsToPay"
import { api, mutationConfig } from "../splitApi"

const DebtsToPay = api.injectEndpoints({
    endpoints: (build) => ({
        getDebtsToPay: build.query<DebtsToPayResponse, {only?: "balance", show?: "results"}>({
            query: ({ only, show }) => ({
                url: '/get/debts/to/pay',
                params: { only, show }
            }),
            providesTags: ['AccountsPayable']
        }),
        getHistoryOfPaidAccounts: build.query<HistoryOfPaidAccountsAsyncResponse, DateRangeFilter>({
            query: ({ startDate, endDate }) => ({
                url: '/get/history/of/paid/accounts',
                params: { startDate, endDate }
            }),
            providesTags: ['AccountsPayable']
        }),
        recordPaymentToAccountPayable: build.mutation<PayAccountPayableAsyncResponse, PayAccountPayableProps>({
            query(body) {
                return { ...mutationConfig, url: "/record/payment/to/account/payable", body };
            },
            invalidatesTags: ['AccountsPayable', 'MultiBox', 'Expense', 'MinorBox', 'BusinessFinancialStatement']
        }),
        payTheEntireBill: build.mutation<PayAllAccountPayableAsyncResponse, PayAllAccountPayableProps>({
            query(body) {
                return { ...mutationConfig, url: "/pay/the/entire/bill", body };
            },
            invalidatesTags: ['AccountsPayable', 'MultiBox', 'Expense', 'MinorBox', 'BusinessFinancialStatement']
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetDebtsToPayQuery,
    useRecordPaymentToAccountPayableMutation,
    useGetHistoryOfPaidAccountsQuery,
    usePayTheEntireBillMutation,
} = DebtsToPay