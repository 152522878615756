import Box from "@component/Box"
import IconButton from "@component/IconButton"
import InputSearchBank from "./InputSearchBank";
import AddRounded from '@mui/icons-material/AddRounded';
import Tooltip from "@component/Tooltip";
import { useTranslation } from "react-i18next";
import { openFormCreateBanks } from "../services/BankSlice";
import { useAppDispatch } from "../../../hooks/state";
import Routes from "../../../services/routes";
import { useNavigate } from "react-router-dom";

const BankNavbar = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <Box display={'flex'} alignItems={'center'} >
            <InputSearchBank onChange={(_, n: any) => navigate(Routes.indexBank(n._id))} />
            <Box ml={1} >
                <Tooltip title={t('createNewBank')!} >
                    <IconButton squareButton onClick={() => dispatch(openFormCreateBanks(true))} >
                        <AddRounded />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    )
}

export default BankNavbar