import Fade from "@component/Fade"
import AccountsPayableList from "./components/AccountsPayableList"
import Grid from "@component/Grid"
import CreateFixedExpense from "@feature/FixedCosts/components/CreateFixedExpense"
import AccountsPayablePreview from "./components/AccountsPayablePreview"
import Tab from "@component/Tab"
import HistoryOfPaidAccounts from "./components/HistoryOfPaidAccounts"
import PayTheEntireBill from "./components/PayTheEntireBill"
import Title from "@component/Title"

const DebtsToPay = () => {
    return(
        <Fade in={true} >
            <Grid container >
                <Grid xs={12} >
                    <Title text="Cuentas por pagar" />
                </Grid>
                <Grid xs={12} >
                    <Tab
                        TabItem={["Cuentas por pagar", "Historial de pagos"]}
                        TabRender={[<AccountsPayableList />, <HistoryOfPaidAccounts />]}
                    />
                </Grid>
                <CreateFixedExpense />
                <AccountsPayablePreview />
                <PayTheEntireBill />
            </Grid>
        </Fade>
    )
}

export default DebtsToPay