import React from 'react'
import Autocomplete from "@component/Autocomplete";
import Grid from "@component/Grid";
import { currencyFormat } from "@hook/useNumberFormat";
import { createFilterOptions } from "@mui/material";
import { useGetFixedExpenses } from "../hooks/useFixedCosts";

type AutocompleteProps = Pick<React.ComponentProps<typeof Autocomplete>, "size">

interface Props extends AutocompleteProps {
    onChange?: (params:any) => void
    expenseId?: string
}

const ExpensesInput = ({ onChange, expenseId }:Props) => {
    const [value, setValue] = React.useState<any>(null)
    const data = useGetFixedExpenses({ groupBy: null })
    const payload = data[0]?.results??[]
    const filterOptions = createFilterOptions({
        stringify: (option:any) => `${option.name} ${option.tags.map((n:any) => n.name).join(" ")} ${option.total}`,
    });

    const handleChange = (params:any) => {
        setValue(params)
        if( onChange !== undefined ){
            onChange(params)
        }
    }

    React.useEffect(() => {
        if( expenseId !== undefined ){
            setValue( payload.find((n:any) => n._id === expenseId ) || null )
        }
    },[expenseId])
    return(
        <>
            <Autocomplete
                value={value}
                onChange={(_, params) => handleChange(params)}
                filterOptions={filterOptions}
                label="Gasto"
                options={payload}
                renderOption={(props, params: any, index) => {
                    return (
                        <li {...props} key={`${index}-${params?._id ?? ''}`} >
                            <Grid container>
                                <Grid xs={12}>{params?.name ?? ''}</Grid>
                                <Grid xs={12}>{ `$ ${currencyFormat(params?.total ?? 0)}` }</Grid>
                            </Grid>
                        </li>
                    );
                }}
            />
        </>
    )
}

export default ExpensesInput