import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { LoginForm, UserFormRegister } from '../../../interfaces/User'
import { deleteToken, getQuickLogin, setQuickLogin, setToken } from '../../../services/localStorage'
import { decode, encode } from '../../../services/parseData'

interface stateProps {
    authenticationForm: "registration" | "login"
    registerForm: UserFormRegister
    loginForm: LoginForm,
    registerFormFieldError:{
        name?: boolean
        lastName?: boolean
        email?: boolean
        password?: boolean
        repeatPassword?: boolean
    }
    loginFormFieldError:{
        email?: boolean
        password?: boolean
    }
}

const registerForm = {
    _id: 0,
    name: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    repeatPassword: ""
}

const loginForm = {
    email: "",
    password: ""
}

const initialState:stateProps = {
    authenticationForm: "login",
    registerForm:{...registerForm},
    loginForm:{...loginForm},
    registerFormFieldError:{},
    loginFormFieldError:{}
}

export const AuthenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        clearLoginForm: (state) => {
            state.loginForm = {...loginForm}
        },
        clearRegistrationForm: (state) => {
            state.registerForm = {...registerForm}
        },
        loadAccountOnQuickLogin: (_,action:PayloadAction<{name: string, email: string, password: string}>) => {
            const quickLoginList = JSON.parse(getQuickLogin()??"[]")
            if( quickLoginList.length <= 0 ){
                const encodeData = encode(action.payload)
                const data = JSON.stringify([encodeData])
                setQuickLogin(data)
            }else{
                const encodeData = encode(action.payload)
                const accountData = quickLoginList.filter((n:any) => decode(n).email === action.payload.email)
                if( accountData.length <= 0 ){
                    quickLoginList.push(encodeData)
                    setQuickLogin(JSON.stringify(quickLoginList))
                }
            }
        },
        toggleFormAuthentication: (state, action:PayloadAction<stateProps['authenticationForm']>) => {
            state.authenticationForm = action.payload
        },
        updateErrorsInLoginFormFields: (state, action:PayloadAction<stateProps['loginFormFieldError']>) => {
            state.loginFormFieldError = action.payload
        },
        updateErrorsInRegistrationFormFields: (state, action:PayloadAction<stateProps['registerFormFieldError']>) => {
            state.registerFormFieldError = action.payload
        },
        updateUserLoginForm: (state, action:PayloadAction<LoginForm>) => {
            state.loginForm = action.payload
        },
        updateUserRegistrationForm: (state, action:PayloadAction<UserFormRegister>) => {
            state.registerForm = action.payload
        },
        
        loadToken: (_, action:PayloadAction<string>) => {
            setToken(action.payload)
            window.location.reload()
        },
        signOff: () => {
            deleteToken()
            window.location.reload()
        },
    }
})

export const {
    clearLoginForm,
    clearRegistrationForm,
    loadAccountOnQuickLogin,
    toggleFormAuthentication,
    updateErrorsInLoginFormFields,
    updateErrorsInRegistrationFormFields,
    updateUserLoginForm,
    updateUserRegistrationForm,
    loadToken,
    signOff,
} = AuthenticationSlice.actions
export default AuthenticationSlice.reducer