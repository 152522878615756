import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../hooks/state";
import { showSecurityAlertClearZone } from "./../services/ZoneSlice";

import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@component/Dialog";
import Button from "@component/Button";
import { useCustomerZones } from "../hooks/useCustomerZones";

export const AlertDeleteZones = () => {
  const { t } = useTranslation();

  const { isLoading, handleDeleteZones } = useCustomerZones();

  const { securityAlertClearZone, selectionToDelete } = useAppSelector(
    ({ ZoneState }) => ZoneState
  );
  const dispatch = useAppDispatch();

  return (
    <>
      <Dialog
        open={securityAlertClearZone}
        onClose={() => dispatch(showSecurityAlertClearZone(false))}
      >
        <DialogTitle>{t("deleteZones")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿{t("areYouSureYouWantToPerformThisAction")}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="error"
            loading={isLoading}
            onClick={() => handleDeleteZones(selectionToDelete)}
          >
            {t("remove")}
          </Button>
          <Button
            variant="text"
            onClick={() => dispatch(showSecurityAlertClearZone(false))}
            loading={isLoading}
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
