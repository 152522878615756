import Grid from '../../components/Grid';
import CreateBusinessesType from './components/CreateBusinessesType';
import BusinessesTypeList from './components/BusinessesTypeList';
import BusinessesTypeSearchBar from './components/BusinessesTypeSearchBar';
import { useBusinessPermissions } from '../../hooks/usePermissions';
import LockComponent from '../../components/LockComponent';
import Title from '@component/Title';

const CustomerBusinessesType = () => {
    const { Customers } = useBusinessPermissions()
    return (
        <>
            {Customers.filters ?
                <Grid container spacing={1} >
                    <Grid xs={12}>
                        <Title text='Tipo de negocio de clientes' />
                    </Grid>
                    <Grid xs={12}>
                        <BusinessesTypeSearchBar />
                    </Grid>
                    <Grid xs={12}><BusinessesTypeList /></Grid>
                    <CreateBusinessesType />
                </Grid>
                :
                <LockComponent />
            }
        </>
    );
};

export default CustomerBusinessesType;
