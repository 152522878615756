import { CardProps } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from "@component/Box"
import Card from "@component/Card"
import Divider from "@component/Divider"
import LockComponent from '../../../components/LockComponent';
import Typography from "@component/Typography"
import { useAppSelector } from '../../../hooks/state';
import { currencyFormat } from '../../../hooks/useNumberFormat';
import { useBusinessPermissions } from '../../../hooks/usePermissions';
import { InvoiceProps } from '@interface/PointOfSale'
import AddRounded from '@mui/icons-material/AddRounded';
import RemoveRounded from '@mui/icons-material/RemoveRounded';

interface Props {
    sx?: CardProps['sx']
    creditNote: InvoiceProps['creditNote']
}

const TemplateCreditNote = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { AppState } = useAppSelector(({ AppState }) => ({ AppState }))
    const { invoiceAlphabeticalOrder } = AppState
    const { Invoice } = useBusinessPermissions()
    let { creditNote, sx } = props

    const { t } = useTranslation()
    // products = products??[] as InvoiceProps['products']
    // const productsAlphabeticalOrder = [...products].sort((a,b) => a.name.localeCompare(b.name))
    // const details = invoiceAlphabeticalOrder === "default" ? products : productsAlphabeticalOrder
    return(
        <>
            {creditNote?.map((n, index) => {
                return(
                    <Card key={index} ref={ref} sx={{ width: "100%", height: "max-content", padding: 1.5, ...sx }} >
                        <>
                            <Box>
                                <Typography lineHeight={1.2} fontWeight={'bold'} fontSize={"1.2rem"} textAlign={'center'} > NOTA CRÉDITO </Typography>
                            </Box>
                            <Box mt={1} display="flex" justifyContent={'space-between'} >
                                <Typography lineHeight={1.2} fontSize={".7rem"} fontWeight={'500'} > #NC: {n?.consecutive} </Typography>
                                <Typography lineHeight={1.2} fontSize={".7rem"} >{t('date')}:  {dayjs(n?.createdAt).format("DD-MM-YYYY HH:mm:ss")} </Typography>
                            </Box>
                            <Divider />

                            <Divider />
                            {(n?.products !== undefined) &&
                                n?.products?.map((product, index) => {
                                    return(
                                        <Box key={index} >
                                            <Box my={"2px"} display={'flex'} flexDirection={'column'} >
                                                <Typography lineHeight={1.2} fontSize={".8rem"} fontWeight='bold' > {product?.name??""} </Typography>
                                                <Box display={'flex'} justifyContent={'space-between'} >
                                                    <Box display={'flex'} >
                                                        {product.variant === "outStock" ? 
                                                            <AddRounded sx={{ color: (theme) => theme.palette.success.main , fontSize: '1rem', fontWeight: 'bold' }} />
                                                            :
                                                            <RemoveRounded sx={{ color: (theme) => theme.palette.error.main , fontSize: '1rem', fontWeight: 'bold' }} />
                                                        }
                                                        <Typography mr={1} lineHeight={1.2} fontSize={".8rem"} > x{product?.quantity??0} </Typography>
                                                        <Typography lineHeight={1.2} fontSize={".8rem"} > $ { currencyFormat(product?.salePrice??0) } </Typography>
                                                    </Box>
                                                    <Typography lineHeight={1.2} fontSize={".8rem"} > $ {currencyFormat(product.salePrice*product.quantity)} </Typography>
                                                </Box>
                                            </Box>
                                            <Divider
                                                sx={{
                                                    borderColor: "#00000078"
                                                }}
                                            />
                                        </Box>
                                    )
                                })
                            }
                            <Box my={1} >
                                <Typography fontWeight={'bold'} lineHeight={1.2} fontSize={".8rem"} textTransform={'uppercase'} >{t('Nota')}: {n?.note??""} </Typography>
                            </Box>
                            <Box my={1} >
                                <Typography sx={{ color: (theme) => n?.moneyBack === 0 ? theme.palette.success.main : theme.palette.error.main }} textAlign={'right'} lineHeight={1.2} fontSize={"1rem"} textTransform={'uppercase'} fontWeight={'bold'} >{t('Total')}: $ { n?.moneyBack === 0 ? currencyFormat(n?.moneyIncome??0) : currencyFormat(n?.moneyBack??0) } </Typography>
                            </Box>
                        </>
                    </Card>
                )

            })}
        </>
    )
})

export default TemplateCreditNote