import AddRounded from '@mui/icons-material/AddRounded';

import { useTranslation } from 'react-i18next';

import Box from '../../../components/Box';
import Tooltip from '../../../components/Tooltip';
import IconButton from '../../../components/IconButton';
import CustomerNeighborhoodsInput from '../../Customer/components/CustomerNeighborhoodsInput';
import {
  displayNeighborhoodsForm,
  loadNeighborhoodsToEdit,
} from '../services/NeighborhoodsSlice';
import { useAppDispatch } from '../../../hooks/state';
import { useGetCustomersNeighborhoodQuery } from '../../../api/querys/Customer';

const NeighborhoodsSearchBar: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data: neighborhoodsData } = useGetCustomersNeighborhoodQuery();

  const neighborhoodArray =
    neighborhoodsData?.payload??[];

  const handleOnChange = (neighborhoodId: string) => {
    const findSameNeighborhoods = neighborhoodArray.find( (props) => props?._id === neighborhoodId )!;
    dispatch(loadNeighborhoodsToEdit(findSameNeighborhoods));
  };

  const handleOpenForm = () => {
    dispatch(displayNeighborhoodsForm(true));
  };

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
    >
      <CustomerNeighborhoodsInput
        neighborhoodsId=''
        onChange={(_,result: any) => handleOnChange(result?._id ?? '')}
      />
      <Box ml={1}>
        <Tooltip title={t('createNewNeighborhood')}>
          <IconButton
            squareButton
            onClick={handleOpenForm}
          >
            <AddRounded />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default NeighborhoodsSearchBar;
