import Fade from "@component/Fade";
import Grid from "@component/Grid";
import CreateBank from "./components/CreateBank";
import BankNavbar from "./components/BankNavbar";
import BanksList from "./components/BanksList";
import { useBusinessPermissions } from "../../hooks/usePermissions";
import LockComponent from "@component/LockComponent";
import Title from "@component/Title";

const Banks = () => {
    const { Banks } = useBusinessPermissions()
    return (
        <Fade in={true}>
            {Banks.lock_module ?
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Title text="Bancos" />
                    </Grid>
                    <Grid xs={12}>
                        <BankNavbar />
                    </Grid>
                    <Grid xs={12}>
                        <BanksList />
                    </Grid>
                    <CreateBank />
                </Grid> :
                <LockComponent />
            }
        </Fade>
    );
};

export default Banks;
