import { useAppDispatch } from '@hook/state';
import { AllCashiersPropsAsyncResponse, CashierFormProps, CashierPropsAsyncResponse } from "@interface/Cashier"
import { useGetBusinessCashiersQuery, useGetCashierQuery, useRegisterNewCashierMutation } from "@query/Cashier"
import { useSnackbar } from "notistack"
import React from "react"
import { useTranslation } from "react-i18next"
import { updateCashierRecordFormValue } from "../services/CashierSlice"

export const useCashier = () => {
    const dispatch = useAppDispatch()
    const [ registerNewCashierMutation, { isLoading:isLoadingRegisterNewCashierMutation } ] = useRegisterNewCashierMutation()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const registerNewCashier = async (params:CashierFormProps) => {
        try {
            const resp = await registerNewCashierMutation(params).unwrap()
            if( resp.status === 1 ){
                dispatch(updateCashierRecordFormValue(false))
                enqueueSnackbar(t("El cajero se registro satisfactoriamente"), {variant: "success"})
            }else{
                enqueueSnackbar(t("anErrorOccurredWhileTryingToProcessTheRequest"), {variant: "error"})
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        registerNewCashier,
        isLoadingRegisterNewCashierMutation
    }

}

export const useGetBusinessCashier = () => {
    const { data, isLoading } = useGetBusinessCashiersQuery()
    const cashiers = data?.payload??[] as AllCashiersPropsAsyncResponse['payload']
    return { cashiers, isLoading }
}

export const useGetCashier = ({ cashierId }:{ cashierId: string }) => {
    const { data, isLoading } = useGetCashierQuery({ cashierId })
    const cashier = data?.payload??{} as CashierPropsAsyncResponse['payload']
    return { cashier, isLoading }
}

export const useCashierData = () => {
    const [cashierData, setCashierData] = React.useState<CashierFormProps>({ boxName: "", cashier: "", base: 0, box: "", amount: 0 })
    const cashierFormData = React.useMemo(() => cashierData, [cashierData])
    
    return { cashierFormData }
}