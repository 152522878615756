import { useAppSignalSelector } from '@hook/state';
import { useGetBoxesFromTheBusinessQuery, useGetBusinessFinancialStatementQuery, useGetListOfBoxesWithBalancesQuery, useRecordCashTransferMutation } from '@query/Business';
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useRegisterBusinessMutation, useUpdateBatchInventoryBusinessSettingsMutation, useUpdateBusinessMutation } from "../../../api/querys/Business"
import type { BoxesFromTheBusinessAsyncResponse, BusinessFinancialStatementProps, BusinessFormProps, FixedAndVariableExpensesAsyncResponse, ListOfBoxesWithBalancesResponse } from "@interface/Business"
import { useGetFixedAndVariableExpensesQuery, useGetMonthlyReportQuery } from '@query/PointOfSale';
import { SaleReportResponse } from '@interface/PointOfSale';
import { calcProfitPercentage } from '@hook/useNumberFormat';

export const useBusiness = () => {
    const [ registerBusinessMutation, { isLoading:isLoadingRegisterBusiness } ] = useRegisterBusinessMutation()
    const [ updateBusinessMutation, { isLoading:isLoadingUpdateBusiness } ] = useUpdateBusinessMutation()
    const [ updateBatchInventoryBusinessSettingsMutation, {isLoading:isLoadingUpdateBatchInventoryBusinessSettings} ] = useUpdateBatchInventoryBusinessSettingsMutation()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const registerBusiness = async (business:BusinessFormProps) => {
        try {
            if( Boolean(business._id) ){
                const resp = await updateBusinessMutation(business).unwrap()
                if( resp.status === 1 ){
                    enqueueSnackbar(t('businessDataUpdatedSuccessfully'),{variant: "success"})
                }else{
                    enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'),{ variant: "error" })
                }
            }else{
                const resp = await registerBusinessMutation(business).unwrap()
                if( resp.status === 1 ){
                    enqueueSnackbar(t('theBusinessWasRegisteredSuccessfully'),{variant: "success"})
                }else{
                    enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'),{ variant: "error" })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const updateBatchInventoryBusinessSettings = async () => {
        try {
            let resp = await updateBatchInventoryBusinessSettingsMutation().unwrap()
            if( resp.status === 1 ){
                enqueueSnackbar("Se actualizo la configuración del negocio satisfactoriamente", { variant: "success" })
            }
        } catch (error) {
            console.log(error)
        }
    }
    return {
        registerBusiness,
        isLoadingRegisterBusiness,
        isLoadingUpdateBusiness,
        isLoadingUpdateBatchInventoryBusinessSettings,
        updateBatchInventoryBusinessSettings,
    }
}

export const useCashTransfer = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { t }= useTranslation()
    const { BusinessState } = useAppSignalSelector()
    const { transferCash } = BusinessState.value
    const [ recordCashTransferMutation, { isLoading:isLoadingRecordCashTransferMutation } ] = useRecordCashTransferMutation()
    
    const recordCashTransfer = async () => {
        try {
            if( transferCash.amount <= 0 ){
                enqueueSnackbar("El monto debe ser superior a 0", { variant: "warning" })
                return
            }
            const resp = await recordCashTransferMutation(transferCash).unwrap()
            if( resp.status === 1 ){
                enqueueSnackbar("El traslado se registro satisfactoriamente", { variant: "success" })
                BusinessState.value = {...BusinessState.value, showTransferCashWindow: false, transferCash: {
                    amount: 0, bank: null, note: "", transfer: "largeBox", whoReceives: "",
                    from: BusinessState.value.transferCash.from
                }}
            }else{
                enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'),{ variant: "error" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return { recordCashTransfer, isLoadingRecordCashTransferMutation }
}

export const useBoxesBusiness = () => {
    const { data, isLoading } = useGetListOfBoxesWithBalancesQuery()
    const boxes = data?.payload??{} as ListOfBoxesWithBalancesResponse['payload']
    return {boxes, isLoading}
}

type GetBoxesFromTheBusinessProps = {
    skip?: boolean
}

export const useGetBoxesFromTheBusiness = (params?:GetBoxesFromTheBusinessProps) => {
    const skip = params?.skip??false
    const { data, isLoading } = useGetBoxesFromTheBusinessQuery(undefined,{ skip })
    const boxes = data?.payload??[] as BoxesFromTheBusinessAsyncResponse['payload']
    return { boxes, isLoading }
}

interface GetBusinessFinancialStatementProps {
    startDate: string
}

export const useGetBusinessFinancialStatement = (params:GetBusinessFinancialStatementProps) => {
    const { startDate } = params
    const { data, isLoading } = useGetBusinessFinancialStatementQuery({ startDate })
    const financialStatement = data?.payload??{} as BusinessFinancialStatementProps

    const boxes = financialStatement?.boxes?.map((n) => ({ value: (n?.total??0) }))||[]
    const fixedCosts = financialStatement?.fixedCosts?.map((n) => ({ value: (n?.total??0) }))||[]
    
    const asset = [
        (financialStatement?.inventory??0),
        (financialStatement?.cxc??0),
        (financialStatement?.advances??0),
        boxes.reduce((acc, params) => (params?.value??0)+acc,0)
    ].reduce((acc, values) => values+acc,0)
    
    const liability = [
        (financialStatement?.cxp??0),
        fixedCosts.reduce((acc, params) => (params?.value??0)+acc,0),
        (financialStatement?.advancePaymentCustomers??0)
    ].reduce((acc, values) => values+acc,0)

    const patrimony = (asset-liability)

    return {
        financialStatement,
        isLoading,
        asset,
        liability,
        patrimony
    }
}

interface ExpensesBusinessProps {
    startDate: string
}

export const useExpensesBusiness = (params:ExpensesBusinessProps) => {
    const { startDate } = params
    const { data, isLoading } = useGetFixedAndVariableExpensesQuery({ startDate })
    const resp = data?.payload??{} as FixedAndVariableExpensesAsyncResponse['payload']
    return {
        resp,
        isLoading
    }
}

export const useGetMonthlyReport = () => {
    const { data, isLoading } = useGetMonthlyReportQuery()
    const resp = data?.payload?.sales??[] as SaleReportResponse['payload']['sales']
    const sales = [...resp]
    const totalSale = sales.reduce((acc, params) => (acc+(params?.total??0)),0)
    const totalCurrency = sales.reduce((acc, params) => (acc+(params?.totalProfitCurrency??0)),0)
    const totalPercentage = calcProfitPercentage(totalCurrency, totalSale)

    return { isLoading, resp, sales, totalSale, totalCurrency, totalPercentage }

}