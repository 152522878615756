import Button from "@component/Button"
import Dialog, { DialogActions, DialogContent } from "@component/Dialog"
import Form from "@component/Form"
import NumberFormat from "@component/NumberFormat"
import { useURLParams } from "@hook/useURLParams"
import { useEffect, useState } from "react"
import { useProvider } from "../hooks/useProvider"

const UpdateAdvanceValue = () => {
    const { updateSupplierAdvance } = useProvider()
    const [amountValue, setAmountValue] = useState<number>(0)
    const { getParameterGroup, delQueries } = useURLParams()
    const { ref=null, field=null } = getParameterGroup(['_id', 'field'])
    const open = Boolean(ref) && Boolean(field)
    const handleSubmit = () => updateSupplierAdvance({ amount: amountValue, advanceId: ref })
    useEffect(() => {
        if( !open ){
            setAmountValue(0)
        }
    },[open])
    return(
        <Dialog maxWidth='xs' fullWidth open={open} onClose={() => delQueries(['ref', 'field'])} >
            <Form onSubmit={handleSubmit} >
                <DialogContent>
                    <NumberFormat prefix="$ " label="Monto" value={amountValue} onChange={({ target }) => setAmountValue(Number((target?.value??0))) } />
                </DialogContent>
                <DialogActions>
                    <Button variant="text" type="submit" >
                        Guardar
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    )
}

export default UpdateAdvanceValue