import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { CustomerCityProps } from '../../../interfaces/Customer';

interface InitialStateProps {
  isOpenForm: boolean;
  CityToEdit: CustomerCityProps;
  selectionToDelete: string[];
  isOpenAlertDeleteCity: boolean;
}

const initialState: InitialStateProps = {
  isOpenForm: false,
  CityToEdit: { name: '', _id: '' },
  selectionToDelete: [],
  isOpenAlertDeleteCity: false,
};
export const CitySlice = createSlice({
  name: 'City',
  initialState,
  reducers: {
    displayCityForm: (state, action: PayloadAction<boolean>) => {
      state.isOpenForm = action.payload;
    },
    closeCityEditionForm: (state) => {
      state.CityToEdit = { name: '', _id: '' };
      state.isOpenForm = false;
    },
    loadCityToEdit: (
      state,
      action: PayloadAction<InitialStateProps['CityToEdit']>
    ) => {
      state.CityToEdit = action.payload;
      state.isOpenForm = true;
    },
    selectCityToDelete: (state, action: PayloadAction<string[]>) => {
      state.selectionToDelete = action.payload;
    },
    showAlertDeleteCity: (state, action: PayloadAction<boolean>) => {
      state.isOpenAlertDeleteCity = action.payload;
    },
  },
});

export const {
  displayCityForm,
  closeCityEditionForm,
  loadCityToEdit,
  selectCityToDelete,
  showAlertDeleteCity,
} = CitySlice.actions;

export default CitySlice.reducer;
