import React from 'react'
import Fade from '../../components/Fade'
import Grid from '../../components/Grid'
import LockComponent from '../../components/LockComponent'
import { useBusinessPermissions } from '../../hooks/usePermissions'
import DeliveryReportTab from './components/DeliveryReportTab'
import FilterDeliveries from './components/FilterDeliveries'
import InformativeDelivery from './components/InformativeDelivery'
import Title from '@component/Title'

const DeliveryReport = () => {
    const [tabPosition, setTabPosition] = React.useState<number>(2)
    const { Delivery } = useBusinessPermissions()
    return(
        <Fade in={true} >
            {Delivery.lock_module ?
                <Grid container spacing={2} >
                    <Grid xs={12} >
                        <Title text='Reporte de domicilios' />
                    </Grid>
                    <Grid xs={12} >
                        <InformativeDelivery positionTab={tabPosition} />
                    </Grid>
                    <Grid xs={12} >
                        <FilterDeliveries />
                    </Grid>
                    <Grid xs={12} >
                        <DeliveryReportTab setTabPosition={setTabPosition} tabPosition={tabPosition} />
                    </Grid>
                </Grid>
                :
                <LockComponent />
            }
        </Fade>
    )
}

export default DeliveryReport