import Fade from "@component/Fade"
import Grid from "@component/Grid"
import { FilterProducts, useGetProductsForMassEdition } from "./hooks/useMassProducts"
import DataGrid, { GridColDef, GridEventListener } from "@component/DataGrid"
import { currencyFormat } from "@hook/useNumberFormat"
import dayjs from "dayjs"
import { useURLParams } from "@hook/useURLParams"
import EditOfSelectedField from "./components/EditOfSelectedField"
import React from "react"
import TextField from "@component/TextField"
import MassProductsToolbar from "./components/MassProductsToolbar"
import { FixedSizeList } from "react-window"
import Box from "@component/Box"
import Title from "@component/Title"
import MassiveProductFilters from "./components/MassiveProductFilters"
import List, { ListItem, ListItemText } from "@component/List"

const MassProducts = () => {
    const [ selection, setSelection ] = React.useState<string[]>([])
    const [ filters, setFilters] = React.useState<FilterProducts>({ arrangeSimpleCompounds: false, searchProduct: "", onlyCompounds: false, onlySimple: false })
    const { isLoading, getProductParent, filterProducts, getProduct, getStockPerPackaging } = useGetProductsForMassEdition({ filters })
    const products = filterProducts()
    const { addQuery, getParameterGroup } = useURLParams()
    const { group="" } = getParameterGroup(['group'])
    const columns:GridColDef[] = [
        { maxWidth: 150, minWidth: 150, field: "totalInventory", headerName: "Valor inventario", renderCell: ({ row }) => `$ ${currencyFormat(row?.totalInventory??0, true)}`},
        { maxWidth: 200, minWidth: 200, field: "name", headerName: "Nombre"},
        { maxWidth: 90, minWidth: 90, field: "stock", headerName: "Stock padre", renderCell: ({ row }) => `${currencyFormat(row?.stock??0, true)}`},
        { maxWidth: 90, minWidth: 90,field: "category", headerName: "Categoría", valueGetter: ({ row }) => `${row?.category?.name??""}`},
        { maxWidth: 100, minWidth: 100, field: "cost", headerName: "Costo", renderCell: ({ row }) => `$ ${currencyFormat(row?.cost??0)}`},
        { maxWidth: 100, minWidth: 100, field: "costForPackaging", headerName: "Costo por embalaje", valueGetter: ({ row }) => ((row?.cost??0)*(row?.packaging?.quantity??0)) , renderCell: ({ row }) => Boolean(row?.compount) ? '' : `$ ${currencyFormat(((row?.cost??0)*(row?.packaging?.quantity??0)))}` },
        { maxWidth: 100, minWidth: 100, field: "prices", headerName: "Precios", valueGetter: ({ row }) => `${row?.prices?.length??0} precios`},
        { maxWidth: 130, minWidth: 130,field: "packaging", headerName: "Embalaje", valueGetter: ({ row }) => `${row?.packaging?.name??""} - x${row?.packaging?.quantity??""}`},
        { maxWidth: 90, minWidth: 90,field: "packagingStock", headerName: "Stock por embalaje", valueGetter: ({ row }) => `${getStockPerPackaging(row)}`},
        { maxWidth: 70, minWidth: 70, field: "skuCode", headerName: "SKU", valueGetter: ({ row }) => Number(row?.skuCode??0), renderCell: ({ row }) => `${row?.skuCode??""}` },
        { maxWidth: 95, minWidth: 95, field: "compount", headerName: "Tipo", valueGetter: ({ row }) => `${row?.compount ? "Compuesto" : "Simple"}`},
        { maxWidth: 150, minWidth: 150, field: "productParent", headerName: "Producto padre", valueGetter: ({ row }) => Boolean(row?.compount) ? `${getProductParent(row?.productParent??"")?.name??""}` : ""},
        { maxWidth: 150, minWidth: 150, field: "provider", headerName: "Proveedor", valueGetter: ({ row }) => `${row?.provider?.name??""}`},
        { maxWidth: 75, minWidth: 75, field: "tax", headerName: "Impuesto", valueGetter: ({ row }) => `${row?.tax?.name??""} ${row?.tax?.tax ? `${row?.tax?.tax}%` : ""}`},
        { maxWidth: 160, minWidth: 160, field: "createdAt", headerName: "Fecha de registro", valueGetter: ({ row }) => `${dayjs(row?.createdAt).format("DD-MM-YYYY HH:mm A")}`},
        { maxWidth: 160, minWidth: 160, field: "updatedAt", headerName: "Ultima actualización", valueGetter: ({ row }) => `${dayjs(row?.updatedAt).format("DD-MM-YYYY HH:mm A")}`},
    ]
    const handleCellClick:GridEventListener<"cellClick">  = ({ field, id }) =>{
        let product = getProduct(id as string)
        if( field === "stock" && Boolean(product?.compount) ){
            return
        }
        if( field !== "__check__" ){
            addQuery({ field, product: id as string })
        }
    }
    return(
        <>
            <Fade in={true} >
                <Grid container >
                    <Grid xs={12} >
                        <Title text={"Productos masivos"} />
                    </Grid>
                    <Grid xs={12} >
                        <TextField
                            label="Buscar producto"
                            value={filters.searchProduct}
                            onChange={({ target }) => setFilters(n => ({...n, searchProduct: target.value}))}
                        />
                    </Grid>
                    <Grid xs={12} sx={{ display: 'flex', justifyContent: 'end', py: 1 }} >
                        <MassiveProductFilters filters={filters} setFilters={setFilters} />
                    </Grid>
                    <Grid xs={12} >
                        { ((group === "categories") || (group === "providers")) ?
                            <>
                                <FixedSizeList height={(window.innerHeight-200)} itemCount={products.length} itemSize={700} width={"100%"} >
                                    {({ index, style }) => {
                                        let results = products[index].results;
                                        return(
                                            <Box key={index} style={style} >
                                                <Title text={(products[index]?.category??"")} variant='subtitle' hideDivider />
                                                <List>
                                                    <ListItem>
                                                        <ListItemText primary={`$ ${currencyFormat(results?.reduce((acc:number, params:any) => Boolean(params?.compount) ? (acc+0) : (acc+(params?.totalInventory??0)),0))}`} secondary="Total inventario" />
                                                    </ListItem>
                                                </List>
                                                <DataGrid
                                                    height={600}
                                                    onCellClick={handleCellClick}
                                                    loading={isLoading}
                                                    columns={columns}
                                                    rows={results}
                                                />
                                            </Box>
                                        )
                                    }}
                                </FixedSizeList>
                            </>
                            :
                            <>
                                <List>
                                    <ListItem>
                                        <ListItemText primary={`$ ${currencyFormat(products?.reduce((acc:number, params) => Boolean(params?.compount) ? (acc+0) : (acc+(params?.totalInventory??0)),0))}`} secondary="Total inventario" />
                                    </ListItem>
                                </List>
                                <DataGrid
                                    rowSelectionModel={selection}
                                    onRowSelectionModelChange={(params) => setSelection(params as string[])}
                                    slots={{
                                        toolbar: () => <MassProductsToolbar items={selection} />
                                    }}
                                    height={600}
                                    disableRowSelectionOnClick
                                    onCellClick={handleCellClick}
                                    checkboxSelection
                                    loading={isLoading}
                                    columns={columns}
                                    rows={products}
                                />
                            </>
                        }
                        
                    </Grid>
                    <EditOfSelectedField />
                </Grid>
            </Fade>
        </>
    )
}

export default MassProducts