import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { calcProfitPercentage } from '../../../hooks/useNumberFormat'
import type { ProductFormProps, ProductProps, ProductsFromExcel } from '../../../interfaces/Product'
import { useDeleteProductMutation, useGetAccurateProductInformationQuery, useGetAvailableProductCodeQuery, useRegisterProductMutation, useUpdateProductMutation, useUploadMassiveProductsMutation } from '../../../api/querys/Product'
import { displayProductCreationForm, loadProductsFromExcel, showProductDeletionAlert, updateProductDataTabPosition } from '../services/ProductSlice'
import { useAppDispatch } from '../../../hooks/state'
import { transformIntoFormData } from '../../../services/parseData'
import { domain } from '../../../api/splitApi'
import { getToken } from '../../../services/localStorage'
import { GridRowSelectionModel } from '@mui/x-data-grid'
import Swal from 'sweetalert2'

interface Props {
    skipAvailableProductCode?: boolean
    onCreateFastProduct?: (product:ProductProps) => void
}

export const useProducts = ({skipAvailableProductCode, onCreateFastProduct}:Props) => {

    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useAppDispatch()
    const [uploadMassiveProducts, { isLoading:isLoadingUploadMassiveProducts }] = useUploadMassiveProductsMutation()
    const { data:availableProductCode, isSuccess:isSuccessAvailableProduct } = useGetAvailableProductCodeQuery(null, { ...(skipAvailableProductCode !== undefined && {skip: skipAvailableProductCode}) })
    const [registerProductMutation, { isLoading:isLoadingRegisterProduct }] = useRegisterProductMutation()
    const [updateProductMutation, { isLoading:isLoadingUpdateProduct }] = useUpdateProductMutation()
    const [deleteProductMutation, { isLoading:isLoadingDeleteProduct }] = useDeleteProductMutation()

    const useValidatorXLSXData  = React.useCallback(({ products }:{ products:any[] }) =>  {
        let result:any[] = []
        let skuCode:{skuCode: number, lineNumber: number}[] = []
        if( products.length <= 0 ){
            enqueueSnackbar(t('youMustAddAtLeastOneProductToTheFile'), { variant: "warning" } )
            return
        }
        for(let productIndex in products){
            const product = products[productIndex]
            if( !Boolean(product[t('productName')]) ){
                enqueueSnackbar(t('missingProductNameOnTheLine',{ lineNumber: parseInt(productIndex)+2}), { variant: "warning" })
                return
            }
            if( !Boolean(product[t('SKUCode')]) ){
                enqueueSnackbar(t('missingProductCodeOnTheLine',{ lineNumber: parseInt(productIndex)+2}), { variant: "warning" })
                return
            }
            if( !Boolean(product[t('cost')]) ){
                enqueueSnackbar(t('missingProductCostOnTheLine',{ lineNumber: parseInt(productIndex)+2}), { variant: "warning" })
                return
            }
            if( !Boolean(product[t('sale')]) ){
                enqueueSnackbar(t('missingProductSalesPriceOnTheLine',{ lineNumber: parseInt(productIndex)+2}), { variant: "warning" })
                return
            }
            if( product[`${t('sale')}`] < product[t('cost')] || product[`${t('sale')} 2`] < product[t('cost')] || product[`${t('sale')} 3`] < product[t('cost')] ){
                enqueueSnackbar(t('theSalePriceCannotBeLessThanTheCostOfTheProductOnTheLine',{ lineNumber: parseInt(productIndex)+2}), { variant: "warning" })
                return
            }

            const skuCodeRepeated = skuCode.findIndex(n => n.skuCode === product[t('SKUCode')])
            if( skuCodeRepeated >= 0 ){
                enqueueSnackbar(t('theSKUCodeInLineIsRepeatedInTheFile',{ lineNumber: parseInt(productIndex)+2, lineNumber2: skuCode[skuCodeRepeated].lineNumber }), { variant: "warning" })
                return
            }else{
                skuCode.push({
                    skuCode: product[t('SKUCode')],
                    lineNumber: parseInt(productIndex)+2
                })
            }
        }

        for(let productIndex in products){
            const product = products[productIndex]
            result.push({
                _id: productIndex,
                name: product[t('productName')],
                cost: product[t('cost')],
                prices: [
                    ( Boolean(product[t('sale')]) ? { sellPrice: product[t('sale')], percentageProfit: calcProfitPercentage((product[`${t('sale')}`]-product[t('cost')]), product[`${t('sale')}`], true), profit: (product[t('sale')]-product[t('cost')]) } : { sellPrice: 0, percentageProfit: 0, profit: 0 } ),
                    ( Boolean(product[`${t('sale')} 2`]) ? { sellPrice: product[`${t('sale')} 2`], percentageProfit: calcProfitPercentage((product[`${t('sale')} 2`]-product[t('cost')]), product[`${t('sale')} 2`], true), profit: (product[`${t('sale')} 2`]-product[t('cost')]) } : { sellPrice: 0, percentageProfit: 0, profit: 0 } ),
                    ( Boolean(product[`${t('sale')} 3`]) ? { sellPrice: product[`${t('sale')} 3`], percentageProfit: calcProfitPercentage((product[`${t('sale')} 3`]-product[t('cost')]), product[`${t('sale')} 3`], true), profit: (product[`${t('sale')} 3`]-product[t('cost')]) } : { sellPrice: 0, percentageProfit: 0, profit: 0 } ),
                ],
                skuCode: product[t('SKUCode')],
                category: product[t('category')],
                provider: product[t('provider')],
            })
        }
        enqueueSnackbar(t('theFileWasValidatedAndUploadedCorrectly'), { variant: "success" })
        return result
    },[t, enqueueSnackbar])

    const useUploadMassiveProducts = React.useCallback( async (products:ProductsFromExcel[]) => {
        const resp = await uploadMassiveProducts(products).unwrap()
        if( resp.status === 1 ){
            enqueueSnackbar(t('updatedProductsCreatedProducts', { productsUpdate: resp.payload.updateCount, productsNew: resp.payload.createCount }), { variant: "success" })
            dispatch(loadProductsFromExcel([]))
        }else{
            enqueueSnackbar(t('itSeemsThatAnErrorOccurredWhileTryingToUploadTheFile'), { variant: "error" })
        }
    },[t, enqueueSnackbar, uploadMassiveProducts, dispatch])

    const useGetAvailableProductCode = React.useCallback(() => {
        if( isSuccessAvailableProduct ){
            if( availableProductCode?.status === 1 ){
                return availableProductCode.payload
            }else{
                enqueueSnackbar("Error al intentar obtener código de producto disponible", {variant: "error"})
                return undefined
            }
        }
    },[availableProductCode, isSuccessAvailableProduct, enqueueSnackbar])

    const registerProduct = async (payload:ProductFormProps & {image: FileList[0] | null}) => {
        try {
            const payloadFormData = transformIntoFormData(payload)
            if( !Boolean(payload.prices[0].sellPrice) ){
                Swal.fire({
                    icon: "warning",
                    showConfirmButton: false,
                    text: "Añada por lo menos un precio de venta",
                    timer: 2000
                })
                return
            }
            const resp = Boolean(payload._id) ? await updateProductMutation(payloadFormData).unwrap() : await registerProductMutation(payloadFormData).unwrap()
            

            if( resp.status === 1 ){
                enqueueSnackbar(t('theProductWasRegisteredSuccessfully'), { variant: "success" })
                dispatch(displayProductCreationForm(false))
                if( onCreateFastProduct !== undefined ){
                    onCreateFastProduct(resp.payload)
                }
                dispatch(updateProductDataTabPosition(0))
            }else{
                if( (resp?.payload?.code??0) === 1003 ){
                    enqueueSnackbar(t((resp?.payload?.key??'')), { variant: "warning" })
                }
                enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'), { variant: "error" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const deleteProducts = async (productsArray:GridRowSelectionModel) => {
        try {
            const resp = await deleteProductMutation(productsArray).unwrap()
            if( resp.status === 1 ){
                const message = (productsArray.length >= 2) ? t('productsWereSuccessfullyRemoved') : t('productWasSuccessfullyRemoved')
                enqueueSnackbar(message,{ variant: "success" })
                dispatch(showProductDeletionAlert(false))
            }else{
                enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'),{ variant: "error" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        useValidatorXLSXData,
        useUploadMassiveProducts,
        isLoadingUploadMassiveProducts,
        useGetAvailableProductCode,
        registerProduct,
        isLoadingRegisterProduct,
        isLoadingUpdateProduct,
        deleteProducts,
        isLoadingDeleteProduct
    }
}

export const useImageProduct = () => {
    const getProductImage = React.useCallback( async (publicPath:string) => {
        try {
            if( Boolean(publicPath) ){
                const publicPathSplit = publicPath.split(/(?:\\|\/)+/)
                const publicPathSplitWithoutPublic = publicPathSplit.slice(1, publicPathSplit.length)
                const path = publicPathSplitWithoutPublic.join("/")
                const token = getToken()
                let blob:any = await (await fetch( `${domain}/${path}`, { headers: new Headers({ 'Authorization': `Bearer ${token}`, }) } )).blob()
        
                if( blob.type !== "text/html" ){
                    blob.name = publicPath.split(/(?:\\|\/)+/)[publicPath.split(/(?:\\|\/)+/).length-1];
                    blob.lastModified = new Date();
                    const myFile = new File([blob], blob.name, {
                        type: blob.type,
                    });
                    return { objectURL: URL.createObjectURL(myFile), file: myFile }
                }
            }
        } catch (error) {
            console.log(error)
        }
    },[])

    return { getProductImage }
}

export const useGetAccurateProductInformation = (fields:string) => {
    const { data, isFetching } = useGetAccurateProductInformationQuery({ fields })
    const { t } = useTranslation()
    let list = data?.payload??[]
    let listForTheBook = [...list].map((n) => {
        let _n = {...n}
        if( typeof n?.compount == "boolean" ){
            if( Boolean(n?.compount) ){
                _n.compount = "Compuesto"
            }else{
                _n.compount = "Simple"
            }
        }
        delete _n._id
        return _n
    })
    let fieldsToResponse = listForTheBook.length >= 1 ? Object.keys(listForTheBook[0]) : []
    fieldsToResponse = fieldsToResponse.map((n) => t(n))

    return {
        list,
        isLoading: isFetching,
        listForTheBook,
        fieldsToResponse
    }
}