import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/state';

import Button from '../../../components/Button';
import Form from '../../../components/Form';
import Grid from '../../../components/Grid';
import TextField from '../../../components/TextField';
import { CustomerBranchProps } from '../../../interfaces/Customer';
import { useCustomerBranch } from '../hooks/useCustomerBranch';

const initialValueOfBranch = {
  name: '',
};

const CustomerBranchForm: React.FC = () => {
  const { t } = useTranslation();
  const { CustomerBranchState } = useAppSelector(({ CustomerBranchState }) => ({
    CustomerBranchState,
  }));
  const { isOpenForm, branchToEdit } = CustomerBranchState;

  const {
    registerCustomerBranchMutation,
    registerCustomerBranchIsLoading,
    updateCustomerBranchIsLoading,
  } = useCustomerBranch();

  const [form, setForm] = useState<CustomerBranchProps>(initialValueOfBranch);

  const handleChange  = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = evt.target;
    setForm((preventFormState) => ({
      ...preventFormState,
      [name]: value,
    }));
  };

  const handleSubmit = () => registerCustomerBranchMutation(form);

  useEffect(() => {
    if (isOpenForm && branchToEdit?._id !== '') {
      setForm((preventFormState) => ({
        ...preventFormState,
        name: branchToEdit?.name,
        _id: branchToEdit?._id,
      }));
    }
  }, [isOpenForm, branchToEdit]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={1}
      >
        <Grid xs={12}>
          <TextField
            margin='none'
            label={t('nameOfTheBranch')}
            name='name'
            value={form.name}
            autoFocus
            onChange={handleChange}
          />
        </Grid>
        <Grid xs={12}>
          <Button
            type='submit'
            loading={
              registerCustomerBranchIsLoading || updateCustomerBranchIsLoading
            }
          >
            {branchToEdit?._id !== ''
              ? t('updateBranch')
              : t('createNewBranch')}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default CustomerBranchForm;
