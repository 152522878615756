import React from 'react'
import Tab from "@component/Tab"
import DeliveryList from "./DeliveryList"
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'

interface Props {
    setTabPosition: React.Dispatch<React.SetStateAction<number>>
    tabPosition: number
}

const DeliveryReportTab = ({ setTabPosition, tabPosition }:Props) => {
    const theme = useTheme()
    return(
        <Tab
            orientation={ useMediaQuery(theme.breakpoints.down("sm")) ? "horizontal" : "vertical" }
            onChange={(_, position) => setTabPosition(position)}
            TabItem={["Todos", "Pagados", "Pendientes"]}
            TabRender={[<DeliveryList tabPosition={tabPosition} />, <DeliveryList tabPosition={tabPosition} />, <DeliveryList tabPosition={tabPosition} />]}
        />
    )
}

export default DeliveryReportTab