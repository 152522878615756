import { styled } from '@mui/material/styles'

interface Props {
    src?: string
    alt?: string
    floatingAnimation?: boolean
}

const Img = styled("img", {
    shouldForwardProp: (prop) => prop !== 'floatingAnimation',
  })<Props>(({floatingAnimation}) => ({
    width: "100%",
    height: 'auto',
    ...(floatingAnimation && {
        animation: "imageFloat 4s ease-in-out",
        animationIterationCount: "infinite",
        transform: "translateY(1.2rem)",
        "@keyframes imageFloat" : {
            "50%": {
                transform: "translateY(0rem)"
            },
            "100%": {
                transform: "translateY(1.2rem)"
            }
        },
    })
}))

const Image = ({...rest}:Props) => {
    return(
        <>
            <Img {...rest} />
        </>
    )
}

export default Image