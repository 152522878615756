import Button from "@component/Button"
import Dialog, { DialogActions, DialogContent } from "@component/Dialog"
import InputAdornment from "@component/InputAdornment"
import TextField from "@component/TextField"
import React from "react"
import { useInvoice } from "../hooks/useInvoice"
import { useParams } from "react-router-dom"
import { useURLParams } from "@hook/useURLParams"

interface Props {
    electronicConsecutive: string
}

const EnterConsecutiveElectronicInvoice = (props:Props) => {
    const { electronicConsecutive } = props
    const { getQuery, delQueries } = useURLParams()
    const open = getQuery("window") === "consecutive-electronic-invoice"
    const { invoiceId } = useParams()
    const [consecutive, setConsecutive] = React.useState<string>("")
    const { updateConsecutiveElectronicInvoice, isLoadingUpdateConsecutiveElectronicInvoiceMutation } = useInvoice()
    const handleSubmit = () => updateConsecutiveElectronicInvoice({ consecutive, invoiceId: invoiceId??"" })
    React.useEffect(() => {
        if( Boolean(electronicConsecutive) ){
            setConsecutive(electronicConsecutive)
        }
    },[electronicConsecutive])
    return(
        <>
            <Dialog maxWidth="xs" fullWidth open={open} onClose={() => delQueries(["window"])} >
                <DialogContent>
                    <TextField
                        value={consecutive}
                        label="Consecutivo"
                        InputProps={{
                            startAdornment: <InputAdornment position="start" >
                                #F:
                            </InputAdornment>
                        }}
                        onChange={({ target }) => setConsecutive(target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button loading={isLoadingUpdateConsecutiveElectronicInvoiceMutation} variant="text" onClick={handleSubmit} >
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default EnterConsecutiveElectronicInvoice