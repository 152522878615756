import { useAppDispatch } from './../../../hooks/state';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useRegisterEmployeeMutation, useUpdateBasicPayrollDataMutation, useUpdateEmployeeMutation } from '../../../api/querys/Employee';
import type { EmployeeDataForm, EmployeeProps } from "@interface/Employee"
import { displayFormToCreateEmployee } from '../services/EmployeeSlice';

const employeeForm = {
    name: "",
    lastName: "",
    email: "",
    numberPhone: "",
}

interface Props{
    employee?: EmployeeDataForm
}

export const useEmployee = ({employee}:Props) => {
    const [loadData, setLoadData] = React.useState<"success" | "none">("none")
    const [employeeData, setEmployeeData] = React.useState<EmployeeDataForm>({...employeeForm})
    const [typeForm, setTypeForm] = React.useState<"update" | "create">("create")
    const [ updateBasicPayrollDataMutation, { isLoading:isLoadingBasicPayrollDataMutation } ] = useUpdateBasicPayrollDataMutation()
    const { enqueueSnackbar } = useSnackbar()


    const loadDataEmployee = React.useCallback(() => {
        if( employee !== undefined && loadData === "none" ) {
            const employeeForm = {...employeeData}
            employeeForm.name = employee.name
            employeeForm.lastName = employee.lastName
            employeeForm.email = employee.email
            employeeForm.numberPhone = employee.numberPhone
            setEmployeeData(employeeForm)
            setLoadData("success")
            setTypeForm("update")
        }
    },[employee, loadData, employeeData])

    const handleChangeForm = React.useCallback((name:string, value:string) => {
        setEmployeeData((data) => ({...data, [name]: value}))
    },[])
    React.useEffect(() => {
        loadDataEmployee()
    },[loadDataEmployee])
    const updateBasicPayrollData = async (payload:Pick<EmployeeProps, "baseSalary" | "cutForPayments" | "startOfTheDay" | "endOfDay">) => {
        const resp = await updateBasicPayrollDataMutation(payload).unwrap()
        if( resp.status === 1 ){
            enqueueSnackbar("Se actualizo la información de nomina con éxito",{variant: 'success'})
        }else{
            enqueueSnackbar("Error",{variant: 'error'})
        }
    }

    return {
        employeeData,
        handleChangeForm,
        typeForm,
        updateBasicPayrollData,
        isLoadingBasicPayrollDataMutation
    }
}

interface ApiEmployeeProps {
    typeForm?: "create" | "update"
}

export const useApiEmployee = ({ typeForm }:ApiEmployeeProps) => {
    const [updateEmployee, { isLoading:isLoadingUpdate }] = useUpdateEmployeeMutation()
    const [registerEmployee, { isLoading:isLoadingRegister }] = useRegisterEmployeeMutation()
    const { employeeId } = useParams()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useAppDispatch()

    const submitEmployeeData = React.useCallback( async (employeeData:EmployeeDataForm) => {
        if( typeForm === "create" ){
            const resp = await registerEmployee(employeeData).unwrap()
            if( resp.status === 1 ){
                enqueueSnackbar('El empleado se registro con exito', {variant: 'success'})
                dispatch(displayFormToCreateEmployee(false))
            }else{
                enqueueSnackbar('Ocurrio un error al intentar registrar a el empleado', {variant: 'error'})
            }
        }
        if( typeForm === "update" ){
            if( !Boolean(employeeId) ){
                enqueueSnackbar('El identificador del empleado es requerido', {variant: 'error'})
                return
            } 
            const payload = {...employeeData}
            payload._id = employeeId
            const resp = await updateEmployee(payload).unwrap()
            if( resp.status === 1 ){
                enqueueSnackbar('Los datos del empleado se actualizaron con exito', {variant: 'success'})
            }else{
                enqueueSnackbar('Ocurrio un error al intentar actualizar los datos', {variant: 'error'})
            }
        }
    },[typeForm, employeeId, enqueueSnackbar, updateEmployee, dispatch, registerEmployee])



    return {
        submitEmployeeData,
        isLoadingUpdate,
        isLoadingRegister,

    }
}