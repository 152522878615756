import Box from "@component/Box"
import IconButton from "@component/IconButton"
import Stack from "@component/Stack"
import Tooltip from "@component/Tooltip"
import { showFormOfExpense } from "@feature/Expenses/services/ExpenseSlice"
import { showFixedExpensePayment } from "@feature/FixedCosts/services/FixedCostsSlice"
import { useAppDispatch, useAppSignalSelector } from "@hook/state"
import CurrencyExchangeRounded from '@mui/icons-material/CurrencyExchangeRounded';

const MinorCashMoveListToolbar = () => {
    const { BusinessState } = useAppSignalSelector()
    const dispatch = useAppDispatch()
    return(
        <>
            <Stack sx={{ pt: 1, px: 2 }} direction={'row'} justifyContent={'end'} alignItems={'center'} >
                <Box>
                    <Tooltip title="Crear gasto de caja menor" >
                        <IconButton onClick={() => dispatch(showFormOfExpense(true))} >
                            <Box fontSize={'1.8rem'} className='icon-moneybag' />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box>
                    <Tooltip title="Pagar gasto fijo" >
                        <IconButton onClick={() => dispatch(showFixedExpensePayment(true))} >
                            <Box className='icon-money' />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box>
                    <Tooltip title="Crear traslado de caja" >
                        <IconButton onClick={() => BusinessState.value = {...BusinessState.value, showTransferCashWindow: true} } >
                            <CurrencyExchangeRounded />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Stack>
        </>
    )
}

export default MinorCashMoveListToolbar