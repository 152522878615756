import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material'
import mainTheme from './styles/theme/main'
import Authentication from './features/Authentication';
import { useAppSelector } from './hooks/state';
import { createTheme } from '@mui/material/styles'
import { openSocket } from './services/socket';
import { SnackbarProvider } from 'notistack';
import Dashboard from './features/Dashboard';
import SignalProvider from '@component/SignalProvider';
import ContextProvider from '@component/ContextProvider';

function App() {
    openSocket()
    const { AppState } = useAppSelector(({ AppState }) => ({ AppState }))
    const { mode, isAuth } = AppState
    const themeApp = createTheme({ ...mainTheme, palette: { mode } })

    return (
        <ThemeProvider theme={themeApp} >
            <SnackbarProvider maxSnack={3} >
                <ContextProvider>
                    <SignalProvider>
                        <CssBaseline />
                        {isAuth ? <Dashboard /> : <Authentication />}
                    </SignalProvider>
                </ContextProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;