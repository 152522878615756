import { useSnackbar } from "notistack"
import { useDeleteEmployeeMutation } from "../../../api/querys/Employee"
import Button from "@component/Button"
import Dialog, { DialogActions, DialogContent, DialogContentText, DialogTitle } from "@component/Dialog"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { showEmployeeDeletionAlert } from "../services/EmployeeSlice"

const AlertDeleteEmployee = () => {
    const dispatch = useAppDispatch()
    const { EmployeeState } = useAppSelector(({ EmployeeState }) => ({ EmployeeState }))
    const { employeeRemovalAlert, employeeSelectionToDelete } = EmployeeState
    const [deleteEmployees, { isLoading }] = useDeleteEmployeeMutation()
    const { enqueueSnackbar } = useSnackbar()
    const handleDelEmployees = async () => {
        const resp = await deleteEmployees(employeeSelectionToDelete).unwrap()
        if( resp.status === 1 ){
            const message = employeeSelectionToDelete.length >= 2 ? "Empleados eliminados satisfactoriamente" : "Empleado eliminado satisfactoriamente"
            enqueueSnackbar(message,{ variant: "success" })
        }
    }
    return(
        <Dialog open={employeeRemovalAlert} onClose={() => dispatch(showEmployeeDeletionAlert(false))} >
            <DialogTitle> Eliminar empleados </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    ¿Esta seguro que desea ejecutar esta acción?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="text" color="error" loading={isLoading} onClick={handleDelEmployees} >Eliminar</Button>
                <Button variant="text" onClick={() => dispatch(showEmployeeDeletionAlert(false))} loading={isLoading} >Cancelar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AlertDeleteEmployee