import { useGetProductInventoryQuery } from "../../api/querys/Product"
import Fade from "@component/Fade"
import Grid from "@component/Grid"
import SearchCategoriesProduct from "./components/SearchCategoriesProduct"
import { useParams } from "react-router-dom"
import DiscriminateInventory from "./components/DiscriminateInventory"
import DataInventory from "./components/DataInventory"
import CircularProgress from "@component/CircularProgess"
import { useBusinessPermissions } from "../../hooks/usePermissions"
import LockComponent from "@component/LockComponent"
import Title from "@component/Title"

const Inventory = () => {
    const { Inventory } = useBusinessPermissions()
    const { categoryId } = useParams()
    const { isLoading, isSuccess } = useGetProductInventoryQuery(categoryId)
    const showComponent = !isLoading && isSuccess
    
    if( showComponent ){
        return(
            <Fade in={true} >
                {Inventory.lock_module ?
                    <Grid container spacing={2} >
                        <Grid xs={12} >
                            <Title text="Inventario" />
                        </Grid>
                        <Grid xs={12} >
                            <SearchCategoriesProduct />
                        </Grid>
                        <Grid xs={12} >
                            <DiscriminateInventory />
                        </Grid>
                        <Grid xs={12} >
                            <DataInventory />
                        </Grid>
                    </Grid>
                    :
                    <LockComponent />
                }
            </Fade>
        )
    }else{
        return(
            <Fade in={true} >
                <CircularProgress />
            </Fade>
        )
    }
}

export default Inventory