import { useGetSessionDataQuery } from '../../api/querys/Users';
import CircularProgress from '../../components/CircularProgess';
import Main from './components/Main';
import { Outlet } from "react-router-dom";
import PointOfSale from '../PointOfSale';
import Dialog, { DialogContent } from '../../components/Dialog';
import Typography from '../../components/Typography';
import FormBusiness from '../Business/components/FormBusiness';
import { useBusinessPermissions } from '../../hooks/usePermissions';

const Dashboard = () => {
  const { PointOfSale:PointOfSalePermission } = useBusinessPermissions()
  const { data, isSuccess, isLoading } = useGetSessionDataQuery()
  const business = data?.payload?.business

  if(isSuccess && !isLoading){
    if( Boolean(business?.active) ){
      return(
        <Main>
            <Outlet />
            {PointOfSalePermission.lock_module &&
              <PointOfSale />
            }
        </Main>
      )
    }else{
      return(
        <>
          <Dialog fullWidth open={true} onClose={() => null} >
            <DialogContent>
              <Typography textAlign={'center'} fontWeight={'500'} > Ya casi terminamos, crea tu negocio y como comienza a administrarlo muy FACILL.! </Typography>
              <FormBusiness />
            </DialogContent>
          </Dialog>
        </>
      )
    }
  }else{
    return <CircularProgress />
  }
  
}

export default Dashboard