import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Form from '../../../components/Form';
import TextField from '../../../components/TextField';
import { useAppSelector } from '../../../hooks/state';
import { EventType } from '../../../interfaces/App';
import { RoleFormProps } from '../../../interfaces/Role';

import { usePermissions } from '../hooks/usePermissions';

const form = {
  name: '',
};

const RolForm = () => {
  const { t } = useTranslation();
  const [formRol, setFormRol] = React.useState<RoleFormProps>({ ...form });
  const { registerRol, updateRol, isLoadingUpdateRole, isLoadingCreateRole } =
    usePermissions();

  const { PermissionState } = useAppSelector(({ PermissionState }) => ({
    PermissionState,
  }));
  const { isOpenEditRol, rolSelected } = PermissionState;

  const handleChange = (evt: EventType) => {
    const { name, value } = evt.target;
    setFormRol((preventFormState) => ({
      ...preventFormState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (isOpenEditRol) {
      setFormRol((preventFormState) => ({
        ...preventFormState,
        name: rolSelected.name,
        _id: rolSelected?._id,
        permissions: rolSelected.permissions,
      }));
    }
  }, [isOpenEditRol, rolSelected]);

  const handleSubmitForm = (formRol: any) => {
    isOpenEditRol ? updateRol(formRol) : registerRol(formRol);
  };

  return (
    <Form onSubmit={() => handleSubmitForm(formRol)}>
      <TextField
        name='name'
        value={formRol.name}
        onChange={handleChange}
        required
        label={t('roleName')}
      />
      <Button
        type='submit'
        loading={isLoadingUpdateRole || isLoadingCreateRole}
      >
        {isOpenEditRol ? t('updateRol') : t('createRole')}
      </Button>
    </Form>
  );
};

export default RolForm;
