import React from 'react'
import Grid from "@component/Grid"
import { Prices } from "@feature/Products/components/ProductForm"
import { useAppDispatch, useAppSelector } from "@hook/state"
import { usePurchasingTools } from "../hooks/usePurchasing"
import Card from '@component/Card'
import IconButton from '@component/IconButton'
import ClearRounded from '@mui/icons-material/ClearRounded';
import Tooltip from '@component/Tooltip'
import { updateListOfSelectedProducts, updateSelectedProductOfList } from '../services/PurchasesSuppliersSlice'
import { ProductPrices } from '@interface/Product'
import { currencyFormat } from '@hook/useNumberFormat'
import List, { ListItem, ListItemText } from '@component/List'

const SelectedProductPrices = () => {
    const dispatch = useAppDispatch()
    const { PurchasesSuppliersState } = useAppSelector(({ PurchasesSuppliersState }) => ({ PurchasesSuppliersState }))
    const { productOfTheSelectedList, productsSelectedList } = PurchasesSuppliersState
    const { calcPrices } = usePurchasingTools()
    const product = calcPrices(productOfTheSelectedList!)

    const handleChangeProductPrice = (priceIndex:number, prices:ProductPrices) => {
        const __productsSelectedList__ = [...productsSelectedList]
        const indexProduct = __productsSelectedList__.findIndex(n => n._id === product?._id)
        if( indexProduct >= 0 ){
            const editableProduct = structuredClone(__productsSelectedList__[indexProduct])
            editableProduct.prices[priceIndex] = prices
            __productsSelectedList__[indexProduct] = editableProduct
            dispatch(updateListOfSelectedProducts(__productsSelectedList__))
            dispatch(updateSelectedProductOfList(editableProduct))
        }
    }
    const handleChangeProductCompoundPrice = (compoundIndex:number, priceIndex:number, prices:ProductPrices) => {
        const __productsSelectedList__ = [...productsSelectedList]
        const indexProduct = __productsSelectedList__.findIndex(n => n._id === product?._id)
        if( indexProduct >= 0 ){
            const editableProduct = structuredClone(__productsSelectedList__[indexProduct])
            editableProduct.compounds[compoundIndex].prices[priceIndex] = prices
            __productsSelectedList__[indexProduct] = editableProduct
            dispatch(updateListOfSelectedProducts(__productsSelectedList__))
            dispatch(updateSelectedProductOfList(editableProduct))
        }
    }
    return(
        <>
            <Card>
                <Grid container >
                    <Grid xs={12} sx={{ display: 'flex', justifyContent: 'end' }} >
                        <Tooltip title="Cerrar" >
                            <IconButton color='error' onClick={() => dispatch(updateSelectedProductOfList(null))} >
                                <ClearRounded sx={{ color: (theme) => theme.palette.error.main }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    {(product !== null) &&
                        <>
                            <Grid xs={12} >
                                <List sx={{ padding: 0 }} >
                                    <ListItem sx={{ padding: 0 }} >
                                        <ListItemText
                                            primaryTypographyProps={{ fontWeight: 'bold' }}
                                            primary={product.name}
                                            secondary={`Costo: $ ${currencyFormat(product?.costUnit??0)} - Flete: $ ${currencyFormat(product?.unitFreight??0)} - Costo con flete: $ ${currencyFormat(product?.costWithFreight??0)}`}
                                        />
                                    </ListItem>
                                </List>
                            </Grid>
                            {product.prices.map((n, index) => {
                                return(
                                    <Prices key={index} onChange={(prices:any) => handleChangeProductPrice(index, prices)} cost={product.costUnit as number} margin={n.percentageProfit} profit={n.profit} sale={n.sellPrice} />
                                )
                            })}

                            {((product.compounds !== null) && (product.compounds !== undefined)) &&
                                <>
                                    {product.compounds.map((compound, compoundIndex) => {
                                        return(
                                            <React.Fragment key={compoundIndex} >
                                                <Grid xs={12} >
                                                    <List sx={{ padding: 0 }} >
                                                        <ListItem sx={{ padding: 0 }} >
                                                            <ListItemText
                                                                primaryTypographyProps={{ fontWeight: 'bold' }}
                                                                primary={compound.name}
                                                                secondary={`Costo: $ ${currencyFormat(compound?.costUnit??0)} - Flete: $ ${currencyFormat((product?.unitFreight??0)*(compound?.packaging?.quantity??0))} - Costo con flete: $ ${currencyFormat( ((compound?.costUnit??0)+( (product?.unitFreight??0)*(compound?.packaging?.quantity??0) )) )}`}
                                                            />
                                                        </ListItem>
                                                    </List>                                                </Grid>
                                                {compound.prices.map((n, index) => {
                                                    return(
                                                        <Prices key={index} onChange={(prices:any) => handleChangeProductCompoundPrice(compoundIndex, index, prices)} cost={compound.costUnit as number} margin={n.percentageProfit} profit={n.profit} sale={n.sellPrice} />
                                                    )
                                                })}
                                            </React.Fragment>
                                        )
                                    })}
                                </>
                            }
                        </>
                    }
                </Grid>
            </Card>
        </>
    )
}

export default SelectedProductPrices