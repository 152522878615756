import * as React from 'react';
import Grid from '@mui/material/Grid';
import { intersection } from './services';
import CustomList from './components/CustomList';
import Actions from './components/Actions';
import { useTranslation } from 'react-i18next';

const TransferList = ({ leftData, leftDataFilter, rightDataFilter, labelRight = 'Chosen', labelLeft = 'Chosen', subHeaderLabel = 'select', handleSubmit, prevDataState, isLoading = false, }: any) => {
  const { t } = useTranslation()
  const [checked, setChecked] = React.useState<readonly string[]>([]);
  const [left, setLeft] = React.useState(leftDataFilter);
  const [right, setRight] = React.useState(rightDataFilter);
  const leftChecked = intersection(checked, left!);
  const rightChecked = intersection(checked, right!);

  React.useEffect(() => {
    setRight(rightDataFilter);
  }, [prevDataState, setRight]);

  React.useEffect(() => {
    setLeft(leftDataFilter);
  }, [prevDataState, setLeft]);

  return (
    <Grid className='grid-transferlist-container' container spacing={2} justifyContent='center' alignItems='center' >
      <Grid item>
        <CustomList title={t(labelLeft)} subHeaderLabel={subHeaderLabel} items={left} checked={checked} setChecked={setChecked} />
      </Grid>
      <Actions
        leftData={leftData}
        right={right}
        setRight={setRight}
        left={left}
        setLeft={setLeft}
        rightChecked={rightChecked}
        leftChecked={leftChecked}
        checked={checked}
        setChecked={setChecked}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
      />
      <Grid item>
        <CustomList title={t(labelRight)} items={right} checked={checked} setChecked={setChecked} subHeaderLabel={subHeaderLabel} />
      </Grid>
    </Grid>
  );
};

export default TransferList;
