import { useTranslation } from "react-i18next"
import { useGetBankByBusinessQuery } from "../../../api/querys/Bank"
import Autocomplete from "@component/Autocomplete"

interface Props {
    bankId: string
    onChange?: (bankId:string) => void
}

const BanksInput = ({ bankId, onChange }:Props) => {
    const { data, isFetching } = useGetBankByBusinessQuery()
    const banks = data?.payload??[]
    const { t } = useTranslation()
    const value = banks.find(n => n._id === bankId)
    const handleChange = (bank:string) => {
        if( onChange !== undefined ) {
            onChange(bank)
        }
    }
    return(
        <Autocomplete
            onChange={(_, n:any) => handleChange(n?._id??"")}
            value={value}
            label={t('banks')!}
            options={banks}
            loading={isFetching}
        />
    )
}

export default BanksInput