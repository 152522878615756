import Box from "@component/Box";
import DatePicker from "@component/DatePicker";
import { useAppDispatch, useAppSelector } from "../../../hooks/state";
import dayjs from "dayjs";
import {
  updateBranch,
  updateBusinessType,
  updateEndDate,
  updateStartDate,
  updateZone,
  updateCustomer,
} from "../services/CustomerPurchaseTrackingSplice";
import CustomerBranchInput from "../../Customer/components/CustomerBranchInput";
import CustomerBusinessTypeInput from "../../Customer/components/CustomerBusinessTypeInput";
import CustomerZoneInput from "../../Customer/components/CustomerZoneInput";
import Typography from "@component/Typography";
import FilterListRounded from "@mui/icons-material/FilterListRounded";
import CustomerInput from "../../Customer/components/CustomerInput";

const AlignSelf = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <Box
      mx={1}
      display={"inline-flex"}
      alignItems={"center"}
      width={245}
      position="relative"
      top={8}
    >
      {children}
    </Box>
  );
};

const CustomerPurchaseTrackingFilters = () => {
  const { CustomerPurchaseTrackingState } = useAppSelector(
    ({ CustomerPurchaseTrackingState }) => ({ CustomerPurchaseTrackingState })
  );
  const dispatch = useAppDispatch();
  const { startDate, endDate, branch, businessType, customerId } =
    CustomerPurchaseTrackingState;

  return (
    <>
      <Box display={"flex"}>
        <FilterListRounded sx={{ marginRight: 1 }} />
        <Typography
          textTransform={"uppercase"}
          color={"GrayText"}
          fontWeight="500"
        >
          Filtros
        </Typography>
      </Box>
      <Box sx={{ overflow: "auto", whiteSpace: "nowrap", paddingBottom: 1 }}>
        <DatePicker
          name="startDate"
          label="Fecha inicial"
          value={dayjs(startDate).toDate()}
          onChange={({ value }) =>
            dispatch(updateStartDate(dayjs(value).format("MM-DD-YYYY")))
          }
        />
        <DatePicker
          name="endDate"
          label="Fecha final"
          value={dayjs(endDate).toDate()}
          onChange={({ value }) =>
            dispatch(updateEndDate(dayjs(value).format("MM-DD-YYYY")))
          }
        />
        <AlignSelf>
          <CustomerBranchInput
            customerBranchId={branch}
            onChange={(element, value) =>
              dispatch(updateBranch(value?._id ?? ""))
            }
          />
        </AlignSelf>
        <AlignSelf>
          <CustomerBusinessTypeInput
            customerBusinessTypeId={businessType}
            onChange={(element, value) =>
              dispatch(updateBusinessType(value?._id ?? ""))
            }
          />
        </AlignSelf>
        <AlignSelf>
          <CustomerZoneInput
            customerZoneId=""
            onChange={(element, value) =>
              dispatch(updateZone(value?._id ?? ""))
            }
          />
        </AlignSelf>
        <AlignSelf>
          <CustomerInput
            customerInputId={customerId}
            onChange={(element, value) => {
              dispatch(updateCustomer(value?._id ?? ""));
            }}
          />
        </AlignSelf>
      </Box>
    </>
  );
};

export default CustomerPurchaseTrackingFilters;
