import Divider from "@component/Divider"
import List, { ListItem, ListItemText } from "@component/List"
import { useGetCreditReport } from "../hooks/useCredits"
import { currencyFormat } from "@hook/useNumberFormat"
import { useAppSelector } from "@hook/state"

const CreditInformation = () => {
    const { CreditsState } = useAppSelector(({ CreditsState }) => ({ CreditsState }))
    const { dateRange, customerId } = CreditsState
    const { endDate, initDate } = dateRange
    const { paidCredits, pendingCredits, totalCredits, quantityCredits } = useGetCreditReport({ startDate: initDate, endDate, customerId })

    return(
        <List>
            <ListItem>
                <ListItemText primaryTypographyProps={{ textTransform: "uppercase", fontSize: '0.875rem', fontWeight: "500" }} secondaryTypographyProps={{ fontSize: '0.875rem', fontWeight: "500" }} primary={"Resultados"} secondary={`${currencyFormat(quantityCredits)} créditos`} />
            </ListItem>
            <Divider />
            <ListItem>
                <ListItemText primaryTypographyProps={{ textTransform: "uppercase", fontSize: '0.875rem', fontWeight: "500" }} secondaryTypographyProps={{ fontSize: '0.875rem', fontWeight: "500", color: (theme) => theme.palette.info.main }} primary={"Total créditos facturados"} secondary={`$ ${currencyFormat(totalCredits)}`} />
            </ListItem>
            <Divider />
            <ListItem>
                <ListItemText primaryTypographyProps={{ textTransform: "uppercase", fontSize: '0.875rem', fontWeight: "500" }} secondaryTypographyProps={{ fontSize: '0.875rem', fontWeight: "500", color: (theme) => theme.palette.success.main }} primary={"Total abonos realizados"} secondary={`$ ${currencyFormat(paidCredits)}`} />
            </ListItem>
            <Divider />
            <ListItem>
                <ListItemText primaryTypographyProps={{ textTransform: "uppercase", fontSize: '0.875rem', fontWeight: "500" }} secondaryTypographyProps={{ fontSize: '0.875rem', fontWeight: "500", color: (theme) => theme.palette.error.main }} primary={"Total pagos pendientes"} secondary={`$ ${currencyFormat(pendingCredits)}`} />
            </ListItem>
            <Divider />
        </List>
    )
}

export default CreditInformation