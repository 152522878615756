import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDeleteCategoryProductMutation, useRegisterCategoryProductMutation, useUpdateCategoryProductMutation } from "../../../api/querys/Product"
import type { ProductCategoriesProps } from '../../../interfaces/Product'

interface Props {
    typeForm?: "create" | "update"
}

export const useCategoryProducts = ({ typeForm }:Props) => {
    const [registerCategoryProduct] = useRegisterCategoryProductMutation()
    const [updateCategoryProduct] = useUpdateCategoryProductMutation()
    const [deleteCategoryProduct] = useDeleteCategoryProductMutation()
    
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    
    const useRegisterCategoryProduct = React.useCallback( async (payload:ProductCategoriesProps) => {
        if( payload.name.trim() === "" ){
            enqueueSnackbar(t('categoryNameIsARequiredField'), {variant: "warning"})
            return
        }
        if( typeForm === "update" && payload.name.trim() === "" ){
            enqueueSnackbar(t('itSeemsThatAnImportantPieceOfInformationIsMissingToUpdateTheProduct')!, {variant: "error"})
            return
        }
        const resp = typeForm === "create" ? await registerCategoryProduct(payload).unwrap() : await updateCategoryProduct(payload).unwrap()
        if( resp?.status === 1 ){
            const message = typeForm === "create" ? t('theProductCategoryWasRegisteredSuccessfully') : t('theProductCategoryWasSuccessfullyUpdated')!
            enqueueSnackbar(message, {variant: "success"})
        }else{
            enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest')!, {variant: "error"})
        }
    },[ enqueueSnackbar, registerCategoryProduct, updateCategoryProduct, typeForm, t ])

    const useDelCategoriesProduct = React.useCallback( async (categories:string[]) => {
        if( categories.length <= 0 ){
            enqueueSnackbar(t('selectAtLeastOneCategoryToRemove')!,{variant: "warning"})
            return
        }
        const resp = await deleteCategoryProduct(categories).unwrap()
        if( resp.status === 1 ){
            enqueueSnackbar(t('categoriesWereSuccessfullyRemoved')!,{variant: "success"})
            return
        }else{
            enqueueSnackbar(t('itSeemsThatAnImportantPieceOfInformationIsMissingToUpdateTheProduct')!,{variant: "error"})
            return
        }
    },[deleteCategoryProduct, enqueueSnackbar, t])

    return { useRegisterCategoryProduct, useDelCategoriesProduct }
}