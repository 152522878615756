import Fade from "@component/Fade"
import Grid from "@component/Grid"
import LockComponent from "@component/LockComponent";
import { useAppSelector } from "../../hooks/state";
import { useBusinessPermissions } from "../../hooks/usePermissions";
import ExcelProductsIndex from "./components/ExcelProductsIndex";
import ImportExportDataExcelProduct from "./components/ImportExportDataExcelProduct";
import PreviewExcelProducts from "./components/PreviewExcelProducts";

const ImportProducts = () => {
    const { Product } = useBusinessPermissions()
    const { ProductState } = useAppSelector(({ ProductState }) => ({ ProductState }))
    const { productsFromExcel } = ProductState
    return(
        <Fade in={true} >
            {Product.bulk_upload_from_file ?
                <Grid container spacing={1} >
                    <Grid xs={12} >
                        <ImportExportDataExcelProduct />
                    </Grid>
                    <Grid xs={12} >
                        {productsFromExcel.length >= 1 ?
                            <PreviewExcelProducts /> : <ExcelProductsIndex />
                        }
                    </Grid>
                </Grid>
                :
                <LockComponent />
            }
        </Fade>
    )
}

export default ImportProducts