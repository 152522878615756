import Box from "./Box"
import Divider from "./Divider"
import Typography from "./Typography"

interface Props {
    text: string
    variant?: "subtitle"
    hideDivider?: boolean
}

const Title = ({ text, variant, hideDivider }:Props) => {
    const variantText = () => {
        let sxStyles = {}
        switch (variant) {
            case "subtitle":
                sxStyles = {
                    fontSize: ".875rem",
                }
            break;
            default:
            break;
        }
        return sxStyles
    }
    return(
        <Box>
            <Typography fontSize={'1.2rem'} fontWeight={'bold'} textTransform={'uppercase'} sx={{...variantText()}} >
                {text}
            </Typography>
            {  (hideDivider === undefined || hideDivider === false) &&
                <Divider />
            }
        </Box>
    )
}

export default Title