import { useParams } from "react-router-dom"
import { usePurchaseData } from "@purchase_report_hook/usePurchaseReport"
import TemplateReportInvoice from "@purchase_report_component/TemplateReportInvoice"

const PurchaseInvoice = () => {
    const { purchaseId } = useParams()
    const { report } = usePurchaseData({ purchaseId: purchaseId! })

    return(
        <>
            <TemplateReportInvoice {...report} />
        </>
    )
}

export default PurchaseInvoice