import React from 'react';
import { useTranslation } from "react-i18next"
import { useGetProvidersByBusinessQuery } from "../../../api/querys/Provider"
import Autocomplete from "@component/Autocomplete"

type Props = Omit<React.ComponentProps<typeof Autocomplete>, "options" | "label" | "loading" | "value" > & { value?: string }

const InputSearchProviders = ({ value, ...rest }:Props) => {
    const { t } = useTranslation()
    const { data, isLoading } = useGetProvidersByBusinessQuery()
    const providers = data?.payload??[]
    const selected = providers.find(n => n._id === value) || null

    return(
        <>
            <Autocomplete
                value={selected}
                loading={isLoading}
                {...rest}
                options={providers}
                label={t('providers')!}
            />
        </>
    )
}

export default InputSearchProviders