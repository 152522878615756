import { createTheme } from '@mui/material/styles'
import AlatsiRegular from '../fonts/Alatsi-Regular.ttf'

const theme = createTheme({
    palette:{
        primary:{
            main: "#00B0E9",
            light: "#65E2FF",
            dark: "#0081B6",
            contrastText: "#FFFFFF"
        },
    },
    shape:{
        borderRadius: 8
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif", "Alatsi"',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides:{
                '@font-face':{
                    fontFamily: 'Alatsi',
                    fontStyle: 'normal',
                    src: `url(${AlatsiRegular})`
                },
                '*::-webkit-scrollbar': {
                    width: '0.5em',
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.2)',
                    borderRadius : "50px"
                },
            },
        },
    },
});

export default theme