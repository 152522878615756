import { useGetInventorySummaryQuery, useGetSummaryDetailsToCustomersQuery, useGetSummaryDetailsToSuppliersQuery, useGetSummaryOfBoxesQuery, useGetSummaryOfFixedExpensesQuery, useGetSummaryOfMovementsQuery, useGetSupplierSummaryQuery } from "@query/Panel"

export const useGetInventorySummary = (params={}) => {
    const { startDate, endDate } = params
    const { data, isLoading } = useGetInventorySummaryQuery({ startDate, endDate })
    const resp = (data?.payload??[])

    return { resp, isLoading }
}

export const useGetSupplierSummary = (params={}) => {
    const { startDate, endDate } = params
    const { data, isLoading } = useGetSupplierSummaryQuery({ startDate, endDate })
    let resp = (data?.payload??[])

    return { resp, isLoading }
}

export const useGetSummaryOfFixedExpenses = (params={}) => {
    const { startDate, endDate } = params
    const { data, isLoading } = useGetSummaryOfFixedExpensesQuery({ startDate, endDate })
    const resp = (data?.payload??[])

    return { resp, isLoading }
}

export const useGetSummaryDetailsToSuppliers = (params={}) => {
    const { startDate, endDate } = params
    const { data, isLoading } = useGetSummaryDetailsToSuppliersQuery({ startDate, endDate })
    const resp = (data?.payload??[])

    return { resp, isLoading }
}

export const useGetSummaryDetailsToCustomers = (params={}) => {
    const { startDate, endDate } = params
    const { data, isLoading } = useGetSummaryDetailsToCustomersQuery({ startDate, endDate })
    const resp = (data?.payload??[])

    return { resp, isLoading }
}

export const useGetSummaryOfMovements = (params={}) => {
    const { startDate, endDate } = params
    const { data, isLoading } = useGetSummaryOfMovementsQuery({ startDate, endDate })
    const resp = (data?.payload??[])

    return { resp, isLoading }
}

export const useGetSummaryOfBoxes = (params={}) => {
    const { startDate, endDate } = params
    const { resp:movements } = useGetSummaryOfMovements({ startDate, endDate })
    const { data, isLoading } = useGetSummaryOfBoxesQuery({ startDate, endDate })
    const resp = (data?.payload??[])

    return { resp, movements, isLoading }
}