import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { ProductCategoriesProps } from '../../../interfaces/Product'

interface InitialStateProps {
    typeForm: "create" | "update"
    isOpenForm: boolean
    categoryToEdit: ProductCategoriesProps
    selectionToDelete: string[]
    isOpenAlertDeleteCategoryProduct: boolean
}

const initialState:InitialStateProps = {
    typeForm: "create",
    isOpenForm: false,
    categoryToEdit: { name: "", _id: "" },
    selectionToDelete: [],
    isOpenAlertDeleteCategoryProduct: false
}
export const ProductCategorySlice = createSlice({
    name: 'productCategory',
    initialState,
    reducers: {
        displayProductCategoryForm: (state, action:PayloadAction<boolean>) => {
            state.isOpenForm = action.payload
        },
        toggleProductCategoryForm: (state, action:PayloadAction<InitialStateProps['typeForm']>) => {
            state.typeForm = action.payload
        },
        closeProductCategoryEditionForm: (state) => {
            state.typeForm = "create"
            state.categoryToEdit = { name: "", _id: "" }
            state.isOpenForm = false
        },
        loadProductCategoryToEdit: (state, action:PayloadAction<InitialStateProps['categoryToEdit']>) => {
            state.typeForm = "update"
            state.categoryToEdit = action.payload
            state.isOpenForm = true
        },
        selectCategoriesToDelete: (state, action:PayloadAction<string[]>) => {
            state.selectionToDelete = action.payload
        },
        showAlertDeleteCategory: (state, action:PayloadAction<boolean>) => {
            state.isOpenAlertDeleteCategoryProduct = action.payload
        },
    }
})

export const {
    displayProductCategoryForm,
    toggleProductCategoryForm,
    closeProductCategoryEditionForm,
    loadProductCategoryToEdit,
    selectCategoriesToDelete,
    showAlertDeleteCategory
} = ProductCategorySlice.actions

export default ProductCategorySlice.reducer