import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { not } from '../services';

const Actions = ({ leftData, right, setRight, left, setLeft, checked, setChecked, rightChecked, leftChecked, isLoading, handleSubmit }: any) => {
  const handleCheckedRight = () => {
    const result = right.concat(leftChecked);
    const newRole = leftData.filter( (n:any) => result.some((x:any) => x === n.name) );
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    handleSubmit(newRole);
  };

  const handleCheckedLeft = () => {
    const result = not(right, rightChecked);
    const newRole = leftData.filter( (n:any) => result.some((x:any) => x === n.name) );

    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    handleSubmit(newRole);
  };

  return (
    <Grid item>
      <Grid container direction='column' alignItems='center' >
        <Button
          sx={{ my: 0.5 }}
          variant='outlined'
          size='small'
          onClick={handleCheckedRight}
          disabled={leftChecked.length === 0 || isLoading}
          aria-label='move selected right'
        >
          &gt;
        </Button>
        <Button
          sx={{ my: 0.5 }}
          variant='outlined'
          size='small'
          onClick={handleCheckedLeft}
          disabled={rightChecked.length === 0 || isLoading}
          aria-label='move selected left'
        >
          &lt;
        </Button>
      </Grid>
    </Grid>
  );
};

export default Actions;
