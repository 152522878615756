import { Stack as StackMUI, StackProps } from '@mui/material'

type Props = Pick<StackProps, "maxWidth" | "width" | "divider" | "spacing" | "children" | "direction" | "justifyContent" | "alignItems" | "sx" | "p" | "pt" | "pb" | "pl" | "pr" | "px" | "py">

const Stack = ({children, ...rest}:Props) => {
    return(
        <StackMUI {...rest} >
            {children}
        </StackMUI>
    )
}

export default Stack