import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGetCategoriesProductQuery } from '../../../api/querys/Product'
import Autocomplete from "@component/Autocomplete"

interface Props {
    productCategoryId: string
    onChange?: (value:string, params:any) => void
}

const ProductCategoriesInput = ({ productCategoryId, onChange }:Props) => {
    const { t } = useTranslation()
    const { data, isSuccess, isLoading } = useGetCategoriesProductQuery()
    const productCategories = data?.payload?.productCategories?.results??[]
    const loadingData = isSuccess && !isLoading
    const value = productCategories.find((n:typeof productCategories[0]) => n._id === productCategoryId)

    return(
        <Autocomplete
            name='category'
            label={t('categories')!}
            options={productCategories}
            loading={!loadingData}
            value={value||null}
            onChange={(_, b:any) => {
                if( onChange !== undefined ){
                    onChange(b?._id??"", b)
                }
            }}
        />
    )
}

export default ProductCategoriesInput