import { GridColDef } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import DataGrid from "@component/DataGrid"
import { currencyFormat } from "../../../hooks/useNumberFormat";
import { useGetProductInventory } from "../hooks/useInventory";
import { useURLParams } from "@hook/useURLParams";
import Title from '@component/Title';
import List, { ListItem, ListItemText } from '@component/List';
import { FixedSizeList } from 'react-window';
import Box from '@component/Box';

const DataInventory = () => {
    const { getParameterGroup } = useURLParams()
    const { group="" } = getParameterGroup(['group'])
    const { categoryId } = useParams()
    const { list, listForCategory } = useGetProductInventory({ categoryId })

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Producto', flex: 1 },
        { field: 'packaging', headerName: 'Embalaje', flex: 1},
        { field: 'packagingQuantity', headerName: 'Cat. Embalaje', flex: 1, align: "center", renderCell: ({row}) => (<>x{currencyFormat(row.packagingQuantity, true)}</>) },
        { field: 'stockPerPacking', headerName: 'Stock embalaje', flex: 1, align: "center", renderCell: ({row}) => (<>{currencyFormat(row.stockPerPacking, true)}</>) },
        { field: 'unitaryStock', headerName: 'Stock unitario', flex: 1, renderCell: ({row}) => (<>{currencyFormat(row.unitaryStock, true)}</>) },
        { field: 'unitCost', headerName: 'Stock costo unitario', flex: 1, renderCell: ({row}) => (<>$ {currencyFormat(row.unitCost, true)}</>) },
        { field: 'costPerPacking', headerName: 'Costo embalaje', flex: 1, renderCell: ({row}) => (<>$ {currencyFormat(row.costPerPacking, true)}</>) },
        { field: 'totalStockCost', headerName: 'Stock costo total', flex: 1, renderCell: ({row}) => (<>$ {currencyFormat(row.totalStockCost, true)}</>) },
    ];
    return(
        <>
            { group === "categories" ?
                <>
                    <FixedSizeList height={(window.innerHeight-200)} itemCount={listForCategory.length} itemSize={550} width={"100%"} >
                        {({ index, style }) => {
                            let results = listForCategory[index].results;
                            return(
                                <Box key={index} style={style} >
                                    <Title text={(listForCategory[index]?.category??"")} variant='subtitle' hideDivider />
                                    <List>
                                        <ListItem>
                                            <ListItemText primary={`$ ${currencyFormat((listForCategory[index]?.totalInventory??0))}`} secondary="Total del inventario" />
                                        </ListItem>
                                    </List>
                                    <DataGrid
                                        columns={columns}
                                        rows={results}
                                    />
                                </Box>
                            )
                        }}
                    </FixedSizeList>
                </>
                :
                <DataGrid
                    columns={columns}
                    rows={list}
                />
            }
        </>
    )
}

export default DataInventory