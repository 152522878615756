import { GridColDef } from "@mui/x-data-grid"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import DataGrid from "@component/DataGrid"
import DataGridCustomHeaderToDelete from "@component/DataGridCustomHeaderToDelete"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import Routes from "../../../services/routes"
import { selectProviders, showAlertDeleteProvider } from "../services/ProviderSlice"
import AlertDeleteProvider from "./AlertDeleteProvider"
import { currencyFormat } from "@hook/useNumberFormat"
import CheckRounded from '@mui/icons-material/CheckRounded';
import ClearRounded from '@mui/icons-material/ClearRounded';
import { useGetProvidersByBusiness } from "../hooks/useProvider"

const ListProviders = () => {
    const { ProviderState } = useAppSelector(({ ProviderState }) => ({ ProviderState }))
    const { selectedProviders } = ProviderState
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { list, isLoading } = useGetProvidersByBusiness()
    const columns:Array<GridColDef> = [
        {maxWidth: 100, minWidth: 100, field: "advancesAvailable", headerName: t('Anticipo disponible')!, renderCell: ({ row }) => `$ ${currencyFormat(row.advancesAvailable??0)}` },
        {flex: 1, field: "name", headerName: t('provider')! },
        {flex: 1, field: "quantityProducts", headerName: t('Productos asignados')! },
        {maxWidth: 200, minWidth: 200, align: 'left', field: "representative", headerName: t('representative')! },
        {maxWidth: 150, minWidth: 150, align: 'left', field: "nit", headerName: t('nit')! },
        {maxWidth: 80, minWidth: 80, align: 'center', field: "code", headerName: t('code')!, renderCell: ({ api, id }) => `${api.getRowIndexRelativeToVisibleRows(id as number)+1}` },
        {flex: 1, field: "address", headerName: t('direction')! },
        {maxWidth: 80, minWidth: 80, align: 'center', field: "ivaResponsible", headerName: t('responsibleForValueAddedTax')! , renderCell: ({ row }) => <> {row.ivaResponsible ? <CheckRounded sx={{ color: (theme) => theme.palette.success.main }} /> : <ClearRounded sx={{ color: (theme) => theme.palette.error.main }} /> } </> },
        {maxWidth: 80, minWidth: 80, align: 'center', field: "tax", headerName: t('Retención en la fuente')! , renderCell: ({ row }) => <> {Boolean(row?.tax?.tax) ? <CheckRounded sx={{ color: (theme) => theme.palette.success.main }} /> : <ClearRounded sx={{ color: (theme) => theme.palette.error.main }} /> } </> },
    ]

    const handleCellClick = (params:any) => {
        const { field, row } = params
        if( field !== "__check__" ){
            navigate(Routes.indexProvider(row._id))
        }
    }

    const CustomToolbar = () => <DataGridCustomHeaderToDelete listItems={selectedProviders} texts={[t('selectedProviders')!, t('selectedProvider')!]} onClick={() => dispatch(showAlertDeleteProvider(true)) } />

    return(
        <>
            <DataGrid
                loading={isLoading}
                onRowSelectionModelChange={params => dispatch(selectProviders(params))}
                slots={{
                    toolbar: CustomToolbar
                }}
                onCellClick={handleCellClick}
                disableRowSelectionOnClick
                checkboxSelection
                rows={list}
                columns={columns}
            />
            <AlertDeleteProvider />
        </>
    )
}

export default ListProviders