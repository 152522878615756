import Dialog, { DialogContent } from "@component/Dialog"
import { useAppDispatch, useAppSelector } from "@hook/state"
import { Boxes } from "@interface/App"
import { ConceptExpense } from "@interface/Expense"
import { showFormOfExpense } from "../services/ExpenseSlice"
import ExpensesForm from "./ExpensesForm"

interface Props {
    box: Boxes
    conceptExpense: ConceptExpense
    bankId?: string
    maxAmount?: number
}

const CreateExpense = ({ box, conceptExpense, bankId, maxAmount }:Props) => {
    const dispatch = useAppDispatch()
    const { ExpenseState } = useAppSelector(({ ExpenseState }) => ({ ExpenseState }))
    const { openExpenseForm } = ExpenseState
    return(
        <Dialog fullWidth open={openExpenseForm} onClose={() => dispatch(showFormOfExpense(false))} maxWidth="xs" >
            <DialogContent>
                <ExpensesForm maxAmount={maxAmount} box={box} conceptExpense={conceptExpense} bankId={bankId} />
            </DialogContent>
        </Dialog>
    )
}

export default CreateExpense