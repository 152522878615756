import List, { ListItem, ListItemIcon, ListItemText } from "@component/List"
import { useGetBusinessCustomers } from "../hooks/useCustomer"
import { currencyFormat } from "@hook/useNumberFormat"
import GroupsRounded from '@mui/icons-material/GroupsRounded';
import Card from "@component/Card"
import CurrencyExchangeRounded from '@mui/icons-material/CurrencyExchangeRounded';
import MuseumRounded from '@mui/icons-material/MuseumRounded';
import React from "react"

const DiscriminationCustomers = () => {
    const { customers, totalAdvances, customersGroupByBranch } = useGetBusinessCustomers()

    return(
        <Card sx={{ padding: 1 }} >
            <List sx={{ padding: 0 }} >
                <ListItem sx={{ padding: 0 }} >
                    <ListItemText primary={currencyFormat(customers.length)} secondary="Clientes en lista" />
                    <ListItemIcon>
                        <GroupsRounded />
                    </ListItemIcon>
                </ListItem>
                <ListItem sx={{ padding: 0 }} >
                    <ListItemText primary={`$ ${currencyFormat(totalAdvances)}`} secondary="En anticipos" />
                    <ListItemIcon>
                        <CurrencyExchangeRounded />
                    </ListItemIcon>
                </ListItem>
                <ListItem sx={{ padding: 0 }} >
                    <ListItemText primary={`${currencyFormat(customersGroupByBranch.length)}`} secondary="Sucursales registradas con clientes" />
                    <ListItemIcon>
                        <MuseumRounded />
                    </ListItemIcon>
                </ListItem>
                {customersGroupByBranch.map((n, index) => {
                    return(
                        <React.Fragment key={index} >
                            <ListItem sx={{ padding: 0 }} >
                                <ListItemText primary={`${n?.results?.length??0} clientes`} secondary={`${n?._id?.name??"Sin asignar"}`} />
                                <ListItemIcon>
                                    <MuseumRounded />
                                </ListItemIcon>
                            </ListItem>
                        </React.Fragment>
                    )
                })}
            </List>
            {/* <Box sx={{ overflow: "auto", whiteSpace: 'nowrap', paddingBottom: 1 }} >
                <CardInformative onClick={() => dispatch(uploadCustomerList({ branch:'Clientes registrados', listCustomer: customers! })) } title="Clientes registrados" moreText={customersQuantity} icon={<Person2TwoTone />} />
                {customersByBranch.map((branch, index) => {
                    return(
                        <CardInformative onClick={() => dispatch(uploadCustomerList({ branch: `Sucursal: ${branch?._id?.name??"No definido"}`, listCustomer: branch.results! })) } key={index} title={Boolean(branch?._id?._id) ? branch._id.name : 'No definido'} moreText={branch.count} icon={<StorefrontTwoTone />} />
                    )
                })}
            </Box> */}
        </Card>
    )
}

export default DiscriminationCustomers