import Autocomplete from "@component/Autocomplete"
import { useURLParams } from "@hook/useURLParams"
import { useGetProvidersByBusinessQuery } from "@query/Provider"
import { useTranslation } from "react-i18next"

type AutocompleteProps = Pick<React.ComponentProps<typeof Autocomplete>, "onChange" | "label" | "value" | "sx">


const MultipleProvidersInput = ({...rest}:AutocompleteProps) => {
    const { getParameterGroup } = useURLParams()
    const { t } = useTranslation()
    const { data } = useGetProvidersByBusinessQuery()
    const providers = data?.payload??[]
    let { providers:providersParams="" } = getParameterGroup(["providers"])
    providersParams = providersParams.split("-")
    const values = providers.filter(n => providersParams.some((x:string) => x === n.name))

    return(
        <>
            <Autocomplete
                value={values}
                options={providers}
                label={t('providers')!}
                ChipProps={{
                    size:"small",
                    color: "primary",
                    variant: "outlined"
                }}
                multiple
                {...rest}
            />
        </>
    )
}

export default MultipleProvidersInput