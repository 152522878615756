import Title from '@component/Title';
import Grid from '../../components/Grid';
import LockComponent from '../../components/LockComponent';
import { useBusinessPermissions } from '../../hooks/usePermissions';
import CreateCustomerBranch from './components/CreateCustomerBranch';
import CustomerBranchList from './components/CustomerBranchList';
import CustomerBranchSearchBar from './components/CustomerBranchSearchBar';

const CustomerBranch: React.FC = () => {
    const { Customers } = useBusinessPermissions()
    return (
        <>
            {Customers.filters ?
                <Grid container spacing={1} >
                    <Grid xs={12}>
                        <Title text='Sucursales de clientes' />
                    </Grid>
                    <Grid xs={12}>
                        <CustomerBranchSearchBar />
                    </Grid>
                    <Grid xs={12}>
                        <CustomerBranchList />
                    </Grid>
                    <CreateCustomerBranch />
                </Grid>
                :
                <LockComponent />
            }
        </>
    );
};

export default CustomerBranch;
