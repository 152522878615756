import * as React from 'react';
import { NumericFormat as NumberFormats, InputAttributes } from 'react-number-format';
import TextField from '../components/TextField'
import { TextFieldProps } from '@mui/material'

interface CustomProps extends Pick<React.ComponentProps<typeof NumberFormats>, "min" | "isAllowed" | "thousandSeparator" | "prefix" | "suffix" > {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumberFormatCustom = React.forwardRef<typeof NumberFormats<InputAttributes>, CustomProps>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormats
      {...other}
      getInputRef={ref}
      onValueChange={(values:any) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      valueIsNumericString
      allowNegative={false}
    />
  );
});

interface State extends Pick<TextFieldProps, 'error' | 'size' | 'variant' | 'sx' | 'style' | 'id' | "onBlur" | "onFocus" | "disabled" | "InputLabelProps" | "onKeyDown" | "autoFocus" | "helperText" > {
  onChange?: (data:{target: { name: string, value: string }}) => void
  name?: string
  label?: string
  prefix?: string
  thousandSeparator?: string
  required?: boolean
  value?: any
  maxVal?: number
  suffix?: CustomProps['suffix']
  min?: CustomProps['min']
}

const NumberFormat = ({prefix="",thousandSeparator=" ", value, style, maxVal, suffix, size="medium", ...rest}:State) => {
  const withValueCap = (inputObj:any) => {
    const { value } = inputObj;
    if(maxVal !== undefined) {
      if (value <= maxVal) return true;
      return false;
    }
    return true;
};
  return (
    <TextField
      margin='none'
      {...rest}
      value={value}
      size={size}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
      }}
      inputProps={{
        style,
        prefix,
        value,
        thousandSeparator,
        isAllowed: withValueCap,
        suffix
      }}
    />
  );
}

export default NumberFormat