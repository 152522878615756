import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLoginUserMutation } from '../../../api/querys/Authentication'
import Avatar from "@component/Avatar"
import Box from "@component/Box"
import List, { ListItemAvatar, ListItemButton, ListItemText } from "@component/List"
import { useMessageErrors } from '../../../hooks/messageErrors'
import { useAppDispatch } from '../../../hooks/state'
import { UserDataProps } from '../../../interfaces/User'
import { getQuickLogin, setQuickLogin } from '../../../services/localStorage'
import { decode, encode } from '../../../services/parseData'
import { loadToken } from '../services/AuthenticationSlice'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import IconButton from '../../../components/IconButton'
import Tooltip from '../../../components/Tooltip'

const QuickLogin = () => {
    const [users, setUsers] = React.useState<Partial<UserDataProps>[]>([])
    const [loginUser] = useLoginUserMutation()
    const { messageErrors } = useMessageErrors()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const data = getQuickLogin()

    React.useEffect(() => {
        let userData:Partial<UserDataProps>[] = []
        if( Boolean(data) ){
            const dataToDecode = JSON.parse(data!)
            for( let index in dataToDecode ){
                const data = decode(dataToDecode[index])
                userData.push(data)
            }
        }
        setUsers(userData)
    },[data])
    const handleQuickLogin = async (index:number) => {
        const data = [...users]
        const { status, payload } = await loginUser(data[index]).unwrap()
        if( status === 0 ){
            messageErrors({ errorCode: payload?.code??0, key:payload?.key??"", respStatus: status, keyValue:payload?.keyValue??{} })
            return
        }
        if( !Boolean(payload.token) ){
            enqueueSnackbar(t('tokenError'), { variant: "error" });
            return
        }
        dispatch(loadToken(payload.token))
    }

    const handleRemoveAccount = (index:string) => {
        if( Boolean(index) ){
            const accounts = [...users]
            const removeAccounts = accounts.filter((n:any) => n.email !== index)
            setUsers(removeAccounts)
            const newAccountsList = removeAccounts.map(n => encode(n) )
            setQuickLogin(JSON.stringify(newAccountsList))
        }
    }

    if( Boolean(data) ){
        return(
            <Box maxHeight={180} overflow="auto" >
                <List>
                    {users.map((account, index) => {
                        return(
                            <Box key={index} display='flex' alignItems={'center'} px={2} >
                                <ListItemButton onClick={() => handleQuickLogin(index)} >
                                    <ListItemAvatar>
                                        <Avatar />
                                    </ListItemAvatar>
                                    <ListItemText primaryTypographyProps={{ style: { fontWeight: "bold" } }} primary={account.name} secondaryTypographyProps={{ style: { fontWeight: "500" } }} secondary={account.email} />
                                </ListItemButton>
                                <Tooltip title="Quitar cuenta" placement='left-start' >
                                    <IconButton size="small" color="default" onClick={() => handleRemoveAccount(account?.email??"")} >
                                        <DeleteRoundedIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )
                    })}
                </List>
            </Box>
        )
    }else{
        return <></>
    }
}

export default QuickLogin