import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetSalesReportQuery } from '../../../api/querys/PointOfSale';
import Box from '../../../components/Box';
import DataGrid, { GridToolbarContainer } from '../../../components/DataGrid';
import Typography from '../../../components/Typography';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import { currencyFormat } from '../../../hooks/useNumberFormat';
import Routes from '../../../services/routes';
import { resetFilters } from '../services/ReportOfTheDaySlice';

const ListOfTableReports = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ReportOfTheDayState } = useAppSelector(({ ReportOfTheDayState }) => ({
    ReportOfTheDayState,
  }));
  const { initDate, endDate, userId, customerId, invoiceNumbers, businessTypeId, zoneId, branchId, neighborhoodId, cityId, groupById, documentNumber } = ReportOfTheDayState;
  const { data } = useGetSalesReportQuery({ initDate, endDate, userId, customerId, invoiceNumbers, businessTypeId, zoneId, branchId, neighborhoodId, cityId, groupById, documentNumber });

  const reports = data!?.payload?.group;


  const HeaderDataGrid = (props: { name: string }) => {
    return (
      <GridToolbarContainer>
        <Box p={1}>
          <Typography
            textTransform={'uppercase'}
            fontWeight='bold'
          >
            {props?.name}
          </Typography>
        </Box>
      </GridToolbarContainer>
    );
  };

  const columns: Array<GridColDef> = [
    {
      field: 'customer',
      headerName: t('customer')!,
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => <> {t(row.customer)} </>,
    },
    { field: 'consecutive', headerName: '#FAC', minWidth: 100 },
    {
      field: 'total',
      headerName: t('sale')!,
      minWidth: 100,
      renderCell: ({ row }) => <>$ {currencyFormat(row.total)} </>,
    },
    {
      field: 'totalProfitCurrency',
      headerName: '$',
      headerAlign: 'center',
      minWidth: 100,
      renderCell: ({ row }) => (
        <>$ {currencyFormat(row.totalProfitCurrency)} </>
      ),
    },
    {
      field: 'totalProfitPercentage',
      headerName: '%',
      headerAlign: 'center',
      maxWidth: 70,
      minWidth: 70,
      renderCell: ({ row }) => (
        <>{currencyFormat(row.totalProfitPercentage)}%</>
      ),
    },
    {
      field: 'typePayment',
      headerName: t('paymentType')!,
      align: 'center',
      renderCell: ({ row }) => <> {t(row.typePayment)} </>,
    },
    { field: 'seller', headerName: t('seller')! },
  ];
  const handleCellClick = ({ id }: GridCellParams) => {
    const rowId = id as string;
    dispatch(resetFilters())
    navigate(Routes.indexInvoice(rowId));
  };

  return (
    <>
      {reports !== undefined &&
        reports !== null &&
        reports?.map((props) => {
          return (
            <Box
              mb={2}
              key={props?._id._id}
            >
              <DataGrid
                components={{ Toolbar: () => HeaderDataGrid(props?._id) }}
                rows={props?.result || []}
                columns={columns}
                onCellClick={handleCellClick}
              />
            </Box>
          );
        })}
    </>
  );
};
export default ListOfTableReports;
