import Autocomplete from "@component/Autocomplete"
import Box from "@component/Box"
import Checkbox from "@component/Checkbox"
import FilterByDateRange from "@component/FilterByDateRange"
import Grid from "@component/Grid"
import Stack from "@component/Stack"
import InputMultipleProviders from "@feature/Provider/components/InputMultipleProviders"
import { useAppContextSelector } from "@hook/state"
import { useURLParams } from "@hook/useURLParams"
import dayjs from "dayjs"
import ButtonMakePurchasePayment from "../ButtonMakePurchasePayment"
import PrintReportTemplateButton from "./PrintReportTemplateButton"
import DiscountButton from "../DiscountButton"

const PurchasingReportFilters = () => {
    const { handleActionParameter, getParameterGroup, handleParameterGroups, delQuery } = useURLParams()
    const { pay=null, group=null, paymentType=null, startDate="", endDate="", filterBy=null } = getParameterGroup(["group", "pay", "paymentType", "startDate", "endDate", "filterBy"])
    const { setGlobalState } = useAppContextSelector()
    const paymentsType = [
        { _id: 1, name: "Crédito", value: "credit" },
        { _id: 2, name: "Anticipo", value: "advance" },
        { _id: 3, name: "Efectivo", value: "cash" },
    ]

    const handleProviders = (providers:any[]) => {
        providers = providers.map((n) => n.name)
        if( providers.length >= 1 ){
            providers = providers.join("-") as any
            handleActionParameter("name", providers as any)
        }else{
            delQuery("name")
        }
    }

    const handleGroupByProvider = () => {
        if( group === "providers" ){
            delQuery("group")
        }else{
            handleActionParameter("group", "providers")
        }
        setGlobalState(n => {
            return {...n,
                PurchaseReportContext: {
                    ...n.PurchaseReportContext,
                    selectedShoppingList: []
                }
            }
        })
    }

    return(
        <>
            <Box display={'flex'} justifyContent={'space-between'} mb={1} >
                <Stack direction={'row'} alignItems={'center'} spacing={1} width={1} >
                    <Grid container >
                        <Grid xs={12} sx={{ display: "flex" }} >
                            <Stack direction={'row'} spacing={1} >
                                <Box width={180} >
                                    <Autocomplete
                                        size="small"
                                        label="Tipo de pago"
                                        value={ paymentsType.find(n => n.value === paymentType) || null }
                                        options={paymentsType}
                                        onChange={(_, params:any) => handleActionParameter("paymentType", params?.value??"")}
                                    />
                                </Box>
                                <Box width={180} >
                                    <InputMultipleProviders size="small" onChange={(_, params:any) => handleProviders(params)} limitTags={1} />
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid xs={12} >
                            <Checkbox
                                checked={pay === "pending"}
                                label="Ver pendientes por pagar"
                                onChange={() => (pay === "pending") ? delQuery("pay") : handleActionParameter("pay", "pending")}
                            />
                            <Checkbox
                                checked={group === "providers"}
                                label="Agrupar proveedores"
                                onChange={handleGroupByProvider}
                            />
                        </Grid>
                    </Grid>
                </Stack>
                <Box width={1} display={'flex'} justifyContent={'end'} >
                    <Grid container >
                        <Grid xs={12} sx={{ display: "flex", alignItems: "end", flexDirection: "column" }} >
                            <Checkbox
                                checked={filterBy === "expirationDate"}
                                label="Filtrar por fecha de vencimiento"
                                onChange={({ target }) => target.checked ? handleActionParameter("filterBy", "expirationDate") : delQuery("filterBy") }
                            />
                            <FilterByDateRange
                                maxDate={ (filterBy === "expirationDate") ? null : undefined }
                                size="small"
                                initDate={startDate}
                                endDate={endDate}
                                onChange={({ endDate, initDate }) => handleParameterGroups(["startDate", "endDate"], [dayjs(initDate).format("MM-DD-YYYY"), dayjs(endDate).format("MM-DD-YYYY")])}
                            />
                        </Grid>
                        <Grid xs={12} sx={{ display: "flex", justifyContent: "end", gap: 1, mt: 1 }} >
                            <DiscountButton />
                            <ButtonMakePurchasePayment />
                            <PrintReportTemplateButton />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}

export default PurchasingReportFilters