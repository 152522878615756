import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import {
  useRegisterZoneMutation,
  useUpdateZoneMutation,
  useDeleteZonesMutation,
} from "../../../api/querys/Zones";

import { ZoneData } from "@interface/Zones";
import { useAppDispatch, useAppSelector } from "../../../hooks/state";
import {
  displayZoneForm,
  showSecurityAlertClearZone,
  loadZonesToDelete,
} from "../services/ZoneSlice";

export const useCustomerZones = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [registerZone, { isLoading: isLoadingRegister }] =
    useRegisterZoneMutation();
  const [updateZone, { isLoading: isLoadingUpdate }] = useUpdateZoneMutation();
  const [deleteZones, { isLoading }] = useDeleteZonesMutation();

  const { zoneToEdit } = useAppSelector(({ ZoneState }) => ({ ...ZoneState }));

  const dispatch = useAppDispatch();

  const handleCreateZone = async (zone: ZoneData) => {
    try {
      if (zone.name.trim() === "") {
        enqueueSnackbar(t("zoneNameIsARequiredField"), {
          variant: "warning",
        });
        return;
      }

      const resp =
        zoneToEdit._id === ""
          ? await registerZone(zone).unwrap()
          : await updateZone(zone).unwrap();

      if (resp.status === 1) {
        const message =
          zoneToEdit._id === ""
            ? t("theZoneWasSuccessfullyRegistered")
            : t("theZoneWasUpdatedSuccessfully")!;

        enqueueSnackbar(message, { variant: "success" });
        dispatch(displayZoneForm(false));
      } else {
        enqueueSnackbar(t("anErrorOccurredWhileTryingToProcessTheRequest")!, {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar(t("errorCreatingZoneTryAgain")!, { variant: "error" });
    }
  };

  const handleDeleteZones = async (zones: string[]) => {
    try {
      if (zones.length <= 0) {
        enqueueSnackbar(t("selectAtLeastOneZoneToDelete")!, {
          variant: "warning",
        });
        return;
      }

      const resp = await deleteZones(zones).unwrap();

      if (resp.status === 1) {
        const message =
          zones.length > 1
            ? t("zonesWereSuccessfullyRemoved")!
            : t("zoneRemovedSuccessfully")!;

        enqueueSnackbar(message, { variant: "success" });
        dispatch(showSecurityAlertClearZone(false));
        dispatch(loadZonesToDelete([]));
      } else {
        enqueueSnackbar(t("anErrorOccurredWhileTryingToProcessTheRequest")!, {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar(t("errorDeletingZones")!, { variant: "error" });
    }
  };

  return {
    isLoadingRegister,
    isLoadingUpdate,
    isLoading,
    handleCreateZone,
    handleDeleteZones,
  };
};
