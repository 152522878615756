import React from 'react'
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import DataGrid, { GridToolbarContainer } from "@component/DataGrid";
import { useAppDispatch, useAppSelector } from "../../../hooks/state";
import { currencyFormat } from "../../../hooks/useNumberFormat";
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import IconButton from "@component/IconButton";
import Tooltip from "@component/Tooltip";
import MonetizationOnRounded from '@mui/icons-material/MonetizationOnRounded';
import { loadProductsFromExcel } from '../services/ProductSlice';
import BackupRounded from '@mui/icons-material/BackupRounded';
import Box from '../../../components/Box';
import { useProducts } from '../hooks/useProducts';

const PreviewExcelProducts = () => {
    const { t } = useTranslation()
    const { ProductState } = useAppSelector(({ ProductState }) => ({ ProductState }))
    const dispatch = useAppDispatch()
    const { useUploadMassiveProducts:UploadMassiveProducts, isLoadingUploadMassiveProducts } = useProducts({})
    const [profitCalc, setProfitCalc] = React.useState<boolean>(true)
    const { productsFromExcel } = ProductState

    const columns: GridColDef[] = [
        { field: 'skuCode', headerName: t('SKUCode')!, flex: 1 },
        { field: 'name', headerName: t('productName')!, flex: 1 },
        { field: 'category', headerName: t('category')!, flex: 1 },
        { field: 'cost', headerName: t('cost')!, flex: 1, renderCell: ({ row })  => <>$ {currencyFormat(row.cost)} </>},
        { field: 'sellPrice1', headerName: `${t('salePrice')}`, flex: 1, renderCell: ({ row }) => <>$ {currencyFormat(row.prices[0].sellPrice)}</> },
        { field: 'profit1', headerName: t('$')!, headerAlign: "center", flex: 1, hideable: profitCalc, renderCell: ({ row }) => <>$ {currencyFormat(row.prices[0].profit)}</> },
        { field: 'profitPercentage1', headerName: t('%')!, headerAlign: "center", hideable: profitCalc, flex: 1, renderCell: ({ row }) => <>{row.prices[0].percentageProfit}</> },
        { field: 'sellPrice2', headerName: `${t('salePrice')} 2`, flex: 1, renderCell: ({ row }) => <>$ {currencyFormat(row.prices[1].sellPrice)}</> },
        { field: 'profit2', headerName: t('$')!, headerAlign: "center", flex: 1, hideable: profitCalc, renderCell: ({ row }) => <>$ {currencyFormat(row.prices[1].profit)}</> },
        { field: 'profitPercentage2', headerName: t('%')!, headerAlign: "center", flex: 1, hideable: profitCalc, renderCell: ({ row }) => <>{row.prices[1].percentageProfit}</> },
        { field: 'sellPrice3', headerName: `${t('salePrice')} 3`, flex: 1, renderCell: ({ row }) => <>$ {currencyFormat(row.prices[2].sellPrice)}</> },
        { field: 'profit3', headerName: t('$')!, headerAlign: "center", flex: 1, hideable: profitCalc, renderCell: ({ row }) => <>$ {currencyFormat(row.prices[2].profit)}</> },
        { field: 'profitPercentage3', headerName: t('%')!, headerAlign: "center", flex: 1, hideable: profitCalc, renderCell: ({ row }) => <>{row.prices[2].percentageProfit}</> },
        { field: 'provider', headerName: t('provider')!, headerAlign: "center", flex: 1, renderCell: ({ row }) => <>{row.provider}</> },
    ];
    const HeaderTool = () => {
        return(
            <GridToolbarContainer>
                {productsFromExcel.length >= 1 ?
                    <Box>
                        <Tooltip title={t('clearFile')} >
                            <IconButton onClick={() => dispatch(loadProductsFromExcel([]))} color="error" >
                                <DeleteRounded />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('seeProfitCalculations')} >
                            <IconButton onClick={() => setProfitCalc(!profitCalc)} >
                                <MonetizationOnRounded />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('loadProducts')} >
                            <IconButton onClick={() => UploadMassiveProducts(productsFromExcel)} >
                                <BackupRounded />
                            </IconButton>
                        </Tooltip>
                    </Box> : <></>
                }
            </GridToolbarContainer>
        )
    }

    return(
        <>
            <DataGrid
                loading={isLoadingUploadMassiveProducts}
                slots={{
                    toolbar: HeaderTool
                }}
                columns={columns}
                rows={productsFromExcel}
            />
        </>
    )
}

export default PreviewExcelProducts