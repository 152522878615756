import IconButton from "@component/IconButton"
import Stack from "@component/Stack"
import Tooltip from "@component/Tooltip";
import { useAppDispatch } from "@hook/state";
import AddRounded from '@mui/icons-material/AddRounded';
import { updateCashierRecordFormValue } from "../services/CashierSlice";

const ListWithCashiersToolbar = () => {
    const dispatch = useAppDispatch()
    return(
        <>
            <Stack justifyContent={'space-between'} direction={'row'} pt={2} px={2} >
                <Stack></Stack>
                <Stack>
                    <Tooltip title="Añadir nuevo cajero" >
                        <IconButton squareButton color="primary" onClick={() => dispatch(updateCashierRecordFormValue(true)) } >
                            <AddRounded />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Stack>
        </>
    )
}

export default ListWithCashiersToolbar