import React from 'react';
import Button from "@component/Button"
import Form from "@component/Form"
import Grid from "@component/Grid"
import TextField from "@component/TextField"
import type { EmployeeDataForm } from '../../../interfaces/Employee';
import { useApiEmployee, useEmployee } from '../hooks/useEmployee';

interface Props{
    employee?: EmployeeDataForm
}

const EmployeeForm = ({employee}:Props) => {
    const { employeeData, handleChangeForm, typeForm } = useEmployee({employee})
    const { submitEmployeeData, isLoadingUpdate, isLoadingRegister } = useApiEmployee({typeForm})
    const handleChange = ({target}:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { name, value } = target
        handleChangeForm(name, value)
    }
    return(
        <Form onSubmit={() => submitEmployeeData(employeeData)} >
            <Grid container spacing={1} >
                <Grid xs={6} >
                    <TextField required name="name" margin="none" label="Nombre" value={employeeData.name} onChange={handleChange} />
                </Grid>
                <Grid xs={6} >
                    <TextField name="lastName" margin="none" label="Apellido" value={employeeData.lastName} onChange={handleChange} />
                </Grid>
                <Grid xs={6} >
                    <TextField name="email" margin="none" label="Correo Electronico" value={employeeData.email} onChange={handleChange} />
                </Grid>
                <Grid xs={6} >
                    <TextField name="numberPhone" margin="none" label="Numero de telefono" value={employeeData.numberPhone} onChange={handleChange} />
                </Grid>
                <Grid xs={12} >
                    <Button type='submit' loading={isLoadingUpdate||isLoadingRegister} > guardar cambios </Button>
                </Grid>
            </Grid>
        </Form>
    )
}

export default EmployeeForm