import Card from "@component/Card"
import InformativeList from "@component/InformativeList"
import Stack from "@component/Stack"
import Typography from "@component/Typography"
import { useGetCashier } from "@feature/Cashier/hooks/useCashier"
import { useAppSelector } from "@hook/state"
import { useParams } from "react-router-dom"
import { useCashierReport } from "../hooks/useMovementsCashier"

const SalesReportCashier = () => {
    const { cashierId } = useParams()
    const { cashier } = useGetCashier({ cashierId: cashierId! })
    const { CashierMovementsState } = useAppSelector(({ CashierMovementsState }) => ({ CashierMovementsState }))
    const { filterMovements } = CashierMovementsState
    const { startDate, endDate } = filterMovements
    const { summary } = useCashierReport({ cashierId: cashierId!, endDate, startDate })
    const { cashTotal, consignmentsTotal, expenses, inCash, paymentsCash, paymentsConsignment, saleCash, saleConsignment, creditNote } = summary
    return(
        <>
            <Typography fontWeight={'bold'} textTransform={'uppercase'} >Resumen - {`${cashier?.cashier?.name??""} ${cashier?.cashier?.lastName??""}`} </Typography>
            <Card>
                <InformativeList
                    data={[
                        { primaryText:"Venta en efectivo", secondaryText: "", value: saleCash },
                        { primaryText:"Pagos en efectivo", secondaryText: "", value: paymentsCash },
                        { primaryText:"Efectivo total", secondaryText: "", value: cashTotal },
                    ]} 
                />
                <InformativeList
                    isAnExpenses
                    data={[
                        { primaryText:"Gastos", secondaryText: "", value: expenses },
                        { primaryText:"Notas crédito", secondaryText: "", value: creditNote },
                    ]} 
                />
                <InformativeList
                    data={[
                        { primaryText:"En caja", secondaryText: "", value: inCash },
                    ]} 
                />
                <InformativeList
                    data={[
                        { primaryText:"Venta por consignación", secondaryText: "", value: saleConsignment },
                        { primaryText:"Pagos por consignación", secondaryText: "", value: paymentsConsignment },
                        { primaryText:"Consignación total", secondaryText: "", value: consignmentsTotal },
                    ]} 
                />
            </Card>
        </>
    )
}

export default SalesReportCashier