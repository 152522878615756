import { useTranslation } from 'react-i18next';
import EditRounded from '@mui/icons-material/EditRounded';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';

import { useAppDispatch, useAppSelector } from '../../../hooks/state';

import DataGrid from '../../../components/DataGrid';
import IconButton from '../../../components/IconButton';
import DataGridCustomHeaderToDelete from '../../../components/DataGridCustomHeaderToDelete';

import { useGetCustomerBranchQuery } from '../../../api/querys/Customer';
import {
  loadBranchToEdit,
  selectBranchToDelete,
  showAlertDeleteBranch,
} from '../services/CustomerBranchSlice';
import AlertDeleteBranchOffices from './AlertDeleteCustomerBranch';
import { CustomerBranchProps } from '../../../interfaces/Customer';

const CustmorBranchOfficesList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { CustomerBranchState } = useAppSelector(({ CustomerBranchState }) => ({
    CustomerBranchState,
  }));

  const { selectionToDelete } = CustomerBranchState;

  const { data: branchOfficesData, isLoading: branchOfficesIsLoading } =
    useGetCustomerBranchQuery();

  const branchOffices =
    branchOfficesData?.payload?.customerBranch?.results ?? [];

  const handleDeleteBranchOffice = () => {
    dispatch(showAlertDeleteBranch(true));
  };

  const handleEditBranchOffice = (row: CustomerBranchProps) => {
    dispatch(loadBranchToEdit(row));
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('branch')!,
      flex: 1,
    },
    {
      field: ' ',
      align: 'center',
      maxWidth: 80,
      minWidth: 80,
      renderCell: ({ row }) => {
        return (
          <IconButton onClick={() => handleEditBranchOffice(row)}>
            <EditRounded />
          </IconButton>
        );
      },
    },
  ];

  const HeaderDataGrid = () => (
    <DataGridCustomHeaderToDelete
      listItems={selectionToDelete}
      texts={[t('selectedBranches'), t('selectedBranch')]}
      title=''
      onClick={handleDeleteBranchOffice}
    />
  );

  const handleSelection = (items: GridRowSelectionModel) => {
    const arrayOfItems = items as Array<string>

    dispatch(selectBranchToDelete(arrayOfItems));
  };

  return (
    <>
      <DataGrid
        onRowSelectionModelChange={handleSelection}
        checkboxSelection
        disableRowSelectionOnClick
        rowHeight={30}
        rows={branchOffices}
        columns={columns}
        loading={branchOfficesIsLoading}
        components={{
          Toolbar: HeaderDataGrid,
        }}
      />
      <AlertDeleteBranchOffices />
    </>
  );
};

export default CustmorBranchOfficesList;
