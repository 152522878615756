import { AppBar as MuiAppBar, AppBarProps } from '@mui/material'
import { styled } from '@mui/material/styles';
import ApplicationMode from '../../../components/ApplicationMode';
import Box from '../../../components/Box';
import Toolbar from '../../../components/Toolbar';
import AccountMenu from '../../../components/UserMenu';
import DehazeRounded from '@mui/icons-material/DehazeRounded';
import IconButton from '../../../components/IconButton';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import { showDrawer } from '../../../services/AppSlice';
import PointOfSaleRounded from '@mui/icons-material/PointOfSaleRounded';
import Tooltip from '../../../components/Tooltip';
import { openPointOfSaleWindow } from '../../PointOfSale/services/PointOfSaleSlice';
import { useBusinessPermissions } from '../../../hooks/usePermissions';
// import Notifications from '@component/Notifications';

interface Props extends AppBarProps{
    open?: boolean
    drawerWidth?: number
    responsive?: boolean
}

const CustomAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth' && prop !== 'responsive',
})<Props>(({ theme, open, drawerWidth, responsive }) => ({
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    border: "none",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...(responsive && {
        background: theme.palette.primary.dark
    }),
}));

const AppBar = ({ drawerWidth=250 }:Props) => {
    const { AppState } = useAppSelector(({ AppState }) => ({ AppState }))
    const { openDrawer:open, isResponsiveDesign } = AppState
    const dispatch = useAppDispatch()
    const { PointOfSale } = useBusinessPermissions()

    return (
        <CustomAppBar open={open} responsive={isResponsiveDesign} drawerWidth={drawerWidth} position="fixed" elevation={0} >
            <Toolbar>
                <Box width={1} display={'flex'} justifyContent={'space-between'} >
                    <Box display={'flex'} alignItems={'center'} >
                        { !open &&
                            <IconButton squareButton onClick={ () => dispatch(showDrawer(true)) } >
                                <DehazeRounded />
                            </IconButton>
                        }
                    </Box>
                    <Box display={'flex'} alignItems={'center'} >
                        <Box>
                            {/* <Notifications /> */}
                        </Box>
                        {PointOfSale.lock_module &&
                            <Tooltip title="POS" >
                                <IconButton squareButton onClick={()=> dispatch(openPointOfSaleWindow(true))} >
                                    <PointOfSaleRounded />
                                </IconButton>
                            </Tooltip>
                        }
                        <Box>
                            <ApplicationMode />
                        </Box>
                        <AccountMenu />
                    </Box>
                </Box>
            </Toolbar>
        </CustomAppBar>
    )
}

export default AppBar