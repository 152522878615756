import { useTranslation } from 'react-i18next';
import { useGetCustomersNeighborhoodQuery } from '../../../api/querys/Customer';
import {
  Autocomplete as AutocompleteMUI,
} from '@mui/material';
import Autocomplete from '../../../components/Autocomplete';

type AutocompleteMUIProps = Omit<
  React.ComponentProps<typeof AutocompleteMUI>,
  'renderInput' | "options"
>;

interface Props extends AutocompleteMUIProps {
  neighborhoodsId: string;
}

const CustomerNeighborhoodsInput: React.FC<Props> = ({ neighborhoodsId, ...rest }) => {
  const { t } = useTranslation();

  const { data: neighborhoodsData, isLoading } = useGetCustomersNeighborhoodQuery();

  let neighborhoodArray:any = neighborhoodsData?.payload??{};
  neighborhoodArray = neighborhoodArray?.customerNeighborhood?.results??[]
  const value = neighborhoodArray.find((n: typeof neighborhoodArray[0]) => n._id === neighborhoodsId);

  return (
    <Autocomplete
      name='Neighborhoods'
      label={t('neighborhoods')!}
      options={neighborhoodArray}
      loading={isLoading}
      value={value || null}
      {...rest}
    />
  );
};

export default CustomerNeighborhoodsInput;
