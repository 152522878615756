import { useGetDiscountsOnPurchasesQuery, useRemovePurchaseDiscountMutation } from "@query/PurchasingSuppliers"
import { isErrorResponse, isSuccessResponse } from "@service/AnswersServices"

export const useDiscountShopping = () => {
    const [ removePurchaseDiscountMutation, { isLoading:isLoadingRemovePurchaseDiscountMutation, } ] = useRemovePurchaseDiscountMutation()
    const removePurchaseDiscount = async ({ discountId }:{ discountId: string }) => {
        try {
            const resp = await removePurchaseDiscountMutation({ discountId }).unwrap()
            if( resp.status === 1 ){
                isSuccessResponse("El descuento a la compra se removió satisfactoriamente")
            }else{
                isErrorResponse()
            }
        } catch (error) {
            console.log(error)
        }
    }
    return {
        removePurchaseDiscount,
        isLoadingRemovePurchaseDiscountMutation
    }
}

export const useGetDiscountsOnPurchases = () => {
    const { data, isLoading } = useGetDiscountsOnPurchasesQuery()
    const resp = (data?.payload??[])

    return {
        resp,
        isLoading,
        totalDiscounts: resp.reduce((acc:any, val:any) => acc+(val?.discountValue??0),0)
    }
}