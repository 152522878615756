import React from 'react';
import { Box as BoxMUI, BoxProps } from '@mui/material'

const Box = React.forwardRef<HTMLDivElement, BoxProps>(({ children, ...rest }, ref) => {
    return(
        <BoxMUI ref={ref} {...rest} >
            {children}
        </BoxMUI>
    )
})

export default Box