import React from 'react';
import { useTranslation } from "react-i18next"
import Button from "@component/Button"
import Form from "@component/Form"
import Grid from "@component/Grid"
import IconButton from '../../../components/IconButton';
import NumberFormat from "@component/NumberFormat"
import BanksInput from "../../Banks/components/BanksInput"
import WalletsInput from './WalletsInput';
import ArrowBackIosRounded from '@mui/icons-material/ArrowBackIosRounded';
import Tooltip from '../../../components/Tooltip';
import dayjs from 'dayjs';
import { useInvoice } from '../hooks/useInvoice';

interface Props {
    maxValue?: number
    onBack?: () => void
    invoiceId: string
}

export interface Payment {
    wallet: "cash" | "consignment",
    bank: string,
    date: Date
    amount: number | undefined
}

const formSubscription:Payment = {
    wallet: "cash",
    bank: "",
    date: dayjs().toDate(),
    amount: undefined
}

const SubscriptionForm = ({ maxValue, onBack, invoiceId }:Props) => {
    const [form, setForm] = React.useState<Payment>({...formSubscription})
    const { t } = useTranslation()
    const { addPaymentToInvoice, isLoadingAddPaymentToInvoiceMutation } = useInvoice()

    return(
        <Form onSubmit={() => addPaymentToInvoice({invoiceId,...form}, onBack)} >
            <Grid container spacing={1} >
                { onBack !== undefined &&
                    <Grid xs={12} >
                        <Tooltip title={t('return')} >
                            <IconButton onClick={onBack} >
                                <ArrowBackIosRounded />
                            </IconButton>                    
                        </Tooltip>
                    </Grid>
                }
                <Grid xs={form.wallet === "cash" ? 12 : 6} >
                    <WalletsInput
                        onChange={(wallet) => setForm(n => ({...n, wallet})) }
                    />
                </Grid>
                <Grid xs={6} sx={{ display: form.wallet === "cash" ? "none" : "block" }} >
                    <BanksInput
                        bankId={form.bank}
                        onChange={(bank) => setForm(n => ({...n, bank})) }
                    />
                </Grid>
                <Grid xs={12} >
                    <NumberFormat
                        value={form.amount}
                        maxVal={maxValue}
                        prefix="$ "
                        label={t('payment')!}
                        onChange={({ target }) => setForm(n => ({...n, amount: parseFloat(target.value) })) }
                    />
                </Grid>
                <Grid xs={12} >
                    <Button loading={isLoadingAddPaymentToInvoiceMutation} type="submit" >{t('pay')}</Button>
                </Grid>
            </Grid>
        </Form>
    )
}

export default SubscriptionForm