import React from "react";
import { useTranslation } from "react-i18next"
import Box from "@component/Box";
import { InvoiceProps } from '../../../interfaces/PointOfSale'
import { BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import Table, { TableBody, TableCell, TableHead, TableRow } from "@component/Table";
import { currencyFormat } from "../../../hooks/useNumberFormat";
import { useAppSelector } from "../../../hooks/state";

const TableCellCustomer = styled(TableCell)(({theme}) => ({
    lineHeight: '0.43',
    borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
    fontSize: '.7rem',
    fontWeight: 'bold'
}))

const TableCellProduct = styled(TableCell)(({theme}) => ({
    lineHeight: '0.43',
    borderBottom: "1px solid #6c6c6c",
    fontSize: '.7rem'
}))

interface Props extends InvoiceProps {
    sx?: BoxProps['sx']
}

const TemplateLargeInvoice = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { t } = useTranslation()
    const { AppState } = useAppSelector(({ AppState }) => ({ AppState }))
    const { invoiceAlphabeticalOrder } = AppState
    let { business, consecutive, createdAt, seller, customer, products, total, sx } = props
    products = products??[] as InvoiceProps['products']
    const productsAlphabeticalOrder = [...products].sort((a,b) => a.name.localeCompare(b.name))
    const details = invoiceAlphabeticalOrder === "default" ? products : productsAlphabeticalOrder
    return(
        <Box ref={ref} sx={{width: 1, padding: 1.5, ...sx}} >
            <Box display={'flex'} justifyContent="space-between" alignItems={'center'} >
                <Box fontSize={'0.8rem'} fontWeight="500" >
                    <Box fontWeight="bold" >
                        {t('dateAndTime')}
                    </Box>
                    { dayjs(createdAt).format("DD-MM-YYYY HH:mm A") }
                </Box>
                <Box textAlign={'center'} >
                    <Box fontSize={'1rem'} fontWeight="bold" >
                        { business?.name??"" }
                    </Box>
                    <Box fontSize={'0.8rem'} fontWeight="bold" >
                        {business?.address??""}
                    </Box>
                    <Box fontSize={'0.8rem'} fontWeight="bold" >
                        Tel. {business?.phone??''}
                    </Box>
                </Box>
                <Box display='inline-flex' flexDirection={'column'} >
                    <Box fontWeight={'bold'} border={1} borderRadius={5} borderColor='grey.500' p={1} height="fit-content" fontSize={'.8rem'} >
                        #FAC {consecutive <= 9 ? `0${consecutive??''}` : `${consecutive??''}`}
                    </Box>
                </Box>
            </Box>
            <Box display={'flex'} >
                <Box width={'auto'} >
                    <Table size="small" >
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCellCustomer>{t('customer')}:</TableCellCustomer>
                                <TableCellCustomer align="left"> {customer?.name??'' }</TableCellCustomer>
                            </TableRow>
                            <TableRow>
                                <TableCellCustomer>{t('phoneNumber')}:</TableCellCustomer>
                                <TableCellCustomer align="left">{ customer?.phoneNumber??'' }</TableCellCustomer>
                            </TableRow>
                            <TableRow>
                                <TableCellCustomer>{t('city')}:</TableCellCustomer>
                                <TableCellCustomer align="left">{ customer?.city??'' }</TableCellCustomer>
                            </TableRow>
                            <TableRow>
                                <TableCellCustomer>{t('address')}:</TableCellCustomer>
                                <TableCellCustomer align="left">{ customer?.address??'' }</TableCellCustomer>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            </Box>
            <Table size="small" >
                <TableHead>
                    <TableRow>
                        <TableCell>{t('quantity')}</TableCell>
                        <TableCell>{t('product')}</TableCell>
                        <TableCell>{t('unitValue')}</TableCell>
                        <TableCell>{t('total')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { details?.map((product, index) => (
                        <TableRow key={index} >
                            <TableCellProduct  align="center">{product?.quantity??''}</TableCellProduct>
                            <TableCellProduct >{product?.name??''}</TableCellProduct>
                            <TableCellProduct >$ {currencyFormat(product?.salePrice??0)}</TableCellProduct>
                            <TableCellProduct  align="right" >$ {currencyFormat(((product?.quantity??0)*(product?.salePrice??0)))}</TableCellProduct>
                        </TableRow>
                    )) }
                </TableBody>
            </Table>
            <Box display={'flex'} justifyContent={'end'} >
                <Box width={'auto'} >
                    <Table size="small" >
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCellProduct >{t('subtotal')}</TableCellProduct>
                                <TableCellProduct align="right"> $ {currencyFormat(total??0)} </TableCellProduct>
                            </TableRow>
                            <TableRow>
                                <TableCellProduct sx={{ fontWeight: 'bold' }} >{t('total')}</TableCellProduct>
                                <TableCellProduct sx={{ fontWeight: 'bold' }} align="right"> $ {currencyFormat(total??0)} </TableCellProduct>
                            </TableRow>
                            <TableRow>
                                <TableCell >{t('seller')}</TableCell>
                                <TableCell align="right">{ seller?.name??"" }</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            </Box>
        </Box>
    )
})

export default TemplateLargeInvoice