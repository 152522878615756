import { Popover as PopoverMUI, PopoverProps as PopoverPropsMUI } from "@mui/material" 

type PopoverProps = Pick<PopoverPropsMUI, "elevation" | "PaperProps" | "sx" | "open" | "children" | "anchorEl" | "anchorOrigin" | "transformOrigin" | "onClose" >

const Popover = ({ children, ...rest }:PopoverProps) => {
    return(
        <PopoverMUI {...rest} >
            {children}
        </PopoverMUI>
    )
}

export default Popover