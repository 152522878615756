import { createSlice } from '@reduxjs/toolkit'
import type { ProductsForPointOfSaleResponse } from '../../../interfaces/Product'
import type { InvoiceProps, ListProductsInvoice, SaleReportProps } from '../../../interfaces/PointOfSale'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface InitialStateProps {
    registerCreditNote: boolean
    isOpenPointOfSale: boolean
    noteNoteCredit: string
    isOpenCashierWindow: boolean
    windowProduct: {
        openWindow: boolean
        productSelected: ProductsForPointOfSaleResponse['payload']['productsForPointOfSale']['results'][0] | undefined
    }
    productList: Array<ListProductsInvoice>
    customer: Partial<InvoiceProps['customer']> | null
    total: number
    totalProfitPercentage: number | string
    totalProfitCurrency: number
    alertClearBillingArea: boolean
    lastBill: Omit<InvoiceProps, "isArchived">
    saleReportUser: SaleReportProps['report']
    totalSaleUser: number
    isOpenFormCreateQuickClient: boolean
    isOpenFormCreateQuickProduct: boolean
    previewInvoice: Partial<InvoiceProps>
    invoiceIdentifiers: {
        _id: string
        consecutive: number
    }
}

const windowProduct:InitialStateProps['windowProduct'] = {
    openWindow: false,
    productSelected: undefined,
}

const initialState:InitialStateProps = {
    registerCreditNote: false,
    isOpenPointOfSale: false,
    isOpenCashierWindow: false,
    windowProduct: {...windowProduct},
    productList: [],
    customer: null,
    total: 0,
    totalProfitPercentage: 0,
    totalProfitCurrency: 0,
    alertClearBillingArea: false,
    lastBill: {
        _id: "",
        bank: {name:"", _id:""},
        business: {_id:"", name:"", address: "", nit:"", phone: ""},
        consecutive: 0,
        createdAt: "",
        creditExpirationDate: "",
        customer: {
            _id: "", 
            address: "", 
            city: '',
         name:"", phoneNumber: ""},
        deliveryName: "",
        deliveryAmount: 0,
        paymentCredits: [],
        products: [],
        seller: {_id: "", name: ""},
        total: 0,
        totalPayments: 0,
        totalProfitCurrency: 0,
        totalProfitPercentage: 0,
        typePayment: "undefined",
        invoiceType: "sale",
        totalIncome: 0,
        totalReal: 0,
        totalReturned: 0,
        electronicConsecutive: ""
    },
    saleReportUser: [],
    totalSaleUser: 0,
    isOpenFormCreateQuickClient: false,
    isOpenFormCreateQuickProduct: false,
    previewInvoice: {},
    invoiceIdentifiers: {
        _id: "",
        consecutive: 0
    },
    noteNoteCredit: ""
}
export const PointOfSaleSlice = createSlice({
    name: 'pointOfSale',
    initialState,
    reducers: {
        showCashierWindow: (state, action:PayloadAction<boolean>) => {
            state.isOpenCashierWindow = action.payload
        },
        showFormCreateQuickClient: (state, action:PayloadAction<boolean>) => {
            state.isOpenFormCreateQuickClient = action.payload
        },
        showFormCreateQuickProduct: (state, action:PayloadAction<boolean>) => {
            state.isOpenFormCreateQuickProduct = action.payload
        },
        uploadInvoicePreview: (state, action:PayloadAction<Partial<InvoiceProps>>) => {
            state.previewInvoice = action.payload
        },
        removeProductFromList: (state, action:PayloadAction<string>) => {
            const list = [...state.productList]
            const indexProduct = list.findIndex(n => n._id === action.payload)
            if( indexProduct >= 0 ){
                list.splice(indexProduct, 1)
                state.productList = list
            }
        },
        showPOSProductWindow: (state, action:PayloadAction<{isOpen: InitialStateProps['windowProduct']['openWindow'], productSelected: InitialStateProps['windowProduct']['productSelected']}>) => {
            if( action.payload.isOpen === true && action.payload.productSelected !== undefined && Boolean(action.payload.productSelected?._id) ){
                state.windowProduct.openWindow = true
                state.windowProduct.productSelected = action.payload.productSelected
            }else{
                state.windowProduct.openWindow = false
                state.windowProduct.productSelected = undefined
            }
        },
        openPointOfSaleWindow: (state, action:PayloadAction<boolean>) => {
            state.isOpenPointOfSale = action.payload
            if( action.payload === false ){
                state.registerCreditNote = false
            }
        },
        loadSalesList: (state, action:PayloadAction<Array<ListProductsInvoice>>) => {
            state.productList = action.payload
        },
        selectCustomerForInvoice: (state, action:PayloadAction<InitialStateProps['customer']>) => {
            state.customer = action.payload
        },
        updateInvoiceTotal: (state, action:PayloadAction<{ total:number,totalProfitPercentage:number | string, totalProfitCurrency:number }>) => {
            state.total = action.payload.total
            state.totalProfitPercentage = action.payload.totalProfitPercentage
            state.totalProfitCurrency = action.payload.totalProfitCurrency
        },
        clearBillingArea: (state) => {
            state.productList = []
            state.customer = null
            state.total = 0
            state.registerCreditNote = false
            state.totalProfitPercentage = 0
            state.totalProfitCurrency = 0
            state.invoiceIdentifiers._id = ""
            state.invoiceIdentifiers.consecutive = 0
        },
        showAlertClearBillingArea: (state) => {
            state.alertClearBillingArea = !state.alertClearBillingArea
        },
        loadLastInvoice: (state, action:PayloadAction<InvoiceProps>) => {
            state.lastBill = action.payload
        },
        uploadUserSaleInformation: (state, action:PayloadAction<{ saleReportUser: InitialStateProps['saleReportUser'], totalSaleUser:InitialStateProps['totalSaleUser'] }>) => {
            state.saleReportUser = action.payload.saleReportUser
            state.totalSaleUser = action.payload.totalSaleUser
        },
        uploadInvoiceIdentifiers: (state, action:PayloadAction<{_id: string, consecutive: number}>) => {
            state.invoiceIdentifiers._id = action.payload._id
            state.invoiceIdentifiers.consecutive = action.payload.consecutive
        },
        activateCreditNoteRegistration: (state, action:PayloadAction<boolean>) => {
            state.registerCreditNote = action.payload
        },
        updateNoteNoteCredit: (state, action:PayloadAction<string>) => {
            state.noteNoteCredit = action.payload
        },
    }
})

export const {
    showPOSProductWindow,
    openPointOfSaleWindow,
    loadSalesList,
    selectCustomerForInvoice,
    updateInvoiceTotal,
    clearBillingArea,
    showAlertClearBillingArea,
    loadLastInvoice,
    uploadUserSaleInformation,
    removeProductFromList,
    showFormCreateQuickClient,
    showFormCreateQuickProduct,
    uploadInvoicePreview,
    uploadInvoiceIdentifiers,
    showCashierWindow,
    activateCreditNoteRegistration,
    updateNoteNoteCredit,
} = PointOfSaleSlice.actions

export default PointOfSaleSlice.reducer