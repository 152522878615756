import { useDispatch } from 'react-redux';
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useDeleteBankMutation , useUpdateBankMutation, useRegisterBankMutation, useGetConsignmentHistoryQuery} from "../../../api/querys/Bank"
import { openFormCreateBanks, showAlertDeleteBanks } from "../services/BankSlice"
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { ConsignmentHistoryAsyncResponse, IBankProps } from '../../../interfaces/Bank';
import { DateRangeFilter } from '@interface/App';

export const useBank = () => {
    const dispatch = useDispatch()
    const [ registerBankMutation, {isLoading:isLoadingRegisterBank} ] = useRegisterBankMutation()
    const [ updateBankMutation, {isLoading:isLoadingUpdateBankMutation} ] = useUpdateBankMutation()
    const [ deleteBankMutation, {isLoading:isLoadingDeleteBankMutation} ] = useDeleteBankMutation()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const registerBanks = async (bank:IBankProps) => {
        try {
            if( Boolean(bank._id) ){
                const resp = await updateBankMutation(bank).unwrap()
                if( resp.status === 1 ){
                    enqueueSnackbar(t('BanksDataUpdatedSuccessfully')!,{ variant: "success" })
                }else{
                    enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'),{ variant: "error" })
                }
            }else{
                const resp = await registerBankMutation(bank).unwrap()
                if( resp.status === 1 ){
                    enqueueSnackbar(t('theBankWasRegisteredSuccessfully')!,{ variant: "success" })
                    dispatch(openFormCreateBanks(false))
                }else{
                    enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'),{ variant: "error" })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const deleteBanks = async (banks:GridRowSelectionModel) => {
        try {
            const resp = await deleteBankMutation(banks).unwrap()
            if( resp.status === 1 ){
                const message = banks.length >= 2 ? t('BanksWereSuccessfullyRemoved') : t('theBankWasRemovedSuccessfully')
                enqueueSnackbar(message,{ variant: "success" })
                dispatch(showAlertDeleteBanks(false))
            }else{
                enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'),{ variant: "error" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        registerBanks,
        deleteBanks,
        isLoadingRegisterBank,
        isLoadingUpdateBankMutation,
        isLoadingDeleteBankMutation
    }
}

export const useGetConsignmentHistory = (params:DateRangeFilter & {bankId: string}) => {
    const { data, isLoading } = useGetConsignmentHistoryQuery(params)
    let history = data?.payload??[] as ConsignmentHistoryAsyncResponse['payload']

    return {history, isLoading}
}