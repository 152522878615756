import { useURLParams } from "@hook/useURLParams"
import { ProductsForMassEditionAsyncResponse, UpdateProductFieldParams } from "@interface/Product"
import { useDeleteProductMutation, useGetProductsForMassEditionQuery, useUpdateProductFieldMutation } from "@query/Product"
import { blockService, isErrorResponse, isSuccessResponse } from "@service/AnswersServices"
import { useTranslation } from "react-i18next"
import Swal from "sweetalert2"

export const useMassProduct = () => {
    const [updateProductFieldMutation, { isLoading: isLoadingUpdateProductFieldMutation }] = useUpdateProductFieldMutation()
    const [ deleteProductMutation, { isLoading:isLoadingDeleteProductMutation } ] = useDeleteProductMutation()
    const { t } = useTranslation()
    const { delQueries } = useURLParams()

    const updateProductField = async (params: UpdateProductFieldParams) => {
        try {
            const { field } = params
            if (isLoadingUpdateProductFieldMutation) {
                Swal.fire({
                    icon: "error",
                    text: `Esta intentando enviar una doble solicitud mientras se encuentra esperando respuesta del servidor, espere que se resuelva la solicitud anterior si presenta demoras para recibir la respuesta puede deberse a una conexión de red inestable o lenta.`,
                })
            }

            let resp = await updateProductFieldMutation(params).unwrap()
            if (resp.status === 1) {
                delQueries(['field', 'product'])
                Swal.fire({
                    icon: "success",
                    text: `El campo ${t(field)} se actualizo satisfactoriamente`,
                    timer: 2000,
                    showConfirmButton: false,
                })
            } else {
                Swal.fire({
                    icon: "error",
                    text: t("anErrorOccurredWhileTryingToProcessTheRequest")!,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    const deleteProduct = async (params:string[]) => {
        try {
            if( params.length <= 0 ){
                blockService("Seleccione al menos un producto para continuar")
                return
            }
            if( isLoadingDeleteProductMutation ){
                blockService()
                return
            }
            const resp = await deleteProductMutation(params).unwrap()
            if( resp.status === 1 ){
                isSuccessResponse("Los productos se eliminaron satisfactoriamente.")
            }else{
                isErrorResponse()
            }
        } catch (error) {
            console.log(error)
        }
    }
    return {
        updateProductField,
        isLoadingUpdateProductFieldMutation,
        deleteProduct,
        isLoadingDeleteProductMutation
    }

}

export type FilterProducts = { arrangeSimpleCompounds?: boolean, searchProduct?: string, onlySimple?: boolean, onlyCompounds?: boolean }
interface useGetProductsForMassEditionProps {
    filters: FilterProducts
}

export const useGetProductsForMassEdition = (params?:useGetProductsForMassEditionProps) => {
    const { getParameterGroup } = useURLParams()
    const { group="", categories="", providers="" } = getParameterGroup(['group', 'categories', 'providers'])
    const { data, isLoading } = useGetProductsForMassEditionQuery()
    const products = data?.payload ?? [] as ProductsForMassEditionAsyncResponse['payload']
    let list = [...products]
    let listForCategory: { category: string, results: (typeof products) }[] = []

    if( Boolean(categories) ){
        const filterByCategories = categories.split("-") as string[]
        list = list.filter((n) => filterByCategories.some(x => x === (n?.category?.name??"")) )
    }
    if( Boolean(providers) ){
        const filterByProviders = providers.split("-") as string[]
        list = list.filter((n) => filterByProviders.some(x => x === (n?.provider?.name??"")) )
    }

    for( let item of list ){
        const match = (group === "categories") ? (item?.category?.name??"default") : (item?.provider?.name??"default")
        const getIndex = listForCategory.findIndex((n) => n.category === match)

        if( getIndex >= 0 ){
            listForCategory[getIndex].results.push(item)
        }else{
            listForCategory.push({
                category: match,
                results: [item]
            })
        }
    }

    const getProductParent = (parentId: string) => list.find(n => n?._id === parentId) || null
    const getProduct = (productId: string) => list.find(n => n?._id === productId) || null
    const filterProducts = () => {
        let productsFilter = ((group === "categories") || (group === "providers")) ? [...listForCategory] : [...list] as any
        let result:any[] = []
        const filters = (parameter:(typeof products)) => {
            let _products = [...parameter]
            if( params?.filters !== undefined ){
                const { arrangeSimpleCompounds, searchProduct, onlyCompounds, onlySimple } = params.filters
                if( arrangeSimpleCompounds ){
                    let newArr = []
                    let singleProduct = [..._products.filter((n) => !Boolean(n?.compount))]
                    const compoundProduct = _products.filter((n) => Boolean(n?.compount))
                    for( let index in singleProduct ){
                        const item = singleProduct[index]
                        const haveCompound = compoundProduct.filter((n) => n?.productParent === item._id)
                        if( haveCompound.length >= 1 ){
                            newArr.push(item)
                            Array.prototype.splice.apply(newArr, [Number(index), 0, ...haveCompound])
                        }
                    }
                    _products = newArr
                }
                if( onlySimple ){
                    _products = _products.filter((n) => !Boolean(n?.compount))
                }
                if( onlyCompounds ){
                    _products = _products.filter((n) => Boolean(n?.compount))
                }
                if( searchProduct && Boolean(searchProduct) && searchProduct.trim() !== "" ){
                    _products = _products.filter((item) => item.name.toUpperCase().includes(searchProduct.toUpperCase()))
                }
            }
            return _products
        }
        if( group === "categories" || group === "providers" ){
            for(let item of listForCategory){
                let r = filters(item.results as (typeof products))
                item.results = r
                result.push(item)
            }
        }else{
            result = filters(productsFilter as (typeof products))
        }
        return result
    }
    const getStockPerPackaging = (product:(typeof list[0])) => {
        let stock = 0
        if( Boolean(product?.compount) ){
            const parent = getProductParent((product?.productParent??""))
            if( parent !== null ){
                stock = (parent?.stock??0)/(product?.packaging?.quantity??0)
            }
        }else{
            stock = (product?.stock??0)/(product?.packaging?.quantity??0)
        }
        stock = Number(stock.toFixed(2))
        return stock
    }
    return {
        products,
        isLoading,
        getProductParent,
        getProduct,
        filterProducts,
        listForCategory,
        getStockPerPackaging
    }
}