import IconButton from '@component/IconButton';
import LocalPrintshopRounded from '@mui/icons-material/LocalPrintshopRounded';
import ShoppingScheduleTemplate from './ShoppingScheduleTemplate';
import ReactToPrint from 'react-to-print';
import Tooltip from '@component/Tooltip';
import React from 'react';
import Box from '@component/Box';

const PrintReportTemplateButton = () => {
    const printRef = React.useRef<HTMLDivElement | null>(null)
    return (
        <>
            <Box>
                <ReactToPrint
                    trigger={() => (
                        <Tooltip title={"Imprimir programación"} placement="top-start" >
                            <IconButton squareButton >
                                <LocalPrintshopRounded />
                            </IconButton>
                        </Tooltip>
                    )}
                    content={() => printRef.current}
                    documentTitle=''
                    removeAfterPrint={true}
                />

                <ShoppingScheduleTemplate ref={printRef} />
            </Box>
        </>
    )
}

export default PrintReportTemplateButton