import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { useGetCustomerZoneQuery } from "../../api/querys/Customer";

import Fade from "@component/Fade";
import Box from "@component/Box";
import Grid from "@component/Grid";
import CircularProgress from "@component/CircularProgess";

import { ZonesSearchBar } from "./components/ZonesSearchBar";
import { Zonelist } from "./components/Zonelist";
import { CreateZone } from "./components/CreateZone";
import { AlertDeleteZones } from "./components/AlertDeleteZones";
import { useBusinessPermissions } from "../../hooks/usePermissions";
import LockComponent from "@component/LockComponent";
import Title from "@component/Title";

const CustomerZones = () => {
    const { Customers } = useBusinessPermissions()
    const { t } = useTranslation();

    const { isLoading, isError } = useGetCustomerZoneQuery();

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (isError)
            enqueueSnackbar(t("errorGettingLogsPleaseTryAgain"), {
                variant: "error",
            });
    }, [isError, enqueueSnackbar, t]);

    if (isLoading)
        return (
            <Fade in={true}>
                <Box my={5}>
                    <CircularProgress />
                </Box>
            </Fade>
        );

    return (
        <>
            <Fade in={true}>
                {Customers.filters ?
                    <Box my={5}>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Title text="Zonas clientes" />
                            </Grid>
                            <Grid xs={12}>
                                <ZonesSearchBar />
                            </Grid>
                            <Grid xs={12}>
                                <Zonelist />
                            </Grid>
                            <CreateZone />
                        </Grid>
                    </Box>
                    :
                    <LockComponent />
                }
            </Fade>
            <AlertDeleteZones />
        </>
    );
};

export default CustomerZones;
