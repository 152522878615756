import {
        Dialog as DialogMUI, DialogProps as DialogPropsMUI,
        DialogTitle as DialogTitleMUI, DialogTitleProps as DialogTitlePropsMUI,
        DialogContent as DialogContentMUI, DialogContentProps as DialogContentPropsMUI,
        DialogActions as DialogActionsMUI, DialogActionsProps as DialogActionsPropsMUI,
        DialogContentText as DialogContentTextMUI, DialogContentTextProps as DialogContentTextPropsMUI,
    } from '@mui/material'

interface DialogProps extends Pick<DialogPropsMUI, "children" | "open" | "onClose" | "fullWidth" | "maxWidth" | "fullScreen" | "onKeyDown"> {}

const Dialog = ({children,...rest}:DialogProps) => {
    return(
        <DialogMUI {...rest} >
            {children}
        </DialogMUI>
    )
}

export default Dialog

interface DialogTitleProps extends Pick<DialogTitlePropsMUI, "children" | "sx" > {}

export const DialogTitle = ({ children }:DialogTitleProps) => {
    return(
        <DialogTitleMUI>
            {children}
        </DialogTitleMUI>
    )
}

interface DialogContentProps extends Pick<DialogContentPropsMUI, "children" | "dividers" | "sx" > {}

export const DialogContent = ({ children, ...rest }:DialogContentProps) => {
    return(
        <DialogContentMUI {...rest} >
            {children}
        </DialogContentMUI>
    )
}

interface DialogContentTextProps extends Pick<DialogContentTextPropsMUI, "children" | "textAlign" | "sx" > {}

export const DialogContentText = ({ children }:DialogContentTextProps) => {
    return(
        <DialogContentTextMUI>
            {children}
        </DialogContentTextMUI>
    )
}

interface DialogActionsProps extends Pick<DialogActionsPropsMUI, "children" > {}

export const DialogActions = ({ children }:DialogActionsProps) => {
    return(
        <DialogActionsMUI>
            {children}
        </DialogActionsMUI>
    )
}