import React from 'react'
import CloudDownloadRounded from "@mui/icons-material/CloudDownloadRounded"
import CloudUploadRounded from "@mui/icons-material/CloudUploadRounded"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import CardInformative from "@component/CardInformative"
import { useSheet } from "../../../hooks/useSheet"
import { useProducts } from '../hooks/useProducts'
import LocalOfferRounded from '@mui/icons-material/LocalOfferRounded';
import Box from '../../../components/Box'
import { loadProductsFromExcel } from '../services/ProductSlice'
import { useAppDispatch, useAppSelector } from '../../../hooks/state'

const ImportExportDataExcelProduct = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const { ProductState } = useAppSelector(({ ProductState }) => ({ ProductState }))
    const { productsFromExcel } = ProductState
    const inputRef = React.useRef<HTMLInputElement | null>(null)

    const columnsTemplate = [t('productName'), t('SKUCode'), t('cost'), t('sale'), `${t('sale')} 2`, `${t('sale')} 3`, t('category'), t('packaging'), t('provider')]
    const { useCreateSheet, useReadBook } = useSheet()
    const DownloadSheet = () => useCreateSheet({ columns:columnsTemplate, fileName: t('productUploadTemplate')!, name:`${dayjs().format("DD-MM-YYYY")}` })
    const handleActivePopupUploadFile = () => {
        const element = inputRef.current
        if( element !== undefined && element !== null ) {
            element.click()
        }
    }
    const { useValidatorXLSXData:ValidatorXLSXData } = useProducts({})
    const HandleWorkbook = (event:React.ChangeEvent<HTMLInputElement>) => {
        useReadBook(event, (result) => {
            const products = ValidatorXLSXData({ products: result })
            if( products !== undefined ) {
                dispatch(loadProductsFromExcel(products))
            }else{
                dispatch(loadProductsFromExcel([]))
            }
        })
    }
    React.useEffect(() => {
        if( productsFromExcel.length <= 0 ){
            const element = inputRef.current
            if( element !== undefined && element !== null ) {
                element.value = ''
            }
        }
    },[productsFromExcel])
    return(
        <Box sx={{ overflow: "auto", whiteSpace: 'nowrap', paddingBottom: 1 }}>
            <CardInformative icon={<CloudUploadRounded />} moreText={t('importProducts')!} informativeText={t('fromEXCEL')!} title="" onClick={handleActivePopupUploadFile} />
            <input ref={inputRef} id="load_products" type={'file'} accept=".xlsx" style={{ display: 'none' }} onChange={HandleWorkbook} />
            <CardInformative icon={<CloudDownloadRounded />} moreText={t('downloadTemplate')!} informativeText={t('EXCEL')!} title="" onClick={DownloadSheet} />
            <CardInformative icon={<LocalOfferRounded />} moreText={productsFromExcel.length} informativeText={t('loadedProducts')!} title={""} />
        </Box>
    )
}

export default ImportExportDataExcelProduct