import { configureStore } from '@reduxjs/toolkit';
import AuthenticationSlice from '../features/Authentication/services/AuthenticationSlice';
import AppSlice from './AppSlice';
import CustomerSlice from '../features/Customer/services/CustomerSlice';
import EmployeeSlice from '../features/Employee/services/EmployeeSlice';
import { api } from '../api/splitApi';
import CustomerPurchaseTrackingSlice from '../features/CustomerPurchaseTracking/services/CustomerPurchaseTrackingSplice';
import ProductSlice from '../features/Products/services/ProductSlice';
import ProductCategorySlice from '../features/ProductCategories/services/ProductCategorySlice';
import PointOfSaleSlice from '../features/PointOfSale/services/PointOfSaleSlice';
import PermissionSlice from '../features/Permission/services/permissionSlice';
import ClosingOfTheDaySlice from '../features/ClosingOfTheDay/services/ClosingOfTheDaySlice';
import ReportOfTheDaySlice from '../features/ReportOfTheDay/services/ReportOfTheDaySlice';
import InvoiceSlice from '../features/Invoice/service/InvoiceSlice';
import ProviderSlice from '../features/Provider/services/ProviderSlice';
import BankSlice from '../features/Banks/services/BankSlice';
import ZoneSlice from '../features/CustomerZones/services/ZoneSlice';
import PaySheetSlice from './../features/Employee/services/PaySheetSlice';
import DebtsToPaySlice from '../features/DebtsToPay/services/DebtsToPaySlice';
import ProductPackagingSlice from '../features/ProductPackaging/services/ProductPackagingSlice';
import NeighborhoodsSlice from '../features/CustomerNeighborhoods/services/NeighborhoodsSlice';
import CustomerBranchSlice from "../features/CustomerBranch/services/CustomerBranchSlice";
import CitySlice from '../features/CustomerCity/services/CitySlice';
import BusinessesTypeSlice from '../features/CustomerBusinessesType/services/BusinessesTypeSlice';
import PurchasesSuppliersSlice from '../features/PurchasingSuppliers/services/PurchasesSuppliersSlice';
import CreditsSlice from '../features/CreditReport/services/CreditsSlice';
import DeliveriesSlice from '../features/DeliveryReport/services/DeliveriesSlice';
import FixedCostsSlice from '@feature/FixedCosts/services/FixedCostsSlice';
import CashierSlice from '@feature/Cashier/services/CashierSlice';
import CashierMovementsSlice from '@feature/MovementsCashier/services/CashierMovementsSlice';
import ExpenseSlice from '@feature/Expenses/services/ExpenseSlice';

export const store = configureStore({
  reducer: {
    AppState: AppSlice,
    AuthenticationState: AuthenticationSlice,
    CustomerState: CustomerSlice,
    EmployeeState: EmployeeSlice,
    CustomerPurchaseTrackingState: CustomerPurchaseTrackingSlice,
    ProductState: ProductSlice,
    ProductCategoryState: ProductCategorySlice,
    ProductPackagingState: ProductPackagingSlice,
    PointOfSaleState: PointOfSaleSlice,
    PermissionState: PermissionSlice,
    ClosingOfTheDayState: ClosingOfTheDaySlice,
    ReportOfTheDayState: ReportOfTheDaySlice,
    InvoiceState: InvoiceSlice,
    ProviderState: ProviderSlice,
    BankState:BankSlice,
    ZoneState: ZoneSlice,
    NeighborhoodState: NeighborhoodsSlice,
    CityState: CitySlice,
    BusinessesTypeState: BusinessesTypeSlice,
    CustomerBranchState: CustomerBranchSlice,
    PaySheetState: PaySheetSlice,
    DebtsToPayState: DebtsToPaySlice,
    PurchasesSuppliersState: PurchasesSuppliersSlice,
    DeliveriesState: DeliveriesSlice,
    CreditsState: CreditsSlice,
    FixedCostsState: FixedCostsSlice,
    CashierState: CashierSlice,
    CashierMovementsState: CashierMovementsSlice,
    ExpenseState: ExpenseSlice,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
