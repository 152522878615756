import { modals_map, URLParameters_map } from "@service/URLParams.map";
import { useSearchParams } from "react-router-dom";

type Modals = keyof typeof modals_map
type URLActions = keyof typeof URLParameters_map

export const useURLParams = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const addQuery = (queries: Record<string, string>) => {
        setSearchParams(prevSearchParams => {
            const updatedSearchParams = new URLSearchParams(prevSearchParams);
            Object.entries(queries).forEach(([name, value]) => {
                updatedSearchParams.set(name, value);
            });
            return Object.fromEntries(updatedSearchParams.entries());
        });
    }
    const delQuery = (name: string) => {
        searchParams.delete(name)
        setSearchParams(searchParams)
    }
    const delQueries = (names: string[]) => {
        names.map((name) => searchParams.delete(name))
        setSearchParams(searchParams)
    }
    const getQuery = (name: string) => searchParams.get(name)
    const handleModalByURLParameter = ( modal:Modals ) => {
        const isOpen = getQuery(URLParameters_map['open_modal']) === modals_map[modal]
        if( isOpen ){
            delQuery(URLParameters_map['open_modal'])
        }else{
            addQuery({ [URLParameters_map['open_modal']] : modals_map[modal] })
        }
    }
    const isOpenModalByURLParameter = (modal:Modals) => getQuery(URLParameters_map['open_modal']) === modals_map[modal]
    const handleActionParameter = (action:URLActions, value:string) => addQuery({ [URLParameters_map[action]]: value })
    const handleParameterGroups = (actions:URLActions[], values:string[]) => {
        let params: any = {}
        for( const [index, value] of actions.entries() ){
            params[URLParameters_map[value]] = values[index]
        }
        addQuery(params)
    }

    const getParameterGroup = (actions:URLActions[]) => {
        let values:any = {}
        for( let action of actions ){
            let value = getQuery(URLParameters_map[action])
            if( Boolean(value) ){
                values[URLParameters_map[action]] = value
            }
        }
        return values
    }

    return { addQuery, delQuery, delQueries, getQuery, handleModalByURLParameter, isOpenModalByURLParameter, handleActionParameter, handleParameterGroups, getParameterGroup }
}