import Swal from "sweetalert2"
import i18n from 'i18next';

export const isSuccessResponse = async (message:string) => {
    const swalAl = await Swal.fire({
        icon: 'success',
        text: message,
        timer: 2000,
        showConfirmButton: false
    })

    return swalAl
}

export const isErrorResponse = async (message?:string) => {
    const existText = i18n.exists(message??"")
    const swalAl = await Swal.fire({
        icon: 'error',
        text: existText ? i18n.t(message??"")! : i18n.t("anErrorOccurredWhileTryingToProcessTheRequest")!,
    })

    return swalAl
}

export const blockService = async (message?:string) => {
    Swal.fire({
        icon: 'warning',
        text: Boolean(message) ? message : i18n.t('unstableConnection')!,
    })
}