import Dialog, { DialogContent } from "@component/Dialog"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import TemplateInvoice from "../../Invoice/components/TemplateInvoice"
import InvoiceOptions from "../../Invoice/components/InvoiceOptions"
import { loadSalesList, selectCustomerForInvoice, uploadInvoiceIdentifiers, uploadInvoicePreview } from "../services/PointOfSaleSlice"
import { InvoiceProps } from "@interface/PointOfSale"
import React from "react"
import TemplateLargeInvoice from "../../Invoice/components/TemplateLargeInvoice"

const PreviewInvoice = () => {
    const invoiceElement = React.useRef<HTMLDivElement | null>(null)
    const dispatch = useAppDispatch()
    const { PointOfSaleState, AppState } = useAppSelector(({ PointOfSaleState, AppState }) => ({ PointOfSaleState, AppState }))
    const { invoiceMode } = AppState
    const { previewInvoice } = PointOfSaleState
    const previewInvoiceRequiredInterface = previewInvoice as Required<InvoiceProps>

    const handleEditSelected = () => {
        dispatch(loadSalesList(previewInvoiceRequiredInterface.products))
        dispatch(uploadInvoiceIdentifiers({ _id: previewInvoiceRequiredInterface._id, consecutive: previewInvoiceRequiredInterface.consecutive }))

        if( Boolean(previewInvoiceRequiredInterface?.customer?.name) ){
            dispatch(selectCustomerForInvoice(previewInvoiceRequiredInterface.customer))
        }else{
            dispatch(selectCustomerForInvoice(null))
        }
        dispatch(uploadInvoicePreview({}))
    }

    return(
        <Dialog open={Boolean(previewInvoice._id)} onClose={() => dispatch(uploadInvoicePreview({}))} fullWidth maxWidth={invoiceMode === "pos" ? "xs" : 'md'} >
            <DialogContent>
                <InvoiceOptions onEdit={handleEditSelected} changeTypePayment={false} payment={false} invoice={previewInvoiceRequiredInterface} invoiceElement={invoiceElement} />
                {invoiceMode === "pos" ?
                    <TemplateInvoice ref={invoiceElement} {...previewInvoiceRequiredInterface} />
                    :
                    <TemplateLargeInvoice ref={invoiceElement} {...previewInvoiceRequiredInterface} />
                }
            </DialogContent>
        </Dialog>
    )
}

export default PreviewInvoice