import Dialog, { DialogContent, DialogTitle } from "@component/Dialog"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { openFormCreateBanks } from "../services/BankSlice"
import BankForm from "./BankForm"
import { useTranslation } from "react-i18next"

const CreateBank = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const { BankState } = useAppSelector(({ BankState }) => ({ BankState }))
    const { isOpenFormCreateBanks } = BankState

    return (
        <Dialog fullWidth open={isOpenFormCreateBanks} onClose={() => dispatch(openFormCreateBanks(false))} >
            <DialogTitle>{t("createNewBank")!}</DialogTitle>
            <DialogContent>
                <BankForm />
            </DialogContent>
        </Dialog>
    )
}

export default CreateBank