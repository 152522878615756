import { GetBusinessTagsResponse } from "../../interfaces/Tags"
import { api } from "../splitApi"

const Tag = api.injectEndpoints({
    endpoints: (build) => ({
        getBusinessTags: build.query<GetBusinessTagsResponse, {module: string}>({
            query: ({module}) => ({
                url: '/get/business/tags',
                params: {module}
            }),
            providesTags: ['Tag'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetBusinessTagsQuery
} = Tag