import React from 'react';
import IconButton from "@component/IconButton"
import PrintRounded from '@mui/icons-material/PrintRounded';
import Box from "@component/Box";
import Menu from "@component/Menu";
import Tooltip from "@component/Tooltip";
import ReactToPrint from "react-to-print";
import { useTranslation } from 'react-i18next';
import AttachMoneyRounded from '@mui/icons-material/AttachMoneyRounded';
import type { InvoiceProps } from '../../../interfaces/PointOfSale';
import AddCardRounded from '@mui/icons-material/AddCardRounded';
import Dialog, { DialogActions, DialogContent, DialogContentText } from '../../../components/Dialog';
import List, { ListItem, ListItemIcon, ListItemText } from '../../../components/List';
import { currencyFormat } from '../../../hooks/useNumberFormat';
import dayjs from 'dayjs';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import LocalAtmRounded from '@mui/icons-material/LocalAtmRounded';
import Button from '../../../components/Button';
import { useInvoice } from '../hooks/useInvoice';
import SubscriptionForm from './SubscriptionForm';
import Typography from '../../../components/Typography';
import { showDeliveryForm, showPaymentMethodForm } from '../service/InvoiceSlice';
import { useAppDispatch } from '../../../hooks/state';
import { GlobalHotKeys } from 'react-hotkeys';
import ModeEditRounded from '@mui/icons-material/ModeEditRounded';
import { useBusinessPermissions } from '../../../hooks/usePermissions';
import MoreVertRounded from '@mui/icons-material/MoreVertRounded';
import SwitchInvoiceFormat from './SwitchInvoiceFormat';
import SwitchAlphabeticalOrder from './SwitchAlphabeticalOrder';
import SwitchArchiveInvoice from './SwitchArchiveInvoice';
import { exportComponentAsPNG } from 'react-component-export-image';
import SimCardDownloadRounded from '@mui/icons-material/SimCardDownloadRounded';
import Receipt from '@mui/icons-material/Receipt';
import NumbersRounded from '@mui/icons-material/NumbersRounded';
import EnterConsecutiveElectronicInvoice from './EnterConsecutiveElectronicInvoice';
import { useURLParams } from '@hook/useURLParams';
import DeliveryDining from '@mui/icons-material/DeliveryDining';

interface Props {
    onEdit?: () => void
    onCreditNote?: () => void
    invoiceElement: React.MutableRefObject<HTMLDivElement | null>
    invoice: InvoiceProps
    print?: boolean
    changeTypePayment?: boolean
    payment?: boolean
    edit?: boolean
}

const InvoiceOptions = ({ invoiceElement, onCreditNote, invoice, print = true, changeTypePayment = true, payment = true, edit = true, onEdit }: Props) => {
    const { addQuery } = useURLParams()
    const { Invoice } = useBusinessPermissions()
    const dispatch = useAppDispatch()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [addNewSubscription, setAddNewSubscription] = React.useState<"addSubscription" | "viewPayments">("viewPayments")
    const [viewPayments, setViewPayments] = React.useState<boolean>(false)
    const [deletePayment, setDeletePayment] = React.useState<{ open: boolean, payment: number }>({ open: false, payment: 0 })
    const { t } = useTranslation()
    const { typePayment, paymentCredits, totalPayments, total, _id, isArchived, invoiceType, consecutive, electronicConsecutive } = invoice
    const printRef = React.useRef<HTMLButtonElement | null>(null)

    const open = Boolean(anchorEl);
    const handleOpenSettingInvoice = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleCloseSettingInvoice = () => setAnchorEl(null);

    const handleOpenViewPayments = () => setViewPayments(!viewPayments)
    const handleDeletePayments = (payment: number = 0) => {
        if (!deletePayment.open) {
            setViewPayments(false)
            setDeletePayment(n => ({ ...n, open: true, payment }))
        } else {
            setViewPayments(true)
            setDeletePayment(n => ({ ...n, open: false, payment }))
        }
    }
    const { deletePaymentCredit } = useInvoice()
    const keyMap = {
        SELECT_PAYMENT_METHOD: "p",
        PRINT_INVOICE: ".",
    }
    const handlers = {
        SELECT_PAYMENT_METHOD: () => dispatch(showPaymentMethodForm(true)),
        PRINT_INVOICE: () => printRef && printRef.current?.click()
    }

    const handleDownloadInvoice = () => {
        if( invoiceElement.current !== null && invoiceElement.current !== undefined ){
            invoiceElement.current.style.maxWidth = "400px"
            exportComponentAsPNG(invoiceElement, { fileName: (invoiceType === "price") ? `#C${consecutive}` : `#R${consecutive}` })
            invoiceElement.current.style.removeProperty("max-width")
        }
    }
    return (
        <GlobalHotKeys handlers={handlers} keyMap={keyMap} >
            <Box mb={2} display='flex' alignItems={'center'} justifyContent={'space-between'} >
                <Box>
                    {Invoice.edit_information &&
                        <>
                            {edit &&
                                <Tooltip title={t('editInvoice')!} placement="top-start" >
                                    <IconButton squareButton onClick={onEdit} >
                                        <ModeEditRounded />
                                    </IconButton>
                                </Tooltip>
                            }
                        </>
                    }
                    {Invoice.print &&
                        <>
                            {print &&
                                <ReactToPrint
                                    trigger={() => (
                                        <Tooltip title={t('printInvoice')!} placement="top-start" >
                                            <IconButton ref={printRef} squareButton >
                                                <PrintRounded />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    content={() => invoiceElement.current}
                                />
                            }
                        </>
                    }
                    <Tooltip title={t('Descargar factura')!} placement="top-start" >
                        <IconButton squareButton onClick={handleDownloadInvoice} >
                            <SimCardDownloadRounded />
                        </IconButton>
                    </Tooltip>
                    {Invoice.change_payment_method &&
                        <>
                            {changeTypePayment &&
                                <Tooltip title={t('changePaymentType')!} placement="top-start" >
                                    <IconButton squareButton onClick={() => dispatch(showPaymentMethodForm(true))} >
                                        <AttachMoneyRounded />
                                    </IconButton>
                                </Tooltip>
                            }
                        </>
                    }
                    {Invoice.add_subscriptions &&
                        <>
                            {payment &&
                                <>
                                    {
                                        (typePayment === "credit" || typePayment === "delivery") &&
                                        <>
                                            <Tooltip title={t('payments')!} placement="top-start" >
                                                <IconButton squareButton onClick={handleOpenViewPayments} >
                                                    <AddCardRounded />
                                                </IconButton>
                                            </Tooltip>
                                            <Dialog open={viewPayments} onClose={() => setViewPayments(false)} fullWidth maxWidth="xs" >
                                                <DialogContent>
                                                    <Typography textTransform={'uppercase'} > {t('balance')}: $ {currencyFormat(total - totalPayments)} </Typography>
                                                    <Typography textTransform={'uppercase'} > {t('Valor de la factura')}: $ {currencyFormat(total)} </Typography>
                                                    {addNewSubscription === "viewPayments" ?
                                                        <>
                                                            <Box>
                                                                {(total - totalPayments) >= 1 &&
                                                                    <Tooltip title={t('addNewSubscription')} >
                                                                        <IconButton onClick={() => setAddNewSubscription("addSubscription")} >
                                                                            <LocalAtmRounded />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }
                                                            </Box>
                                                            {paymentCredits.length >= 1 ?
                                                                <>
                                                                    <List>
                                                                        {paymentCredits.map((payment, index) => {
                                                                            return (
                                                                                <ListItem key={index} sx={{ padding: 0 }} >
                                                                                    <ListItemText
                                                                                        primary={`$ ${currencyFormat(payment.amount)} - ${payment.wallet === "consignment" ? payment.bank : t(payment.wallet)}`}
                                                                                        secondary={`${dayjs(payment.date).format("DD-MM-YYYY")}`}
                                                                                    />
                                                                                    { ((payment?.amount >= 1) && (index >= 1)) &&
                                                                                        <ListItemIcon>
                                                                                            <Tooltip title={t('removeSubscription')} placement='top' >
                                                                                                <IconButton color='error' onClick={() => handleDeletePayments(payment.amount)} >
                                                                                                    <DeleteRounded />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </ListItemIcon>
                                                                                    }
                                                                                </ListItem>
                                                                            )
                                                                        })}
                                                                    </List>
                                                                </> :
                                                                <Typography textAlign={"center"} textTransform={'uppercase'} fontWeight={'bold'} > {t('thereAreNoSubscriptionsToDisplay')} </Typography>
                                                            }
                                                        </> :
                                                        <SubscriptionForm invoiceId={_id} onBack={() => setAddNewSubscription("viewPayments")} maxValue={total - totalPayments} />
                                                    }
                                                </DialogContent>
                                            </Dialog>
                                            <Dialog fullWidth open={deletePayment.open} onClose={() => handleDeletePayments()} maxWidth="xs" >
                                                <DialogContent sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    pb: 0
                                                }} >
                                                    <DialogContentText>{t('doYouWantToRemoveTheCompost')}</DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button variant='text' color='error' onClick={() => deletePaymentCredit({ payment: deletePayment.payment, invoiceId: _id }, handleDeletePayments )} > {t('erase')} </Button>
                                                    <Button variant='text' onClick={() => handleDeletePayments()} > {t('cancel')} </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </>
                                    }
                                </>
                            }
                        </>
                    }
                    <Tooltip title={t('Registrar nota crédito')!} placement="top-start" >
                        <IconButton squareButton onClick={onCreditNote} >
                            <Receipt />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('Asignar consecutivo de factura electrónica')!} placement="top-start" >
                        <IconButton squareButton onClick={() => addQuery({ window: "consecutive-electronic-invoice" })} >
                            <NumbersRounded />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('Añadir datos de domicilio')!} placement="top-start" >
                        <IconButton squareButton onClick={() => {
                            dispatch(showDeliveryForm(true))
                            addQuery({typeOf: typePayment})
                        }} >
                            <DeliveryDining />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box>
                    <Tooltip title="Ajustes de la factura" >
                        <IconButton onClick={handleOpenSettingInvoice} >
                            <MoreVertRounded />
                        </IconButton>
                    </Tooltip>
                    <Menu anchorEl={anchorEl} open={open} onClose={handleCloseSettingInvoice} onClick={() => null}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                maxWidth: 250,
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <ListItem>
                            <SwitchInvoiceFormat />
                        </ListItem>
                        <ListItem>
                            <SwitchAlphabeticalOrder />
                        </ListItem>
                        <ListItem>
                            <SwitchArchiveInvoice invoiceId={_id} isArchived={Boolean(isArchived)} typePayment={typePayment} total={total} />
                        </ListItem>
                    </Menu>
                </Box>
            </Box>
            <EnterConsecutiveElectronicInvoice electronicConsecutive={electronicConsecutive} />
        </GlobalHotKeys>
    )
}

export default InvoiceOptions