import { useGetBusinessCustomersQuery } from "../../../api/querys/Customer"
import Autocomplete from "@component/Autocomplete"
import { useTranslation } from "react-i18next"

interface Props extends Pick<React.ComponentProps<typeof Autocomplete>, "sx" | "onChange"> {}

const SearchCustomerNumberDocument = ({ ...rest}:Props) => {
    const { t } = useTranslation()
    const { data } = useGetBusinessCustomersQuery()
    let customers = data?.payload?.customers?.results??[]
    customers = [...customers].map((n) => ({...n, name: String(n?.identificationNumber??"")}))
    customers = customers.filter((n) => Boolean(n?.identificationNumber))
    return(
        <>
            <Autocomplete
                sx={{ width: 240 }}
                label={t('Numero de documento cliente')!}
                options={customers}
                {...rest}
            />
        </>
    )
}

export default SearchCustomerNumberDocument