import Dialog, { DialogContent, DialogTitle } from "@component/Dialog"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { openFormCreateProvider } from "../services/ProviderSlice"
import ProviderForm from "./ProviderForm"

const CreateProvider = () => {
    const dispatch = useAppDispatch()
    const { ProviderState } = useAppSelector(({ ProviderState }) => ({ ProviderState }))
    const { isOpenFormCreateProvider } = ProviderState

    return(
        <Dialog fullWidth open={isOpenFormCreateProvider} onClose={() => dispatch(openFormCreateProvider(false))} >
            <DialogTitle>CREAR NUEVO PROVEEDOR</DialogTitle>
            <DialogContent>
                <ProviderForm />
            </DialogContent>
        </Dialog>
    )
}

export default CreateProvider