import { CloudDownloadRounded } from "@mui/icons-material"
import CardInformative from "@component/CardInformative"
import { useSheet } from "../../../hooks/useSheet"

const BeginningInventoryTemplate = () => {
    const { useCreateSheet:createSheetBeginningInventory } = useSheet()
    const handleCreateSheet = () => createSheetBeginningInventory({ columns:[
        "Producto",
        "Stock inicial",
        "Embalaje",
        "Categoría",
        "Código SKU",
        "Código de barras",
        "Código de proveedor",
        "Costo",
        "PV-1",
        "PV-2",
        "PV-3",
        "Compuesto",
    ], rows: [] })
    return(
        <>
            <CardInformative
                onClick={handleCreateSheet}
                title={"Inventario inicial"}
                moreText={`Descargar plantilla`}
                icon={<CloudDownloadRounded />}
            />
        </>
    )
}

export default BeginningInventoryTemplate