import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface stateProps {
    openCreateForm: boolean
    employeeSelectionToDelete: string[]
    employeeRemovalAlert: boolean
}

const initialState:stateProps = {
    openCreateForm: false,
    employeeSelectionToDelete: [],
    employeeRemovalAlert: false
}

export const EmployeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        displayFormToCreateEmployee: (state, action:PayloadAction<boolean>) => {
            state.openCreateForm = action.payload
        },
        selectEmployeesToDelete: (state, action:PayloadAction<string[]>) => {
            state.employeeSelectionToDelete = action.payload
        },
        showEmployeeDeletionAlert: (state, action:PayloadAction<boolean>) => {
            state.employeeRemovalAlert = action.payload
        },
    }
})

export const {
    displayFormToCreateEmployee,
    selectEmployeesToDelete,
    showEmployeeDeletionAlert
} = EmployeeSlice.actions
export default EmployeeSlice.reducer