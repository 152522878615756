import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { InvoicePaymentProps } from '../../../interfaces/PointOfSale';

interface stateProps {
  openSelectedPaymentMethod: boolean;
  openDeliveryForm: boolean;
  openBankForm: boolean;
  openCreditForm: boolean;
  invoiceValues: InvoicePaymentProps
}

const initialState: stateProps = {
  openSelectedPaymentMethod: false,
  openDeliveryForm: false,
  openBankForm: false,
  openCreditForm: false,
  invoiceValues: {
    _id: '',
    seller: '',
    total: 0,
    consecutive: 0,
    customer: '',
  },
};

export const InvoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    showPaymentMethodForm: (state, action: PayloadAction<boolean>) => {
      state.openSelectedPaymentMethod = action.payload;
    },
    showDeliveryForm: (state, action: PayloadAction<boolean>) => {
      state.openDeliveryForm = action.payload;
    },
    showBankForm: (state, action: PayloadAction<boolean>) => {
      state.openBankForm = action.payload;
    },
    showCreditForm: (state, action: PayloadAction<boolean>) => {
      state.openCreditForm = action.payload;
    },
    getInvoiceValues: (state, action: PayloadAction<InvoicePaymentProps> ) => {
      state.invoiceValues = action.payload;
    },
  },
});

export const {
  showPaymentMethodForm,
  showDeliveryForm,
  showBankForm,
  showCreditForm,
  getInvoiceValues,
} = InvoiceSlice.actions;
export default InvoiceSlice.reducer;
