import Dialog, { DialogContent } from '../../../components/Dialog';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import {
  openRoleCreateForm,
  openRoleEditForm,
} from '../services/permissionSlice';
import RolForm from './RolForm';

const CreateRol = () => {
  const { PermissionState } = useAppSelector(({ PermissionState }) => ({
    PermissionState,
  }));
  const dispatch = useAppDispatch();
  const { isOpenFormCreateRol, isOpenEditRol } = PermissionState;

  const handleCloseForm = () => {
    isOpenFormCreateRol
      ? dispatch(openRoleCreateForm(false))
      : dispatch(openRoleEditForm(false));
  };

  return (
    <Dialog
      open={isOpenFormCreateRol || isOpenEditRol}
      onClose={handleCloseForm}
      fullWidth
    >
      <DialogContent>
        <RolForm />
      </DialogContent>
    </Dialog>
  );
};

export default CreateRol;
