import { useTranslation } from "react-i18next"
import { useGetClosingOfDayQuery, useGetPreviousDaysCreditsQuery } from "../../../api/querys/PointOfSale"
import Card from "@component/Card"
import InformativeList from "@component/InformativeList"
import Typography from "@component/Typography"
import { useAppSelector } from "../../../hooks/state"
import { CloseOfTheDayResponse, PreviousDaysCreditsResponse } from "@interface/PointOfSale"

const ClosingOfTheDayDetails = () => {
    const { ClosingOfTheDayState } = useAppSelector(({ ClosingOfTheDayState }) => ({ ClosingOfTheDayState }))
    const { dateClosingOfDay } = ClosingOfTheDayState
    const { t } = useTranslation()
    const { data } = useGetClosingOfDayQuery({ closingDate: dateClosingOfDay })
    const { data: dataPreviousDaysCredits } = useGetPreviousDaysCreditsQuery({ closingDate: dateClosingOfDay })
    const { cashFromCreditsAndDomiciles, cashSale, saleOfTheDay, closingOfTheDiscriminatedDay, creditsOfTheDay, deliveriesOfTheDay, expensesOfTheDay, purchasesOfTheDay, totalIndefiniteInvoices, totalArchived } = data?.payload??{} as CloseOfTheDayResponse['payload']
    const { creditInformationPreviousDays, deliveriesInformationPreviousDays } = dataPreviousDaysCredits?.payload ?? {} as PreviousDaysCreditsResponse['payload']
    const { cashTotal:previousDaysCreditsCashTotal=0, consignmentTotal=0 } = creditInformationPreviousDays??{} as PreviousDaysCreditsResponse['payload']['creditInformationPreviousDays']
    const { cashTotal: previousDeliveriesCashTotal=0, consignmentTotal:previousDeliveriesConsignmentTotal=0 } = deliveriesInformationPreviousDays??{} as PreviousDaysCreditsResponse['payload']['deliveriesInformationPreviousDays']
    const consignmentToDay = closingOfTheDiscriminatedDay?.find(n => n._id === "consignment")

    return (
        <>
            <Typography fontWeight={'bold'} textTransform={'uppercase'} >{t('Resumen venta del dia')}</Typography>
            <Card sx={{ mb: 2 }} >
                <InformativeList data={[{ primaryText: t('consignmentsOfTheDay'), secondaryText: "", value: consignmentToDay?.total ?? 0 }]} />
                <InformativeList data={[{ primaryText: t('Pago domicilios por consignación'), secondaryText: "", value: (deliveriesOfTheDay?.totalPaymentsToDeliveriesConsignment??0) }]} />
                <InformativeList data={[{ primaryText: t('Sin cobrar')!, secondaryText: "", value: (totalIndefiniteInvoices??0) }]} />
                <InformativeList data={[{ primaryText: t('deliveryOfTheDay')!, secondaryText: "", value: ((deliveriesOfTheDay?.totalDeliveries??0) - (deliveriesOfTheDay?.totalPaymentsToDeliveries??0)) }]} />
                <InformativeList data={[{ primaryText: t('creditsOfTheDay'), secondaryText: "", value: ((creditsOfTheDay?.totalCredits??0) - (creditsOfTheDay?.totalPaymentsToCredits??0)) }]} />
                <InformativeList data={[{ primaryText: t('cashAddresses')!, secondaryText: "", value: (cashFromCreditsAndDomiciles??0) }]} />
                <InformativeList data={[{ primaryText: t('cashSale')!, secondaryText: "", value: (cashSale??0) }]} />
                <InformativeList primaryTypographyProps={{ fontWeight: "bold" }} data={[{
                    primaryText: t('saleOfTheDay'), secondaryText: "",
                    value: (consignmentToDay?.total ?? 0) + ((creditsOfTheDay?.totalCredits??0) - (creditsOfTheDay?.totalPaymentsToCredits??0)) + (cashFromCreditsAndDomiciles??0) + (cashSale??0) + ((deliveriesOfTheDay?.totalDeliveries??0) - (deliveriesOfTheDay?.totalPaymentsToDeliveries??0))+(totalIndefiniteInvoices??0)+(deliveriesOfTheDay?.totalPaymentsToDeliveriesConsignment??0)
                }]} />

            </Card>
            <Typography fontWeight={'bold'} textTransform={'uppercase'} >{t('Efectivos')}</Typography>
            <Card sx={{ mb: 2 }} >
                <InformativeList data={[{ primaryText: t('paymentCreditsPreviousDaysInCash')!, secondaryText: "", value: (previousDaysCreditsCashTotal??0) }]} />
                <InformativeList data={[{ primaryText: t('Abono domicilios días anteriores en efectivo')!, secondaryText: "", value: (previousDeliveriesCashTotal??0) }]} />
                <InformativeList data={[{ primaryText: t('cashSaleOfTheDay')!, secondaryText: "", value: (saleOfTheDay??0) }]} />
                <InformativeList isAnExpenses={true} data={[{ primaryText: t('minorExpensesOfTheDay'), secondaryText: "", value: (expensesOfTheDay??0) }]} />
                <InformativeList isAnExpenses={true} data={[{ primaryText: t('minorPurchasesOfTheDay'), secondaryText: "", value: (purchasesOfTheDay??0) }]} />
                <InformativeList primaryTypographyProps={{ fontWeight: "bold" }} data={[{ primaryText: t('fullCash')!, secondaryText: "", value: ((saleOfTheDay??0) + (previousDaysCreditsCashTotal??0) - (expensesOfTheDay??0) - (purchasesOfTheDay??0)) }]} />
            </Card>
            <Typography fontWeight={'bold'} textTransform={'uppercase'} >{t('Consignaciones días anteriores')}</Typography>
            <Card sx={{ mb: 2 }} >
                <InformativeList data={[{ primaryText: t('Abono créditos días anteriores en consignación'), secondaryText: "", value: (consignmentTotal??0) }]} />
                <InformativeList data={[{ primaryText: t('Abono domicilios días anteriores en consignación'), secondaryText: "", value: (previousDeliveriesConsignmentTotal??0) }]} />
            </Card>
            <Typography fontWeight={'bold'} textTransform={'uppercase'} >{t('Archivados')}</Typography>
            <Card sx={{ mb: 2 }} >
                <InformativeList data={[{ primaryText: t('Total facturas archivadas del dia'), secondaryText: "", value: (totalArchived??0) }]} />
            </Card>
        </>
    )
}

export default ClosingOfTheDayDetails