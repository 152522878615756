import { useSnackbar } from 'notistack';
import { useAppSignalSelector } from './../../../hooks/state';
import { CashiersSummaryAsyncResponse, CashierSummaryAsyncResponse, CashierSummaryDetailsAsyncResponse, FilterMovementsCashierProps } from "@interface/Cashier"
import { useGetCashiersSummaryQuery, useGetCashierSummaryDetailsQuery, useGetCashierSummaryQuery, useTransferToPettyCashMutation } from "@query/Cashier"
import { useTranslation } from 'react-i18next';

export const useCashierMovement = () => {
    const { MovementsCashierState, MinorCashState } = useAppSignalSelector()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const [ transferToPettyCashMutation, { isLoading:isLoadingTransferToPettyCashMutation } ] = useTransferToPettyCashMutation()
    const transferToPettyCash = async () => {
        try {
            const { amountToWithdraw, summaryCashier } = MovementsCashierState.value
            const { endDate, startDate } = MinorCashState.value.filterDateRange
            if( amountToWithdraw <= 0 ){
                enqueueSnackbar("El monto de traslado debe ser mayor a 0", { variant: "warning" })
                return
            }
            const resp = await transferToPettyCashMutation({ amount:amountToWithdraw, cashierId:summaryCashier?.cashier?._id??"", endDate, startDate }).unwrap()
            if( resp.status === 1 ){
                MovementsCashierState.value = {...MovementsCashierState.value, amountToWithdraw: 0, summaryCashier: null}
                enqueueSnackbar("El traslado se registro satisfactoriamente", { variant: "success" })
            }else{
                enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'), { variant: "error" })
            }
        
        } catch (error) {
            console.log(error)
        }
    }

    return {
        transferToPettyCash,
        isLoadingTransferToPettyCashMutation
    }
}

interface useCashierReportProps extends FilterMovementsCashierProps {}

export const useCashierReport = ({ cashierId, startDate, endDate }:useCashierReportProps) => {
    const { data, isLoading } = useGetCashierSummaryQuery({ cashierId, startDate, endDate })
    const summary = data?.payload??{} as CashierSummaryAsyncResponse['payload']
    return { summary, isLoading }
}

export const useGetCashierSummaryDetails = ({ cashierId, startDate, endDate }:useCashierReportProps) => {
    const { data, isLoading } = useGetCashierSummaryDetailsQuery({ cashierId, startDate, endDate })
    const summary = data?.payload??{} as CashierSummaryDetailsAsyncResponse['payload']
    return { summary, isLoading }
}

export const useCashiersReport = ({ startDate, endDate }:Omit<useCashierReportProps, "cashierId">) => {
    const { data, isLoading } = useGetCashiersSummaryQuery({ startDate, endDate })
    const summaries = data?.payload??[] as CashiersSummaryAsyncResponse['payload']
    return { summaries, isLoading }
}