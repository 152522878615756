import Box from "@component/Box"
import DataGrid, { GridColDef, GridSelectionColDef } from "@component/DataGrid"
import List, { ListItem, ListItemText } from "@component/List"
import Typography from "@component/Typography"
import { useAppContextSelector } from "@hook/state"
import { currencyFormat } from "@hook/useNumberFormat"
import { useURLParams } from "@hook/useURLParams"
import { GridCellParams, GridRowParams } from "@mui/x-data-grid"
import { useGetPurchasesFromSupplierBusiness } from "@purchase_report_hook/usePurchaseReport"
import dayjs from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"
import { FixedSizeList } from "react-window"
import RemoveRedEyeRounded from '@mui/icons-material/RemoveRedEyeRounded';
import IconButton from "@component/IconButton"
import { useNavigate } from "react-router-dom"
import Routes from "@service/routes"
import Tooltip from "@component/Tooltip"
import clsx from "clsx"

interface TotalizePurchaseListProps {
    totalSubTotal: number,
    totalTax: number,
    totalRete: number,
    totalPurchase: number,
    totalBalance: number,
    totalSelectionToPay: number,
}
const TotalizePurchaseList = ({ totalBalance, totalPurchase, totalRete, totalSubTotal, totalTax, totalSelectionToPay }:TotalizePurchaseListProps) => {

    return(
        <>
            <List sx={{ padding: 0, display: "flex" }} >
                <ListItem sx={{ padding: 0 }} >
                    <ListItemText primary={`$ ${currencyFormat(totalSelectionToPay)}`} secondary="Total selección a pagar" />
                </ListItem>
                <ListItem sx={{ padding: 0 }} >
                    <ListItemText primary={`$ ${currencyFormat(totalSubTotal+totalTax)}`} secondary="Subtotal + IVA" />
                </ListItem>
                <ListItem sx={{ padding: 0 }} >
                    <ListItemText primary={`$ ${currencyFormat(totalRete)}`} secondary="Rete-fuente" />
                </ListItem>
                <ListItem sx={{ padding: 0 }} >
                    <ListItemText primary={`$ ${currencyFormat(totalPurchase)}`} secondary="Total en compras" />
                </ListItem>
                <ListItem sx={{ padding: 0 }} >
                    <ListItemText primary={`$ ${currencyFormat(totalBalance)}`} secondary="Total pendiente por pagar" />
                </ListItem>
            </List>
        </>
    )
}

const PurchasesList = () => {
    const { getParameterGroup } = useURLParams()
    const { list, groupByProviders, totalBalance, totalPurchase, totalRete, totalSubTotal, totalTax } = useGetPurchasesFromSupplierBusiness()
    const { t } = useTranslation()
    const { group=null } = getParameterGroup(["group"])
    const { PurchaseReportContext, setGlobalState } = useAppContextSelector()
    const { selectedShoppingList } = PurchaseReportContext
    const navigate = useNavigate()

    let columns:GridColDef[] = [
        { ...GridSelectionColDef },
        {maxWidth: 80, minWidth: 80, field: "consecutive", headerName: "C#"},
        {flex: 1, minWidth: 200, field: "provider", headerName: "Proveedor", valueGetter: ({ row }) => `${row?.provider?.name??""}`},
        {maxWidth: 80, minWidth: 80, field: "invoiceNumber", headerName: "F#"},
        {maxWidth: 80, minWidth: 80, field: "typePayment", headerName: "Tipo de pago", renderCell: ({ row }) => `${t(row?.typePayment??"")}`},
        {maxWidth: 95, minWidth: 95, field: "dateReceived", headerName: "Fecha de Recibido", renderCell: ({ row }) => `${dayjs(row?.dateReceived??"").format("DD-MM-YYYY")}`},
        {maxWidth: 95, minWidth: 95, field: "dueDate", headerName: "Fecha de vencimiento", renderCell: ({ row }) => `${dayjs(row?.dueDate??"").format("DD-MM-YYYY")}`,
        cellClassName: (params: GridCellParams<any>) => {
            const { row } = params
            const diffDays = dayjs( dayjs(row.dueDate).startOf("day") ).diff(dayjs().startOf("day"), 'day')
            if (params.value == null) {
                return '';
            }

            return clsx('super-app', {
                negative: diffDays <= 0,
            });
        }},
        {maxWidth: 110, minWidth: 110, field: "subWithIva", headerName: "Sub+IVA", valueGetter: ({ row }) => ((row?.subTotal??0)+(row?.tax??0)), renderCell: ({ row }) => `$ ${currencyFormat(((row?.subTotal??0)+(row?.tax??0)), true)}` },
        {maxWidth: 95, minWidth: 95, field: "retention", headerName: "Retefuente", renderCell: ({ row }) => `$ ${currencyFormat((row?.retention??0), true)}` },
        {maxWidth: 110, minWidth: 110, field: "total", headerName: "Total", renderCell: ({ row }) => `$ ${currencyFormat(row?.total??0)}` },
        {maxWidth: 110, minWidth: 110, field: "balance", headerName: "Saldo por pagar",
        cellClassName: (params: GridCellParams<any>) => {
            if (params.value == null) {
                return '';
            }

            return clsx('super-app', {
                positive: params.value === 0,
            });
        },
        renderCell: ({ row }) => {
            return (
                <>
                    <Box width={'100%'} fontWeight={'bold'} >
                        {`$ ${currencyFormat((row?.balance??0), true)}`}
                    </Box>
                </>
            )
        }},
        {maxWidth: 160, minWidth: 160, field: "createdAt", headerName: "Fecha de registro", renderCell: ({ row }) => `${dayjs(row?.createdAt??"").format("DD-MM-YYYY HH:mm A")}`},
        {maxWidth: 50, minWidth: 50, field: " ", headerName: " ", renderCell: ({ row }) => {
            return(
                <>
                    <Tooltip title="Ver compra" >
                        <IconButton size="small" color="primary" onClick={() => navigate(Routes.indexPurchase(row?._id??"" as string))} >
                            <RemoveRedEyeRounded />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }},
    ]

    const handleSelectedPurchase = (selection:string[], index:number) => {
        const list = [...selectedShoppingList]
        list[index] = selection
        setGlobalState(n => {
            return {...n,
                PurchaseReportContext: {
                    ...n.PurchaseReportContext,
                    selectedShoppingList: list
                }
            }
        })
    }

    const populateDefaultSelection = list.filter((n) => (selectedShoppingList[0]??[]).some((x) => x === n._id))
    return(
        <>
            {group === "providers" ?
                <>
                <FixedSizeList height={(window.innerHeight-200)} itemCount={groupByProviders.length} itemSize={550} width={"100%"} >
                    {({ index, style }) => {
                        const group = groupByProviders[index];
                        let selection = selectedShoppingList[index]??[]
                        let results = group?.results ?? [];
                        let populatePurchases = list.filter((n) => selection.some((x) => x === n._id))
                        return(
                            <Box key={index} style={style} >
                                <Box pt={1} px={2} >
                                    <Typography fontWeight={'bold'} fontSize={'.875rem'} > {group.name} </Typography>
                                    <TotalizePurchaseList totalBalance={group.totalBalance} totalPurchase={group.totalPurchase} totalRete={group.totalRete} totalSubTotal={group.totalSubTotal} totalTax={group.totalTax} totalSelectionToPay={populatePurchases.reduce((acc, params) => (params?.balance??0)+acc,0)} />
                                </Box>
                                <DataGrid
                                    disableRowSelectionOnClick
                                    rowSelectionModel={selection as any}
                                    isRowSelectable={({ row }: GridRowParams) => row.balance >= 1}
                                    onRowSelectionModelChange={(n) => handleSelectedPurchase(n as string[], index)}
                                    checkboxSelection
                                    rows={results}
                                    columns={columns}
                                    sx={{
                                        '& .super-app.negative': {
                                            color: (theme) => theme.palette.error.main,
                                        },
                                        '& .super-app.positive': {
                                            color: (theme) => theme.palette.success.main,
                                        },
                                    }}
                                />
                            </Box>
                        )
                    }}
                </FixedSizeList>
                </>
                :
                <React.Fragment>
                    <Box pt={1} px={2} >
                        <TotalizePurchaseList totalBalance={totalBalance} totalPurchase={totalPurchase} totalRete={totalRete} totalSubTotal={totalSubTotal} totalTax={totalTax}  totalSelectionToPay={populateDefaultSelection.reduce((acc, params) => (params?.balance??0)+acc,0)} />
                    </Box>
                    <DataGrid
                        disableRowSelectionOnClick
                        rowSelectionModel={selectedShoppingList[0]??[] as any}
                        onRowSelectionModelChange={(n) => handleSelectedPurchase(n as string[], 0)}
                        isRowSelectable={({ row }: GridRowParams) => row.balance >= 1}
                        checkboxSelection
                        rows={list}
                        columns={columns}
                        sx={{
                            '& .super-app.negative': {
                                color: (theme) => theme.palette.error.main,
                            },
                            '& .super-app.positive': {
                                color: (theme) => theme.palette.success.main,
                            },
                        }}
                    />
                </React.Fragment>
            }
        </>
    )
}

export default PurchasesList