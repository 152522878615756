import Button from "@component/Button"
import Form from "@component/Form"
import Grid from "@component/Grid"
import NumberFormat from "@component/NumberFormat"
import TextField from "@component/TextField"
import { useValidation } from "@hook/formik"
import { Boxes } from '@interface/App'
import { ConceptExpense } from "@interface/Expense"
import { useExpense, useExpenseData } from "../hooks/useExpense"
import * as yup from 'yup'
import React from "react"
import { currencyFormat } from "@hook/useNumberFormat"
import Swal from "sweetalert2"

interface Props {
    box: Boxes
    conceptExpense: ConceptExpense
    bankId?: string
    maxAmount?: number
}

export const ExpensesForm = ({ box, conceptExpense, bankId, maxAmount }:Props) => {
    const { expenseFormData } = useExpenseData()
    const { registerNewExpense, isLoadingRegisterNewExpenseMutation } = useExpense()

    const handleSubmit = (params:any) => {
        if( maxAmount !== undefined ){
            if( params.amount > maxAmount ){
                Swal.fire({
                    icon: 'warning',
                    text: `No es posible crear un gasto por $ ${currencyFormat(params.amount)}, solo tiene disponible $ ${currencyFormat(maxAmount)} en la caja.`
                })
                return
            }
        }
        registerNewExpense(params)
    }

    const { formik } = useValidation({
        initialValues: expenseFormData,
        yupRules: {
            amount: yup.number()
            .min(1, "El monto debe ser superior a 0")
            .required("El monto es un campo obligatorio"),
            note: yup.string()
            .min(5, "Use una descripción mas larga")
            .required("La nota es un campo obligatorio")
        },
        onSubmit: handleSubmit
    })
    const loadCashierData = React.useCallback(() => {
        formik.setFieldValue('box', box)
        formik.setFieldValue('conceptExpense', conceptExpense)
        formik.setFieldValue('bank', bankId??null)
    }, [box, conceptExpense, bankId])

    React.useEffect(() => {
        loadCashierData()
    },[loadCashierData])

    return(
        <Form onSubmit={() => formik.handleSubmit()} >
            <Grid container >
                <Grid xs={12} >
                    <NumberFormat
                        label="Monto"
                        prefix="$ "
                        onChange={({ target }) => formik.setFieldValue('amount', Number(target?.value??0) )}
                        value={formik.values.amount}
                        error={formik.touched.amount && Boolean(formik.errors.amount)}
                        helperText={<>{formik.touched.amount && formik.errors.amount}</>}
                        maxVal={maxAmount}
                    />
                </Grid>
                <Grid xs={12} >
                    <TextField
                        label="Nota"
                        rows={2}
                        multiline
                        onChange={({ target }) => formik.setFieldValue('note', target?.value??"" )}
                        value={formik.values.note}
                        error={formik.touched.note && Boolean(formik.errors.note)}
                        helperText={<>{formik.touched.note && formik.errors.note}</>}
                    />
                </Grid>
                <Grid xs={12} >
                    <Button disableElevation type="submit" loading={isLoadingRegisterNewExpenseMutation} >
                        Registrar gasto
                    </Button>
                </Grid>
            </Grid>
        </Form>
    )
}

export default ExpensesForm