import { useAppDispatch, useAppSelector } from '../../../hooks/state';

import Dialog, { DialogContent } from '../../../components/Dialog';
import {
  closeBranchEditionForm,
  displayBranchForm,
} from '../services/CustomerBranchSlice';
import CustomerBranchForm from './CustomerBranchForm';

const CreateBranchOffices = () => {
  const dispatch = useAppDispatch();
  const { CustomerBranchState } = useAppSelector(({ CustomerBranchState }) => ({
    CustomerBranchState,
  }));
  const { isOpenForm, branchToEdit } = CustomerBranchState;

  const handleClose = () => {
    if (branchToEdit?._id === '') {
      dispatch(displayBranchForm(false));
    } else {
      dispatch(closeBranchEditionForm());
    }
  };

  return (
    <Dialog
      open={isOpenForm}
      onClose={handleClose}
      fullWidth
    >
      <DialogContent>
        <CustomerBranchForm />
      </DialogContent>
    </Dialog>
  );
};

export default CreateBranchOffices;
