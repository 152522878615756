import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useGetBankQuery } from "../../../api/querys/Bank"
import Fade from "@component/Fade"
import List from "@component/List"
import ListItemData from "@component/ListItemData"
import { addAsteriskSymbol } from "../../../services/parseData"

const BankInformation = () => {
    const { t } = useTranslation()
    const { bankId } = useParams()
    const { data } = useGetBankQuery({ bankId: bankId! })
    const bank = data?.payload

    return (
        <Fade in={true} >
            <List>
                <ListItemData showAvatar primaryText={t('bank')!} secondaryText={bank?.name ?? ""} />
                <ListItemData primaryText={t('accountNumber')!} secondaryText={bank?.accountNumber ? addAsteriskSymbol({ accountNumber: bank?.accountNumber }) : ""} />
                <ListItemData primaryText={t('personType')!} secondaryText={bank?.accountType ? t(`${bank?.accountType}`)! : ""} />
                <ListItemData primaryText={t('accountType')!} secondaryText={bank?.account ? t(`${bank?.account}`)! : ""} />
            </List>
        </Fade>
    )
}

export default BankInformation