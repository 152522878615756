import { signal } from '@preact/signals-react';
import dayjs from 'dayjs';

interface ExpenseProps {
    filterByRange: {
        startDate: Date
        endDate: Date
    }
}

export const ExpenseSignal = signal<ExpenseProps>({
    filterByRange:{
        startDate: dayjs().toDate(),
        endDate: dayjs().toDate(),
    }
})