import Grid from "@component/Grid"
import LockComponent from "@component/LockComponent"
import { useBusinessPermissions } from "../../hooks/usePermissions"
import CategoryProductSearchBar from "./components/CategoryProductSearchBar"
import CategoryProductsList from "./components/CategoryProductsList"
import CreateCategoryProduct from "./components/CreateCategoryProduct"
import Title from "@component/Title"

const ProductCategories = () => {
    const { ProductCategories } = useBusinessPermissions()
    return(
        <>
            {ProductCategories.lock_module ?
                <Grid container spacing={1} >
                    <Grid xs={12} >
                        <Title text="Categorías de producto" />
                    </Grid>
                    <Grid xs={12} >
                        <CategoryProductSearchBar />
                    </Grid>
                    <Grid xs={12} >
                        <CategoryProductsList />
                    </Grid>
                    <CreateCategoryProduct />
                </Grid>
                :
                <LockComponent />
            }
        </>
    )
}

export default ProductCategories