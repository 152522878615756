import { useURLParams } from "@hook/useURLParams"
import { DebtsToPayProps, HistoryOfPaidAccountsProps, PayAccountPayableProps, PayAllAccountPayableProps } from "@interface/DebtsToPay"
import { useGetDebtsToPayQuery, useGetHistoryOfPaidAccountsQuery, usePayTheEntireBillMutation, useRecordPaymentToAccountPayableMutation } from "@query/DebtsToPay"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import Swal from "sweetalert2"

export const useAccountsPayable = () => {
    const { delQueries } = useURLParams()
    const [ recordPaymentToAccountPayableMutation, { isLoading:isLoadingRecordPaymentToAccountPayableMutation } ] = useRecordPaymentToAccountPayableMutation()
    const [ payTheEntireBillMutation, { isLoading:isLoadingPayTheEntireBillMutation } ] = usePayTheEntireBillMutation()
    const { t } = useTranslation()

    const recordPaymentToAccountPayable = async (payload:PayAccountPayableProps) => {
        try {
            const resp = await recordPaymentToAccountPayableMutation(payload).unwrap()
            if( resp.status === 1 ){
                Swal.fire({
                    icon: 'success',
                    text: "El pago se realizo satisfactoriamente",
                    showConfirmButton: false,
                    timer: 2000
                })
                delQueries(['paymentId'])
            }else{
                Swal.fire({
                    icon: 'error',
                    text: t('anErrorOccurredWhileTryingToProcessTheRequest')!,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const payTheEntireBill = async (params:PayAllAccountPayableProps) => {
        try {
            const resp = await payTheEntireBillMutation(params).unwrap()
            if( resp.status === 1 ){
                Swal.fire({
                    icon: 'success',
                    text: "Los pagos se realizaron satisfactoriamente",
                    showConfirmButton: false,
                    timer: 2000
                })
                delQueries(['modal', 'tag'])
            }else{
                Swal.fire({
                    icon: 'error',
                    text: t('anErrorOccurredWhileTryingToProcessTheRequest')!,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        recordPaymentToAccountPayable,
        isLoadingRecordPaymentToAccountPayableMutation,
        payTheEntireBill,
        isLoadingPayTheEntireBillMutation
    }
}


export const useGetDebtsToPay = (params?: { only: "balance", show: "results" }) => {
    const { data, isLoading } = useGetDebtsToPayQuery({ only: params?.only, show: params?.show })
    const { getQuery } = useURLParams()
    let tagURLQuery = getQuery("tag")
    let debtsToPay = data?.payload??[] as DebtsToPayProps[]
    let list = [...debtsToPay]
    let groupList: ({ name: string, results: DebtsToPayProps[][0]['results'], total: number })[] = []
    if( Boolean(tagURLQuery) ){
        let results = list.find((n) => n._id === tagURLQuery) || {} as DebtsToPayProps[][0]
        let resultList = results?.results??[] as DebtsToPayProps[][0]['results']

        for( let value of resultList ){
            const findItemIndex = groupList.findIndex((n) => n.name === value.name)
            if( findItemIndex >= 0 ){
                groupList[findItemIndex].total += (value?.amount??0)
                groupList[findItemIndex].results.push(value)
            }else{
                groupList.push({
                    name: (value?.name??""),
                    results: [value],
                    total: (value?.amount??0)
                })
            }
        }
    }
    return { debtsToPay, groupList, isLoading }
}

export const useGetHistoryOfPaidAccounts = () => {
    const { getQuery } = useURLParams()
    const startDate = getQuery('initialDate') || dayjs().format("MM-DD-YYYY")
    const endDate = getQuery('finalDate') || dayjs().format("MM-DD-YYYY")

    const { data, isLoading } = useGetHistoryOfPaidAccountsQuery({ startDate, endDate })
    const history = data?.payload??[] as HistoryOfPaidAccountsProps[]
    return { history, isLoading }
}