import {
    Accordion as AccordionMUI, AccordionProps as AccordionPropsMUI,
    AccordionSummary  as AccordionSummaryMUI, AccordionSummaryProps as AccordionSummaryPropsMUI,
    AccordionDetails  as AccordionDetailsMUI, AccordionDetailsProps as AccordionDetailsPropsMUI,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded';


type AccordionProps = Pick<AccordionPropsMUI, "children">

const Accordion = ({ children, ...rest}:AccordionProps) => {
    return(
        <AccordionMUI variant='outlined' {...rest}>
            {children}
        </AccordionMUI>
    )
}

export default Accordion

type AccordionSummaryProps = Pick<AccordionSummaryPropsMUI, "children">

export const AccordionSummary = ({ children, ...rest}:AccordionSummaryProps) => {
    return(
        <AccordionSummaryMUI expandIcon={<ExpandMoreIcon />} {...rest}>
            {children}
        </AccordionSummaryMUI>
    )
}

type AccordionDetailsProps = Pick<AccordionDetailsPropsMUI, "children">

export const AccordionDetails = ({ children, ...rest}:AccordionDetailsProps) => {
    return(
        <AccordionDetailsMUI {...rest}>
            {children}
        </AccordionDetailsMUI>
    )
}