import { Divider as DividerMUI, DividerProps } from '@mui/material'

type Props = Pick<DividerProps, "children" | "sx" | "style" | "variant" | "classes" | "orientation" >

const Divider = ({ children, variant="fullWidth", ...rest }:Props) => {
    return(
        <DividerMUI component={'div'} variant={variant} {...rest} >
            {children}
        </DividerMUI>
    )
}

export default Divider