import DataGrid, { GridColDef } from "@component/DataGrid"
import { currencyFormat } from "@hook/useNumberFormat"
import dayjs from "dayjs"
import { useGetBusinessCashier } from "../hooks/useCashier"
import AddNewCashier from "./AddNewCashier"
import ListWithCashiersToolbar from "./ListWithCashiersToolbar"
import QueryStatsRounded from '@mui/icons-material/QueryStatsRounded';
import Stack from "@component/Stack"
import IconButton from "@component/IconButton"
import Tooltip from "@component/Tooltip"
import { useNavigate } from "react-router-dom"
import Routes from "@service/routes"

const ListWithCashiers = () => {
    const navigate = useNavigate()
    const { cashiers } = useGetBusinessCashier()
    const columns:GridColDef[] = [
        { flex: 1, field: "boxName", headerName: "Caja"},
        { maxWidth: 200, minWidth: 200, field: "base", headerName: "Base", valueGetter: ({row}) => `$ ${currencyFormat(row?.base??0)}`},
        { maxWidth: 200, minWidth: 200, field: "cashier", headerName: "Responsable", valueGetter: ({row}) => `${row?.cashier?.name??""} ${row?.cashier?.lastName??""}`},
        { maxWidth: 200, minWidth: 200, field: "createdAt", headerName: "Registrado", valueGetter: ({row}) => `${dayjs(row?.createdAt??"").format("DD-MM-YYYY HH:mm A")}` },
        { maxWidth: 50, minWidth: 50, field: " ", align: "center",
            renderCell: ({row}) => {
                return(
                    <Stack>
                        <Tooltip title="Ver movimientos" >
                            <IconButton size="small" onClick={() => navigate(Routes.indexCashierMovements(row?.cashier?._id??""))} >
                                <QueryStatsRounded />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )
            }
        },
    ]
    return(
        <>
            <DataGrid
                slots={{
                    toolbar: ListWithCashiersToolbar
                }}
                columns={columns}
                rows={cashiers}
            />
            <AddNewCashier />
        </>
    )
}

export default ListWithCashiers