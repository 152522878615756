import React from 'react'
import Dialog, { DialogContent } from "@component/Dialog"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { closeProductCategoryEditionForm, displayProductCategoryForm, toggleProductCategoryForm } from "../services/ProductCategorySlice"
import ProductCategoryForm from "./ProductCategoryForm"

const CreateCategoryProduct = () => {
    const { ProductCategoryState } = useAppSelector(({ ProductCategoryState }) => ({ ProductCategoryState }))
    const { isOpenForm, typeForm } = ProductCategoryState
    const dispatch = useAppDispatch()

    React.useEffect(() => {
        if( !isOpenForm ){
            dispatch(toggleProductCategoryForm("create"))
        }
    },[isOpenForm, dispatch])

    const handleClose = () => {
        if( typeForm === "create" ){
            dispatch(displayProductCategoryForm(false))
        }else{
            dispatch(closeProductCategoryEditionForm())
        }
    }

    return(
        <Dialog open={isOpenForm} onClose={handleClose} fullWidth >
            <DialogContent>
                <ProductCategoryForm />
            </DialogContent>
        </Dialog>
    )
}

export default CreateCategoryProduct