import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { typeOfPayment } from '..';
import Button from '../../../components/Button';
import DatePicker from '../../../components/DatePicker';
import Form from '../../../components/Form';
import { useAppDispatch } from '../../../hooks/state';
import { useInvoice } from '../hooks/useInvoice';
import { showCreditForm } from '../service/InvoiceSlice';

interface Props {
  invoiceId: string | undefined;
  observation: string
}

const CreditForm = ({ invoiceId, observation }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { changePaymentMethod } = useInvoice()

  const [creditExpirationDate, setCreditExpirationDate] = useState<string>(
    dayjs().add(1, "day").format('MM-DD-YYYY')
  );

  const handleSubmit = () => {
    changePaymentMethod(typeOfPayment.credit, {
      invoiceId: invoiceId ?? '',
      creditExpirationDate,
      observation
    });
    dispatch(showCreditForm(false));
  };

  return (
        <Form onSubmit={handleSubmit}>
          <DatePicker
            maxDate={null}
            minDate={dayjs().toDate()}
            name='Date'
            label={t('date')!}
            value={dayjs(creditExpirationDate).toDate()}
            onChange={({ value }) =>
            setCreditExpirationDate(dayjs(value).format('MM-DD-YYYY'))
            }
          />
          <Button type='submit'> {t('save')} </Button>
        </Form>
  );
};
export default CreditForm;
