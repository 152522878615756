import { BusinessSignal } from '@feature/Business/services/signals';
import { ExpenseSignal } from '@feature/Expenses/services/signals';
import { BusinessBox } from '@feature/MultiBox/services/signals'; 
import { MinorCash } from '@feature/MinorCash/services/signals';
import { MovementsCashier } from '@feature/MovementsCashier/services/signals';
import { PointOfSale } from '@feature/PointOfSale/signals/state';
const signals = {
    PointOfSaleSignalState: PointOfSale,
    MovementsCashierState: MovementsCashier,
    MinorCashState: MinorCash,
    ExpenseState: ExpenseSignal,
    BusinessState: BusinessSignal,
    BusinessBoxState: BusinessBox,
}

export type SignalAppState = typeof signals

export const AppSignalState = () => {
    return signals as SignalAppState
}