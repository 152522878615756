import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../services/localStorage';
import { ApiTags } from './ApiTags';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone'
dayjs.extend(tz)
const timeZone = dayjs.tz.guess()

export const domain = `https://api.facill.net`;
const prefix = `/api`;
export const endpoint = `${domain}${prefix}`;

export const mutationConfig = {
    url: '',
    method: 'POST',
    body: {},
};

export const api = createApi({
    reducerPath: 'FacilApi',
    baseQuery: fetchBaseQuery({
        baseUrl: endpoint,
        timeout: 60000,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('user_token');
            if (Boolean(token)) headers.set('Authorization', `token ${getToken()}`);
            headers.set('LocalTimeZone', `${timeZone}`);
            return headers;
        },
    }),
    tagTypes: ApiTags,
    endpoints: () => ({}),
});