import { useNavigate } from 'react-router-dom';
import React from 'react'
import { useAppDispatch } from '@hook/state';
import { FixedCostsForm, GetFixedCostResponse, GetFixedCostsResponse, PayFixedExpenseSend } from "@interface/FixedCosts"
import { useDeleteFixedCostMutation, useGetFixedExpenseQuery, useGetFixedExpensesQuery, usePayFixedExpenseMutation, useRegisterNewFixedExpenseMutation, useUpdateFixedExpenseMutation } from "@query/FixedCosts"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { showFixedExpenseForm, updateTableSelection } from '../services/FixedCostsSlice';
import Routes from '@service/routes';
import Swal from 'sweetalert2';

export const useFixedCosts = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [ registerNewFixedExpenseMutation, {isLoading:isLoadingRegisterNewFixedExpenseMutation} ] = useRegisterNewFixedExpenseMutation()
    const [ updateFixedExpenseMutation, {isLoading:isLoadingUpdateFixedExpenseMutation} ] = useUpdateFixedExpenseMutation()
    const [ payFixedExpenseMutation, {isLoading:isLoadingPayFixedExpenseMutation} ] = usePayFixedExpenseMutation()
    const [ deleteFixedCostMutation, { isLoading:isLoadingDeleteFixedCostMutation } ] = useDeleteFixedCostMutation()

    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const registerNewFixedExpense = async (params:FixedCostsForm) => {
        try {
            const resp = !Boolean(params?._id) ? await registerNewFixedExpenseMutation(params).unwrap() : await updateFixedExpenseMutation(params).unwrap()
            if( resp.status === 1 ){
                if( Boolean(params?._id) ){
                    Swal.fire({
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000,
                        text: "Se actualizo el gasto fijo con éxito"
                    })
                }else{
                    Swal.fire({
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000,
                        text: "Se añadió el gasto fijo con éxito"
                    })
                }
                dispatch(showFixedExpenseForm(false))
                if( Boolean(params?._id) ) navigate(Routes.indexFixedCosts(""))

            }else{
                Swal.fire({
                    icon: "error",
                    showConfirmButton: false,
                    timer: 2000,
                    text: t('anErrorOccurredWhileTryingToProcessTheRequest')!
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const payFixedExpense = async (params:PayFixedExpenseSend) => {
        try {
            const resp = await payFixedExpenseMutation(params).unwrap()
            if( resp.status === 1 ){
                let message = resp.payload.length >= 2 ? "Los gastos se pagaron con existo" : "El gasto se pago con existo"
                enqueueSnackbar(t(message), { variant: "success" })
            }else{
                enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'), { variant: "error" })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const deleteFixedCost = async (params:string[]) => {
        try {
            const resp = await deleteFixedCostMutation(params).unwrap()

            if( resp.status === 1 ){
                Swal.fire({
                    icon: 'success',
                    text: ` ${params.length >= 2 ? "Los items se eliminaron satisfactoriamente" : "El item se elimino satisfactoriamente"} `,
                    timer: 2000,
                    showConfirmButton: false
                })
                dispatch(updateTableSelection([]))
            }else{
                Swal.fire({
                    icon: 'error',
                    text: t('anErrorOccurredWhileTryingToProcessTheRequest')!,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    return {
        registerNewFixedExpense,
        isLoadingRegisterNewFixedExpenseMutation,
        isLoadingUpdateFixedExpenseMutation,
        isLoadingPayFixedExpenseMutation,
        payFixedExpense,
        isLoadingDeleteFixedCostMutation,
        deleteFixedCost
    }
}

interface useGetFixedExpensesProps {
    groupBy: "tags" | null
}

export const useGetFixedExpenses = ({ groupBy }:useGetFixedExpensesProps) => {
    const { data } = useGetFixedExpensesQuery({ groupBy })
    const fixedExpense = data?.payload??[] as GetFixedCostsResponse['payload']
    
    return fixedExpense
}

interface useFixedExpenseFormDataProps {
    fixedCostId?: string
}

export const useFixedExpenseFormData = ({ fixedCostId }:useFixedExpenseFormDataProps) => {
    const { data } = useGetFixedExpenseQuery({ fixedCostId: fixedCostId! }, { skip: !Boolean(fixedCostId) })
    const resp = data?.payload??{} as GetFixedCostResponse['payload']
    const responseResult = React.useMemo(() => resp,[resp])

    const [FormData, setFormData] = React.useState<FixedCostsForm>({ _id: undefined, amount: 0, expirationDate: 1, name: "", tags: [], recurrent: true, generateDaily: false })

    React.useEffect(() => {
        if( Boolean(responseResult?._id) && !Boolean(FormData?._id) ){
            setFormData({
                _id: responseResult._id,
                amount: responseResult.amount,
                expirationDate: responseResult.expirationDate,
                name: responseResult.name,
                tags: responseResult.tags,
                recurrent: responseResult.recurrent,
                generateDaily: responseResult.generateDaily,
            })
        }
    },[responseResult])

    return FormData
}