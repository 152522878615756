import DeleteItemsButton from "@component/DeleteItemsButton";
import Stack from "@component/Stack";
import { useMassProduct } from "../hooks/useMassProducts";

interface Props {
    items: string[]
}

const MassProductsToolbar = ({ items }:Props) => {
    const { deleteProduct } = useMassProduct()
    const handleDelete = () => deleteProduct(items)
    return(
        <>
            <Stack pt={1} px={2} direction={'row'} justifyContent={'end'} >
                <DeleteItemsButton onDelete={handleDelete} quantityItems={items.length} />
            </Stack>
        </>
    )
}

export default MassProductsToolbar