import { Slide as SlideMUI, Box, SlideProps } from '@mui/material';

type Props = Pick<SlideProps, "direction" | "in" | "mountOnEnter" | "unmountOnExit" | "children" | "timeout" >

const Slide = ({children, ...rest}:Props) => {
    return(
        <SlideMUI {...rest} >
            <Box>
                {children}
            </Box>
        </SlideMUI>
    )
}

export default Slide