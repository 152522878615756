import Box from "@component/Box"
import IconButton from "@component/IconButton"
import Tooltip from "@component/Tooltip"
import { useAppContextSelector } from "@hook/state"
import { Badge } from "@mui/material"
import React from "react"
import PaymentPurchases from "./components/PaymentPurchases"
import DiscountRoundedIcon from '@mui/icons-material/DiscountRounded';

const DiscountButton = () => {
    const [ anchorEl, setAnchorEl ] = React.useState<HTMLButtonElement | null>(null)
    const { PurchaseReportContext } = useAppContextSelector()
    const { selectedShoppingList } = PurchaseReportContext
    const lengthElem = selectedShoppingList.reduce((acc, params) => (params?.length??0)+acc,0)
    const handleOpen = () => {
        const elem = document.getElementById("discount-purchase") as HTMLButtonElement
        if( Boolean(elem) ){
            setAnchorEl(elem)
        }
    }

    return(
        <>
            <Box>
                <Tooltip title="Realizar descuentos" >
                    <IconButton squareButton disabled={lengthElem === 0} color="primary" id={`discount-purchase`} onClick={handleOpen} >
                        <Badge badgeContent={lengthElem} color="error" >
                            <DiscountRoundedIcon />
                        </Badge>
                    </IconButton>
                </Tooltip>
            </Box>
            <PaymentPurchases anchorEl={anchorEl} onClose={() => setAnchorEl(null)} variant="discount" />
        </>
    )
}

export default DiscountButton