import { useTranslation } from 'react-i18next';

import {
  Autocomplete as AutocompleteMUI,
} from '@mui/material';
import Autocomplete from '../../../components/Autocomplete';
import { useGetCustomersCitiesQuery } from '../../../api/querys/Customer';

type AutocompleteMUIProps = Omit<
  React.ComponentProps<typeof AutocompleteMUI>,
  'renderInput' | "options"
>;

interface Props extends AutocompleteMUIProps {
  CityId: string;
}

const CustomerCityInput: React.FC<Props> = ({
  CityId,
  ...rest
}) => {
  const { t } = useTranslation();

  const { data: CityData, isLoading } = useGetCustomersCitiesQuery();

  let cityArray:any = CityData?.payload??[];
  cityArray = cityArray?.customerCities?.results??[]
  const value = cityArray?.find((n: typeof cityArray[0]) => n._id === CityId);

  return (
    <Autocomplete
      name='City'
      label={t('cities')!}
      options={cityArray}
      loading={isLoading}
      value={value || null}
      {...rest}
    />
  );
};

export default CustomerCityInput;
