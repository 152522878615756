import DeleteRounded from "@mui/icons-material/DeleteRounded"
import Box from "./Box"
import { GridToolbarContainer } from "./DataGrid"
import IconButton from "./IconButton"
import Tooltip from "./Tooltip"
import Typography from "./Typography"
import { useTheme } from '@mui/material/styles'
import { useTranslation } from "react-i18next"

interface Props {
    listItems: any[]
    texts: string[]
    onClick?: () => void
    title?: string
}

const DataGridCustomHeaderToDelete = ({ listItems, texts, onClick, title }:Props) => {
        const text = listItems.length >= 2 ? texts[0] : texts[1]
        const theme = useTheme()
        const { t } = useTranslation()
        return(
        <GridToolbarContainer>
            <Box px={2} pt={2} width={1} >
                <Typography fontWeight={'bold'} textTransform={'uppercase'} >
                    {title}
                </Typography>
                {(listItems.length >= 1) &&
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={'center'} >
                        <Box >
                            <Typography textTransform={'uppercase'} fontWeight={'500'} >
                                <Box component={'span'} sx={{ color: theme.palette.error.light, fontWeight: "bold" }} > ({listItems.length}) </Box>
                                {text}
                            </Typography>
                        </Box>
                        <Box >
                            <Tooltip placement='top' title={t('removeSelection')} >
                                <IconButton color='error' onClick={onClick} >
                                    <DeleteRounded />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                }
            </Box>
        </GridToolbarContainer>
    )
}

export default DataGridCustomHeaderToDelete