import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { CustomerBusinessesTypeProps } from '../../../interfaces/Customer';

interface InitialStateProps {
  isOpenForm: boolean;
  BusinessesTypeToEdit: CustomerBusinessesTypeProps;
  selectionToDelete: string[];
  isOpenAlertDeleteBusinessesType: boolean;
}

const initialState: InitialStateProps = {
  isOpenForm: false,
  BusinessesTypeToEdit: { name: '', _id: '' },
  selectionToDelete: [],
  isOpenAlertDeleteBusinessesType: false,
};
export const BusinessesTypeSlice = createSlice({
  name: 'BusinessesType',
  initialState,
  reducers: {
    displayBusinessesTypeForm: (state, action: PayloadAction<boolean>) => {
      state.isOpenForm = action.payload;
    },
    closeBusinessesTypeEditionForm: (state) => {
      state.BusinessesTypeToEdit = { name: '', _id: '' };
      state.isOpenForm = false;
    },
    loadBusinessesTypeToEdit: (
      state,
      action: PayloadAction<InitialStateProps['BusinessesTypeToEdit']>
    ) => {
      state.BusinessesTypeToEdit = action.payload;
      state.isOpenForm = true;
    },
    selectBusinessesTypeToDelete: (state, action: PayloadAction<string[]>) => {
      state.selectionToDelete = action.payload;
    },
    showAlertDeleteBusinessesType: (state, action: PayloadAction<boolean>) => {
      state.isOpenAlertDeleteBusinessesType = action.payload;
    },
  },
});

export const {
  displayBusinessesTypeForm,
  closeBusinessesTypeEditionForm,
  loadBusinessesTypeToEdit,
  selectBusinessesTypeToDelete,
  showAlertDeleteBusinessesType,
} = BusinessesTypeSlice.actions;

export default BusinessesTypeSlice.reducer;
