import { cashierSummaryProps } from '@interface/Cashier';
import { signal } from '@preact/signals-react';
interface MovementsCashierPropsState {
    summaryCashier: null | cashierSummaryProps,
    amountToWithdraw: number
}

export const MovementsCashier = signal<MovementsCashierPropsState>({
    summaryCashier: null,
    amountToWithdraw: 0
})