import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

interface initialStateProps {
    startDate: string
    endDate: string
    branch: string
    zone: string
    businessType: string
    customerId: string
    billsOfTheDay: {
        createdAt: Date
        consecutive: number
        invoiceId: string
        total: number
        customer:{
            name: string
        }
    }[]
    showBillsOfTheDay: boolean,
    showProductPurchaseTracking: boolean
    invoiceCollection: string[]
}

const initialState:initialStateProps = {
    startDate: dayjs().startOf('week').format("MM-DD-YYYY"),
    endDate: dayjs().endOf('day').format("MM-DD-YYYY") ,
    branch: "",
    zone: "",
    businessType: "",
    customerId: "",
    billsOfTheDay: [],
    showBillsOfTheDay: false,
    showProductPurchaseTracking: false,
    invoiceCollection: []
}

export const CustomerPurchaseTrackingSlice = createSlice({
    name: 'customerPurchaseTracking',
    initialState,
    reducers: {
        updateStartDate: (state, action:PayloadAction<string>) => {
            state.startDate = action.payload
        },
        updateEndDate: (state, action:PayloadAction<string>) => {
            state.endDate = action.payload
        },
        updateBranch: (state, action:PayloadAction<string>) => {
            state.branch = action.payload
        },
        updateZone: (state, action:PayloadAction<string>) => {
            state.zone = action.payload
        },
        updateBusinessType: (state, action:PayloadAction<string>) => {
            state.businessType = action.payload
        },
        updateCustomer: (state, action:PayloadAction<string>) => {
            state.customerId = action.payload
        },
        showBillsOfTheDay: (state, action:PayloadAction<{ billsOfTheDay:initialStateProps['billsOfTheDay'], showBillsOfTheDay: boolean }>) => {
            state.billsOfTheDay = action.payload.billsOfTheDay
            state.showBillsOfTheDay = action.payload.showBillsOfTheDay
        },
        showProductPurchaseTracking: (state, action:PayloadAction<{show: boolean, invoiceCollection: string[]}>) => {
            state.showProductPurchaseTracking = action.payload.show
            state.invoiceCollection = action.payload.invoiceCollection
        },
    }
})

export const {
    updateStartDate,
    updateEndDate,
    updateBranch,
    updateZone,
    updateBusinessType,
    updateCustomer,
    showBillsOfTheDay,
    showProductPurchaseTracking
} = CustomerPurchaseTrackingSlice.actions
export default CustomerPurchaseTrackingSlice.reducer