import { Toolbar as ToolbarMUI, ToolbarProps } from '@mui/material'

type Props = Pick<ToolbarProps, "sx" | "children">

const Toolbar = ({ children, ...rest }:Props) => {
    return(
        <ToolbarMUI {...rest} >
            {children}
        </ToolbarMUI>
    )
}

export default Toolbar