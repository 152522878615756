import { useTranslation } from 'react-i18next';
import { useGetCustomerPurchaseTrackingQuery } from '../../../api/querys/PointOfSale';
import Autocomplete from '../../../components/Autocomplete';
import { useAppSelector } from '../../../hooks/state';

type AutocompleteProps = Omit<React.ComponentProps<typeof Autocomplete>, "renderCell" | "options">

interface Props extends AutocompleteProps{
  customerInputId: string;
  onChange?: (event: React.SyntheticEvent<Element, Event>, value: any) => void;
}

const CustomerInput = ({ customerInputId, ...rest }: Props) => {
  const { t } = useTranslation();

  const { CustomerPurchaseTrackingState } = useAppSelector(
    ({ CustomerPurchaseTrackingState }) => ({ CustomerPurchaseTrackingState })
  );
  const { startDate, endDate, branch, businessType, zone, customerId } =
    CustomerPurchaseTrackingState;

  const { data, isSuccess, isLoading } = useGetCustomerPurchaseTrackingQuery({
    startDate,
    endDate,
    branch,
    businessType,
    zone,
    customerId,
  });

  const listOfCustomers = data?.payload?.customers ?? [];

  const loadingData = isSuccess && !isLoading;

  const listOfCustomersWithTransformerId = listOfCustomers.map((props) => {
    return {
      _id: props.customerId,
      name: props.name,
    };
  });

  const customerValue =
    listOfCustomersWithTransformerId.find(
      (n: any) => n._id === customerInputId
    )! || null;

  return (
    <Autocomplete
      name='customer'
      label={t('customer')!}
      options={listOfCustomersWithTransformerId}
      loading={!loadingData}
      value={customerValue}
      {...rest}
    />
  );
};

export default CustomerInput;
