import Fade from "@component/Fade"
import Grid from "@component/Grid"
import LockComponent from "@component/LockComponent"
import { useBusinessPermissions } from "../../hooks/usePermissions"
import CustomerPurchaseTrackingFilters from "./components/CustomerPurchaseTrackingFilters"
import CustomerPurchaseTrackingList from "./components/CustomerPurchaseTrackingList"
import Title from "@component/Title"


const CustomerPurchaseTracking = () => {
    const { CustomerFollowUP } = useBusinessPermissions()
    return (
        <Fade in={true} >
            {CustomerFollowUP.lock_module ?
                <Grid container spacing={1} >
                    <Grid xs={12} >
                        <Title text="Seguimiento de cliente" />
                    </Grid>
                    <Grid xs={12} >
                        <CustomerPurchaseTrackingFilters />
                    </Grid>
                    <Grid xs={12} >
                        <CustomerPurchaseTrackingList />
                    </Grid>
                </Grid>
                :
                <LockComponent />
            }
        </Fade>
    )
}

export default CustomerPurchaseTracking