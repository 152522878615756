import { useTranslation } from "react-i18next"

// components
import Button from "@component/Button"
import Dialog, { DialogActions, DialogContent } from "@component/Dialog"
import Typography from "@component/Typography"

// hooks
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { useBank } from "../hooks/useBank"
import { showAlertDeleteBanks } from "../services/BankSlice"

const AlertDeleteBank = () => {
    const { t } = useTranslation()
    const { BankState } = useAppSelector(({ BankState }) => ({ BankState }))
    const { isOpenAlertDeleteBanks, selectedBanks } = BankState
    const dispatch = useAppDispatch()
    const { deleteBanks } = useBank()

    return (
        <Dialog open={isOpenAlertDeleteBanks} onClose={() => dispatch(showAlertDeleteBanks(false))} >
            <DialogContent sx={{ pb: 0 }} >
                <Typography> ¿{t('areYouSureYouWantToPerformThisAction')}? </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="text" color="error" onClick={() => deleteBanks(selectedBanks)} > {t('remove')} </Button>
                <Button variant="text" onClick={() => dispatch(showAlertDeleteBanks(false))} > {t('cancel')} </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AlertDeleteBank