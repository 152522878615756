import Fade from "@component/Fade"
import LockComponent from "@component/LockComponent"
import { useAppSelector } from "@hook/state"
import { useBusinessPermissions } from "../../hooks/usePermissions"
import ProviderSelection from "./components/ProviderSelection"
import Purchases from "./components/Purchases"
import BetaPhaseAlert from "@component/BetaPhaseAlert"

const PurchasingSuppliers = () => {
    const { PurchaseFromSuppliers } = useBusinessPermissions()
    const { PurchasesSuppliersState } = useAppSelector(({ PurchasesSuppliersState }) => ({ PurchasesSuppliersState }))
    const { perform } = PurchasesSuppliersState
    return (
        <Fade in={true} >
            <>
                <BetaPhaseAlert />
                {PurchaseFromSuppliers.lock_module ? 
                    <>
                        { (perform === null) || (perform === "advance") ? 
                            <ProviderSelection />
                            :
                            <Purchases />
                        }
                    </>
                    :
                    <LockComponent />
                }
            </>
        </Fade>
    )
}

export default PurchasingSuppliers