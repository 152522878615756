import { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '../../../components/Dialog';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import { useCustomerBranch } from '../hooks/useCustomerBranch';

import { showAlertDeleteBranch } from '../services/CustomerBranchSlice';

const AlertDeleteBranchOffices = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { CustomerBranchState } = useAppSelector(({ CustomerBranchState }) => ({
    CustomerBranchState,
  }));

  const { deleteCustomerBranchMutation, deleteCustomerBranchIsLoading } = useCustomerBranch();

  const { selectionToDelete, isOpenAlertDeleteBranch } =
  CustomerBranchState;

  const handleSubmit = () => {
    deleteCustomerBranchMutation(selectionToDelete);
    handleClose();
  };

  const handleClose = () => {
    dispatch(showAlertDeleteBranch(false));
  };

  const handleKeyDown = (evt: KeyboardEvent) => {
    const { keyCode, which } = evt;
    if (keyCode === 13 || which === 13) {
      handleSubmit();
    }
  };

  return (
    <Dialog
      open={isOpenAlertDeleteBranch}
      onClose={handleClose}
      onKeyDown={handleKeyDown}
    >
      <DialogTitle>{t('deleteBranch')}</DialogTitle>

      <DialogContent>
        <DialogContentText>{`¿${t(
          'areYouSureYouWantToPerformThisAction'
        )}?`}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant='text'
          color='error'
          type='submit'
          onClick={handleSubmit}
        >
          {t('remove')}
        </Button>
        <Button
          variant='text'
          onClick={handleClose}
          loading={deleteCustomerBranchIsLoading}
        >
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDeleteBranchOffices;
