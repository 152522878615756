import Fade from "@component/Fade"
import Grid from "@component/Grid"
import LockComponent from "@component/LockComponent"
import { useBusinessPermissions } from "../../../hooks/usePermissions"
import CreateProduct from "./CreateProduct"
import ProductsList from "./ProductsList"
import SearchProducts from "./SearchProducts"
import Title from "@component/Title"

const ListOfProducts = () => {
    const { Product } = useBusinessPermissions()
    return(
        <Fade in={true} >
            {Product.lock_module ?
                <Grid container spacing={2} >
                    <Grid xs={12} >
                        <Title text="Productos" />
                    </Grid>
                    <Grid xs={12} >
                        <SearchProducts />
                    </Grid>
                    <Grid xs={12} >
                        <ProductsList />
                    </Grid>
                    {Product.create_information &&
                        <CreateProduct />
                    }
                </Grid>
                :
                <LockComponent />
            }
        </Fade>
    )
}

export default ListOfProducts