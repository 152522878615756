import Box from "@component/Box"
import IconButton from "@component/IconButton";
import AddRounded from '@mui/icons-material/AddRounded';
import Tooltip from "@component/Tooltip";
import SearchProductsInput from "./SearchProductsInput";
import { useTranslation } from "react-i18next";
import Routes from "../../../services/routes";
import { useNavigate } from "react-router-dom";
import CloudUploadRounded from "@mui/icons-material/CloudUploadRounded";
import { displayProductCreationForm } from "../services/ProductSlice";
import { useAppDispatch } from "../../../hooks/state";
import { useBusinessPermissions } from "../../../hooks/usePermissions";

const SearchProducts = () => {
    const { Product } = useBusinessPermissions()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    return(
        <Box display={'flex'} alignItems={'center'} >
            {Product.only_view_and_edit &&
                <SearchProductsInput onChange={(_, val:any) => navigate(Routes.indexProduct(val._id))} />
            }
            <Box ml={1} display={'flex'} >
                {Product.create_information &&
                    <Tooltip title={t('createProduct')} placement="top-start" >
                        <IconButton squareButton onClick={() => dispatch(displayProductCreationForm(true))} >
                            <AddRounded />
                        </IconButton>
                    </Tooltip>
                }
                {Product.bulk_upload_from_file &&
                    <Tooltip title={t('loadProductsFromFile')} placement="top-start" >
                        <IconButton squareButton onClick={() => navigate(Routes.indexImportProducts())} >
                            <CloudUploadRounded />
                        </IconButton>
                    </Tooltip>
                }
            </Box>
        </Box>
    )
}

export default SearchProducts