import { displayZoneForm } from "./../services/ZoneSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/state";

import Dialog, { DialogContent } from "@component/Dialog";
import { ZoneForm } from "./ZoneForm";

export const CreateZone = () => {
  const { isOpenForm } = useAppSelector(({ ZoneState }) => ZoneState);

  const dispatch = useAppDispatch();

  return (
    <Dialog
      open={isOpenForm}
      onClose={() => dispatch(displayZoneForm(false))}
      fullWidth
    >
      <DialogContent dividers={true}>
        <ZoneForm />
      </DialogContent>
    </Dialog>
  );
};
