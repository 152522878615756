import DataGrid, { GridColDef } from "@component/DataGrid"
import { useAppSignalSelector } from "@hook/state"
import { currencyFormat } from "@hook/useNumberFormat"
import clsx from "clsx"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useGetMinorBoxHistory } from "../hooks/useMinorCash"
import MinorCashMoveListToolbar from "./MinorCashMoveListToolbar"

const MinorCashMoveList = () => {

    const { t } = useTranslation()
    const { MinorCashState } = useAppSignalSelector()
    const { endDate, startDate } = MinorCashState.value.filterDateRange
    const { history, isLoading } = useGetMinorBoxHistory({ endDate, startDate })
    const columns:GridColDef[] = [
        { maxWidth: 130, minWidth: 130, field: "cashier", headerName: "Registrado por", valueGetter: ({ row }) => `${row?.cashier?.name??""} ${row?.cashier?.lastName??""}` },
        { maxWidth: 110, minWidth: 110, field: "amount", headerName: "Monto", renderCell: ({ row }) => `$ ${currencyFormat((row?.amount??0))}`,
        cellClassName: ({row}) =>
            clsx('super-app', {
                negative: row.transfer !== "minorBox",
                positive: row.transfer === "minorBox"
            }),
        },
        { maxWidth: 100, minWidth: 100, field: "from", headerName: "Desde", renderCell: ({ row }) => `${t(row?.from??"")}` },
        { maxWidth: 100, minWidth: 100, field: "transfer", headerName: "Para", renderCell: ({ row }) => `${t(row?.transfer??"")}` },
        { flex: 1, field: "note", headerName: "Nota", valueGetter: ({ row }) => `${t(row?.note??"")} ${(row.note === 'cashierTranslate') ? `efectivo correspondiente al ${dayjs(row?.withdrawalDate).format("DD-MM-YYYY")}` : "" } ` },
        { maxWidth: 160, minWidth: 160, field: "createdAt", headerName: "Fecha de registro", renderCell: ({ row }) => dayjs(row?.createdAt).format("DD-MM-YYYY HH:mm A") },
    ]
    return(
        <>
            <DataGrid
                sx={{
                    '& .super-app.negative': {
                        color: (theme) => theme.palette.error.main
                    },
                    '& .super-app.positive': {
                        color: (theme) => theme.palette.success.main
                    },
                }}
                slots={{
                    toolbar: MinorCashMoveListToolbar
                }}
                loading={isLoading}
                columns={columns}
                rows={history}
            />
        </>
    )
}

export default MinorCashMoveList