import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useGetBankByBusinessQuery } from "../../../api/querys/Bank"
import DataGrid from "@component/DataGrid"
import DataGridCustomHeaderToDelete from "@component/DataGridCustomHeaderToDelete"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import Routes from "../../../services/routes"
import { selectBanks, showAlertDeleteBanks } from "../services/BankSlice"
import AlertDeleteBank from "./AlertDeleteBank"
import { addAsteriskSymbol } from "../../../services/parseData"
import WalletRounded from '@mui/icons-material/WalletRounded';
import IconButton from "@component/IconButton"
import Tooltip from "@component/Tooltip"

const BanksList = () => {
    const { BankState } = useAppSelector(({ BankState }) => ({ BankState }))
    const { selectedBanks } = BankState
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { data } = useGetBankByBusinessQuery()
    const banks = data?.payload ?? []
    const dispatch = useAppDispatch()

    const columns: Array<GridColDef> = [
        { flex: 1, field: "name", headerName: t('banks')! },
        {
            flex: 1, field: "accountType", headerName: t('personType')!, valueGetter: (params: GridValueGetterParams) => {
                return t(params.row.accountType)!
            }
        },
        {
            flex: 1, field: "account", headerName: t('accountType')!, valueGetter: (params: GridValueGetterParams) => {
                return t(params.row.account)!
            }
        },
        {
            flex: 1, field: "accountNumber", headerName: t('accountNumber')!, minWidth: 200, align: 'left', valueGetter: (params: GridValueGetterParams) => {
                const accountNumber = params.row.accountNumber.toString()
                return addAsteriskSymbol({ accountNumber: accountNumber })
            }
        },
        {
            flex: 1, field: "tools", headerName: ' ', minWidth: 50, maxWidth: 50, align: "center", renderCell: ({ row }) => {
                return(
                    <>
                        <Tooltip title="Ver caja" >
                            <IconButton size="small" onClick={() => navigate(Routes.indexBankBox(row._id))} >
                                <WalletRounded sx={{ color: (theme) => theme.palette.primary.main }} />
                            </IconButton>
                        </Tooltip>
                    </>
                )
            }
        },
    ]

    const handleCellClick = (params: any) => {
        const { field, row } = params
        if (field !== "__check__" && field !== "tools") {
            navigate(Routes.indexBank(row._id))
        }
    }

    const CustomToolbar = () => <DataGridCustomHeaderToDelete listItems={selectedBanks} texts={[t('selectedBanks')!, t('selectedBank')!]} onClick={() => dispatch(showAlertDeleteBanks(true))} />

    return (
        <>
            <DataGrid
                onRowSelectionModelChange={params => dispatch(selectBanks(params))}
                onCellClick={handleCellClick}
                components={{
                    Toolbar: CustomToolbar
                }}
                disableRowSelectionOnClick
                checkboxSelection
                rows={banks}
                columns={columns}
            />
            <AlertDeleteBank />
        </>
    )
}

export default BanksList


