import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ProductsForSupplierPurchasesResponse } from '@interface/Product'
import { ProviderSelected, PurchasingProductsSelected } from '@interface/Purchasing'
import dayjs from 'dayjs'

export interface stateProps {
    openPaymentSelectionWindow: boolean
    openFormCredit: boolean
    openFormCash: boolean
    providerSelected: ProviderSelected | null
    productSelected: ProductsForSupplierPurchasesResponse['payload'][0] | null
    productsSelectedList: Array<PurchasingProductsSelected>
    productOfTheSelectedList: PurchasingProductsSelected | null
    billingDate: string
    dateReceived: string
    dueDate: string
    whoReceives: string
    invoiceNumber: string
    perform: "purchase" | "advance" | null
    valueTotalOfPreviousPurchases: number
    purchaseId: string | null
    haulier: string
}

const initialState:stateProps = {
    perform: null,
    openFormCash: false,
    openFormCredit: false,
    openPaymentSelectionWindow: false,
    providerSelected: null,
    productSelected: null,
    productsSelectedList: [],
    productOfTheSelectedList: null,
    billingDate: dayjs().format("MM-DD-YYYY"),
    dateReceived: dayjs().format("MM-DD-YYYY"),
    dueDate: dayjs().add(1, "day").format("MM-DD-YYYY"),
    whoReceives: "",
    valueTotalOfPreviousPurchases: 0,
    invoiceNumber: "",
    purchaseId: null,
    haulier: ""
}

export const PurchasesSuppliers = createSlice({
    name: 'purchasesSuppliers',
    initialState,
    reducers: {
        updateSelectedProduct: (state, action:PayloadAction<stateProps['productSelected']>) => {
            state.productSelected = action.payload
        },
        updateListOfSelectedProducts: (state, action:PayloadAction<stateProps['productsSelectedList']>) => {
            state.productsSelectedList = action.payload
        },
        updateSelectedProductOfList: (state, action:PayloadAction<stateProps['productOfTheSelectedList']>) => {
            state.productOfTheSelectedList = action.payload
        },
        updateSelectedProvider: (state, action:PayloadAction<stateProps['providerSelected']>) => {
            state.providerSelected = action.payload
        },
        showPaymentSelectionWindow: (state, action:PayloadAction<boolean>) => {
            state.openPaymentSelectionWindow = action.payload
        },
        showCashForm: (state, action:PayloadAction<boolean>) => {
            state.openFormCash = action.payload
        },
        displayFormCredit: (state, action:PayloadAction<boolean>) => {
            state.openFormCredit = action.payload
        },
        updateDateReceived: (state, action:PayloadAction<Date>) => {
            state.dateReceived = dayjs(action.payload).format("MM-DD-YYYY")
        },
        updateBillingDate: (state, action:PayloadAction<Date>) => {
            state.billingDate = dayjs(action.payload).format("MM-DD-YYYY")
        },
        updateDueDate: (state, action:PayloadAction<Date>) => {
            state.dueDate = dayjs(action.payload).format("MM-DD-YYYY")
        },
        updateWhoReceives: (state, action:PayloadAction<string>) => {
            state.whoReceives = action.payload
        },
        updatePerform: (state, action:PayloadAction<stateProps['perform']>) => {
            state.perform = action.payload
        },
        restartDefaultValues: (state) => {
            state.openFormCash = false
            state.openFormCredit = false
            state.openPaymentSelectionWindow = false
            state.providerSelected = null
            state.productsSelectedList = []
            state.billingDate = dayjs().format("MM-DD-YYYY")
            state.dateReceived = dayjs().format("MM-DD-YYYY")
            state.dueDate = dayjs().add(1, "day").format("MM-DD-YYYY")
            state.whoReceives = ""
            state.perform = null
            state.purchaseId = null
            state.valueTotalOfPreviousPurchases = 0
            state.haulier = ""
        },
        updateTotalValueOfPreviousPurchases: (state, action:PayloadAction<number>) => {
            state.valueTotalOfPreviousPurchases = action.payload
        },
        updateInvoiceNumber: (state, action:PayloadAction<string>) => {
            state.invoiceNumber = action.payload
        },
        updatePurchaseId: (state, action:PayloadAction<string | null>) => {
            state.purchaseId = action.payload
        },
        updateValueOfCarrier: (state, action:PayloadAction<string>) => {
            state.haulier = action.payload
        }
    }
})

export const {
    updateSelectedProduct,
    updateListOfSelectedProducts,
    updateSelectedProductOfList,
    updateSelectedProvider,
    showPaymentSelectionWindow,
    showCashForm,
    displayFormCredit,
    updateDateReceived,
    updateBillingDate,
    updateWhoReceives,
    updateDueDate,
    restartDefaultValues,
    updatePerform,
    updateTotalValueOfPreviousPurchases,
    updateInvoiceNumber,
    updatePurchaseId,
    updateValueOfCarrier,
} = PurchasesSuppliers.actions
export default PurchasesSuppliers.reducer