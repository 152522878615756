import Box from '../../../components/Box';
import Tooltip from '../../../components/Tooltip';
import IconButton from '../../../components/IconButton';
import CustomerBranchInput from '../../Customer/components/CustomerBranchInput';
import { useTranslation } from 'react-i18next';
import { AddRounded } from '@mui/icons-material';
import { useAppDispatch } from '../../../hooks/state';
import {
  displayBranchForm,
  loadBranchToEdit,
} from '../services/CustomerBranchSlice';
import { ChangeEvent } from 'react';

const CustomerBranchSearchBar: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleOpenForm = () => {
    dispatch(displayBranchForm(true));
  };

  const handleAutocompleteChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: Array<string>,
    name: string | undefined
  ) => {
    dispatch(loadBranchToEdit(value));
  };

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
    >
      <CustomerBranchInput
        customerBranchId=''
        onChange={handleAutocompleteChange}
      />
      <Box ml={1}>
        <Tooltip title={t('createNewBranch')}>
          <IconButton
            squareButton
            onClick={handleOpenForm}
          >
            <AddRounded />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default CustomerBranchSearchBar;
