import { CashTransferFormProps } from '@interface/CashTransfer';
import { signal } from '@preact/signals-react';

interface BusinessProps {
    transferCash: CashTransferFormProps
    showTransferCashWindow: boolean
}

export const BusinessSignal = signal<BusinessProps>({
    transferCash: {
        amount: 0,
        bank: null,
        note: "",
        transfer: "largeBox",
        whoReceives: "",
        from: "minorBox",
        outputBank: ""
    },
    showTransferCashWindow: false
})