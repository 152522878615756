import { api, mutationConfig } from "../splitApi";
import type { BranchForm, businessCustomersDataResponse, CustomerBranchResponse, CustomerBusinessTypesResponse, CustomerDataForm, CustomerDataResponse, CustomerNeighborhoodsForm, CustomerRegisterResponse, CustomerZonesResponse, RegisterCustomerNeighborhoodsResponse, RegisterBranchResponse, RegisterCustomerCityResponse, CustomerCityForm, RegisterCustomerBusinessesTypeResponse, CustomerBusinessesTypeForm, CustomerAdvanceFormProps, CustomerAdvanceAsyncResponse, UpdateCustomerAdvanceProps, UpdateCustomerAdvanceAsyncResponse, } from "../../interfaces/Customer";

const Customer = api.injectEndpoints({
    endpoints: (build) => ({
        getBusinessCustomers: build.query<businessCustomersDataResponse, void>({
            query: () => ({ url: "/get/business/customers" }),
            providesTags: ["Customer"],
        }),
        getAccurateCustomerInformation: build.query<any, { fields: string }>({
            query: ({ fields }) => ({ url: `/get/accurate/customer/information`, params: {fields} }),
        }),
        getCustomer: build.query<CustomerDataResponse, string>({
            query: (customerId) => ({ url: `/get/customer`, params: { customerId } }),
            providesTags: ["Customer"],
        }),
        getCustomerBusinessType: build.query<CustomerBusinessTypesResponse, void>({
            query: () => ({ url: `/get/customer/business/types` }),
            providesTags: ['BusinessType']
        }),
        getCustomerBranch: build.query<CustomerBranchResponse, void>({
            query: () => ({ url: `/get/customer/branches` }),
            providesTags: ["CustomerBranch"]
        }),
        getCustomerZone: build.query<CustomerZonesResponse, void>({
            query: () => ({ url: `/get/customer/zones` }),
            providesTags: ["Zones"],
        }),
        getCustomersNeighborhood: build.query<RegisterCustomerNeighborhoodsResponse, void>({
            query: () => ({ url: `/get/customer/neighborhood` }),
            providesTags: ["CustomerNeighborhood"],
        }),
        getCustomersCities: build.query<RegisterCustomerCityResponse, void>({
            query: () => ({ url: `/get/customer/cities` }),
            providesTags: ["CustomerCity"],
        }),
        registerCustomer: build.mutation<CustomerRegisterResponse, Partial<CustomerDataForm>>({
            query(body) {
                return { ...mutationConfig, url: "/register/customer", body };
            },
            invalidatesTags: ["Customer"],
        }),
        registerCustomersNeighborhood: build.mutation<RegisterCustomerNeighborhoodsResponse, CustomerNeighborhoodsForm>({
            query(body) {
                return { ...mutationConfig, url: "/register/customer/neighborhood", body };
            },
            invalidatesTags: ["CustomerNeighborhood"],
        }),
        registerCustomersCity: build.mutation<RegisterCustomerCityResponse, CustomerCityForm>({
            query(body) {
                return { ...mutationConfig, url: "/register/customer/city", body };
            },
            invalidatesTags: ["CustomerCity"],
        }),
        updateCustomersNeighborhood: build.mutation<RegisterCustomerNeighborhoodsResponse, CustomerNeighborhoodsForm>({
            query(body) {
                return { ...mutationConfig, url: "/update/customer/neighborhood", body };
            },
            invalidatesTags: ["CustomerNeighborhood"],
        }),
        updateCustomersCity: build.mutation<RegisterCustomerCityResponse, CustomerCityForm>({
            query(body) {
                return { ...mutationConfig, url: "/update/customer/city", body };
            },
            invalidatesTags: ["CustomerCity"],
        }),
        registerCustomersBusinessesType: build.mutation<RegisterCustomerBusinessesTypeResponse, CustomerBusinessesTypeForm>({
            query(body) {
                return { ...mutationConfig, url: "/register/customer/business/type", body };
            },
            invalidatesTags: ["BusinessType"],
        }),
        updateCustomersBusinessesType: build.mutation<RegisterCustomerBusinessesTypeResponse, CustomerBusinessesTypeForm>({
            query(body) {
                return { ...mutationConfig, url: "/update/customer/business/type", body };
            },
            invalidatesTags: ["BusinessType"],
        }),
        registerCustomerBranch: build.mutation<RegisterBranchResponse, BranchForm>({
            query(body) {
                return { ...mutationConfig, url: "/register/customer/branch", body };
            },
            invalidatesTags: ["CustomerBranch"],
        }),
        updateCustomerBranch: build.mutation<RegisterBranchResponse, BranchForm >({
            query(body) {
                return { ...mutationConfig, url: "/update/customer/branch", body };
            },
            invalidatesTags: ["CustomerBranch"]
        }),
        updateCustomer: build.mutation<CustomerRegisterResponse, Partial<CustomerDataForm>>({
            query(body) {
                return { ...mutationConfig, url: "/update/customer", body };
            },
            invalidatesTags: ["Customer"],
        }),
        deleteCustomers: build.mutation<any, string[]>({
            query(body) {
                return { ...mutationConfig, url: "/delete/customers", body };
            },
            invalidatesTags: ["Customer"],
        }),
        deleteCustomersNeighborhood: build.mutation<any, string[]>({
            query(body) {
                return { ...mutationConfig, url: "/delete/customer/neighborhood", body };
            },
            invalidatesTags: ["CustomerNeighborhood"],
        }),
        deleteCustomersCity: build.mutation<any, string[]>({
            query(body) {
                return { ...mutationConfig, url: "/delete/customer/cities", body };
            },
            invalidatesTags: ["CustomerCity"],
        }),
        deleteCustomersBusinessesType: build.mutation<any, string[]>({
            query(body) {
                return { ...mutationConfig, url: "/delete/customer/business/type", body };
            },
            invalidatesTags: ["BusinessType"],
        }),
        deleteCustomerBranch: build.mutation<any, string[]>({
            query(body) {
                return { ...mutationConfig, url: "/delete/customer/branch", body };
            },
            invalidatesTags: ["CustomerBranch"],
        }),
        recordCustomerAdvance: build.mutation<CustomerAdvanceAsyncResponse, CustomerAdvanceFormProps>({
            query(body) {
                return { ...mutationConfig, url: "/record/customer/advance", body };
            },
            invalidatesTags: ["Customer"],
        }),
        updateCustomerAdvance: build.mutation<UpdateCustomerAdvanceAsyncResponse, UpdateCustomerAdvanceProps>({
            query(body) {
                return {...mutationConfig, url: '/update/customer/advance', body}
            },
            invalidatesTags: ['Customer', 'MinorBox', 'LargeBox', 'MasterBox', 'BankBox', 'BoxBalances']
        }),
        returnAdvanceToCustomer: build.mutation<any, any>({
            query(body) {
                return {...mutationConfig, url: '/return/advance/to/customer', body}
            },
            invalidatesTags: ['Customer', 'MinorBox', 'LargeBox', 'MasterBox', 'BankBox', 'BoxBalances']
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetBusinessCustomersQuery,
    useGetCustomerQuery,
    useGetCustomerBusinessTypeQuery,
    useGetCustomerBranchQuery,
    useGetCustomerZoneQuery,
    useGetCustomersNeighborhoodQuery,
    useGetCustomersCitiesQuery,
    useRegisterCustomersBusinessesTypeMutation,
    useRegisterCustomerMutation,
    useRegisterCustomerBranchMutation,
    useUpdateCustomerMutation,
    useUpdateCustomerBranchMutation,
    useDeleteCustomersMutation,
    useRegisterCustomersNeighborhoodMutation,
    useRegisterCustomersCityMutation,
    useUpdateCustomersNeighborhoodMutation,
    useUpdateCustomersCityMutation,
    useDeleteCustomersNeighborhoodMutation,
    useDeleteCustomersCityMutation,
    useUpdateCustomersBusinessesTypeMutation,
    useDeleteCustomersBusinessesTypeMutation,
    useDeleteCustomerBranchMutation,
    useGetAccurateCustomerInformationQuery,
    useRecordCustomerAdvanceMutation,
    useUpdateCustomerAdvanceMutation,
    useReturnAdvanceToCustomerMutation
} = Customer;



