import Dialog, { DialogContent, DialogTitle } from "../../../../components/Dialog"
import { useAppDispatch, useAppSelector } from "../../../../hooks/state"
import { loadSalesList, showPOSProductWindow } from "../../services/PointOfSaleSlice"
import Divider from "../../../../components/Divider"
import Grid from "../../../../components/Grid"
import React from "react"
import { ListProductsInvoice } from "@interface/PointOfSale"
import { useTranslation } from "react-i18next"
import { useSnackbar } from "notistack"
import WindowProductPOSHeader from "./components/WindowProductPOSHeader"
import WindowProductPOSInformationSale from "./components/WindowProductPOSInformationSale"
import WindowProductPOSQuantityInput from "./components/WindowProductPOSQuantityInput"
import { ConfigProduct } from "./interface/WindowProductPOS"
import WindowProductPOSPrices from "./components/WindowProductPOSPrices"
import WindowProductPOSTotalize from "./components/WindowProductPOSTotalize"

const configDefault:ConfigProduct = { pricePosition: 0, typePrice: "sellPrice", quantity: 1, manualPrice: 0 }

interface Props {
    editProduct: ListProductsInvoice | null
    setLoadProductToEdit : React.Dispatch<React.SetStateAction<ListProductsInvoice | null>>
}

const WindowProductPOS = ({ editProduct, setLoadProductToEdit }:Props) => {
    const { enqueueSnackbar } = useSnackbar()
    const [productToList, setProductToList] = React.useState<ListProductsInvoice>({ _id: "", costPrice: 0, quantity: 0, name: "", salePrice: 0, typePrice: "sellPrice" })
    const [configProduct, setConfigProduct] = React.useState<ConfigProduct>({...configDefault})
    const { pricePosition, typePrice, quantity, manualPrice } = configProduct
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }))
    const { productList } = PointOfSaleState
    const { openWindow, productSelected,  } = PointOfSaleState.windowProduct
    const product = {...productSelected}
    let timer:any = null
    
    const updateProductToList = React.useCallback(() => {
        setProductToList(n => ({...n,
            _id: product?._id??"",
            costPrice: product?.cost??0,
            name: product?.name??"",
            quantity: quantity,
            salePrice: typePrice === "sellPrice" ? product?.prices?.[pricePosition]?.sellPrice??0 : manualPrice,
            typePrice: typePrice
        }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[pricePosition, typePrice, quantity, manualPrice, product._id])
    React.useEffect(() => {
        updateProductToList()
    },[updateProductToList])
    const selectTextInputElement = (elementId:string) => {
        const element = (document.getElementById(elementId) as HTMLInputElement)
        if( element !== null ){
            timer = setTimeout(() => {
                element.select()
            }, 500)
        }
    }

    React.useEffect(() => {
        selectTextInputElement('quantityProduct')
        return () => {
            clearTimeout(timer)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[quantity])

    const addProductToTheList = () => {
        if( productToList.quantity === 0 ){
            enqueueSnackbar(t('theAmountOfProductMustBeHigher')!,{variant: "warning"})
            return
        }
        if( productToList.salePrice < productToList.costPrice ){
            enqueueSnackbar(t('theSalePriceMustBeHigherThanTheCostOfTheProduct')!,{variant: "warning"})
            return
        }
        if( Boolean(productToList._id) ){
            const list = [...productList]
            const findIndex = list.findIndex(n => n._id === productToList._id)
            if( findIndex >= 0 ){
                list[findIndex] = productToList
            }else{
                list.push(productToList)
            }
            dispatch(loadSalesList(list))
            dispatch(showPOSProductWindow({ isOpen: false, productSelected: undefined }))
        }
    }

    const handleKeyEvent = (event:React.KeyboardEvent) => {
        const { keyCode, which } = event
        const code = keyCode??which
        if( code === 13 ){
            event.preventDefault()
            event.stopPropagation()
            addProductToTheList()
        }
    }

    React.useEffect(() => {
        if( editProduct !== null && editProduct !== undefined && Boolean(product._id) ){
            let index:number | null = null
            const prices = product.prices!
            const findIndexPrice = prices.findIndex(n => n.sellPrice === editProduct.salePrice)
            if( findIndexPrice >= 0 ){
                index = findIndexPrice
            }
            setConfigProduct(n => ({...n,
                quantity: editProduct.quantity,
                typePrice: editProduct.typePrice === "sellPrice" ? (index === null ? "manual" : "sellPrice") : "manual",
                pricePosition: index??0,
                manualPrice: editProduct.salePrice
            }))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[editProduct, product._id])

    React.useEffect(() => {
        if( !openWindow ){
            setConfigProduct({...configDefault})
            setLoadProductToEdit(null)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[openWindow])

    React.useEffect(() => {
        if( openWindow === true ){
            setTimeout(() => {
                const d = (document.getElementById('quantityProduct') as HTMLInputElement)
                if( d !== null ){
                    d.focus()
                    setTimeout(() => {
                        d.select()
                    },500)
                }
            },250)
        }
    },[openWindow])

    return(
        <Dialog onKeyDown={handleKeyEvent} open={openWindow} fullWidth maxWidth="xs" onClose={() => dispatch(showPOSProductWindow({ isOpen: false, productSelected: undefined }))} >
            { (openWindow && productSelected !== undefined ) &&
                <>
                    <DialogTitle >
                        <WindowProductPOSHeader addProductToTheList={addProductToTheList} editProduct={editProduct} />
                        <Divider />
                    </DialogTitle>
                    <DialogContent>
                        <Grid container sx={{ display: 'flex', alignItems: 'center' }} spacing={2} >
                            <Grid xs={8} >
                                <WindowProductPOSInformationSale manualPrice={manualPrice} pricePosition={pricePosition} typePrice={typePrice} />
                            </Grid>
                            <Grid xs={4} >
                                <WindowProductPOSQuantityInput configProduct={configProduct} setConfigProduct={setConfigProduct} />
                            </Grid>
                            <Grid xs={12} >
                                <WindowProductPOSPrices manualPrice={manualPrice} pricePosition={pricePosition} setConfigProduct={setConfigProduct} typePrice={typePrice} />
                            </Grid>
                            <Grid xs={12} >
                                <WindowProductPOSTotalize manualPrice={manualPrice} pricePosition={pricePosition} quantity={quantity} typePrice={typePrice} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </>
            }
        </Dialog>
    )
}

export default WindowProductPOS