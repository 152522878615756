import React from 'react';
import { Table as TableMUI, TableHead as TableHeadMUI, TableRow as TableRowMUI, TableCell as TableCellMUI, TableBody as TableBodyMUI } from '@mui/material'

type TableProps = Pick<React.ComponentProps<typeof TableMUI>, "children" | "size" | "sx">

const Table = ({children, ...res}:TableProps) => {
    return(
        <TableMUI {...res} >
            {children}
        </TableMUI>
    )
}

export default Table

type TableHeadProps = Pick<React.ComponentProps<typeof TableHeadMUI>, "children">

export const TableHead = ({children, ...res}:TableHeadProps) => {
    return(
        <TableHeadMUI {...res} >
            {children}
        </TableHeadMUI>
    )
}

type TableRowProps = Pick<React.ComponentProps<typeof TableRowMUI>, "children">

export const TableRow = ({children, ...res}:TableRowProps) => {
    return(
        <TableRowMUI {...res} >
            {children}
        </TableRowMUI>
    )
}

type TableCellProps = Pick<React.ComponentProps<typeof TableCellMUI>, "children" | "rowSpan" | "colSpan" | "align" | "sx" >

export const TableCell = ({children, ...res}:TableCellProps) => {
    return(
        <TableCellMUI {...res} >
            {children}
        </TableCellMUI>
    )
}

type TableBodyProps = Pick<React.ComponentProps<typeof TableBodyMUI>, "children">

export const TableBody = ({children, ...res}:TableBodyProps) => {
    return(
        <TableBodyMUI {...res} >
            {children}
        </TableBodyMUI>
    )
}