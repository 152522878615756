import Fade from "@component/Fade"
import Grid from "@component/Grid"
import CashTransfer from "@feature/Business/components/CashTransfer"
import CreateExpense from "@feature/Expenses/components/CreateExpense"
import PayFixedExpense from "@feature/FixedCosts/components/PayFixedExpense"
import { Boxes } from "@interface/App"
import { useParams } from "react-router-dom"
import AbstractBox from "./components/AbstractBox"
import FilterBox from "./components/FilterBox"
import { useGetBox } from "./hooks/useBox"
import { useBusinessPermissions } from "@hook/usePermissions"
import LockComponent from "@component/LockComponent"
import MovementsTab from "./components/MovementsTab"

interface Props {
    box: Boxes
}

const MultiBox = ({ box="largeBox" }:Props) => {
    const { bankId } = useParams()
    const { box:boxBalance } = useGetBox({ box, bank: bankId })
    const { Boxes } = useBusinessPermissions()
    return(
        <Fade in={true} >
            {Boxes.lock_module ?
                <Grid container spacing={1} >
                    <Grid xs={8} >
                        <FilterBox />
                        <MovementsTab box={box} />
                        <PayFixedExpense maxAmount={boxBalance?.amount??0} forBox={box} bankId={bankId} />
                        <CashTransfer maxAmount={boxBalance?.amount??0} from={box} />
                        <CreateExpense maxAmount={boxBalance?.amount??0} bankId={bankId} box={box} conceptExpense="cashExpense" />
                    </Grid>
                    <Grid xs={4} >
                        <AbstractBox box={box} />
                    </Grid>
                </Grid>
                :
                <LockComponent />
            }
        </Fade>
    )
}

export default MultiBox