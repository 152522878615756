import { Box, Tooltip as TooltipMUI, TooltipProps } from '@mui/material'

type Props = Pick<TooltipProps, "children" | "title" | "placement" | "sx">

const Tooltip = ({ children, ...rest }:Props) => {
    return(
        <TooltipMUI arrow {...rest} >
            <Box display={'inline-flex'} >
                {children}
            </Box>
        </TooltipMUI>
    )
}

export default Tooltip