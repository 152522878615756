import Dialog, { DialogContent, DialogTitle } from "@component/Dialog"
import Typography from "@component/Typography"
import { useAppDispatch, useAppSelector } from "@hook/state"
import { useNavigate, useParams } from "react-router"
import { showFixedExpenseForm } from "../services/FixedCostsSlice"
import FixedExpenseForm from "./FixedExpenseForm"
import Routes from "@service/routes"

const CreateFixedExpense = () => {
    const { fixedCostId } = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { FixedCostsState } = useAppSelector(({ FixedCostsState }) => ({ FixedCostsState }))
    const { isOpenFixedExpenseForm } = FixedCostsState
    const handleClose = () => {
        dispatch(showFixedExpenseForm(false))
        if( Boolean(fixedCostId) ) navigate(Routes.indexFixedCosts(""))
    }
    return (
        <>
            <Dialog maxWidth="xs" fullWidth open={isOpenFixedExpenseForm || Boolean(fixedCostId)} onClose={handleClose} >
                <DialogTitle>
                    <Typography textTransform={'uppercase'} fontWeight={'bold'} fontSize={'1.2rem'} > Gasto fijo </Typography>
                </DialogTitle>
                <DialogContent>
                    <FixedExpenseForm />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CreateFixedExpense