import React from 'react'
import BoxTraslade from "@component/BoxTraslade"
import Button from "@component/Button"
import Dialog, { DialogContent } from "@component/Dialog"
import Form from "@component/Form"
import Grid from "@component/Grid"
import NumberFormat from "@component/NumberFormat"
import TextField from "@component/TextField"
import { useAppSignalSelector } from "@hook/state"
import { Boxes } from "@interface/App"
import { useCashTransfer } from '../hooks/useBusiness'
import Swal from 'sweetalert2'
import { currencyFormat } from '@hook/useNumberFormat'
import { useParams } from 'react-router-dom'

interface Props {
    from: Boxes
    omitValue?: Boxes
    maxAmount?: number
}

const CashTransfer = ({ maxAmount, omitValue, from }:Props) => {
    const { bankId } = useParams()
    const { BusinessState } = useAppSignalSelector()
    const { transferCash, showTransferCashWindow } = BusinessState.value
    const { amount, note, transfer, whoReceives } = transferCash
    const handleChange = (params:{ attr:string, value:any }) => {
        const { attr, value } = params
        const transferCash = structuredClone(BusinessState.value.transferCash)
        transferCash[attr] = value
        BusinessState.value = {...BusinessState.value, transferCash}
    }
    React.useEffect(() => {
        handleChange({ attr: 'from', value: from })
        handleChange({ attr: 'outputBank', value: bankId })
    },[from, bankId])
    const { recordCashTransfer, isLoadingRecordCashTransferMutation } = useCashTransfer()
    const handleSubmit = () => {
        if( maxAmount !== undefined ){
            if( amount > maxAmount ){
                Swal.fire({
                    icon: 'warning',
                    text: `No es posible trasladar $ ${currencyFormat(amount)}, solo tiene disponible $ ${currencyFormat(maxAmount)} en la caja.`,
                })
                return
            }
        }
        recordCashTransfer()
    }
    return(
        <Dialog maxWidth="xs" fullWidth open={showTransferCashWindow} onClose={() => BusinessState.value = {...BusinessState.value, showTransferCashWindow: false} } >
            <DialogContent>
                <Form onSubmit={handleSubmit} >
                    <Grid container spacing={1} >
                        <Grid xs={12} >
                            <BoxTraslade useBanks value={transfer} omitValue={omitValue} onChange={(_, params:any) => handleChange({ attr: 'transfer', value: (params?.value??"") })}  onBankChange={(params) => handleChange({ attr: 'bank', value: params?._id??null })} />
                        </Grid>
                        <Grid xs={6} >
                            <TextField value={whoReceives} margin="none" label="Quien recibe" onChange={({ target }) => handleChange({ attr:'whoReceives', value: (target?.value??"") })} />
                        </Grid>
                        <Grid xs={6} >
                            <NumberFormat maxVal={maxAmount} value={amount} prefix="$ " label="Monto" onChange={({ target }) => handleChange({ attr:'amount', value: Number(target?.value??0) }) } />
                        </Grid>
                        <Grid xs={12} >
                            <TextField value={note} margin="none" multiline rows={3} onChange={({ target }) => handleChange({ attr: 'note', value: (target?.value??"") })} label="Nota" />
                        </Grid>
                        { maxAmount !== undefined ?
                            <>
                                {!(amount > maxAmount) &&
                                    <Grid xs={12} >
                                        <Button type="submit" disableElevation >
                                            Trasladar dinero
                                        </Button>
                                    </Grid>
                                }
                            </>
                            :
                            <Grid xs={12} >
                                <Button loading={isLoadingRecordCashTransferMutation} type="submit" disableElevation >
                                    Trasladar dinero
                                </Button>
                            </Grid>
                        }
                        
                    </Grid>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default CashTransfer