import React from 'react'
import dropFile from '../styles/images/dropfile.png'
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import Box from './Box';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

const DeleteItemDropZone = styled(Box)(({theme}) => ({
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(1),
    position: 'relative',
    display: "inline-flex",
    justifyContent: 'center',
    height: 90,
    width: 90,
    '& .removeItem' : {
        transition: theme.transitions.create(['all'], {
            duration: 100,
        }),
        borderRadius : theme.shape.borderRadius,
        width : "100%",
        height : "calc(100% - 8px)",
        justifyContent : "center",
        alignItems : "center",
        display : "none",
    },
    '&:hover .removeItem' : {
        backgroundColor : "#eb0d0d4f",
        cursor : "pointer",
        display : "flex",
    }
}))

interface ImageGalleryProps {
    onClick?: (image:any) => void
    createURL?: boolean
    image: any
}

export const ImageGallery = ({ image, onClick, createURL=true }:ImageGalleryProps) => {
    const handleProps = () => {
        if(onClick !== undefined){
            onClick(image)
        }
    }
    return(
        <DeleteItemDropZone>
            <Box position="absolute" className="removeItem" onClick={handleProps} >
                <DeleteIcon style={{ color : "#FFF"}} />
            </Box>
            <Box sx={{ width: 1, display: "flex", border: 1, borderRadius: 1, borderColor: (theme) => theme.palette.grey[300], overflow: 'hidden' }} >
                <img width={'100%'} style={{ objectFit: "cover" }} src={ createURL ? URL.createObjectURL(image) : image} alt="" />
            </Box>
        </DeleteItemDropZone>
    )
}

const DropzoneElements = styled(Box)(({theme}) => ({
    display:"flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down('xs')] : {
        flexDirection : "column",
    }
}))

const DropzoneHover = styled(Box)(({theme}) => ({
    position: "absolute",
    width: "100%",
    height: "100%",
    transition: theme.transitions.create(['all'], {
        duration: 100,
    }),
    cursor : "pointer",
    '&:hover' : {
        backgroundColor : "#FFFFFFA8"
    }
}))

type DropzoneChangeProps = { name: string, value: Array<File> }

interface DropzoneProps{
    onChange?: (response:DropzoneChangeProps, limit: number) => void
    limitFiles?: number
    name?: string
    variant?: 'dropzone' | 'single'
}

const Dropzone = ({ onChange, limitFiles=5, name="dragAndDropZone", variant="dropzone" }:DropzoneProps) => {

    const theme = useTheme()
    const [images, setImages] = React.useState<Array<File>>([])
    const formats = ["jpg", "jpeg", "png"]
    const inputFile = React.useRef<HTMLInputElement | null>(null)

    const pushToCollection = (collectionFiles:Array<File>) => {
        let cloneCollectionImages = [...images]
        if( cloneCollectionImages.length < limitFiles ){
            for(let x of [...collectionFiles]){
                const cleanArrFiles = cloneCollectionImages.filter( f => f.name === x.name)
                if( !Boolean(cleanArrFiles) || cleanArrFiles.length <= 0){
                    const formatFile = x.name.split(".")[1]
                    const formatValidation = formats.includes(formatFile)
                    if( formatValidation ){
                        cloneCollectionImages.push(x)
                    }
                }
            }
            setImages(cloneCollectionImages)
        }
        if( onChange !== null && onChange !== undefined ) onChange({name: name, value: cloneCollectionImages}, limitFiles)
    }
    const handlerDropzone = (evt:React.DragEvent<HTMLDivElement>) => {
        evt.preventDefault()
        evt.stopPropagation()
        const data:any = evt.dataTransfer.files
        pushToCollection(data)
    }

    const handlerDropOverZone = (evt:React.DragEvent<HTMLDivElement>) => {
        evt.preventDefault()
        evt.stopPropagation()
    }
    const handlerChangeInputFile = (evt:React.ChangeEvent<HTMLInputElement>) => { 
        const data:any = evt.target.files
        pushToCollection(data)
    }
    
    const handlerClick = (evt:MouseEvent) => {
        const event = evt.target as HTMLInputElement
        if(event.id === "dropzone" || event.id === "dropzoneHoverMask"){
            inputFile.current!.click()
        }
    }
    React.useEffect(() => {
        window.addEventListener('click',handlerClick)
        return () => {
            window.removeEventListener('click',handlerClick)
        }
    },[])
    const removeItem = (index:number) => {
        const cloneImagesCollection = [...images]
        cloneImagesCollection.splice(index,1);
        setImages(cloneImagesCollection)
        if( cloneImagesCollection.length <= 0 ){
            if( inputFile.current !== null ) inputFile.current.value = ""
        }
    }

    return(
        <>
            <input ref={inputFile} type="file" style={{ display : variant === "dropzone" ? "none" : "block" }} multiple onChange={handlerChangeInputFile} />
            {variant === "dropzone" &&
                <Box id="dropzone" display="flex" justifyContent="center" flexWrap="wrap" onDrop={handlerDropzone} onDragOver={handlerDropOverZone} border={1} borderColor="#C4C4C4" borderRadius={2} minHeight={130} overflow="hidden" >
                    {images.length >= 1 ?
                        (
                            images.map((el,index) => {
                                return(
                                    <React.Fragment key={index} >
                                        <ImageGallery image={el} onClick={() => removeItem(index)} />
                                    </React.Fragment>
                                )
                            })
                        ) :
                        <Box width="100%" position="relative" >
                            <DropzoneHover id="dropzoneHoverMask" onDrop={handlerDropzone} onDragOver={handlerDropOverZone} />
                            <DropzoneElements>
                                <Box mr={2}  >
                                    <Box width={120} >
                                        <img width={'100%'} src={dropFile} alt="" />
                                    </Box>
                                </Box>
                                <Box style={{ textAlign : "center" }} >
                                    <Box fontWeight="bold" fontSize={18} color="grey.800" >
                                        Soltar o Seleccionar archivos
                                    </Box>
                                    <Box fontSize={14} color="grey.800" >
                                        Arrastre las imágenes o haga click para cargarlas.
                                    </Box>
                                    <Box fontSize={12} color={theme.palette.warning.main} >
                                        Este campo solo permite imágenes.
                                    </Box>
                                </Box>
                            </DropzoneElements>
                        </Box>
                    }
                </Box>
            }
        </>
    )
}

export default Dropzone