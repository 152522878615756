import { ChangeEvent, useEffect, useState } from "react";

import Button from "@component/Button";
import Form from "@component/Form";
import Grid from "@component/Grid";
import TextField from "@component/TextField";

import CustomerBranchInput from "./CustomerBranchInput";
import CustomerBusinessTypeInput from "./CustomerBusinessTypeInput";
import CustomerZoneInput from "./CustomerZoneInput";
import type { CustomerDataForm, CustomerDataProps } from "@interface/Customer";
import CustomerCityInput from "./CustomerCityInput";
import { useCustomer } from "../hooks/useCustomer";

import { useTranslation } from "react-i18next";
import CustomerNeighborhoodsInput from "./CustomerNeighborhoodsInput";

interface Props {
  onCreateFastCustomer?: (customer: CustomerDataProps) => void;
  customerData?: CustomerDataForm
}

export const initialValueOfCustomerForm = {
  name: '',
  businessName: '',
  identificationNumber: '',
  phoneNumber: '',
  businessType: '',
  address: '',
  city: '',
  zone: '',
  branch: '',
  neighborhood: '',
};

const CustomerForm = ({ onCreateFastCustomer, customerData }: Props) => {
  const { t } = useTranslation()

  const [form, setForm] = useState<CustomerDataForm>({...initialValueOfCustomerForm})
  
  const handleInputChange = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = evt.target;
    let upperValue = value.toLocaleUpperCase();
    setForm((preventFormState) => ({
      ...preventFormState,
      [name]: upperValue,
    }));
  };

  useEffect(() => {
    if (customerData !== undefined) {
      setForm({...customerData})
    }
  }, [customerData])
  

  const {registerMutation} = useCustomer()
  const clearFields = () => setForm(initialValueOfCustomerForm)
  const handleSubmit = () => registerMutation(clearFields, onCreateFastCustomer, form)

    
  

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid xs={6}>
          <TextField
            autoFocus
            name="name"
            margin="none"
            label={t('name')}
            value={form?.name}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            name="businessName"
            margin="none"
            label={t('businessName')}
            value={form?.businessName}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            name="identificationNumber"
            margin="none"
            label={t('identificationNumber')}
            value={form?.identificationNumber}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            name="phoneNumber"
            margin="none"
            label={t('phoneNumber')}
            value={form?.phoneNumber}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid xs={6}>
          <CustomerBusinessTypeInput
            customerBusinessTypeId={form?.businessType!}
            onChange={(_, result: any) => setForm((preventFormState) => ({
              ...preventFormState,
              businessType: result?._id ?? ''
            }))}
          />
        </Grid>
        <Grid xs={6}>
          <CustomerBranchInput
            customerBranchId={form?.branch!}
            onChange={(_, result: any) => setForm((preventFormState) => ({
              ...preventFormState,
              branch: result?._id ?? ''
            }))}
          />
        </Grid>
        <Grid xs={6}>
        <CustomerCityInput
            CityId={form?.city!}
            onChange={(_, result: any) => setForm((preventFormState) => ({
              ...preventFormState,
              city: result?._id ?? ''
            }))}
          />
        </Grid>
        <Grid xs={6}>
          <CustomerZoneInput
            customerZoneId={form?.zone!}
            onChange={(_, result: any) => setForm((preventFormState) => ({
              ...preventFormState,
              zone: result?._id ?? ''
            }))}
          />
        </Grid>
        <Grid xs={12}>
          <CustomerNeighborhoodsInput
            neighborhoodsId={form?.neighborhood!}
            onChange={(_, result: any) => setForm((preventFormState) => ({
              ...preventFormState,
              neighborhood: result?._id ?? ''
            }))}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            name="address"
            multiline
            rows={3}
            margin="none"
            label={t('address')}
            value={form?.address}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid xs={12}>
          <Button type="submit">
            {t('saveChanges')}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default CustomerForm;
