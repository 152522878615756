import Autocomplete from "@component/Autocomplete"
import React from "react"
import { useGetBoxesFromTheBusiness } from "../hooks/useBusiness"
import { useTranslation } from "react-i18next"
import Grid from "@component/Grid"
import { currencyFormat } from "@hook/useNumberFormat"
import NumberFormat from "@component/NumberFormat"
import { useSnackbar } from "notistack"
import Swal from "sweetalert2"

type AutocompleteProps = Pick<React.ComponentProps<typeof Autocomplete>, "size">
export type onChangeEventBoxResult = { box: string, payment: number, bank: string | null }

interface Props extends AutocompleteProps {
    maxPayment?: number
    onChange?: (params: onChangeEventBoxResult[]) => void
    maxPayments?: number
    defaultValue?: number
    skip?: boolean
}
const BoxPayments = ({ maxPayment, onChange, maxPayments, defaultValue, skip, size }: Props) => {
    const [payments, setPayments] = React.useState<Partial<onChangeEventBoxResult[]>>([{ bank: null, box: "", payment: 0 }])
    const { boxes, isLoading } = useGetBoxesFromTheBusiness({skip})
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const totalPayments = payments.reduce((acc, params) => (params?.payment ?? 0 + acc), 0)
    const handleChange = ({ attr, value, index }: { attr: 'box' | 'payment', value: any, index: number }) => {
        let val = structuredClone(payments) as Required<onChangeEventBoxResult[]>
        if (attr === "box") {
            if (Boolean(value)) {
                if (index === val.length - 1) {
                    if (maxPayments === undefined) {
                        val.push({ bank: null, box: "", payment: 0 })
                    } else {
                        if (val.length < maxPayments) {
                            val.push({ bank: null, box: "", payment: 0 })
                        }
                    }
                }
                if (maxPayment !== undefined) {
                    if (totalPayments < maxPayment) {
                        val[index].box = value?.name ?? ""
                        val[index].bank = value?.bankId ?? null
                    }
                } else {
                    val[index].box = value?.name ?? ""
                    val[index].bank = value?.bankId ?? null
                }
            } else {
                val.splice(index, 1)
            }

            if( defaultValue !== undefined ){
                if( value.amount < defaultValue ){
                    Swal.fire({
                        icon: "warning",
                        text: "La caja no cuenta con saldo suficiente para realizar este proceso",
                        showConfirmButton: false,
                        timer: 2000
                    })
                    return
                }
                val[index].payment = defaultValue
            }
        } else {
            val[index].payment = value
        }
        setPayments(val)
        if (onChange !== undefined) onChange(val.filter(n => (n.payment >= 1) && (Boolean(n.box))))
    }

    React.useEffect(() => {
        if (maxPayment !== undefined) {
            if (totalPayments >= maxPayment) {
                enqueueSnackbar(`Has alcanzado el limite de pagos - ($ ${currencyFormat(maxPayment)})`, { variant: "warning" })
            }
        }
    }, [totalPayments])

    return (
        <>
        <Grid container spacing={1} >
            {payments.map((params, index) => {
                let selection = boxes.find((n:any) => n.name === params?.box) || null
                const boxesAvailable = boxes.filter((n:any) => !payments.some(x => x?.box === n?.name))
                return (
                    <React.Fragment key={index} >
                        <Grid xs={6} >
                            <Autocomplete
                                size={size}
                                getOptionLabel={(paramsLabel: any) => `${t(paramsLabel?.name)} ${Boolean(paramsLabel?.bank) ? ` - ${paramsLabel?.bank}` : ``} `}
                                value={selection}
                                label="Caja"
                                loading={isLoading}
                                options={boxesAvailable}
                                renderOption={(props, params: any, index) => {
                                    return (
                                        <li {...props} key={`${index}-${params._id}`} >
                                            <Grid container >
                                                <Grid xs={12} >
                                                    {t(params.name)} {`${(params.name === "bank") ? ` - ${params.bank}` : ""}`}
                                                </Grid>
                                                <Grid xs={12} >
                                                    {`$ ${currencyFormat(Number(params?.amount ?? 0))}`}
                                                </Grid>
                                            </Grid>
                                        </li>
                                    )
                                }}
                                onChange={(_, params: any) => handleChange({ attr: 'box', index, value: params })}
                            />
                        </Grid>
                        <Grid xs={6} >
                            <NumberFormat
                                size={size}
                                disabled={defaultValue !== undefined}
                                value={params?.payment ?? 0}
                                onChange={({ target }) => handleChange({ attr: 'payment', index, value: Number(target?.value ?? 0) })}
                                maxVal={selection?.amount}
                                label="Monto"
                                prefix="$ "
                            />
                        </Grid>
                    </React.Fragment>
                )
            })}
        </Grid>
        </>
    )
}

export default React.memo(BoxPayments)