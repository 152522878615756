import { useTranslation } from "react-i18next"
import Autocomplete from "@component/Autocomplete"

interface Props {
    value: boolean
    onChange?: () => void
}

const TypeProductInput = ({ value, onChange }:Props) => {
    const options = [
        {_id:0, name: "Simple/Padre - Descuenta x1"},
        {_id:1, name: "Compuesto"},
    ]
    const { t } = useTranslation()
    return(
        <Autocomplete
            value={ options[value ? 1 : 0] }
            label={t('kindOfProduct')!}
            options={options}
            onChange={onChange}
        />
    )
}

export default TypeProductInput