import React from 'react';
import { TimePicker as TimePickerMUI } from '@mui/x-date-pickers';
import TextField from './TextField';

type Props = Pick<React.ComponentProps<typeof TimePickerMUI>, "label" | "value" | "onChange" | "disabled">

const TimePicker = ({...rest}:Props) => {
    return(
        <TimePickerMUI {...rest} renderInput={(params) => <TextField {...params} />} />
    )
}

export default TimePicker