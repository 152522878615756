import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface stateProps {
    showCashierRegistrationForm: boolean
}

const initialState:stateProps = {
    showCashierRegistrationForm: false
}

export const CashierSlice = createSlice({
    name: 'cashier',
    initialState,
    reducers: {
        updateCashierRecordFormValue: (state, action:PayloadAction<boolean>) => {
            state.showCashierRegistrationForm = action.payload
        }
    }
})

export const {
    updateCashierRecordFormValue,
} = CashierSlice.actions
export default CashierSlice.reducer