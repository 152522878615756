import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  useRegisterRolMutation,
  useUpdateRoleMutation,
} from '../../../api/querys/Permission';
import type { RoleFormProps, RoleProps } from '../../../interfaces/Role';
import { castRoleUpdated } from '../../../services/socket';

export const usePermissions = () => {
  const [registerRolMutation, { isLoading: isLoadingCreateRole }] = useRegisterRolMutation();
  const [updateRoleMutation, { isLoading: isLoadingUpdateRole }] =
    useUpdateRoleMutation();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const registerRol = async (form: RoleFormProps) => {
    if (form.name.trim() === '') {
      enqueueSnackbar(t('roleNameIsRequiredField'), { variant: 'warning' });
      return;
    }
    const resp = await registerRolMutation(form).unwrap();

    if (resp.status === 1) {
      enqueueSnackbar(t('newRoleRegistered'), { variant: 'success' });
    } else {
      enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'), {
        variant: 'error',
      });
    }
  };

  const updateRol = async (form: RoleProps) => {
    const resp = await updateRoleMutation(form).unwrap();

    if (resp.status === 1) {
      castRoleUpdated(true)
      enqueueSnackbar(t('roleUpdated'), { variant: 'success' });
    } else {
      enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'), {
        variant: 'error',
      });
    }
  };

  return { registerRol, updateRol, isLoadingUpdateRole, isLoadingCreateRole };
};
