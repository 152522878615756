import React from "react"
import Grid from "@component/Grid"
import { useAppDispatch, useAppSelector } from "@hook/state"
import { showDrawer } from "@service/AppSlice"
import ProductPreSelectionWindow from "./ProductPreSelectionWindow"
import SearchProductsForShopping from "./SearchProductsForShopping"
import SelectedProductPrices from "./SelectedProductPrices"
import TableSelectedProducts from "./TableSelectedProducts"
import PaymentSelectionWindow from "./PaymentSelectionWindow"
import PurchaseInvoiceDates from "./PurchaseInvoiceDates"
import Stack from "@component/Stack"
import Button from "@component/Button"
import { useSnackbar } from "notistack"
import { showPaymentSelectionWindow } from "../services/PurchasesSuppliersSlice"
import Box from "@component/Box"
import SupplierPreviousPurchasesList from "./SupplierPreviousPurchasesList"
import { usePurchasing } from "../hooks/usePurchasing"

const Purchases = () => {
    const dispatch = useAppDispatch()
    const { PurchasesSuppliersState } = useAppSelector(({ PurchasesSuppliersState }) => ({ PurchasesSuppliersState }))
    const { productOfTheSelectedList, productsSelectedList, haulier, whoReceives, providerSelected, purchaseId, invoiceNumber, billingDate, dateReceived, dueDate } = PurchasesSuppliersState
    const { recordPurchaseFromSupplier } = usePurchasing()
    const openPrices = Boolean(productOfTheSelectedList)
    const { enqueueSnackbar } = useSnackbar()
    React.useEffect(() => {
        dispatch(showDrawer(false))
    },[])
    const handleKeyShortcut = (event:KeyboardEvent) => {
        const { key } = event
        const keyLock = ['F3']
        if( keyLock.some(n => key === n) ){
            event.preventDefault()
            event.stopPropagation()
        }
        if( key === "F3" ){
            const cellDataGridElem = document.querySelectorAll('[data-colindex="0"], [aria-colindex="1"]')
            if( cellDataGridElem.length >= 2 && cellDataGridElem[1] !== null ){
                const focusElem = cellDataGridElem[1] as HTMLInputElement
                focusElem.focus()
            }
        }
        if( key === "Enter" ){
            const elementFocused = document.activeElement
            if( elementFocused?.role !== undefined && elementFocused.role === "cell" ){
                const clickElem = elementFocused as HTMLDivElement
                clickElem.click()
            }
        }
    }
    const saveChanges = () => {
        recordPurchaseFromSupplier({ invoiceNumber, haulier, purchaseId, productList: productsSelectedList, provider: providerSelected!, typePayments: "cash", billingDate, dateReceived, whoReceives, dueDate})
    }
    React.useEffect(() => {
        document.addEventListener("keydown", handleKeyShortcut)
        return () => document.removeEventListener("keydown", handleKeyShortcut)
    }, [handleKeyShortcut])
    const handleOpenPaymentWindow = () => {
        if( productsSelectedList.length <= 0 ){
            enqueueSnackbar("Debe añadir por lo menos un producto a la lista de compra", {variant: 'warning'})
            return
        }
        if( !Boolean(whoReceives) ){
            enqueueSnackbar("El campo recibe es un campo obligatorio", {variant: 'warning'})
            return
        }
        dispatch(showPaymentSelectionWindow(true))
    }
    let reteTax = (providerSelected?.tax?.name === "retefuente")

    return(
        <Grid container spacing={1} >
            {reteTax &&
                <Grid xs={3} >
                    <SupplierPreviousPurchasesList />
                </Grid>
            }
            <Grid xs={openPrices ? (reteTax ? 6 : 8) : (reteTax ? 9 : 12)} >
                <PurchaseInvoiceDates />
                <SearchProductsForShopping />
                <TableSelectedProducts />
                {
                    Boolean(purchaseId) ?
                    <>
                        <Stack alignItems={'end'} >
                            <Box maxWidth={200} >
                                <Button disableElevation onClick={saveChanges} >
                                    Guardar cambios
                                </Button>
                            </Box>
                        </Stack>
                    </>
                    :
                    <Stack alignItems={'end'} >
                        <Box maxWidth={200} >
                            <Button disableElevation onClick={handleOpenPaymentWindow} >
                                Completar compra
                            </Button>
                        </Box>
                    </Stack>
                }
                <ProductPreSelectionWindow />
                <PaymentSelectionWindow />
            </Grid>
            {openPrices &&
                <Grid xs={(reteTax ? 3 : 4)} >
                    <SelectedProductPrices />
                </Grid> 
            }
        </Grid>
    )
}

export default Purchases