import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface stateProps {
    openExpenseForm: boolean
}

const initialState:stateProps = {
    openExpenseForm: false
}

export const ExpenseSlice = createSlice({
    name: 'expense',
    initialState,
    reducers: {
        showFormOfExpense: (state, action:PayloadAction<boolean>) => {
            state.openExpenseForm = action.payload
        }
    }
})

export const {
    showFormOfExpense
} = ExpenseSlice.actions
export default ExpenseSlice.reducer