import React from 'react';
import { useTranslation } from "react-i18next"
import { typeOfPayment } from '..';
import Button from "@component/Button"
import Dialog, { DialogContent } from "@component/Dialog"
import Form from "@component/Form"
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import BanksInput from '../../Banks/components/BanksInput'
import { useInvoice } from '../hooks/useInvoice';
import { showBankForm, showPaymentMethodForm } from '../service/InvoiceSlice';

interface Props {
    invoiceId: string | undefined
    observation: string
}

const BankForm = ({ invoiceId, observation }:Props) => {
    const dispatch = useAppDispatch()
    const { InvoiceState } = useAppSelector(({ InvoiceState }) => ({ InvoiceState }))
    const { openBankForm } = InvoiceState
    const [bank, setBank] = React.useState<string>("")
    const { t } = useTranslation()
    const { changePaymentMethod } = useInvoice()
    const handleClose = () => {
        dispatch(showPaymentMethodForm(true))
        dispatch(showBankForm(false))
    }

    const handleSubmit = () => {
        changePaymentMethod(typeOfPayment.consignment, { invoiceId: invoiceId ?? '', bank, observation })
        dispatch(showBankForm(false))
    }

    return(
        <Dialog open={openBankForm} onClose={handleClose} maxWidth={'xs'} fullWidth >
            <DialogContent>
                <Form onSubmit={handleSubmit} >
                    <BanksInput bankId={bank} onChange={(n) => setBank(n)} />
                    <Button type="submit" > {t('save')} </Button>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default BankForm