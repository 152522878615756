import { FixedCostsForm, FixedCostsPropsResponse, GetFixedCostsResponse, GetFixedCostResponse, PayFixedExpenseSend, PayFixedExpenseResponse, DeleteFixedCostAsyncResponse, StopFixedExpenseAsyncResponse, GetFixedCostParamsProps } from './../../interfaces/FixedCosts.d';
import { api, mutationConfig } from "../splitApi"

const Tag = api.injectEndpoints({
    endpoints: (build) => ({
        registerNewFixedExpense: build.mutation<FixedCostsPropsResponse, FixedCostsForm>({
            query(body) {
                return {...mutationConfig, url: '/register/new/fixed/expense', body}
            },
            invalidatesTags: ['FixedCost', 'Tag'],
        }),
        updateFixedExpense: build.mutation<FixedCostsPropsResponse, FixedCostsForm>({
            query(body) {
                return {...mutationConfig, url: '/update/fixed/cost', body}
            },
            invalidatesTags: ['FixedCost', 'Tag'],
        }),
        payFixedExpense: build.mutation<PayFixedExpenseResponse, PayFixedExpenseSend>({
            query(body) {
                return {...mutationConfig, url: '/pay/fixed/expense', body}
            },
            invalidatesTags: ['MultiBox', 'MinorBox']
        }),
        getFixedExpenses: build.query<GetFixedCostsResponse, {groupBy?: "tags" | null}>({
            query: ({groupBy}) => ({ url: `/get/fixed/expenses`, params: {groupBy} }),
            providesTags: ['FixedCost']
        }),
        getFixedExpense: build.query<GetFixedCostResponse, GetFixedCostParamsProps>({
            query: ({fixedCostId}) => ({ url: `/get/fixed/expense`, params: {fixedCostId} }),
            providesTags: ['FixedCost']
        }),
        deleteFixedCost: build.mutation<DeleteFixedCostAsyncResponse, string[]>({
            query(body) {
                return {...mutationConfig, url: '/delete/fixed/cost', body}
            },
            invalidatesTags: ['FixedCost']
        }),
        stopFixedExpense: build.mutation<StopFixedExpenseAsyncResponse, string[]>({
            query(body) {
                return {...mutationConfig, url: '/stop/fixed/expense', body}
            },
            invalidatesTags: ['FixedCost']
        }),
    }),
    overrideExisting: false,
})

export const {
    useRegisterNewFixedExpenseMutation,
    useGetFixedExpensesQuery,
    useGetFixedExpenseQuery,
    useUpdateFixedExpenseMutation,
    usePayFixedExpenseMutation,
    useDeleteFixedCostMutation,
    useStopFixedExpenseMutation
} = Tag