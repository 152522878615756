import Box from "@component/Box"
import Filters from "@component/Filters"

const FilterExpenses = () => {

    return(
        <Box mb={1} display={'flex'} >
            <Filters filterByBoxes />
        </Box>
    )
}

export default FilterExpenses