import type { BoxesFromTheBusinessAsyncResponse, BusinessFinancialStatementAsyncResponse, BusinessFormProps, BusinessPayrollSetupResponse, ListOfBoxesWithBalancesResponse, TaxesBusinessResponse, UpdateBatchInventoryBusinessSettingsResponse } from './../../interfaces/Business.d';
import type { BusinessResponseProps } from "../../interfaces/Business";
import { api, mutationConfig } from "../splitApi"
import { CashTransferAsyncResponse, CashTransferFormProps } from '@interface/CashTransfer';

const Business = api.injectEndpoints({
    endpoints: (build) => ({
        getBusinessPayrollSetup: build.query<BusinessPayrollSetupResponse, void>({
            query: () => '/get/business/payroll/setup',
            providesTags: ['BusinessPayrollSetup']
        }),
        getBusiness: build.query<BusinessResponseProps, void>({
            query: () => '/get/business',
            providesTags: ['Business']
        }),
        getTaxes: build.query<TaxesBusinessResponse, void>({
            query: () => '/get/taxes',
        }),
        getListOfBoxesWithBalances: build.query<ListOfBoxesWithBalancesResponse, void>({
            query: () => ({ url: '/get/list/of/boxes/with/balances' }),
            providesTags: ['BoxBalances']
        }),
        updateBusiness: build.mutation<BusinessResponseProps, BusinessFormProps>({
            query(body) {
                return {...mutationConfig, url: '/update/business', body}
            },
            invalidatesTags: ['Business'],
        }),
        registerBusiness: build.mutation<BusinessResponseProps, BusinessFormProps>({
            query(body) {
                return {...mutationConfig, url: '/register/business', body}
            },
            invalidatesTags: ['Authentication'],
        }),
        updateBatchInventoryBusinessSettings: build.mutation<UpdateBatchInventoryBusinessSettingsResponse, void>({
            query(body) {
                return {...mutationConfig, url: '/update/batch/inventory/business/settings', body}
            },
            invalidatesTags: ['Authentication'],
        }),
        recordCashTransfer: build.mutation<CashTransferAsyncResponse, CashTransferFormProps>({
            query(body) {
                return {...mutationConfig, url: '/record/cash/transfer', body}
            },
            invalidatesTags: ['MinorBox', 'MultiBox', 'CashierBox'],
        }),
        getBoxesFromTheBusiness: build.query<BoxesFromTheBusinessAsyncResponse, void>({
            query: () => ({ url: '/get/boxes/from/the/business' }),
            providesTags: ['MinorBox', 'MultiBox', 'CashierBox']
        }),
        getBusinessFinancialStatement: build.query<BusinessFinancialStatementAsyncResponse, { startDate: string }>({
            query: ({ startDate }) => ({ url: '/get/business/financial/statement', params: { startDate } }),
            providesTags: ['BusinessFinancialStatement']
        }),
        getSummaryDashboardFinancialStatus: build.query<any, void>({
            query: () => ({ url: '/get/summary/dashboard/financial/status' }),
            providesTags: ['BusinessFinancialStatement']
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetBusinessQuery,
    useUpdateBusinessMutation,
    useRegisterBusinessMutation,
    useGetBusinessPayrollSetupQuery,
    useGetTaxesQuery,
    useUpdateBatchInventoryBusinessSettingsMutation,
    useGetListOfBoxesWithBalancesQuery,
    useRecordCashTransferMutation,
    useGetBoxesFromTheBusinessQuery,
    useGetBusinessFinancialStatementQuery,
    useGetSummaryDashboardFinancialStatusQuery
} = Business