import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface initialStateProps {
    dateRange: {
        initDate: string
        endDate: string
    }
    customerId: string
}

const initialState:initialStateProps = {
    dateRange:{
        initDate: "",
        endDate: "",
    },
    customerId: ""
}

export const CreditsSlice = createSlice({
    name: 'credits',
    initialState,
    reducers: {
        updateDateRange: (state, action:PayloadAction<initialStateProps['dateRange']>) => {
            state.dateRange = action.payload
        },
        updateSelectedCustomerId: (state, action:PayloadAction<string>) => {
            state.customerId = action.payload
        }
    }
})

export const {
    updateDateRange,
    updateSelectedCustomerId,
} = CreditsSlice.actions
export default CreditsSlice.reducer