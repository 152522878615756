import { Typography as TypographyMUI, TypographyProps } from '@mui/material'

const Typography = ({ children, ...rest }:TypographyProps) => {
    return(
        <TypographyMUI {...rest} >
            {children}
        </TypographyMUI>
    )
}

export default Typography