import dayjs from "dayjs"
import { useParams } from "react-router-dom"
import { useGetAvailableBatchesOfProductQuery } from "../../../api/querys/Product"
import Accordion, { AccordionDetails, AccordionSummary } from "@component/Accordion"
import Box from "@component/Box"
import Typography from "@component/Typography"
import { AvailableBatchesOfProductResponse } from "@interface/Product"
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import { useTranslation } from "react-i18next"
import Grid from "@component/Grid"
import { currencyFormat } from "../../../hooks/useNumberFormat"
import { Prices } from "./ProductForm"
import OutlinedCardQuantityData from "@component/OutlinedCardQuantityData"

const BatchData = () => {
    const { t } = useTranslation()
    const { productId } = useParams()
    const { data } = useGetAvailableBatchesOfProductQuery({ productId: productId! })
    const availableBatchesOfProduct = data?.payload??{} as AvailableBatchesOfProductResponse['payload']
    return(
        <>
            {availableBatchesOfProduct?.batches?.map((product, index) => {
                const day = dayjs(product?.createdAt??'').get('day')
                return(
                    <Accordion key={index} >
                        <AccordionSummary >
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={1} >
                                <Box>
                                    <Typography fontWeight={'bold'} lineHeight={1} >
                                        {availableBatchesOfProduct?.name??''}
                                    </Typography>
                                    <Typography fontWeight={'500'} fontSize={'.8rem'} >
                                        Cantidades adquiridas: {product?.quantity??0} {product?.packaging?.name??''}
                                    </Typography>
                                </Box>
                                <Box display={'flex'} alignItems={'center'} >
                                    <Typography>
                                        {t(`day_${day}`)} {' '}
                                        {dayjs(product?.createdAt??'').format("DD-MM-YYYY HH:mm:ss A")}
                                    </Typography>
                                    <CalendarMonthRoundedIcon sx={{ ml: 2 }} />
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1} >
                                <Grid xs={12} sx={{ display: 'flex', gap: 2 }} >
                                    <OutlinedCardQuantityData primaryText="Costo:" secondaryText={`$ ${currencyFormat(product.cost)}`} />
                                    <OutlinedCardQuantityData primaryText="unidades en stock:" secondaryText={`${product.stock}`} />
                                    <OutlinedCardQuantityData primaryText={`${product?.packaging?.name??''} en stock:`} secondaryText={`${product.quantity}`} />
                                </Grid>
                                {product?.prices?.map((price, index) => {
                                    return(
                                        <Prices key={index} cost={product.cost} margin={price.percentageProfit} profit={price.profit} sale={price.sellPrice} />
                                    )
                                })}
                            </Grid>
                            {product?.compounds?.map((compoundProduct, indexCompoundProduct) => {
                                return(
                                    <Box key={indexCompoundProduct} display={'flex'} flexDirection={'column'} >
                                        <Box my={1} >
                                            <Typography fontWeight={'bold'} lineHeight={1} >
                                                {compoundProduct?.name??''}
                                            </Typography>
                                        </Box>
                                        <Grid container spacing={1} >
                                            <Grid xs={12} sx={{ display: 'flex', gap: 2 }} >
                                                <OutlinedCardQuantityData primaryText="Costo:" secondaryText={`$ ${currencyFormat(compoundProduct.cost)}`} />
                                                <OutlinedCardQuantityData primaryText={`${compoundProduct?.packaging?.name??''} en stock:`} secondaryText={`${compoundProduct.stock}`} />
                                            </Grid>
                                            {compoundProduct?.prices?.map((price, index) => {
                                                return(
                                                    <Prices key={index} cost={compoundProduct.cost} margin={price.percentageProfit} profit={price.profit} sale={price.sellPrice} />
                                                )
                                            })}
                                        </Grid>
                                    </Box>
                                )
                            })}
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </>
    )
}

export default BatchData