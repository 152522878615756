import ReceiptLongRounded from "@mui/icons-material/ReceiptLongRounded"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"
import Dialog, { DialogContent, DialogTitle } from "@component/Dialog"
import List, { ListItemButton, ListItemIcon, ListItemText } from "@component/List"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { currencyFormat } from "../../../hooks/useNumberFormat"
import { handleGoToTheBilligProps } from "@interface/BillsOfTheDay"
import Routes from "../../../services/routes"
import { showBillsOfTheDay } from "../services/CustomerPurchaseTrackingSplice"

const BillsOfTheDay = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const { CustomerPurchaseTrackingState } = useAppSelector(({ CustomerPurchaseTrackingState }) => ({ CustomerPurchaseTrackingState }))
    
    const { showBillsOfTheDay:showBillsOfTheDayActive, billsOfTheDay=[] } = CustomerPurchaseTrackingState
    
    const handleGoToTheBillig = ({invoiceId}: handleGoToTheBilligProps ) => {
        navigate(Routes.indexInvoice(invoiceId))
     }

    return(
        <Dialog open={showBillsOfTheDayActive} onClose={() => dispatch(showBillsOfTheDay({ showBillsOfTheDay: false, billsOfTheDay: [] }))} fullWidth  >
            <DialogTitle> {billsOfTheDay[0]?.customer?.name??""} </DialogTitle>
            <DialogContent>
                <List>
                    {billsOfTheDay.map((invoice, index) => {
                        const invoiceId = invoice.invoiceId
                        return(
                            <ListItemButton key={index}  onClick={() => handleGoToTheBillig({invoiceId})}>
                                <ListItemIcon> <ReceiptLongRounded sx={{ fontSize: "2.5rem" }} /> </ListItemIcon>
                                <ListItemText primaryTypographyProps={{ fontWeight: "bold" }} secondaryTypographyProps={{ fontWeight: "500" }} primary={`$ ${currencyFormat(invoice.total) }`} secondary={`#FAC ${invoice.consecutive} - ${dayjs(invoice.createdAt).format("DD/MM/YYYY HH:mm")}`} />
                            </ListItemButton>
                        )
                    })}
                </List>
            </DialogContent>
        </Dialog>
    )
}

export default BillsOfTheDay

