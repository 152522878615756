import { useParams } from "react-router-dom"
import { useGetCustomerQuery } from "../../api/querys/Customer"
import Box from "@component/Box"
import CircularProgress from "@component/CircularProgess"
import Fade from "@component/Fade"
import Grid from "@component/Grid"
import LockComponent from "@component/LockComponent"
import Tab from "@component/Tab"
import { useBusinessPermissions } from "../../hooks/usePermissions"
import DataCustomer from "./components/DataCustomer"
import EditCustomer from "./components/EditCustomer"
import ListOfAdvances from "./components/ListOfAdvances"

const CustomerData = () => {
    const { Customers } = useBusinessPermissions()
    const { customerId } = useParams()
    const { isSuccess, isLoading } = useGetCustomerQuery(customerId!)
    const showComponent = !isLoading && isSuccess
    if( showComponent ){
        return(
            <Fade in={true} >
                {Customers.lock_module ?
                    <Box pt={1} >
                        <Grid container >
                            <Grid xs={8} >
                                <Tab
                                    TabItem={[
                                        Customers.view_information ? 'Información' : '',
                                        Customers.edit_information ? 'Editar información' : '',
                                        'Anticipos'
                                    ]}
                                    TabRender={[
                                        Customers.view_information ? <DataCustomer /> : <></>,
                                        Customers.edit_information ? <EditCustomer /> : <></>,
                                        <ListOfAdvances />
                                    ]}
                                />
                            </Grid>
                            <Grid xs={4} >

                            </Grid>
                        </Grid>
                    </Box>
                    :
                    <LockComponent />
                }
            </Fade>
        )
    }else{
        return(
            <Fade in={true} >
                <CircularProgress />
            </Fade>
        )
    }
}

export default CustomerData