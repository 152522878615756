import Fade from "@component/Fade"
import ListOfProducts from "./components/ListOfProducts"

const Products = () => {
    return(
        <Fade in={true} >
            <ListOfProducts />
        </Fade>
    )
}

export default Products