import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGetCustomerBusinessTypeQuery } from "../../../api/querys/Customer"
import Autocomplete from "@component/Autocomplete"
import type { CustomerBusinessTypesData } from "@interface/Customer"

interface Props {
    customerBusinessTypeId: string
    onChange?: (event:any, value: any | null, name: string | undefined) => void
}

const CustomerBusinessTypeInput = ({ customerBusinessTypeId, ...rest }:Props) => {
    const { t } = useTranslation()
    const { data, isSuccess, isLoading } = useGetCustomerBusinessTypeQuery()
    const customerBusinessTypes = data?.payload?.customerBusinessTypes?.results??[]
    const loadingData = isSuccess && !isLoading
    const MemoizeValue = React.useMemo(() => {
        let result:Partial<CustomerBusinessTypesData> | null = null
        if(loadingData){
            if( customerBusinessTypeId !== undefined && Boolean(customerBusinessTypeId) ){
                const existCustomerBusinessType = customerBusinessTypes.find(n => n._id === customerBusinessTypeId)!
                if( Boolean(existCustomerBusinessType) ){
                    result = existCustomerBusinessType
                }
            }
        }
        return result
    },[customerBusinessTypeId, loadingData, customerBusinessTypes])
    return(
        <Autocomplete
            name='businessType'
            label={t("businessesType")!}
            options={customerBusinessTypes}
            loading={!loadingData}
            value={MemoizeValue||null}
            {...rest}
        />
    )
}

CustomerBusinessTypeInput.prototype = {

}

export default CustomerBusinessTypeInput