import { createFilterOptions } from "@mui/material/Autocomplete"
import React from "react"
import { useTranslation } from "react-i18next"
import { useGetBusinessCustomersQuery } from "../../../api/querys/Customer"
import { useGetProductsForPointOfSaleQuery } from "../../../api/querys/Product"
import Autocomplete from "@component/Autocomplete"
import Box from "@component/Box"
import { useAppDispatch } from "../../../hooks/state"
import { selectCustomerForInvoice, showPOSProductWindow } from "../services/PointOfSaleSlice"
import IconButton from "@component/IconButton"
import SupervisedUserCircleRounded from '@mui/icons-material/SupervisedUserCircleRounded';

const SearchBarPOS = () => {
    const [inputText, setInputText] = React.useState<string>("")
    const dispatch = useAppDispatch()
    const [ useCustomers, setUseCustomers ] = React.useState<boolean>(false)
    const { t } = useTranslation()
    const { data:ProductsForPointOfSaleQuery, isLoading:isLoadingGetProductsForPointOfSale } = useGetProductsForPointOfSaleQuery()
    const { data:BusinessCustomersQuery, isLoading:isLoadingGetBusinessCustomers } = useGetBusinessCustomersQuery()
    const products = ProductsForPointOfSaleQuery?.payload?.productsForPointOfSale?.results??[]
    const customers = BusinessCustomersQuery?.payload?.customers?.results??[]
    const { label, results } = useCustomers ?
        { label: t('findCustomer'), results:  customers } :
        { label : t('searchProduct'), results: products }
    
    const handleKeyDown = (event:React.KeyboardEvent) => {
        const { keyCode, which } = event
        const code = keyCode??which
        if( code === 9 ){
            event.preventDefault()
            event.stopPropagation()
            setUseCustomers(n => !n)
        }
        if( code === 45 ){
            event.preventDefault()
            event.stopPropagation()
            if( useCustomers === true ){
                const createCustomerPOSElement = document.getElementById("createCustomerPOS")
                if( createCustomerPOSElement !== null ){
                    createCustomerPOSElement.click()
                }
            }else{
                const createProductPOSElement = document.getElementById("createProductPOS")
                if( createProductPOSElement !== null ){
                    createProductPOSElement.click()
                }
            }
        }
    }
    const handleSelectedCustomer = (customer:any) => {
        dispatch(selectCustomerForInvoice(customer))
        setUseCustomers(false)
        setInputText("")
    }
    React.useEffect(() => {
        const element = document.getElementById('searchBarPOS')
        if( element !== null ){
            setTimeout(() => {
                element.focus()
            }, 500)
        }
    },[])
    const filterOptions = createFilterOptions({
        stringify: (option:any) => `${option.name} ${option.skuCode}`,
    });
    const spreadFilter = useCustomers === false ? filterOptions : undefined
    return (
        <Box display={'flex'} >
            <Autocomplete
                onBlur={() => setInputText("")}
                onInputChange={(_, text) => setInputText(text)}
                value={null}
                inputValue={inputText}
                filterOptions={spreadFilter}
                id="searchBarPOS"
                freeSolo
                onChange={(_, params:any) => useCustomers ? handleSelectedCustomer(params) : dispatch(showPOSProductWindow({ isOpen: true, productSelected: {...params} })) }
                onKeyDown={handleKeyDown}
                label={label}
                options={results}
                loading={isLoadingGetProductsForPointOfSale||isLoadingGetBusinessCustomers}
            />
            <Box display={'flex'} alignItems={'center'} >
                <IconButton onClick={() => setUseCustomers(n => !n)} >
                    <SupervisedUserCircleRounded />
                </IconButton>
            </Box>
        </Box>
    )
}

export default SearchBarPOS