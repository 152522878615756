import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

interface stateProps {
    initDate: string;
    endDate: string;
    userId: string;
    customerId: string;
    invoiceNumbers: string;
    businessTypeId: string;
    zoneId: string;
    branchId: string;
    neighborhoodId: string;
    cityId: string;
    groupById: string;
    documentNumber: string;
}

const initialState: stateProps = {
    initDate: dayjs().format('MM-DD-YYYY'),
    endDate: dayjs().format('MM-DD-YYYY'),
    userId: '',
    customerId: '',
    invoiceNumbers: '',
    businessTypeId: '',
    zoneId: '',
    branchId: '',
    neighborhoodId: '',
    cityId: '',
    groupById: '',
    documentNumber: '',
};

export const ReportOfTheDaySlice = createSlice({
    name: 'reportOfTheDay',
    initialState,
    reducers: {
        updateStartDate: (state, action: PayloadAction<string>) => {
            state.initDate = action.payload;
        },
        updateEndDate: (state, action: PayloadAction<string>) => {
            state.endDate = action.payload;
        },
        updateCustomers: (state, action: PayloadAction<string>) => {
            state.customerId = action.payload;
        },
        updateInvoiceNumbers: (state, action: PayloadAction<string>) => {
            state.invoiceNumbers = action.payload;
        },
        updateCustomerDocumentNumber: (state, action: PayloadAction<string>) => {
            state.documentNumber = action.payload;
        },
        updateSellers: (state, action: PayloadAction<string>) => {
            state.userId = action.payload;
        },
        updateBusinessesType: (state, action: PayloadAction<string>) => {
            state.businessTypeId = action.payload;
        },
        updateZones: (state, action: PayloadAction<string>) => {
            state.zoneId = action.payload;
        },
        updateBranches: (state, action: PayloadAction<string>) => {
            state.branchId = action.payload;
        },
        updateNeighborhood: (state, action: PayloadAction<string>) => {
            state.neighborhoodId = action.payload;
        },
        updateCity: (state, action: PayloadAction<string>) => {
            state.cityId = action.payload;
        },
        updateGroupBy: (state, action: PayloadAction<string>) => {
            state.groupById = action.payload;
        },
        resetFilters: (state) => {
            state.customerId = '';
            state.invoiceNumbers = '';
            state.userId = '';
            state.businessTypeId = '';
            state.zoneId = '';
            state.branchId = '';
            state.neighborhoodId = '';
            state.customerId = '';
            state.groupById = '';
            state.cityId = '';
        },
    },
});

export const {
    updateStartDate,
    updateEndDate,
    updateCustomers,
    updateInvoiceNumbers,
    updateSellers,
    updateBusinessesType,
    updateZones,
    updateBranches,
    updateNeighborhood,
    updateCity,
    updateGroupBy,
    resetFilters,
    updateCustomerDocumentNumber
} = ReportOfTheDaySlice.actions;
export default ReportOfTheDaySlice.reducer;