import { api } from "../splitApi"
import type { UserSessionDataResponse } from './../../interfaces/User.d';

const User = api.injectEndpoints({
    endpoints: (build) => ({
        getSessionData: build.query<UserSessionDataResponse, void>({
            query: () => '/get/session/data',
            providesTags: ['Business', 'Authentication'],
        }),
    }),
    overrideExisting: false,
})

export const { useGetSessionDataQuery } = User