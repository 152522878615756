import React from 'react';
import { GridColDef } from "@mui/x-data-grid"
import { useGetBusinessProductsQuery } from "../../../api/querys/Product"
import Avatar from "@component/Avatar"
import DataGrid from "@component/DataGrid"
import Typography from "@component/Typography"
import SellRounded from '@mui/icons-material/SellRounded';
import { domain } from '../../../api/splitApi';
import { getToken } from '../../../services/localStorage';
import { useTranslation } from 'react-i18next';
import EditRounded from '@mui/icons-material/EditRounded';
import IconButton from '../../../components/IconButton';
import Tooltip from '../../../components/Tooltip';
import { useNavigate } from 'react-router-dom';
import Routes from '../../../services/routes';
import DataGridCustomHeaderToDelete from '../../../components/DataGridCustomHeaderToDelete';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import { showProductDeletionAlert, updateArrayWithSelectedProducts } from '../services/ProductSlice';
import AlertDeleteProducts from './AlertDeleteProducts';
import { useBusinessPermissions } from '../../../hooks/usePermissions';
import Box from '@component/Box';
import { FileDownloadRounded } from '@mui/icons-material';
import { useURLParams } from '@hook/useURLParams';
import SelectProductListFields from './SelectCustomerListFields';

export const ProductAvatar = ({ imagePath="" }:{ imagePath?: string }) => {
    const [image, setImage] = React.useState<string>("")

    const getProductImage = React.useCallback( async (publicPath:string) => {
        try {
            if( Boolean(publicPath) ){
                const publicPathSplit = publicPath.split(/(?:\\|\/)+/)
                const publicPathSplitWithoutPublic = publicPathSplit.slice(1, publicPathSplit.length)
                const path = publicPathSplitWithoutPublic.join("/")
                const token = getToken()
                let blob:any = await (await fetch( `${domain}/${path}`, { headers: new Headers({ 'Authorization': `Bearer ${token}`, }) } )).blob()
        
                if( blob.type !== "text/html" ){
                    blob.name = publicPath.split(/(?:\\|\/)+/)[publicPath.split(/(?:\\|\/)+/).length-1];
                    blob.lastModified = new Date();
                    const myFile = new File([blob], blob.name, {
                        type: blob.type,
                    });
                    setImage(URL.createObjectURL(myFile))
                }
            }
        } catch (error) {
            console.log(error)
        }
    },[])
    React.useEffect(() => {
        getProductImage(imagePath!)
    },[getProductImage, imagePath])
    return(
        <Avatar src={image} >
            <SellRounded />
        </Avatar>
    )
}

const ProductsList = () => {
    const { addQuery } = useURLParams()
    const { Product } = useBusinessPermissions()
    const { t } = useTranslation()
    const { data } = useGetBusinessProductsQuery()
    const products = data?.payload?.products?.results??[]
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { ProductState } = useAppSelector( ({ ProductState }) => ({ ProductState }))
    const { productSelection } = ProductState

    const columns:GridColDef[] = [
        {field: "name", headerName: t('product')!, flex: 1, renderCell: ({row}) => <> <ProductAvatar imagePath={row.image} /> <Typography ml={2} > {row.name} </Typography> </>},
        {field: "skuCode", headerName: t('SKUCode')!, flex: 1},
        { field: " ", maxWidth: 50, minWidth: 50, flex: 1, hideable: !Product.edit_information, align: "center", renderCell: ({ row }) => <>
            <Tooltip title={t('editProduct')!} >
                <IconButton onClick={() => navigate(Routes.indexProduct(row._id))} >
                    <EditRounded />
                </IconButton>
            </Tooltip>
        </> },
    ]

    const CustomHeader = () => {
        return(
            <>
                <DataGridCustomHeaderToDelete listItems={productSelection} texts={[ t('selectedProducts') , t('selectedProduct')]} title={""} onClick={ () => dispatch(showProductDeletionAlert(true)) } />
                <Box pt={1} px={2} >
                    <Tooltip title="Descargar lista de productos" >
                        <IconButton squareButton onClick={() => addQuery({ download: "products-list" })} >
                            <FileDownloadRounded />
                        </IconButton>
                    </Tooltip>
                </Box>
            </>
        )
    }

    return(
        <>
            <DataGrid
                onRowSelectionModelChange={(selection) => dispatch(updateArrayWithSelectedProducts(selection)) }
                slots={{
                    toolbar: CustomHeader
                }}
                disableRowSelectionOnClick
                checkboxSelection={Product.delete_information}
                rowHeight={50}
                rows={products}
                columns={columns}
            />
            <SelectProductListFields />
            <AlertDeleteProducts />
        </>
    )
}

export default ProductsList