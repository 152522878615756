import Fade from "@component/Fade"
import Grid from "@component/Grid"
import ExpenseList from "./components/ExpenseList"
import FilterExpenses from "./components/FilterExpenses"
import { useBusinessPermissions } from "@hook/usePermissions"
import LockComponent from "@component/LockComponent"
import Title from "@component/Title"
import { useURLParams } from "@hook/useURLParams"
import { useGetExpenses } from "./hooks/useExpense"
import dayjs from "dayjs"
import List, { ListItem, ListItemText } from "@component/List"
import { currencyFormat } from "@hook/useNumberFormat"
import { useTranslation } from "react-i18next"

const Expenses = () => {
    const { t } = useTranslation()
    const { Expenses } = useBusinessPermissions()
    const { getParameterGroup } = useURLParams()
    const { profit, startDate=dayjs().format("MM-DD-YYYY"), endDate=dayjs().format("MM-DD-YYYY") } = getParameterGroup(['profit', 'startDate', 'endDate'])
    const { expensesByMonth } = useGetExpenses({ startDate, endDate })
    return(
        <Fade in={true} >
            {Expenses.lock_module ?
                <Grid container spacing={1} >
                    <Grid xs={12} >
                        <Title text="Gastos" />
                    </Grid>
                    <Grid xs={ profit === "month" ? 9 : 12} >
                        <FilterExpenses />
                        <ExpenseList />
                    </Grid>
                    {profit === "month" &&
                        <Grid xs={3} >
                            <List>
                                {expensesByMonth.map((n, index) => {
                                    return(
                                        <ListItem key={index} >
                                            <ListItemText primary={`$ ${currencyFormat((n?.total??0))}`} secondary={` ${t(n.concept)} del mes de ${ t(`month_${dayjs(n.month, "MM-YYYY").month()}`) } del ${dayjs(n.month, "MM-YYYY").format("YYYY")}`} />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </Grid>
                    }
                </Grid>
                :
                <LockComponent />
            }
        </Fade>
    )
}

export default Expenses