import { GridColDef } from "@mui/x-data-grid"
import { t } from "i18next"
import { useGetClosingOfDayQuery } from "../../../api/querys/PointOfSale"
import Box from "@component/Box"
import DataGrid, { GridToolbarContainer } from "@component/DataGrid"
import Typography from "@component/Typography"
import { useAppSelector } from "../../../hooks/state"
import { currencyFormat } from "../../../hooks/useNumberFormat"
import { CloseOfTheDayResponse } from "@interface/PointOfSale"

const DeliveriesTable = () => {
    const { ClosingOfTheDayState } = useAppSelector(({ ClosingOfTheDayState }) => ({ClosingOfTheDayState}))
    const { dateClosingOfDay } = ClosingOfTheDayState
    const { data } = useGetClosingOfDayQuery({ closingDate: dateClosingOfDay })
    const { closingOfTheDiscriminatedDay } = data?.payload??{} as CloseOfTheDayResponse['payload']
    const DeliveriesToDay = closingOfTheDiscriminatedDay?.find(n => n._id === "delivery")
    const DeliveriesToDayList = DeliveriesToDay?.results??[]
    
    const columns:Array<GridColDef> = [
        {field: "consecutive", headerName: t('#FAC')!},
        {field: "customer", headerName: t('customer')!, flex: 1},
        {field: "deliveryName", headerName: t('Domiciliario')!, flex: 1},
        {field: "total", headerName: t('total')!, renderCell: ({row}) => <>$ { currencyFormat(row.total) }</>},
        {field: "payments", headerName: t('payment')!, renderCell: ({row}) => <>$ { currencyFormat(row.payments) }</>},
        {field: "balance", headerName: t('balance')!, renderCell: ({row}) => <>$ { currencyFormat(row.balance) }</>},
        {field: "paymentsCash", headerName: t('Pago Efec.')!, renderCell: ({row}) => <>$ { currencyFormat(row.paymentsCash) }</>},
        {field: "paymentsConsignment", headerName: t('Pago Cons.')!, renderCell: ({row}) => <>$ { currencyFormat(row.paymentsConsignment) }</>},
    ]

    const HeaderDataGrid = () => {
        return(
            <GridToolbarContainer>
                <Box p={2} >
                    <Typography textTransform={'uppercase'} fontWeight="bold" >{t('delivery')}</Typography>
                    <Typography fontSize={'.9rem'} textTransform={'uppercase'} fontWeight="bold" >{t('total')}: $ {currencyFormat(DeliveriesToDayList.reduce((acc, params) => (acc+params?.total??0),0))}</Typography>
                </Box>
            </GridToolbarContainer>
        )
    }

    return(
        <Box mb={2} >
            <DataGrid
                slots={{
                    toolbar: HeaderDataGrid
                }}
                rows={DeliveriesToDayList}
                columns={columns}
            />
        </Box>
    )
}

export default DeliveriesTable