import Dialog, { DialogContent } from '../../../components/Dialog';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import {
  closeNeighborhoodsEditionForm,
  displayNeighborhoodsForm,
} from '../services/NeighborhoodsSlice';
import NeighborhoodsForm from './NeighborhoodsForm';

const CreateNeighborhoods = () => {
  const dispatch = useAppDispatch();
  const { NeighborhoodState } = useAppSelector(({ NeighborhoodState }) => ({
    NeighborhoodState,
  }));
  const { isOpenForm, NeighborhoodsToEdit } = NeighborhoodState;

  const handleClose = () => {
    if (NeighborhoodsToEdit._id === '') {
      dispatch(displayNeighborhoodsForm(false));
    } else {
      dispatch(closeNeighborhoodsEditionForm());
    }
  };

  return (
    <Dialog
      open={isOpenForm}
      onClose={handleClose}
      fullWidth
    >
      <DialogContent>
        <NeighborhoodsForm />
      </DialogContent>
    </Dialog>
  );
};

export default CreateNeighborhoods;
