export const ApiTags: string[] = [
    'Authentication',
    'Business',
    'Customer',
    'Employee',
    'Product',
    'ProductCategories',
    'ProductPackaging',
    'MinorExpense',
    'MinorShopping',
    'Permission',
    'Invoice',
    'SKUCode',
    'Provider',
    'Transfer',
    'MasterBox',
    'MinorBox',
    'BankBox',
    'LargeBox',
    'PaySheet',
    'BusinessPayrollSetup',
    'Zones',
    'CustomerBranch',
    'CustomerNeighborhood',
    'Tag',
    "CustomerCity",
    'BusinessType',
    'InvoiceWithOutPayment',
    "Banks",
    "PurchasingSuppliers",
    "FixedCost",
    "BoxBalances",
    "Cashier",
    "Expense",
    "MultiBox",
    "BusinessFinancialStatement",
    "AccountsPayable",
]