import DataGrid, { GridColDef } from "@component/DataGrid"
import { useAppSelector } from "@hook/state"
import { currencyFormat } from "@hook/useNumberFormat"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useGetCashierSummaryDetails } from "../hooks/useMovementsCashier"
import ListsToolbar from "./ListsToolbar"

const ListOfPaymentsReceivedCashier = () => {
    const { cashierId } = useParams()
    const { CashierMovementsState } = useAppSelector(({ CashierMovementsState }) => ({ CashierMovementsState }))
    const { filterMovements } = CashierMovementsState
    const { startDate, endDate } = filterMovements
    const { summary, isLoading } = useGetCashierSummaryDetails({ cashierId: cashierId!, startDate, endDate })
    const { paymentsCash=[] } = summary
    const { t } = useTranslation()

    const columns:GridColDef[] = [
        { maxWidth: 110, minWidth: 110, field: "consecutive", headerName: "#", valueGetter: ({ row }) => `#R${row.consecutive}` },
        { flex: 1, field: "customer", headerName: "Cliente", valueGetter: ({ row }) => `${t(row?.customer??"")}` },
        {field: "seller", headerName: "Vendedor"},
        {field: "total", headerName: "Total", valueGetter: ({ row }) => `$ ${currencyFormat(row?.total??0)}` },
        {field: "typePayment", headerName: "Tipo de pago", valueGetter: ({ row }) => `${t(row?.typePayment??"")}`},
        { maxWidth: 160, minWidth: 160, field: "createdAt", headerName: "Registro", valueGetter: ({ row }) => `${dayjs(row?.createdAt).format("DD-MM-YYYY HH:mm A")}`},
    ]

    return(
        <>
            <DataGrid
                slots={{
                    toolbar: () => <ListsToolbar listTitle="Pagos - efectivo recibido" movements={paymentsCash} />
                }}
                height={'auto'}
                sx={{ maxHeight: 450 }}
                autoHeight
                loading={isLoading}
                columns={columns}
                rows={paymentsCash}
            />
        </>
    )
}

export default ListOfPaymentsReceivedCashier