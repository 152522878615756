import Box from "@component/Box"
import Typography from "@component/Typography"
import { currencyFormat } from "@hook/useNumberFormat"
import { useGetPurchasesFromSupplierBusiness } from "@purchase_report_hook/usePurchaseReport"
import dayjs from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"

const ShoppingScheduleTemplate = React.forwardRef<HTMLDivElement, any>((_, ref) => {
    const { groupByProviders } = useGetPurchasesFromSupplierBusiness()
    const { t } = useTranslation()
    const divideArr = React.useMemo(() => {
        let partsArray = 1
        let divideArr = groupByProviders.reduce((acc:any, params) => [...acc, ...params.results], [])
        divideArr = divideArr.length*47
        while (divideArr > 1400) {
            divideArr /= 2
            partsArray += 1
        }

        var tamañoSubarray = Math.ceil(groupByProviders.length / partsArray);
        var arrayDividido = [];
        
        for (var i = 0; i < groupByProviders.length; i += tamañoSubarray) {
            var subarray = groupByProviders.slice(i, i + tamañoSubarray);
            arrayDividido.push(subarray);
        }
        return arrayDividido
    }, [groupByProviders])

    return(
        <Box ref={ref} p={2} sx={{ displayPrint: "block", display: "none" }} className="print-container" >
            {divideArr.map((groups, groupsIndex) => {
                return(
                    <React.Fragment key={groupsIndex} >
                        <Box fontSize={'.875rem'} textTransform={'uppercase'} > { `Generado el ${t(`day_${dayjs().day()}`)} ${dayjs().format("DD")} de ${t(`month_${dayjs().month()}`)} del ${dayjs().format("YYYY")} a las ${dayjs().format("HH:mm A")}` } </Box>
                        <Box className="page-break" border={2} borderColor={'#000000'} maxWidth={2480} maxHeight={3508} width={"100%"} >
                            {groups.map((params, index) => {
                                let r = [...params.results]
                                r = r.sort((a, b) => Number(a?.invoiceNumber??0)-Number(b.invoiceNumber??0))

                                return(
                                    <React.Fragment key={index} >
                                        {/* <Box fontSize={'.975rem'} fontWeight={'bold'} p={"1px"} borderTop={ (index === 0) ? 0 : 2} borderBottom={2} width={1}  display={'flex'} justifyContent={'space-between'} >
                                            <Box>
                                                {params.name}
                                            </Box>
                                        </Box> */}
                                        <Box display={'flex'} flexDirection={'column'} >
                                            { r.map((item, resultIndex) => {
                                                return(
                                                    <React.Fragment key={resultIndex} >
                                                        {(resultIndex === 0 && Boolean(item?.provider?.typeOfBankAccount)) &&
                                                            <Box borderBottom={2} borderTop={ (resultIndex === 0) ? 0 : 2 } display={'flex'} justifyContent={'space-between'} px={2} textTransform={'uppercase'} >
                                                                <Typography fontWeight={'bold'} fontSize=".975rem" >
                                                                    {params.name}
                                                                </Typography>
                                                                <Typography fontWeight={'500'} >
                                                                    { t(item?.provider?.bank??"") }
                                                                </Typography>
                                                                <Typography fontWeight={'500'} >
                                                                    CTA: { t(item?.provider?.typeOfBankAccount??"") }
                                                                </Typography>
                                                                <Typography fontWeight={'500'} >
                                                                    #Cta: {item?.provider?.bankAccountNumber??"" }
                                                                </Typography>
                                                                <Typography fontWeight={'500'} >
                                                                    Convenio: {item?.provider?.bankAgreementNumber??"" }
                                                                </Typography>
                                                            </Box>
                                                        }
                                                        <Box fontSize={'.775rem'} borderTop={ (resultIndex === 0) ? 0 : 2} px={1} display={'flex'} fontWeight={'500'} flexDirection={'row'} justifyContent={'space-between'} >
                                                            <Box display={'flex'} flexDirection={'row'} >
                                                                <Box mr={1} >
                                                                    FAC#{item?.invoiceNumber}
                                                                </Box>
                                                                <Box mr={1} >
                                                                    {dayjs(item?.dueDate??"").format("DD-MM-YYYY")}
                                                                </Box>
                                                                <Box mr={1} >
                                                                    { item?.payments?.map((n) => `AB $ ${currencyFormat((n?.payment??0))}`).join(", ") }
                                                                </Box>
                                                            </Box>
                                                            <Box display={'flex'} >
                                                                <Box mr={1} fontWeight={'500'} >
                                                                    Valor: $ {currencyFormat((item?.subTotal??0)+(item?.tax??0))}
                                                                </Box>
                                                                {((item?.retention??0) >= 1) &&
                                                                    <Box mr={1} fontWeight={'500'} >
                                                                        Retención: $ {currencyFormat((item?.retention??0))}
                                                                    </Box>
                                                                }
                                                                <Box mr={1} fontWeight={'500'} >
                                                                    Total: $ {currencyFormat(item?.balance)}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </React.Fragment>
                                                )
                                            }) }
                                        </Box>
                                        <Box fontSize={'.975rem'} fontWeight={'bold'} p={"1px"} borderTop={2} borderBottom={2} width={1}  display={'flex'} justifyContent={'space-between'} >
                                            <Box>
                                                TOTAL:
                                            </Box>
                                            <Box>
                                                $ { currencyFormat((params?.totalBalance??0)) }
                                            </Box>
                                        </Box>
                                    </React.Fragment>
                                )
                            })}
                        {(divideArr.length-1 === groupsIndex) &&
                            <Box textTransform={'uppercase'} width={1} display={'flex'} justifyContent={'end'} fontWeight={'bold'} borderTop={2} >
                                Total: $ { currencyFormat(divideArr.reduce( (acc:any, params) => [...acc, ...params.reduce((accumulator:any, items) => [...accumulator, ...items?.results??[]], [])],[] ).reduce((acc, params) => (acc+(params?.balance??0)),0)) }
                            </Box>
                        }
                        </Box>
                    </React.Fragment>
                )
            })}
        </Box>
    )
})

export default ShoppingScheduleTemplate