import dayjs from "dayjs"
import { useGetSalesReportQuery } from "../../../api/querys/PointOfSale"
import CardInformative from "@component/CardInformative"
import InformativeList from "@component/InformativeList"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { calcProfitPercentage, currencyFormat } from "../../../hooks/useNumberFormat"
import AttachMoneyRounded from '@mui/icons-material/AttachMoneyRounded';
import { useTranslation } from "react-i18next"
import { SaleReportResponse } from "@interface/PointOfSale"
import Box from "@component/Box"
import { updateEndDate, updateStartDate } from "../services/ReportOfTheDaySlice"

const DetailsSale = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const { ReportOfTheDayState } = useAppSelector(({ ReportOfTheDayState }) => ({ ReportOfTheDayState }))
    const { initDate, endDate, userId, customerId, invoiceNumbers, businessTypeId, zoneId, branchId, neighborhoodId, cityId, documentNumber, } = ReportOfTheDayState
    const { data } = useGetSalesReportQuery({ initDate, endDate, userId, customerId, invoiceNumbers, businessTypeId, zoneId, branchId, neighborhoodId, cityId, documentNumber })
    const sales = data?.payload?.sales??[] as SaleReportResponse['payload']['sales']
    const totalSale = sales.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0 )
    const totalCurrency = sales.reduce((accumulator, currentValue) => accumulator + currentValue.totalProfitCurrency, 0 )
    const render = sales.map(n => ({
        primaryText: `${t(`day_${dayjs(n._id).get("day")}`)} ${dayjs(n._id).format("DD-MM-YYYY")}`,
        secondaryText:  `$ ${currencyFormat(n.totalProfitCurrency)} - ${calcProfitPercentage(n.totalProfitCurrency, n.total)}%`,
        value: n.total,
        onClick: (params:any) => {
            const date = dayjs(params.primaryText.split(" ")[1], "DD-MM-YYYY").format("MM-DD-YYYY")
            dispatch(updateStartDate(date))
            dispatch(updateEndDate(date))
        }
    }))
    return(
        <>  
            {render.length >= 2 &&
                <CardInformative moreText={`$ ${currencyFormat(totalSale)}`} informativeText={`$ ${currencyFormat(totalCurrency)} - ${calcProfitPercentage(totalCurrency, totalSale)}%`} title="" icon={<AttachMoneyRounded />} />
            }
            <Box maxHeight={400} height={"100%"} overflow={'auto'} >
                <InformativeList data={render} />
            </Box>
        </>
    )
}

export default DetailsSale