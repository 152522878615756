import Dialog, { DialogContent } from "@component/Dialog"
import { useAppDispatch, useAppSelector } from "@hook/state"
import { updateCashierRecordFormValue } from "../services/CashierSlice"
import FormCashier from "./FormCashier"

const AddNewCashier = () => {
    const dispatch = useAppDispatch()
    const { CashierState } = useAppSelector(({ CashierState }) => ({ CashierState }))
    const { showCashierRegistrationForm } = CashierState
    return(
        <Dialog maxWidth="xs" fullWidth open={showCashierRegistrationForm} onClose={() => dispatch(updateCashierRecordFormValue(false))} >
            <DialogContent>
                <FormCashier />
            </DialogContent>
        </Dialog>
    )
}

export default AddNewCashier