import AddRounded from "@mui/icons-material/AddRounded"
import IconButton from "@component/IconButton"
import Tooltip from "@component/Tooltip"
import CreateEmployee from "./CreateEmployee"
import { useAppDispatch } from '../../../hooks/state'
import { displayFormToCreateEmployee } from '../services/EmployeeSlice'

const ButtonAddEmployee = () => {
    const dispatch = useAppDispatch()
    return(
        <>
            <Tooltip title="Crear nuevo empleado" placement="top-start" >
                <IconButton squareButton onClick={() => dispatch(displayFormToCreateEmployee(true))} >
                    <AddRounded sx={{ fontSize: "2rem", fontWeight: 'bold' }} />
                </IconButton>
            </Tooltip>
            <CreateEmployee />
        </>
    )
}

export default ButtonAddEmployee