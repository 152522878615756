import { useTranslation } from 'react-i18next';
import EditRounded from '@mui/icons-material/EditRounded';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';

import DataGrid from '../../../components/DataGrid';
import IconButton from '../../../components/IconButton';
import DataGridCustomHeaderToDelete from '../../../components/DataGridCustomHeaderToDelete';
import AlertDeleteBusinessesType from './AlertDeleteBusinessesType';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import {
  loadBusinessesTypeToEdit,
  selectBusinessesTypeToDelete,
  showAlertDeleteBusinessesType,
} from '../services/BusinessesTypeSlice';
import { CustomerBusinessesTypeProps } from '../../../interfaces/Customer';
import { useGetCustomerBusinessTypeQuery } from '../../../api/querys/Customer';

const BusinessesTypeList: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { BusinessesTypeState } = useAppSelector(({ BusinessesTypeState }) => ({
    BusinessesTypeState,
  }));
 
  const { selectionToDelete } = BusinessesTypeState;

  const { data: BusinessesTypeProductData, isLoading  } = useGetCustomerBusinessTypeQuery()
  
  const BusinessesTypeArray =
    BusinessesTypeProductData?.payload?.customerBusinessTypes.results ?? []

  const handleDeleteBusinessesType = () => {
    dispatch(showAlertDeleteBusinessesType(true));
  };

  const handleEditBusinessesType = (row: CustomerBusinessesTypeProps) => {
    dispatch(loadBusinessesTypeToEdit(row));
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('nameOfTheBusinessType')!,
      flex: 1,
    },
    {
      field: ' ',
      align: 'center',
      maxWidth: 80,
      minWidth: 80,
      renderCell: ({ row }) => {
        return (
          <IconButton onClick={() => handleEditBusinessesType(row)}>
            <EditRounded />
          </IconButton>
        );
      },
    },
  ];

  const HeaderDataGrid = () => (
    <DataGridCustomHeaderToDelete
      listItems={selectionToDelete}
      texts={[t('selectedBusinessesType'), t('selectedBusinessType')]}
      title=''
      onClick={handleDeleteBusinessesType}
    />
  );

  const handleSelection = (items: GridRowSelectionModel) => {
    const arrayOfItems = items as Array<string>

    dispatch(selectBusinessesTypeToDelete(arrayOfItems));
  };

  return (
    <>
      <DataGrid
        onRowSelectionModelChange={handleSelection}
        checkboxSelection
        disableRowSelectionOnClick
        rowHeight={30}
        rows={BusinessesTypeArray}
        columns={columns}
        loading={isLoading}
        components={{
          Toolbar: HeaderDataGrid,
        }}
      />
      <AlertDeleteBusinessesType />
    </>
  );
};

export default BusinessesTypeList;
