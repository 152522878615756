import { cashierSummaryProps } from '@interface/Cashier';
import { signal } from '@preact/signals-react';
import dayjs from 'dayjs';
interface MinorCashProps {
    filterDateRange: {
        startDate: string
        endDate: string
    }
}

export const MinorCash = signal<MinorCashProps>({
    filterDateRange: {
        startDate: dayjs().format("MM-DD-YYYY"),
        endDate: dayjs().format("MM-DD-YYYY")
    },
})