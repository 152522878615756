import { NoteCreditFormProps, NoteCreditPropsAsyncResponse, UpdateConsecutiveElectronicInvoiceAsyncResponse, UpdateConsecutiveElectronicInvoiceFormProps } from "@interface/Invoice"
import { api, mutationConfig } from "../splitApi"

const Invoice = api.injectEndpoints({
    endpoints: (build) => ({
        // getCashier: build.query<CashierPropsAsyncResponse, { cashierId: string }>({
        //     query: ({cashierId}) => ({
        //         url: '/get/cashier',
        //         params: {cashierId}
        //     }),
        //     providesTags: ['Cashier']
        // }),
        registerCreditNote: build.mutation<NoteCreditPropsAsyncResponse, NoteCreditFormProps>({
            query(body) {
                return {...mutationConfig, url: '/register/credit/note', body}
            },
            invalidatesTags: ['Invoice'],
        }),
        updateConsecutiveElectronicInvoice: build.mutation<UpdateConsecutiveElectronicInvoiceAsyncResponse, UpdateConsecutiveElectronicInvoiceFormProps>({
            query(body) {
                return {...mutationConfig, url: '/update/consecutive/electronic/invoice', body}
            },
            invalidatesTags: ['Invoice'],
        }),
    }),
    overrideExisting: false,
})

export const {
    useRegisterCreditNoteMutation,
    useUpdateConsecutiveElectronicInvoiceMutation
} = Invoice