import { useMediaQuery } from '@mui/material'
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import Fade from "@component/Fade"
import Grid from "@component/Grid"
import LockComponent from '../../components/LockComponent'
import Tab from "@component/Tab"
import { useBusinessPermissions } from '../../hooks/usePermissions'
import EditProduct from "./components/EditProduct"
import InformationProduct from "./components/InformationProduct"
import ProductPurchaseHistory from './components/ProductPurchaseHistory'
import { useAppDispatch, useAppSelector } from '@hook/state'
import { updateProductDataTabPosition } from './services/ProductSlice'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Routes from '@service/routes'

const ProductData = () => {
    const dispatch = useAppDispatch()
    const { ProductState } = useAppSelector(({ ProductState }) => ({ ProductState }))
    const { productDataTabPosition } = ProductState
    const { Product } = useBusinessPermissions()
    const { t } = useTranslation()
    const theme = useTheme()
    const media = useMediaQuery(theme.breakpoints.down('sm'))
    const navigate = useNavigate()

    const handlerKeyboardShortcutsPOS = (keyboardEvent:KeyboardEvent) => {
        const { key } = keyboardEvent
        const lockKey:Array<KeyboardEvent['key']> = ["Escape"]
        if( lockKey.some(n => n === key) ){
            keyboardEvent.preventDefault()
            keyboardEvent.stopPropagation()
        }

        if( key === "Escape" ){
            navigate(Routes.indexProducts())
        }
    }

    React.useEffect(() => {
            window.addEventListener('keydown', handlerKeyboardShortcutsPOS, true)
        return () => {
            window.removeEventListener('keydown', handlerKeyboardShortcutsPOS, true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <Fade in={true} >
            { Product.lock_module ?
                <Grid container >
                    <Grid xs={12} sm={12} md={9} lg={9} >
                        <Tab
                            onChange={(_,position) => dispatch(updateProductDataTabPosition(position))}
                            tabPosition={productDataTabPosition}
                            variant={media ? "scrollable" : "standard"}
                            orientation={ media ? "horizontal" : "vertical" }
                            TabItem={[
                                Product.view_information ? t('information') : "",
                                Product.edit_information ? t('edit') : "",
                                Product.view_purchase_history ? t('Historial de compra') : "",
                            ]}
                            TabRender={[
                                Product.view_information ? <InformationProduct /> : <></>,
                                Product.edit_information ? <EditProduct /> : <></>,
                                Product.view_purchase_history ? <ProductPurchaseHistory /> : <></>,
                            ]}
                        />
                    </Grid>
                </Grid>
                :
                <LockComponent />
            }
        </Fade>
    )
}

export default ProductData