import Box from "@component/Box"
import Chip from "@component/Chip"
import DataGrid, { GridColDef } from "@component/DataGrid"
import IconButton from "@component/IconButton"
import List, { ListItem } from "@component/List"
import Title from "@component/Title"
import { useGetFixedExpenses } from "@fixed_cost_hook/useFixedCost"
import { useAppContextSelector } from "@hook/state"
import { currencyFormat } from "@hook/useNumberFormat"
import { useURLParams } from "@hook/useURLParams"
import { ListItemText } from "@mui/material"
import dayjs from "dayjs"
import { FixedSizeList } from "react-window"
import EditRounded from '@mui/icons-material/EditRounded';
import Tooltip from "@component/Tooltip"

const FixedExpensesList = () => {
    const { getParameterGroup, handleParameterGroups } = useURLParams()
    const { group=null } = getParameterGroup(['group'])
    const { list, tags, totalFixedExpenses, totalAccumulatorToPayment } = useGetFixedExpenses()
    const { FixedCostContext, setGlobalState } = useAppContextSelector()
    const { fixedCostSelection } = FixedCostContext

    const columns:GridColDef[] = [
        { flex: 1, field: "name", headerName: "Nombre"},
        { maxWidth: 90, minWidth: 90, field: "amount", headerName: "Monto", renderCell: ({ row }) => `$ ${currencyFormat((row?.amount??0))}`},
        { maxWidth: 90, minWidth: 90, field: "total", headerName: "Acumulado", renderCell: ({ row }) => `$ ${currencyFormat((row?.total??0))}`},
        { maxWidth: 100, minWidth: 100, field: "recurrent", headerName: "Genera", renderCell: ({ row }) => `${row?.recurrent ? "Automático" : "Manual"}`},
        { maxWidth: 100, minWidth: 100, field: "expirationDate", headerName: "Vence", valueGetter: ({ row }) => Boolean(row?.generateDaily) ? `Todos los días` : `${row.expirationDate} del mes`},
        ...(group === "tags" ? [] : [
            { maxWidth: 150, minWidth: 150, field: "tags", headerName: "Etiqueta", valueGetter: ({ row }:any) => `${Boolean(row?.tags) ? row?.tags[0]?.name??"" : ""}`, renderCell: ({ row }:any) => {
                return(
                    Boolean(row?.tags) ?
                    <>
                        <Chip sx={{ fontSize: ".875rem" }} label={Boolean(row?.tags) ?  `${ (row?.tags[0]?.name??"").charAt(0).toUpperCase()}${(row?.tags[0]?.name??"").slice(1).toLowerCase()}` : "" } size="small" variant="outlined" color="primary" />
                    </>
                    :
                    <> </>
                )
            } },
        ]),
        { maxWidth: 100, minWidth: 100, field: "active", headerName: "Estado", align: "center", renderCell: ({ row }:any) => {
            return(
                <>
                    <Chip sx={{ fontSize: ".875rem" }} label={Boolean(row?.active) ?  "activo" : "detenido" } size="small" variant="outlined" color={Boolean(row?.active) ? "success" : "error"} />
                </>
            )
        } },
        { maxWidth: 160, minWidth: 160, field: "createdAt", headerName: "Registro", renderCell: ({ row }) => `${dayjs(row?.createdAt??"").format("DD-MM-YYYY HH:mm A")}`},
        { maxWidth: 50, minWidth: 50, field: " ", headerName: " ", align: "center", renderCell: ({ row }) => {
            return(
                <>
                    <Tooltip title="Editar gasto fijo" >
                        <IconButton size="small" onClick={() => handleParameterGroups(["_id", "open_modal"], [row._id as string, "fixed_expense"])} >
                            <EditRounded />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }},
    ]

    const handleSelection = (index:number, selection:string[]) => {
        let _fixedCostSelection = [...fixedCostSelection]
        _fixedCostSelection[index] = selection
        setGlobalState(n => ({...n, 
            FixedCostContext: { ...n.FixedCostContext,
                fixedCostSelection: _fixedCostSelection
            }
        }))
    }
    return(
        <>
            {group === "tags" ?
                <>
                    <FixedSizeList height={(window.innerHeight-200)} itemCount={list.length} itemSize={550} width={"100%"} >
                        {({ index, style }) => {
                            let item = list[index]
                            return(
                                <Box style={style} >
                                    <Title text={(tags[index]??"")} variant="subtitle" hideDivider />
                                    <List sx={{ display: "inline-flex", gap: 2, maxWidth: 360, width: "100%" }} >
                                        <ListItem>
                                            <ListItemText
                                                primary={`$ ${currencyFormat((totalFixedExpenses[index]??0))}`}
                                                secondary="Total gastos fijos"
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                primary={`$ ${currencyFormat((totalAccumulatorToPayment[index]??0))}`}
                                                secondary="Total acumulado  a pagar"
                                            />
                                        </ListItem>
                                    </List>
                                    <DataGrid
                                        onRowSelectionModelChange={(selection) => handleSelection(index, selection as string[])}
                                        rowSelectionModel={fixedCostSelection[index]}
                                        checkboxSelection
                                        disableRowSelectionOnClick
                                        rowHeight={35}
                                        columns={columns}
                                        rows={item}
                                    />
                                </Box>
                            )
                        }}
                    </FixedSizeList>
                </>
                :
                <>
                    <List sx={{ display: "inline-flex", gap: 2, maxWidth: 360, width: "100%" }} >
                        <ListItem >
                            <ListItemText
                                primary={`$ ${currencyFormat((totalFixedExpenses[0]??0))}`}
                                secondary="Total gastos fijos"
                            />
                        </ListItem>
                        <ListItem >
                            <ListItemText
                                primary={`$ ${currencyFormat((totalAccumulatorToPayment[0]??0))}`}
                                secondary="Total gastos fijos a pagar"
                            />
                        </ListItem>
                    </List>
                    <DataGrid
                        onRowSelectionModelChange={(selection) => handleSelection(0, selection as string[])}
                        rowSelectionModel={fixedCostSelection[0]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        rowHeight={35}
                        columns={columns}
                        rows={list}
                    />
                </>
            }
        </>
    )
}

export default FixedExpensesList