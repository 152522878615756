import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGetBusinessProductsQuery } from '../../../api/querys/Product'
import Autocomplete from "@component/Autocomplete"

interface Props {
    simpleProductId: string
    onChange?: (value:string, params:any) => void
}

const SimpleProductInput = ({ simpleProductId, onChange }:Props) => {
    const { t } = useTranslation()
    const { data, isSuccess, isLoading } = useGetBusinessProductsQuery()
    const simpleProducts = data?.payload?.simpleProducts?.results??[]
    const loadingData = isSuccess && !isLoading
    const value = simpleProducts.find((n:typeof simpleProducts[0]) => n._id === simpleProductId)

    return(
        <Autocomplete
            label={t('simpleProducts')!}
            options={simpleProducts}
            loading={!loadingData}
            value={value||null}
            onChange={(_, b:any) => {
                if( onChange !== undefined ){
                    onChange(b?._id??"", b)
                }
            }}
        />
    )
}

export default SimpleProductInput