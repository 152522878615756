import { businessCustomersDataResponse } from './../../../interfaces/Customer.d';
import { useURLParams } from '@hook/useURLParams';
import { DateRangeFilter } from '@interface/App';
import { CreditsResponse } from '@interface/PointOfSale';
import { useGetBusinessCustomersQuery } from '@query/Customer';
import { useGetCreditReportQuery } from '@query/PointOfSale';
import dayjs from 'dayjs';

const useCredits = () => {

}

export const useGetCreditReport = (props:(DateRangeFilter & {customerId?: string})) => {
    const { getQuery } = useURLParams()
    const creditsQuery = getQuery("credits")
    let numberDocument = getQuery("numberDocument")
    const { data, isFetching } = useGetCreditReportQuery(props)
    const { data:dataCustomers } = useGetBusinessCustomersQuery()
    let customers = dataCustomers?.payload?.customers?.results??[] as businessCustomersDataResponse['payload']['customers']['results']
    let credits = data?.payload?.credits?.results??[] as CreditsResponse['payload']['credits']['results']

    if( Boolean(creditsQuery) ){
        if( creditsQuery === "payments" ){
            credits = credits.filter(n => Number(n?.balance??0) === 0)
        }
        if( creditsQuery === "pending" ){
            credits = credits.filter(n => Number(n?.balance??0) >= 1)
        }
        if( creditsQuery === "beaten" ){
            credits = credits.filter(n => dayjs(n.creditExpirationDate).startOf("day").diff(dayjs().startOf("day"), 'days') <= 0)
        }
        if( creditsQuery === "valid" ){
            credits = credits.filter(n => dayjs(n.creditExpirationDate).startOf("day").diff(dayjs().startOf("day"), 'days') >= 1)
        }
    }

    if( Boolean(numberDocument) ){
        let customerResult = customers.find(n => Number(numberDocument) === Number(n?.identificationNumber))
        if( customerResult !== null && customerResult !== undefined ){
            credits = credits.filter(n => new RegExp(customerResult!.name, "i").test(n.customer) )
        }
    }

    let paidCredits = data?.payload?.credits?.totalPayments??0
    let pendingCredits = data?.payload?.credits?.totalBalance??0
    let totalCredits = data?.payload?.credits?.totalCredits??0
    let quantityCredits = data?.payload?.credits?.totalRegisters??0

    let creditsForCustomers:(Array<{ customer: string, total: number, payment: number, balance: number, results: Array<typeof credits[0]> }>) = []
    for(let n of credits){
        const index = creditsForCustomers.findIndex((x) => x.customer === n.customer)
        if( index >= 0 ){
            creditsForCustomers[index].balance += Number(n?.balance??0)
            creditsForCustomers[index].payment += (Number(n?.total??0)-Number(n?.balance??0))
            creditsForCustomers[index].total += Number(n?.total??0)
            creditsForCustomers[index].results.push(n)
        }else{
            creditsForCustomers.push({ customer: n.customer, balance: Number(n?.balance??0), payment: (Number(n?.total??0)-Number(n?.balance??0)), total: Number(n?.total??0), results: [n] })
        }
    }

    return {
        credits,
        isFetching,
        creditsForCustomers,
        paidCredits,
        pendingCredits,
        totalCredits,
        quantityCredits
    }
}

export default useCredits