import { useNavigate } from "react-router-dom"
import { useGetCategoriesProductQuery } from "../../../api/querys/Product"
import Autocomplete from "@component/Autocomplete"
import Routes from "../../../services/routes"

const SearchCategoriesProduct = () => {
    const { data, isLoading } = useGetCategoriesProductQuery()
    const productCategories = data?.payload?.productCategories?.results??[]
    const navigate = useNavigate()
    const handleChange = (data:any, params:any) => navigate(Routes.inventoryByCategoryProducts(params._id))
    return(
        <Autocomplete label="Buscar categoría" options={productCategories} loading={isLoading} onChange={handleChange} />
    )
}

export default SearchCategoriesProduct