import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import { useGetCustomerQuery } from "../../../api/querys/Customer"
import Fade from "@component/Fade"
import { useAppDispatch } from "../../../hooks/state"
import { 
    displayFormToCreateCustomer, 
} from "../services/CustomerSlice"
import CustomerForm, { initialValueOfCustomerForm } from "./CustomerForm"

const EditCustomer = () => {
    const { customerId } = useParams()
    const dispatch = useAppDispatch()

    const [dataToEdit, setDataToEdit] = useState({...initialValueOfCustomerForm})

    const { data } = useGetCustomerQuery(customerId!)

    const customer = data?.payload!

    useEffect(() => {
        setDataToEdit((prevState) => ({
            ...prevState,
            name: customer.name ?? '',
            businessName: customer?.businessName ?? '',
            identificationNumber: customer?.identificationNumber ?? '',
            phoneNumber: customer?.phoneNumber ?? '',
            businessType: customer?.businessType?._id ?? '',
            address: customer?.address ?? '',
            city: customer?.city?._id ?? '',
            neighborhood: customer?.neighborhood?._id ?? '',
            zone: customer?.zone?._id ?? '',
            branch:customer?.branch?._id ?? '',
            _id: customer?._id ?? '',
        }))
      
        dispatch(displayFormToCreateCustomer(false))
    },[customer, dispatch])

    return(
        <Fade in={true} >
            <CustomerForm customerData={dataToEdit}/>
        </Fade>
    )
}

export default EditCustomer