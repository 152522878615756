import { CircularProgress as CircularProgressMUI, circularProgressClasses, CircularProgressProps } from '@mui/material';
import Box from './Box';
import { useTheme } from '@mui/material/styles'

const CircularProgress = (props: CircularProgressProps) => {
    const theme = useTheme()
    return (
        <Box width={1} height={1} position="relative" sx={{ background: theme.palette.background.paper }} zIndex={10000} left={0} top={0} >
            <Box position={'absolute'} top="50%" left={"50%"} zIndex={10000} >
                <Box sx={{ position: 'relative' }} >
                    <CircularProgressMUI
                        variant="determinate"
                        sx={{
                            color: (theme) =>
                                theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                        }}
                        size={40}
                        thickness={4}
                        {...props}
                        value={100}
                    />
                    <CircularProgressMUI
                        variant="indeterminate"
                        disableShrink
                        sx={{
                            color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
                            animationDuration: '550ms',
                            position: 'absolute',
                            left: 0,
                            [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: 'round',
                            },
                        }}
                        size={40}
                        thickness={4}
                        {...props}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default CircularProgress