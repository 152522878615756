import Button from "@component/Button"
import Dialog, { DialogActions, DialogContent, DialogContentText, DialogTitle } from "@component/Dialog"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { showAlertDeleteCategory } from "../services/ProductCategorySlice"
import { useCategoryProducts } from "../services/useCategoryProducts"

const AlertDeleteCategoryProduct = () => {
    const dispatch = useAppDispatch()
    const { ProductCategoryState } = useAppSelector(({ ProductCategoryState }) => ({ ProductCategoryState }))
    const { isOpenAlertDeleteCategoryProduct, selectionToDelete } = ProductCategoryState
    const { useDelCategoriesProduct:DelCategoriesProduct } = useCategoryProducts({})

    return(
        <Dialog open={isOpenAlertDeleteCategoryProduct} onClose={() => dispatch(showAlertDeleteCategory(false))} >
            <DialogTitle> Eliminar categorías de producto </DialogTitle>
            <DialogContent>
                <DialogContentText>¿Esta seguro que desea ejecutar esta acción?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="text" color="error" onClick={() => DelCategoriesProduct(selectionToDelete)} > Eliminar </Button>
                <Button variant="text" onClick={() => dispatch(showAlertDeleteCategory(false))} > Cancelar </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AlertDeleteCategoryProduct