import Title from '@component/Title';
import Grid from '../../components/Grid';
import LockComponent from '../../components/LockComponent';
import { useBusinessPermissions } from '../../hooks/usePermissions';
import CreatePackagingProduct from './components/CreatePackagingProduct';
import PackagingProductSearchBar from './components/PackagingProductSearchBar';
import ProductPackagingList from './components/ProductPackagingList';

const ProductPackaging = () => {
    const { ProductPackaging } = useBusinessPermissions()
    return (
        <>
            {ProductPackaging.lock_module ?
                <Grid container spacing={1} >
                    <Grid xs={12}>
                        <Title text='Embalaje de producto' />
                    </Grid>
                    <Grid xs={12}>
                        <PackagingProductSearchBar />
                    </Grid>
                    <Grid xs={12}>
                        <ProductPackagingList />
                    </Grid>
                    <CreatePackagingProduct />
                </Grid>
                :
                <LockComponent />
            }
        </>
    )
};

export default ProductPackaging;
