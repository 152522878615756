import React from 'react';
import { useTranslation } from "react-i18next"
import { useGetBankByBusinessQuery } from "../../../api/querys/Bank"
import Autocomplete from "@component/Autocomplete"

type Props = Omit<React.ComponentProps<typeof Autocomplete>, "options" | "label" | "loading" | "value"> & { value?: string }

const InputSearchBank = ({ value, ...rest }: Props) => {
    const { t } = useTranslation()
    const { data, isLoading } = useGetBankByBusinessQuery()
    const banks = data?.payload ?? []
    const selected = banks.find(n => n._id === value) || null

    return (
        <>
            <Autocomplete
                value={selected}
                loading={isLoading}
                {...rest}
                options={banks}
                label={t('banks')!}
            />
        </>
    )
}

export default InputSearchBank