import { useAppContextSelector } from "@hook/state"
import { useURLParams } from "@hook/useURLParams"
import { FixedCostsForm, GetFixedCostParamsProps, GetFixedCostResponse, GetFixedCostsResponse } from "@interface/FixedCosts"
import { useGetFixedExpenseQuery, useGetFixedExpensesQuery, useRegisterNewFixedExpenseMutation, useStopFixedExpenseMutation, useUpdateFixedExpenseMutation } from "@query/FixedCosts"
import { isErrorResponse, isSuccessResponse } from "@service/AnswersServices"

export const useFixedCost = () => {
    const { delQueries } = useURLParams()
    const { setGlobalState, FixedCostContext } = useAppContextSelector()
    const [ stopFixedExpenseMutation, { isLoading:isLoadingStopFixedExpenseMutation } ] = useStopFixedExpenseMutation()
    const [ registerNewFixedExpenseMutation, { isLoading:isLoadingRegisterNewFixedExpenseMutation } ] = useRegisterNewFixedExpenseMutation()
    const [ updateFixedExpenseMutation, { isLoading:isLoadingUpdateFixedExpenseMutation } ] = useUpdateFixedExpenseMutation()
    
    const saveFixedExpense = async (params:FixedCostsForm) => {
        try {
            const resp = !Boolean(params?._id) ? await registerNewFixedExpenseMutation(params).unwrap() : await updateFixedExpenseMutation(params).unwrap()
            
            if( resp.status === 1 ){
                if( !Boolean(params?._id) ){
                    isSuccessResponse("El gasto fijo se creo satisfactoriamente")
                }else{
                    isSuccessResponse("El gasto fijo se actualizo satisfactoriamente")
                }
                delQueries(["ref", "modal"])
            }else{
                isErrorResponse()
            }
        } catch (error) {
            console.log(error)
        }
    }
    
    const stopFixedExpense = async () => {
        try {
            const { fixedCostSelection } = FixedCostContext
            let payload = fixedCostSelection.reduce((acc, params) => [...acc, ...params], [])
            const resp = await stopFixedExpenseMutation(payload).unwrap()
            if( resp.status === 1 ){
                isSuccessResponse( payload.length >= 2 ? "Los gastos se detuvieron satisfactoriamente" : "El gasto se detuvo satisfactoriamente")
                setGlobalState(n => ({...n, 
                    FixedCostContext: { ...n.FixedCostContext,
                        fixedCostSelection: []
                    }
                }))
            }else{
                isErrorResponse()
            }
        } catch (error) {
            console.log(error)
        }
    }

    return {
        stopFixedExpense,
        isLoadingStopFixedExpenseMutation,
        saveFixedExpense,
        isLoadingRegisterNewFixedExpenseMutation,
        isLoadingUpdateFixedExpenseMutation,
    }
}

export const useGetFixedExpenses = () => {
    const { getParameterGroup } = useURLParams()
    const { group=null } = getParameterGroup(['group'])
    const { data, isFetching:isLoadingGetFixedExpensesQuery } = useGetFixedExpensesQuery({ groupBy: group })
    const resp = data?.payload??[] as GetFixedCostsResponse['payload']
    let list = (group === "tags") ? resp?.map((n) => n.results) : resp[0]?.results??[]
    let tags = resp.map((n) => n.name)
    let totalFixedExpenses: number[] = []
    let totalAccumulatorToPayment: number[] = []
    if( group === "tags" ){
        for( let [index, item] of list.entries() ){
            const elem = item as any[]
            totalFixedExpenses[index] = elem.reduce((acc, params) => (params?.amount??0)+acc,0)
            totalAccumulatorToPayment[index] = elem.reduce((acc, params) => (params?.total??0)+acc,0)
        }
    }else{
        let elem = list as any[]
        totalFixedExpenses[0] = elem.reduce((acc, params) => (acc+(params?.amount??0)),0)
        totalAccumulatorToPayment[0] = elem.reduce((acc, params) => (acc+(params?.total??0)),0)
    }
    return {
        resp,
        isLoading: isLoadingGetFixedExpensesQuery,
        list,
        totalFixedExpenses,
        tags,
        totalAccumulatorToPayment
    }
}


export const useGetFixedExpense = ({ fixedCostId }:GetFixedCostParamsProps) => {
    const { data, isLoading } = useGetFixedExpenseQuery({ fixedCostId }, { skip: !Boolean(fixedCostId) })
    const fixedCost = data?.payload??{} as GetFixedCostResponse["payload"]

    return {
        fixedCost,
        isLoading
    }
}