import React from 'react';
import {
  useGetPermissionsQuery,
  useGetRoleByBusinessQuery,
} from '../../api/querys/Permission';
import Fade from '../../components/Fade';
import CreateRol from './components/CreateRol';
import { selectRole } from './services/permissionSlice';
import { useAppDispatch } from '../../hooks/state';
import Grid from '../../components/Grid';
import TransferListPermissions from './components/TransferListPermissions';
import RolesList from './components/RolesList';
import InformativeHeader from './components/InformativeHeader';
import CircularProgress from '../../components/CircularProgess';
import Box from '../../components/Box';
import { useBusinessPermissions } from '../../hooks/usePermissions';
import LockComponent from '../../components/LockComponent';

const Permission = () => {
  const { Permissions } = useBusinessPermissions()
  const dispatch = useAppDispatch();
  const {
    data: dataRoles,
    isSuccess: isSuccessRoles,
    isLoading: isLoadingRoles,
  } = useGetRoleByBusinessQuery();
  const { isSuccess: isSuccessPermissions, isLoading: isLoadingPermissions } =
    useGetPermissionsQuery();
  const roles = dataRoles?.payload ?? [];
  
  React.useEffect(() => {
    if (roles.length >= 1) {
      dispatch(selectRole(roles[0]));
    }
  }, [roles, dispatch]);
  const showComponent =
    isSuccessPermissions &&
    isSuccessRoles &&
    !isLoadingRoles &&
    !isLoadingPermissions;
  if (showComponent) {
    return (
      <Fade in={true}>
        { Permissions.lock_module ?
          <Grid container spacing={1} >
            <Grid xs={12}>
              <InformativeHeader />
            </Grid>
            <Grid xs={12}>
              <Box
                sx={{ overflow: 'auto', whiteSpace: 'nowrap', paddingBottom: 1 }}
              >
                <RolesList />
              </Box>
            </Grid>
            <Grid xs={12}>
              <TransferListPermissions />
            </Grid>
            <CreateRol />
          </Grid>
          :
          <LockComponent />
        }
      </Fade>
    );
  } else {
    return (
      <Fade in={true}>
        <CircularProgress />
      </Fade>
    );
  }
};

export default Permission;
