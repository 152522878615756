import React from "react"
import Dialog, { DialogContent } from "@component/Dialog"
import Grid from "@component/Grid"
import NumberFormat from "@component/NumberFormat"
import { useAppDispatch, useAppSelector } from "@hook/state"
import { useFormikProductPreSelectionWindow } from "../services/formik"
import { updateListOfSelectedProducts, updateSelectedProduct } from "../services/PurchasesSuppliersSlice"
import * as yup from 'yup'
import Form from "@component/Form"
import TaxInput from "@feature/Business/components/TaxInput"
import PackagingProductInput from "@feature/Products/components/PackagingProductInput"
import { PurchasingProductsSelected } from "@interface/Purchasing"
import { DialogTitle } from "@mui/material"
import Button from "@component/Button"
import Typography from "@component/Typography"
import Stack from "@component/Stack"

const ProductPreSelectionWindow = () => {
    const dispatch = useAppDispatch()
    const { PurchasesSuppliersState } = useAppSelector(({ PurchasesSuppliersState }) => ({ PurchasesSuppliersState }))
    const { productSelected, productsSelectedList } = PurchasesSuppliersState

    const handleSubmit = (params:any) => {
        const list = [...productsSelectedList]
        const indexItem = list.findIndex((n) => n._id === productSelected?._id )
        
        if( indexItem >= 0 ){
            list[indexItem] = {
                _id: productSelected?._id as string,
                name: productSelected?.name as string,
                compounds: productSelected?.compounds as PurchasingProductsSelected['compounds'],
                prices: productSelected?.prices as PurchasingProductsSelected['prices'],
                packaging: productSelected?.packaging as PurchasingProductsSelected['packaging'],
                tax: productSelected?.tax as PurchasingProductsSelected['tax'],
                ...params
            }
        }else{
            list.push({
                _id: productSelected?._id as string,
                name: productSelected?.name as string,
                compounds: productSelected?.compounds as PurchasingProductsSelected['compounds'],
                prices: productSelected?.prices as PurchasingProductsSelected['prices'],
                packaging: productSelected?.packaging as PurchasingProductsSelected['packaging'],
                tax: productSelected?.tax as PurchasingProductsSelected['tax'],
                ...params
            })
        }
        
        dispatch(updateListOfSelectedProducts(list))
        dispatch(updateSelectedProduct(null))
    }

    const { formik } = useFormikProductPreSelectionWindow({
        initialValues: { quantity: 0, purchaseCost: 0, freight: 0, tax: productSelected?.tax??"", packaging: productSelected?.packaging??"" },
        yupRules: {
            quantity: yup.number()
                .min(1, "El valor debe ser igual o superior a 1")
                .positive("No se admiten números negativos")
                .required("La cantidad debe ser superior a 0"),
            purchaseCost: yup.number()
                .positive("No se admiten números negativos")
                .required("El costo de compra requerido"),
            packaging: yup.object()
                .nonNullable()
                .required("Seleccione un embalaje")
        },
        onSubmit: handleSubmit
    })
    const preloadData = React.useCallback(() => {
        const __productsSelectedList__ = [...productsSelectedList]
        const index = __productsSelectedList__.findIndex((n) => n._id === productSelected?._id)
        if( index >= 0 && productSelected !== null ){
            const __productSelected__ = structuredClone(__productsSelectedList__[index])
            const productKeys = Object.keys(__productSelected__!)
            for( let n of productKeys ){
                formik.setFieldValue(n, __productSelected__[n])
            }
        }
    },[productSelected, productsSelectedList])
    React.useEffect(() => {
        if( Boolean(productSelected?._id) ){
            formik.setFieldValue("packaging", productSelected?.packaging??"")
            formik.setFieldValue("tax", productSelected?.tax??"")
            setTimeout(() => {
                let elem = document.getElementById("quantityInput") as HTMLInputElement
                if( elem !== null ){
                    elem.focus()
                }
            }, 100)
            preloadData()
        }
    },[productSelected, preloadData])

    return(
        <Dialog maxWidth="xs" fullWidth open={Boolean(productSelected)} onClose={() => dispatch(updateSelectedProduct(null))} >
            <DialogTitle>
                <Stack direction={'row'} alignItems={'center'} spacing={1} >
                    {productSelected?.name}
                    <Typography ml={1} > {productSelected?.packaging?.name??""} </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Form onSubmit={() => formik.handleSubmit()} >
                    <Grid container spacing={1} >
                        <Grid xs={6} >
                            <NumberFormat
                                onChange={({ target }) => formik.setFieldValue('quantity', Number(target?.value??0)) }
                                value={formik.values.quantity}
                                id="quantityInput"
                                label="Cantidad"
                                error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                                helperText={<>{formik.touched.quantity && formik.errors.quantity}</>}
                            />
                        </Grid>
                        <Grid xs={6} >
                            <NumberFormat
                                prefix="$ "
                                label="Costo de compra"
                                onChange={({ target }) => formik.setFieldValue('purchaseCost', Number(target?.value??0)) }
                                value={formik.values.purchaseCost}
                                error={formik.touched.purchaseCost && Boolean(formik.errors.purchaseCost)}
                                helperText={<>{formik.touched.purchaseCost && formik.errors.purchaseCost}</>}
                            />
                        </Grid>
                        <Grid xs={12} >
                            <NumberFormat
                                onChange={({ target }) => formik.setFieldValue('freight', Number(target?.value??0)) }
                                value={formik.values.freight}
                                prefix="$ "
                                label="Flete"
                            />
                        </Grid>
                        <Grid xs={6} >
                            <TaxInput
                                onChange={(_,params) => formik.setFieldValue('tax', params) }
                                value={formik?.values?.tax?._id??null}
                            />
                        </Grid>
                        <Grid xs={6} >
                            <PackagingProductInput
                                label="Embalaje de compra"
                                onChange={(_, params) => formik.setFieldValue('packaging', params??null) }
                                productPackagingId={formik?.values?.packaging?._id??""}
                                error={formik.touched.packaging && Boolean(formik.errors.packaging)}
                                helperText={<>{formik.touched.packaging && formik.errors.packaging}</>}
                            />
                        </Grid>
                        <Grid xs={12} >
                            <Button disableElevation type="submit" >
                                Agregar a la lista
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default ProductPreSelectionWindow