import React from 'react'
import { useTheme } from "@mui/material"
import NumberFormat from "../../../../../components/NumberFormat"
import { ConfigProduct } from '../interface/WindowProductPOS'
import { useAppSelector } from '../../../../../hooks/state'
import { useSnackbar } from 'notistack'

interface Props {
    setConfigProduct : React.Dispatch<React.SetStateAction<ConfigProduct>>
    configProduct: ConfigProduct

}

const WindowProductPOSQuantityInput = ({ configProduct, setConfigProduct }:Props) => {
    const { enqueueSnackbar } = useSnackbar()
    const { typePrice, pricePosition, quantity } = configProduct
    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }))
    const { registerCreditNote  } = PointOfSaleState
    const { productSelected,  } = PointOfSaleState.windowProduct
    const product = {...productSelected}
    const [ initialQuantity, setInitialQuantity ] = React.useState<null | number>(null)

    const handleKeyDown = (event:React.KeyboardEvent) => {
        const { keyCode, which } = event
        const code = keyCode??which
        if( code === 9 ){
            event.preventDefault()
            event.stopPropagation()
            if( typePrice === "sellPrice" ){
                event.preventDefault()
                event.stopPropagation()
                if( pricePosition+1 ===  product?.prices?.length??0-1 ){
                    setTimeout(() => {
                        const manualPriceElement = document.getElementById('manualPrice')
                        if( manualPriceElement !== null ){
                            manualPriceElement.focus()
                        }
                    },250)
                    setConfigProduct(n => ({...n, typePrice: "manual"}))
                    setConfigProduct( n => ({...n, pricePosition: -1}) )
                }else{
                    setConfigProduct( n => ({...n, pricePosition: pricePosition+1}) )
                }
            }else{
                if( configProduct.manualPrice <= 0 ){
                    setConfigProduct(n => ({...n, typePrice: "sellPrice"}))
                    setConfigProduct( n => ({...n, pricePosition: 0}) )
                }else{
                    const manualPriceElement = document.getElementById('manualPrice')
                    if( manualPriceElement !== null ){
                        manualPriceElement.focus()
                    }
                }
            }
        }
    }

    const handleChange = (quantityValue:number) => {
        if( registerCreditNote ){
            if( initialQuantity !== null && quantity > initialQuantity ){
                enqueueSnackbar("Nota crédito no permite añadir mas elementos", { variant: "error" })
                return
            }
        }
        setConfigProduct((n:any) => ({...n, quantity: quantityValue }))
    }

    React.useEffect(() => {
        setTimeout(() => {
            if( initialQuantity === null ){
                setInitialQuantity(quantity)
            }
        },250)
    },[quantity])

    const theme = useTheme()
    return(
        <>
            <NumberFormat
                onChange={({ target }) => handleChange(Number(target?.value??0))}
                autoFocus
                onKeyDown={handleKeyDown}
                id="quantityProduct"
                value={quantity}
                variant="outlined"
                label=""
                style={{
                    textAlign: 'center',
                    color: theme.palette.primary.main,
                    fontWeight: 'bold'
                }}
            />
        </>
    )
}

export default WindowProductPOSQuantityInput