import Box from "@component/Box"
import DatePicker from "@component/DatePicker"
import TextField from "@component/TextField"
import { useAppDispatch, useAppSelector } from "@hook/state"
import { updateBillingDate, updateDateReceived, updateInvoiceNumber, updateWhoReceives } from "../services/PurchasesSuppliersSlice"
import React from "react"
import { currencyFormat } from "@hook/useNumberFormat"
import List, { ListItem, ListItemAvatar, ListItemText } from "@component/List"
import Avatar from "@component/Avatar"

const PurchaseInvoiceDates = () => {
    const { PurchasesSuppliersState } = useAppSelector(({ PurchasesSuppliersState }) => ({ PurchasesSuppliersState }))
    const { dateReceived, billingDate, whoReceives, providerSelected, invoiceNumber } = PurchasesSuppliersState
    const dispatch = useAppDispatch()
    const callbackRef = React.useCallback((inputElement:HTMLInputElement) => {
        if (inputElement) inputElement.focus()
    }, []);
    return (
        <>
            <Box display={'flex'} justifyContent='space-between' alignItems={'center'} flexDirection={'row'} mb={1} >
                <Box>
                    {providerSelected !== null &&
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${providerSelected.name}`}
                                    secondary={`Anticipo: $ ${currencyFormat(providerSelected.advance??0, true)}`}
                                />
                            </ListItem>
                        </List>
                    }
                </Box>
                <Box sx={{ display: 'inline-flex', gap: 1 }} >
                    <DatePicker ref={callbackRef} fullWidth label="Fecha de facturación" size="small" value={billingDate} onChange={({ value }) => dispatch(updateBillingDate(value as Date))} />
                    <DatePicker fullWidth label="Fecha de recibido" size="small" value={dateReceived} onChange={({ value }) => dispatch(updateDateReceived(value as Date))} />
                    <TextField value={whoReceives} size="small" margin="none" label="Recibe" onChange={({ target }) => dispatch(updateWhoReceives(target.value))} />
                    <TextField value={invoiceNumber} size="small" margin="none" label="Numero de factura" onChange={({ target }) => dispatch(updateInvoiceNumber(target.value))} />
                </Box>
            </Box>
        </>
    )
}

export default PurchaseInvoiceDates