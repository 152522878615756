import React from 'react'
import dayjs from "dayjs"
import Box from "./Box"
import DatePicker from "./DatePicker"

type DatePickerProps = React.ComponentProps<typeof DatePicker>
type DateRangeProps = { initDate: Date, endDate: Date }

interface Props extends Pick<DatePickerProps, 'size' | "disableFuture" | "disablePast" | "maxDate"> {
    finDate?: boolean
    initDate?: Date
    endDate?: Date
    onChange?: (props:DateRangeProps) => void
}

const FilterByDateRange = ({ endDate=dayjs().toDate(), initDate=dayjs().toDate(), onChange, finDate=true, ...rest }:Props) => {
    const [dateRange, setDateRange] = React.useState<DateRangeProps>({ initDate, endDate })

    const handleChange = (value:Date, field:string) => {
        const result = {...dateRange, [field]: value}
        setDateRange(_ => ({...result}))
        if( onChange !== undefined ){
            onChange(result)
        }
    }
    React.useEffect(() => {
        if( !Boolean(dateRange?.endDate) ) setDateRange((n) => ({...n, endDate}))
        if( !Boolean(dateRange?.initDate) ) setDateRange((n) => ({...n, initDate}))
    }, [endDate, initDate])

    return(
        <Box sx={{ display:'flex', gap: 1 }} >
            <DatePicker
                {...rest}
                onChange={({ value }) => handleChange( dayjs(value).toDate(), 'initDate' )}
                label="Fecha inicial"
                value={dateRange.initDate}
            />
            {finDate &&
                <DatePicker
                    {...rest}
                    onChange={({ value }) => handleChange( dayjs(value).toDate(), 'endDate' )}
                    label="Fecha final"
                    value={dateRange.endDate}
                />
            }
        </Box>
    )
}

export default FilterByDateRange