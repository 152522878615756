import { useParams } from "react-router-dom"
import { useGetEmployeeQuery } from "../../../api/querys/Employee"
import CircularProgress from "@component/CircularProgess"
import Fade from "@component/Fade"
import List from "@component/List"
import ListItemData from "@component/ListItemData"
import day from 'dayjs'

const DataEmployee = () => {
    const { employeeId } = useParams()
    const { data, isSuccess, isLoading } = useGetEmployeeQuery(employeeId!)
    const showComponent = !isLoading && isSuccess
    const employee = data?.payload
    if(showComponent){
        return(
            <Fade in={true} >
                <List>
                    <ListItemData showAvatar primaryText="Nombre" secondaryText={`${employee?.name} ${employee?.lastName}`} />
                    <ListItemData primaryText="Correo electronico" secondaryText={employee?.email} />
                    <ListItemData primaryText="Numero de telefono" secondaryText={employee?.numberPhone} />
                    <ListItemData primaryText="Ultima coneccion" secondaryText={day(employee?.lastConnection).format("DD-MM-YYYY - HH:mm:ss")} />
                    <ListItemData primaryText="Ultima solicitud en el sistema" secondaryText={day(employee?.lastRequest).format("DD-MM-YYYY - HH:mm:ss")} />
                </List>
            </Fade>
        )
    }else{
        return(
            <Fade in={true} >
                <CircularProgress />
            </Fade>
        )
    }
}

export default DataEmployee