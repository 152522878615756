import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { styled } from '@mui/material/styles';
import Box from './Box';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

type ClippedDrawnerProps = {
  children: JSX.Element | JSX.Element[];
  open: boolean;
  onOpen?: () => void;
  anchor?: Anchor;
  variant?: 'persistent' | 'permanent' | 'temporary';
  marginTop?: number
  padding?: number 
};

const StyledSwipeableDrawer = styled(SwipeableDrawer)(() => ({
  '.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop': {
    backgroundColor: 'transparent !important',
  },
  '.css-1160xiw-MuiPaper-root-MuiDrawer-paper': {
    boxShadow: 'none !important',
    borderLeft: '1px solid rgb(224, 224, 224) !important',
  },
}));

const ClippedDrawer = ({
  children,
  open,
  onOpen = () => {},
  anchor = 'right',
  variant = 'temporary',
  marginTop = 8,
  padding = 2
}: ClippedDrawnerProps) => {
  return (
    <StyledSwipeableDrawer
      anchor={anchor}
      open={open}
      onClose={onOpen!}
      onOpen={onOpen!}
      variant={variant}
    >
        <Box
          overflow='auto'
          width='240px'
          marginTop={marginTop}
          padding={padding}
        >
          {children}
        </Box>
    </StyledSwipeableDrawer>
  );
};

export default ClippedDrawer;
