import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import DatePicker from "@component/DatePicker"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { changeClosingDateOfDay } from "../services/ClosingOfTheDaySlice"

const FilterClosingOfTheDay = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const { ClosingOfTheDayState } = useAppSelector(({ ClosingOfTheDayState }) => ({ ClosingOfTheDayState }))
    const { dateClosingOfDay } = ClosingOfTheDayState
    return(
        <>
            <DatePicker
                label={t('closingDate')!}
                value={dayjs(dateClosingOfDay).toDate()}
                onChange={({ value }) => dispatch(changeClosingDateOfDay(dayjs(value).format("MM-DD-YYYY")))}
            />
        </>
    )
}

export default FilterClosingOfTheDay