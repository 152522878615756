import Box from "@component/Box";
import Dialog, { DialogContent } from "@component/Dialog"
import Typography from "@component/Typography";
import { IconContainer } from "@feature/Invoice/components/SelectPaymentMethod";
import LocalAtmRounded from '@mui/icons-material/LocalAtmRounded';
import AttachMoneyRounded from '@mui/icons-material/AttachMoneyRounded';
import CurrencyExchangeRounded from '@mui/icons-material/CurrencyExchangeRounded';
import CashPaymentForm from "./CashPaymentForm";
import { useAppDispatch, useAppSelector } from "@hook/state";
import { displayFormCredit, showCashForm, showPaymentSelectionWindow } from "../services/PurchasesSuppliersSlice";
import CreditForm from "./CreditForm";
import Form from '@component/Form';
import { usePurchasing, usePurchasingTools } from '../hooks/usePurchasing';
import { useSnackbar } from 'notistack';

interface Props {
    onChangePaymentMethod?: (typePayment:string) => void
}

const PaymentSelectionWindow = ({ onChangePaymentMethod }:Props) => {
    const dispatch = useAppDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { PurchasesSuppliersState } = useAppSelector(({ PurchasesSuppliersState }) => ({ PurchasesSuppliersState }))
    const { openPaymentSelectionWindow, haulier, productsSelectedList, providerSelected, billingDate, purchaseId, dateReceived, whoReceives, dueDate, invoiceNumber } = PurchasesSuppliersState
    const { recordPurchaseFromSupplier,  } = usePurchasing()
    const { calcTotalPurchasing } = usePurchasingTools()
    const { total } = calcTotalPurchasing(productsSelectedList)
    const handleSubmit = () => {
        const advanceProvider = (providerSelected?.advance??0)
        if( advanceProvider < total ){
            enqueueSnackbar("El proveedor no cuenta con anticipos suficientes para realizar el pago.", {variant: "warning"})
            return
        }
        recordPurchaseFromSupplier({ purchaseId, haulier, productList: productsSelectedList, provider: providerSelected!, typePayments: "advance", billingDate, dateReceived, whoReceives, dueDate, invoiceNumber })
    }

    const handleSubmitForm = () => {
        const elem = document.activeElement
        const selection = elem?.id
        if( selection !== null && selection !== undefined ){
            if( selection === "cash" ){
                dispatch(showCashForm(true))
            }
            if( selection === "credit" ){
                dispatch(displayFormCredit(true))
            }
            if( selection === "advance" ){
                handleSubmit()
            }
            dispatch(showPaymentSelectionWindow(false))
        }
    }
    return (
        <>
            <Dialog fullWidth open={openPaymentSelectionWindow} onClose={() => dispatch(showPaymentSelectionWindow(false))} >
                <DialogContent>
                    <Form onSubmit={handleSubmitForm} >
                        <Box px={2} display={'flex'} width={'100%'} justifyContent={'space-between'} >
                            <Box maxWidth={150} minWidth={150} >
                                <IconContainer tabIndex={0} id="cash" onClick={handleSubmitForm} >
                                    <LocalAtmRounded sx={{ fontSize: "5rem" }} color="primary" />
                                    <Typography textTransform={'uppercase'} fontWeight={'bold'} fontSize={'1rem'} >
                                        Contado
                                    </Typography>
                                </IconContainer>
                            </Box>
                            <Box maxWidth={150} minWidth={150} >
                                <IconContainer tabIndex={0} id="credit" onClick={handleSubmitForm} >
                                    <AttachMoneyRounded sx={{ fontSize: "5rem" }} color="primary" />
                                    <Typography textTransform={'uppercase'} fontWeight={'bold'} fontSize={'1rem'} >
                                        Crédito
                                    </Typography>
                                </IconContainer>
                            </Box>
                            <Box maxWidth={150} minWidth={150} >
                                <IconContainer tabIndex={0} id="advance" onClick={handleSubmitForm} >
                                    <CurrencyExchangeRounded sx={{ fontSize: "5rem" }} color="primary" />
                                    <Typography textTransform={'uppercase'} fontWeight={'bold'} fontSize={'1rem'} >
                                        Anticipo
                                    </Typography>
                                </IconContainer>
                            </Box>
                        </Box>
                    </Form>
                </DialogContent>
            </Dialog>
            <CashPaymentForm />
            <CreditForm />
        </>
    )
}

export default PaymentSelectionWindow