import Autocomplete from '../../../components/Autocomplete';
import { Autocomplete as AutocompleteMUI } from '@mui/material';
import Box from '../../../components/Box';

type AutocompleteMUIProps = Omit<
  React.ComponentProps<typeof AutocompleteMUI>,
  'renderInput'
>;

interface FilterReportInputProps extends AutocompleteMUIProps {
  chipColor?:
    | 'primary'
    | 'default'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  chipSize?: 'small' | 'medium';
  label: string;
}

const FilterReportInput = ({
  chipColor = 'primary',
  chipSize = 'small',
  label,
  ...rest
}: FilterReportInputProps) => {
  return (
    <Box mb={2}>
      <Autocomplete
        label={label}
        limitTags={2}
        ChipProps={{ color: chipColor, size: chipSize }}
        {...rest}
      />
    </Box>
  );
};

export default FilterReportInput;
