import React from 'react';
import FormControlLabel from "./FormControlLabel"
import { Checkbox as CheckboxMUI, CheckboxProps } from '@mui/material'

type ControlLabelProps = React.ComponentProps<typeof FormControlLabel>
interface Props extends Pick<CheckboxProps, "checked" | "disabled"> {
    label: ControlLabelProps['label']
    onChange: CheckboxProps['onChange']
    sx?: ControlLabelProps['sx']
}

const Checkbox = ({ label, onChange, sx, ...rest }:Props) => {
    return(
        <>
            <FormControlLabel
                sx={sx}
                control={<CheckboxMUI {...rest} onChange={onChange} />}
                label={label}
            />
        </>
    )
}

export default Checkbox