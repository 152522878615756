import { useTranslation } from "react-i18next"
import Fade from "@component/Fade"
import Grid from "@component/Grid"
import LockComponent from "@component/LockComponent"
import Tab from "@component/Tab"
import { useBusinessPermissions } from "../../../hooks/usePermissions"
import BankInformation from "./BankInformation"
import EditBank from "./EditBank"

const BankData = () => {
    const { t } = useTranslation()
    const { Banks } = useBusinessPermissions()

    return (
        <Fade in={true} >
            {Banks.lock_module ?
                <Grid container >
                    <Grid xs={8} >
                        <Tab
                            TabItem={[t('information'), t('editInformation')]}
                            TabRender={[<BankInformation />, <EditBank />]}
                        />
                    </Grid>
                </Grid>
                :
                <LockComponent />
            }
        </Fade>
    )
}

export default BankData