import React from 'react'
import Box from '../../../components/Box';
import Dialog, { DialogContent } from '../../../components/Dialog';
import Grid from '../../../components/Grid';
import { styled } from '@mui/material/styles';
import Typography from '../../../components/Typography';
import { useTranslation } from 'react-i18next';
import Form from '../../../components/Form';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import { showBankForm, showCreditForm, showDeliveryForm, showPaymentMethodForm } from '../service/InvoiceSlice';
import { useInvoice } from '../hooks/useInvoice';
import DeliveryNameForm from './DeliveryNameForm';
import BankForm from './BankForm';
import InvoiceCard from './InvoiceCard';
import { showCashierWindow } from '../../PointOfSale/services/PointOfSaleSlice';
import { typeOfPayment } from '..';
import CreditMethod from './CreditMethod';
import LocalAtmTwoTone from '@mui/icons-material/LocalAtmRounded';
import AttachMoneyTwoTone from '@mui/icons-material/AttachMoneyRounded';
import MopedTwoTone from '@mui/icons-material/MopedRounded';
import AccountBalanceTwoTone from '@mui/icons-material/AccountBalanceRounded';
import { genUUID } from '@service/parseData';
import TextField from '@component/TextField';
import { useURLParams } from '@hook/useURLParams';
import CurrencyExchangeRounded from '@mui/icons-material/CurrencyExchangeRounded';

type CustomBoxProps = React.ComponentProps<typeof Box> & { active?: boolean }

export const IconContainer = styled((props:CustomBoxProps) => <Box {...props} />, { shouldForwardProp: (prop) => prop !== 'active' } )(({ theme, active }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 3,
    borderRadius: theme.shape.borderRadius,
    width: 120,
    height: 90,
    cursor: 'pointer',
    ...(active ? {
        backgroundColor: '#42a5f55c',
        border: '2px solid',
    } : {}),
    '&:focus' : {
        backgroundColor: '#42a5f55c',
        border: '2px solid',
    },
    '& svg' : {
        fontSize: "3.5rem",
        color: theme.palette.primary.main
    },
    '& p' : {
        fontSize: ".875rem",
        textTransform: "uppercase",
        fontWeight: "bold"
    }
}));

interface Props {
    invoiceId?: string;
}

const SelectPaymentMethod = ({ invoiceId }: Props) => {
    const { getQuery } = useURLParams()
    const typeOf = getQuery("typeOf") || undefined
    const [observation, setObservation] = React.useState<string>("")
    const [paymentsMethods] = React.useState<string[]>(["cash", "credit", "delivery", "consignment", "customerAdvance"])
    const [ positionPaymentMethod, setPositionPaymentMethod ] = React.useState<number>(0)
    const dispatch = useAppDispatch();
    const { InvoiceState } = useAppSelector(({ InvoiceState }) => ({
        InvoiceState,
    }));

    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({
        PointOfSaleState,
    }));
    const { isOpenPointOfSale } = PointOfSaleState;

    const { openSelectedPaymentMethod, invoiceValues } = InvoiceState;

    const { changePaymentMethod } = useInvoice();
    const { t } = useTranslation();
    const handleSubmit = () => {
        const value = paymentsMethods[positionPaymentMethod]
        if(value === "cash"){
            changePaymentMethod(typeOfPayment.cash, { invoiceId: invoiceId ?? '', observation });
            return
        }
        if(value === "credit"){
            dispatch(showPaymentMethodForm(false));
            dispatch(showCreditForm(true));
            return
        }
        if(value === "delivery"){
            dispatch(showPaymentMethodForm(false));
            dispatch(showDeliveryForm(true));
            return
        }
        if(value === "consignment"){
            dispatch(showPaymentMethodForm(false));
            dispatch(showBankForm(true));
            return
        }
        if(value === "customerAdvance"){
            changePaymentMethod(typeOfPayment.customerAdvance, { invoiceId: invoiceId ?? '', observation });
            return
        }
    };

    const handleOnClose = () => {
        dispatch(showPaymentMethodForm(false));
        dispatch(showCashierWindow(true));
    };

    const handleKeyPress = (event:React.KeyboardEvent) => {
        const { key } = event
        const elem = document.getElementById("observation") as HTMLInputElement
        if( key === "Tab" ){
            event.preventDefault()
            event.stopPropagation()
            if( positionPaymentMethod < paymentsMethods.length-1 ){
                if( Boolean(elem) ){
                    elem.blur()
                }
                setPositionPaymentMethod(n => n+1)
            }else{
                if( Boolean(elem) ){
                    elem.focus()
                }
                setPositionPaymentMethod(0)
            }
        }
        if( key === "F1" ){
            event.preventDefault()
            event.stopPropagation()
            if( Boolean(elem) ){
                elem.focus()
            }
        }
        if( key === "Enter" ){
            handleSubmit()
        }
    }

    return (
        <>
            <Dialog open={openSelectedPaymentMethod} onClose={handleOnClose} fullWidth onKeyDown={handleKeyPress} >
                <DialogContent>
                    <Form onSubmit={handleSubmit}>
                        <Grid container spacing={1} >
                            <Grid xs={6} >
                                {isOpenPointOfSale && (
                                    <InvoiceCard {...invoiceValues} isInformative />
                                )}
                            </Grid>
                            <Grid xs={6} >
                                <TextField id="observation" value={observation} margin='none' label="Observación" multiline rows={2} onChange={({ target }) => setObservation(target?.value??"")} />
                            </Grid>
                            {/* <Grid xs={1} sx={{ visibility: 'hidden' }} ></Grid>
                            {paymentsMethods.map((methods, index) => {
                                return(
                                    <React.Fragment key={index} >
                                        <Grid xs={2}>
                                            <IconContainer key={genUUID()} active={(positionPaymentMethod === index)} onClick={() => setPositionPaymentMethod(index)} onDoubleClick={handleSubmit} >
                                                { methods === "cash" && <LocalAtmTwoTone /> }
                                                { methods === "credit" && <AttachMoneyTwoTone /> }
                                                { methods === "delivery" && <MopedTwoTone /> }
                                                { methods === "consignment" && <AccountBalanceTwoTone /> }
                                                { methods === "customerAdvance" && <CurrencyExchangeRounded /> }
                                                <Typography textTransform={'uppercase'} fontWeight={'bold'} fontSize={'1rem'} >
                                                    {t(methods)}
                                                </Typography>
                                            </IconContainer>
                                        </Grid>
                                    </React.Fragment>
                                )
                            })}
                            <Grid xs={1} sx={{ visibility: 'hidden' }} ></Grid> */}
                        </Grid>
                        <Box mt={2} sx={{ display: 'flex', gap: 2, justifyContent: "space-between" }} >
                        {paymentsMethods.map((methods, index) => {
                                return(
                                    <React.Fragment key={index} >
                                        <IconContainer key={genUUID()} active={(positionPaymentMethod === index)} onClick={() => setPositionPaymentMethod(index)} onDoubleClick={handleSubmit} >
                                            { methods === "cash" && <LocalAtmTwoTone /> }
                                            { methods === "credit" && <AttachMoneyTwoTone /> }
                                            { methods === "delivery" && <MopedTwoTone /> }
                                            { methods === "consignment" && <AccountBalanceTwoTone /> }
                                            { methods === "customerAdvance" && <CurrencyExchangeRounded /> }
                                            <Typography textTransform={'uppercase'} fontWeight={'bold'} fontSize={'1rem'} >
                                                {t(methods)}
                                            </Typography>
                                        </IconContainer>
                                    </React.Fragment>
                                )
                            })}
                        </Box>
                    </Form>
                </DialogContent>
            </Dialog>
            <CreditMethod invoiceId={invoiceId} observation={observation} />
            <DeliveryNameForm invoiceId={invoiceId} observation={observation} typeOf={typeOf} />
            <BankForm invoiceId={invoiceId} observation={observation} />
        </>
    );
};

export default SelectPaymentMethod;
