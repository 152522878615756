import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ProductsFromExcel } from '../../../interfaces/Product'
import { GridRowSelectionModel } from '@mui/x-data-grid'
import dayjs from 'dayjs'

interface stateProps {
    productsFromExcel: ProductsFromExcel[]
    formType: "update" | "create"
    openProductForm: boolean,
    createTypeForm: "product" | "category" | "packaging"
    productSelection : GridRowSelectionModel
    isTheProductDeletionAlertOpen: boolean
    startDateProductPurchaseHistory: string
    endDateProductPurchaseHistory: string
    productDataTabPosition: number
}

const initialState:stateProps = {
    productsFromExcel: [],
    formType: "create",
    openProductForm: false,
    createTypeForm: "product",
    productSelection: [],
    isTheProductDeletionAlertOpen: false,
    startDateProductPurchaseHistory: dayjs().startOf('month').format('MM-DD-YYYY'),
    endDateProductPurchaseHistory: dayjs().endOf('day').format('MM-DD-YYYY'),
    productDataTabPosition: 0,
}

export const ProductSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        loadProductsFromExcel: (state, action:PayloadAction<ProductsFromExcel[]>) => {
            state.productsFromExcel = action.payload
        },
        displayProductCreationForm: (state, action:PayloadAction<boolean>) => {
            state.formType = "create"
            state.openProductForm = action.payload
        },
        toggleBetweenForms: (state, action:PayloadAction<stateProps['createTypeForm']>) => {
            state.createTypeForm = action.payload
        },
        updateArrayWithSelectedProducts: (state, action:PayloadAction<GridRowSelectionModel>) => {
            state.productSelection = action.payload
        },
        showProductDeletionAlert: (state, action:PayloadAction<boolean>) => {
            state.isTheProductDeletionAlertOpen = action.payload
        },
        updateProductPurchaseHistoryStartDate: (state, action:PayloadAction<string>) => {
            state.startDateProductPurchaseHistory = action.payload
        },
        updateProductPurchaseHistoryEndDate: (state, action:PayloadAction<string>) => {
            state.endDateProductPurchaseHistory = action.payload
        },
        updateProductDataTabPosition: (state, action:PayloadAction<number>) => {
            state.productDataTabPosition = action.payload
        }
    }
})

export const {
    loadProductsFromExcel,
    displayProductCreationForm,
    toggleBetweenForms,
    updateArrayWithSelectedProducts,
    showProductDeletionAlert,
    updateProductPurchaseHistoryStartDate,
    updateProductPurchaseHistoryEndDate,
    updateProductDataTabPosition,
} = ProductSlice.actions
export default ProductSlice.reducer