import { useTranslation } from "react-i18next";
import { useGetCustomerZoneQuery } from "../../../api/querys/Customer";

import Autocomplete from "@component/Autocomplete";

interface Props {
  customerZoneId: string;
  onChange?: (event: any, value: any | null, name: string | undefined) => void;
}

const CustomerZoneInput = ({ customerZoneId, ...rest }: Props) => {
  const { t } = useTranslation();

  const { isLoading, data: ZoneData  } = useGetCustomerZoneQuery();

  const ZoneDataArray = ZoneData?.payload.customerZones.results || [];

  const value = ZoneDataArray.find(
    (n: typeof ZoneDataArray[0]) => n._id === customerZoneId
  );

  return (
    <Autocomplete
      name="zones"
      label={t("zones")!}
      value={value || null}
      options={ZoneDataArray}
      loading={isLoading}
      {...rest}
    />
  );
};

export default CustomerZoneInput;
