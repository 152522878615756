import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

interface initialStateProps {
    dateRange: {
        initDate: string
        endDate: string
    }
}

const initialState:initialStateProps = {
    dateRange:{
        initDate: dayjs().format("MM-DD-YYYY"),
        endDate: dayjs().format("MM-DD-YYYY"),
    }
}

export const DeliveriesSlice = createSlice({
    name: 'deliveries',
    initialState,
    reducers: {
        updateDateRange: (state, action:PayloadAction<initialStateProps['dateRange']>) => {
            state.dateRange = action.payload
        }
    }
})

export const {
    updateDateRange,
} = DeliveriesSlice.actions
export default DeliveriesSlice.reducer