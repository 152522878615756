import React from 'react';
import { DateTimePicker as DateTimePickerMUI } from '@mui/x-date-pickers/DateTimePicker';
import TextField from './TextField';

type Props = Pick<React.ComponentProps<typeof DateTimePickerMUI>, "label" | "value" | "onChange">

const DateTimePicker = ({...rest}:Props) => {
    return(
        <DateTimePickerMUI {...rest} renderInput={(params) => <TextField {...params} />} />
    )
}

export default DateTimePicker