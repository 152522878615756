import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { CustomerDataProps } from '../../../interfaces/Customer'

interface stateProps {
    branch: string
    listCustomer: CustomerDataProps[]
    isTheFormToCreateCustomerOpen: boolean
    securityAlertClearClient: boolean
    customersToDelete: string[]
}

const initialState:stateProps = {
    branch: '',
    listCustomer: [],
    isTheFormToCreateCustomerOpen: false,
    securityAlertClearClient: false,
    customersToDelete: []
}

export const CustomerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        uploadCustomerList: (state, action:PayloadAction<{listCustomer: CustomerDataProps[], branch: string}>) => {
            state.listCustomer = action.payload.listCustomer
            state.branch = action?.payload?.branch??''
        },
        displayFormToCreateCustomer: (state, action:PayloadAction<boolean>) => {
            state.isTheFormToCreateCustomerOpen = action.payload
        },
        showSecurityAlertClearClient: (state, action:PayloadAction<boolean>) => {
            state.securityAlertClearClient = action.payload
        },
        loadCustomersToDelete: (state, action:PayloadAction<string[]>) => {
            state.customersToDelete = action.payload
        },
    }
})

export const {
    uploadCustomerList,
    displayFormToCreateCustomer,
    showSecurityAlertClearClient,
    loadCustomersToDelete
} = CustomerSlice.actions
export default CustomerSlice.reducer