import { useGetEmployessByBusinessQuery } from "../../api/querys/Employee"
import CircularProgress from "@component/CircularProgess"
import Fade from "@component/Fade"
import Grid from "@component/Grid"
import LockComponent from "@component/LockComponent"
import { useBusinessPermissions } from "../../hooks/usePermissions"
import ListEmployee from "./components/ListEmployess"
import SearchEmployee from "./components/SearchEmployee"
import Title from "@component/Title"

const Employee = () => {
    const { Team } = useBusinessPermissions()
    const { isLoading, isSuccess } = useGetEmployessByBusinessQuery()

    const showComponent = !isLoading && isSuccess

    if( showComponent ){
        return(
            <Fade in={true} >
                { Team.lock_module ?
                    <Grid container spacing={1} >
                        <Grid xs={12} >
                            <Title text="Equipo" />
                        </Grid>
                        <Grid xs={12} >
                            <SearchEmployee />
                        </Grid>
                        <Grid xs={8} >
                            <ListEmployee />
                        </Grid>
                        <Grid xs={4} >
                            
                        </Grid>
                    </Grid>
                    :
                    <LockComponent />
                }
            </Fade>
        )
    }else{
        return(
            <CircularProgress />
        )
    }
}

export default Employee