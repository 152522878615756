import { useGetEmployessByBusinessQuery } from "../../../api/querys/Employee"
import Autocomplete from "@component/Autocomplete"
import React from "react"

type AutocompleteProps = Pick<React.ComponentProps<typeof Autocomplete>, "onChange" | "label" | "helperText" | "error">

const EmployeesInput = ({...rest}:AutocompleteProps) => {
    const { data, isLoading } = useGetEmployessByBusinessQuery()
    const employees = data?.payload?.employees?.results??[]
    return(
        <Autocomplete
            loading={isLoading}
            options={employees}
            label="Empleados"
            {...rest}
            renderOption={(props, params:any, index) =>{
                return(
                    <li {...props} key={`${index}-${params._id}`}>
                        {`${params.name} ${params.lastName}`}
                    </li>
                )
            } }
        />
    )
}

export default EmployeesInput