import AddCardRounded from "@mui/icons-material/AddCardRounded"
import AttachMoneyRounded from "@mui/icons-material/AttachMoneyRounded"
import DeliveryDiningRounded from "@mui/icons-material/DeliveryDiningRounded"
import MonetizationOnRounded from "@mui/icons-material/MonetizationOnRounded"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import Box from "@component/Box"
import List, { ListItem, ListItemIcon, ListItemText } from "@component/List"
import { currencyFormat } from "../../../hooks/useNumberFormat"
import type { InvoiceProps } from "@interface/PointOfSale"
import EventRounded from '@mui/icons-material/EventRounded';
import ErrorRounded from '@mui/icons-material/ErrorRounded';
import Chip from "@component/Chip"
import Typography from "@component/Typography"
import ReceiptRounded from '@mui/icons-material/ReceiptRounded';
import CurrencyExchangeRounded from '@mui/icons-material/CurrencyExchangeRounded';
import { NumbersRounded } from "@mui/icons-material"
import SearchRounded from '@mui/icons-material/SearchRounded';

const DetailsInvoice = (props:InvoiceProps) => {
    const { t } = useTranslation()
    const { totalReal, typePayment, totalProfitCurrency, totalProfitPercentage, paymentCredits, deliveryName, deliveryAmount=0, bank, creditExpirationDate, totalPayments, total, isArchived, electronicConsecutive, observation } = props

    return(
        <Box>
            <List>
                { Boolean(electronicConsecutive) &&
                    <ListItem>
                        <ListItemText
                            primary={`#F-${electronicConsecutive}`}
                            secondary={`Consecutivo de factura electronica`}
                        />
                        <ListItemIcon>
                            <NumbersRounded />
                        </ListItemIcon>
                    </ListItem>
                }
                <ListItem>
                    <ListItemText
                        primary={`${Boolean(isArchived) ? "Archivada" : "Venta" }`}
                        secondary={`Estado de la factura`}
                    />
                    <ListItemIcon>
                        <ReceiptRounded />
                    </ListItemIcon>
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={`$ ${currencyFormat(totalReal??0)}`}
                        secondary={`Valor real`}
                    />
                    <ListItemIcon>
                        <CurrencyExchangeRounded />
                    </ListItemIcon>
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={`${t(typePayment)} ${typePayment === "consignment" ? ` - ${bank.name}` : ""} `}
                        secondary={t('paymentType')}
                    />
                    <ListItemIcon>
                        <AttachMoneyRounded />
                    </ListItemIcon>
                </ListItem>

                <>
                    { Boolean(deliveryName) &&
                        <ListItem>
                            <ListItemText
                                primary={deliveryName}
                                secondary={t('domiciliary')}
                            />
                            <ListItemIcon>
                                <DeliveryDiningRounded />
                            </ListItemIcon>
                        </ListItem>
                    }

                    { (deliveryAmount >= 0) &&
                        <ListItem>
                            <ListItemText
                                primary={`$ ${currencyFormat(deliveryAmount)}`}
                                secondary={t('Valor del envío')}
                            />
                            <ListItemIcon>
                                <DeliveryDiningRounded />
                            </ListItemIcon>
                        </ListItem>
                    }
                </>
                
                <ListItem>
                    <ListItemText
                        primary={`$ ${currencyFormat(totalProfitCurrency)} - ${totalProfitPercentage}%`}
                        secondary={t('profit')}
                    />
                    <ListItemIcon>
                        <MonetizationOnRounded />
                    </ListItemIcon>
                </ListItem>
                { Boolean(creditExpirationDate) &&
                    <ListItem>
                        <ListItemText
                            primary={
                                <Box display={'flex'} alignItems={'center'} >
                                    <Typography mr={1} >
                                        {dayjs(creditExpirationDate).format("DD-MM-YYYY")}
                                    </Typography>
                                    { (total-totalPayments) === 0 ?
                                        <>
                                            <Chip label={t('Pagado')!} size="small" color="success" variant="outlined" sx={{ fontWeight: 'bold' }} />
                                        </> :
                                        <>
                                            {dayjs(creditExpirationDate).isBefore(dayjs()) ?
                                                <Chip label={t('defeated')!} size="small" color="warning" variant="outlined" sx={{ fontWeight: 'bold' }} />
                                                :
                                                <Chip label={`vence en ${dayjs(creditExpirationDate).endOf("day").diff(dayjs().startOf("day").toDate(), "days")} dias`} size="small" color="success" variant="outlined" sx={{ fontWeight: 'bold' }} />
                                            }
                                        </>
                                    }
                                </Box>
                            }
                            secondary={t('creditExpiration')}
                        />
                        <ListItemIcon>
                            <EventRounded />
                        </ListItemIcon>
                    </ListItem>
                }
                {(typePayment === "credit" || typePayment === "delivery") &&
                    <ListItem>
                        <ListItemText
                            primary={`${(total-totalPayments) === 0 ? t('paidOut') : t('pending') }`}
                            secondary={ (typePayment === "credit") ? t('creditStatus') : t('deliveryStatus')}
                        />
                        <ListItemIcon>
                            <ErrorRounded />
                        </ListItemIcon>
                    </ListItem>
                }
                {paymentCredits?.map((payment, index) => {
                    return(
                        <ListItem key={index} >
                            <ListItemText
                                primary={`$ ${currencyFormat(payment.amount)} - ${ payment.wallet ===  "consignment" ? `${payment.bank}` : t(payment.wallet)}`}
                                secondary={`${t('payment')} - ${dayjs(payment.date).format("DD-MM-YYYY HH:mm A")}`}
                            />
                            <ListItemIcon>
                                <AddCardRounded />
                            </ListItemIcon>
                        </ListItem>
                    )
                })}
                {Boolean(observation) &&
                    <ListItem>
                        <ListItemText
                            primary={`${observation}`}
                            secondary={`Observación`}
                        />
                        <ListItemIcon>
                            <SearchRounded />
                        </ListItemIcon>
                    </ListItem>
                }
            </List>
        </Box>
    )
}

export default DetailsInvoice