import Card from "./Card"
import Typography from "./Typography"

interface Props {
    primaryText?: string
    secondaryText?: string
}

const OutlinedCardQuantityData = ({ primaryText, secondaryText }:Props) => {
    return(
        <Card sx={{ maxWidth: 200, width: "100%" }} >
            <Typography lineHeight={1} textTransform={'uppercase'} fontWeight={'bold'} fontSize={'.9rem'} >
                {primaryText}
            </Typography>
            <Typography textTransform={'uppercase'} fontWeight={'bold'} fontSize={'1.2rem'} >
                {secondaryText}
            </Typography>
        </Card>
    )
}

export default OutlinedCardQuantityData