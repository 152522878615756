import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Form from '../../../components/Form';
import Grid from '../../../components/Grid';
import TextField from '../../../components/TextField';
import { useAppSelector } from '../../../hooks/state';
import { CustomerCityProps } from '../../../interfaces/Customer';

import { useCity } from '../hooks/useCity';

const initialValueOfCityForm = {
  name: '',
};

const CityForm: React.FC = () => {
  const { t } = useTranslation();

  const { CityState } = useAppSelector(({ CityState }) => ({
    CityState,
  }));
  const { isOpenForm, CityToEdit } = CityState;

  const {
    registerCityMutation,
    registerCityIsLoading,
    updateCityIsLoading,
  } = useCity();

  const [form, setForm] = useState<CustomerCityProps>(
    initialValueOfCityForm
  );

  const handleChange = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = evt.target;
    setForm((preventFormState) => ({
      ...preventFormState,
      [name]: value,
    }));
  };

  const handleSubmit = () => registerCityMutation(form)


  useEffect(() => {
    if (isOpenForm && CityToEdit._id !== '') {
      setForm((preventFormState) => ({
        ...preventFormState,
        name: CityToEdit.name,
        _id: CityToEdit?._id,
      }));
    }
  }, [isOpenForm, CityToEdit]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={1}
      >
        <Grid xs={12}>
          <TextField
            margin='none'
            label={t('nameOfTheCity')}
            name='name'
            value={form.name}
            autoFocus
            onChange={handleChange}
          />
        </Grid>
        <Grid xs={12}>
          <Button
            type='submit'
            loading={
              registerCityIsLoading || updateCityIsLoading
            }
          >
            {CityToEdit._id !== ''
              ? t('updateCity')
              : t('createCity')}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default CityForm;
