import {InputAdornment as InputAdornmentMUI, InputAdornmentProps} from '@mui/material'

const InputAdornment = ({ children, ...rest }:InputAdornmentProps) => {
    return(
        <InputAdornmentMUI {...rest} >
            {children}
        </InputAdornmentMUI>
    )
}

export default InputAdornment