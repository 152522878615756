import Dialog, { DialogContent } from "@component/Dialog"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { displayProductCreationForm } from "../services/ProductSlice"
import ProductForm from "./ProductForm"

const CreateProduct = () => {
    const { ProductState } = useAppSelector(({ ProductState }) => ({ ProductState }))
    const dispatch = useAppDispatch()
    const { openProductForm } = ProductState
    return(
        <Dialog open={openProductForm} onClose={() => dispatch(displayProductCreationForm(false))} fullWidth >
            <DialogContent dividers={true} >
                <ProductForm />
            </DialogContent>
        </Dialog>
    )
}

export default CreateProduct