import React from 'react'
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useGetInvoiceQuery, useGetSalesReportQuery } from "../../../api/querys/PointOfSale"
import { useGetSessionDataQuery } from "../../../api/querys/Users"
import Box from "@component/Box"
import Typography from "@component/Typography"
import { currencyFormat } from "../../../hooks/useNumberFormat"
import { uploadInvoicePreview, uploadUserSaleInformation } from '../services/PointOfSaleSlice'
import { useAppDispatch, useAppSelector } from '../../../hooks/state'
import Autocomplete from '../../../components/Autocomplete'
import Grid from '../../../components/Grid'
import { createFilterOptions } from '@mui/material'
import IconButton from '@component/IconButton'
import Tooltip from '@component/Tooltip'
import Stack from '@component/Stack'
import { showFormOfExpense } from '@feature/Expenses/services/ExpenseSlice'
import { useBusinessPermissions } from '@hook/usePermissions'


const PointOfSaleUserSales = () => {
    const [invoiceId, setInvoiceId] = React.useState<string | null>(null)
    const dispatch = useAppDispatch()
    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }))
    const { saleReportUser, previewInvoice } = PointOfSaleState
    const {data:dataInvoice} = useGetInvoiceQuery({ invoiceId: invoiceId! }, { skip: (invoiceId === null) })
    const { t } = useTranslation()
    const { data:sessionUser } = useGetSessionDataQuery()
    const userId = sessionUser?.payload?._id??""
    const { data } = useGetSalesReportQuery({ initDate: dayjs().format('MM-DD-YYYY'), endDate: dayjs().format('MM-DD-YYYY'), userId })
    const report = data?.payload?.report
    const sales = data?.payload?.sales
    const { PointOfSale } = useBusinessPermissions()

    React.useEffect(() => {
        let userReports = report??[]
        let userTotalSale = sales??[]
        let total = 0
        for( let sale of userTotalSale ){ total += sale.total }
        dispatch(uploadUserSaleInformation({ saleReportUser: userReports, totalSaleUser: total }))
    },[dispatch, report, sales])

    React.useEffect(() => {
        if( dataInvoice !== undefined ){
            dispatch(uploadInvoicePreview(dataInvoice.payload))
        }
    },[dataInvoice, dispatch])
    React.useEffect(() => {
        if( previewInvoice._id === null || previewInvoice._id === undefined ){
            setInvoiceId(null)
        }
    },[previewInvoice._id])
    const filterOptions = createFilterOptions({
        stringify: (option:any) => `${t(option?.customer??"")} ${option?.consecutive??0} ${dayjs(option?.createdAt??"").format("DD-MM-YYYY HH:mm:ss A")} ${currencyFormat(option?.total??0)} `,
      });
    return(
        <Box width={'100%'} >
            <Stack alignItems={'end'} >
                {PointOfSale.record_cashier_expense &&
                    <Box >
                        <Tooltip title="Registrar gasto de cajero" >
                            <IconButton size="small" onClick={() => dispatch(showFormOfExpense(true))} >
                                <Box fontSize={'2.2rem'} className='icon-moneybag' />
                            </IconButton>
                        </Tooltip>
                    </Box>
                }
            </Stack>
            <Box maxHeight={"45vh"} overflow="auto" >
                <Autocomplete
                    value={{customer: ""}}
                    filterOptions={filterOptions}
                    options={saleReportUser}
                    label="Mis ventas de hoy"
                    getOptionLabel={(params:any) => t(params?.customer??"") }
                    onChange={(_, params:any) => setInvoiceId(params?._id??"")}
                    renderOption={(props, params:any, index) =>{
                        return(
                            <li {...props} key={`${index}-${params?._id??""}`} >
                                <Grid container >
                                    <Grid xs={12} >
                                        <Typography fontSize={'.8rem'} >
                                            {t(params?.customer??"")}
                                        </Typography>
                                    </Grid>
                                    <Grid xs={12} >
                                        <Typography fontSize={'.8rem'} fontWeight={'bold'} >
                                            $ { currencyFormat(params?.total??0) }
                                        </Typography>
                                    </Grid>
                                    <Grid xs={12} >
                                        <Typography fontSize={'.8rem'} >
                                            R# {params?.consecutive??0} - {dayjs(params?.createdAt??"").format("DD-MM-YYYY HH:mm:ss A")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </li>
                        )
                    } }
                />
            </Box>
            <Typography marginTop={2} color={'GrayText'} > {saleReportUser.length} {t('salesRecordedToday')} </Typography>
        </Box>
    )
}

export default PointOfSaleUserSales