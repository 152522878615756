import dayjs from "dayjs"
import FilterByDateRange from "@component/FilterByDateRange"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { updateDateRange } from "../services/DeliveriesSlice"

const FilterDeliveries = () => {
    const dispatch = useAppDispatch()
    const { DeliveriesState } = useAppSelector(({ DeliveriesState }) => ({ DeliveriesState }))
    const { dateRange } = DeliveriesState
    const { endDate, initDate } = dateRange
    return(
        <>
            <FilterByDateRange
                endDate={ Boolean(endDate) ? dayjs(endDate).toDate() : dayjs().toDate() }
                initDate={ Boolean(initDate) ? dayjs(initDate).toDate() : dayjs().toDate() }
                onChange={({ endDate, initDate }) => dispatch(updateDateRange({ endDate: dayjs(endDate).format('MM-DD-YYYY'), initDate: dayjs(initDate).format('MM-DD-YYYY') }))}
            />
        </>
    )
}

export default FilterDeliveries