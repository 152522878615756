import {
    Alert as AlertMUI, AlertProps as AlertPropsMUI,
    AlertTitle as AlertTitleMUI, AlertTitleProps as AlertTitlePropsMUI,
} from '@mui/material'


type AlertProps = Pick<AlertPropsMUI, "children" | "variant" | "severity" | "action" | "sx" >

const Alert = ({children, ...rest}:AlertProps) => {
    return(
        <AlertMUI {...rest} >
            {children}
        </AlertMUI>
    )
}

export default Alert

type AlertTitleProps = Pick<AlertTitlePropsMUI, "children">

export const AlertTitle = ({ children }:AlertTitleProps) => {
    return(
        <AlertTitleMUI>
            {children}
        </AlertTitleMUI>
    )
}