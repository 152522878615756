import Box from "@component/Box"
import Fade from "@component/Fade"
import Grid from "@component/Grid"
import LockComponent from "@component/LockComponent"
import Tab from "@component/Tab"
import { useBusinessPermissions } from "../../hooks/usePermissions"
import DataEmployee from "./components/DataEmployee"
import EditEmployee from "./components/EditEmployee"
import EmployeeSettings from "./components/EmployeeSettings"
import PaySheet from "./components/PaySheet"

const EmployeeData = () => {
    const { Team } = useBusinessPermissions()
    return(
        <Fade in={true} >
            {Team.lock_module ?
                <Box pt={1} >
                    <Grid container >
                        <Grid xs={7} >
                            <Tab
                                TabItem={['Información', 'Editar información', 'Ajustes del empleado', 'Ajustes de nomina']}
                                TabRender={[<DataEmployee />, <EditEmployee />, <EmployeeSettings />, <PaySheet />]}
                            />
                        </Grid>
                        <Grid xs={5} >

                        </Grid>
                    </Grid>
                </Box>
                :
                <LockComponent />
            }
        </Fade>
    )
}

export default EmployeeData