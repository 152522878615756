import Autocomplete from "@component/Autocomplete"
import { useURLParams } from "@hook/useURLParams"
import { useGetCategoriesProductQuery } from "@query/Product"
import { useTranslation } from "react-i18next"

type AutocompleteProps = Pick<React.ComponentProps<typeof Autocomplete>, "onChange" | "label" | "value" | "sx">

const MultipleProductCategoriesInput = ({...rest}:AutocompleteProps) => {
    const { getParameterGroup } = useURLParams()
    let { categories="" } = getParameterGroup(["categories"])
    categories = categories.split("-")
    const { t } = useTranslation()
    const { data } = useGetCategoriesProductQuery()
    const productCategories = data?.payload?.productCategories?.results??[]
    const values = productCategories.filter(n => categories.some((x:string) => x === n.name))

    return(
        <>
            <Autocomplete
                value={values}
                ChipProps={{
                    size:"small",
                    color: "primary",
                    variant: "outlined"
                }}
                label={t('categories')!}
                options={productCategories}
                multiple
                {...rest}
            />
        </>
    )
}

export default MultipleProductCategoriesInput