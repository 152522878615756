import { GridColDef } from "@mui/x-data-grid"
import { t } from "i18next"
import { useGetClosingOfDayQuery } from "../../../api/querys/PointOfSale"
import Box from "@component/Box"
import DataGrid, { GridToolbarContainer } from "@component/DataGrid"
import Typography from "@component/Typography"
import { useAppSelector } from "../../../hooks/state"
import { currencyFormat } from "../../../hooks/useNumberFormat"

const SaleBranchTable = () => {
    const { ClosingOfTheDayState } = useAppSelector(({ ClosingOfTheDayState }) => ({ClosingOfTheDayState}))
    const { dateClosingOfDay } = ClosingOfTheDayState
    const { data } = useGetClosingOfDayQuery({ closingDate: dateClosingOfDay })
    const branchSales = data?.payload.branchSales
    const branchSalesList = branchSales??[]
    
    const columns:Array<GridColDef> = [
        {flex: 1, field: "_id", headerName: t('Sucursal')!},
        { field: "total", headerName: t('Total')!, maxWidth: 120, minWidth: 120, renderCell: ({row}) => <>$ { currencyFormat(row.total) } </> },
        {field: "totalProfitCurrency", headerAlign: 'center', headerName: t('$')!, maxWidth: 120, minWidth: 120, renderCell: ({row}) => <>$ { currencyFormat(row.totalProfitCurrency) }</>},
        {field: "totalProfitPercentage", headerAlign: 'center', headerName: t('%')!, maxWidth: 80, minWidth: 80, renderCell: ({row}) => <> { row.totalProfitPercentage } %</>},
    ]
    const HeaderDataGrid = () => {
        return(
            <GridToolbarContainer>
                <Box p={2} >
                    <Typography textTransform={'uppercase'} fontWeight="bold" >{t('Sucursales')}</Typography>
                    <Typography fontSize={'.9rem'} textTransform={'uppercase'} fontWeight="bold" >{t('total')}: $ {currencyFormat(branchSalesList.reduce((acc, params) => (acc+params?.total??0),0))}</Typography>
                </Box>
            </GridToolbarContainer>
        )
    }
    return(
        <Box mb={2}>
            <DataGrid
                slots={{
                    toolbar: HeaderDataGrid
                }}
                rows={branchSalesList}
                columns={columns}
            />
        </Box>
    )
}

export default SaleBranchTable