import dayjs from "dayjs"
import FilterByDateRange from "@component/FilterByDateRange"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { updateDateRange, updateSelectedCustomerId } from "../services/CreditsSlice"
import Stack from "@component/Stack"
import SearchCustomer from "@feature/Customer/components/SearchCustomer"
import Checkbox from "@component/Checkbox"
import { useURLParams } from "@hook/useURLParams"
import SearchCustomerNumberDocument from "@feature/Customer/components/SearchCustomerNumberDocument"
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import IconButton from "@component/IconButton"
import Box from "@component/Box"
import { useGetCreditReport } from "../hooks/useCredits"
import { useSheet } from '@hook/useSheet'
import { useTranslation } from "react-i18next"
import { currencyFormat } from "@hook/useNumberFormat"

const FilterCredits = () => {
    const { t } = useTranslation()
    const { addQuery, getQuery, delQueries } = useURLParams()
    const creditsQuery = getQuery("credits")
    const groupByQuery = getQuery("groupBy")
    const dispatch = useAppDispatch()
    const { CreditsState } = useAppSelector(({ CreditsState }) => ({ CreditsState }))
    const { dateRange, customerId } = CreditsState
    const { endDate, initDate } = dateRange
    const { credits } = useGetCreditReport({ endDate, startDate: initDate, customerId })
    const { useCreateSheet:createSheet } = useSheet()
    const columnsExcel:any[] = ['Vendedor', t('customer'), t('total'), t('consecutive'), t('payments'), t('balance'), 'Fecha de facturación', t('creditExpirationDate')]
    const dataExcel:any[] = [...credits].map((n:any) => ({seller: n.cashier, customer: n.customer, total: `$ ${currencyFormat(n.total)}`, consecutive: n.consecutive, payments: `$ ${currencyFormat(n.payments)}`, balance: `$ ${currencyFormat(n.balance)}`, createdAt: dayjs(n.createdAt).format("DD-MM-YYYY"), creditExpirationDate: dayjs(n.creditExpirationDate).format("DD-MM-YYYY")}))
    
    return(
        <>
            <Stack direction={'row'} spacing={1} >
                <FilterByDateRange
                    initDate={ Boolean(initDate) ? dayjs(initDate).toDate() : dayjs().toDate() }
                    endDate={ Boolean(endDate) ? dayjs(endDate).toDate() : dayjs().toDate() }
                    onChange={({ endDate, initDate }) => dispatch(updateDateRange({ endDate: dayjs(endDate).format("MM-DD-YYYY"), initDate: dayjs(initDate).format("MM-DD-YYYY") }))}
                />
                <SearchCustomer addButton={false} sx={{ width: 240 }} onChange={(_, params:any) => dispatch(updateSelectedCustomerId(params?._id??""))} />
                <SearchCustomerNumberDocument onChange={(_, params:any) => Boolean(params?.identificationNumber) ? addQuery({ numberDocument: params?.identificationNumber??"" }) : delQueries(["numberDocument"]) } />
            </Stack>
            <Stack direction={'row'} spacing={1} alignItems={'center'} >
                <Box>
                    <IconButton squareButton onClick={() => createSheet({ columns:columnsExcel, rows: dataExcel, fileName: "creditos.xlsx" })} >
                        <DownloadRoundedIcon />
                    </IconButton>
                </Box>
                <Checkbox
                    checked={(creditsQuery === "pending")}
                    label="Pendientes"
                    onChange={() => (creditsQuery === "pending") ? delQueries(['credits']) : addQuery({ credits: "pending" })}
                />
                <Checkbox
                    checked={(creditsQuery === "payments")}
                    label="Pagados"
                    onChange={() => (creditsQuery === "payments") ? delQueries(['credits']) : addQuery({ credits: "payments" })}
                />
                <Checkbox
                    checked={(creditsQuery === "beaten")}
                    label="Vencidos"
                    onChange={() => (creditsQuery === "beaten") ? delQueries(['credits']) : addQuery({ credits: "beaten" })}
                />
                <Checkbox
                    checked={(creditsQuery === "valid")}
                    label="Vigentes"
                    onChange={() => (creditsQuery === "valid") ? delQueries(['credits']) : addQuery({ credits: "valid" })}
                />
                <Checkbox
                    checked={(groupByQuery === "customer")}
                    label="Agrupar clientes"
                    onChange={() => (groupByQuery === "customer") ? delQueries(['groupBy']) : addQuery({ groupBy: "customer" })}
                />
            </Stack>
        </>
    )
}

export default FilterCredits