import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useAddPaymentToInvoiceMutation, useArchiveInvoiceMutation, useChangePaymentMethodMutation, useDeletePaymentToInvoiceMutation } from '../../../api/querys/PointOfSale';
import { useAppDispatch } from '../../../hooks/state';
import { InvoiceProps } from '../../../interfaces/PointOfSale';
import { newChangeInvoicePaymentStatus } from '../../../services/socket';
import { Payment } from '../components/SubscriptionForm';
import { showPaymentMethodForm } from '../service/InvoiceSlice';
import { useUpdateConsecutiveElectronicInvoiceMutation } from '@query/Invoice';
import { UpdateConsecutiveElectronicInvoiceFormProps } from '@interface/Invoice';
import { isSuccessResponse, isErrorResponse } from '@service/AnswersServices';
import { useURLParams } from '@hook/useURLParams';

export const useInvoice = () => {
    const { delQueries } = useURLParams()
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [deletePaymentToInvoice] = useDeletePaymentToInvoiceMutation();
    const [addPaymentToInvoiceMutation, { isLoading: isLoadingAddPaymentToInvoiceMutation },] = useAddPaymentToInvoiceMutation();
    const [changePaymentMethodCashOrCreditMutation] = useChangePaymentMethodMutation();
    const [archiveInvoiceMutation, { isLoading: isLoadingArchiveInvoice }] = useArchiveInvoiceMutation();
    const [updateConsecutiveElectronicInvoiceMutation, { isLoading: isLoadingUpdateConsecutiveElectronicInvoiceMutation }] = useUpdateConsecutiveElectronicInvoiceMutation();

    const deletePaymentCredit = async (payload: { invoiceId: string; payment: number; }, closeWindow?: () => void) => {
        try {
            const resp = await deletePaymentToInvoice(payload).unwrap();
            if (resp.status === 1) {
                enqueueSnackbar(t('compostRemovedSuccessfully'), { variant: 'success', });
                if (closeWindow !== undefined) closeWindow()
            } else {
                enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'), {
                    variant: 'error',
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
    const addPaymentToInvoice = async (payload: Payment & { invoiceId: string }, closeWindow?: () => void) => {
        try {
            if (payload.amount === undefined || payload.amount <= 0) {
                enqueueSnackbar(t('theCreditAmountMustBeGreaterThan'), {
                    variant: 'warning',
                });
                return;
            }
            if (payload.wallet === 'consignment' && payload.bank.trim() === '') {
                enqueueSnackbar(t('consignmentsMustBeAssignedToBank'), { variant: 'warning' });
                return;
            }
            if (payload.invoiceId.trim() === '' || !Boolean(payload.invoiceId)) {
                enqueueSnackbar(t('anImportantPieceOfInformationIsMissingToGenerateTheRequest'), { variant: 'warning' });
                return;
            }
            const resp = await addPaymentToInvoiceMutation(payload).unwrap();
            if (resp.status === 1) {
                enqueueSnackbar(t('addedYearSuccessfully'), { variant: 'success' });
                if (closeWindow !== undefined) closeWindow()
            } else {
                enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'), {
                    variant: 'warning',
                });
            }
        } catch (error) {
            console.log(error);
        }
    };
    const changePaymentMethod = async (typePayment: InvoiceProps['typePayment'], payload: { invoiceId: string; deliveryName?: InvoiceProps['deliveryName']; deliveryAmount?: InvoiceProps['deliveryAmount']; creditExpirationDate?: InvoiceProps['creditExpirationDate']; bank?: string; observation: string }) => {
        try {
            const resp = await changePaymentMethodCashOrCreditMutation({ ...payload, typePayment }).unwrap();
            if (resp.status === 1) {
                newChangeInvoicePaymentStatus(payload?.invoiceId);
                enqueueSnackbar(t('invoicePaymentTypeUpdatedSuccessfully'), { variant: 'success', });
                dispatch(showPaymentMethodForm(false));
            } else {
                isErrorResponse(resp?.payload?.key??"");
            }
        } catch (error) {
            console.log(error);
        }
    };
    const changeStatusArchivedInvoice = async ({ invoiceId, bank, box }: { invoiceId: string, bank: string | null, box: string | null }) => {
        try {
            const resp = await archiveInvoiceMutation({ invoiceId, bank, box }).unwrap()
            if (resp.status === 1) {
                if (Boolean(resp?.payload?.isArchived)) {
                    enqueueSnackbar("La factura se archivo satisfactoriamente", { variant: 'success' })
                } else {
                    enqueueSnackbar("La factura se recupero satisfactoriamente", { variant: 'success' })
                }
            } else {
                enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest'), { variant: 'error' });
            }
        } catch (error) {
            console.log(error)
        }
    }
    const updateConsecutiveElectronicInvoice = async (params: UpdateConsecutiveElectronicInvoiceFormProps) => {
        try {
            const resp = await updateConsecutiveElectronicInvoiceMutation(params).unwrap()
            if (resp.status === 1) {
                isSuccessResponse("Se actualizo con éxito el consecutivo de factura electronica")
                delQueries(["window"])
            } else {
                isErrorResponse()
            }
        } catch (error) {
            console.log(error)
        }
    }
    return {
        deletePaymentCredit,
        addPaymentToInvoice,
        isLoadingAddPaymentToInvoiceMutation,
        changePaymentMethod,
        changeStatusArchivedInvoice,
        isLoadingArchiveInvoice,
        isLoadingUpdateConsecutiveElectronicInvoiceMutation,
        updateConsecutiveElectronicInvoice
    };
};
