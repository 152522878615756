import React from 'react'
import Box from "./Box"
import Card from "./Card"
import Divider from "./Divider"
import Typography from "./Typography"
import { styled } from '@mui/material/styles'
import { TypographyProps } from '@mui/material'
import dayjs from 'dayjs'
import ChevronLeftRounded from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRounded from '@mui/icons-material/ChevronRightRounded';
import IconButton from './IconButton'
import { useTranslation } from 'react-i18next'
import CircularProgress from './CircularProgess'

interface Props {
    headerVariant?: "text" | "date"
    text?: string
    icon?: React.ReactNode
    headerText?: string
    typographyProps?: TypographyProps
    onChangeDate?: (date:Date) => void
    slots?: {
        cardBody?: React.ReactNode
        weekday?: React.ReactNode
    }
    loading?: boolean,
    onClick?: () => void
}

const IconContainer = styled(Box)(({ theme }) => ({
    textAlign: "center",
    'svg' : {
        color: theme.palette.grey[700],
        fontSize: "2rem"
    }
}))

const DataCard = (props:Props) => {
    const { headerVariant="text", text, icon, headerText, typographyProps, onChangeDate, slots, loading, onClick } = props

    const [ date, setDate ] = React.useState<Date>( dayjs().toDate() )
    const { t } = useTranslation()
    const TextHeaderVariant = () => <Typography py={1} textAlign={'center'} textTransform={'uppercase'} fontWeight={'bold'} > {headerText} </Typography>
    const DateHeaderVariant = () => {
        const handleChange = (date:Date) => {
            if( onChangeDate !== undefined ){
                onChangeDate(date)
            }
        }
        const handleAddDay = () => {
            const newDay = dayjs(date).add(1, "day").toDate()
            setDate(newDay)
            handleChange(newDay)
        }
        const handleSubtractDay = () => {
            const newDay = dayjs(date).subtract(1, "day").toDate()
            setDate(newDay)
            handleChange(newDay)
        }
        return(
            <>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
                <Box>
                    <IconButton size='small' onClick={handleSubtractDay} >
                        <ChevronLeftRounded />
                    </IconButton>
                </Box>
                <Box>
                    <Typography pt={1} textAlign={'center'} fontWeight={'bold'} fontSize={'.875rem'} textTransform={'uppercase'} >
                        
                        { slots?.weekday !== undefined ? slots.weekday : t(`day_${dayjs(date).day()}`) }
                    </Typography>
                    <Typography lineHeight={1} pb={1} textAlign={'center'} fontWeight={'bold'} >
                        {t("fullDate", {
                            day: dayjs(date).format("DD"),
                            month: t(`month_${dayjs(date).month()}`),
                            year: dayjs(date).format("YYYY"),
                        })}
                    </Typography>
                </Box>
                <Box>
                    <IconButton size='small' onClick={handleAddDay} disabled={dayjs().format("DD-MM-YYYY") === dayjs(date).format("DD-MM-YYYY")} >
                        <ChevronRightRounded />
                    </IconButton>
                </Box>
            </Box>
            </>
        )
    }
    const handleClick = () => {
        if( onClick !== undefined ) onClick()
    }
    return(
        <>
            <Card variant="elevation" elevation={3} sx={{ padding: 0, height: "100%", maxHeight: 200, ...(onClick !== undefined ? {cursor: "pointer"} : {}) }} onClick={handleClick} >
                { headerVariant === "text" && <TextHeaderVariant /> }
                { headerVariant === "date" && <DateHeaderVariant /> }
                <Divider />
                <Box padding={4} sx={{ position: "relative" }} >
                    { icon !== undefined &&
                        <IconContainer textAlign={'center'} >
                            {icon}
                        </IconContainer>
                    }

                    {loading ?
                        <CircularProgress />
                        :
                        <>
                            { !Boolean(slots?.cardBody) && <Typography textAlign={'center'} fontWeight={'bold'} fontSize={'1.3rem'} {...typographyProps} > {text} </Typography> }
                            { slots !== undefined &&
                                <>
                                    { slots.cardBody }
                                </>
                            }
                        </>
                    }
                </Box>
            </Card>
        </>
    )
}

export default DataCard