import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import type { PayloadAction } from '@reduxjs/toolkit'

interface stateProps {
    workDays: Array<{
        day: "day_0" | "day_1" | "day_2" | "day_3" | "day_4" | "day_5" | "day_6"
        active: boolean
        firstWorkingDay: {
            startOfActivity: Date
            finishActivity: Date
        }
        secondWorkingDay: {
            startOfActivity: Date
            finishActivity: Date
        }
    }>
    overtimePercentages: {
        extraDaytime: number
        extraNightWork: number
        extraDayWork: number
        extraNightWorkOnSundaysAndHolidays: number
        nightWork: number
        sunDayAndHolidayWork: number
        nightWorkOnSundayAndHolidays: number
    }
    daytimeLaborDay: {
        initHours: string
        endHours: string
    }
    nightLaborDay: {
        initHours: string
        endHours: string
    }
}

const firstWorkingDayStartOfActivity = dayjs().hour(8).minute(0).second(0).toDate()
const firstWorkingDayFinishActivity = dayjs().hour(12).minute(0).second(0).toDate()
const secondWorkingDayStartOfActivity = dayjs().hour(13).minute(0).second(0).toDate()
const secondWorkingDayFinishActivity = dayjs().hour(17).minute(0).second(0).toDate()

const initialState:stateProps = {
    overtimePercentages: {
        extraDaytime: 25,
        extraNightWork: 75,
        extraDayWork: 100,
        extraNightWorkOnSundaysAndHolidays: 150,
        nightWork: 35,
        sunDayAndHolidayWork: 75,
        nightWorkOnSundayAndHolidays: 110,
    },
    daytimeLaborDay: {
        initHours: '06:00',
        endHours: '21:00'
    },
    nightLaborDay: {
        initHours: '21:00',
        endHours: '06:00'
    },
    workDays: [
        { day: "day_1", active: true, firstWorkingDay: { startOfActivity: firstWorkingDayStartOfActivity, finishActivity: firstWorkingDayFinishActivity }, secondWorkingDay: {startOfActivity: secondWorkingDayStartOfActivity, finishActivity: secondWorkingDayFinishActivity} },
        { day: "day_2", active: true, firstWorkingDay: { startOfActivity: firstWorkingDayStartOfActivity, finishActivity: firstWorkingDayFinishActivity }, secondWorkingDay: {startOfActivity: secondWorkingDayStartOfActivity, finishActivity: secondWorkingDayFinishActivity} },
        { day: "day_3", active: true, firstWorkingDay: { startOfActivity: firstWorkingDayStartOfActivity, finishActivity: firstWorkingDayFinishActivity }, secondWorkingDay: {startOfActivity: secondWorkingDayStartOfActivity, finishActivity: secondWorkingDayFinishActivity} },
        { day: "day_4", active: true, firstWorkingDay: { startOfActivity: firstWorkingDayStartOfActivity, finishActivity: firstWorkingDayFinishActivity }, secondWorkingDay: {startOfActivity: secondWorkingDayStartOfActivity, finishActivity: secondWorkingDayFinishActivity} },
        { day: "day_5", active: true, firstWorkingDay: { startOfActivity: firstWorkingDayStartOfActivity, finishActivity: firstWorkingDayFinishActivity }, secondWorkingDay: {startOfActivity: secondWorkingDayStartOfActivity, finishActivity: secondWorkingDayFinishActivity} },
        { day: "day_6", active: false, firstWorkingDay: { startOfActivity: firstWorkingDayStartOfActivity, finishActivity: firstWorkingDayFinishActivity }, secondWorkingDay: {startOfActivity: secondWorkingDayStartOfActivity, finishActivity: secondWorkingDayFinishActivity} },
        { day: "day_0", active: false, firstWorkingDay: { startOfActivity: firstWorkingDayStartOfActivity, finishActivity: firstWorkingDayFinishActivity }, secondWorkingDay: {startOfActivity: secondWorkingDayStartOfActivity, finishActivity: secondWorkingDayFinishActivity} },
    ]
}

export const PaySheetSlice = createSlice({
    name: 'paySheet',
    initialState,
    reducers: {
        toggleWorkDay: (state, action:PayloadAction<{ isActive: boolean, day: stateProps['workDays'][0]['day'] }>) => {
            const workDays = [...state.workDays]
            const workDayIndex = workDays.findIndex(n => n.day === action.payload.day)
            if( workDayIndex >= 0 ){
                workDays[workDayIndex].active = action.payload.isActive
                state.workDays = workDays
            }
        },
        updateFirstWorkingDay: (state, action:PayloadAction<{ day: stateProps['workDays'][0]['day'], time: "startTime" | "endTime", value: Date }>) => {
            const workDays = [...state.workDays]
            const workDayIndex = workDays.findIndex(n => n.day === action.payload.day)
            if( workDayIndex >= 0 ){
                if( action.payload.time === "startTime" ) workDays[workDayIndex].firstWorkingDay.startOfActivity = action.payload.value
                if( action.payload.time === "endTime" ) workDays[workDayIndex].firstWorkingDay.finishActivity = action.payload.value
                state.workDays = workDays
            }
        },
        updateSecondWorkingDay: (state, action:PayloadAction<{ day: stateProps['workDays'][0]['day'], time: "startTime" | "endTime", value: Date }>) => {
            const workDays = [...state.workDays]
            const workDayIndex = workDays.findIndex(n => n.day === action.payload.day)
            if( workDayIndex >= 0 ){
                if( action.payload.time === "startTime" ) workDays[workDayIndex].secondWorkingDay.startOfActivity = action.payload.value
                if( action.payload.time === "endTime" ) workDays[workDayIndex].secondWorkingDay.finishActivity = action.payload.value
                state.workDays = workDays
            }
        }
    }
})

export const {
    toggleWorkDay,
    updateFirstWorkingDay,
    updateSecondWorkingDay,
} = PaySheetSlice.actions
export default PaySheetSlice.reducer