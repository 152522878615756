import React from "react"
import Switch from "@component/Switch"
import { useInvoice } from "../hooks/useInvoice"
import Dialog, { DialogActions, DialogContent } from "@component/Dialog"
import BoxPayments, { onChangeEventBoxResult } from "@feature/Business/components/BoxPayments"
import Button from "@component/Button"
import Alert from "@component/Alert "
import Grid from "@component/Grid"

interface Props {
    isArchived : boolean
    invoiceId: string
    typePayment: string
    total: number
}

const SwitchArchiveInvoice = ({ isArchived, invoiceId, typePayment, total }:Props) => {
    const [box, setBox] = React.useState<{ box: string | null, bank: string | null }>({ bank: null, box: null })
    const [isOpenSelectedBox, setIsOpenSelectedBox] = React.useState<boolean>(false)
    const { changeStatusArchivedInvoice, isLoadingArchiveInvoice } = useInvoice()

    const handleArchiveInvoice = () => {
        if( typePayment === "undefined" ){
            changeStatusArchivedInvoice({ invoiceId, bank: null, box: null })
        }else{
            setIsOpenSelectedBox(true)
        }
    }

    const handleSelectedBox = (params:(onChangeEventBoxResult | null)) => {
        if( params !== null ){
            setBox(n => ({...n, bank: params.bank, box: params.box}))
        }else{
            setBox(n => ({...n, bank: null, box: null}))
        }
    }

    return(
        <>
            <Switch
                disabled={isLoadingArchiveInvoice}
                checked={isArchived}
                onChange={handleArchiveInvoice}
                label='Archivar factura'
            />
            <Dialog fullWidth maxWidth="sm" open={isOpenSelectedBox} onClose={() => setIsOpenSelectedBox(false)} >
                <DialogContent>
                    <Grid container spacing={1} >
                        <Grid xs={12} >
                            <BoxPayments maxPayments={1} defaultValue={total} onChange={(val) => handleSelectedBox(val[0] || null)} />
                        </Grid>
                        <Grid xs={12} >
                            <Alert severity="warning">
                                Esta factura ya cuenta con un medio de pago asignado, seleccione la caja { !isArchived ? `de donde se descontara el valor de la factura` : `a donde se cargara el valor de la factura` }
                            </Alert>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button loading={isLoadingArchiveInvoice} variant="text" onClick={() => changeStatusArchivedInvoice({ invoiceId, bank: box.bank, box: box.box })} >
                    {!isArchived ? `Archivar` : "Recuperar"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SwitchArchiveInvoice