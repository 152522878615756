import { useTranslation } from 'react-i18next';

import { useSnackbar } from 'notistack';


import { CustomerBusinessesTypeProps } from '../../../interfaces/Customer';
import { useDeleteCustomersBusinessesTypeMutation, useRegisterCustomersBusinessesTypeMutation, useUpdateCustomersBusinessesTypeMutation } from '../../../api/querys/Customer';

export const useBusinessesType = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [registerBusinessesType, { isLoading: registerBusinessesTypeIsLoading }] =
    useRegisterCustomersBusinessesTypeMutation();
  const [updateBusinessesType, { isLoading: updateBusinessesTypeIsLoading }] =
    useUpdateCustomersBusinessesTypeMutation();
  const [deleteBusinessesType, { isLoading: deleteBusinessesTypeIsLoading }] =
    useDeleteCustomersBusinessesTypeMutation();

  const registerBusinessesTypeMutation = async (
    payload: CustomerBusinessesTypeProps
  ) => {
    if (payload.name.trim() === '') {
      enqueueSnackbar(t('BusinessTypeNameIsARequiredField'), {
        variant: 'warning',
      });
      return;
    }
    if (Boolean(payload._id) && payload.name.trim() === '') {
      enqueueSnackbar(
        t(
          'itSeemsThatAnImportantPieceOfInformationIsMissingToUpdateTheProduct'
        )!,
        { variant: 'error' }
      );
      return;
    }
    const resp = Boolean(payload._id)
      ? await updateBusinessesType(payload).unwrap()
      : await registerBusinessesType(payload).unwrap();
    if (resp?.status === 1) {
      const message = Boolean(payload._id)
        ? t('theBusinessesTypeWasSuccessfullyUpdated')
        : t('theBusinessesTypeWasRegisteredSuccessfully')!;
      enqueueSnackbar(message, { variant: 'success' });
    } else {
      enqueueSnackbar(t('anErrorOccurredWhileTryingToProcessTheRequest')!, {
        variant: 'error',
      });
    }
  };

  const deleteBusinessesTypeMutation = async (packaging: string[]) => {
    if (packaging.length <= 0) {
      enqueueSnackbar(t('selectAtLeastBusinessesTypeToRemove')!, {
        variant: 'warning',
      });
      return;
    }
    const resp = await deleteBusinessesType(packaging).unwrap();
    if (resp.status === 1) {
      enqueueSnackbar(t('BusinessesTypeWereSuccessfullyRemoved')!, {
        variant: 'success',
      });
      return;
    } else {
      enqueueSnackbar(
        t(
          'itSeemsThatAnImportantPieceOfInformationIsMissingToUpdateTheProduct'
        )!,
        { variant: 'error' }
      );
      return;
    }
  };
  return {
    registerBusinessesTypeMutation,
    deleteBusinessesTypeMutation,
    registerBusinessesTypeIsLoading,
    updateBusinessesTypeIsLoading,
    deleteBusinessesTypeIsLoading,
  };
};
