import AddShoppingCartRounded from "@mui/icons-material/AddShoppingCartRounded"
import HighlightOffRounded from "@mui/icons-material/HighlightOffRounded"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import Box from "../../../../../components/Box"
import IconButton from "../../../../../components/IconButton"
import Typography from "../../../../../components/Typography"
import { useAppDispatch, useAppSelector } from "../../../../../hooks/state"
import { currencyFormat } from "../../../../../hooks/useNumberFormat"
import { ListProductsInvoice } from "@interface/PointOfSale"
import { showPOSProductWindow } from "../../../services/PointOfSaleSlice"


interface Props {
    editProduct: ListProductsInvoice | null
    addProductToTheList: () => void
}

const WindowProductPOSHeader = ({ editProduct, addProductToTheList }:Props) => {
    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }))
    const { productList } = PointOfSaleState
    const { productSelected } = PointOfSaleState.windowProduct
    const product = {...productSelected}
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const { t } = useTranslation()
    let itemInList:any = productList.find((n) => (n?._id??"") === (product?._id??""))
    itemInList = Boolean(itemInList)

    return(
        <Box display={'flex'} justifyContent={'space-between'} alignItems="center" sx={(itemInList ? { backgroundColor: theme.palette.error.main, color: theme.palette.error.contrastText , borderRadius: 8 } : {} )} >
            <Box>
                <IconButton sx={(itemInList ? { color: theme.palette.success.contrastText } : {} )} tabIndex={-1} color="success" onClick={() => addProductToTheList()} >
                    <AddShoppingCartRounded />
                </IconButton>
            </Box>
            <Typography fontWeight={'bold'} width={1} textAlign={'center'} lineHeight={'1'} >
                {product?.name??""} <br />
                {t('cost')}: $ { currencyFormat(product?.cost??0) }
            </Typography>
            <Box>
                <IconButton sx={(itemInList ? { color: theme.palette.error.contrastText } : {} )} tabIndex={-1} color="error" onClick={() => dispatch(showPOSProductWindow({ isOpen: false, productSelected: undefined }))} >
                    <HighlightOffRounded />
                </IconButton>
            </Box>
        </Box>
    )
}

export default WindowProductPOSHeader