import React from 'react'
import Switch from "@component/Switch"
import { useAppDispatch, useAppSelector } from '../../../hooks/state'
import { changeInvoiceFormatMode } from '../../../services/AppSlice'
import { setInvoiceMode } from "../../../services/localStorage"

const SwitchInvoiceFormat = () => {
    const dispatch = useAppDispatch()
    const { AppState } = useAppSelector(({ AppState }) => ({ AppState }))
    const { invoiceMode } = AppState

    const value = React.useMemo(() => {
        setInvoiceMode(invoiceMode)
        return invoiceMode === "pos"
    },[invoiceMode])

    return(
        <>
            <Switch
                onChange={({ target }) => dispatch(changeInvoiceFormatMode( target.checked ? 'pos' : 'large' ))}
                label='Formato POS'
                checked={value}
            />
        </>
    )
}

export default SwitchInvoiceFormat