import Autocomplete from "@component/Autocomplete"
import Box from "@component/Box"
import { useAppDispatch, useAppSelector } from "@hook/state"
import { useProductsForSupplierPurchases } from "../hooks/usePurchasing"
import { updateSelectedProduct, updateValueOfCarrier } from "../services/PurchasesSuppliersSlice"
import TextField from "@component/TextField"

const SearchProductsForShopping = () => {
    const dispatch = useAppDispatch()
    const { products, isLoading } = useProductsForSupplierPurchases()
    const { PurchasesSuppliersState } = useAppSelector(({ PurchasesSuppliersState }) => ({ PurchasesSuppliersState }))
    const { haulier, productsSelectedList } = PurchasesSuppliersState
    let totalFreight = productsSelectedList.reduce((acc, val) => ((val?.freight??0)+acc),0)
    return(
        <Box mb={1} display={'flex'} gap={1} >
            <Autocomplete
                onChange={(_, params:any) => dispatch(updateSelectedProduct(params))}
                value={null}
                label="Productos"
                loading={isLoading}
                options={products}
            />
            {(totalFreight >= 0.1) &&
                <Box>
                    <TextField label="Transportador" margin="none" value={haulier} onChange={({ target }) => dispatch( updateValueOfCarrier(target.value) )} />
                </Box>
            }
        </Box>
    )
}

export default SearchProductsForShopping