import React from 'react'
import { Unstable_Grid2 as GridMUI, Grid2Props } from '@mui/material'

const Grid = React.forwardRef<HTMLDivElement, Grid2Props>(({children, ...rest}, ref) => {
    return(
        <GridMUI ref={ref} {...rest} >
            {children}
        </GridMUI>
    )
})

export default Grid