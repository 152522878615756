import Fade from "@component/Fade"
import Grid from "@component/Grid"
import LockComponent from "@component/LockComponent"
import { useBusinessPermissions } from "../../hooks/usePermissions"
import CreateProvider from "./components/CreateProvider"
import ProviderNavbar from "./components/ProviderNavbar"
import MovementsSuppliersTab from "./components/MovementsSuppliersTab"
import Title from "@component/Title"

const Provider = () => {
    const { Provider } = useBusinessPermissions()
    return(
        <Fade in={true} >
            {Provider.lock_module ?
                <Grid container spacing={2} >
                    <Grid xs={12} >
                        <Title text="Proveedores" />
                    </Grid>
                    <Grid xs={12} >
                        <ProviderNavbar />
                    </Grid>
                    <Grid xs={12} >
                        <MovementsSuppliersTab />
                    </Grid>
                    <CreateProvider />
                </Grid>
                :
                <LockComponent />
            }
        </Fade>
    )
}

export default Provider