import Fade from "@component/Fade"
import FilterByDateRange from "@component/FilterByDateRange"
import Grid from "@component/Grid"
import Stack from "@component/Stack"
import { useAppDispatch, useAppSelector } from "@hook/state"
import CashierMovementList from "./components/CashierMovementList"
import ExpenseListCashier from "./components/ExpenseListCashier"
import ListNotesCreditsCashier from "./components/ListNotesCreditsCashier"
import ListOfPaymentsReceivedCashier from "./components/ListOfPaymentsReceivedCashier"
import ListOfSalesByConsignmentsReceived from "./components/ListOfSalesByConsignmentsReceived"
import PaymentsReceivedByConsignments from "./components/PaymentsReceivedByConsignments"
import SalesReportCashier from "./components/SalesReport"
import { updateCashierMovementDates } from "./services/CashierMovementsSlice"

const MovementsCashier = () => {
    const dispatch = useAppDispatch()
    const { CashierMovementsState } = useAppSelector(({ CashierMovementsState }) => ({ CashierMovementsState }))
    const { filterMovements } = CashierMovementsState
    const { startDate, endDate } = filterMovements
    return(
        <Fade in={true} >
            <Grid container spacing={2} >
                <Grid xs={12} >
                    <FilterByDateRange onChange={({ initDate, endDate }) => dispatch(updateCashierMovementDates({ startDate: initDate, endDate }))} initDate={startDate as Date} endDate={endDate as Date} />
                </Grid>
                <Grid xs={8} >
                    <Stack spacing={1} >
                        <CashierMovementList />
                        <ListOfSalesByConsignmentsReceived />
                        <ListOfPaymentsReceivedCashier />
                        <PaymentsReceivedByConsignments />
                        <ExpenseListCashier />
                        <ListNotesCreditsCashier />
                    </Stack>
                </Grid>
                <Grid xs={4} >
                    <SalesReportCashier />
                </Grid>
            </Grid>
        </Fade>
    )
}

export default MovementsCashier