import { GridColDef } from "@mui/x-data-grid"
import { useTranslation } from "react-i18next"
import { useGetCategoriesProductQuery } from "../../../api/querys/Product"
import DataGrid from "@component/DataGrid"
import DataGridCustomHeaderToDelete from "@component/DataGridCustomHeaderToDelete"
import EditRounded from '@mui/icons-material/EditRounded';
import IconButton from "@component/IconButton"
import { loadProductCategoryToEdit, selectCategoriesToDelete, showAlertDeleteCategory } from "../services/ProductCategorySlice"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import AlertDeleteCategoryProduct from "./AlertDeleteCategoryProduct"

const CategoryProductsList = () => {
    const { ProductCategoryState } = useAppSelector(({ ProductCategoryState }) => ({ProductCategoryState}))
    const { selectionToDelete } = ProductCategoryState
    const { data, isLoading } = useGetCategoriesProductQuery()
    const productCategories = data?.payload?.productCategories?.results??[]
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const columns:GridColDef[] = [
        { field:'name', headerName: t('categoryName')!, flex: 1 },
        { field:'products', headerName: t('quantityOfAssignedProducts')!, flex: 1 },
        { field:' ', headerName: " ", align: "center", maxWidth: 80, minWidth: 80, renderCell: ({row}) => {
            return(
                <IconButton onClick={() => dispatch(loadProductCategoryToEdit(row))} >
                    <EditRounded />
                </IconButton>
            )
        } },
    ]
    const HeaderDataGrid = () => <DataGridCustomHeaderToDelete listItems={selectionToDelete} texts={[t('selectedProductCategories')!, t('selectedProductCategory')!]} title="" onClick={() => dispatch(showAlertDeleteCategory(true))} />
    const handleSelection = (items:any[]) => {
        dispatch(selectCategoriesToDelete(items))
    }
    return(
        <>
            <DataGrid
                onRowSelectionModelChange={handleSelection}
                checkboxSelection
                disableRowSelectionOnClick
                rowHeight={30}
                rows={productCategories}
                columns={columns}
                loading={isLoading}
                slots={{
                    toolbar: HeaderDataGrid
                }}
            />
            <AlertDeleteCategoryProduct />
        </>
    )
}

export default CategoryProductsList