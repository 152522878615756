import { Box, Fade as FadeMUI, FadeProps } from '@mui/material'

type Props = Pick<FadeProps, "in" | "children" | "timeout">

const Fade = ({ children, timeout=500, ...rest }:Props) => {
    return(
        <FadeMUI timeout={timeout} {...rest} >
            <Box>
                {children}
            </Box>
        </FadeMUI>
    )
}

export default Fade