import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useGetCustomerQuery } from "../../../api/querys/Customer"
import Fade from "@component/Fade"
import List from "@component/List"
import ListItemData from "@component/ListItemData"

const DataCustomer = () => {
    const { t } = useTranslation();
    const { customerId } = useParams();
    const { data } = useGetCustomerQuery(customerId!);
    const customer = data?.payload!

    return(
        <Fade in={true} >
            <List>
                <ListItemData showAvatar primaryText={t('customer')!} secondaryText={customer.name} />
                <ListItemData primaryText={t('businessName')!} secondaryText={customer?.businessName} />
                <ListItemData primaryText={t('businessType')!} secondaryText={customer?.businessType?.name} />
                <ListItemData primaryText={t('address')!} secondaryText={customer?.address} />
                <ListItemData primaryText={t('zone')!} secondaryText={customer?.zone?.name} />
                <ListItemData primaryText={t('neighborhood')!} secondaryText={customer?.neighborhood?.name} />
                <ListItemData primaryText={t('city')!} secondaryText={customer?.city?.name} />
                <ListItemData primaryText={t('branch')!} secondaryText={customer?.branch?.name} />
                <ListItemData primaryText={`${t('identificationNumber')} (DI)`} secondaryText={customer?.identificationNumber} />
                <ListItemData primaryText={t('phoneNumber')!} secondaryText={customer?.phoneNumber} />
            </List>
        </Fade>
    )
}

export default DataCustomer