import React from 'react';
import { IconButton as IconButtonMUI, IconButtonProps as IconButtonPropsMUI } from '@mui/material'
import { styled } from '@mui/material/styles'

type IconButtonProps = IconButtonPropsMUI


interface Props extends  Pick<IconButtonProps, "children" | "color" | "onClick" | "size" | "sx" | "id" | "tabIndex" | "disabled" >{
    squareButton?: boolean
}

const IconButtonCustom = styled(IconButtonMUI,
    {shouldForwardProp: (prop) => prop !== 'squareButton' }
    )<Props>(({theme, squareButton, color="primary"}) => ({
        marginLeft: 1,
        marginRight: 1,
        ...(squareButton && {
            ...(color === "primary" && {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
            }),
            ...(color === "error" && {
                backgroundColor: theme.palette.error.light,
                color: theme.palette.error.contrastText,
            }),
            borderRadius: 12,
            padding: 5,
            "&:hover": {
                ...(color === "primary" && {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                }),
                ...(color === "error" && {
                    backgroundColor: theme.palette.error.dark,
                    color: theme.palette.error.contrastText,
                }),
            }
        })
}))

const IconButton = React.forwardRef<HTMLButtonElement, Props>(({ children, color="primary", ...rest }, ref) => {
    return(
        <IconButtonCustom ref={ref} color={color} {...rest} >
            {children}
        </IconButtonCustom>
    )
})

export default IconButton