import Box from "@component/Box";
import IconButton from "@component/IconButton"
import Stack from "@component/Stack"
import Tooltip from "@component/Tooltip";
import EditRounded from '@mui/icons-material/EditRounded';
import { usePurchaseData } from "../hooks/usePurchaseReport";
import { useAppDispatch } from "@hook/state";
import { ProviderSelected, PurchasingProductsSelected } from "@interface/Purchasing";
import { showPaymentSelectionWindow, updateBillingDate, updateDateReceived, updateDueDate, updateInvoiceNumber, updateListOfSelectedProducts, updatePerform, updatePurchaseId, updateSelectedProvider, updateWhoReceives } from "@feature/PurchasingSuppliers/services/PurchasesSuppliersSlice";
import { useNavigate } from "react-router-dom";
import Routes from "@service/routes";
import dayjs from "dayjs";
import Delete from '@mui/icons-material/Delete';
import Swal from "sweetalert2";
import { useTheme } from "@mui/material/styles";
import { usePurchasing } from "@feature/PurchasingSuppliers/hooks/usePurchasing";
import { useBusinessPermissions } from "@hook/usePermissions";
import AttachMoneyRounded from '@mui/icons-material/AttachMoneyRounded';
import PaymentSelectionWindow from "@feature/PurchasingSuppliers/components/PaymentSelectionWindow";

interface Props {
    purchaseId: string
}

const PurchaseInvoiceOptions = ({ purchaseId }: Props) => {
    const dispatch = useAppDispatch()
    const { report } = usePurchaseData({ purchaseId })
    const { deletePurchase, changeMethodOfPaymentOfThePurchaseInvoice } = usePurchasing()
    const navigate = useNavigate()
    const theme = useTheme()
    const { PurchaseFromSuppliers } = useBusinessPermissions()

    const handleEdit = () => {
        if (Boolean(report?._id)) {
            let providerSelected: ProviderSelected = {
                _id: report.provider._id,
                advance: 0,
                name: report.provider.name,
                tax: report.provider.tax
            }
            let productsSelectedList: Array<PurchasingProductsSelected> = []
            productsSelectedList = report.products.map((n) => ({
                _id: n._id,
                name: n.name,
                prices: n.prices,
                quantity: n.quantity,
                purchaseCost: n.purchaseCost,
                freight: (n?.freight ?? 0),
                tax: n.tax,
                packaging: n.packaging,
                costUnit: (n?.purchaseCost ?? 0) / (n?.packaging?.quantity ?? 0),
                unitFreight: (n?.freight ?? 0) / (n?.packaging?.quantity ?? 0),
                costWithFreight: ((n?.purchaseCost ?? 0) / (n?.packaging?.quantity ?? 0)) + ((n?.freight ?? 0) / (n?.packaging?.quantity ?? 0)),
                compounds: n.compounds,
            })) as Array<PurchasingProductsSelected>

            dispatch(updateSelectedProvider(providerSelected))
            dispatch(updateListOfSelectedProducts(productsSelectedList))
            dispatch(updatePerform("purchase"))
            dispatch(updateDateReceived(dayjs(report.dateReceived).toDate()))
            dispatch(updateBillingDate(dayjs(report.billingDate).toDate()))
            dispatch(updateDueDate(dayjs(report.dueDate).toDate()))
            dispatch(updateWhoReceives(report.whoReceives))
            dispatch(updateInvoiceNumber(String(report?.invoiceNumber ?? "")))
            dispatch(updatePurchaseId(report._id))
            navigate(Routes.indexPurchasingSuppliers())
        }
    }

    const handleDelete = async () => {
        const SwalAlert = await Swal.fire({
            icon: 'question',
            text: '¿Estas seguro que deseas eliminar esta compra?',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Eliminar',
            cancelButtonColor: theme.palette.error.main,
            confirmButtonColor: theme.palette.primary.main,
        })

        if( SwalAlert.isConfirmed ) deletePurchase({ purchaseId })
    }

    const handleChangePaymentMethod = (paymentMethod:string) => {
        changeMethodOfPaymentOfThePurchaseInvoice({ paymentMethod, purchasesId: purchaseId })
    }

    return (
        <>
            <Stack direction={'row'} justifyContent={'end'} >
                <Box>
                    <Tooltip title="Cambiar método de pago" >
                        <IconButton squareButton onClick={() => dispatch(showPaymentSelectionWindow(true))} >
                            <AttachMoneyRounded />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box>
                    <Tooltip title="Editar compra" >
                        <IconButton squareButton onClick={handleEdit} >
                            <EditRounded />
                        </IconButton>
                    </Tooltip>
                </Box>
                {PurchaseFromSuppliers.remove_purchase &&
                    <Box>
                        <Tooltip title="Eliminar compra" >
                            <IconButton squareButton onClick={handleDelete} color="error" >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                }
                <PaymentSelectionWindow onChangePaymentMethod={handleChangePaymentMethod} />
            </Stack>
        </>
    )
}

export default PurchaseInvoiceOptions