import Stack from "@component/Stack"
import Typography from "@component/Typography"
import { currencyFormat } from "@hook/useNumberFormat"
import { MovementsCashierPropsAsyncResponse } from "@interface/Cashier"

interface Props {
    movements: MovementsCashierPropsAsyncResponse['payload']
    listTitle: string
}

const ListsToolbar = ({ movements, listTitle }:Props) => {
    const total = movements?.reduce((accumulator, params) => params.total+accumulator, 0)
    return(
        <>
            <Stack pt={2} px={2} >
                <Typography fontWeight={'bold'} textTransform={'uppercase'} > {listTitle} </Typography>
                <Typography fontWeight={'bold'} textTransform={'uppercase'} fontSize={'.9rem'} > Total: $ {currencyFormat(total)} </Typography>
            </Stack>
        </>
    )
}

export default ListsToolbar