import { Container as ContainerMUI, ContainerProps } from '@mui/material'

type Props = Pick<ContainerProps, "maxWidth" | "children" | "sx">

const Container = ({ children, maxWidth="sm" }:Props) => {
    return(
        <ContainerMUI maxWidth={maxWidth} >
            {children}
        </ContainerMUI>
    )
}

export default Container