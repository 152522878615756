import { signal } from '@preact/signals-react';
import dayjs from 'dayjs';
interface BusinessBoxProps {
    filterDateRange: {
        startDate: string
        endDate: string
    }
}

export const BusinessBox = signal<BusinessBoxProps>({
    filterDateRange: {
        startDate: dayjs().format("MM-DD-YYYY"),
        endDate: dayjs().format("MM-DD-YYYY")
    },
})