import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetInvoiceReportWithoutDefinedPaymentQuery } from "../../../api/querys/PointOfSale";
import Drawer from "@component/Drawer";
import List from "@component/List";
import { useAppDispatch, useAppSelector } from "../../../hooks/state";
import { ArrayOfInvoicePaymentProps, InvoicePaymentProps } from "@interface/PointOfSale";
import { showCashierWindow } from "../../PointOfSale/services/PointOfSaleSlice";
import InvoiceCard from '../../Invoice/components/InvoiceCard'; 
import { getInvoiceValues, showPaymentMethodForm } from '../../Invoice/service/InvoiceSlice';

const DrawerCashier = () => {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const dispatch = useAppDispatch()
    const { PointOfSaleState } = useAppSelector(({ PointOfSaleState }) => ({ PointOfSaleState }));
    const { isOpenCashierWindow, } = PointOfSaleState;
    const { data, isError } = useGetInvoiceReportWithoutDefinedPaymentQuery();
    const invoicePayment = data?.payload??[] as ArrayOfInvoicePaymentProps;
    const handleGoToPaymentForm = ( props: InvoicePaymentProps, index: number ) => {
        setSelectedIndex(index);
        setTimeout(() => {
            dispatch(getInvoiceValues(props));
            dispatch(showPaymentMethodForm(true));
        }, 150)
        handleClose();
    };
    const handleClose = () => {
        setSelectedIndex(0);
        dispatch(showCashierWindow(false));
    };

    const handleKeyDown = (evt: React.KeyboardEvent<HTMLDivElement>) => {
        const lastElement = invoicePayment?.length - 1;
        const { keyCode, which } = evt;
        const lockKey: Array<number> = [33, 9, 38, 40];
        if (lockKey.indexOf(keyCode || which) >= 0) {
            evt.preventDefault();
            evt.stopPropagation();
        }

        if (keyCode === 38 || which === 38) {
            if (selectedIndex !== 0) {
            setSelectedIndex((prevState) => prevState - 1);
            }
        }

        if (keyCode === 9 || which === 9) {
            if (selectedIndex !== lastElement) {
            setSelectedIndex((prevState) => prevState + 1);
            }
        }

        if (keyCode === 40 || which === 40) {
            if (selectedIndex !== lastElement) {
            setSelectedIndex((prevState) => prevState + 1);
            }
        }

        if (keyCode === 33) {
            setSelectedIndex(0);
        }
    };

    React.useEffect(() => {
        if( isError ){
            enqueueSnackbar(t('itSeemsThatYouHaveProblemsWithYourConnectionTheWaitingTimeHasExpired'), { variant: "error" })
        }
    },[isError, t, enqueueSnackbar])
    return(
        <Drawer anchor='right' open={isOpenCashierWindow} onClose={handleClose} sx={{ zIndex: '1400', width: '240px' }} >
            <List>
            {invoicePayment.map((props, index) => {
                return (
                <div key={props._id}>
                    <InvoiceCard
                        onClick={() => handleGoToPaymentForm(props, index)}
                        autoFocus={selectedIndex === index}
                        onKeyDown={(evt) => handleKeyDown(evt)}
                        seller={props.seller}
                        total={props.total}
                        customer={props.customer}
                        consecutive={props.consecutive}
                    />
                </div>
                );
            })}
            </List>
        </Drawer>
    )
}

export default DrawerCashier