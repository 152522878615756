import Dialog, { DialogContent } from "@component/Dialog"
import { useURLParams } from "@hook/useURLParams"
import { useGetDebtsToPay } from "../hooks/useAccountsPayable"
import DataGrid from "@component/DataGrid"
import { GridColDef } from "@mui/x-data-grid"
import { currencyFormat } from "@hook/useNumberFormat"
import dayjs from "dayjs"
import CurrencyExchangeRounded from '@mui/icons-material/CurrencyExchangeRounded';
import IconButton from "@component/IconButton"
import Tooltip from "@component/Tooltip"
import PayAccountPayable from "./PayAccountPayable"
import { useTranslation } from "react-i18next"
import Box from "@component/Box"
import { FixedSizeList } from "react-window"
import List, { ListItem, ListItemText } from "@component/List"

const AccountsPayablePreview = () => {
    const { getQuery, delQueries, addQuery } = useURLParams()
    const open = getQuery("modal") === "accountsPayablePreview"
    const { groupList } = useGetDebtsToPay({ only: "balance", show: "results" })
    const { t } = useTranslation()
    const columns:GridColDef[] = [
        {flex: 1, field: 'name', headerName: "Asunto", renderCell: ({ row }) => t(row?.name??"")},
        {maxWidth: 120, minWidth: 120, field: 'amount', headerName: "Monto", renderCell: ({ row }) => `$ ${currencyFormat(row?.amount??0)}` },
        {maxWidth: 160, minWidth: 160, field: 'createdAt', headerName: "Expirado", valueGetter: ({ row }) => `Vencido el ${ dayjs(row.createdAt).format("DD-MM-YYYY") }` },
        {maxWidth: 50, minWidth: 50, field: ' ', align: "center", renderCell: ({ id }) => {
            return(
                <>
                    <Tooltip title="Pagar" >
                        <IconButton size="small" onClick={() => addQuery({ paymentId: id as string })} >
                            <CurrencyExchangeRounded sx={{ fontSize: "1rem" }} />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }},
    ]

    return(
        <Dialog fullWidth open={open} onClose={() => delQueries(["modal", "tag"])} >
            <DialogContent>
                <FixedSizeList height={500} itemCount={groupList.length} itemSize={500} width={"100%"} >
                    {({ index, style }) => {
                        const n = groupList[index];
                        let results = n?.results ?? [];
                        return (
                            <Box style={style} >
                                <List>
                                    <ListItem>
                                        <ListItemText primary={ `$ ${currencyFormat(n?.total??0)}` } secondary="Total" />
                                    </ListItem>
                                </List>
                                <DataGrid
                                    columnHeaderHeight={0}
                                    hideFooter
                                    columns={columns}
                                    rows={results}
                                />
                            </Box>
                        );
                    }}
                </FixedSizeList>
                <PayAccountPayable />
            </DialogContent>
        </Dialog>
    )
}

export default AccountsPayablePreview