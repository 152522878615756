import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePackagingProducts } from '../hooks/usePackagingProducts';

import { useAppSelector } from '../../../hooks/state';

import Button from '../../../components/Button';
import Form from '../../../components/Form';
import Grid from '../../../components/Grid';
import TextField from '../../../components/TextField';
import { ProductPackaging } from '../../../interfaces/Product';
import NumberFormat from '../../../components/NumberFormat';
import { EventType } from '../../../interfaces/App';



const initialValueOfPackagingForm = {
    name: '',
    quantity: 0,
};

const ProductPackagingForm: React.FC = () => {
    const { t } = useTranslation();
    const { ProductPackagingState } = useAppSelector(
        ({ ProductPackagingState }) => ({ ProductPackagingState })
    );
    const { isOpenForm, packagingToEdit } = ProductPackagingState;

    const { registerPackagingProductMutation } = usePackagingProducts();

    const [form, setForm] = useState<ProductPackaging>(
        initialValueOfPackagingForm
    );

    const handleChange = (evt: EventType) => {
        const { name, value } = evt.target;
        setForm((preventFormState) => ({
            ...preventFormState,
            [name]: value,
        }));
    };

    const handleSubmit = () => registerPackagingProductMutation(form);

    useEffect(() => {
        if (isOpenForm && packagingToEdit._id !== '') {
            setForm((preventFormState) => ({
                ...preventFormState,
                name: packagingToEdit.name,
                quantity: packagingToEdit.quantity,
                _id: packagingToEdit?._id,
            }));
        }
    }, [isOpenForm, packagingToEdit]);

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container spacing={1} >
                <Grid xs={12}>
                    <TextField
                        margin='none'
                        label={t('nameOfPackaging')}
                        name='name'
                        value={form.name}
                        autoFocus
                        onChange={handleChange}
                    />
                </Grid>
                <Grid xs={12}>
                    <NumberFormat
                        label={t('Cantidad que suma al inventario')!}
                        value={form.quantity}
                        name='quantity'
                        onChange={handleChange}
                        thousandSeparator=''
                    />
                </Grid>
                <Grid xs={12}>
                    <Button type='submit'>
                        {packagingToEdit._id !== ''
                            ? t('updatePackaging')
                            : t('createNewPackaging')}
                    </Button>
                </Grid>
            </Grid>
        </Form>
    );
};

export default ProductPackagingForm;
