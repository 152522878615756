import { api } from "../splitApi"

const Query = api.injectEndpoints({
    endpoints: (build) => ({
        getInventorySummary: build.query({
            query: ({ startDate, endDate }) => ({
                url: `/get/inventory/summary`,
                params: {startDate, endDate}
            }),
        }),
        getSupplierSummary: build.query({
            query: ({ startDate, endDate }) => ({
                url: `/get/supplier/summary`,
                params: {startDate, endDate}
            }),
        }),
        getSummaryOfFixedExpenses: build.query({
            query: ({ startDate, endDate }) => ({
                url: `/get/summary/of/fixed/expenses`,
                params: {startDate, endDate}
            }),
        }),
        getSummaryDetailsToSuppliers: build.query({
            query: ({ startDate, endDate }) => ({
                url: `/get/summary/details/to/suppliers`,
                params: {startDate, endDate}
            }),
        }),
        getSummaryDetailsToCustomers: build.query({
            query: ({ startDate, endDate }) => ({
                url: `/get/summary/details/to/customers`,
                params: {startDate, endDate}
            }),
        }),
        getSummaryOfMovements: build.query({
            query: ({ startDate, endDate }) => ({
                url: `/get/summary/of/movements`,
                params: {startDate, endDate}
            }),
        }),
        getSummaryOfBoxes: build.query({
            query: ({ startDate, endDate }) => ({
                url: `/get/summary/of/boxes`,
                params: {startDate, endDate}
            }),
        }),
        // recordMasterBoxTransfer: build.mutation({
        //     query(body) {
        //         return {...mutationConfig, url: '/record/master/box/transfer', body}
        //     },
        // }),
    }),
    overrideExisting: false,
})

export const {
    useGetInventorySummaryQuery,
    useGetSupplierSummaryQuery,
    useGetSummaryOfFixedExpensesQuery,
    useGetSummaryDetailsToSuppliersQuery,
    useGetSummaryDetailsToCustomersQuery,
    useGetSummaryOfMovementsQuery,
    useGetSummaryOfBoxesQuery,
} = Query