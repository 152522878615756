import React from 'react'
import { Autocomplete as AutocompleteMUI, TextFieldProps } from '@mui/material';
import { CircularProgress } from '@mui/material';
import TextField from './TextField';
import { useTranslation } from 'react-i18next';

type AutocompleteMUIProps = Omit<React.ComponentProps<typeof AutocompleteMUI>, "renderInput">

interface Props extends AutocompleteMUIProps {
    label?: string
    name?: string
    InputProps?: TextFieldProps['InputProps']
    helperText?: TextFieldProps['helperText']
    error?: TextFieldProps['error']
}

const Autocomplete: React.FC<Props> = ({ options, label, loading=false, name, renderOption=(props, option:any) => (
    <li {...props} key={option._id} > {option.name} </li> ), fullWidth=true, getOptionLabel=(params:any) => params.name,
    error, helperText,...rest }) => {
    const [list, setList] = React.useState<typeof options>([])
    const [open, setOpen] = React.useState(false)
    const { t } = useTranslation()
    React.useEffect(() => {
        if( loading ){
            setList([])
        }else{
            setList([...options])
        }
    },[options, loading])

    return (
        <AutocompleteMUI
            autoHighlight
            noOptionsText={t('thereAreNoResultsToShow')!}
            {...rest}
            fullWidth={fullWidth}
            options={list}
            getOptionLabel={getOptionLabel || ""}
            isOptionEqualToValue={(option:any, value:any) => option.name === value.name}
            loading={loading}
            open={open}
            onOpen={() => { setOpen(true) }}
            onClose={() => { setOpen(false) }}
            renderOption={renderOption}
            renderInput={(params) => (
                <TextField
                    error={error}
                    helperText={helperText}
                    margin='none'
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

export default Autocomplete