import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetProductQuery } from '../../../api/querys/Product'
import ProductForm from "./ProductForm"
import { ProductFormProps } from '../../../interfaces/Product'
import { useImageProduct } from '../hooks/useProducts'

const EditProduct = () => {
    const [ form, setForm ] = React.useState<ProductFormProps & {image: FileList[0] | null} | null>(null)
    const { productId } = useParams()
    const { data } = useGetProductQuery(productId)
    const { getProductImage } = useImageProduct()
    const product = data?.payload

    const productEdit = React.useCallback( async () => {
        const image = await getProductImage(product?.image??"")
        let p = {
            _id: product?._id??"",
            compount: product?.compount??false,
            cost: product?.cost??0,
            name: product?.name??"",
            prices: product?.prices??[],
            skuCode: product?.skuCode??0,
            stock: product?.stock??0,
            category: product?.category?._id??"",
            packaging: product?.packaging?._id??"",
            productParent: product?.productParent?._id??"",
            image: image?.file || null,
            tax: product?.tax?._id??"",
            provider: product?.provider?._id??"",
            tags: (product?.tags !== undefined && product?.tags !== null) ? product.tags.map(n => n._id) : [] as string[]
        }
        return p
    },[product, getProductImage])

    productEdit().then( (n) => {
        if( form === null || !Boolean(form?._id) ){
            // @ts-ignore
            setForm(n)
        }
    })
    React.useEffect(() =>{
        return () => {
            setForm(null)
        }
    }, [])
    return(
        <>
            <ProductForm editProduct={form} />
        </>
    )
}

export default EditProduct