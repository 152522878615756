import React from 'react'
import Autocomplete from "@component/Autocomplete"
import Button from "@component/Button"
import Dialog, { DialogActions, DialogContent } from "@component/Dialog"
import { FileDownloadRounded } from "@mui/icons-material"
import { genUUID } from "@service/parseData"
import { useURLParams } from '@hook/useURLParams'
import { useGetAccurateCustomerInformation } from '../hooks/useCustomer'
import { useSheet } from '@hook/useSheet'

const SelectCustomerListFields = () => {
    const { getQuery, delQueries } = useURLParams()
    const downloadCustomerList = getQuery("download")
    const [filter, setFilter] = React.useState<string>("")
    const open = (downloadCustomerList === "customer-list")
    const { isLoading, fieldsToResponse, listForTheBook } = useGetAccurateCustomerInformation(filter)
    const fieldsDefault = [
        {_id: genUUID(), name: "Nombre", value: "name"},
        {_id: genUUID(), name: "Numero de documento", value: "identificationNumber"},
        {_id: genUUID(), name: "Razón social", value: "businessName"},
        {_id: genUUID(), name: "Numero de teléfono", value: "phoneNumber"},
        {_id: genUUID(), name: "Ciudad", value: "city"},
        {_id: genUUID(), name: "Zona", value: "zone"},
        {_id: genUUID(), name: "Tipo de negocio", value: "businessType"},
        {_id: genUUID(), name: "Sucursal", value: "branch"},
        {_id: genUUID(), name: "Barrio", value: "neighborhood"},
        {_id: genUUID(), name: "Dirección", value: "address"},
    ]
    const { useCreateSheet:createSheet } = useSheet()
    const handleFields = (params:Array<typeof fieldsDefault[0]>) => {
        let values:any = {}
        for( let n of params ){
            values[n.value] = 1
        }
        values = JSON.stringify(values)
        setFilter(values)
    }

    const handleDownloadFile = () => createSheet({ columns: fieldsToResponse, rows: listForTheBook, fileName: "clientes.xlsx" })
    return(
        <>
            <Dialog fullWidth open={(open)} onClose={() => delQueries(['download'])} maxWidth="xs" >
                <DialogContent>
                    <Autocomplete
                        onChange={(_, params:any) => handleFields(params)}
                        ChipProps={{
                            size: "small",
                            color: "primary",
                            variant: "outlined"
                        }}
                        multiple
                        label="Seleccione los campos"
                        options={fieldsDefault}
                    />
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<FileDownloadRounded />} variant="text" loading={isLoading} onClick={handleDownloadFile} >
                        Descargar documento
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SelectCustomerListFields