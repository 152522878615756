import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { getInvoiceAlphabeticalOrder, getInvoiceMode, getToken } from './localStorage'

interface stateProps {
    isAuth: boolean,
    mode : "light" | "dark",
    openDrawer: boolean
    isResponsiveDesign: boolean,
    invoiceAlphabeticalOrder: 'default' | 'alphabetical'
    invoiceMode: 'pos' | 'large'
}

const initialState:stateProps = {
    isAuth: Boolean(getToken()),
    mode: "light",
    openDrawer: false,
    isResponsiveDesign: false,
    invoiceAlphabeticalOrder: getInvoiceAlphabeticalOrder() === null ? 'default' : getInvoiceAlphabeticalOrder() as stateProps['invoiceAlphabeticalOrder'],
    invoiceMode: getInvoiceMode() === null ? 'pos' : getInvoiceMode() as stateProps['invoiceMode']
}
export const AppSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        toggleApplicationMode: (state, action:PayloadAction<stateProps['mode']>) => {
            localStorage.setItem('mode', action.payload)
            state.mode = action.payload
        },
        showDrawer: (state, action:PayloadAction<boolean>) => {
            state.openDrawer = action.payload
        },
        displayResponsiveDesign: (state, action:PayloadAction<boolean>) => {
            state.isResponsiveDesign = action.payload
        },
        changeInvoiceFormatMode: (state, action:PayloadAction<stateProps['invoiceMode']>) => {
            state.invoiceMode = action.payload
        },
        changeInvoiceAlphabeticalOrder: (state, action:PayloadAction<stateProps['invoiceAlphabeticalOrder']>) => {
            state.invoiceAlphabeticalOrder = action.payload
        },
    }
})

export const {
    toggleApplicationMode,
    showDrawer,
    displayResponsiveDesign,
    changeInvoiceFormatMode,
    changeInvoiceAlphabeticalOrder,
} = AppSlice.actions
export default AppSlice.reducer