import React from 'react';
import VisibilityRounded from "@mui/icons-material/VisibilityRounded"
import type { GridColDef } from "@mui/x-data-grid"
import { useNavigate } from "react-router-dom"
import { useGetEmployessByBusinessQuery } from "../../../api/querys/Employee"
import Box from "@component/Box"
import DataGrid from "@component/DataGrid"
import DataGridCustomHeaderToDelete from "@component/DataGridCustomHeaderToDelete"
import IconButton from "@component/IconButton"
import Tooltip from "@component/Tooltip"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import Routes from "../../../services/routes"
import { selectEmployeesToDelete, showEmployeeDeletionAlert } from "../services/EmployeeSlice"
import AlertDeleteEmployee from './AlertDeleteEmployee';

const ListEmployee = () => {
    const { data } = useGetEmployessByBusinessQuery()
    const employees = data?.payload?.employees?.results??[]
    const { EmployeeState } = useAppSelector(({ EmployeeState }) => ({ EmployeeState }))
    const dispatch = useAppDispatch()
    const { employeeSelectionToDelete } = EmployeeState
    const navigate = useNavigate()
    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Nombre', flex: 1,
        renderCell: ({ row }) => {
            return(
                <Box> {row.name} {row.lastName} </Box>
            )
        } },
        { field: ' ', headerName: ' ', maxWidth: 100, align: "center",
        renderCell: ({ row }) => {
            return(
                <Box display={'flex'} >
                    <Tooltip title="Ver" placement='left' >
                        <IconButton onClick={() => navigate(Routes.employeeData(row._id))} >
                            <VisibilityRounded />
                        </IconButton>
                    </Tooltip>
                </Box>
            )
        } },
    ];
    const CustomHeader = () => <DataGridCustomHeaderToDelete listItems={employeeSelectionToDelete} texts={["Empleados seleccionados", "Empleado seleccionado"]} onClick={() => dispatch(showEmployeeDeletionAlert(true))} />
    const handleSelection = (data:any[]) => dispatch(selectEmployeesToDelete(data))

    React.useEffect(() => {
        return () => {
            dispatch(selectEmployeesToDelete([]))
        }
    },[dispatch])


    return(
        <>
            <DataGrid
                onRowSelectionModelChange={handleSelection}
                components={{
                    Toolbar: CustomHeader
                }}
                checkboxSelection
                columns={columns}
                rows={employees}
            />
            <AlertDeleteEmployee />
        </>
    )
}

export default ListEmployee