import React from 'react'
import { useTranslation } from 'react-i18next'
import Dialog, { DialogContent, DialogTitle } from '../../../components/Dialog'
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { displayFormToCreateCustomer } from "../services/CustomerSlice"
import CustomerForm from "./CustomerForm"

const CreateCustomer = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch()
    const { CustomerState } = useAppSelector(({ CustomerState }) => ({CustomerState}))
    const { isTheFormToCreateCustomerOpen } = CustomerState

    const handleOnClose = () => {
        dispatch(displayFormToCreateCustomer(false))
    }

    return(
        <Dialog open={isTheFormToCreateCustomerOpen} onClose={handleOnClose} >
            <DialogTitle> {t('createCustomer')} </DialogTitle>
            <DialogContent>
                <CustomerForm />
            </DialogContent>
        </Dialog>
    )
}

export default CreateCustomer