import { useTranslation } from 'react-i18next';
import EditRounded from '@mui/icons-material/EditRounded';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';

import DataGrid from '../../../components/DataGrid';
import IconButton from '../../../components/IconButton';
import DataGridCustomHeaderToDelete from '../../../components/DataGridCustomHeaderToDelete';
import AlertDeleteCity from './AlertDeleteCity';
import { useAppDispatch, useAppSelector } from '../../../hooks/state';
import {
  loadCityToEdit,
  selectCityToDelete,
  showAlertDeleteCity,
} from '../services/CitySlice';
import { CustomerCityProps } from '../../../interfaces/Customer';
import { useGetCustomersCitiesQuery } from '../../../api/querys/Customer';

const CityList: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { CityState } = useAppSelector(({ CityState }) => ({
    CityState,
  }));
  const { selectionToDelete } = CityState;

  const { data: CityCustomerData, isLoading } =
  useGetCustomersCitiesQuery();
  const CityArray = CityCustomerData?.payload??[]
  const handleDeleteCitys = () => {
    dispatch(showAlertDeleteCity(true));
  };

  const handleEditCity = (row: CustomerCityProps) => {
    dispatch(loadCityToEdit(row));
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('nameOfTheCity')!,
      flex: 1,
    },
    {
      field: ' ',
      align: 'center',
      maxWidth: 80,
      minWidth: 80,
      renderCell: ({ row }) => {
        return (
          <IconButton onClick={() => handleEditCity(row)}>
            <EditRounded />
          </IconButton>
        );
      },
    },
  ];

  const HeaderDataGrid = () => (
    <DataGridCustomHeaderToDelete
      listItems={selectionToDelete}
      texts={[t('selectedCities'), t('selectedCity')]}
      title=''
      onClick={handleDeleteCitys}
    />
  );

  const handleSelection = (items: GridRowSelectionModel) => {
    const arrayOfItems = items as Array<string>
    
    dispatch(selectCityToDelete(arrayOfItems));
  };

  return (
    <>
      <DataGrid
        onRowSelectionModelChange={handleSelection}
        checkboxSelection
        disableRowSelectionOnClick
        rowHeight={30}
        rows={CityArray}
        columns={columns}
        loading={isLoading}
        components={{
          Toolbar: HeaderDataGrid,
        }}
      />
      <AlertDeleteCity />
    </>
  );
};

export default CityList;
