import { GridRowSelectionModel } from '@mui/x-data-grid';
import type {
    AvailableBatchesOfProductResponse,
    AvailableProductCode,
    BuyBatchProducts,
    InventoryDataResponse,
    ProductCategoriesDataResponse,
    ProductCategoriesProps,
    ProductDataResponse,
    ProductPackagingForm,
    ProductPackagingResponse,
    ProductPurchaseHistoryResponse,
    ProductsDataResponse,
    ProductsForMassEditionAsyncResponse,
    ProductsForPointOfSaleResponse,
    ProductsForSupplierPurchasesResponse,
    ProductsFromExcel,
    RegisterCategoryProductResponse,
    RegisterPackagingProductResponse,
    RegisterProductResponse,
    UpdateProductFieldAsyncResponse,
    UpdateProductFieldParams,
    UpdateProductResponse,
    UploadMassiveProductsResponse,
} from '../../interfaces/Product';
import { api, mutationConfig } from '../splitApi';

const Product = api.injectEndpoints({
    endpoints: (build) => ({
        getProductInventory: build.query<InventoryDataResponse, string | undefined>(
            {
                query: (categoryId) => ({
                    url: `/get/product/inventory`,
                    params: { categoryId },
                }),
                providesTags: ['Product'],
            }
        ),
        getCategoriesProduct: build.query<ProductCategoriesDataResponse, void>({
            query: () => '/get/product/categories',
            providesTags: ['ProductCategories'],
        }),
        getBusinessProducts: build.query<ProductsDataResponse, void>({
            query: () => '/get/business/products',
            providesTags: ['Product'],
        }),
        getProductsForSupplierPurchases: build.query<ProductsForSupplierPurchasesResponse, { searchString?: string }>({
            query: ({ searchString }) => ({
                url: `/get/products/for/supplier/purchases`,
                params: { searchString },
            }),
            providesTags: ['Product'],
        }),
        getPackagingProduct: build.query<ProductPackagingResponse, void>({
            query: () => '/get/product/packaging',
            providesTags: ['ProductPackaging'],
        }),
        getProductsForPointOfSale: build.query<ProductsForPointOfSaleResponse, void >({
            query: () => '/get/products/for/point/of/sale',
            providesTags: ['Product'],
        }),
        getAvailableProductCode: build.query<AvailableProductCode, void | null>({
            query: () => '/get/available/product/code',
            providesTags: ['SKUCode'],
        }),
        getProduct: build.query<ProductDataResponse, void | null | string>({
            query: (productId) => ({ url: `/get/product`, params: { productId } }),
            providesTags: ['Product'],
        }),
        uploadMassiveProducts: build.mutation<UploadMassiveProductsResponse, ProductsFromExcel[]>({
            query(body) {
                return { ...mutationConfig, url: '/upload/massive/products', body };
            },
            invalidatesTags: ['Product', 'ProductCategories'],
        }),
        registerCategoryProduct: build.mutation<RegisterCategoryProductResponse, ProductCategoriesProps>({
            query(body) {
                return { ...mutationConfig, url: '/register/product/category', body };
            },
            invalidatesTags: ['Product', 'ProductCategories'],
        }),
        updateCategoryProduct: build.mutation<void, ProductCategoriesProps>({
            query(body) {
                return { ...mutationConfig, url: '/update/product/category', body };
            },
            invalidatesTags: ['Product', 'ProductCategories'],
        }),
        deleteCategoryProduct: build.mutation<any, string[]>({
            query(body) {
                return { ...mutationConfig, url: '/delete/product/category', body };
            },
            invalidatesTags: ['Product', 'ProductCategories'],
        }),
        registerPackagingProduct: build.mutation<RegisterPackagingProductResponse, ProductPackagingForm>({
            query(body) {
                return { ...mutationConfig, url: '/register/product/packaging', body };
            },
            invalidatesTags: ['Product', 'ProductPackaging'],
        }),
        updatePackagingProduct: build.mutation<RegisterPackagingProductResponse, ProductPackagingForm>({
            query(body) {
                return { ...mutationConfig, url: '/update/product/packaging', body };
            },
            invalidatesTags: ['Product', 'ProductPackaging'],
        }),
        deletePackagingProduct: build.mutation<any, string[]>({
            query(body) {
                return { ...mutationConfig, url: '/delete/product/packaging', body };
            },
            invalidatesTags: ['Product', 'ProductPackaging'],
        }),
        registerProduct: build.mutation<RegisterProductResponse, FormData>({
            query(body) {
                return { ...mutationConfig, url: '/register/product', body };
            },
            invalidatesTags: ['SKUCode', 'Product'],
        }),
        updateProduct: build.mutation<UpdateProductResponse, FormData>({
            query(body) {
                return { ...mutationConfig, url: '/update/product', body };
            },
            invalidatesTags: ['Product'],
        }),
        deleteProduct: build.mutation<any, GridRowSelectionModel>({
            query(body) {
                return { ...mutationConfig, url: '/delete/product', body };
            },
            invalidatesTags: ['Product'],
        }),
        productPurchaseHistory: build.query<ProductPurchaseHistoryResponse, { startDate: string; endDate: string; productId: string }>({
            query: ({ startDate, endDate, productId }) => ({
                url: `/product/purchase/history`,
                params: { startDate, endDate, productId },
            }),
        }),
        getBuyBatchProducts: build.query<BuyBatchProducts, void>({
            query: () => ({
                url: `/get/buy/batch/products`,
            }),
        }),
        getAvailableBatchesOfProduct: build.query<AvailableBatchesOfProductResponse, { productId: string }>({
            query: ({ productId }) => ({
                url: `/get/available/batches/of/product`,
                params: { productId }
            }),
        }),
        getProductsForMassEdition: build.query<ProductsForMassEditionAsyncResponse, void>({
            query: () => ({
                url: `/get/products/for/mass/edition`,
            }),
            providesTags: ['Product']
        }),
        updateProductField: build.mutation<UpdateProductFieldAsyncResponse, UpdateProductFieldParams>({
            query(body) {
                return { ...mutationConfig, url: '/update/product/field', body };
            },
            invalidatesTags: ['Product'],
        }),
        getAccurateProductInformation: build.query<any, { fields: string }>({
            query: ({ fields }) => ({ url: `/get/accurate/product/information`, params: {fields} }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetProductInventoryQuery,
    useGetCategoriesProductQuery,
    useGetBusinessProductsQuery,
    useUploadMassiveProductsMutation,
    useGetPackagingProductQuery,
    useGetAvailableProductCodeQuery,
    useRegisterCategoryProductMutation,
    useRegisterPackagingProductMutation,
    useUpdatePackagingProductMutation,
    useDeletePackagingProductMutation,
    useUpdateCategoryProductMutation,
    useDeleteCategoryProductMutation,
    useGetProductsForPointOfSaleQuery,
    useRegisterProductMutation,
    useGetProductQuery,
    useUpdateProductMutation,
    useDeleteProductMutation,
    useProductPurchaseHistoryQuery,
    useGetProductsForSupplierPurchasesQuery,
    useGetBuyBatchProductsQuery,
    useGetAvailableBatchesOfProductQuery,
    useGetProductsForMassEditionQuery,
    useUpdateProductFieldMutation,
    useGetAccurateProductInformationQuery,
} = Product;
