const TokenKey:string = 'user_token'
const invoiceMode:string = 'invoice_mode'
const invoiceAlphabeticalOrder:string = 'invoice_alphabetical_order'

export const setToken = (token:string) => localStorage.setItem(TokenKey, token)
export const getToken = () => localStorage.getItem(TokenKey)
export const deleteToken = () => localStorage.removeItem(TokenKey)
export const setQuickLogin = (data:string) => localStorage.setItem('qlogin', data)
export const getQuickLogin = () => localStorage.getItem('qlogin')

export const setInvoiceMode = (mode:'large' | 'pos') => localStorage.setItem(invoiceMode, mode)
export const getInvoiceMode = () => localStorage.getItem(invoiceMode)

export const setInvoiceAlphabeticalOrder = (mode:'default' | 'alphabetical') => localStorage.setItem(invoiceAlphabeticalOrder, mode)
export const getInvoiceAlphabeticalOrder = () => localStorage.getItem(invoiceAlphabeticalOrder)