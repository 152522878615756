import { GridColDef } from "@mui/x-data-grid"
import { useGetProductPurchaseTrackingQuery } from "../../../api/querys/PointOfSale"
import DataGrid from "@component/DataGrid"
import Dialog, { DialogContent, DialogTitle } from "@component/Dialog"
import { useAppDispatch, useAppSelector } from "../../../hooks/state"
import { useProductPurchaseTracking } from "../hooks/useCustomerPurchaseTracking"
import { showProductPurchaseTracking } from "../services/CustomerPurchaseTrackingSplice"

const HeaderDataGridList = (dates:string[]) => {
    const columns:GridColDef[] = [
        { field: 'name', headerName: 'Cliente', minWidth: 250 },
    ]

    for( let dateIndex in dates ){
        columns.push({ field: `${dates[dateIndex]}`, headerName: `${dates[dateIndex]}`, minWidth: 100 })
    }

    return columns
}

const ProductPurchaseTrackingList = () => {
    const { CustomerPurchaseTrackingState } = useAppSelector(({ CustomerPurchaseTrackingState }) => ({ CustomerPurchaseTrackingState }))
    const { showProductPurchaseTracking:showProductPurchaseTrackingActive, invoiceCollection=[] } = CustomerPurchaseTrackingState
    const { data } = useGetProductPurchaseTrackingQuery(invoiceCollection.join(','),{ skip: !((invoiceCollection.length >= 1) && showProductPurchaseTrackingActive) })
    const dispatch = useAppDispatch()
    const productPurchaseTracking = data?.payload?.productPurchaseTracking??[]
    const productPurchaseTrackingList = data?.payload?.products??[]
    const { organizeData } = useProductPurchaseTracking(productPurchaseTracking)
    const { productTrackingList, dates } = organizeData(productPurchaseTrackingList)
    const columns:GridColDef[] = HeaderDataGridList(dates)

    return(
        <Dialog fullWidth={true} maxWidth="lg" open={showProductPurchaseTrackingActive} onClose={() => dispatch(showProductPurchaseTracking({ show: false, invoiceCollection: [] }))} >
            <DialogTitle> Seguimiento de compra de productos </DialogTitle>
            <DialogContent>
                <DataGrid
                    height={"calc(100vh - 200px)"}
                    columns={columns}
                    rows={productTrackingList}
                />
            </DialogContent>
        </Dialog>
    )
}

export default ProductPurchaseTrackingList